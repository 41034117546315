import React from "react";
import { Loader } from "components";
import { useContentCreator } from "hooks/use-content-creator";
import type { RequestState } from "constants/";
import { FormCreateMaterialState } from "modules/forms/form-create-material/form-create-material-adapter";

const View = React.lazy(() => import("./content-builder-create"));

const ContentBuilderCreate = (props: {}) => (
  <React.Suspense fallback={<Loader />}>
    <View {...props} />
  </React.Suspense>
);

export default ContentBuilderCreate;

export type ContentBuilderCreateProps = {
  // Whether the current action is to create a new learning material or to edit an existing one.
  currentAction: "create" | "edit";
  // The learning material to be edited. This is only valid when the currentAction is "edit".
  selectedLearningMaterial: LearningMaterial | {};
  // The static data required to render the view.
  staticData: ReturnType<typeof useContentCreator>["staticData"];
  // The status of the request.
  status: RequestState;
  // The listener to be called when the form is submitted.
  submissionListener: (values: FormCreateMaterialState) => void;
  publishListener: () => void;
  cancellationListener?: () => void;
};
