import React, { useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import reviewPic from "assets/images/reviews/reviewPic.png";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { RTutorTutoring } from "constants/tutor-views-routes";

const ReviewBlank = () => {
  useEffect(() => {
    setTimeout(() => {}, 2000);
  }, []);

  const { availability } = useSelector(
    (state) => state.tutorDashboard.userInfo.user
  );

  return (
    <Container fluid>
      <Row>
        <Col xs="12" className="text-center">
          <Row className="justify-content-center">
            <Col sm={4}>
              <div className="maintenance-img mt-5">
                <img
                  src={reviewPic}
                  alt=""
                  className="img-fluid mx-auto d-block"
                />
              </div>
            </Col>
          </Row>
          <h3 className="mt-5 secondary-color fw-bold">
            Initiate your Tutoring Services or content creation to start
            collecting reviews!
          </h3>
          <p className="font-size-13 text-secondary-dim">
            Note: Customer feedback is a great way to enhance
            <br /> the services you provide.
          </p>
          <div className="d-flex gap-2 align-items-center justify-content-center">
            {Array.isArray(availability) && availability.length === 0 && (
              <Link
                to={RTutorTutoring.availability}
                className="my-3 btn btn-secondary"
              >
                Setup Availability
              </Link>
            )}
            <Link
              data-tourstep="setupSessionCTA"
              to="/1-1-tutoring/new-session"
              className="my-3 btn btn-secondary"
              id="setupSessionCTA"
            >
              Setup Sessions
            </Link>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ReviewBlank;
