import React, {useEffect} from "react";
import {Col, Row} from "reactstrap";
import {isEmpty} from "lodash";
import {
  IconLink,
  IconLayoutSidebarLeftCollapse,
  IconEar,
  IconBrandWindows,
} from "@tabler/icons";
import {IconGlobe} from "@tabler/icons";
import {useStaticDataV2} from "hooks";
import {useSelector} from "react-redux";
import {Icon, Loader} from "components";

const LearnerLearningStylePreferences = ({state}) => {
  const [learningStylesPreferences, setLearningStylesPreferences] = state;

  const {learningStyles} = useStaticDataV2(["learningStyles"]);
  const {state: allLearningStylesState, data: allLearningStyles} =
    learningStyles;

  const {learningstyle: storedLearningStyles} = useSelector(
    (state) => state.learnerDashboard.userInfo.user
  );

  useEffect(() => {
    setLearningStylesPreferences(
      storedLearningStyles.map((i) => i.learning_style_id)
    );
  }, []);

  const _handleLearningStyleClick = (lSId) => {
    if (learningStylesPreferences.includes(lSId)) {
      const updatedLS = learningStylesPreferences.filter((i) => i !== lSId);
      setLearningStylesPreferences(updatedLS);
    } else {
      setLearningStylesPreferences([lSId, ...learningStylesPreferences]);
    }
  };

  const _getmyIcon = (icon) => {
    switch (icon) {
      case "MdMenuOpen":
        return <IconLayoutSidebarLeftCollapse />;
      case "MdOutlineHearing":
        return <IconEar />;
      case "MdLink":
        return <IconLink />;
      case "MdDesktopWindows":
        return <IconBrandWindows />;
      case "FaGlobe":
        return <IconGlobe />;
      default:
        break;
    }
  };

  if (allLearningStylesState === "loading") return <Loader />;

  return (
    <div className="preference-learning">
      <h5 className="preference-head">
        <b>Select your learning style</b>
      </h5>
      <Row>
        <Col md="9">
          <Row className="g-3">
            {!isEmpty(allLearningStyles) &&
              allLearningStyles.map((item) => {
                return (
                  <Col lg="6" key={item.id}>
                    <div
                      className={`${
                        learningStylesPreferences.includes(item.id) &&
                        "border-success"
                      } p-3 preference-goaltitle h-100`}
                      onClick={() => _handleLearningStyleClick(item.id)}
                    >
                      <Icon.Rounded
                        className={`${
                          learningStylesPreferences.includes(item.id)
                            ? "text-white"
                            : "bg-opacity-10 text-success"
                        } bg-success  ms-1 `}
                        size="md"
                      >
                        {_getmyIcon(item.icon)}
                      </Icon.Rounded>

                      <h2
                        className="preference-learning"
                        style={{marginLeft: "7px"}}
                      >
                        {item.title}
                      </h2>
                      <span className="d-grid p-2 preference-content">
                        {item.description}
                        <br />
                      </span>
                    </div>
                  </Col>
                );
              })}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default LearnerLearningStylePreferences;
