// Types.
import type { Boolbacks, RemoteProfile } from "constants/types";

// Utils.
import { apiService } from "service";
import { getEnvVar } from "utils/utils-env";
import { getAuthHeaders, getFormDataFromObject } from "utils/utils-functions";
import { useCallback } from "react";
import useSetRemoteProfile from "hooks/profile/use-set-remote-profile";
import { useSelector } from "react-redux";

type UpdateAvailabilityProps = {
  availability: string;
} & Boolbacks<RemoteProfile>;

type SetAvailabilityProps = Partial<
  Pick<
    RemoteProfile,
    "availability" | "min_booking_time" | "max_advance_book_time"
  >
>;

export default function useRemoteAvailability() {
  const setProfile = useSetRemoteProfile();
  const { availability, min_booking_time, max_advance_book_time } = useSelector(
    (state: any) => state.profile
  ) as RemoteProfile;

  const setAvailability = useCallback(
    (props: SetAvailabilityProps) => {
      setProfile({
        profile: props,
      });
    },
    [setProfile]
  );

  const updateAvailability = useCallback(
    ({ onSuccess, onFailure, availability }: UpdateAvailabilityProps) => {
      apiService.post({
        headers: getAuthHeaders(),
        data: getFormDataFromObject({
          availability,
          min_booking_time,
          max_advance_book_time,
        }),
        url: `${getEnvVar("USER_SERVICE_URL")}/tutor/update-availability`,
        onFailure,
        onSuccess: ({ data }) => {
          setProfile({
            profile: {
              availability: data,
            },
          });

          onSuccess({ data });
        },
      });
    },
    [max_advance_book_time, min_booking_time, setProfile]
  );

  return {
    availability,
    min_booking_time,
    max_advance_book_time,
    setAvailability,
    updateAvailability,
  };
}
