// Hooks.
import useToast from "hooks/use-toast";
import useBioFormSchema from "./use-bio-form-schema";
import useRemoteProfile from "hooks/profile/use-remote-profile";

// Types.
import type { BioFormState } from "./bio-form-types";
import useBioFormAdapter from "./use-bio-form-adapter";

type Props = {
  children: ({
    initialValues,
    validationSchema,
    onSubmit,
  }: {
    initialValues: BioFormState;
    validationSchema: unknown;
    onSubmit: (state: BioFormState) => void;
  }) => JSX.Element;
};

export default function BioFormProvider({ children }: Props) {
  const toast = useToast();
  const { profile, updateProfile } = useRemoteProfile();

  // Features.
  const validationSchema = useBioFormSchema();
  const { serialize, deserialize } = useBioFormAdapter();

  /**
   * Initial values of the form.
   */
  const initialValues = deserialize(profile);

  function onSubmit(state: BioFormState) {
    toast.loading();

    updateProfile({
      profile: serialize(state),
      onFailure: ({ message }) => toast.error(message),
      onSuccess: () => toast.success(),
    });
  }

  return children({
    onSubmit,
    initialValues,
    validationSchema,
  });
}
