import React, { useRef, useState } from "react";
import { useStaticDataV3 } from "hooks/static-data";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import moment from "moment";
import ToastFactory from "utils/toast-factory";
import { messages } from "constants/strings";
import { fetchTutorAvailableSlots } from "store";
import { ModalProps } from "reactstrap";
import { Loader } from "components";
import { IComponentProps } from "./learner-book-session-modal";
import { RawLearnerOrderedBundle, ReduxStore } from "constants/";
import { StaticDataV3Record } from "constants/types/types-static-data";
import { useApiEffect } from "hooks";
import { RLearnerTutoring } from "constants/learner-views-routes";
import useSessionActions from "hooks/sessions/useSessionActions";

export interface IViewModelPassedControllers {
  isOpen: ModalProps["isOpen"];
  toggle: ModalProps["toggle"];
  saneCurriculums: string[];
  saneGrades: string[];
  isStaticDataLoaded: boolean;
  agenda: string;
  availableSlots: any;
  setAgenda: React.Dispatch<React.SetStateAction<string>>;
  description: string;
  setDescription: React.Dispatch<React.SetStateAction<string>>;
  selectedDate: string;
  setSelectedDate: React.Dispatch<React.SetStateAction<string>>;
  selectedSlot: any;
  setSelectedSlot: React.Dispatch<React.SetStateAction<any>>;
  selectedBundle: RawLearnerOrderedBundle;
  simpleValidator: React.MutableRefObject<SimpleReactValidator>;
  bundleSubjectName: string;
  handleSendRequest: () => void;
  getStaticData: (
    key: string | number,
    record: StaticDataV3Record
  ) => string | undefined;
}

function LearnerBookSessionViewModel({
  isOpen,
  toggle,
  children,
  bundleId,
}: IComponentProps & {
  children: (passedControllers: IViewModelPassedControllers) => JSX.Element;
}) {
  const dispatch: (dispatch: any) => void = useDispatch();
  const navigate = useNavigate();
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState<number>();

  const toastFactory = ToastFactory();
  const { handleBookSessionByLearner } = useSessionActions();

  /* 
   Picking the bundle that learner has chose from his ordered bundles. At this point, he has ordered 
   bundles in store and chose bundle can be picked from those ordered bundles.
  */
  const { selectedBundle, availableSlots } = useSelector(
    (state: ReduxStore) => ({
      availableSlots: state.tutorTutoring.availableSlots,
      selectedBundle: state.learnerTutoring.orderedBundles.data.find(
        (b) => b.bundle_id === bundleId
      )!,
    })
  );

  const [agenda, setAgenda] = useState("");
  const [description, setDescription] = useState("");

  // BookSession states
  const [selectedDate, setSelectedDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  // TODO: add TS Type
  const [selectedSlot, setSelectedSlot] = useState<any>(null);
  // ------------------------

  // Static Data States
  const { subjects, curriculums, grades, getStaticData } = useStaticDataV3([
    "subjects",
    "grades",
    "curriculums",
  ]);
  const [
    { data: totalCurriculums, state: curriculumsState },
    { data: totalGrades, state: gradesState },
    { data: totalSubjects, state: subjectsState },
  ] = [curriculums, grades, subjects];
  const isStaticDataLoaded =
    curriculumsState === "loaded" &&
    gradesState === "loaded" &&
    subjectsState === "loaded";
  // ---------------------

  const bundleSubjectName = getStaticData(
    selectedBundle?.subject_id,
    totalSubjects
  )!;

  const handleSendRequest = (): void => {
    if (simpleValidator.current.allValid()) {
      if (!selectedSlot) {
        toastFactory.info(messages.SLOT_NOT_SELECTED);
        return;
      }
    } else {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    if (selectedBundle?.remaining_credits <= 0) {
      toastFactory.error(messages.NO_BUNDLE_CREDIT);
      return;
    }

    const startTime = moment(
      selectedSlot.startTimeString.split(" ")[1],
      "HH:mm"
    ).format("HH:mm");

    const endTime = selectedSlot.endTime;

    const weekDay = moment(selectedDate, "YYYY-MM-DD").day();
    const date = moment(selectedSlot.id.split(" ")[0], "DD-MM-YYYY").format(
      "YYYY-MM-DD"
    );

    handleBookSessionByLearner({
      agenda,
      description,
      startTime,
      endTime,
      receiverId: selectedBundle?.user_id,
      bundleId,
      date,
      weekDay,
      subjectName: bundleSubjectName,
      receiverName: selectedBundle?.tutor_name,
      receiverPicture: selectedBundle?.profile_picture,
      onSuccess: () => {
        dispatch({ type: "RESET_LEARNER_UPCOMING_SESSIONS" });
        // @ts-ignore
        toggle!();
        navigate(RLearnerTutoring.getSessionsView("upcoming"));
      },
      onFailure: () => {},
    });
  };

  useApiEffect(() => {
    dispatch(
      fetchTutorAvailableSlots({
        bundleId: selectedBundle?.bundle_id,
        range: 7,
        date: selectedDate,
        tutorId: selectedBundle?.user_id,
        onFailure: (error: { message: string }) => {
          toastFactory.error(error.message);
        },
      })
    );
  }, [selectedDate]);

  /// Data uniformation starts here.

  /**
   * Loop-able grades.
   */
  const saneGrades = selectedBundle?.grade_id.map(
    (id) => getStaticData(id, totalGrades)!
  );

  /**
   * Loop-able curriculums.
   */
  const saneCurriculums = selectedBundle?.curriculum_id.map(
    (id) => getStaticData(id, totalCurriculums)!
  );

  /// Data uniformation ends here.

  if (!isStaticDataLoaded) return <Loader />;

  const passedControllers: IViewModelPassedControllers = {
    saneCurriculums,
    saneGrades,
    availableSlots,
    isOpen,
    toggle,
    handleSendRequest,
    bundleSubjectName,
    getStaticData,
    isStaticDataLoaded,
    agenda,
    setAgenda,
    description,
    setDescription,
    selectedDate,
    setSelectedDate,
    selectedSlot,
    setSelectedSlot,
    selectedBundle,
    simpleValidator,
  };

  return children(passedControllers);
}

export default LearnerBookSessionViewModel;
