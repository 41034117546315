import React from "react";
import {Container} from "reactstrap";

import ChattingUserDoodle from "assets/images/chatting-user.svg";
import {useUser} from "hooks";

/**
 * @author Abdullah-Sajjad026
 */
export default function MessagesPlaceholderView() {
  const {type: userType} = useUser();

  return (
    <Container fluid className="py-5 py-sm-0">
      <div className="d-flex flex-column align-items-center justify-content-center h-100">
        <div>
          <img
            src={ChattingUserDoodle}
            alt="Chatting user doodle"
            width="500"
          />
        </div>
        <h3 className="mt-3 secondary-color fw-bold">
          Start messaging with your{" "}
          {userType === "learner" ? "tutors" : "learners"}
        </h3>
        {/* <p className="font-size-13 text-secondary-dim">Some text here</p> */}
      </div>
    </Container>
  );
}
