export const FETCHED_UPCOMING_SESSIONS = "FETCHED_UPCOMING_SESSIONS";
export const FETCHED_RECENT_TUTORS = "FETCHED_RECENT_TUTORS";
export const FETCHED_CALENDAR_SESSIONS = "FETCHED_CALENDAR_SESSIONS";
export const FETCHED_PREVIOUS_SESSIONS = "FETCHED_PREVIOUS_SESSIONS";
export const FETCHED_MISSED_SESSIONS = "FETCHED_MISSED_SESSIONS";
export const FETCHED_ORDERED_BUNDLES = "FETCHED_ORDERED_BUNDLES";

export const RESET_CALENDAR_SESSIONS = "RESET_CALENDAR_SESSIONS";
export const RESET_PREVIOUS_SESSIONS = "RESET_PREVIOUS_SESSIONS";
export const RESET_ORDERED_BUNDLES = "RESET_ORDERED_BUNDLES";
export const RESET_MISSED_SESSIONS = "RESET_MISSED_SESSIONS";

/// Add session review
export const POST_SESSION_REVIEW = "POST_SESSION_REVIEW";

export const ERRED_PREVIOUS_SESSIONS = "ERRED_PREVIOUS_SESSIONS";
export const ERRED_MISSED_SESSIONS = "ERRED_MISSED_SESSIONS";
