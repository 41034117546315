import React, {useEffect} from "react";
import {Col, Row} from "reactstrap";
import {IconCircleCheck} from "@tabler/icons";
import {IconSunset2, IconSunHigh} from "@tabler/icons";
import {IconMoonStars} from "@tabler/icons";
import {isEmpty} from "lodash";
import {connect} from "react-redux";
import {store} from "store";
import {UPDATE_TIMESLOTS} from "store/learnerdashboard/actionTypes";
import Moment from "moment";
import {storeTimeSlots, fetchTimeSlots} from "store/learnerdashboard/action";
import "./best-time.scss";

const columns = [
  {
    id: 1,
    accessor: "Sun",
    value: "Sunday",
    ischecked: false,
  },
  {
    id: 2,
    accessor: "Mon",
    value: "Monday",
  },
  {
    id: 3,
    accessor: "Tue",
    value: "Tuesday",
  },
  {
    id: 4,
    accessor: "Wed",
    value: "Wednesday",
  },
  {
    id: 5,
    accessor: "Thu",
    value: "Thursday",
  },
  {
    id: 6,
    accessor: "Fri",
    value: "Friday",
  },
  {
    id: 7,
    accessor: "Sat",
    value: "Saturday",
  },
];

const LearnerBestTimePreferences = ({timeSlotProps, state, fetchTimeSlots}) => {
  const [bestTimesPreferences, setBestTimesPreferences] = state;

  const timeSlotsRow = timeSlotProps.timeSlotsRow;
  const selectedTime = timeSlotProps.selectedTimeSlots;

  const updatedTimeSlots = (selectedTime) => {
    const updatedArray = [];
    selectedTime.forEach((item) => {
      let obj = {};
      obj.day = item.day;
      obj.slot_id = item.slot_id;
      updatedArray.push(obj);
    });
    return updatedArray;
  };

  const handleMultiSelect = (data) => {
    let allValid = [];
    let array = [];
    for (const i of timeSlotsRow) {
      const selectedSessionData = {
        day: data.id,
        slot_id: i.id,
      };
      array.push(selectedSessionData);
      if (
        isEmpty(
          bestTimesPreferences.find(
            (obj) =>
              obj.day === selectedSessionData.day &&
              obj.slot_id === selectedSessionData.slot_id
          )
        )
      ) {
        allValid.push(true);
      } else {
        allValid.push(false);
      }
    }
    if (allValid.every((valid) => valid == false)) {
      for (let i of array) {
        const indexOfObject = bestTimesPreferences.findIndex((object) => {
          return object.day === i.day && object.slot_id === i.slot_id;
        });
        bestTimesPreferences.splice(indexOfObject, 1);
      }
      setBestTimesPreferences(bestTimesPreferences);
      store.dispatch({
        type: UPDATE_TIMESLOTS,
        payload: bestTimesPreferences,
      });
    } else if (allValid.some((valid) => valid == false)) {
      let a = [...array, ...bestTimesPreferences];
      let jsonObject = a.map(JSON.stringify);
      let uniqueSet = new Set(jsonObject);
      setBestTimesPreferences(Array.from(uniqueSet).map(JSON.parse));
      store.dispatch({
        type: UPDATE_TIMESLOTS,
        payload: Array.from(uniqueSet).map(JSON.parse),
      });
    } else {
      if (allValid.every((valid) => valid == true)) {
        let a = [...array, ...bestTimesPreferences];
        let jsonObject = a.map(JSON.stringify);
        let uniqueSet = new Set(jsonObject);
        setBestTimesPreferences(Array.from(uniqueSet).map(JSON.parse));
        store.dispatch({
          type: UPDATE_TIMESLOTS,
          payload: Array.from(uniqueSet).map(JSON.parse),
        });
      }
    }
  };

  const handleSelect = (columnId, rowId) => {
    const selectedSession = {
      day: columnId,
      slot_id: rowId,
    };
    if (
      !isEmpty(
        bestTimesPreferences.find(
          (obj) =>
            obj.day === selectedSession.day &&
            obj.slot_id === selectedSession.slot_id
        )
      )
    ) {
      const indexOfObject = bestTimesPreferences.findIndex((object) => {
        return (
          object.day === selectedSession.day &&
          object.slot_id === selectedSession.slot_id
        );
      });
      bestTimesPreferences.splice(indexOfObject, 1);

      setBestTimesPreferences(bestTimesPreferences);

      store.dispatch({
        type: UPDATE_TIMESLOTS,
        payload: bestTimesPreferences,
      });
    } else {
      setBestTimesPreferences([selectedSession, ...bestTimesPreferences]);
      store.dispatch({
        type: UPDATE_TIMESLOTS,
        payload: [selectedSession, ...bestTimesPreferences],
      });
    }
  };

  const _getmyIcon = (icon) => {
    switch (icon) {
      case "Morning":
      case "Late Morning":
      case "Evening":
        return <IconSunset2 />;
      case "Afternoon":
      case "Late Afternoon":
        return <IconSunHigh />;
      case "Late Evening":
        return <IconMoonStars />;
      default:
        break;
    }
  };

  useEffect(() => {
    fetchTimeSlots();
    setBestTimesPreferences(selectedTime);
    const updateTime = updatedTimeSlots(selectedTime);
    setBestTimesPreferences(updateTime);
  }, []);
  return (
    <Row className="preference-time">
      <Col>
        <Col>
          <table className="preference-time_table table-bordered">
            <thead className="preference-time_content">
              <tr>
                <th className="preference-time_slots">Time slots</th>
                {columns.map((item) => {
                  let i = 0;
                  for (let j of bestTimesPreferences) {
                    if (j.day === item.id) i++;
                  }
                  let ischecked = false;
                  if (i === 6) ischecked = true;
                  return (
                    <th
                      className="text-center preference-time_columnhead"
                      key={item.id}
                    >
                      <h2 className="preference-time_head1 m-0">
                        {item.accessor}
                      </h2>
                      <div className="">
                        <input
                          className=""
                          type="checkbox"
                          checked={ischecked}
                          onClick={() => {
                            handleMultiSelect(item);
                          }}
                          name={item.value}
                          id={`${item.id} flexCheckChecked`}
                        />
                        <label className="" htmlFor="flexCheckChecked"></label>
                      </div>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {!isEmpty(timeSlotsRow) &&
                timeSlotsRow.map((item) => {
                  return (
                    <tr>
                      <td
                        className="d-flex preference-time_content"
                        key={item.id}
                      >
                        <span className="checkIcon2 m-1 p-1">
                          {_getmyIcon(item.title)}
                        </span>
                        <div className="d-flex flex-column preference-time_tablehead1">
                          <span className="preference-time_head">
                            {item.title}
                          </span>
                          <span className="preference-time_span">
                            {Moment(item.start_time, "HH:mm:ss A").format(
                              "h a"
                            )}{" "}
                            -{" "}
                            {Moment(item.end_time, "HH:mm:ss A").format("h a")}
                          </span>
                        </div>
                      </td>

                      {columns.map((data) => {
                        return (
                          <td
                            className="text-center"
                            key={data.id}
                            onClick={() => {
                              handleSelect(data.id, item.id);
                            }}
                          >
                            {!isEmpty(
                              bestTimesPreferences.find(
                                (obj) =>
                                  obj.day === data.id && obj.slot_id === item.id
                              )
                            ) && (
                              <span className="preference-time-check-icon">
                                <IconCircleCheck />
                              </span>
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </Col>
      </Col>
    </Row>
    //  </Container>
  );
};

const mapToStateProps = (state) => ({
  timeSlotProps: state.learnerDashboard.timeSlot,
});

export default connect(mapToStateProps, {storeTimeSlots, fetchTimeSlots})(
  LearnerBestTimePreferences
);
