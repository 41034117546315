import React from "react";
// import ChooseBestTime from "./ChooseBestTime";
// import ChooseLanguage from "./ChooseLanguage";
// import ChooseTopic from "./ChooseTopic";
// import VerifyEmail from "./VerifyEmail"
// import ChooseGoal from "./ChooseGoal";
// import ChooseGoalSecond from "./ChooseGoalSecond";
import {Col, Container, Row} from "reactstrap";
import OnboardingSidebar from "../onboarding-sidebar";
import "./onboarding-learner.scss";
import OnboardingLearnerStep1 from "./onboarding-learner-step1";

/**
 * @author Abdullah-Sajjad026
 */
export function LearnerOnboarding(props) {
  // const [step, setStep] = useState(1);
  // const [{ hasCurriculums, hasGrades, hasInterests }, setTopicBar] = useState({
  //   hasCurriculums: true,
  //   hasGrades: true,
  //   hasInterests: true,
  // });
  // // const [
  // //   {hasLanguages, hasGoals, hasBestTimes, hasLearningStyles},
  // //   setAboutBar,
  // // ] = useState({
  // //   hasLanguages: false,
  // //   hasGoals: false,
  // //   hasBestTimes: false,
  // //   hasLearningStyles: false,
  // // });

  // const [topicBarPercent, setTopicBarPercent] = useState(100);
  // const [aboutBarPercent, setAboutBarPercent] = useState(0);

  // useEffect(() => {
  //   setTopicBarPercent(() => {
  //     if (hasCurriculums && hasGrades && hasInterests) {
  //       return 100;
  //     } else if (
  //       (hasCurriculums && !hasGrades && !hasInterests) ||
  //       (!hasCurriculums && hasGrades && !hasInterests) ||
  //       (!hasCurriculums && !hasGrades && hasInterests)
  //     ) {
  //       return 33;
  //     } else if (
  //       (hasCurriculums && hasGrades && !hasInterests) ||
  //       (!hasCurriculums && hasGrades && hasInterests) ||
  //       (hasCurriculums && !hasGrades && hasInterests)
  //     ) {
  //       return 67;
  //     } else {
  //       return 0;
  //     }
  //   });
  // }, [hasCurriculums, hasGrades, hasInterests]);

  // const nextStep = () => {
  //   setStep((prevStep) => prevStep + 1);
  // };
  // const prevStep = () => {
  //   setStep((prevStep) => prevStep - 1);
  // };

  return (
    <>
      <Container fluid className="choose-topic">
        <Row className="h-100 w-100 mx-0">
          <Col
            md="6"
            xs="12"
            id="sticky-sidebar"
            className="p-0 d-none d-md-block"
          >
            <OnboardingSidebar />
          </Col>
          <Col
            md="6"
            xs="12"
            className="p-4 pb-sm-5 py-sm-2 px-3 px-xl-3 py-md-0 choose-topic-main"
          >
            <Container className="py-xl-0 p-0 p-lg-1 p-md-1 py-md-0 h-100">
              <div className="py-md-3 h-100 d-flex flex-column">
                <Col xs="12">
                  <h6 className="main-heading mt-sm-4 mt-md-4 mt-lg-3 mb-5 mb-sm-3 mb-md-4 mb-lg-4">
                    Let us know more about yourself.
                  </h6>
                </Col>

                {/* <Row>
                  <Col
                    sm="6"
                    className="mb-4 mb-sm-0 mt-1 d-flex flex-column justify-content-between"
                  >
                    <div className="d-flex flex-row divBox1 mb-1">
                      <span
                        className="prog2 text-success bg-opacity-10 bg-success d-flex justify-content-center align-items-center p-2 rounded-circle"
                        style={{ width: "55px", height: "55px" }}
                      >
                        <IconBook size="24" />
                      </span>
                      <div className="mx-2">
                        <b className="aTextStyle">Choose Topics</b>
                        <p className="mb-0">Some topics you'd like to learn.</p>
                      </div>
                    </div>
                    <div className="learner-progress-bar mt-2">
                      <div
                        className="learner-progress-bar"
                        id="myBar1"
                        style={{ width: `${topicBarPercent}%` }}
                      />
                    </div>
                  </Col>
                  <Col
                    sm="6"
                    className="mb-3 mb-sm-0 mt-1 d-flex flex-column justify-content-between"
                  >
                    <div className="d-flex flex-row grid-container divBox1 mb-1">
                      <span
                        className="prog2 text-success bg-opacity-10 bg-success d-flex justify-content-center align-items-center p-2 rounded-circle"
                        style={{ width: "55px", height: "55px" }}
                      >
                        <IconUser size="24" />
                      </span>
                      <div className="mx-2">
                        <b className="aTextStyle">About you</b>
                        <p className="mb-0">Let us know more about you.</p>
                      </div>
                    </div>
                    <div className="learner-progress-bar mt-2">
                      <div
                        className="learner-progress-bar"
                        id="my"
                        style={{ width: `${aboutBarPercent}%` }}
                      />
                    </div>
                  </Col>
                </Row> */}

                <OnboardingLearnerStep1 />

                {/* {step === 1 ? (
                  <ChooseTopic
                    nextStep={nextStep}
                    updateBar={setTopicBar}
                    setAboutBar={setAboutBarPercent}
                  />
                ) : step === 2 ? (
                  <ChooseLanguage
                    nextStep={nextStep}
                    prevStep={prevStep}
                    updateBar={setAboutBarPercent}
                  />
                ) : step === 3 ? (
                  <ChooseGoal
                    nextStep={nextStep}
                    prevStep={prevStep}
                    updateBar={setAboutBarPercent}
                  />
                ) : step === 4 ? (
                  <ChooseBestTime
                    nextStep={nextStep}
                    prevStep={prevStep}
                    updateBar={setAboutBarPercent}
                  />
                ) : (
                  step === 5 && (
                    <ChooseGoalSecond
                      nextStep={nextStep}
                      prevStep={prevStep}
                      updateBar={setAboutBarPercent}
                    />
                  )
                )} */}
              </div>
            </Container>
          </Col>
        </Row>
        {/* </div> */}
      </Container>
    </>
  );
}

export default LearnerOnboarding;
