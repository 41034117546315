import React from "react";
import { getEnvInt } from "utils/utils-env";
import { boxStyles } from "constants/config";
import { Col, Form, Row } from "react-bootstrap";
import { StaticField } from "components/static-field";
import FormErrorMessage from "components/error-message-form";

// Types.
import type { FormikProps } from "formik";
import type { SubjectsFormState } from "./subjects-form-types";

export default function SubjectsFormView({
  values,
  errors,
  touched,
  setFieldValue,
  setFieldTouched,
}: FormikProps<SubjectsFormState>) {
  return (
    <Row className={boxStyles.g}>
      <Col xs={12}>
        <Form.Group>
          <StaticField.Multi
            id="subjects"
            staticKey="subjects"
            value={values.subjects}
            maxValues={getEnvInt("TUTOR_MAX_SUBJECTS")}
            onBlur={() => setFieldTouched("subjects", true)}
            onChange={(value) => setFieldValue("subjects", value)}
          />
          {touched.subjects && errors.subjects && (
            <Form.Text className="text-muted">
              <FormErrorMessage message={errors.subjects as string} />
            </Form.Text>
          )}
        </Form.Group>
      </Col>
    </Row>
  );
}
