import { SmallBadgesList } from "components/lists";
import { IconHeart, IconShoppingCart } from "@tabler/icons";
import { Badge, Button, CardBody, CardTitle, Progress } from "reactstrap";

// Utils.
import { DEFAULT_PROGRESS_VALUE } from "constants";
import CardWrapper from "components/cards/card-wrapper";
import { getAbbrPriceFrom, removeDiscountPercentage } from "utils";

/**
 * @typedef {Object} BestSellingProductCardProps
 *
 * @property {string} title
 *
 * @property {string | undefined} slug
 *
 * @property {BootstrapColors} badgeColor
 *
 * @property {string | undefined} badgeText
 *
 * @property {"top-left" | "top-right"} badgePosition
 *
 * @property {string | undefined} coverImageSrc
 *
 * @property {HeadingLevel | undefined} headingTag
 *
 * @property {1 | 2 | 3 | 4 | 5 | undefined} rating
 *
 * @property {string} sellerName
 *
 * @property {string | undefined} sellerPunchline
 *
 * @property {number} price
 *
 * @property {number | undefined} discountPercentage
 *
 * @property {number | undefined} numOfRecentSales
 *
 * @property {string[] | undefined} keywords
 *
 * @property {string} progressText
 *
 * @property {number} progressValue
 *
 * @property {Function | undefined} buyButtonListener
 * Button won't render if listener isn't provided.
 *
 * @property {Function | undefined} favoriteButtonListener
 * Button won't render if listener isn't provided.
 */

/**
 * A card representing any best selling product.
 *
 * @param {BestSellingProductCardProps & import("reactstrap").CardProps} props
 *
 * @returns {JSX.Element}
 *
 * @author kashan-ahmad
 * @version 0.0.3
 *
 * @changelog
 * - 0.0.3: Replaced `vstack` CSS class with `d-flex flex-column` on multiple spots due to unwanted properties.
 * - 0.0.2: Added `badgeText`, `badgeColor`, and `badgePosition` props.
 * - 0.0.1: Initial version.
 */
function BestSellingProductCard({
  id,
  title,
  price,
  rating,
  keywords,
  badgeText,
  sellerName,
  headingTag,
  progressText,
  progressValue,
  coverImageSrc,
  sellerPunchline,
  numOfRecentSales,
  buyButtonListener,
  discountPercentage,
  favoriteButtonListener,
  badgeColor = "success",
  badgePosition = "top-right",
  ...cardProps
}) {
  const badgeClasses = [
    "z-1",
    "fs-7",
    "rounded-0",
    "position-absolute",
    {
      "top-left": "top-0 start-0",
      "top-right": "top-0 end-0",
    }[badgePosition],
  ];

  return (
    <CardWrapper id={id} {...cardProps}>
      {badgeText && (
        <Badge color={badgeColor} className={badgeClasses.join(" ")}>
          {badgeText}
        </Badge>
      )}
      <div className="h-3 maxh-3 thumbnail-wrapper">
        <img
          src={coverImageSrc}
          alt="Couldn't load thumbnail."
          className="thumbnail d-flex justify-content-center align-items-center"
        />
      </div>
      <CardBody className="vstack gap-4 p-3 justify-content-between">
        <header className="d-flex flex-column gap-3">
          <div>
            <div className="hstack gap-2 align-items-start justify-content-between">
              <CardTitle tag={headingTag} className="mb-0 text-truncate-n2">
                {title}
              </CardTitle>
              {rating && (
                <div className="fs-5 flex-shrink-0">
                  <span className="fs-4 text-warning">&#9733;</span> {rating}
                </div>
              )}
            </div>
            {(sellerName || sellerPunchline) && (
              <div className="list-dots fs-7 mt-1 text-gray-600 text-truncate">
                {sellerName && <span>By {sellerName}</span>}
                {sellerPunchline && <span>{sellerPunchline}</span>}
              </div>
            )}
          </div>
          <div className="hstack align-items-center justify-content-between flex-wrap">
            {(!!price || !!discountPercentage) && (
              <div className="vstack justify-content-start text-truncate">
                {price && (
                  <CardTitle className="fs-4 mb-0 text-truncate">
                    {getAbbrPriceFrom(price)}
                  </CardTitle>
                )}
                {discountPercentage && (
                  <div className="fs-5 text-gray-600 text-truncate text-decoration-line-through">
                    {getAbbrPriceFrom(
                      removeDiscountPercentage(price, discountPercentage)
                    )}
                  </div>
                )}
              </div>
            )}
            {!numOfRecentSales ? null : (
              <div className="text-success fs-7 hstack gap-1">
                <IconShoppingCart size={16} />
                {numOfRecentSales} bought in 24h
              </div>
            )}
          </div>
        </header>
        <footer className="d-flex flex-column gap-3">
          {progressText && (
            <>
              <Progress
                color="success"
                className="bg-light border"
                value={progressValue || DEFAULT_PROGRESS_VALUE}
              />
              <div aria-label="Material Completion Percentage">
                {progressValue}% {progressText}
              </div>
            </>
          )}
          <SmallBadgesList breakAt={3} hasDropdown entities={keywords} />
          {(favoriteButtonListener || buyButtonListener) && (
            <div role="group" className="hstack gap-2">
              {favoriteButtonListener && (
                <Button
                  outline
                  color="secondary"
                  onClick={() => favoriteButtonListener(id)}
                >
                  <IconHeart />
                </Button>
              )}
              {buyButtonListener && (
                <Button
                  color="secondary"
                  className="w-100 align-self-stretch"
                  onClick={() => buyButtonListener(id)}
                >
                  Buy Now
                </Button>
              )}
            </div>
          )}
        </footer>
      </CardBody>
    </CardWrapper>
  );
}

export default BestSellingProductCard;
