/**
 * A general purpose (polymorphic) padded
 * container for small-medium sized textual
 * entities.
 *
 * @param {Object} object The properties passed
 * to the component.
 * @param {string} object.tag The HTML element to
 * wrap the component with.
 * @param {string} object.color The bootstrap colors.
 * @param {string} object.className The CSS classes.
 * @param {React.ReactNode} object.children The children.
 *  @param {React.CSSProperties} [object.style] The inline styles.
 * @param {...any} props Any additional props to be
 * passed down to the component.
 *
 * .
 * @returns {JSX.Element}
 * @version 0.0.1
 * @author kashan-ahmad
 *
 * @changelog
 * - 0.0.1: Initial version.
 */
const Label = ({
  tag: Tag = "span",
  color = "primary",
  className = "",
  children,
  ...props
}) => (
  <Tag
    {...props}
    className={`d-inline-flex px-2 text-${color} bg-${color} bg-opacity-10 rounded-2 ${className}`}
  >
    {children}
  </Tag>
);

export default Label;
