const DUMMIES_DURATION = [
  {label: "15 Minutes", value: 15},
  {label: "30 Minutes", value: 30},
  {label: "45 Minutes", value: 45},
  {label: "60 Minutes", value: 60},
  {label: "75 Minutes", value: 75},
  {label: "90 Minutes", value: 90},
];

const DUMMIES_DAY_NUMERIC = [
  {id: 1, name: "Monday"},
  {id: 2, name: "Tuesday"},
  {id: 3, name: "Wednesday"},
  {id: 4, name: "Thursday"},
  {id: 5, name: "Friday"},
  {id: 6, name: "Saturday"},
  {id: 7, name: "Sunday"},
];

const DUMMIES_MONTHS_NUMERIC = [
  "January",
  "Feburary",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
].map((m, i) => ({label: m, value: i + 1}));

const DUMMIES_YEARS_NUMERIC = (() => {
  const currentYear = new Date().getFullYear();
  let yearCount = currentYear - 100;
  const years = [];
  while (yearCount <= currentYear) {
    years.push({label: yearCount, value: yearCount});
    yearCount += 1;
  }
  return years.reverse();
})();

const DUMMIES_TIME_FORMAT = [
  {label: "Day/Month/Year", value: "dd-mm-yyyy"},
  {label: "Year/Month/Day", value: "yyyy-mm-dd"},
];

export {
  DUMMIES_DURATION,
  DUMMIES_MONTHS_NUMERIC,
  DUMMIES_DAY_NUMERIC,
  DUMMIES_TIME_FORMAT,
  DUMMIES_YEARS_NUMERIC,
};
