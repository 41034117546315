import { ProfileMenuAction, ProfileMenuItem } from ".";

export function profileMenuAddItem(item: ProfileMenuItem): ProfileMenuAction {
  return {
    payload: item,
    type: "PROFILE_MENU_ADD_ITEM",
  };
}

export function profileMenuRemoveItem(
  id: ProfileMenuItem["id"]
): ProfileMenuAction {
  return {
    payload: id,
    type: "PROFILE_MENU_REMOVE_ITEM",
  };
}
