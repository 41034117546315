import { apiService } from "service";

function setExpiry() {
  localStorage.setItem("STATIC_EXPIRY", new Date().toString());
}

function isExpired() {
  return (
    // Expires after 1 hour.
    Math.floor(
      (new Date() - new Date(localStorage.getItem("STATIC_EXPIRY"))) /
        (1000 * 60 * 60)
    ) > 0
  );
}

/**
 * Static Data V2.
 * A neutral action handler that fetches any of the static data based on the provided parameters.
 * @param {string} url The request URL.
 * @param {StaticDataV2Action['payload']['key']} key
 * @param {"get" | "post"} method
 * @param {StaticDataV2ActionType} actionType
 * Note that the action type needs to be separated from it's prefix, such as "SET" and "ERRED" and just the action type needs to be passed here.
 */
export const fetchStaticDataV2 =
  (url, key, method = "get", actionType) =>
  /**
   * @param {import("redux").Dispatch<StaticDataV2Action>} dispatch
   */
  (dispatch) => {
    const successActionType = `SET_${actionType}`;

    if (!isExpired() && localStorage.getItem(actionType)) {
      // If cached/hydrated data exists.
      dispatch({
        type: successActionType,
        payload: {
          key,
          data: JSON.parse(localStorage.getItem(actionType)),
        },
      });
      return;
    }

    // Fetch new data.
    apiService[method]({
      url,
      onFailure: ({ message }) =>
        dispatch({
          type: `ERRED_${actionType}`,
          payload: {
            key,
            error: message,
          },
        }),
      onSuccess: ({ data }) => {
        // Cache the data.
        setExpiry();
        localStorage.setItem(actionType, JSON.stringify(data));

        // Update the state.
        dispatch({
          payload: { key, data },
          type: successActionType,
        });
      },
    });
  };
