import { useCallback } from "react";
import { apiService } from "service";
import { getAuthHeaders } from "utils";
import { getEnvVar } from "utils/utils-env";
import { Boolbacks, RemoteProfile } from "constants/types";
import useSetRemoteProfile from "hooks/profile/use-set-remote-profile";
import { useSelector } from "react-redux";

type UpdateSkills = {
  skills: RemoteProfile["skills"];
} & Boolbacks;

type DeleteSkill = {
  skillId: string | number;
} & Boolbacks;

export default function useRemoteSkills() {
  const setProfile = useSetRemoteProfile();

  const { skills } = useSelector(
    (state: any) => state.profile
  ) as RemoteProfile;

  const setSkills = useCallback(
    ({ skills }: { skills: RemoteProfile["skills"] }) => {
      setProfile({ profile: { skills } });
    },
    [setProfile]
  );

  const updateSkills = useCallback(
    ({ skills, onSuccess, onFailure }: UpdateSkills) => {
      apiService.post({
        headers: getAuthHeaders(),
        data: { skills: JSON.stringify(skills) },
        url: `${getEnvVar("USER_SERVICE_URL")}/tutor/update-specialities`,
        onFailure,
        onSuccess: ({ data }: { data: RemoteProfile["skills"] }) => {
          setSkills({ skills: data });
          onSuccess?.({ data });
        },
      });
    },
    [setSkills]
  );

  const deleteSkill = useCallback(
    ({ skillId, onSuccess, onFailure }: DeleteSkill) => {
      apiService.delete({
        headers: getAuthHeaders(),
        url: `${getEnvVar("USER_SERVICE_URL")}/tutor/delete-skill/${skillId}`,
        onFailure,
        onSuccess: () => {
          const updatedSkills = skills.filter(({ id }) => id !== skillId);

          setSkills({ skills: updatedSkills });
          onSuccess({ data: updatedSkills });
        },
      });
    },
    [setSkills, skills]
  );

  return { skills, updateSkills, deleteSkill };
}
