const NOTIFICATION_DUMMIES = [
  {
    id: 1,
    sent_by: {
      name: "John Doe",
      avatar: "avatar-3.jpg",
    },
    sent_to: {
      name: "Kawabunga Khan",
    },
    description: "Lorem ipsum sit dolor amet",
    type: 12,
    req_id: "123",
    seen: 0,
    created_at: "2022-04-11 19:59:24",
    updated_at: "2022-04-28 19:56:39",
    state: "",
    link: "",
    action: "",
  },
  {
    id: 2,
    sent_by: {
      name: "Hamza Doe",
      avatar: "avatar-3.jpg",
    },
    sent_to: {
      name: "Kawabunga Khan",
    },
    description: "Lorem ipsum sit dolor amet Lorem ipsum sit dolor amet",
    type: 15,
    req_id: "125",
    seen: 0,
    created_at: "2022-06-03 14:21:00",
    updated_at: "2022-03-05 22:14:21",
    state: "",
    link: "",
    action: "",
  },
  {
    id: 3,
    sent_by: {
      name: "Hamza Doe",
      avatar: "avatar-4.jpg",
    },
    sent_to: {
      name: "Kawabunga Khan",
    },
    description: "Lorem ipsum sit dolor amet Lorem ipsum sit dolor amet",
    type: 6,
    req_id: "130",
    seen: 0,
    created_at: "2022-03-05 22:14:21",
    updated_at: "2022-02-28 04:08:45",
    state: "",
    link: "",
    action: "",
  },
  {
    id: 4,
    sent_by: {
      name: "TheTutor",
    },
    sent_to: {
      name: "Kawabunga Khan",
    },
    description: "Lorem ipsum sit dolor amet Lorem ipsum sit dolor amet",
    type: 8,
    req_id: "130",
    seen: 1,
    created_at: "2022-03-26 19:05:21",
    updated_at: "2022-03-26 19:05:21",
    state: "",
    link: "",
    action: "",
  },
  {
    id: 5,
    sent_by: {
      name: "TheTutor",
    },
    sent_to: {
      name: "Kawabunga Khan",
    },
    description: "Lorem ipsum sit dolor amet Lorem ipsum sit dolor amet",
    type: 18,
    req_id: "130",
    seen: 1,
    created_at: "2022-03-26 19:10:21",
    updated_at: "2022-03-26 19:10:21",
    state: "",
    link: "",
    action: "",
  },
];

export default NOTIFICATION_DUMMIES;
