import {IconEye, IconVideo, IconVideoPlus} from "@tabler/icons";
import {Loader, SassyAlert} from "components";
import {useApiEffect} from "hooks";
import SimpleBar from "simplebar-react";
import {useStaticDataV3} from "hooks/static-data";
import moment from "moment";
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {fetchTutorUpcomingSession} from "store";
import {getAdjustedTime, getMeetingLink} from "utils";
import {RTutorTutoring} from "constants/tutor-views-routes";
import NoUpComingSessions from "components/no-upcoming-sessions/no-upcoming-sessions";

const DashboardTutorUpcomingSessions = ({hasSessionBundles = true, props}) => {
  // const upcomingSessions = useSelector(
  //   (state) => state.tutorTutoring.upcomingSessions
  // );

  const [upcomingSessions, setUpcomingSessions] = React.useState({
    state: "loading",
    data: [],
  });

  const dispatch = useDispatch();

  const {subjects, grades, curriculums} = useStaticDataV3([
    "subjects",
    "grades",
    "curriculums",
  ]);

  useApiEffect(() => {
    upcomingSessions.state === "loaded" ||
      dispatch(
        fetchTutorUpcomingSession({
          limit: 5,
          onSuccess: (data) =>
            setUpcomingSessions({state: "loaded", data: data}),
          onFailure: () => setUpcomingSessions({state: "error", data: []}),
        })
      );
  }, []);

  const columns = [
    {
      isDummy: true,
      dataField: "data",
      text: "Date & Time",
      formatter: (_, row) => (
        <div className="text-secondary">
          <p className="mb-0">
            {getAdjustedTime(row.start_time, "HH:mm:ss").format("hh:mm A")}
          </p>
          <p className="mb-0">
            {moment(row.date, "YYYY-MM-DD").format("DD, MMM YYYY")}
          </p>
        </div>
      ),
    },
    {
      isDummy: true,
      dataField: "agenda",
      text: "Session Details",
      formatter: (_, row) => (
        // <div>
        //   <p className="font-size-16 fw-normal">{row.agenda}</p>

        // </div>
        <div>
          <p className="text-secondary mb-0">{row.agenda}</p>
          {/* <ul className=" mb-0 p-0 d-flex text-muted list-dots">
            {subjects.state === "loaded" && (
              <li>{getStaticData(row.session.subject_id, subjects.data)}</li>
            )}
            {grades.state && (
              <li>
                {" "}
                {getStaticData(row.session.grade_id.split(",")[0], grades.data)}
              </li>
            )}
            {curriculums.state && (
              <li>
                {getStaticData(
                  row.session.curriculum_id.split(",")[0],
                  curriculums.data
                )}
              </li>
            )}
          </ul> */}
        </div>
      ),
    },
    {
      isDummy: true,
      dataField: "id",
      text: "Learner's Name",
      formatter: (_, row) => (
        <div className="hstack gap-3">
          {/* <Avatar
      alt="Learner Image"
      src={
        row.sent_to.user_type === 'learner' ? row.sent_to.profile_picture : row.sent_by.profile_picture
      }
      className="avatar-xs "
    /> */}
          <div>{row.name.substring(0, 30)}</div>
        </div>
      ),
    },
    {
      isDummy: true,
      dataField: "id",
      text: "",
      formatter: (_, row) => {
        return (
          <div className="hstack gap-2">
            <button
              type="button"
              role="tooltip"
              aria-label={row.status === 2 ? "Join Session" : "Can't Be Joined"}
              data-microtip-position="left"
              className={`p-1 border-1 rounded ${
                row.status === 2
                  ? "bg-gray-100 text-success hover:bg-gray-300 focus:bg-gray-300"
                  : "bg-gray-400 text-danger"
              } `}
              disabled={row.status !== 2}
              onClick={
                row.status === 2
                  ? () =>
                      window.location.assign(
                        getMeetingLink(
                          row.meeting.meeting_id,
                          row.meeting.meeting_type.type
                        )
                      )
                  : () => {}
              }
            >
              <IconVideoPlus size={16} />
            </button>
            <Link
              as="button"
              role="tooltip"
              aria-label="View Details"
              data-microtip-position="left"
              className={`p-1 border-1 rounded bg-gray-100 hover:bg-gray-300 focus:bg-gray-300 text-secondary `}
              to={RTutorTutoring.getSessionDetailsView(row.session_id, row.id)}
            >
              <IconEye size={16} />
            </Link>
          </div>
        );
      },
    },
  ];

  if (
    hasSessionBundles &&
    (grades.state === "loading" ||
      subjects.state === "loading" ||
      curriculums.state === "loading" ||
      upcomingSessions.state === "loading")
  ) {
    return <Loader />;
  }

  return (
    <div>
      {!hasSessionBundles ? (
        <div className="p-5 d-flex flex-column justify-content-center align-items-center">
          <div className="rounded-3 p-3 bg-soft-success">
            <IconVideo />
          </div>
          <div className="text-secondary fw-semibold mt-3 text-center">
            <p className="fw-bold">You have not created any service, </p>
            <p className="fw-bold">
              By{" "}
              <Link to={RTutorTutoring.createService} className="text-info">
                Creating Service
              </Link>
              , you can start earning as an Online Tutor!
              {/* <h3 className="d-inline-block fw-bolder"></h3> */}
            </p>
          </div>
        </div>
      ) : (
        <div>
          {grades.state === "erred" ||
          subjects.state === "erred" ||
          curriculums.state === "erred" ||
          upcomingSessions.state === "erred" ? (
            <SassyAlert
              color="danger"
              title="We've encountered and error while loading your upcoming sessions."
            />
          ) : upcomingSessions.data.length === 0 ? (
            <NoUpComingSessions />
          ) : (
            <>
              <SimpleBar className="overflow-auto">
                <BootstrapTable
                  {...props}
                  columns={columns}
                  // TODO :
                  data={upcomingSessions.data.slice(0, 5)}
                  striped={false}
                  bordered={false}
                  keyField="id"
                  // wrapperClasses="overflow-auto"
                  headerWrapperClasses="table-light"
                  classes="table align-middle table-nowrap table-check mb-0 text-secondary"
                />
              </SimpleBar>

              <div className="p-3">
                <Link
                  className="btn btn-secondary"
                  to={RTutorTutoring.sessionsBaseView}
                >
                  View More
                </Link>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default DashboardTutorUpcomingSessions;
