import {IconNotebook} from "@tabler/icons";
import {Icon} from "components";
import React from "react";

const BorderedNotebookIcon = ({size = "lg"}) => {
  return (
    <Icon.Rounded
      size={size}
      className={`border bg-soft-warning border-warning text-warning`}
    >
      <IconNotebook />
    </Icon.Rounded>
  );
};

export default BorderedNotebookIcon;
