import React from "react";
import {Link} from "react-router-dom";
import {
  hexToRgba,
  getCalendarListingIcon,
  getCalendarListingColor,
} from "utils";
import {AdaptedLearnerCalendarSession} from "constants/";
import {RLearnerTutoring} from "constants/learner-views-routes";

//  export const toggleSettingBar = () => {
//     setSettingBar((prevState) => !prevState);

//     if (settingBar) {
//       document.body.classList.add("right-bar-enabled");
//     } else {
//       document.body.classList.remove("right-bar-enabled");
//     }
//   };

export const eventTemplate = (props: AdaptedLearnerCalendarSession) => {
  // const sTime = moment(props.StartTime).format("hh:mm");
  // const eTime = moment(props.EndTime).format("hh:mm");

  return (
    <div
      className="template-wrapper"
      style={{
        backgroundColor: `${hexToRgba(
          getCalendarListingColor(props.RequestStatus)
        )}`,
      }}
    >
      <div className="d-flex align-items-center">
        <p className="m-0 text-secondary fs-7 fw-bold flex-grow-1 text-truncate">
          {props.Subject}
        </p>
        <span
          className="session-type-icon-box-sm"
          style={{
            backgroundColor: `${getCalendarListingColor(props.RequestStatus)}`,
          }}
        >
          {getCalendarListingIcon(props.RequestStatus)}
        </span>
      </div>
      {/* <p className="subject text-break mb-1">{props.Subject}</p>
      <div className="time-container mb-1">
        <span className="time-box">
          {sTime} - {eTime}
        </span>
      </div>
      <p className="tutor-name mb-1">{props.TutorName}</p>
      <span className="event-icon-box">
        <span
          className="session-type-icon-box"
          style={{
            backgroundColor: `${getCalendarListingColor(props.RequestStatus)}`,
          }}
        >
          {getCalendarListingIcon(props.RequestStatus)}
        </span>
      </span> */}
    </div>
  );
};

export const onCellClick = (args: {cancel: boolean}) => {
  args.cancel = true;
};

export const footerTemplate = (props: AdaptedLearnerCalendarSession) => {
  return (
    <div className="quick-info-footer">
      <div className="event-footer d-flex justify-content-between align-items-center">
        <div className="text-start">
          <span>Tutor: </span>
          <h5>{props.TutorName}</h5>
        </div>
        <Link
          to={
            props.RequestStatus === 5
              ? RLearnerTutoring.getPastSessionDetailsView(
                  props.SessionId,
                  props.RequestId
                )
              : props.IsUpcoming
              ? RLearnerTutoring.getUpcomingSessionDetailsView(
                  props.SessionId,
                  props.RequestId
                )
              : RLearnerTutoring.getPastSessionDetailsView(
                  props.SessionId,
                  props.RequestId
                )
          }
          className="btn btn-secondary"
        >
          More Details
        </Link>

        {/* {props.RequestStatus === 5 ? (
          <Alert color="danger">Cancelled</Alert>
        ) : (
          <>
            <div className="text-start">
              <span>Tutor: </span>
              <h5>{props.TutorName}</h5>
            </div>
            {props.IsUpcoming ? (
              <Link
                to={RLearnerTutoring.getUpcomingSessionDetailsView(props.Id)}
                className="btn btn-secondary"
              >
                More Details
              </Link>
            ) : (
              <Link
                to={RLearnerTutoring.getPastSessionDetailsView(props.Id)}
                className="btn btn-secondary"
              >
                More Details
              </Link>
            )}
          </>
        )} */}
      </div>
    </div>
  );
};
