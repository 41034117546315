import * as React from "react";
import { Loader } from "components";

const View: React.LazyExoticComponent<() => JSX.Element> = React.lazy(
  () => import("./tutoring-learner-sessions")
);

const TutoringLearnerSessions = () => (
  <React.Suspense fallback={<Loader />}>
    <View />
  </React.Suspense>
);

export default TutoringLearnerSessions;
