import {API_MAIN_URL} from "constants/env";

/**
 * @typedef {Object} StaticGrade
 *
 * @property {number} id
 * @property {string} grade
 */

/**
 * @typedef {Object} StaticSubject
 *
 * @property {number} id
 * @property {string} subject
 */

/**
 * @typedef { "SET_GRADES"
 * | "SET_SUBJECTS"
 * | "SET_CURRICULUMS"
 * | "SET_REQUEST_TYPES"
 * | "ERRED_STATIC_DATA"
 * | "LOADED_STATIC_DATA"
 * } StaticDataActionType
 */

/**
 * @typedef { "SET_GRADES"
 * | "ERRED_GRADES"
 * | "SET_DEGREES"
 * | "ERRED_DEGREES"
 * | "SET_SUBJECTS"
 * | "ERRED_SUBJECTS"
 * | "SET_LANGUAGES"
 * | "ERRED_LANGUAGES"
 * | "SET_CURRICULUMS"
 * | "ERRED_CURRICULUMS"
 * | "SET_REQUEST_TYPES"
 * | "ERRED_REQUEST_TYPES"
 * | "SET_LEARNING_STYLES"
 * | "ERRED_LEARNING_STYLES
 * | "SET_MATERIAL_TYPES"
 * | "ERRED_MATERIAL_TYPES
 * | "SET_QUALIFICATIONS"
 * | "ERRED_QUALIFICATIONS"
 * } StaticDataV2ActionType
 */

/**
 * @typedef {Object} StaticDataAction
 *
 * @property {any} payload
 * @property {StaticDataActionType} type
 */

/**
 * @typedef {Object} StaticDataV2Action
 *
 * @property {{
 * data: any,
 * erred: boolean,
 * key: "grades"
 * | "degrees"
 * | "subjects"
 * | "languages"
 * | "curriculums"
 * | "requestTypes"
 * | "materialTypes"
 * | "learningStyles"
 * | "qualifications"
 * }} payload
 * @property {StaticDataV2ActionType} type
 */

/**
 * A record of metadata required to request a static data type.
 * @type {Record<StaticDataV2Action['payload']['key'], {
 * url: string,
 * method: undefined | "post"
 * actionType: StaticDataV2ActionType
 * }>}
 */
const staticDataV2QueryType = {
  grades: {
    method: "post",
    actionType: "GRADES",
    url: `${API_MAIN_URL}/content/grades-list`,
  },
  degrees: {
    actionType: "DEGREES",
    url: `${API_MAIN_URL}/content/degrees-list`,
  },
  goals: {
    method: "post",
    actionType: "GOALS",
    url: `${API_MAIN_URL}/content/goals-list`,
  },
  subjects: {
    method: "post",
    actionType: "SUBJECTS",
    url: `${API_MAIN_URL}/content/subjects-list`,
  },
  skills: {
    actionType: "SKILLS",
    url: `${API_MAIN_URL}/content/skills-list`,
  },
  languages: {
    method: "post",
    actionType: "LANGUAGES",
    url: `${API_MAIN_URL}/content/languages-list`,
  },
  curriculums: {
    method: "post",
    actionType: "CURRICULUMS",
    url: `${API_MAIN_URL}/content/curriculums-list`,
  },
  requestTypes: {
    actionType: "REQUEST_TYPES",
    url: `${API_MAIN_URL}/tutor/request-types-list`,
  },
  materialTypes: {
    actionType: "MATERIAL_TYPES",
    url: `${API_MAIN_URL}/content/learning-material-types-list`,
  },
  learningStyles: {
    method: "post",
    actionType: "LEARNING_STYLES",
    url: `${API_MAIN_URL}/content/learning-styles`,
  },
  qualifications: {
    actionType: "QUALIFICATIONS",
    url: `${API_MAIN_URL}/content/qualifications-list`,
  },
  interests: {
    method: "post",
    actionType: "INTERESTS",
    url: `${API_MAIN_URL}/content/interests-list`,
  },
};

export {staticDataV2QueryType};
