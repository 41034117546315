import { Label } from "reactstrap";

/**
 * @param {JSX.IntrinsicElements['label']} props
 * @returns {JSX.Element}
 */
function SassyFormLabel({ className = "", children, htmlFor, ...props }) {
  return (
    <Label
      {...props}
      htmlFor={htmlFor}
      data-testclass="sassyLabel"
      data-testid={`${htmlFor}Label`}
      className={`text-blue  ${className} `}
    >
      {children}
    </Label>
  );
}

export default SassyFormLabel;
