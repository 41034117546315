import ReactSelect from "react-select";
import CreatableSelect from "react-select/creatable";
import makeAnimated from "react-select/animated";
import { Label } from "reactstrap";

// CSS.
import "./select.scss";

/**
 * A react-select based select component.
 * Introduces defaults and some fixes such as
 * the options taking half the length of the parent.
 * @param {Object} object The properties
 * passed to the component.
 * @param {boolean} object.isMulti Whether
 * multiple options can be selected or not.
 * @param {Array} object.options The selectables.
 * @param {Array} object.selectedOptions The
 * options that have been selected by the user.
 * @param {(option: {label: string, value: any}) => void} object.onSelect
 * The handler for the selection of an option.
 * @param {string} object.label The label of the component.
 * @param {string} object.subTitle The smaller text
 * right next to the label.
 * @param {*} object.description The stuff right below
 * the select component.
 * @param {number} object.maxSelections The maximum
 * selections that can be performed.
 * @param {boolean} object.creatable Whether the select
 * component should allow user-defined options or not.
 * @param {boolean} object.disabled Whether the select
 * is disabled or not.
 * @param {boolean} object.closeMenuOnSelect Whether to
 * close the select menu when an item is selected or not.
 * @returns {JSX.Element}
 *
 * @version 0.0.3
 * @author kashan-ahmad
 *
 * @changelog
 * - 0.0.4: Added the `isClearable` prop.
 * - 0.0.3: Added the `closeMenuOnSelect` prop.
 * - 0.0.2: Made `label` optional.
 * - 0.0.1: Initial version.
 */
function Select({
  isMulti = true,
  options,
  label,
  onSelect,
  subTitle,
  isClearable,
  description,
  selectedOptions,
  creatable = false,
  maxSelections = 100,
  disabled = false,
  closeMenuOnSelect = false,
  className = "",
  ...props
}) {
  const Component = creatable ? CreatableSelect : ReactSelect;

  return (
    <div className={`templating-select select2-container ${className}`}>
      {label && (
        <Label className="control-label font-size-13 text-secondary-dim">
          {label}{" "}
          {subTitle && (
            <span className="font-size-10" style={{ color: "#8CA6C0" }}>
              {subTitle}
            </span>
          )}
        </Label>
      )}
      <Component
        {...props}
        isMulti={isMulti}
        options={options}
        isClearable={isClearable || false}
        onChange={onSelect}
        isDisabled={disabled}
        value={selectedOptions}
        components={makeAnimated()}
        classNamePrefix="select2-selection"
        closeMenuOnSelect={closeMenuOnSelect}
        isOptionDisabled={() => selectedOptions?.length >= maxSelections}
      />
      {description && (
        <div className="font-size-12 text-secondary-dim my-2">
          {description}
        </div>
      )}
    </div>
  );
}

export default Select;
