// Exporting learner views routes getters to use in Links based on routes structure defined in src/router/_get-learner-routes.js
const RLearnerTutoring = {
  baseRoute: "/dashboard",
  landingView: "/dashboard/",
  trendingTutorsView: "/1-1-tutoring",

  getSessionsView: (type: "upcoming" | "all" | "missed" = "upcoming") =>
    `/dashboard/sessions?type=${type}`,
  getUpcomingSessionDetailsView: (
    sessionId: string | number,
    requestId: string | number
  ) => `/dashboard/sessions/request/upcoming/${sessionId}/req/${requestId}`,
  getSessionRescheduleView: (
    sessionId: string | number,
    requestId: string | number
  ) => `/dashboard/sessions/request/reschedule/${sessionId}/req/${requestId}`,
  getPastSessionDetailsView: (
    sessionId: string | number,
    requestId: string | number
  ) => `/dashboard/sessions/request/history/${sessionId}/req/${requestId}`,
  getTutorAvailableSessionsView: (tutorUserId: string | number) =>
    `/dashboard/sessions/new/tutor/${tutorUserId}`,

  // baseRoute: "/dashboard",
  // landingView: `/dashboard/`,
  // getSessionsView: (type: "upcoming" | "all" = "upcoming") =>
  //   `/dashboard/upcoming-sessions?type=${type}`,
  // getUpcomingSessionDetailsView: (requestId: string | number) =>
  //   `/dashboard/upcoming-sessions/details/${requestId}`,
  // TODO: fix link when uncommenting

  // getSessionRescheduleView: (requestId: string | number) =>
  //   `/dashboard/upcoming-sessions/reschedule/${requestId}`,
  // getPastSessionDetailsView: (requestId: string | number) =>
  //   `/dashboard/sessions-history/${requestId}`,
  // getTutorAvailableSessionsView: (tutorUserId: string | number) =>
  //   `/dashboard/new-session/tutor/${tutorUserId}`,
};

export { RLearnerTutoring };
