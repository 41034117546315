import {IconNotebook} from "@tabler/icons";
import {useStaticDataV3Selector} from "store/store-static-v3";

/**
 * @typedef {Object} NotificationDetailsProps
 *
 * @property {number} subjectId
 * @property {number[]} gradeIds
 * @property {number[]} curriculumIds
 */

/**
 * Simple notification details that can be included for extra aesthetics.
 * @param {NotificationDetailsProps} props
 * @returns {JSX.Element}
 *
 * @author kashan-ahmad
 *
 * @version 0.0.2
 *
 * @changelog
 * - 0.0.2: Updated gradeId and curriculumId properties to support an array of
 * IDs.
 * - 0.0.1: Initial version.
 */
function NotificationDetails({gradeIds, subjectId, curriculumIds}) {
  // The data must be requested elsewhere.
  const {grades, subjects, curriculums} = useStaticDataV3Selector(
    (state) => state
  );

  // Guard clause.
  if (!gradeIds || !subjectId || !curriculumIds) return null;

  if ([grades.state, subjects.state, curriculums.state].includes("loaded")) {
    const subject = subjects.data.get(parseInt(subjectId));

    /**
     * Loop-able grades.
     */
    const saneGrades = gradeIds.map((id) => grades.data.get(parseInt(id)));

    /**
     * Loop-able curriculums.
     */
    const saneCurriculums = curriculumIds.map((id) =>
      curriculums.data.get(parseInt(id))
    );

    // Guard clause.
    if (!subject || !saneGrades[0] || !saneCurriculums[0]) return null;

    /**
     * Once in a while you give them a little slap.
     * @param {string} string The string to adjust.
     * @returns {string}
     */
    function makeDisplayable(string) {
      return string.split(" ")[0];
    }

    return (
      <div className="hstack gap-1 text-primary fw-semi-bold">
        <IconNotebook className="flex-shrink-0" />
        <div>
          {/* Subjects and curriculums are, sometimes, too long for the design. */}
          {makeDisplayable(subject)} ({saneGrades[0]},{" "}
          {makeDisplayable(saneCurriculums[0])})
        </div>
      </div>
    );
  }

  // Backup clause.
  return null;
}

export default NotificationDetails;
