import * as React from "react";
import { Loader } from "components";
import { WrapperLayout } from "layout";
import { usePaymentMethods } from "hooks";
import { useTransactions } from "hooks/transactions";
import { Navigate, useMatch } from "react-router-dom";

// Features.
import TransactionsRootView from "./view-transactions-root";
import TransactionsEmptyView from "./view-transactions-empty";

export default function TransactionsView() {
  const isDashboard = useMatch("/transactions");

  /// Block: Fetch the required data.
  const transactions = useTransactions();
  const paymentMethods = usePaymentMethods();

  /// Block: Check if the user has paid something.
  const { total: totalTransactions, state: transactionsState } = transactions;

  if (transactionsState === "erred") return <Navigate to="/400" />;

  if (transactionsState === "loading") return <Loader />;

  /// Block: Check if the user has added at least 1 payment method.
  const {
    data: paymentMethodsList,
    isErred: arePaymentMethodsErred,
    isLoaded: arePaymentMethodsLoaded,
  } = paymentMethods;

  if (arePaymentMethodsErred) return <Navigate to="/400" />;

  if (!arePaymentMethodsLoaded) return <Loader />;

  const hasPaymentMethods =
    Array.isArray(paymentMethodsList) && paymentMethodsList.length > 0;

  /// Block: Rendering based on a list of conditions.
  return (
    <WrapperLayout>
      <WrapperLayout.Main>
        {/* If the user hasn't paid anything AND they haven't added a payment method AND they're accessing the dashboard. */}
        {!!!totalTransactions && !hasPaymentMethods && isDashboard ? (
          <TransactionsEmptyView />
        ) : (
          <TransactionsRootView />
        )}
      </WrapperLayout.Main>
    </WrapperLayout>
  );
}
