import { useState } from "react";
import { Row, Col, Label, FormGroup, Input } from "reactstrap";
import Select from "react-select";
import { Form, Formik, Field, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  FormErrorMessage,
  IToolTip,
  OverlayError,
  SassyCard,
  SassyCardBody,
  DatePicker,
} from "components";
import { addTutorBank, getCountryZoneCodes } from "store";
import {
  getInitialValues,
  onBankAddFailure,
  onBankAddSuccess,
  selectsOptions,
  validationSchema,
} from "./tutor-bank-add-utils";
import { useApiEffect, useBanks } from "hooks";
import { messages, prompts } from "constants/strings";
import { parseMessage } from "utils";
import ToastFactory from "utils/toast-factory";
import {
  MAX_LIMIT_NAME,
  MAX_LIMIT_TITLES,
  MAX_LIMIT_DESCRIPTIONS,
  MAX_BANKS,
  DUMMIES_MONTHS_NUMERIC,
} from "constants/";

function TutorBankAdd({ afterSuccess, afterFailure }) {
  /*
    Detailed Flow of Code: 
    1. Importing all the required components and hooks.
    2. Creating a function to get the initial values of the form.
    3. Creating a function to handle the success of the form submission.
    4. Creating a function to handle the failure of the form submission.
    5. Creating a function to handle the submission of the form.
    6. Creating a function to handle the change of the date.
    7. Creating a function to handle the change of the country.
    8. Creating a function to handle the change of the currency.
    9. Creating a function to handle the change of the account type.
    10. Creating a function to handle the change of the place of birth country.
    11. Creating a function to handle the change of the bank account type.
    12. Creating a function to handle the change of the zone code.
   */

  const dispatch = useDispatch();
  const toastFactory = ToastFactory();

  const {
    email: tutorEmail,
    name: tutorName,
    dob: dateofbirth,
  } = useSelector((state) => state.tutorDashboard.userInfo.user);

  // DatePicker States
  const [selectedDay, setSelectedDay] = useState({
    label: dateofbirth.split("-")[2],
    value: dateofbirth.split("-")[2],
  });
  const [selectedMonth, setSelectedMonth] = useState(
    DUMMIES_MONTHS_NUMERIC.filter(
      (m) => m.value === parseInt(dateofbirth.split("-")[1])
    )[0]
  );
  const [selectedYear, setSelectedYear] = useState({
    label: dateofbirth.split("-")[0],
    value: dateofbirth.split("-")[0],
  });
  const [isValidDate, setIsValidDate] = useState(undefined);
  const selectedDate = `${selectedYear.value}-${(
    "0" + selectedMonth.value
  ).slice(-2)}-${("0" + selectedDay.value).slice(-2)}`;
  // ----

  const { isLoaded, isErred, data: banks } = useBanks();

  const [zoneCodes, setZoneCodes] = useState({
    status: "",
    data: [],
  });
  const [selectedCountry, setSelectedCountry] = useState();

  const onSubmit = (values, { setFieldError }) => {
    if (banks.length >= MAX_BANKS) {
      toastFactory.error(
        parseMessage(messages.ERR_LIMIT_REACHED(MAX_BANKS, "banks"))
      );
      return;
    }
    toastFactory.loading();
    const bankData = {
      ...values,
      country: values.country.value,
      currency: values.currency.value,
      accountType: values.accountType.value,
      placeOfBirthCountry: values.placeOfBirthCountry.value,
      bankAccountType: values.bankAccountType.value,
      zoneCode:
        typeof values.zoneCode === "string"
          ? values.zoneCode
          : values.zoneCode.value,
      // zoneCode: values.country.value,
    };
    delete bankData.agreement;

    if (values.country.value === "IN") bankData["dateOfBirth"] = selectedDate;

    dispatch(
      addTutorBank({
        bankData,
        onSuccess: (response) => onBankAddSuccess({ response, afterSuccess }),
        onFailure: (error) =>
          onBankAddFailure({
            error,
            afterFailure,
            setErredField: setFieldError,
          }),
      })
    );
  };

  useApiEffect(() => {
    if (selectedCountry && selectedCountry.value) {
      // add loading states
      getCountryZoneCodes({
        countryCode: selectedCountry.value,
        onSuccess: (res) => {
          setZoneCodes({ data: res.data, status: "success" });
        },
        onFailure: (error) => {
          setZoneCodes({ status: "erred", data: [] });
        },
      });
    }
  }, [selectedCountry]);

  return (
    <SassyCard className="position-relative">
      <SassyCardBody>
        <Formik
          initialValues={getInitialValues({ tutorEmail, tutorName })}
          enableReinitialize
          {...{ onSubmit, validationSchema }}
        >
          {({ values, handleBlur, setFieldValue, validateForm }) => (
            <Form>
              <h3 className="fs-4 fw-bold">Add Bank Details</h3>

              <Col xl="10">
                <Row className="mt-3">
                  <Col sm="6" className="mb-3" lg="6">
                    <FormGroup>
                      <Label htmlFor="" className="text-blue">
                        Bank Name
                      </Label>
                      <Field
                        as={Input}
                        name="bankName"
                        placeholder="Enter Bank Name"
                        maxLength={120}
                      />
                      <ErrorMessage
                        name="bankName"
                        render={(message) => (
                          <FormErrorMessage {...{ message }} />
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="6" className="mb-3" lg="6">
                    <FormGroup>
                      <Label htmlFor="" className="text-blue">
                        Branch Name
                      </Label>
                      <Field
                        as={Input}
                        name="branchName"
                        placeholder="Enter Branch Name"
                        maxLength={120}
                      />
                      <ErrorMessage
                        name="branchName"
                        render={(message) => (
                          <FormErrorMessage {...{ message }} />
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="6" className="mb-3" lg="6">
                    <FormGroup>
                      <Label htmlFor="" className="text-blue">
                        Personal or Business Account?
                        <IToolTip
                          position="top"
                          content={prompts.BANK_ACCOUNT_TYPE}
                        />
                      </Label>
                      <Select
                        options={selectsOptions.accountTypeOptions}
                        isMulti={false}
                        isClearable={false}
                        name="accountType"
                        onChange={(val) => setFieldValue("accountType", val)}
                        onBlur={handleBlur}
                        value={values.accountType}
                      />

                      <ErrorMessage
                        name="accountType"
                        render={(message) => (
                          <FormErrorMessage {...{ message }} />
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="6" className="mb-3" lg="6">
                    <FormGroup>
                      <Label htmlFor="" className="text-blue">
                        Account Type
                      </Label>
                      <Select
                        options={selectsOptions.bankAccountTypeOptions}
                        isMulti={false}
                        isClearable={false}
                        name="bankAccountType"
                        onChange={(val) =>
                          setFieldValue("bankAccountType", val)
                        }
                        onBlur={handleBlur}
                        value={values.bankAccountType}
                      />

                      <ErrorMessage
                        name="bankAccountType"
                        render={(message) => (
                          <FormErrorMessage {...{ message }} />
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="6" className="mb-3" lg="5">
                    <FormGroup>
                      <Label htmlFor="" className="text-blue">
                        Currency
                      </Label>
                      <Select
                        options={JSON.parse(selectsOptions.currencyOptions)}
                        isMulti={false}
                        isClearable={false}
                        name="currency"
                        onChange={(val) => setFieldValue("currency", val)}
                        onBlur={handleBlur}
                        value={values.currency}
                      />

                      <ErrorMessage
                        name="currency"
                        render={(message) => (
                          <FormErrorMessage {...{ message }} />
                        )}
                      />
                    </FormGroup>
                  </Col>

                  <Col sm="6" className="mb-3" lg="7">
                    <FormGroup>
                      <Label htmlFor="" className="text-blue">
                        Account Number
                        <IToolTip position="top" content={prompts.IBAN} />
                      </Label>
                      <Field
                        as={Input}
                        name="accountNumber"
                        placeholder="Enter Account Number or IBAN Number"
                        maxLength={MAX_LIMIT_NAME}
                      />
                      <ErrorMessage
                        name="accountNumber"
                        render={(message) => (
                          <FormErrorMessage {...{ message }} />
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="6" className="mb-3" lg="4">
                    <FormGroup>
                      <Label htmlFor="" className="text-blue">
                        Routing Number
                        <IToolTip
                          position="top"
                          content={prompts.ROUTING_NUMBER}
                        />
                      </Label>
                      <Field
                        as={Input}
                        name="routingNumber"
                        placeholder="Enter Routing Number"
                        maxLength={MAX_LIMIT_NAME}
                      />
                      <ErrorMessage
                        name="routingNumber"
                        render={(message) => (
                          <FormErrorMessage {...{ message }} />
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="6" className="mb-3" lg="4">
                    <FormGroup>
                      <Label htmlFor="" className="text-blue ">
                        Bank Swift BIC
                      </Label>
                      <Field
                        as={Input}
                        name="bankSwiftBic"
                        placeholder="Enter Bank Swift BIC"
                        maxLength={MAX_LIMIT_NAME}
                      />
                      <ErrorMessage
                        name="bankSwiftBic"
                        render={(message) => (
                          <FormErrorMessage {...{ message }} />
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="6" className="mb-3" lg="4">
                    <FormGroup>
                      <Label htmlFor="" className="text-blue">
                        Bank's Zip / Postal Code
                      </Label>
                      <Field
                        as={Input}
                        name="postalCode"
                        placeholder="Enter Bank's Zip / Postal Code"
                        maxLength={MAX_LIMIT_NAME}
                      />
                      <ErrorMessage
                        name="postalCode"
                        render={(message) => (
                          <FormErrorMessage {...{ message }} />
                        )}
                      />
                    </FormGroup>
                  </Col>

                  <Col sm="6" className="mb-3" lg="4">
                    <FormGroup>
                      <Label htmlFor="" className="text-blue">
                        Branch Country
                        <IToolTip position="top" content={prompts.BANK_INDIA} />
                      </Label>
                      <Select
                        options={JSON.parse(selectsOptions.countryOptions)}
                        isMulti={false}
                        isClearable={false}
                        name="country"
                        onChange={(val) => {
                          setSelectedCountry(val);
                          setFieldValue("country", val);
                        }}
                        onBlur={handleBlur}
                        value={values.country}
                      />

                      <ErrorMessage
                        name="country"
                        render={(message) => (
                          <FormErrorMessage {...{ message }} />
                        )}
                      />
                    </FormGroup>
                  </Col>

                  <Col sm="6" className="mb-3" lg="4">
                    <FormGroup>
                      <Label htmlFor="" className="text-blue">
                        Branch State / Province
                      </Label>
                      <Field
                        as={Input}
                        name="state"
                        placeholder="Enter Branch State / Province"
                        maxLength={MAX_LIMIT_TITLES}
                      />
                      <ErrorMessage
                        name="state"
                        render={(message) => (
                          <FormErrorMessage {...{ message }} />
                        )}
                      />
                    </FormGroup>
                  </Col>

                  <Col sm="6" className="mb-3" lg="4">
                    <FormGroup>
                      <Label htmlFor="" className="text-blue">
                        Branch City
                      </Label>
                      <Field
                        as={Input}
                        name="city"
                        placeholder="Enter Branch City "
                        maxLength={MAX_LIMIT_TITLES}
                      />
                      <ErrorMessage
                        name="city"
                        render={(message) => (
                          <FormErrorMessage {...{ message }} />
                        )}
                      />
                    </FormGroup>
                  </Col>

                  <Col sm="6" className="mb-3" lg="4">
                    <FormGroup>
                      <Label htmlFor="" className="text-blue">
                        Zone Code
                      </Label>

                      {["erred", ""].includes(zoneCodes.status) ? (
                        <Field
                          as={Input}
                          name="zoneCode"
                          placeholder="Enter your Zone Code"
                          maxLength={MAX_LIMIT_TITLES}
                        />
                      ) : (
                        <Select
                          options={zoneCodes.data.map((zc) => ({
                            label: zc.name,
                            value: zc.code,
                          }))}
                          isMulti={false}
                          isClearable={false}
                          name="zoneCode"
                          onChange={(val) => setFieldValue("zoneCode", val)}
                          onBlur={handleBlur}
                          value={values.zoneCode}
                        />
                      )}

                      <ErrorMessage
                        name="zoneCode"
                        render={(message) => (
                          <FormErrorMessage {...{ message }} />
                        )}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Col>

              <h3 className="fs-4 fw-bold">Add Personal Details</h3>
              <Col xl="10">
                <Row className="mt-3">
                  <Col sm="6" className="mb-3" lg="4">
                    <FormGroup>
                      <Label htmlFor="" className="text-blue">
                        First Name
                        <IToolTip
                          position="top"
                          content={prompts.BANK_FIRST_NAME}
                        />
                      </Label>
                      <Field
                        as={Input}
                        name="firstName"
                        placeholder="Enter First name "
                        maxLength={MAX_LIMIT_NAME}
                      />
                      <ErrorMessage
                        name="firstName"
                        render={(message) => (
                          <FormErrorMessage {...{ message }} />
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="6" className="mb-3" lg="4">
                    <FormGroup>
                      <Label htmlFor="lastName" className="text-blue">
                        Last Name
                        <IToolTip
                          position="top"
                          content={prompts.BANK_LAST_NAME}
                        />
                      </Label>
                      <Field
                        as={Input}
                        name="lastName"
                        placeholder="Enter Last Name "
                        maxLength={MAX_LIMIT_NAME}
                      />
                      <ErrorMessage
                        name="lastName"
                        render={(message) => (
                          <FormErrorMessage {...{ message }} />
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="6" className="mb-3" lg="4">
                    <FormGroup>
                      <Label htmlFor="" className="text-blue">
                        Email Address
                        <IToolTip position="top" content={prompts.BANK_MAIL} />
                      </Label>
                      <Field
                        as={Input}
                        name="email"
                        placeholder="Enter Email Address"
                        maxLength={MAX_LIMIT_TITLES}
                      />
                      <ErrorMessage
                        name="email"
                        render={(message) => (
                          <FormErrorMessage {...{ message }} />
                        )}
                      />
                    </FormGroup>
                  </Col>

                  <Col sm="6" className="mb-3" lg="4">
                    <FormGroup>
                      <Label htmlFor="" className="text-blue">
                        Place Of Birth - Region
                        {values.country.value !== "IN" && (
                          <span className="text-muted font-size-10 ms-2">
                            (Optional)
                          </span>
                        )}
                      </Label>
                      <Field
                        as={Input}
                        name="placeOfBirthRegion"
                        placeholder="Enter Place Of Birth - Region"
                        maxLength={MAX_LIMIT_TITLES}
                      />
                      <ErrorMessage
                        name="placeOfBirthRegion"
                        render={(message) => (
                          <FormErrorMessage {...{ message }} />
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="6" className="mb-3" lg="4">
                    <FormGroup>
                      <Label htmlFor="" className="text-blue">
                        Place Of Birth - Country
                        {values.country.value !== "IN" && (
                          <span className="text-muted font-size-10 ms-2">
                            (Optional)
                          </span>
                        )}
                      </Label>
                      <Select
                        options={JSON.parse(selectsOptions.countryOptions)}
                        isMulti={false}
                        isClearable={false}
                        name="placeOfBirthCountry"
                        onChange={(val) =>
                          setFieldValue("placeOfBirthCountry", val)
                        }
                        onBlur={handleBlur}
                        value={values.placeOfBirthCountry}
                      />
                      {/* <Field
                        as={Input}
                        name="placeOfBirthCountry"
                        placeholder="Enter Place Of Birth - Country"
                      /> */}
                      <ErrorMessage
                        name="placeOfBirthCountry"
                        render={(message) => (
                          <FormErrorMessage {...{ message }} />
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="6" className="mb-3" lg="4">
                    <FormGroup>
                      <Label htmlFor="" className="text-blue">
                        Phone Number
                        <IToolTip
                          position="top"
                          content={prompts.PHONE_NUMBER}
                        />
                      </Label>

                      <Field
                        as={Input}
                        name="phoneNumber"
                        placeholder="Enter Phone Number"
                        maxLength={MAX_LIMIT_NAME}
                      />
                      <ErrorMessage
                        name="phoneNumber"
                        render={(message) => (
                          <FormErrorMessage {...{ message }} />
                        )}
                      />
                    </FormGroup>
                  </Col>

                  <Col sm="6" className="mb-3" lg="6">
                    <FormGroup>
                      <Label htmlFor="" className="text-blue">
                        Address Line 1
                      </Label>
                      <Field
                        as={Input}
                        name="addressLine1"
                        placeholder="Enter Address Line 1 "
                        maxLength={MAX_LIMIT_DESCRIPTIONS}
                      />
                      <ErrorMessage
                        name="addressLine1"
                        render={(message) => (
                          <FormErrorMessage {...{ message }} />
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="6" className="mb-3" lg="6">
                    <FormGroup>
                      <Label htmlFor="" className="text-blue">
                        Address Line 2
                        <span className="text-muted font-size-10 ms-2">
                          (Optional)
                        </span>
                      </Label>
                      <Field
                        as={Input}
                        name="addressLine2"
                        placeholder="Enter Address Line 2 "
                        maxLength={MAX_LIMIT_DESCRIPTIONS}
                      />
                      <ErrorMessage
                        name="addressLine2"
                        render={(message) => (
                          <FormErrorMessage {...{ message }} />
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="6" className="mb-3" lg="6">
                    <FormGroup>
                      <Label htmlFor="" className="text-blue">
                        Personal Tax ID
                        {values.accountType.value !== "PERSONAL" && (
                          <span className="text-muted font-size-10 ms-2">
                            (Optional)
                          </span>
                        )}
                      </Label>
                      <Field
                        as={Input}
                        name="personalTaxId"
                        placeholder="Enter Personal Tax ID"
                        maxLength={MAX_LIMIT_NAME}
                      />
                      <ErrorMessage
                        name="personalTaxId"
                        render={(message) => (
                          <FormErrorMessage {...{ message }} />
                        )}
                      />
                    </FormGroup>
                  </Col>

                  <Col sm="6" className="mb-3" lg="6">
                    <FormGroup>
                      <Label htmlFor="" className="text-blue">
                        Date Of Birth
                        {values.country.value !== "IN" && (
                          <span className="text-muted font-size-10 ms-2">
                            (Optional)
                          </span>
                        )}
                      </Label>
                      <DatePicker
                        max={moment().subtract(1, "days")}
                        selectedDate={selectedDate}
                        isValidSelection={isValidDate}
                        setValidation={setIsValidDate}
                      >
                        {[
                          <DatePicker.Day
                            id="inputDob"
                            value={selectedDay}
                            className="flex-grow-1"
                            onChange={(val) => setSelectedDay(val)}
                          />,
                          <DatePicker.Month
                            value={selectedMonth}
                            className="flex-grow-1"
                            onChange={(val) => setSelectedMonth(val)}
                          />,
                          <DatePicker.Year
                            value={selectedYear}
                            className="flex-grow-1"
                            onChange={(val) => setSelectedYear(val)}
                          />,
                        ]}
                      </DatePicker>
                    </FormGroup>
                  </Col>

                  <Col sm="6" className="mb-3" lg="6">
                    <FormGroup>
                      <Label htmlFor="" className="text-blue">
                        Business Name
                        {values.accountType.value !== "BUSINESS" && (
                          <span className="text-muted font-size-10 ms-2">
                            (Optional)
                          </span>
                        )}
                      </Label>
                      <Field
                        as={Input}
                        name="businessName"
                        placeholder="Enter Business Name"
                        maxLength={MAX_LIMIT_TITLES}
                      />
                      <ErrorMessage
                        name="businessName"
                        render={(message) => (
                          <FormErrorMessage {...{ message }} />
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="6" className="mb-3" lg="6">
                    <FormGroup>
                      <Label htmlFor="" className="text-blue">
                        Business Tax ID
                        {values.accountType.value !== "BUSINESS" && (
                          <span className="text-muted font-size-10 ms-2">
                            (Optional)
                          </span>
                        )}
                      </Label>
                      <Field
                        as={Input}
                        name="businessTaxId"
                        placeholder="Enter Business Tax ID"
                        maxLength={MAX_LIMIT_NAME}
                      />
                      <ErrorMessage
                        name="businessTaxId"
                        render={(message) => (
                          <FormErrorMessage {...{ message }} />
                        )}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
              <Row>
                <Col className="text-left mb-4">
                  {/* <div className="">
                    <FormGroup>
                      <Label
                        htmlFor="agreement"
                        className="text-secondary d-flex gap-2"
                      >
                        <Field
                          as={Input}
                          type="checkbox"
                          name="agreement"
                          className="flex-shrink-0"
                        />
                        <span className="setup-payment-agree">
                          Agree to the TheTutor.me's{" "}
                          <a
                            href={`${process.env.REACT_APP_WEBSITE_URL}/terms-conditions`}
                            className="term"
                            style={{display: "inline"}}
                          >
                            <span className="text-primary text-decoration-underline terms-and-conditions-styles">
                              Terms & Conditions.
                            </span>
                          </a>
                        </span>
                      </Label>
                      <ErrorMessage
                        name="agreement"
                        render={(message) => (
                          <FormErrorMessage {...{message}} />
                        )}
                      />
                    </FormGroup>
                  </div> */}
                  <div className="mt-4">
                    <button
                      className="btn btn-secondary"
                      type="submit"
                      onClick={async () => {
                        const validationResult = await validateForm();

                        // onSubmit(values)
                      }}
                    >
                      Add Bank
                    </button>
                  </div>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </SassyCardBody>

      <OverlayError
        isPaddedBy={3}
        condition={isLoaded && !isErred && banks.length >= MAX_BANKS}
        error={messages.ERR_LIMIT_REACHED(MAX_BANKS, "banks")}
        description={messages.ERR_LIMIT_REACHED_DESC("banks")}
      />
    </SassyCard>
  );
}

export default TutorBankAdd;
