/* eslint-disable eqeqeq */
import { useState } from "react";

// Features.
import TutoringTutorBundles from "./tutoring-tutor-bundle-bundles";

// Components.
import {
  Col,
  Row,
  Button,
  FormGroup,
  Input,
  UncontrolledAccordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
  CardTitle,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  ListGroup,
} from "reactstrap";
import { Switch, FormErrorMessage, SassySelect, Select } from "components";

// Utils.
import { actionTypes, requirements } from "constants/strings";
import {
  DUMMIES_DURATION,
  TUTOR_MAX_SESSIONS,
  TUTOR_MIN_SESSION_FEE,
  TUTOR_MAX_SESSION_FEE,
} from "constants";
import { SassyCard } from "components/cards";
import { LeadingBadgesList, SmallBadgesList } from "components/lists";
import { ListItemUser } from "components/list-items";
import { useUser } from "hooks";
import { getAbbrPriceFrom, removeDiscountPercentage } from "utils";
import { CURRICULUMS_PER_SESSION } from "constants";
import { GRADES_PER_SESSION } from "constants";
import { boxStyles, gridStyles } from "constants/config";
import { StaticField } from "components/static-field";
import FormLabel from "components/forms/form-label";
import useToast from "hooks/use-toast";
import MaxLimitHint from "components/forms/max-limit-hint";

function TutoringTutorBundleHeadless({
  id,
  title,
  postSession,
  initialStatus,
  initialBundles,
  initialSelectedGrades,
  initialSelectedSubject,
  initialSelectedKeywords,
  initialSelectedDuration,
  initialShowErrorMessages,
  initialSelectedCurriculums,
  gradesData,
  subjectsData,
  curriculumsData,
  onSubmit,
}) {
  const user = useUser();
  const toast = useToast();

  // State.
  const [status, setStatus] = useState(initialStatus);
  const [bundles, setBundles] = useState(initialBundles);
  //const [fee, setFee] = useState(initialFee);
  const [isMockupOffCanvasShown, setIsMockupOffCanvasShown] = useState(false);
  const [selectedGrades, setSelectedGrades] = useState(initialSelectedGrades);
  const [selectedSubject, setSelectedSubject] = useState(
    initialSelectedSubject
  );
  const [selectedKeywords, setSelectedKeywords] = useState(
    initialSelectedKeywords
  );
  const [selectedDuration, setSelectedDuration] = useState(
    initialSelectedDuration
  );
  const [selectedCurriculums, setSelectedCurriculums] = useState(
    initialSelectedCurriculums
  );
  const [showErrorMessages, setShowErrorMessages] = useState(
    initialShowErrorMessages
  );

  const keywordOptions = [
    ...selectedGrades.map(({ label }) => ({ label, value: label })),
    ...selectedSubject.map(({ label }) => ({ label, value: label })),
    ...selectedCurriculums.map(({ label }) => ({ label, value: label })),
  ];

  const mockupCard = (
    <SassyCard className="align-self-stretch">
      <SassyCard.Body>
        {/* Card Header */}
        <ListItemUser
          avatarSrc={user.profile_picture}
          title={selectedSubject[0] ? selectedSubject[0].label : "Subject Name"}
        />
        {/* Curriculums */}
        <LeadingBadgesList
          hasDropdown
          breakAt={1}
          entities={selectedCurriculums.map((curriculum) => curriculum.label)}
        />
        {/* Grades */}
        <LeadingBadgesList
          hasDropdown
          breakAt={1}
          entities={selectedGrades.map((grade) => grade.label)}
        />
        {/* Keywords */}
        <SmallBadgesList hasDropdown breakAt={3} entities={selectedKeywords} />
        {/* Pricing */}
        <div className="d-flex gap-2 align-items-end">
          {bundles
            .slice(0, 3)
            .map(({ discount, total_fee, no_of_sessions }, i) => (
              <div
                key={i}
                className="w-100 d-flex gap-1 flex-column align-items-center"
              >
                {discount > 0 && (
                  <span className="fs-8 fw-bold">
                    was{" "}
                    {getAbbrPriceFrom(
                      removeDiscountPercentage(total_fee || 1, discount)
                    )}
                  </span>
                )}
                <Button
                  readOnly
                  type="button"
                  className="w-100 d-flex flex-column align-items-center gap-1"
                >
                  {getAbbrPriceFrom(total_fee || 1)}
                  <span className="fs-8 fw-bold text-nowrap">
                    {no_of_sessions}{" "}
                    {no_of_sessions > 1 ? "sessions" : "session"}
                  </span>
                </Button>
              </div>
            ))}
        </div>
      </SassyCard.Body>
    </SassyCard>
  );

  function submitForm() {
    if (
      !selectedDuration ||
      selectedGrades.length === 0 ||
      selectedSubject.length === 0 ||
      selectedCurriculums.length === 0
    ) {
      toast.error(requirements.ALL);
      setShowErrorMessages(true);
      return;
    }

    // Bundle Fee validation begins here.
    let isErred = false;

    bundles.forEach((bundle) => {
      if (
        bundle.total_fee < TUTOR_MIN_SESSION_FEE ||
        bundle.total_fee > TUTOR_MAX_SESSION_FEE ||
        bundle.no_of_sessions > TUTOR_MAX_SESSIONS ||
        bundle.no_of_sessions < 1
      ) {
        !showErrorMessages && setShowErrorMessages(true);
        isErred = true;
      }
    });

    if (isErred) {
      toast.error(requirements.ALL);
      return;
    }
    // Bundle Fee validation ends here.

    // Session submission starts here.
    toast.loading();

    postSession({
      onSuccess: ({ status, message }) => {
        if (!status) {
          toast.error(message, {
            size: "md",
          });
          return;
        }

        toast.success();
        onSubmit?.();
      },
      onFailure: ({ message }) => {
        toast.error(message);
      },
      data: {
        id,
        status,
        keywords: selectedKeywords,
        subject_id: selectedSubject[0].value,
        grade_id: selectedGrades.map(({ value }) => value),
        curriculum_id: selectedCurriculums.map(({ value }) => value),
        bundles: bundles.map((bundle) => ({
          ...bundle,
          duration: selectedDuration.value,
        })),
      },
    });
  }

  function changeProductStatus(checked) {
    setStatus(checked ? 1 : 0);

    // The user just deactivated the product.
    if (!checked) {
      // Deactivate all the bundles.
      setBundles(
        bundles.map((bundle) => ({
          ...bundle,
          status: 0,
        }))
      );

      return;
    }

    // The user just activated the product.
    // Activate all the bundles.
    setBundles(
      bundles.map((bundle) => ({
        ...bundle,
        status: 1,
      }))
    );
  }

  function updateBundles(bundles) {
    setBundles(bundles);

    // The product's activation status is dependant on each of the bundles.
    // Activate the product if even one of the bundles is active.
    if (bundles.find((bundle) => bundle.status == 1)) setStatus(1);
    else setStatus(0);
  }

  return (
    <Row className={gridStyles.g}>
      <Col xs={12} className={`d-flex flex-column ${boxStyles.yGap}`}>
        <div>
          {/* <h2 className="card-title mb-0">Session details</h2>
                <div>
                  You can now curate the sessions that you would like to teach,
                  follow the below steps in-order to complete the process.
                </div> */}
          <UncontrolledAccordion defaultOpen={["interests"]}>
            <AccordionItem>
              <AccordionHeader targetId={`interests`}>
                <CardTitle className="mb-0">
                  Step 1: Select What You Would Like To Teach
                </CardTitle>
              </AccordionHeader>
              <AccordionBody accordionId={`interests`}>
                <div className="my-3">
                  {/* Creation allows subject modification. */}
                  {title === actionTypes.ACTION_TYPE_CREATE ? (
                    <FormGroup>
                      <StaticField
                        staticKey="subjects"
                        hasSuggestions
                        closeMenuOnSelect
                        value={selectedSubject?.[0]}
                        onChange={(subject) => setSelectedSubject([subject])}
                        slotAbove={
                          <FormLabel className="mb-0"  >Choose Subject</FormLabel>
                        }
                      />
                      {showErrorMessages && selectedSubject.length === 0 && (
                        <FormErrorMessage message="Please select at least 1 Subject!" />
                      )}
                    </FormGroup>
                  ) : (
                    // Edition displays the selected subject and that's it.
                    <FormGroup>
                      <label className="font-size-13 text-secondary-dim">
                        Subject
                      </label>
                      <Input
                        readOnly
                        disabled
                        value={selectedSubject[0].label}
                      />
                    </FormGroup>
                  )}
                  <FormGroup>
                    <StaticField.Multi
                      staticKey="curriculums"
                      hasSuggestions
                      closeMenuOnSelect
                      maxValues={CURRICULUMS_PER_SESSION}
                      value={selectedCurriculums}
                      onChange={(curriculums) =>
                        setSelectedCurriculums(curriculums)
                      }
                      slotAbove={
                        <FormLabel className="mb-0" hint={<MaxLimitHint limit={CURRICULUMS_PER_SESSION} />} >Choose Curricula</FormLabel>
                      }
                    />

                    {showErrorMessages && selectedCurriculums.length === 0 && (
                      <FormErrorMessage message="Please select at least 1 Curriculum!" />
                    )}
                  </FormGroup>
                  <FormGroup>
                    <StaticField.Multi
                      staticKey="grades"
                      hasSuggestions
                      closeMenuOnSelect
                      maxValues={GRADES_PER_SESSION}
                      value={selectedGrades}
                      onChange={(grades) => setSelectedGrades(grades)}
                      slotAbove={
                        <FormLabel className="mb-0" hint={<MaxLimitHint limit={GRADES_PER_SESSION} />}  >Choose Grades</FormLabel>
                      }
                    />
                    {showErrorMessages && selectedGrades.length === 0 && (
                      <FormErrorMessage message="Please select at least 1 Grade!" />
                    )}
                  </FormGroup>
                </div>
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId={`keywords`}>
                <CardTitle className="mb-0">
                  Step 2: Add Keywords To Help Learners Find You
                </CardTitle>
              </AccordionHeader>
              <AccordionBody accordionId={`keywords`}>
                <div className="my-3">
                  <SassySelect
                    creatable
                    label="Choose Keywords"
                    maxSelections={5}
                    options={keywordOptions}
                    subTitle="(Maximum 5)"
                    onChange={(keywords) =>
                      setSelectedKeywords(keywords.map(({ value }) => value))
                    }
                    selectedOptions={selectedKeywords.map((value) => ({
                      value,
                      label: value,
                    }))}
                  />
                </div>
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId={`duration`}>
                <CardTitle className="mb-0">
                  Step 3: Select Duration per Session
                </CardTitle>
              </AccordionHeader>
              <AccordionBody accordionId={`duration`}>
                {/* <h2 className="card-title text-secondary">
                  Duration & Activation
                  <br />
                  <span className="fs-7 fw-normal text-blue">
                    ({messages.PRODUCT_DEACTIVATION})
                  </span>
                </h2> */}
                <div className="my-3">
                  <Row className="g-0">
                    <Col
                      xs={3}
                      sm={2}
                      lg={3}
                      xxl={2}
                      className="d-flex flex-column gap-2"
                    >
                      <label className="font-size-13 text-secondary-dim">
                        Active
                      </label>
                      <Switch
                        checked={status == 1}
                        onChange={changeProductStatus}
                      />
                    </Col>
                    <Col xs={9} sm={10} md={8} lg={9} xxl={4}>
                      <Select
                        isMulti={false}
                        label="Duration"
                        className="mb-2"
                        closeMenuOnSelect
                        options={DUMMIES_DURATION}
                        selectedOptions={selectedDuration}
                        onSelect={(option) => setSelectedDuration(option)}
                      />
                      {showErrorMessages && !selectedDuration && (
                        <FormErrorMessage message="Please select a valid Duration!" />
                      )}
                    </Col>
                    {/* <Col md={6}>
                    <div className="mb-3">
                      <Label className="control-label font-size-13 text-secondary-dim">
                        Fee per Session{" "}
                        <span className="font-size-10">(in USD)</span>
                      </Label>
                      <Input
                        type="number"
                        value={Math.ceil(fee)}
                        min={TUTOR_MIN_SESSION_FEE}
                        max={TUTOR_MAX_SESSION_FEE}
                        placeholder="Fee goes here..."
                        className="form-control px-2 font-size-13 text-secondary mb-2"
                        onChange={(e) => setFee(parseInt(e.target.value))}
                      />
                      <p className="fs-8 mb-0">
                        Minimum ${TUTOR_MIN_SESSION_FEE} & Maximum $
                        {TUTOR_MAX_SESSION_FEE}
                      </p>
                      {showErrorMessages &&
                        (!fee ||
                          fee < TUTOR_MIN_SESSION_FEE ||
                          fee > TUTOR_MAX_SESSION_FEE) && (
                          <div className="text-danger fw-bold mb-3">
                            Please type a valid fee!
                          </div>
                        )}
                    </div>
                  </Col> */}
                  </Row>
                </div>
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader targetId={`rates`}>
                <CardTitle className="mb-0 d-flex flex-column gap-1">
                  Step 4: Set Your Rates{" "}
                  <div className="fs-7 fw-normal text-blue">
                    (Pro Tip: Offering Discounts Always Incentivises Learners to
                    Purchase)
                  </div>
                </CardTitle>
              </AccordionHeader>
              <AccordionBody accordionId={`rates`} className="py-2">
                <ListGroup className="p-0 border-0">
                  <TutoringTutorBundles
                    //feePerSession={fee}
                    {...{ bundles, showErrorMessages }}
                    onUpdateBundles={updateBundles}
                  />
                </ListGroup>
              </AccordionBody>
            </AccordionItem>
          </UncontrolledAccordion>
        </div>
        <div className="d-flex gap-2 justify-content-end">
          <Button
            outline
            type="button"
            onClick={() => setIsMockupOffCanvasShown(true)}
          >
            Preview Service
          </Button>
          <Button type="button" onClick={submitForm}>
            Save & Continue
          </Button>
        </div>
      </Col>
      <Offcanvas
        direction="end"
        isOpen={isMockupOffCanvasShown}
        toggle={() => setIsMockupOffCanvasShown(!isMockupOffCanvasShown)}
      >
        <OffcanvasHeader
          toggle={() => setIsMockupOffCanvasShown(!isMockupOffCanvasShown)}
        >
          <CardTitle className="mb-0">Service Preview</CardTitle>
        </OffcanvasHeader>
        <OffcanvasBody>{mockupCard}</OffcanvasBody>
      </Offcanvas>
      {/* <Col className="maxw-md">
              <div className="my-4 px-2">
                <div className="mt-4 d-flex justify-content-between ms-1">
                  <p className="text-secondary m-0 p-0 font-size-13">
                    I offer multiple sessions.
                  </p>
                  <Switch
                    checked={areMultipleSessions}
                    className="me-1 mb-sm-8 mb-2"
                    onChange={(checked) => {
                      checked ? setBundles([BUNDLE_DUMMY]) : setBundles([]);
                      setAreMultipleSessions(checked);
                    }}
                  />
                </div>
                {areMultipleSessions && bundles.length > 0 && (
                  <TutoringTutorBundles
                    bundles={bundles}
                    //feePerSession={fee}
                    onUpdateBundles={(bundles) => setBundles(bundles)}
                  />
                )}
              </div>
            </Col> */}
    </Row>
  );
}

export default TutoringTutorBundleHeadless;
