import * as React from "react";
import {
  fetchLearnerSessionDetails,
  fetchTutorAvailableSlots,
  fetchUpcomingSessions,
  updateSessionInfo,
} from "store";
import ToastFactory from "utils/toast-factory";
import {
  AjaxCallStatus,
  BookSessionSlot,
  RawLearnerUpcomingSessionDetailed,
  RawTutorAvailability,
  ReduxStore,
} from "constants/";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Loader } from "components";
import { IComponentProps } from "./index";
import { useStaticDataV3 } from "hooks/static-data";
import useSessionActions from "hooks/sessions/useSessionActions";
import { RLearnerTutoring } from "constants/learner-views-routes";
import SimpleReactValidator from "simple-react-validator";
import { DateTime } from "luxon";

export interface IViewModelPassedControllers {
  saneCurriculums: (string | undefined)[] | undefined;
  saneGrades: (string | undefined)[] | undefined;
  tutorName: string | undefined;
  tutorImage: string | undefined;
  subjectName: string | undefined;
  currentUserDetails: { id: number; [key: string]: any };

  isEditingSessionInfo: boolean;
  setIsEditingSessionInfo: React.Dispatch<React.SetStateAction<boolean>>;
  canEditSessionInfo: boolean;
  sessionNewInfo: {
    agenda: string;
    description: string;
  };
  setSessionNewInfo: React.Dispatch<
    React.SetStateAction<{
      agenda: string;
      description: string;
    }>
  >;
  handleSessionInfoUpdate: () => void;

  isRescheduling: boolean;
  setIsRescheduling: React.Dispatch<React.SetStateAction<boolean>>;
  selectedSlot: BookSessionSlot | null;
  setSelectedSlot: React.Dispatch<React.SetStateAction<BookSessionSlot | null>>;
  slotsStartDate: string;
  setSlotsStartDate: React.Dispatch<React.SetStateAction<string>>;
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  availableSlots: {
    isLoaded: boolean;
    isErred: boolean;
    data: RawTutorAvailability[] | [];
  };
  sessionInfo: {
    state: AjaxCallStatus;
    data: RawLearnerUpcomingSessionDetailed | null;
  };
  toggleModal: () => void;
  handleRequestCancel: () => void;
  handleRequestConfirm: () => void;
  handleRequestReschedule: () => void;

  simpleValidator: React.MutableRefObject<SimpleReactValidator>;
}

// todo: document the flow of the code

/**
 *  This is the view model for LearnerUpcomingSessionDetailed component. It contains all the business logic and state management logic for the component.
 */
function LearnerUpcomingSessionDetailedViewModel({
  children,
  isEdit,
  sessionId,
  requestId,
}: {
  children: (controllers: IViewModelPassedControllers) => JSX.Element;
} & IComponentProps): JSX.Element {
  const toastFactory = ToastFactory();
  //
  const simpleValidator = React.useRef(new SimpleReactValidator());
  const [, forceUpdate] = React.useState<number>();

  // if the user has navigated by the url, the session id will be present in the url. If not, it will be present in the props.
  const _sessionId = parseInt(useParams().session_id as string) || sessionId;
  const _requestId = parseInt(useParams().request_id as string) || requestId;

  // const {refetchUpcomingSessions} = useOutletContext<{
  //   refetchUpcomingSessions: () => {};
  // }>();

  // a method to refetch upcoming sessions. This is used when the user performs any action on the session.
  const refetchUpcomingSessions = () => {
    dispatch({ type: "RESET_LEARNER_UPCOMING_SESSIONS" });

    // TODO: add pagination
    dispatch(fetchUpcomingSessions({}));
  };

  const dispatch: (dispatch: any) => void = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  // sessionInfo states
  const [sessionInfo, setSessionInfo] = React.useState<{
    state: AjaxCallStatus;
    data: RawLearnerUpcomingSessionDetailed | null;
  }>({ state: "loading", data: null });

  // states to manage and keep track of the editing and updating of session info like agenda and description

  const [isEditingSessionInfo, setIsEditingSessionInfo] = React.useState(false);
  const [sessionNewInfo, setSessionNewInfo] = React.useState({
    agenda: "",
    description: "",
  });
  const openSchedule = location.state?.openReschedule;
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);

  // State which keeps track of either user is rescheduling session or not.
  const [isRescheduling, setIsRescheduling] = React.useState<boolean>(
    isEdit || openSchedule || false
  );
  // BookSession states
  const [slotsStartDate, setSlotsStartDate] = React.useState<string>(
    DateTime.now().toFormat("yyyy-MM-dd")
  );
  const [selectedSlot, setSelectedSlot] =
    React.useState<BookSessionSlot | null>(null);
  // ----

  // we need current user details to know his name and implement some checks on session status whether this user has sent the session request or th other uesr.
  // available slots are of tutor and will be used if user wants to reschedule the session.
  const {
    currentUserDetails,
    availableSlots,
  }: {
    currentUserDetails: { id: number; [key: string]: any };
    availableSlots: {
      isLoaded: boolean;
      isErred: boolean;
      data: RawTutorAvailability[] | [];
    };
  } = useSelector((state: ReduxStore) => ({
    currentUserDetails: state.auth.userDetails,
    availableSlots: state.tutorTutoring.availableSlots,
  }));

  const { curriculums, grades, subjects, getStaticData } = useStaticDataV3([
    "curriculums",
    "grades",
    "subjects",
  ]);

  const { handleSessionReschedule, handleSessionCancel, handleSessionConfirm } =
    useSessionActions();

  const [
    { state: curriculumsState },
    { state: gradesState },
    { data: totalSubjects, state: subjectsState },
  ] = [curriculums, grades, subjects];

  const isStaticDataLoaded =
    curriculumsState === "loaded" &&
    gradesState === "loaded" &&
    subjectsState === "loaded";

  React.useEffect(() => {
    //initially session info is set to null. we are keeping session info locally because it is changed for each session on any session status update event by the other. so we don't need to store it in store or redux.
    setSessionInfo({ state: "loading", data: null });
    setIsRescheduling(isEdit || openSchedule || false);
    setIsEditingSessionInfo(false);
    dispatch(
      fetchLearnerSessionDetails({
        sessionId: _sessionId,
        requestId: _requestId,
        onSuccess: ({
          data,
        }: {
          data: RawLearnerUpcomingSessionDetailed | [];
        }) => {
          if (Array.isArray(data)) {
            if (data.length === 0) navigate("/404");
          } else setSessionInfo({ state: "loaded", data });
        },
        onFailure: (error: { message: string }) => {
          toastFactory.error(error.message);
          setSessionInfo({ state: "erred", data: null });
        },
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_sessionId, _requestId]);

  React.useEffect(() => {
    if (sessionInfo.state === "loaded") {
      dispatch(
        fetchTutorAvailableSlots({
          bundleId: sessionInfo.data?.bundle_id,
          range: 7,
          date: slotsStartDate,
          tutorId: sessionInfo.data?.tutor?.user_id,
          onFailure: (error: { message: string }) => {
            toastFactory.error(error.message);
          },
        })
      );
      setSessionNewInfo({
        agenda: sessionInfo.data!.agenda,
        description: sessionInfo.data!.description,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionInfo, slotsStartDate]);

  const toggleModal = () => {
    setIsModalOpen(false);
  };

  // session action handlers
  const handleRequestConfirm = () => {
    handleSessionConfirm({
      requestId: sessionInfo.data!.request_id,
      onSuccess: ({ data }: { data: RawLearnerUpcomingSessionDetailed }) => {
        setSessionInfo({ state: "loaded", data });
        refetchUpcomingSessions();
      },
      onFailure: (error: { message: string }) => {
        setIsRescheduling(false);
      },
    });
  };
  const handleRequestCancel = () => {
    handleSessionCancel({
      requestId: sessionInfo.data!.request_id,
      endTime: sessionInfo.data?.end_time,
      startTime: sessionInfo.data?.start_time,
      date: sessionInfo.data?.date,
      subjectName,
      userName: tutorName,
      userPicture: tutorImage,
      onSuccess: ({ data }: any) => {
        setSessionInfo({ state: "loaded", data });
        refetchUpcomingSessions();
      },
      onFailure: (error: any) => {
        setIsRescheduling(false);
      },
      onRebook: () => {
        navigate(
          RLearnerTutoring.getTutorAvailableSessionsView(
            sessionInfo.data?.tutor?.user_id ?? ""
          )
        );
      },
    });
  };
  const handleRequestReschedule = () => {
    const newTime = DateTime.fromFormat(selectedSlot!.id, "dd/MM/yyyy HH:mm");

    const startTime = newTime.toFormat("HH:mm");
    const endTime = selectedSlot!.endTime;
    const date = newTime.toFormat("yyyy-MM-dd");
    const weekDay = newTime.weekday;

    handleSessionReschedule({
      startTime,
      endTime,
      date,
      requestId: sessionInfo.data!.request_id,
      weekDay,
      rescheduledBy: currentUserDetails.id,
      subjectName,
      receiverImage: tutorImage,
      receiverName: tutorName,
      onSuccess: ({ data }: { data: RawLearnerUpcomingSessionDetailed }) => {
        setIsRescheduling(false);
        setSessionInfo({ state: "loaded", data });
        refetchUpcomingSessions();
      },
      onFailure: (error: { message: string }) => {
        setIsRescheduling(false);
      },
    });
  };

  const handleSessionInfoUpdate = () => {
    if (!simpleValidator.current.allValid()) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    toastFactory.loading();
    dispatch(
      updateSessionInfo({
        id: sessionInfo.data?.request_id!,
        data: sessionNewInfo,
        onSuccess: (res) => {
          toastFactory.success();
          setIsEditingSessionInfo(false);
          refetchUpcomingSessions();

          setSessionInfo({
            state: "loaded",
            data: {
              ...sessionInfo.data!,
              agenda: sessionNewInfo.agenda,
              description: sessionNewInfo.description,
            },
          });
        },
        onFailure: (error) => {
          toastFactory.error();
        },
      })
    );
  };

  if (sessionInfo.state === "loading" || !isStaticDataLoaded) {
    return <Loader />;
  }

  // if (sessionInfo.data.request_status == false) {
  //   navigate(RLearnerTutoring.getSessionsView(), {
  //     replace: true,
  //   });
  // }

  /// Data uniformation starts here.

  /**
   * Loop-able grades.
   */
  const saneGrades = sessionInfo.data?.grade_id.map((id) =>
    grades.data.get(parseInt(id))
  );

  /**
   * Loop-able curriculums.
   */
  const saneCurriculums = sessionInfo.data?.curriculum_id.map((id) =>
    curriculums.data.get(parseInt(id))
  );

  const tutorName = sessionInfo.data?.tutor.name;
  const tutorImage =
    sessionInfo.data!.source + sessionInfo.data?.tutor.profile_picture;
  const subjectName = getStaticData(
    sessionInfo.data!.subject_id,
    totalSubjects
  );

  // we need to know whether the user  can edit the agenda or description of the session or not
  // If he is the one who has sent the session request and the request has not been cancelled or rejected, he can edit the session info.

  const canEditSessionInfo =
    sessionInfo.state === "loaded" &&
    ![3, 5].includes(sessionInfo.data?.request_status!) &&
    sessionInfo.data?.sent_by == currentUserDetails.id;

  /// Data uniformation ends here.

  const passedControllers = {
    saneCurriculums,
    saneGrades,
    tutorName,
    tutorImage,
    subjectName,
    currentUserDetails,
    availableSlots,
    sessionInfo,

    isEditingSessionInfo,
    setIsEditingSessionInfo,
    canEditSessionInfo,
    sessionNewInfo,
    setSessionNewInfo,
    handleSessionInfoUpdate,

    isRescheduling,
    setIsRescheduling,
    selectedSlot,
    setSelectedSlot,
    slotsStartDate,
    setSlotsStartDate,
    isModalOpen,
    setIsModalOpen,
    toggleModal,
    handleRequestCancel,
    handleRequestConfirm,
    handleRequestReschedule,

    simpleValidator,
  };

  return children(passedControllers);
}

export default LearnerUpcomingSessionDetailedViewModel;
