/**
 * @typedef { Object } PaymentHistory
 *
 * @property { number } product_id,
 * @property { "session" | "material" } type,
 * @property { "tutor" | "material" } product_type,
 * @property { string } bio,
 * @property { string } date,
 * @property { string } status,
 * @property { number } session,
 * @property { number } grade_id,
 * @property { number } subject_id,
 * @property { number } curriculum_id,
 * @property {{ '$oid': string }} ref_id
 * @property {{ owner_id: number,
 * name: string,
 * experiance: string,
 * rating: number,
 * profile_picture: string
 * }} product_owner
 */

/**
 * @typedef { Object } PaymentHistoryState
 *
 * @property { number } total
 * @property { number } per_page
 * @property { number } current_page
 * @property { RequestState } state
 * @property { PaymentHistory[] } data
 */

/**
 * @typedef { "SET_PAYMENT_HISTORY"
 * | "ERRED_PAYMENT_HISTORY"
 * | "LOADING_PAYMENT_HISTORY"
 *  } PaymentHistoryActionType
 */

/**
 * @typedef { Object } PaymentHistoryAction
 *
 * @property { any } payload
 * @property { PaymentHistoryActionType } type
 */

//1= paid 2= refund Request , 3 = In  dispute  4= Processing  5=Refunded 6= partially refunded
export const PAYMENT_HISTORY_STATUS_TYPES = [
  {
    id: 1,
    classNames: "alert alert-success",
    label: "Paid",
    key: "paid",
  },
  {
    id: 2,
    classNames: "bg-danger text-white rounded",
    label: "Refund Request",
    key: "refund_request",
  },
  {
    id: 3,
    classNames: "alert alert-primary",
    label: "Disputed",
    key: "dispute",
  },
  {
    id: 4,
    classNames: "alert alert-warning",
    label: "Processing",
    key: "process",
  },
  {
    id: 5,
    classNames: "alert alert-danger",
    label: "Refunded",
    key: "refund",
  },
  {
    id: 6,
    classNames: "bg-dark text-white rounded",
    label: "Refund in progress",
    key: "refund_partial",
  },
];
