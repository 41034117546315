import * as React from "react";
import { Form } from "react-bootstrap";
import { OTP_LENGTH } from "constants/env";
import { ErrorMessage, Field } from "formik";
import { FormErrorMessage } from "components";

export default function OtpFields({ name }: { name: string }) {
  return (
    <>
      <label htmlFor={name}>OTP</label>
      <div role="group" aria-label="OTP input group">
        <Field
          id={name}
          name={name}
          type="number"
          as={Form.Control}
          maxLength={OTP_LENGTH}
          placeholder={`Enter ${OTP_LENGTH}-digit OTP`}
        />
      </div>
      <ErrorMessage
        name={name}
        render={(message) => <FormErrorMessage message={message} />}
      />
    </>
  );
}
