import React from "react";
import { createPortal } from "react-dom";
import { Modal, ModalProps } from "react-bootstrap";

export default function BaseModal(props: ModalProps) {
  return createPortal(
    <Modal {...props} centered>
      {props.children}
    </Modal>,
    document.body
  );
}
