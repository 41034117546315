import React from "react";
import Card from "./sassy-card";
import SassyCardBody from "./sassy-card-body";
import { Card as BsCard } from "react-bootstrap";

const SassyCard = Object.assign(Card, {
  Body: SassyCardBody,
  Header: BsCard.Header,
  Footer: BsCard.Footer,
  Title: ({
    className = "",
    children,
    ...props
  }: React.ComponentPropsWithoutRef<"div">) => (
    <div {...props} className={`card-title mb-0 ${className}`}>
      {children}
    </div>
  ),
});

export default SassyCard;
