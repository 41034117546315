/**
 * @typedef {Object} LayoutWrapperProps
 *
 * @property {React.ReactNode} children
 */

/**
 * A wrapper for each view's layout.
 * @param {LayoutWrapperProps
 * & JSX.IntrinsicElements['section']} props
 *
 * @returns {JSX.Element}
 *
 * @author kashan-ahmad
 * @version 0.0.1
 */
function WrapperLayout({ children, className = "", ...props }) {
  return (
    <section {...props} className={`content ${className}`}>
      {children}
    </section>
  );
}

function Main({ children, className = "", ...props }) {
  return (
    <div {...props} className={`content__main ${className}`}>
      {children}
    </div>
  );
}

WrapperLayout.Main = Main;

export default WrapperLayout;
