/**
 * Enum containing all the static data members of the tutor's
 * onboarding module.
 *
 * @type {Record<string, any>}
 */
export const ETutorOnboarding = {
  // Section IDs
  BASIC_INFO: "basic-info",
  QUALIFICATIONS: "qualifications",
  EXPERIENCE: "experience",
  SKILLS: "skills",
  CERTS: "certifications",
  SUBJECTS: "subjects",
};
