import { API_MAIN_URL } from "constants";
import { apiService } from "service";
import { getAuthHeaders } from "utils";

export const addTutorBank =
  ({ bankData, onSuccess, onFailure }) =>
  async (dispatch) => {
    const formData = new FormData();

    Object.entries(bankData).forEach(([key, value]) => {
      formData.append(key, value);
    });

    apiService.post({
      url: `${API_MAIN_URL}/payment/borderless-contact/add`,
      headers: getAuthHeaders(),
      data: formData,
      onSuccess: (res) => {
        dispatch({ type: "RESET_BANKS_DATA" });
        onSuccess?.(res);
      },
      onFailure: ({ error }) => {
        onFailure?.(error.data);
      },
    });
  };

export const fetchTutorBanks = () => async (dispatch) => {
  apiService.post({
    url: `${API_MAIN_URL}/payment/borderless-contact/get`,
    headers: getAuthHeaders(),
    onSuccess: (response) => {
      if (response.status) {
        dispatch({
          type: "SET_BANKS_DATA",
          payload: response.data,
        });
      } else {
        dispatch({
          type: "SET_BANKS_DATA",
          payload: [],
        });
      }
    },
    onFailure: (error) => {
      dispatch({
        type: "ERRED_BANKS_DATA",
      });

      // onFailure?.();
    },
  });
};

export const fetchSingleBankDetails =
  ({ accountHandle, onSuccess, onFailure }) =>
  async (dispatch) => {
    // console.log("action", {accountHandle});

    const data = new FormData();
    data.append("handle", accountHandle);

    apiService.post({
      url: `${API_MAIN_URL}/payment/borderless-contact/get-one-account`,
      data,
      headers: getAuthHeaders(),
      onSuccess: ({ data }) => {
        onSuccess?.(data);
      },
      onFailure: (error) => onFailure?.(error),
    });
  };

export const checkBankAccountStatus =
  ({ accountHandle, onSuccess, onFailure }) =>
  async (disptach) => {
    //TODO: Properly test and integrate these API endpoints.

    const formData = new FormData();

    formData.append("handle", accountHandle);

    apiService.post({
      url: `${API_MAIN_URL}/payment/borderless-contact/status`,
      data: formData,
      headers: getAuthHeaders(),
      onSuccess: (response) => {
        // console.log(response);

        onSuccess?.();
      },
      onFailure: (error) => {
        // console.log(error);

        onFailure?.();
      },
    });
  };

export const makeBankAccountDefault =
  ({ accountHandle, onSuccess, onFailure }) =>
  async (dispatch) => {
    //TODO: Properly test and integrate these API endpoints.

    const data = new FormData();
    data.append("handle", accountHandle);

    apiService.post({
      url: `${API_MAIN_URL}/payment/borderless-contact/default`,
      headers: getAuthHeaders(),
      data,
      onSuccess: ({ data }) => {
        dispatch({
          type: "SET_BANKS_DATA",
          payload: data,
        });

        onSuccess?.();
      },
      onFailure: (error) => {
        // console.log(error);
        onFailure?.();
      },
    });
  };

export const removeBankAccount =
  ({ accountHandle, onSuccess, onFailure }) =>
  async (dispatch, getState) => {
    //TODO: Properly test and integrate these API endpoints.

    const data = new FormData();

    data.append("handle", accountHandle);

    apiService.post({
      url: `${API_MAIN_URL}/payment/borderless-contact/deactivated`,
      headers: getAuthHeaders(),
      data,
      onFailure: (error) => onFailure?.(error),
      onSuccess: ({ data }) => {
        dispatch({
          type: "REMOVED_BANK_ACCOUNT",
          payload: data,
        });

        onSuccess?.(data);
      },
    });
  };

export const fetchEarningsData =
  ({ status = "", onSuccess, onFailure }) =>
  async (dispatch, getState) => {
    const data = new FormData();
    if (status) data.append("status", status);

    apiService.post({
      url: `${API_MAIN_URL}/payment/borderless-payout/details`,
      headers: getAuthHeaders(),
      data,
      onSuccess: ({ data }) => {
        if (status)
          dispatch({
            type: "SET_EARNINGS_DATA_BY_STATUS",
            payload: { status: status.toLowerCase(), data },
          });
        else {
          const state = getState();

          const pending = data.filter((e) => e.status === "Pending");
          const available = data.filter((e) => e.status === "Available");
          const intransit = data.filter((e) => e.status === "InTransit");
          const withdrawn = data.filter((e) => e.status === "Withdrawn");
          const deducted = data.filter((e) => e.status === "Deducted");

          const earningsData = {
            pending: {
              data: pending,
              sum: state.earnings.earningsData.pending.sum,
            },
            available: {
              data: available,
              sum: state.earnings.earningsData.available.sum,
            },
            intransit: {
              data: intransit,
              sum: state.earnings.earningsData.intransit.sum,
            },
            withdrawn: {
              data: withdrawn,
              sum: state.earnings.earningsData.withdrawn.sum,
            },
            deducted: {
              data: deducted,
              sum: state.earnings.earningsData.deducted.sum,
            },
          };

          dispatch({
            type: "SET_EARNINGS_DATA",
            payload: earningsData,
          });
        }
      },
      onFailure: ({ error }) => {
        dispatch({ type: "ERRED_EARNINGS_DATA" });
      },
    });
  };

export const getEarningsSum =
  ({
    status = "",
    start_date = "",
    end_date = "",
    onSuccess = undefined,
    onFailure = undefined,
  }) =>
  async (dispatch, getState) => {
    const data = new FormData();
    if (status) data.append("status", status);
    if (start_date) data.append("start_date", start_date);
    if (end_date) data.append("end_date", end_date);

    apiService.post({
      url: `${API_MAIN_URL}/payment/borderless-payout/sum`,
      headers: getAuthHeaders(),
      data,
      onSuccess: ({ data }) => {
        if (status)
          dispatch({
            type: "SET_EARNINGS_SUM_BY_STATUS",
            payload: { status: status.toLowerCase(), data },
          });
        else {
          const state = getState();

          const earningsData = {
            pending: {
              data: state.earnings.earningsData.pending.data,
              sum: data.Pending,
            },
            available: {
              data: state.earnings.earningsData.available.data,
              sum: data.Available,
            },
            intransit: {
              data: state.earnings.earningsData.intransit.data,
              sum: data.InTransit,
            },
            withdrawn: {
              data: state.earnings.earningsData.withdrawn.data,
              sum: data.Withdrawn,
            },
            deducted: {
              data: state.earnings.earningsData.deducted.data,
              sum: data.Deducted,
            },
            remaining: {
              data: state.earnings.earningsData.remaining.data,
              sum: data.Remain_amount,
            },
          };

          dispatch({
            type: "SET_EARNINGS_SUM",
            payload: earningsData,
          });
        }

        onSuccess?.(data);
      },
      onFailure: ({ error }) => {
        dispatch({ type: "ERRED_EARNINGS_SUM" });

        onFailure?.(error);
      },
    });
  };

export const initiatePayoutRequest =
  // ({description = "Payout for tutor", onSuccess, onFailure}) =>


    ({
      description = "Payout for tutor",
      withdrawAmount,
      onSuccess,
      onFailure,
    }) =>
    async (dispatch) => {
      const data = new FormData();
      data.append("description", description);
      data.append("withdrawamount", withdrawAmount);

      apiService.post({
        url: `${API_MAIN_URL}/payment/borderless-payout`,
        headers: getAuthHeaders(),
        data,
        onSuccess: (response) => {
          onSuccess?.(response);
        },
        onFailure: ({ error }) => {
          onFailure?.(error.data);
        },
      });
    };

export const fetchMonthlyEarningsStats =
  ({ monthCount = 7 }) =>
  async (dispatch) => {
    apiService.get({
      url: `${API_MAIN_URL}/payment/orders-graph`,
      data: [{ key: "month_count", value: monthCount }],
      headers: getAuthHeaders(),
      onSuccess: ({ data }) => {
        dispatch({
          type: "SET_MONTHLY_EARNINGS_STATS",
          payload: data?.reverse()?.map((i) => ({
            ...i,
            Sessions: i.sessionsTotal,
            Materials: i.materialTotal,
          })),
        });
      },
      onFailure: ({ error }) => {
        dispatch({ type: "ERRED_MONTHLY_EARNINGS_STATS" });
      },
    });
  };

export const fetchWithdrawalsHistory =
  ({ start_date, end_date, onSuccess, onFailure }) =>
  async (dispatch) => {
    const data = new FormData();
    if (start_date) data.append("start_date", start_date);
    if (end_date) data.append("end_date", end_date);

    apiService.post({
      url: `${API_MAIN_URL}/payment/withdraw-history`,
      data,
      headers: getAuthHeaders(),
      onSuccess: ({ data }) => {
        dispatch({
          type: "SET_WITHDRAWALS_HISTORY",
          payload: data,
        });
      },
      onFailure: ({ error }) => {
        dispatch({ type: "ERRED_WITHDRAWALS_HISTORY" });
      },
    });
  };

export const fetchDailyEarningsStats =
  ({ onSuccess = undefined, onFailure = undefined, days_count = 7 }) =>
  async (dispatch) => {
    apiService.get({
      url: `${API_MAIN_URL}/payment/daily-sale-graph`,
      data: [{ key: "days_count", value: days_count }],
      headers: getAuthHeaders(),
      onSuccess: ({ data }) => {
        dispatch({
          type: "SET_DAILY_EARNINGS",
          payload: data,
        });
      },
      onFailure: ({ error }) => {
        dispatch({
          type: "ERRED_DAILY_EARNINGS",
        });
      },
    });
  };

export const getCountryZoneCodes = ({ countryCode, onSuccess, onFailure }) => {
  const formData = new FormData();
  formData.append("country", countryCode);

  apiService.post({
    url: `${API_MAIN_URL}/payment/borderless-zone`,
    headers: getAuthHeaders(),
    data: formData,
    onSuccess: (res) => {
      onSuccess?.(res);
    },
    onFailure: (error) => {
      console.log({ error });

      onFailure?.(error);
    },
  });
};
