type Variable =
  | "WEBSITE_URL"
  | "VIDEO_CALL_TOOL"
  | "USER_SERVICE_URL"
  | "CONTENT_BUILDER_URL"
  | "TUTOR_MAX_SKILLS"
  | "TUTOR_MAX_SUBJECTS"
  | "TUTOR_MAX_LANGUAGES"
  | "TUTOR_MAX_EXPERIENCES"
  | "TUTOR_MAX_CERTIFICATIONS"
  | "TUTOR_MAX_QUALIFICATIONS"
  | "MAX_BIO_CHARACTERS"
  | "NODE_ENV"
  | "JITSI_ENV";

export function getEnvVar(name: Variable): string {
  const value = process.env[`REACT_APP_${name}`];

  if (!value) {
    throw new Error(`Environment variable ${name} is not defined.`);
  }

  return value;
}

export function getEnvInt(name: Variable): number {
  return parseInt(getEnvVar(name));
}

export function setEnvVar(name: string, value: string): void {
  process.env[name] = value;
}
