import { OverlayError } from "components";
import { TutorCertificateForm } from "modules/forms";
import React from "react";
import { useDispatch } from "react-redux";
import { useOutletContext } from "react-router-dom";
import {
  deleteCertificates,
  storeTutorCertificates,
} from "store/tutordashboard/action";
import { Error404 } from "views/error";
import TutorCertificatesList from "./view-profile-tutor-certificates-list";
import { TUTOR_MAX_CERTIFICATES } from "constants";
import { convertBase64 } from "utils";
import { messages } from "constants/strings";
import { wrapperStyles } from "constants/config";
import useToast from "hooks/use-toast";
import { ConfirmDeletionModal } from "modules/modals/confirmation";
import { SimpleModalBody } from "modules/modals/components/modal-body/variants";
import { DeletionModalFooter } from "modules/modals/components/modal-footer/variants";

/**
 * @author Abdullah-Sajjad026
 */
const TutorProfileCertificatesView = (props) => {
  const toast = useToast();
  const dispatch = useDispatch();

  /* eslint-disable eqeqeq */
  const DEFAULT_STATE = {
    certificate_name: "",
    issuing_organization: "",
    issue_date: "",
    document: null,
    description: "",
  };

  const outletProps = useOutletContext();
  const certificates = props?.certificates || outletProps?.certificates || [];

  const [{ isEditing, formVariant, currentState }, setViewState] =
    React.useState({
      formVariant: "add",
      currentState: DEFAULT_STATE,
      // Editable by default if the certificates don't exist.
      isEditing: !certificates || certificates.length === 0 || props.isEditing,
      isListEditable: props.isListEditable || true,
    });

  function setState(key, value) {
    setViewState((state) => ({ ...state, [key]: value }));
  }

  // Form Actions
  const onEditCertificate = (certificate) => {
    setViewState((state) => ({
      ...state,
      formVariant: "edit",
      currentState: {
        ...certificate,
        ...(typeof certificate.document === "string"
          ? { fileName: certificate.document, document: null }
          : {}),
      },
      isEditing: true,
    }));

    // Scroll the form into sight.
    document
      .getElementById("certificates-form")
      .scrollIntoView({ behavior: "smooth" });
  };

  const onRemoveCertificate = (certificateId) => {
    toast.render(
      <ConfirmDeletionModal
        body={
          <SimpleModalBody>
            Are you sure you want to delete this certificate?
          </SimpleModalBody>
        }
        footer={
          <DeletionModalFooter
            onCancel={() => toast.dismiss()}
            onDelete={() =>
              dispatch(
                deleteCertificates(certificateId, () => {
                  setViewState((state) => ({
                    ...state,
                    isEditing: true,
                  }));
                })
              )
            }
          />
        }
      />
    );
  };

  const onAddCertificate = async (certificate) => {
    if (
      formVariant === "add" &&
      certificates.length >= TUTOR_MAX_CERTIFICATES
    ) {
      toast.error(
        messages.ERR_LIMIT_REACHED(TUTOR_MAX_CERTIFICATES, "certificates")
      );
      return;
    }

    setState("currentState", DEFAULT_STATE);

    if (Object.keys(certificate).includes("id")) {
      certificate["certificate_id"] = certificate.id;
      delete certificate.id;
    }
    if (certificate.document)
      certificate.document = await convertBase64(certificate.document);
    toast.loading();

    dispatch(
      storeTutorCertificates({
        certificates: [certificate],
        onSuccess: (data) => {
          toast.dismiss();
          setViewState((state) => ({
            ...state,
            isEditing: props.isEditing ? true : formVariant === "add",
            formVariant: "add",
          }));
          document
            .getElementById("certificates-form")
            .scrollIntoView({ behavior: "smooth" });
        },
        onFailure: (error) => {
          toast.error(error.message);
        },
      })
    );
  };

  const onCloseForm = () =>
    setViewState((state) => ({
      ...state,
      formVariant: "add",
      currentState: DEFAULT_STATE,
      // Editable by default if the certificates don't exist.
      isEditing: false,
    }));
  const onResetForm = () => {
    setViewState((state) => ({
      ...state,
      formVariant: "add",
      currentState: DEFAULT_STATE,
    }));
  };

  return !certificates ? (
    <Error404 />
  ) : (
    <>
      {isEditing && (
        <section id="certificates-form" className="position-relative">
          <TutorCertificateForm
            state={currentState}
            variant={formVariant}
            onReset={onResetForm}
            onCloseForm={onCloseForm}
            onAddCertificate={onAddCertificate}
            allowFormClose={props.allowFormCancel && certificates.length > 0}
          />
          <OverlayError
            isPaddedBy={3}
            condition={
              certificates.length >= TUTOR_MAX_CERTIFICATES &&
              !Object.keys(currentState).includes("id")
            }
            {...(props.allowFormCancel ? { onCloseOverlay: onCloseForm } : {})}
            error={messages.ERR_LIMIT_REACHED(
              TUTOR_MAX_CERTIFICATES,
              "Certificates"
            )}
            description={messages.ERR_LIMIT_REACHED_DESC("certificates")}
          />
        </section>
      )}
      <section
        id="certificates-list"
        className={`d-flex flex-column ${wrapperStyles.yGap}`}
      >
        <TutorCertificatesList
          {...{
            isEditing,
            certificates,
            onEditCertificate,
            onRemoveCertificate,
          }}
          setIsEditing={(value) => setState("isEditing", value)}
        />
      </section>
    </>
  );
};

export default TutorProfileCertificatesView;
