/* eslint-disable eqeqeq */
import * as React from "react";
import { SearchForm } from "..";
import { API_MAIN_URL } from "constants";
import { useNavigate } from "react-router-dom";
import { learnerRoutes } from "router/routes";

/**
 * A form that makes use of the SearchForm component in order to search through a list of connected tutors for a learner.
 * @param {Object} props
 * @param {Function} props.onReset
 * @param {Function} props.onSelectRecord
 * The callback for a searched item's click event.
 * @param {string | undefined} props.searchedName
 * The name of the searched tutor.
 * @param {string | React.ComponentType<any>} props.as
 * Callback for the form's reset event.
 *
 * @returns {JSX.Element}
 *
 * @author kashan-ahmad
 * @version 0.0.1
 */
function TutorSearchForm({
  searchedName,
  onReset,
  onSelectRecord,
  as: Component = "div",
  ...props
}) {
  const navigate = useNavigate();

  const [isModelOpen, setIsModalOpen] = React.useState(false);

  return (
    <Component {...props}>
      <SearchForm
        url={`${API_MAIN_URL}/learner/get-connected-tutors`}
        paramName="search"
        title="Search for a tutor"
        indexTitleKey="name"
        initialQueryValue={searchedName}
        isOpen={isModelOpen}
        toggle={() => setIsModalOpen(!isModelOpen)}
        onReset={onReset}
        onSelectRecord={({ user_id, ...record }) => {
          onSelectRecord?.();
          setIsModalOpen(false);
          navigate(`${learnerRoutes.creditsSearch.url}/${user_id}`, {
            state: record,
          });
        }}
      />
    </Component>
  );
}

export default TutorSearchForm;
