/* eslint-disable eqeqeq */
// Hooks.
import { useCallback } from "react";

// Utils.
import { getEnvVar } from "utils/utils-env";
import { getAuthHeaders, getFormDataFromObject } from "utils/utils-functions";
import { useLocation, useNavigate } from "react-router-dom";
// import { useDispatch } from "react-redux";

// Services.
import apiService from "service/api-service";

// Types.
import type { Boolbacks, RemoteProfile, UserType } from "constants/types";
import useSetRemoteProfile from "./use-set-remote-profile";
import useRemoteProfileValue from "./use-remote-profile-value";
import { useRemoteAvailability } from "hooks/availability";
import { DUMMY_SERIALIZED_AVAILABILITY } from "constants/dummies/dummies-availability";
import useToast from "hooks/use-toast";

type UpdateProfileProps = {
  profile: Partial<
    Omit<RemoteProfile, "profile_picture" | "cover_image" | "languages"> & {
      cover_image: File;
      profile_picture: File;

      // JSON Strings.
      skills: string;
      subjects: string;
      languages: string;
    }
  >;
} & Boolbacks<RemoteProfile>;

type UpdateProfileResponse = {
  default: RemoteProfile;
  erred: {
    message: string;
    data: RemoteProfile;
    error_code?: number;
  };
};

const isDefaultResponse = (
  response: unknown
): response is UpdateProfileResponse["default"] => {
  return (
    typeof response === "object" && response !== null && "user_type" in response
  );
};

export default function useRemoteProfile() {
  const location = useLocation();
  const navigate = useNavigate();
  const toast = useToast();
  const { availability, updateAvailability } = useRemoteAvailability();

  const setProfile = useSetRemoteProfile();
  const remoteProfile = useRemoteProfileValue();

  const saferAvailability =
    availability.length > 0 ? availability : DUMMY_SERIALIZED_AVAILABILITY;

  /**
   * Function to update the profile.
   * Automatically updates the profile in the store.
   */
  const updateProfile = useCallback(
    ({ profile, onSuccess, onFailure, onByPass }: UpdateProfileProps) => {
      const url = `${getEnvVar("USER_SERVICE_URL")}/${
        remoteProfile.user_type === "tutor"
          ? "tutor/update-profile"
          : "learner/update-profile"
      }`;

      apiService.post({
        url,
        headers: getAuthHeaders(),
        data: getFormDataFromObject(profile),
        onFailure,
        onSuccess: ({
          data,
        }: // message,
        {
          message: string;
          data: Record<string, unknown>;
        }) => {
          if (isDefaultResponse(data)) {
            setProfile({ profile: data });
            onSuccess({ data });
            return;
          }

          // Request the caller to bypass it's own logic.
          onByPass?.();

          // * Store user availibility data in local storage
          localStorage.setItem(
            "BACKUP_PROFILE_AVAILABILITY",
            JSON.stringify(saferAvailability)
          );

          // Clear the user's availability.
          updateAvailability({
            availability: JSON.stringify(
              (saferAvailability as object[]).map((instance) => ({
                ...instance,
                status: 0,
                slots: [],
              }))
            ),
            onSuccess: () => console.log("success"),
            onFailure: ({ message }) => toast.error(message),
          });

          // Update the user's profile that they so crave.
          updateProfile({
            profile,
            onSuccess: ({ data }) => {
              onSuccess({ data });
              setProfile({ profile: data });

              const availability = localStorage.getItem(
                "BACKUP_PROFILE_AVAILABILITY"
              )!;

              // Request the server to set the availability from local cache.
              // Clear the user's availability.
              updateAvailability({
                availability,
                onSuccess: () => {},
                onFailure: () =>
                  toast.error(
                    "Profile updated but availability failed to restore."
                  ),
              });
            },

            onFailure: ({ message }) => toast.error(message),
          });

          // The user is updating their timezone while their availability is set, this results in multiple issues on the server.
          // We need to display a modal that let's the user resolve this with ease.
          // const nextRouteProps = getModalRouteProps(
          //   "/modal/error/timezone/update",
          //   location
          // );

          // Here we go...
          // We need to navigate to the modal.
          // navigate(nextRouteProps.to, {
          //   state: {
          //     ...nextRouteProps.state,
          //     errorMessage: message,
          //     updatedProfile: profile,
          //   },
          // });
        },
      });
    },
    [location, navigate, remoteProfile.user_type, setProfile]
  );

  const getProfile = useCallback(
    ({
      userType,
      onSuccess,
      onFailure,
      existingProfile,
      isOnboarding = 0,
    }: {
      userType?: UserType;
      existingProfile?: RemoteProfile;
      isOnboarding: 0 | 1;
    } & Boolbacks<RemoteProfile>) => {
      const url = `${getEnvVar("USER_SERVICE_URL")}/${
        (userType ? userType : remoteProfile.user_type) === "tutor"
          ? "tutor/get-profile"
          : "learner/get-profile"
      }`;

      const data = [{ key: "is_onboarding", value: isOnboarding }];

      apiService.get({
        url,
        data,
        onFailure,
        headers: getAuthHeaders(),
        onSuccess: ({ data }: { data: RemoteProfile }) => {
          setProfile({ userType, profile: { ...existingProfile, ...data } });
          onSuccess({ data });
        },
      });
    },
    [remoteProfile.user_type, setProfile]
  );

  return {
    getProfile,
    setProfile,
    updateProfile,
    profile: remoteProfile,
  };
}
