import type { ProfileAction, ProfileState } from ".";
import type { RemoteProfile } from "constants/types/types-profile";
import { dummyRemoteProfile } from "constants/dummies/dummies-profile";

const initialState: RemoteProfile = dummyRemoteProfile;

export default function profileReducer(
  state: ProfileState = initialState,
  action: ProfileAction
) {
  switch (action.type) {
    case "SET_PROFILE":
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
