import * as React from "react";
import SassySelect from "components/sassy-select";
import { DUMMIES_YEARS_NUMERIC } from "constants/";
import { SassySelectProps, SelectOption } from "constants/types/types";

/**
 * A React-Select based Year picker component that allows to select a year between current year and last 70 years from a select menu.
 * @param {SassySelectProps & {value: {label: int, value: int}}} props
 * Props passed to Year Picker
 *
 * @returns {JSX.Element}
 * @author "Abdullah-Sajjad026"
 */

const YearPicker = ({
  value,
  onChange,
  className,
  disabled,
  label,
  isMulti = false,
  placeholder = "Year",
  closeMenuOnSelect = true,
  ...props
}: SassySelectProps & { value: SelectOption }): JSX.Element => {
  return (
    <SassySelect
      {...{
        ...props,
        label,
        className,
        disabled,
        onChange,
        placeholder,
        closeMenuOnSelect,
        isMulti,
      }}
      options={DUMMIES_YEARS_NUMERIC}
      selectedOptions={{ ...value, value: parseInt(value.value) }}
    />
  );
};

export default YearPicker;
