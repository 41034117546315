import React from "react";
import templateAvatar from "assets/images/person.svg";

/**
 * A generic user avatar that uses a default avatar in case the provided one is erred.
 * @param {JSX.IntrinsicElements['img']} object
 * @returns {JSX.Element}
 * @author kashan-ahmad
 * @version 0.0.1
 */
const Avatar = ({ alt, src, size = "sm", style, className = "", ...props }) => (
  <img
    {...props}
    alt={alt || ""}
    src={src || templateAvatar}
    className={`rounded-circle object-cover avatar-${size} ${className}`}
    style={{
      ...style,
      aspectRatio: "1/1",
    }}
    onError={({ target }) => {
      // Removing the event listener prevents an infinite event chain.
      target["onerror"] = null;
      target["src"] = templateAvatar;
    }}
  />
);

export default Avatar;
