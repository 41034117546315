import React from "react";
import TimezoneUpdateModal from "modules/modals/timezone-update-modal";

/**
 * @author Abdullah-Sajjad026
 */
export default function TimezoneUpdatePlaceholder() {
  return (
    <div className="position-fixed top-0 start-0 end-0 bottom-0 bg-transparent">
      <TimezoneUpdateModal />
    </div>
  );
}
