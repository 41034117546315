import thunk from "redux-thunk";
import { configureStore } from "@reduxjs/toolkit";
import staticDataV3Reducer from "./store-static-v3-reducer";

const staticDataV3Store = configureStore({
  middleware: [thunk],
  reducer: staticDataV3Reducer,
});

export default staticDataV3Store;

export type StaticDataV3Dispatch = typeof staticDataV3Store.dispatch;
