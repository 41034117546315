import React from "react";
import { Button } from "react-bootstrap";

/**
 * Generic icon button.
 *
 * @param {any} props
 *
 * @returns {JSX.Element}
 *
 * @author kashan-ahmad
 * @version 0.0.1
 */
function IconButton({
  title,
  children,
  className = "",
  tooltipPosition = "top",
  as: Component = Button,
  variant = "outline-secondary",
  ...props
}) {
  return (
    <Component
      {...props}
      type="button"
      role="tooltip"
      variant={variant}
      data-microtip-position={tooltipPosition}
    >
      {children}
    </Component>
  );
}

export default IconButton;
