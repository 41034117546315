/* eslint-disable eqeqeq */
import moment from "moment";
import { isEmpty } from "lodash";
import countries from "countries-list";
import { WEBSITE_URL, MAX_BIO_CHARACTERS } from "constants";
import strings from "constants/strings";
import { Timezone } from "@syncfusion/ej2-react-schedule";
import { Location } from "react-router-dom";
import { breakpoints } from "./utils-theme";
import { period } from "constants/dummyJson";

// HOFs start here.

/**
 * Higher order function that makes a displayable string from a number.
 *
 * @param {Object} props
 *
 * @param {string | undefined} prefix
 *
 * @param {string | undefined} suffix
 *
 * @param {number} number
 *
 * @param {number} decimals Number of decimals allowed.
 *
 * @returns {string}
 */
function withNumber({ prefix = "", suffix = "", number = 1, decimals = 0 }) {
  return `${prefix}${number.toFixed(decimals)}${suffix}`;
}

/**
 * Higher order function that truncates some text.
 *
 * @param {Object} props
 * @param {number} props.maxChars Default=255
 * @param {boolean} props.hasEllipsis Default=true
 * @param {string} props.text The subject.
 *
 * @returns {string} Truncated string if it exceeds the provided limit.
 */
function withTextTruncation({ maxChars = 255, hasEllipsis = true, text }) {
  return text.length <= maxChars
    ? text
    : `${text.substring(0, maxChars)}${hasEllipsis && "..."}`;
}

// HOFs end here.

const usdFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const getCountryCodes = Object.keys(countries.countries);

export const getCountryNames = getCountryCodes
  .map((code) => countries.countries[code].name)
  .sort();

/**
 * Get a key from an array by matching an `id` to its `id`.
 * The array must have an `id` element at each object and the passed `key`.
 *
 * @param {Array} arr The array to find the key from.
 * @param {any} id The id to match from the array's id.
 * @param {string} key The key to extract from the array.
 * @returns {string | undefined} The matching key or undefined.
 */
export const getKeyFromArrUsingId = (arr, key, id) =>
  arr.find((elm) => elm.id == id)?.[key] || "";

/**
 * Get an `id` from an array by matching a `key` to its `key`.
 * The array must have an `id` element at each object and the passed `key`.
 *
 * @param {Array} arr The array to find the id from.
 * @param {string} key The key to match from the array's key.
 * @returns {string | undefined} The matching id or undefined.
 */
export const getIdFromArrUsingKey = (arr, key, value) =>
  arr.find((elm) => elm[key] == value)["id"];

/**
 * Calculate the percentage-based discount from a number.
 *
 * @param {number} value The value to be discounted.
 * @param {number} discount The percentage of discount to apply.
 */
export const getDiscounted = (value, discount) =>
  parseFloat(value - (value * discount) / 100).toFixed(2);

/**
 * Remove the discount from a value.
 * The general formula to remove the discount goes like this:
 * Original Value = Discounted Value / 1 - discount
 * @param {number} value The discounted value.
 * @param {number} discount The decimal discount value, smaller than 1.
 * @return {number} The original value without the discount.
 */
export const removeDiscount = (value, discount) => value / (1 - discount);

/**
 * Remove the percentage-discount from a value.
 * The general formula to remove the discount goes like this:
 * Original Value = Discounted Value / 1 - discount
 * @param {number} value The discounted value.
 * @param {number} discount The percentage-discount value, such as 10 or 20 as in 10 percent, 20 percent.
 * @param {number}.
 */
export const removeDiscountPercentage = (value, discount) =>
  value / (1 - discount / 100);

/**
 * Convert a value into a key-value pair.
 *
 * @param {any} value The value to be converted
 * @param {any | undefined} label A custom label, if required.
 * @returns {Object} The key-value pair.
 */
export const getObjectFromVal = (value, label) => ({
  label: label || value,
  value,
});

/**
 * Get the headers required for an authorized request.
 * @param {string} token the Authentication token.
 * @returns {import("axios").AxiosRequestHeaders} The required headers.
 */
export const getAuthHeaders = (
  token = localStorage.getItem("token")
  // timezone = localStorage.getItem("TIMEZONE")
) => ({
  Accept: "application/json",
  "Content-Type": "application/json",
  "X-Auth-Token": token,
  // "X-User-Timezone":
  //   timezone || Intl.DateTimeFormat().resolvedOptions().timeZone,
});

/**
 * @return {boolean}
 * Whether an object is an object and has atleast one entry.
 *
 * @see https://stackoverflow.com/a/32108184/14716989
 */
export const isObjectValid = (object) =>
  object &&
  Object.keys(object).length > 0 &&
  Object.getPrototypeOf(object) === Object.prototype;

/**
 * @return {boolean}
 * A realistic array is one that's actually an array and contains at least one
 * element.
 */
export const isRealisticArray = (array) =>
  array && Array.isArray(array) && array.length > 0;

export const capitalize = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const isMobile = () =>
  /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

/**
 * @see https://stackoverflow.com/a/24457420
 * @param {any} subject
 * @returns {Boolean}
 */
export const isNumeric = (subject) => /^-?\d+$/.test(subject);

/**
 * Stricly checks if even the type of a value is number o not.
 * @param {any} subject
 * @returns {Boolean}
 */
export const isStrictlyNumeric = (subject) =>
  typeof subject === "number" && isNumeric(subject);

/**
 * Get an array that's ready to paginate over.
 *
 * Well, what does that mean?
 * Aha, thought you'd ask that.
 *
 * It's an array that's sliced from and to the
 * point which you wanna show on a page, and it's
 * also reversed so the latest entries are always
 * on the first page.
 *
 * @param {Array} arr
 *
 * @param {number} currentIndex The index where the
 * page currently starts from.
 *
 * @param {number} itemsPerPage The maximum number of
 * items shown on a page.
 *
 * @returns {Array}
 */
export const getPaginationReadyArrFrom = (arr, currentIndex, itemsPerPage) => {
  const newArr = arr.slice();

  // Reverse reverses the array in place so we need to
  // call it on the array before using it.
  newArr.reverse();
  return newArr.slice(currentIndex, currentIndex + itemsPerPage);
};

export function createOptions(arr, key = "title") {
  // Solve this problem
  // arr.map(i => ({label: i?.[key], value: i?.id}));

  const options = [];
  !isEmpty(arr) &&
    arr.forEach((element) => {
      let obj = {};
      obj.value = element.id;
      obj.label = element[key];
      options.push(obj);
    });

  return options;
}

export function getKeyFromMap(map, item) {
  let foundKey;

  for (const [key, value] of map) {
    if (item == value) {
      foundKey = key;
      break;
    }
  }
  return foundKey;
}

/**
 * Creates React-Select's options from record.
 * Suitable for a simple key-value data structures. Such as the ones in static-data-v3
 *
 * @param {StaticDataV3Record} record
 * The record to create options from.
 *
 * @returns {SelectOption[]}
 *
 * @author "Abdullah-Sajjad026"
 */
export const createSelectablesV3 = (record) => {
  const options = [];

  record?.forEach((title, id) =>
    options.push({ value: parseInt(id), label: capitalize(title) })
  );

  return options;
};

export const createOptionsFromIdsArray = (
  idsArray,
  totalArray,
  labelKey = "title",
  valueKey = "id"
) => {
  return idsArray.map((id) => {
    const foundItem = totalArray.find((i) => i[valueKey] === id);
    if (foundItem) {
      return {
        label: foundItem[labelKey],
        value: foundItem[valueKey],
      };
    }
  });
};

/**
 * Creates options for react-select that are just integers.
 * @param {Object} object
 * @param {number} object.length The amount of options to generate.
 * @param {string | undefined} object.prefix The prefix to be concatenated to each option.
 * @param {number | undefined} object.startingPoint The number to start the options from.
 * @returns {{label: number | string, value: number | string}[]}
 */
export function createIntegralOptions({ length, prefix, startingPoint = 0 }) {
  const options = [];

  [...Array(length).keys()].forEach((i) => {
    if (i !== 0) {
      // No adjustments are made by default.
      const adjustedIndex = i + startingPoint;

      // The prefix might be adsent so...
      const value = prefix ? `${prefix}${adjustedIndex}` : adjustedIndex;

      // Finally, we add the adjusted value.
      options.push({ label: value, value: value });
    }
  });

  return options;
}

export function updatedLanguages(selectedLang, allLanguages) {
  const updatedArray = [];
  selectedLang.forEach((item) => {
    let obj = {};
    allLanguages.forEach((allLang) => {
      if (
        item &&
        item.language_id &&
        allLang &&
        allLang.id &&
        item.language_id.toString() === allLang.id.toString()
      ) {
        obj.value = allLang.id;
        obj.label = allLang.title;
      }
    });
    updatedArray.push(obj);
  });
  return updatedArray;
}

export function convertBase64(file) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
}

export function generateArrayOfYears() {
  var max = new Date().getFullYear();
  var min = max - 50;
  var years = [];
  for (var i = max; i >= min; i--) {
    years.push(i);
  }
  return years;
}

export function calculateExperience(from, to) {
  const fromDate = from ? new Date(from) : new Date();
  const toDate = to ? new Date(to) : new Date();

  const yearDifference = toDate.getFullYear() - fromDate.getFullYear();
  const monthDifference = toDate.getMonth() - fromDate.getMonth();
  const yearText =
    yearDifference !== 0
      ? yearDifference !== 1
        ? `${yearDifference} Years`
        : `${yearDifference} Year`
      : "";
  const monthText =
    monthDifference !== 0
      ? monthDifference !== 1
        ? `${monthDifference} Months`
        : `${monthDifference} Month`
      : "1 Month";
  const result = `~ ${yearText} ${monthText}`;
  return result;
}

export const getTutorProfileUrl = (tutorName, tutorUserId) => {
  return `${WEBSITE_URL}/tutor/${window.btoa(
    tutorUserId
  )}/profile/${slugifyString(tutorName)}`;
};

export const getTutorProductBuyUrl = ({
  gradeId,
  curriculumId,
  subjectId,
  bundleId,
  tutorUserId,
  tutorName,
}) => {
  const params = {
    grade_id: gradeId,
    curriculum_id: curriculumId,
    subject_id: subjectId,
    bundle_id: bundleId,
    tutor_id: tutorUserId,
  };
  return (
    getTutorProfileUrl(tutorName, tutorUserId) +
    `?q=${window.btoa(JSON.stringify(params))}`
  );
};

/**** Time/Date functions. ****/
/**
 * Tells if the passed `endTime` is literally
 * after the passed `startTime`.
 *
 * @param {string} startTime
 * @param {string} endTime
 * @param {string} format The format of the
 * `startTime` & `endTime` strings. Required to
 * convert these times into UTC format and compare.
 * @see https://momentjs.com/docs/#/parsing/string-format/
 *
 * @returns {boolean} Whether the `endTime` is after
 * the `startTime` or not.
 *
 * @version 0.0.1
 * @author kashan-ahmad
 *
 * @changelog
 * - 0.0.1: Initial version.
 */
export function isTimeAfter(startTime, endTime, format, check) {
  // The time needs to be converted to UTC format
  // before momentjs can parse it and conclude the difference.

  // Convert the start time to 24-hour format if it's "00"

  // Extract the minutes and seconds from the start time
  // const [startHour, startMin] = startTime.split(":");
  // const [endHour, endMinute] = endTime.split(":");
  // Convert the hour to 24-hour format if it's "00"
  // const convertedStartHour = startHour === "00" ? "24" : startHour;
  // const convertedEndHour = endHour === "00" ? "24" : endHour;
  // Reconstruct the start time with the updated hour
  // const convertedStartTime = `${convertedStartHour}:${startMin}:00`;
  // const convertedEndTime = `${convertedStartHour}:${endMinute}:00`;

  // if (convertedStartHour === "24") {
  //   return true;
  // }
  // if (check === "check" && convertedEndHour === "24") {
  //   return true;
  // }

  const from = moment(startTime, format).format("YYYY-MM-DD[T]HH:mm[Z]");
  const to = moment(endTime, format).format("YYYY-MM-DD[T]HH:mm[Z]");

  return moment(to).isAfter(from);
}

/**
 * Returns an instance of moment with the necessary adjustments applied to the passed time.
 *
 * @param {string} time The time to be adjusted.
 * @param {string} currentFormat The format of the first parameter.
 * @returns {moment.Moment}
 *
 * @version
 * - 0.0.1: Converted the passed time to UTC timezone.
 */
export const getAdjustedTime = (time, currentFormat = "YYYY-MM-DD hh:mm:ss") =>
  moment(time, currentFormat);

export function hexToRgba(hex) {
  var c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return (
      "rgba(" + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") + ",0.1)"
    );
  }
  throw new Error("Bad Hex");
}

/**
 * Generates a formData instance based on the key-value
 * pairs of an object.
 *
 * @param {Object} object
 *
 * @returns {FormData}
 */
export function getFormDataFromObject(object) {
  if (!isObjectValid(object))
    throw new Error("😑 What a mess. This object isn't even a valid object");

  const formData = new FormData();

  Object.entries(object).forEach(([key, value]) => formData.append(key, value));

  return formData;
}

export const testFileLimit = ({ file, limitInMb }) => {
  const limitInBytes = limitInMb * 1000 * 1000;
  return file.size <= limitInBytes;
};

/**
 * A utility function to bring a given element to front of a given array.
 *
 * @param {any} element The element which needs to be shifted to the front of array.
 * @param {Array} array  The actual array, containing the element, on which shifting will be performed.
 * @returns {Array} The new array in which element has been shifted.
 *
 * @version 0.0.1
 * @author Abdullah-Sajjad026
 */
export const bringElementToFrontInArray = (element, array) => {
  array.unshift(
    array.splice(
      array.findIndex((e) => JSON.stringify(e) === JSON.stringify(element)),
      1
    )[0]
  );
  return array;
};

export const slugifyString = (string) => {
  return string.toLowerCase().trim().replaceAll(" ", "-");
};

/**
 * Splits the URL by dots and returns the last two indexes as a string.
 *
 * @param {string} url
 *
 * @returns {string}
 */
export function getFileNameFromUrl(url) {
  const fileParts = url.split("/").pop().split(".");
  const fileExtension = fileParts.pop();
  const fileName = fileParts.pop();

  return `${fileName.substring(fileName.length - 10)}.${fileExtension}`;
}

/**
 * Download a resource from a URL.
 *
 * @param {string} url URL to download from.
 * @param {string} name Name of the downloaded file.
 */
export function downloadResource(url, name = "unknown") {
  if (!url) {
    console.error(
      `Missing parameter 'url' in ${downloadResource.name} function!`
    );
    return;
  }

  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      // Create a temporary link element
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = name;

      // Set 'rel' to "noopener noreferrer" for security reasons.
      link.setAttribute("rel", "noopener noreferrer");

      // Append the link to the document and click it to trigger the download
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch((error) => {
      console.error("Error downloading resource:", error);
      alert("Error downloading resource.");
    });
  // const link = document.createElement("a");

  // link.setAttribute("href", url);
  // link.setAttribute("download", name);
  // link.setAttribute("target", "_blank");
  // link.setAttribute("rel", "noreferrer");

  // document.body.appendChild(link);

  // link.click();
  // link.remove();
}

/**
 * Formats a number into a readable currency-based format.
 *
 * @param {number | bigint} number The number to format.
 * @returns {string} The formatted currency-based string.
 */
export function getFormattedPriceFrom(number) {
  if (!number) {
    console.error("🙄 Not a number.");
    return number;
  }

  return usdFormatter.format(number);
}

/**
 * Abbreviates thousands into Ks and millions into Ms and so on.
 *
 * @param {number} value The value to abbreviate
 * @param {number} digits The digits to fix the value with
 * with.
 *
 * @see https://stackoverflow.com/a/9462382
 *
 * @returns {string} The abbreviated string
 */
function getAbbrNumbersFrom(value, digits) {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];

  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;

  const item = lookup
    .slice()
    .reverse()
    .find((item) => value >= item.value);

  // In case it's a faulty value
  if (!item) return "0";

  // The value without the abbreviated part
  return (value / item.value).toFixed(digits).replace(rx, "$1") + item.symbol;
}

/**
 * Converts a number into a currency-based string and then abbreviates the
 * thousand and millions into Ks and Ms
 *
 * @param {number} value The value to convert
 *
 * @returns {string} The converted, abbreviated string, such as 1.5M for 1,500,
 * 000
 */
export function getAbbrPriceFrom(value) {
  if (Number(value) >= 0 && Number(value) < 1) {
    return `$${Number(value).toFixed(1)}`;
  } else {
    return `$${getAbbrNumbersFrom(value, 1)}`;
  }
}

/// Stringomaniacs.

/**
 * Truncate multiline paragraphs programmatically.
 *
 * @param {string} text The subject.
 * @param {number} maxChars Default=MAX_BIO_CHARACTERS
 * * @returns {string}
 */
export function getTruncatedParagraph(text, maxChars = MAX_BIO_CHARACTERS) {
  return withTextTruncation({
    text,
    maxChars,
  });
}

/**
 * Get a heading that's semantically next to the current heading you're at.
 *
 * @param {Props} props
 * @param {HeadingLevel} props.currentHeading
 *
 * @returns {HeadingLevel | "div"}
 */
export function getSemanticHeading(currentHeading = "div") {
  const headings = ["h1", "h2", "h3", "h4", "h5", "h6"];

  // Fallback for a wrong element.
  if (!headings.includes(currentHeading)) return currentHeading;

  // A div is what you need if you're already at h6.
  if (currentHeading === "h6") return "div";

  // The heading next to the current one.
  return headings[
    1 + headings.findIndex((heading) => currentHeading === heading)
  ];
}

/// Store-related functions.

/**
 * Generate's an eloquent reducer for a store with equivalent data entities.
 *
 * @param {Object} props
 *
 * @param {Object} props.state
 * Previous state of the store.
 *
 * @param {Object | undefined} props.adapter
 * The adapter to deserialize the response with. Note that the response isn't adapted if an adapter isn't provided.
 *
 * @param {{
 * data?: any,
 * key: string,
 * error?: string,
 * }} props.payload
 * A next-gen payload that decides whether to show an error or the new record.
 *
 * @param {string[]} props.actionTypes
 * All the possible action types of the reducer.
 *
 * @param {string} props.currentActionType
 * Action type being executed currently.
 *
 * @returns {Object} Updated state.
 */
export function generateReducer({
  state,
  adapter,
  payload,
  actionTypes,
  currentActionType,
}) {
  function getState() {
    return payload.error
      ? {
          data: {},
          state: "erred",
          error: payload.error || strings.DEFAULT_ERROR_MESSAGE,
        }
      : payload.loading
      ? {
          data: {},
          state: "loading",
        }
      : {
          state: "loaded",
          data: !adapter ? payload.data : adapter.deserialize(payload.data),
        };
  }

  return !actionTypes.find((actionType) => currentActionType === actionType)
    ? // If the action type is invalid, return the state as it is.
      state
    : // Otherwise, generate the state with this action type.
    !payload.key
    ? // When a key isn't forwarded, it's supposed that the state is a plain object.
      getState()
    : // Otherwise, it's an object of objects.
      {
        ...state,
        [payload.key]: getState(),
      };
}

export function getGenericResponseObject(state, key, payload) {
  return {
    ...state,
    [key]: {
      ...state[key],
      data: payload,
      status: "loaded",
    },
  };
}

export function getGenericErrorObject(state, key, error) {
  return {
    ...state,
    [key]: {
      ...state[key],
      error,
      data: [],
      status: "erred",
    },
  };
}

/// Byte-sized functions (pun intended).
export const getMegabytesFromBytes = (bytes) =>
  withNumber({
    decimals: 0,
    suffix: "MB",
    number: bytes / 1000000,
  });

/**
 * A utility function to separate mobile no from its dial code and return separated number.
 *
 * @param {number | string} fullNo - Full mobile containing dial code
 * @param {number} dialCode - The dial code to be removed
 *
 * @returns number
 */
export const getMobileWithoutCode = (fullNo, dialCode = "") =>
  parseInt(fullNo.toString().split(dialCode)[1]);

export function getShareableLinkedInURL(url) {
  return `https://www.linkedin.com/sharing/share-offsite/?url=${url}`;
}

export function getShareableTwitterURL(url, text) {
  return `https://twitter.com/intent/tweet?text=${text}&url=${url}`;
}

export function getShareableFacebookURL(url) {
  return `https://www.facebook.com/sharer.php?u=${url}`;
}

export function getModalRouteProps(path, location, state = {}) {
  return {
    to: path,
    state: {
      modalLocation: location,
      ...state,
    },
  };
}

/**
 * Get the state for a modal route.
 * @param {Location} location
 * @example navigate("/*", {state: getModalRouteState(location)})
 */
export function getModalRouteState(location, state) {
  return {
    modalLocation: location,
    ...state,
  };
}

export function getCurrentTimezone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

/**
 * Remove a timezone's offset from a specific time.
 *
 * @param {string} time The time to be corrected.
 * @param {string} zone The timezone to be removed.
 */
export function removeTimezoneFromTime(time, zone) {
  const date = new Date(time);
  const timezone = new Timezone();
  return timezone.remove(date, zone);
}

/**
 * See if the current page is visited by the user or not.
 * @returns {boolean}
 */
export function isFirstVisit() {
  return !localStorage.getItem(window.location.pathname);
}

/**
 * Set the current page as visited.
 * @returns {void}
 */
export function setPageAsVisited() {
  localStorage.setItem(window.location.pathname, true);
}

/**
 * Reset all scrollbars to the top.
 * @param {undefined | 'smooth' | 'instant' | 'auto'} behavior
 * @returns {void}
 */
export function resetAllScrollbars(behavior = "instant") {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior,
  });

  // Possible internally scrolled elements.
  const elements = document.getElementsByClassName("overflow-handler");

  if (elements) {
    Array.from(elements).forEach((element) => {
      element.scrollTo({
        top: 0,
        left: 0,
        behavior,
      });
    });
  }
}

export function getAutoSelectedTimezone(supportedTimezones) {
  const timezonesList = Object.keys(supportedTimezones);

  let timezone;

  const detectedTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  if (timezonesList.includes(detectedTimezone)) {
    timezone = detectedTimezone;
  } else {
    // Manually map the timezone default UTC
    timezone = "Etc/GMT";
  }

  return timezone;
}

/**
 * Scroll the first error into view.
 *
 * @returns {void}
 *
 * @example
 * scrollErrorIntoView();
 */
export function scrollErrorIntoView() {
  const errorElement = document.querySelector('[data-testclass="formError"]');

  if (errorElement) {
    errorElement.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }
}

/**
 * A utility function to convert time 12:15 to react-select format
 *
 * @param {array} 
 
 *
 * @returns array
 */
export const convertTimeToReactSelectFormate = (timesArray) => {
  return timesArray.map((item) => {
    const [startHour, startMinute] = item?.startTime?.split?.(":") || [];
    const [endHour, endMinute] = item?.endTime?.split?.(":") || [];

    const startPeriod = item?.startPeriod?.includes("--")
      ? "--"
      : parseInt(startHour, 10) >= 12 && parseInt(startHour, 10) <= 23
      ? "PM"
      : startHour === "--" || startHour === ""
      ? "--"
      : "AM";
    const endPeriod = item?.endPeriod?.includes("--")
      ? "--"
      : parseInt(endHour, 10) >= 12 && parseInt(endHour, 10) <= 23
      ? "PM"
      : endHour === "--" || endHour === ""
      ? "--"
      : "AM";

    const convertedStartHour =
      parseInt(startHour, 10) === 12 && startPeriod === "AM"
        ? "12"
        : startHour === "--" || startHour === ""
        ? "--"
        : String(((parseInt(startHour, 10) + 11) % 12) + 1).padStart(2, "0");

    const convertedEndHour =
      parseInt(endHour, 10) === 12 && endPeriod === "AM"
        ? "12"
        : endHour === "--" || endHour === ""
        ? "--"
        : String(((parseInt(endHour, 10) + 11) % 12) + 1).padStart(2, "0");

    return {
      startTime: {
        hour: {
          label: convertedStartHour,
          value: convertedStartHour,
        },
        minute: {
          label:
            startMinute === undefined ? "--" : startMinute?.padStart(2, "0"),
          value:
            startMinute === undefined ? "--" : startMinute?.padStart(2, "0"),
        },
        period: {
          label: startPeriod,
          value: startPeriod,
        },
      },
      endTime: {
        hour: {
          label: convertedEndHour,
          value: convertedEndHour,
        },
        minute: {
          label: endMinute === undefined ? "--" : endMinute?.padStart(2, "0"),
          value: endMinute === undefined ? "--" : endMinute?.padStart(2, "0"),
        },
        period: {
          label: endPeriod,
          value: endPeriod,
        },
      },
    };
  });
};

/**
 * A utility function to convert time from react-select format to 12:00 format
 *
 * @param {array} 
 
 *
 * @returns array
 */
export const convertToOriginalTimeFormateFromReactSelect = (
  availabilityTime
) => {
  return availabilityTime.map((item) => {
    const startHour =
      item.startTime.hour.value === "--" ? "--" : item.startTime.hour.value;
    const startMinute =
      item.startTime.minute.value === "--" ? "--" : item.startTime.minute.value;
    const startPeriod =
      item.startTime.period.value === "--" ? "--" : item.startTime.period.value;

    const endHour =
      item.endTime.hour.value === "--" ? "--" : item.endTime.hour.value;
    const endMinute =
      item.endTime.minute.value === "--" ? "--" : item.endTime.minute.value;
    const endPeriod =
      item.endTime.period.value === "--" ? "--" : item.endTime.period.value;

    let originalStartTime = "--:--";
    if (!(startHour === "--" && startMinute === "--" && startPeriod === "--")) {
      const originalStartHour =
        startPeriod === "AM" && startHour === "12"
          ? "00"
          : startPeriod === "PM" && startHour === "12"
          ? "12"
          : String(
              (parseInt(startHour, 10) + (startPeriod === "PM" ? 12 : 0)) % 24
            ).padStart(2, "0");

      originalStartTime = `${originalStartHour}:${startMinute}`;
    }

    let originalEndTime = "--:--";
    if (!(endHour === "--" && endMinute === "--" && endPeriod === "--")) {
      const originalEndHour =
        endPeriod === "AM" && endHour === "12"
          ? "00"
          : endPeriod === "PM" && endHour === "12"
          ? "12"
          : String(
              (parseInt(endHour, 10) + (endPeriod === "PM" ? 12 : 0)) % 24
            ).padStart(2, "0");

      originalEndTime = `${originalEndHour}:${endMinute}`;
    }

    return {
      startTime: originalStartTime,
      endTime: originalEndTime,
      startPeriod: startPeriod,
      endPeriod: endPeriod,
    };
  });
};

export const emptyAvailibilityCheck = (availability) => {
  return availability.some((item) => {
    return item.times.some((avail) => {
      if (avail.startTime === "--:--" || avail.endTime === "--:--") {
        return true; // Found a match, exit early
      }
      return false;
    });
  });
};

// * convert the time off values to react select object structure
export const convertOffTimeToReactSelect = (item) => {
  if (item === "" || !item) {
    return {
      time: {
        hour: {
          label: "--",
          value: "--",
        },
        minute: {
          label: "--",
          value: "--",
        },
        period: {
          label: "--",
          value: "--",
        },
      },
    };
  }

  const [hour, minute] = item?.split?.(":") || [];

  const period = item?.startPeriod?.includes("--")
    ? "--"
    : parseInt(hour, 10) >= 12 && parseInt(hour, 10) <= 23
    ? "PM"
    : hour === "--" || hour === ""
    ? "--"
    : "AM";

  const convertedStartHour =
    parseInt(hour, 10) === 12 && period === "AM"
      ? "12"
      : hour === "--" || hour === ""
      ? "--"
      : String(((parseInt(hour, 10) + 11) % 12) + 1).padStart(2, "0");

  return {
    time: {
      hour: {
        label: convertedStartHour,
        value: convertedStartHour,
      },
      minute: {
        label: minute === undefined ? "--" : minute?.padStart(2, "0"),
        value: minute === undefined ? "--" : minute?.padStart(2, "0"),
      },
      period: {
        label: period,
        value: period,
      },
    },
  };
};

// * convert the react-select object to time
export const converOffTimeToOrigionalTime = (item) => {
  const hour = item.time.hour.value === "--" ? "--" : item.time.hour.value;
  const minute =
    item.time.minute.value === "--" ? "--" : item.time.minute.value;
  const period =
    item.time.period.value === "--" ? "--" : item.time.period.value;

  let origionalTime = "--:--";
  if (!(hour === "--" && minute === "--" && period === "--")) {
    const originalStartHour =
      period === "AM" && hour === "12"
        ? "00"
        : period === "PM" && hour === "12"
        ? "12"
        : String(
            (parseInt(hour, 10) + (period === "PM" ? 12 : 0)) % 24
          ).padStart(2, "0");

    origionalTime = `${originalStartHour}:${minute}`;
  }

  return {
    time: origionalTime,
    period: period,
  };
};

// * This will checkif all the time fields of timeoff time is filled
export const checkTimeFilled = (time) => {
  const { hour, minute, period } = time.time;

  if (
    hour.value &&
    hour.value !== "--" &&
    minute.value &&
    minute.value !== "--" &&
    period.value &&
    period.value !== "--"
  ) {
    return true;
  }

  return false;
};

//* Function to check if an array has data and is not empty for onBoarding bio form
export function hasValidData(arr) {
  return arr.length && arr.some((item) => item !== undefined);
}
