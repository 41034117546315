import {apiService} from "service";
import {API_MAIN_URL} from "constants";
import {getAuthHeaders} from "utils";

export const fetchPaymentsHistory =
  ({page, onSuccess, onFailure, product_type, start_date, end_date}) =>
  /**
   * @param {import("redux").Dispatch<TransactionAction>} dispatch
   */
  (dispatch) => {
    const data = new FormData();

    if (product_type) data.append("product_type", product_type);
    if (start_date) data.append("start_date", start_date);
    if (start_date) data.append("end_date", end_date);

    apiService.postAndGet({
      params: [{page}],
      headers: getAuthHeaders(),
      data,
      url: `${API_MAIN_URL}/payment/transaction-history`,
      onSuccess: ({data}) => {
        dispatch({
          type: "SET_PAYMENT_HISTORY",
          payload: data,
        });

        onSuccess?.(data);
      },
      onFailure: (error) => {
        dispatch({
          type: "ERRED_PAYMENT_HISTORY",
        });

        onFailure?.(error);
      },
    });
  };
