import SimpleBar from "simplebar-react";
import { Card, CardHeader, Col, Row } from "reactstrap";

/**
 * @typedef {Object} ListFileProps
 *
 * @property {File[] | string[]} [files]
 */

/**
 * Generic list that displays a set of files.
 *
 * @param {ListFileProps & import("reactstrap").CardProps} props
 *
 * @returns {JSX.Element}
 *
 * @author kashan-ahmad
 *
 * @version 0.0.1
 */
function ListFile({ files, children, className = "", ...props }) {
  return (
    <Card
      {...props}
      tag={SimpleBar}
      className={`mb-0 overflow-auto ${className}`}
    >
      <CardHeader className="minw-md bg-gray-100 rounded p-3">
        <Row className="g-2 mx-0 w-100">
          <Col xs={1} />
          <Col xs={4}>Title</Col>
          <Col xs={6}>Description</Col>
        </Row>
      </CardHeader>
      {children}
    </Card>
  );
}

export default ListFile;
