import {API_MAIN_URL} from "constants";
import {apiService} from "service";
import {getAuthHeaders} from "utils";

export * from "./layout/actions";

// Common actions for learner and tutor

export const fetchRefersHistory =
  ({onSuccess, onFailure}) =>
  async (dispatch) => {
    apiService.get({
      url: `${API_MAIN_URL}/get-referrals-list`,
      headers: getAuthHeaders(),
      onSuccess: ({data}) => {
        onSuccess?.(data);
      },
      onFailure: ({error}) => {
        onFailure?.(error);
      },
    });
  };
