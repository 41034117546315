import React, { useEffect, useState } from "react";
import {
  Col,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import { IconCreditCard, IconInfoCircle } from "@tabler/icons";
import classnames from "classnames";
import { useParams } from "react-router-dom";
import SessionHistory from "./tutoring-tutor-sessions-history";
import SessionSchedule from "./tutoring-tutor-session-schedule";
import { useSelector } from "react-redux";
import { Avatar } from "components";
import { makePluralIf } from "utils";
import { LeadingBadgesList } from "components/lists";
import { useStaticDataV3 } from "hooks/static-data";

function TutoringTutorNewSessionStudentActions({
  isLearnerSelected,
  isBundleSelected,
}) {
  let { student_id, bundle_id } = useParams();

  if (isLearnerSelected) {
    student_id = parseInt(student_id);
  }
  if (isBundleSelected) {
    bundle_id = parseInt(bundle_id);
  }

  const [activeTab, setActiveTab] = useState(isBundleSelected ? "1" : "2");

  /*
        we have connectedLearners data in store. So we are getting this specific student from store
         to access its properties like bundles and student id and subject, grade, curriculum etc.
      */

  const { data: connectedLearners } = useSelector(
    (state) => state.tutorTutoring.connectedLearners
  );

  const selectedLearner = connectedLearners.find(
    (l) => l?.user_id === student_id
  );
  const selectedBundle = selectedLearner?.bundles?.find(
    (bundle) => bundle.bundle_id === bundle_id
  );

  useEffect(() => {
    setActiveTab(isBundleSelected ? "1" : "2");
    document.querySelector("#top-container")?.[0]?.scrollIntoView();
  }, [student_id, bundle_id]);

  const { curriculums, grades, subjects, getStaticData } = useStaticDataV3([
    "curriculums",
    "grades",
    "subjects",
  ]);

  const [
    { data: totalCurriculums, state: curriculumsState },
    { data: totalGrades, state: gradesState },
  ] = [curriculums, grades, subjects];

  const saneCurriculums = selectedLearner.user.curriculums.map?.((curri) =>
    getStaticData(curri.curriculum_id, totalCurriculums)
  );

  const saneGrades = selectedLearner.user.grades?.map?.((grade) =>
    getStaticData(grade.grade_id, totalGrades)
  );

  return (
    <>
      {/* showing alert on right col if user is on index path 
              because no learner is selected yet therefore 
              tutor can't perform ant student actions yet.
          */}

      <div
        className="student-info d-flex flex-column flex-sm-row  bg-light-gray p-3 align-items-sm-center"
        id="top-container"
      >
        <Avatar
          src={selectedLearner?.user?.profile_picture}
          style={{ width: "40px", height: "40px" }}
        />

        <div className="student-details ms-sm-3 mt-2 mt-sm-0">
          <div className=" d-flex align-items-center mb-2">
            <h3
              className="pe-2 m-0"
              style={{
                borderRight: isBundleSelected ? "1px solid #d5dde5 " : "",
              }}
            >
              {selectedLearner?.user?.name}
            </h3>
            {isBundleSelected && selectedBundle && (
              <>
                <IconCreditCard
                  className="ms-2"
                  style={{
                    fontSize: "20px",
                    color: `${
                      selectedBundle.remaining_credits === 0
                        ? "#EA8686"
                        : "#5CDB95"
                    }`,
                  }}
                />
                <p className="m-0 ps-1 me-1" style={{ color: "#05386b " }}>
                  {selectedBundle.remaining_credits}{" "}
                  {makePluralIf("Credit", selectedBundle.remaining_credits)}{" "}
                  Available
                </p>
              </>
            )}
          </div>
        </div>
        <div className="d-flex justify-content-start mb-2">
          <UncontrolledDropdown className="border-0">
            <DropdownToggle color="" size="sm" className="border-0 p-0">
              <IconInfoCircle
                style={{ width: "20px", height: "20px" }}
                className="text-secondary"
              />
            </DropdownToggle>
            <DropdownMenu className="p-3">
              <Row className="mx-0 gap-3">
                <Col className="ps-0" xs="12">
                  <h3 className="font-size-14 fw-bold mb-2 ">Curriculum</h3>
                  {/* @ts-ignore */}
                  <LeadingBadgesList
                    breakAt={1}
                    hasDropdown
                    entities={saneCurriculums}
                  />
                </Col>
                <Col className="ps-0" xs="12">
                  <h3 className="font-size-14 fw-bold mb-2 ">Grades</h3>
                  {/* @ts-ignore */}
                  <LeadingBadgesList
                    breakAt={1}
                    hasDropdown
                    entities={saneGrades}
                  />
                </Col>
              </Row>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </div>
      <div className=" mb-0">
        <div className="tabs mx-0 my-1">
          <Nav tabs className="nav-tabs-custom">
            {isBundleSelected && selectedBundle && (
              <NavItem className="px-3 py-1">
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: activeTab === "1",
                  })}
                  onClick={() => {
                    setActiveTab("1");
                  }}
                >
                  <span className="d-block d-sm-none">
                    <i className="fas fa-home"></i>
                  </span>
                  <span className="d-none d-sm-block">Schedule Session</span>
                </NavLink>
              </NavItem>
            )}

            <NavItem className="px-3 py-1">
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: activeTab === "2",
                })}
                onClick={() => {
                  setActiveTab("2");
                }}
              >
                <span className="d-block d-sm-none">
                  <i className="far fa-user"></i>
                </span>
                <span className="d-none d-sm-block">History</span>
              </NavLink>
            </NavItem>
          </Nav>
        </div>

        <div className="p-3">
          {isBundleSelected && selectedBundle && activeTab === "1" && (
            <SessionSchedule
              learner={selectedLearner}
              bundle={selectedBundle}
            />
          )}

          {/* ======================== */}
          {activeTab === "2" && <SessionHistory />}
        </div>
      </div>
    </>
  );
}

export default TutoringTutorNewSessionStudentActions;
