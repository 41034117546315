import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Collapse,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
//Import Breadcrumb
import { Breadcrumb } from "../../components";
import userpic from "../../assets/images/support/user-thumb.png";

import { TextField } from "../../components";
import TicketList from "./TicketList";

// data
import { mytickets } from "./titckets";
import TicketDetails from "./TicketDetails";

function SupportListing() {
  const [ticket, setTicket] = useState([]);
  const [isOpen, setIsOpen] = React.useState(true);
  // const getSupportDetails = (id) => {

  //       mytickets.filter((data.id === ))

  // }

  const handleList = (val) => {
    let ticketData = mytickets.filter((data) => {
      if (data.id === val) {
        return data;
      }
    });

    setTicket(ticketData[0]);
  };

  useEffect(() => {
    setTicket(mytickets[0]);
  }, []);

  return (
    <section className="content learner-main-dashboard">
      {/* <Breadcrumb
        parentRouteName="Dashboard"
        activeRouteName="support"
        className="border"
        listClassName="border"
      /> */}
      <div className="learner-main-dashboard-container"></div>
      <Container fluid={false}>
        <Row>
          <Col
            lg="6"
            md="12"
            sm="12"
            xs="12"
            className="bg-light-gray border-bottom"
          >
            <div
              className="list-group w-auto"
              onClick={() => setIsOpen(!isOpen)}
            >
              <div className="list-group-item bg-light-gray">
                <div className="helper-search">
                  <div className="d-flex align-items-center">
                    <div className="flex-grow-1 ms-3">
                      <div className="position-relative">
                        <input
                          type="text"
                          className="form-control helper-search-input"
                          placeholder="Search..."
                        />
                        <span className="bx bx-search"></span>
                      </div>
                    </div>
                    <div className="flex-shrink-0" style={{ zIndex: "100" }}>
                      <div className="col-xs-1 col-sm-1 col-md-1 d-flex align-items-center text-secondary ">
                        <div
                          className="badge border bg-light-gray text-secondary mx-1"
                          style={{ fontSize: "18px" }}
                        >
                          <i className="bx bx-sync"></i>
                        </div>
                        <div
                          className="badge border bg-light-gray text-secondary"
                          style={{ fontSize: "18px" }}
                        >
                          <i className="bx bx-filter-alt"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* render tickets list  */}
            <Collapse isOpen={isOpen}>
              {mytickets.map((item, i) => (
                <TicketList
                  key={item.id}
                  ticketNo={item.id}
                  title={item.title}
                  date={item.date}
                  status={item.status}
                  onTicketClick={(e) => handleList(e)}
                />
              ))}
            </Collapse>
          </Col>
          <Col
            lg="6"
            md="12"
            sm="12"
            xs="12"
            className="bg-light-gray border-bottom border-start"
          >
            <Row>
              <div className="list-group w-auto">
                <a
                  href="#"
                  className="list-group-item bg-light-gray border-0 border-left list-group-item-action d-flex gap-3 py-3"
                  aria-current="true"
                >
                  <img
                    src={userpic}
                    alt="twbs"
                    width="32"
                    height="32"
                    className="rounded-circle flex-shrink-0"
                  />
                  <div className="d-flex gap-2 w-100 justify-content-between">
                    <div>
                      <h6 className="mb-1">Karl Cronin</h6>
                      <p className="mb-0 opacity-75">
                        <span className="text-muted">Last session</span>
                      </p>
                    </div>
                  </div>
                </a>
              </div>
            </Row>
            <Row>
              <Col md="12" lg="12" className="px-0">
                <Nav tabs className="nav-tabs-custom  bg-light text-secondary">
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className="active pt-3"
                    >
                      {/* <span className="d-block d-sm-none"><i className="fas fa-home"></i></span> */}
                      <span className="d-block px-3 text-secondary">
                        Problem Details
                      </span>
                    </NavLink>
                  </NavItem>
                </Nav>
                {ticket && <TicketDetails data={ticket} />}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default SupportListing;
