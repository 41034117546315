import { SassyAlert } from "components";
import strings from "constants/strings";
import { Link } from "react-router-dom";
import { Card, CardBody } from "reactstrap";

// Static.
import dashedCircleImage from "assets/images/sidebar-eclipse.png";

/**
 * A card but there's a problem.
 *
 * @param {Object} props
 * @param {string} props.message
 * @param {"error" | "empty"} props.type
 *
 * @returns {JSX.Element}
 *
 * @author kashan-ahmad
 * @version 0.0.1
 */
function ErrorCard({ message, type = "error" }) {
  const title =
    type === "empty"
      ? strings.DEFAULT_EMPTY_MESSAGE
      : strings.DEFAULT_ERROR_MESSAGE;

  const description = type !== "empty" && (
    <>
      Please try reloading the page or
      <Link to="/support">contact support</Link>.
    </>
  );

  return (
    <Card className="h-100 border-1 rounded position-relative overflow-hidden">
      <img
        alt=""
        src={dashedCircleImage}
        className="h-100 position-absolute start-0 bottom-0"
        style={{
          transform: "translate(-2.25rem, 1.5rem) rotate(180deg)",
        }}
      />
      <CardBody className="d-flex justify-content-center align-items-center">
        <SassyAlert
          {...{ description }}
          className="text-center"
          title={message || title}
          color={type === "empty" ? "info" : "danger"}
        />
      </CardBody>
    </Card>
  );
}

export default ErrorCard;
