import { PaginationItem, PaginationLink } from "reactstrap";

/**
 * Custom pagination item, extended from Reactstrap's PaginationItem.
 *
 * @param {Object} props
 *
 * @param {number} props.index
 *
 * @param {boolean} props.isActive Default=false
 *
 * @param {boolean} props.isDisabled Default=false
 *
 * @param {import("react").ReactElement} props.children
 * Custom node to display instead of the calculated page number.
 *
 * @param {import("./pagination-pages").PaginationPagesProps['onChangePage']}
 *
 * @returns {JSX.Element}
 *
 * @author kashan-ahmad
 * @version 0.0.1
 */
function MyPaginationItem({
  index,
  children,
  onChangePage,
  isActive = false,
  isDisabled = false,
}) {
  return (
    <PaginationItem active={isActive}>
      <PaginationLink
        disabled={isDisabled}
        onClick={() => isActive || isDisabled || onChangePage(1 + index)}
        className={`fw-bold fs-6 ${
          isActive
            ? "text-success border-0 rounded-circle"
            : "border-0 text-secondary"
        }`}
        style={{
          width: "32px",
          height: "32px",
          whiteSpace: "nowrap",
          backgroundColor: isActive ? "#ECF9F3" : "transparent",
        }}
      >
        {children || index + 1}
      </PaginationLink>
    </PaginationItem>
  );
}

export default MyPaginationItem;
