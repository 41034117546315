import React from "react";

type Props = React.ComponentPropsWithoutRef<"small"> & {
  limit: string | number;
};

export default function MaxLimitHint({ limit, ...props }: Props) {
  return (
    <small className="fw-normal text-muted" {...props}>
      (Max Limit: {limit})
    </small>
  );
}
