import React from "react";
import styles from "./icon.module.scss";

/**
 * A general purpose (polymorphic) Icon component
 * with a rounded background that's adjusted with
 * the `size` attribute.
 *
 * Can be passed a `size` with one of the following values:
 * - sm
 * - md (default)
 * - lg
 *
 * @param {Object} object
 * The forwarded props.
 *
 * @param {string} object.tag
 * The HTML tag, defaults to span.
 *
 * @param {"xs" | "sm" | "md" | "lg" | "xl"} object.size
 * The size of the icon.
 *
 * @param {string} object.className
 * The className of the tag
 *
 * @param {React.ReactElement} object.children
 * The icon to render, can be literally anything.
 *
 * @returns {JSX.Element}
 * @version 0.0.1
 * @author kashan-ahmad
 */
function IconRounded({
  tag: Tag = "span",
  className = "",
  size = "sm",
  children,
  ...props
}) {
  return (
    <Tag
      {...props}
      className={`${styles[`icon-rounded-${size}`]} ${className}`}
    >
      {children}
    </Tag>
  );
}

export default IconRounded;
