/* eslint-disable eqeqeq */
import * as React from "react";

// Components
// import { Badge } from "reactstrap";
// import { IconBell } from "@tabler/icons";
// import { Dropdown } from "react-bootstrap";
// import { Icon, ListDividerVertical, Loader, Notification } from "components";

// // Utils.
// import { Link } from "react-router-dom";
// import { useNotifications } from "hooks";

// Views.

/**
 * The dropdown that shows unread notifications
 * from the navigation bar.
 *
 * @returns {JSX.Element}
 *
 * @version 0.0.3
 * @author kashan-ahmad
 *
 * @changelog
 * - 0.0.3: Removed the wrapper Link component around the Notification component.
 * - 0.0.2: Integrated the notifications API using useNotifications hook.
 * - 0.0.1: Initial version.
 */
function NotificationsDropdown() {
  return <></>;
  // const { isErred, isLoaded, data: notifications } = useNotifications();

  // // There exists a `seen` property that tells it.
  // const unreadNotifications = React.useMemo(
  //   () => notifications.filter(({ seen }) => seen == 0),
  //   [notifications]
  // );

  // return (
  //   <Dropdown tag="li" align="end" className="border-0">
  //     <Dropdown.Toggle
  //       title="Show unread notifications"
  //       className="header-item bg-transparent border-0"
  //     >
  //       <Icon.Rounded
  //         size="md"
  //         className={`position-relative bg-gray-300 text-secondary ${
  //           unreadNotifications.length > 0 ? "bx-tada text-success" : ""
  //         }`}
  //       >
  //         <IconBell />
  //       </Icon.Rounded>
  //       {unreadNotifications.length > 0 && (
  //         <Badge
  //           color="danger"
  //           style={{
  //             top: "7.5px",
  //             right: "7.5px",
  //             height: "1rem",
  //             minWidth: "1rem",
  //           }}
  //           className="rounded-circle position-absolute p-1"
  //         >
  //           {unreadNotifications.length}
  //         </Badge>
  //       )}
  //     </Dropdown.Toggle>
  //     <Dropdown.Menu
  //       className="border-1 dropdown-menu-lg"
  //       style={{
  //         maxHeight: "80vh",
  //         height: "max-content",
  //         overflowY: "auto",
  //       }}
  //     >
  //       {isErred ? (
  //         !navigator.onLine ? (
  //           <Dropdown.Item className="px-3">
  //             <div className="alert alert-danger mb-0">
  //               Please check your network connection & try again!
  //             </div>
  //           </Dropdown.Item>
  //         ) : (
  //           <Dropdown.Item className="px-3">
  //             <div className="alert alert-danger mb-0">
  //               There was an issue loading your notifications, please try again!
  //             </div>
  //           </Dropdown.Item>
  //         )
  //       ) : !isLoaded ? (
  //         <Loader isInline />
  //       ) : unreadNotifications && unreadNotifications.length === 0 ? (
  //         <Dropdown.Item className="px-3">
  //           <div className="alert alert-success mb-0">
  //             Nothing here! Have a good day.
  //           </div>
  //         </Dropdown.Item>
  //       ) : (
  //         unreadNotifications.map((notification, index, arr) => (
  //           <Dropdown.Item as="li" key={index} className="p-0">
  //             <Notification isCompact {...notification} />
  //             <ListDividerVertical currentIndex={index} listSize={arr.length} />
  //           </Dropdown.Item>
  //         ))
  //       )}
  //       <Dropdown.Item
  //         as={Link}
  //         to="/notifications"
  //         className="text-center text-primary"
  //       >
  //         All notifications
  //       </Dropdown.Item>
  //     </Dropdown.Menu>
  //   </Dropdown>
  // );
}

export default NotificationsDropdown;
