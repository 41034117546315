import React from "react";
import {
  fetchTutorBanks,
  removeBankAccount,
  makeBankAccountDefault,
} from "store/store-earnings";
import ToastFactory from "utils/toast-factory";
import { useDispatch, useSelector } from "react-redux";
import { messages, questions } from "constants/strings";

function useBanks() {
  const dispatch = useDispatch();
  const toastFactory = ToastFactory();

  const banksData = useSelector((state) => state.earnings.banksData);

  React.useEffect(() => {
    if (!banksData.isLoaded) {
      dispatch(fetchTutorBanks());
    }
    return () => new AbortController().abort();
  }, [banksData.isLoaded, dispatch]);

  const onRemoveListener = ({ accountHandle, is_default }) => {
    if (is_default) {
      toastFactory.error(messages.ACCOUNT_DEFAULT_ERR_REMOVE("bank account"));
      return;
    }

    if (window.confirm(questions.YOU_SURE)) {
      toastFactory.loading();

      dispatch(
        removeBankAccount({
          accountHandle,
          onSuccess: () => toastFactory.dismiss(),
          onFailure: () =>
            toastFactory.error(messages.REMOVING_ERR("Bank account")),
        })
      );
    }
  };

  const onMakeDefaultListener = ({ accountHandle, is_default }) => {
    if (is_default) {
      toastFactory.info(messages.ACCOUNT_DEFAULT_ALR("bank account"));
      return;
    }

    toastFactory.loading();

    dispatch(
      makeBankAccountDefault({
        accountHandle,
        onSuccess: () => toastFactory.dismiss(),
        onFailure: () => {
          toastFactory.error(messages.ACCOUNT_DEFAULT_ERR_SET("bank account"));
        },
      })
    );
  };

  return { ...banksData, onRemoveListener, onMakeDefaultListener };
}

export default useBanks;
