import React from "react";
import {Loader} from "components";
import TutoringTutorNewSessionStudentActions from "./tutoring-tutor-ns-student-actions";

const View = React.lazy(() => import("./tutoring-tutor-new-session"));

/**
 * @author Abdullah-Sajjad026
 */
const TutoringTutorNewSession = (props) => (
  <React.Suspense fallback={<Loader />}>
    <View {...props} />
  </React.Suspense>
);

export default TutoringTutorNewSession;
export {TutoringTutorNewSessionStudentActions};
