import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
import rootSaga from "./sagas";

// const persistConfig = {
//   key: "auth",
//   storage: storage,
//   whitelist: ["auth"], // which reducer want to store
// }

// const pReducer = persistReducer(persistConfig, rootReducer)

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware, thunk];
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middlewares))
);

sagaMiddleware.run(rootSaga);

// const persistor = persistStore(store)

export { store };

// External modules.
export * from "./store-tutoring-tutor";
export * from "./store-tutoring-learner";
export * from "./store-notification";
export * from "./tutordashboard";
export * from "./store-content-builder";
export * from "./store-transactions";
export * from "./store-earnings";
export * from "./store-payments";
