import React from "react";
import {useSelector} from "react-redux";
import {getModalRouteProps} from "utils";
import {Link, useLocation} from "react-router-dom";

// Components.
import Expired from "./expired";
// import {Button} from "reactstrap";
// import {RLearnerTutoring} from "constants/learner-views-routes";

// Types.
import type {ReduxStore} from "constants/";
import type {SessionStatusCardVariantProps} from "../session-status-card-types";
// import useSessionActions from "hooks/sessions/useSessionActions";
// import {useStaticDataV3} from "hooks/static-data";
import SessionDetailsButton from "../session-details-button";
import Label from "components/label";

export default function Pending({
  isExpired,
  renderer,
  isDetailOpen,
  onViewDetails,
  onActionSuccess,
  ...session
}: SessionStatusCardVariantProps) {
  const {sent_by, session_id, id} = session;

  const currentUserDetails = useSelector(
    (state: ReduxStore) => state.auth.userDetails
  );

  // const {subjects, getStaticData} = useStaticDataV3(["subjects"]);

  // // The corresponding hook.
  // const {handleSessionCancel, handleSessionConfirm} = useSessionActions();

  if (isExpired)
    return (
      <Expired
        {...session}
        {...{isExpired, renderer, isDetailOpen, onViewDetails}}
      />
    );

  return (
    <>
      {/* Pending Session */}

      <div className="vstack gap-2 flex-md-wrap flex-lg-nowrap align-items-end">
        {sent_by === currentUserDetails.id && (
          <Label tag="span" color="info" className="my-2 text-secondary ">
            Pending Confirmation
          </Label>
        )}
        <SessionDetailsButton
          sessionId={session_id}
          requestId={id}
          {...{renderer, isDetailOpen, onViewDetails}}
        />
      </div>

      {/* {sent_by === currentUserDetails.id ? (
        // is sent by same user
        <div className="vstack gap-2 flex-md-wrap flex-lg-nowrap align-items-end">
          <span className="">Pending Acceptance</span>

          <div>
            <Link
              type="button"
              className="btn btn-outline-info m-1"
                          // TODO: fix link when uncommenting

              to={RLearnerTutoring.getSessionRescheduleView(session_id)}
            >
              Reschedule
            </Link>
            <Button
              outline
              color="danger"
              className="m-1"
              onClick={() =>
                handleSessionCancel({
                  onSuccess: onActionSuccess,
                  startTime: start_time,
                  endTime: end_time,
                  date,
                  userName: name,
                  userPicture: profile_picture,
                  requestId: id,
                  subjectName: getStaticData(subject_id, subjects.data),
                })
              }
            >
              Cancel
            </Button>
          </div>
        </div>
      ) : (
        // is sent by other user
        
        <div className="hstack gap-2 flex-md-wrap flex-lg-nowrap ">
          <Link
            className="m-1 btn btn-outline-secondary"
            {...getModalRouteProps("/modal/session/reject", location, {
              requestId: id,
            })}
          >
            Reject
          </Link>

          <Link
            type="button"
            className="btn btn-outline-info"
                        // TODO: fix link when uncommenting

            to={RLearnerTutoring.getSessionRescheduleView(session_id)}
          >
            Reschedule
          </Link>

          <button
            type="button"
            className="btn btn-success "
            onClick={() =>
              handleSessionConfirm({onSuccess: onActionSuccess, requestId: id})
            }
          >
            Accept
          </button>
        </div>
      )} */}
    </>
  );
}
