import {
  Avatar,
  Loader,
  Pagination,
  SassyAlert,
  FindTutorModal,
  SassyCard,
  SassyCardBody,
} from "components";
import { LeadingBadgesList } from "components/lists";
import { DEFAULT_ORDERED_BUNDLES_PER_PAGE } from "constants";
import { useEffect, useState } from "react";
import { IconCreditCard } from "@tabler/icons";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchLearnerOrderedBundles, fetchRecentTutorsList } from "store";
import { RESET_ORDERED_BUNDLES } from "store/store-tutoring-learner/store-tutoring-learner-types";
import { getTutorProfileUrl, makePluralIf } from "utils";

import {
  Button,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import { useStaticDataV3 } from "hooks/static-data";
import { ListItemUser } from "components/list-items";
import { RLearnerTutoring } from "constants/learner-views-routes";

const TutoringLearnerBundlesList = ({ tutorId, setSelectedBundleId }) => {
  const { curriculums, grades, subjects, getStaticData } = useStaticDataV3([
    "curriculums",
    "grades",
    "subjects",
  ]);

  const [
    { data: totalCurriculums, state: curriculumsState },
    { data: totalGrades, state: gradesState },
    { data: totalSubjects, state: subjectsState },
  ] = [curriculums, grades, subjects];

  const isStaticDataLoaded =
    curriculumsState === "loaded" &&
    gradesState === "loaded" &&
    subjectsState === "loaded";
  const isStaticDataErred = [
    curriculumsState,
    gradesState,
    subjectsState,
  ].includes("erred");

  // const [renderedBundles, setRenderedBundles] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recentTutorsModalState, setRecentTutorsModalState] = useState("close");

  const dispatch = useDispatch();

  const { orderedBundles, recentTutors } = useSelector((state) => ({
    orderedBundles: state.learnerTutoring.orderedBundles,
    recentTutors: state.learnerTutoring.recentTutors,
  }));

  const selectedTutor = recentTutors.data?.find((t) => t.user_id == tutorId);

  useEffect(() => {
    if (recentTutors.state === "loading") dispatch(fetchRecentTutorsList());

    dispatch({ type: "RESET_ORDERED_BUNDLES" });

    const proposal = {
      limit: DEFAULT_ORDERED_BUNDLES_PER_PAGE,
      page: currentPage,
    };
    if (tutorId) proposal.tutorUserId = tutorId;
    // if (bundleId) proposal.bundle_id = bundleId;
    dispatch(fetchLearnerOrderedBundles(proposal));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, tutorId]);

  // // filtering out bundles for which remaining credits are above 0 so that a session request can be sent.
  // const eligibleBundles = [
  //   ...orderedBundles.data?.filter((b) => b.remaining_credits > 0),
  // ];

  /* whenever a new bundle is selected by tutor, we are copying current orders in temp variable.
   Then we are resetting ordered bundles in state and sorting the temp variable array.
    Then we store that array in ordered bundles data in state. */
  // useEffect(() => {
  //   if (!orderedBundles.loading) {
  //     const currentBundles = [...orderedBundles.data];
  //     setRenderedBundles([]);
  //     // TODO: add comment here
  //     if (currentBundles.length === 0) return;

  //     if (selectedBundle) {
  //       // bringing selected bundle in front from currently page displayed bundles.
  //       const sortedPageDisplayedBundles = bringElementToFrontInArray(
  //         selectedBundle,
  //         currentBundles
  //       );

  //       // replacing these DEFAULT_ORDERED_BUNDLES_PER_PAGE bundles in all rendered bundles array
  //       // for (let i = 0; i < DEFAULT_ORDERED_BUNDLES_PER_PAGE; i++) {
  //       //   if (isEmpty(sortedPageDisplayedBundles)) break;
  //       //   currentBundles[i] = sortedPageDisplayedBundles[0];
  //       //   sortedPageDisplayedBundles.splice(0, 1);
  //       // }
  //     }

  //     // setting rendered bundles to new sorted bundles array
  //     setRenderedBundles(currentBundles);
  //   }

  //   // whenever a new bundle is selected, then we are sorting array to bring selected bundle on top on page displayed bundles.
  // }, [selectedBundle, orderedBundles.loading]);

  return (
    <div className="sessions">
      <div className="hstack justify-content-between mb-3">
        <h3 className="fw-bold font-size-20 mt-3 mb-3">
          Available {makePluralIf("Session", orderedBundles.data.length)}
        </h3>
        <div className="hstack gap-2">
          {recentTutors.state === "loaded" && !!selectedTutor && (
            <>
              <h3 className="fs-5">Choose Tutor: </h3>
              <UncontrolledDropdown size="lg">
                <DropdownToggle className="bg-transparent border-0">
                  <ListItemUser
                    username={selectedTutor.name}
                    avatarSrc={selectedTutor.profile_picture}
                    usernameClass={"text-nowrap"}
                  />
                </DropdownToggle>
                <DropdownMenu
                  style={{ maxHeight: "250px", overflow: "auto" }}
                  className="bg-light"
                >
                  {recentTutors.data.map((t) => (
                    <DropdownItem className="p-2 mb-2">
                      <Link
                        to={RLearnerTutoring.getTutorAvailableSessionsView(
                          t.user_id
                        )}
                        onClick={() => setCurrentPage(1)}
                      >
                        <ListItemUser
                          username={t.name}
                          avatarSrc={t.profile_picture}
                        />
                      </Link>
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </UncontrolledDropdown>
            </>
          )}
        </div>
      </div>

      {orderedBundles.state === "erred" || isStaticDataErred ? (
        <SassyAlert
          className="my-3"
          color="danger"
          title="An error has occurred while loading your available sessions."
          description="Try Again or in worst case contact support."
        />
      ) : orderedBundles.state === "loading" || !isStaticDataLoaded ? (
        <Loader isInline />
      ) : (
        orderedBundles.state === "loaded" && (
          <>
            {orderedBundles.data.length === 0 ? (
              <SassyAlert
                className="my-3"
                color="info"
                title="Find a tutor"
                rightWing={
                  <Button onClick={() => setRecentTutorsModalState("open")}>
                    Find Now
                  </Button>
                }
              />
            ) : (
              <>
                {/* <SimpleBar className="new-session-available-items-box"> */}
                <Row className="g-3">
                  {orderedBundles.data.map((b) => {
                    /// Data uniformation starts here.

                    /**
                     * Loop-able grades.
                     */
                    const saneGrades = b.grade_id.map((id) =>
                      getStaticData(id, totalGrades)
                    );

                    /**
                     * Loop-able curriculums.
                     */
                    const saneCurriculums = b.curriculum_id.map((id) =>
                      getStaticData(id, totalCurriculums)
                    );

                    /// Data uniformation ends here.

                    const hasRemainingCredits = b.remaining_credits > 0;
                    // const isSelectedBundle =
                    //   selectedBundle.bundle_id === b.bundle_id;

                    // const Component = hasRemainingCredits ? Link : "div";
                    // const props = {
                    //   className: "text-secondary",
                    //   ...(hasRemainingCredits && {
                    //     to: ,
                    //   }),
                    // };

                    // rgb(218 227 246)

                    return (
                      <Col sm="6" lg="4">
                        <SassyCard
                          className={`h-100 
                          ${!hasRemainingCredits && "bg-soft bg-danger"}
                          `}
                          // style={{
                          //   backgroundColor: !hasRemainingCredits && "#EA8686 ",
                          // }}
                          key={b.bundle_id}
                        >
                          <SassyCardBody className="text-inherit">
                            <div className="d-flex align-items-center">
                              <Avatar
                                className="icon-lg"
                                src={b.profile_picture}
                                alt="Tutor Image"
                              />
                              {/* <img
                      src={b.profile_picture || USER_AVATAR}
                      className="rounded-circle"
                      alt=""
                      style={{
                        width: "40px",
                        height: "40px",
                        objectFit: "cover",
                      }}
                    /> */}
                              <div className="ms-3 ">
                                <h4 className="card-title  font-size-14">
                                  {b.tutor_name}
                                </h4>
                                <p
                                  className="m-0"
                                  style={{ color: "#05386b " }}
                                >
                                  <IconCreditCard
                                    className="me-2"
                                    style={{
                                      fontSize: "20px",
                                      color: `${
                                        b?.remaining_credits <= 0
                                          ? "#EA8686"
                                          : "#5CDB95"
                                      }`,
                                    }}
                                  />
                                  {b.remaining_credits}{" "}
                                  {b.remaining_credits > 1
                                    ? "Credits"
                                    : "Credit"}{" "}
                                  Available
                                </p>
                              </div>
                            </div>

                            <div className="mt-2">
                              <p className="m-0 mb-1 text-secondary-dim font-size-13">
                                Subject
                              </p>
                              <p className="m-0 font-size-14">
                                {getStaticData(b.subject_id, totalSubjects)}
                              </p>
                            </div>
                            <div className="mt-2">
                              <p className="m-0 mb-1 text-secondary-dim font-size-13">
                                Curricula
                              </p>
                              <LeadingBadgesList
                                breakAt={2}
                                hasDropdown
                                entities={saneCurriculums}
                              />
                              {/* <p className="m-0 font-size-14">
                            {getKeyFromArrUsingId(
                              totalCurriculums,
                              "title",
                              b.curriculum_id
                            )}
                          </p> */}
                            </div>
                            <div className="mt-2">
                              <p className="m-0 mb-1 text-secondary-dim font-size-13">
                                Grades
                              </p>
                              <LeadingBadgesList
                                breakAt={2}
                                hasDropdown
                                entities={saneGrades}
                              />
                              {/* <p className="m-0 font-size-14">
                            {getKeyFromArrUsingId(
                              totalGrades,
                              "grade",
                              b.grade_id
                            )}
                          </p> */}
                            </div>
                            <div className="text-end mt-auto ">
                              {!hasRemainingCredits ? (
                                <a
                                  className="btn btn-success mt-3"
                                  href={getTutorProfileUrl(
                                    b.tutor_name,
                                    b.user_id
                                  )}
                                >
                                  Top-up credits
                                </a>
                              ) : (
                                <Button
                                  onClick={() => {
                                    setSelectedBundleId(b.bundle_id);
                                  }}
                                >
                                  Book Session
                                </Button>
                              )}
                            </div>
                          </SassyCardBody>
                        </SassyCard>
                      </Col>
                    );
                  })}
                </Row>
                {/* </SimpleBar> */}

                <Pagination
                  type="pages"
                  className="mt-3"
                  totalItems={
                    orderedBundles.number_of_pages *
                    DEFAULT_ORDERED_BUNDLES_PER_PAGE
                  }
                  itemsPerPage={DEFAULT_ORDERED_BUNDLES_PER_PAGE}
                  currentPage={currentPage}
                  onChangePage={(newPage) => {
                    dispatch({ type: RESET_ORDERED_BUNDLES });
                    setCurrentPage(newPage);
                  }}
                />
              </>
            )}
            <FindTutorModal
              isOpen={recentTutorsModalState === "open"}
              toggleModal={() => {
                setRecentTutorsModalState("close");
              }}
            />
          </>
        )
      )}
    </div>
  );
};

export default TutoringLearnerBundlesList;
