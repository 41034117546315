import React from "react";
import { Loader } from "components";
import { lazy, Suspense } from "react";

const View = lazy(() => import("./AddBio"));

const AddBio = (props) => (
  <Suspense fallback={<Loader />}>
    <View {...props} />
  </Suspense>
);

export default AddBio;
