import * as React from "react";
import { getMobileWithoutCode } from "utils";
import PhoneInput from "react-phone-input-2";
import { FormErrorMessage } from "components";
import { PhoneNumberFormState } from "./phone-number-form-types";
import { ErrorMessage, FormikHandlers, FormikHelpers } from "formik";

type Props = {
  name: string;
  value: string;
  handleChange: FormikHandlers["handleChange"];
  setFieldValue: FormikHelpers<PhoneNumberFormState>["setFieldValue"];
  setFieldTouched: FormikHelpers<PhoneNumberFormState>["setFieldTouched"];
};

export default function PhoneNumberFormFields({
  name,
  value,
  handleChange,
  setFieldValue,
  setFieldTouched,
}: Props) {
  return (
    <>
      <label htmlFor={name}>Mobile Number</label>
      <div role="group" aria-label="Mobile number input group">
        <PhoneInput
          {...{ name, value }}
          country="ae"
          inputClass="w-100"
          onChange={(value: string, { dialCode }: { dialCode: number }) => {
            const countryCode = dialCode || "";
            const mobileNumber = getMobileWithoutCode(value, dialCode) || "";

            // Change the hidden values.
            setFieldValue("mobileNumber", mobileNumber);
            setFieldValue("countryCode", `+${countryCode}`);

            // Change the actual form field.
            handleChange({
              target: {
                name,
                value: `${countryCode}${mobileNumber}`,
              },
            });
          }}
          onBlur={() => setFieldTouched(name)}
        />
      </div>
      <ErrorMessage
        name={name}
        render={(message) => <FormErrorMessage message={message} />}
      />
    </>
  );
}
