import { object, string } from "yup";
import { OTP_LENGTH } from "constants/env";
import { messages, requirements } from "constants/strings";

export default class OtpSchema {
  constructor() {
    return object({
      code: string()
        .required(requirements.INIT("OTP"))
        .min(OTP_LENGTH, messages.TOO_SHORT)
        .max(OTP_LENGTH, messages.TOO_LONG),
    });
  }
}
