import React from "react";
import PhoneInput from "react-phone-input-2";

type Props = Omit<Parameters<typeof PhoneInput>[0], "onChange"> & {
  onChange?: (
    /**
     * The full phone number in international format.
     */
    value: string,
    {
      dialCode,
      strippedPhoneNumber,
    }: {
      /**
       * The dial code of the selected country.
       */
      dialCode: string;

      /**
       * The phone number without the dial code.
       */
      strippedPhoneNumber: string;
    }
  ) => void;
};

export default function PhoneField({
  onChange,
  inputClass = "",
  containerClass = "",
  ...props
}: Props) {
  function onChangeListener(value: string, { dialCode }: { dialCode: string }) {
    const strippedPhoneNumber = value.slice(dialCode.length);
    onChange?.(value, { dialCode, strippedPhoneNumber });
  }

  return (
    <PhoneInput
      {...props}
      onChange={onChangeListener}
      containerClass={`w-100 ${containerClass}`}
      inputClass={`w-100 h-100 form-control ${inputClass}`}
    />
  );
}
