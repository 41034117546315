import React from "react";
import { Loader } from "components";

const View = React.lazy(() => import("./tutoring-tutor-bundle-controller"));

const TutoringTutorBundle = (props) => (
  <React.Suspense fallback={<Loader />}>
    <View {...props} />
  </React.Suspense>
);

export default TutoringTutorBundle;
