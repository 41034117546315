/* eslint-disable eqeqeq */
import { capitalize } from "utils";
import styles from "./credit-card.module.scss";
import creditCardImage from "assets/images/credit-card/@x2/credit-card.png";

/**
 * A generic credit card wrapper for a user's credit card markup.
 * @param {PaymentMethod} object
 * @returns {JSX.Element}
 *
 * @author kashan-ahmad
 * @version 0.0.1
 */
const CreditCard = ({
  last4,
  exe_year,
  exe_month,
  is_default,
  holder_name,
  payment_brand,
}) => {
  return (
    <div className={`rounded-4 position-relative ${styles.container}`}>
      <img
        alt=""
        src={creditCardImage}
        className="w-100 h-100 object-contain object-right"
      />
      <div className={styles.holder}>{holder_name}</div>
      <div className={styles.expiry}>
        {exe_month}/{exe_year.toString().substring(2)}
      </div>
      <div className={styles.brand}>{capitalize(payment_brand)}</div>
      <div className={styles.number}>{last4}</div>
      {is_default == 1 && (
        <div className="badge bg-success p-2 fw-bold position-absolute top-0 end-0">
          Default
        </div>
      )}
    </div>
  );
};

export default CreditCard;
