import {combineReducers} from "redux";

// // Front
import Layout from "./layout/reducer";

// // Authentication
// import Login from "./auth/login/reducer"
// import Account from "./auth/register/reducer"
// import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer";

// //E-commerce
// import ecommerce from "./e-commerce/reducer"

// //Calendar
// import calendar from "./calendar/reducer"

// //chat
// import chat from "./chat/reducer"

// //crypto
// import crypto from "./crypto/reducer"

// //invoices
// import invoices from "./invoices/reducer"

// //projects
// import projects from "./projects/reducer"

// //tasks
// import tasks from "./tasks/reducer"

// //contacts
// import contacts from "./contacts/reducer"

// //mails
// import mails from "./mails/reducer";

// //Dashboard
// import Dashboard from "./dashboard/reducer";

// //Dasboard saas
// import DashboardSaas from "./dashboard-saas/reducer";

//Learner Dashboard

import learnerDashboardReducer from "./learnerdashboard/reducer";
import tutorDashboardReducer from "./tutordashboard/reducer";
import authReducer from "./auth/authReducer";
import {tutorTutoringReducer} from "./store-tutoring-tutor";
import {learnerTutoringReducer} from "./store-tutoring-learner";
import {contentBuilderReducer} from "./store-content-builder";
import {transactionsReducer} from "./store-transactions";
import {earningsReducer} from "./store-earnings";
import {paymentsReducer} from "./store-payments";

import {storeMessagesReducer} from "./store-messages";

// Newer entities with more structured state.
import {tourReducer} from "./tour";
import {profileReducer} from "./profile";
import {profileMenuReducer} from "./profile-menu";
import commonStoreReducer from "./store-common/";

const rootReducer = combineReducers({
  tour: tourReducer,
  common: commonStoreReducer,
  profile: profileReducer,
  profileMenu: profileMenuReducer,
  auth: authReducer,
  // Tutoring.
  tutorTutoring: tutorTutoringReducer,
  learnerTutoring: learnerTutoringReducer,
  // Dashboard.
  learnerDashboard: learnerDashboardReducer,
  tutorDashboard: tutorDashboardReducer,
  // Content Builder.
  contentBuilder: contentBuilderReducer,
  // Transactions.
  transactions: transactionsReducer,
  // Earnings.
  earnings: earningsReducer,
  // Payments [Earnings & Transactions combined.]
  payments: paymentsReducer,
  messages: storeMessagesReducer,
  //   // public
  Layout,
  //   Login,
  //   Account,
  //   ForgetPassword,
  Profile,
});

export default rootReducer;
