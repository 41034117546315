import {UserType} from "./types";

export type TutoringUser = {
  id: number;
  name: string;
  email: string;
  dob: string;
  mobile_number: string;
  user_type: UserType;
  timezone: string;
  referal_id: string;
  username: string;
  rocket_chat_user_id?: string;
  channel_id?: string;
  channel_name?: string;
  country_code: string;
};

export type TutoringMeeting = {
  id: number;
  hosts: {
    id: string;
    detail: {
      name: string;
      email: string;
      rocket_chat_user_id: string;
    };
  }[];
  attendents: {
    id: number | string;
    detail: {
      name: string;
      email: string;
      rocket_chat_user_id: string;
    };
  }[];
  meeting_id: string;
  duration: number;
  scheduled_at: null | string;
  created_at: string;
  updated_at: string;
  session_id: number;
  req_id: number;
  joinees: "";
  start_time: string;
  // TODO:
  type: 1 | 2 | 3;
  status: "pending" | string;
  meeting_status: {
    id: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
    title: MeetingStatusTitle;
  };
  meeting_type: {
    id: 1 | 2 | 3;
    type: "instanct_connect" | "session" | "free_connect";
    created_at: string;
    updated_at: string;
  };
};

export type MeetingStatusTitle = "Scheduled" |
'Joined By Tutor' |
'Joined By You' |
'Joined By Learner' |
'Session in Progress' |
'Missed By You' |
'Missed By Tutor' |
'Missed By Learner' |
'Unattended' |
'Completed' ;

export type BookSessionSlot = {
  id: string;
  startTimeString: string;
  endTime: string;
};

export type RawTutorAvailability = {
  day: string;
  date: string;
  slots: RawTutorSlot[] | [];
};

export type RawTutorSlot = {
  start: string;
  end: string;
  reserved: 1 | 0;
};

/**
 * @typedef {Object} RawTutoringSessionBundle
 *
 * @property {number | undefined} id
 * @property {number} product_id
 * @property {number} no_of_sessions
 * @property {number} discount
 * @property {number} total_fee
 * @property {number} duration
 * @property {number} fee_per_session
 * @property {0 | 1} status
 */

/**
 * @typedef {Object} RawTutoringSession
 *
 * @property {number | undefined} id
 * @property {number[]} grade_id
 * @property {number} subject_id
 * @property {number[]} curriculum_id
 * @property {string[]} keywords
 * @property {number} boughts
 * @property {number} students
 * @property {0 | 1} status
 * @property {RawTutoringSessionBundle[]} bundles
 */

/**
 * @typedef {Object} TutoringSessionBundle
 *
 * @property {number | undefined} id
 * @property {number} sessionId
 * @property {number} numOfSessions
 * @property {number} discountPercentage
 * @property {number} netFee
 * @property {number} durationInMinutes
 * @property {number} feePerSession
 * @property {boolean} isActive
 */

/**
 * @typedef {Object} TutoringSession
 *
 * @property {number | undefined} id
 * @property {number} subjectId
 * @property {number[]} gradeIds
 * @property {number[]} curriculumIds
 * @property {string[]} keywords
 * @property {number} numOfSales
 * @property {number} numOfBuyers
 * @property {boolean} isActive
 * @property {TutoringSessionBundle[]} bundles
 */

/**
 * The session request types.
 */
export const sessionStatusTypes: {id: number; title: string; color: string}[] =
  [
    {
      id: 1,
      title: "Pending",
      color: "#B8C7D6",
    },
    {
      id: 2,
      title: "Accepted",
      color: "#3E6D9C",
    },
    {
      id: 3,
      title: "Rejected",
      color: "#EA8686",
    },
    {
      id: 4,
      title: "Rescheduled",
      color: "#5CDB95",
    },
    {
      id: 5,
      title: "Cancelled",
      color: "#555051",
    },
  ];
