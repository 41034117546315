import {IconVideo} from "@tabler/icons";
import {Icon} from "components";
import React from "react";

const BorderedCameraIcon = ({size = "lg"}) => {
  return (
    <Icon.Rounded
      size={size}
      className={`border bg-soft-info border-info text-info`}
    >
      <IconVideo />
    </Icon.Rounded>
  );
};

export default BorderedCameraIcon;
