import type {
  StaticDataV3Record,
  StaticDataV3RawRecord,
} from "constants/types/types-static-data";
import type { IAdapter } from "adapters/IAdapter";

/**
 * Adapter for the static data collections.
 */
export default class StaticDataV3Adapter
  implements IAdapter<StaticDataV3RawRecord, StaticDataV3Record>
{
  /**
   * Serializes a deserialized static data collection.
   *
   * @param deserializedData The deserialized data.
   *
   * @returns {StaticDataV3RawRecord[]}
   */
  // @ts-ignore
  serialize(deserializedData: StaticDataV3Record): StaticDataV3RawRecord[] {
    const serializedData: StaticDataV3RawRecord[] = [];

    deserializedData.forEach((data, index) => {
      if (data)
        serializedData.push({
          id: index,
          title: data,
        });
    });

    return serializedData;
  }

  /**
   * Deserializes a serialized static data collection.
   *
   * @param serializedData The serialized data.
   *
   * @returns {StaticDataV3Record}
   */
  // @ts-ignore
  deserialize(serializedData: StaticDataV3RawRecord[]): StaticDataV3Record {
    const deserializedData = new Map<number, string>();

    serializedData.forEach((data) =>
      // Some collections utilize special keys instead of the `title` key.
      deserializedData.set(data.id, data.grade || data.subject || data.title)
    );

    return deserializedData;
  }
}
