import React from "react";
import {useState} from "react";

const ReadMoreText = ({
  textClass,
  buttonClass,
  text,
  moreClickListener,
  limit = 100,
}) => {
  const [showFullBio, setShowFullBio] = useState(false);

  return (
    <p className={`text-secondary ${textClass}`}>
      {!showFullBio && text.substring(0, limit)}
      {showFullBio && text}
      {text.length > limit && (
        <button
          className={`outline-0 border-0 text-info ms-1 ${buttonClass}`}
          onClick={
            moreClickListener
              ? moreClickListener
              : () => setShowFullBio(!showFullBio)
          }
        >
          {" "}
          Read {!showFullBio ? "more" : "less"}
        </button>
      )}
    </p>
  );
};

export default ReadMoreText;
