import React, { useState, useRef } from "react";
import { Row, Col, Button } from "reactstrap";
import { connect } from "react-redux";
import {
  storeSubjects,
  deleteSubjects,
} from "../../../store/tutordashboard/action";
import SimpleReactValidator from "simple-react-validator";
import { TUTOR_MAX_SUBJECTS } from "constants";
import { Loader, SassyAlert, SassySelect } from "components";
import { createSelectablesV3 } from "utils";
import { useStaticDataV3 } from "hooks/static-data";

const AddSubjects = ({ subjectInfo, storeSubjects }) => {
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();

  const {
    getStaticData,
    subjects: { data: allSubjectsData, state: allSubjectsState },
  } = useStaticDataV3(["subjects"]);
  const subjectsOptions = createSelectablesV3(allSubjectsData);

  const [selectedOptions, setSelectedOptions] = useState(
    subjectInfo.map(({ subject_id }) => ({
      label: getStaticData(subject_id, allSubjectsData),
      value: subject_id,
    }))
  );

  const handleSubmit = (e) => {
    e.preventDefault();

    if (simpleValidator.current.allValid()) {
      const subjectList = selectedOptions.map((item) => item.value);
      storeSubjects(subjectList);
    } else {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return false;
    }
  };

  if (allSubjectsState === "loading") return <Loader />;
  if (allSubjectsState === "erred")
    return (
      <SassyAlert
        color="danger"
        title="There was an error while loading list of available subjects."
      />
    );
  return (
    <form
      className="edit-skills1"
      data-testid="subjectsForm"
      onSubmit={handleSubmit}
    >
      <Row>
        <Col md="12" sm="12" xs="12" className="basic-edit-choose">
          <span className="searchbar">
            <SassySelect
              label="Choose Subjects:"
              subTitle={`(Maximum: ${TUTOR_MAX_SUBJECTS})`}
              isMulti
              data-testid="subjectSelect"
              selectedOptions={selectedOptions}
              options={subjectsOptions}
              placeholder="Choose your Subjects"
              onChange={setSelectedOptions}
              maxSelections={TUTOR_MAX_SUBJECTS}
              hasSuggestions={true}
            />

            {simpleValidator.current.message(
              "Subject",
              selectedOptions,
              "required"
            )}
          </span>
        </Col>
      </Row>
      <Col className="mt-4 general-btn text-end">
        <Button
          data-testid="submitButton"
          className="text-dark my-2 continue-button"
        >
          <span style={{ color: "#ffffff" }}>Save</span>
        </Button>
      </Col>
    </form>
  );
};

const mapToStateProps = (state) => ({
  subjectInfo: state.tutorDashboard.userInfo.user.subjects,
});
export default connect(mapToStateProps, {
  deleteSubjects,
  storeSubjects,
})(AddSubjects);
