// Types.
import type { ProfileMenuAction, ProfileMenuState } from ".";

const initialState: ProfileMenuState = {
  menuItems: [],
};

export default function profileMenuReducer(
  state = initialState,
  action: ProfileMenuAction
) {
  switch (action.type) {
    case "PROFILE_MENU_ADD_ITEM":
      return {
        ...state,
        // Check if item already exists. If it does, replace it, otherwise add it.
        menuItems: state.menuItems.some((item) => item.id === action.payload.id)
          ? state.menuItems.map((item) =>
              item.id === action.payload.id ? action.payload : item
            )
          : [...state.menuItems, action.payload],
      };
    case "PROFILE_MENU_REMOVE_ITEM":
      return {
        ...state,
        menuItems: state.menuItems.filter((item) => item.id !== action.payload),
      };
    default:
      return state;
  }
}
