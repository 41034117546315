import { IAdapter } from "adapters/IAdapter";
import {
  PhoneNumber,
  RawPhoneNumber,
} from "constants/types/types-phone-number";

export default class PhoneNumberAdapter
  implements IAdapter<RawPhoneNumber, PhoneNumber>
{
  serialize(phoneNumber: PhoneNumber): RawPhoneNumber {
    return {
      country_code: phoneNumber.countryCode,
      mobile_number: phoneNumber.mobileNumber,
    };
  }

  deserialize(rawPhoneNumber: RawPhoneNumber): PhoneNumber {
    return {
      countryCode: rawPhoneNumber.country_code,
      mobileNumber: rawPhoneNumber.mobile_number,
    };
  }

  isRawPhoneNumber(value: any): value is RawPhoneNumber {
    return value?.mobile_number && value?.country_code;
  }
}
