import * as React from "react";
import store from "./store-static-v3";
import {Provider} from "react-redux";
import context from "./store-static-v3-context";

const StoreStaticV3Provider = ({children}: {children: React.ReactNode}) => (
  // @ts-ignore
  <Provider {...{store, context}}>{children}</Provider>
);

export default StoreStaticV3Provider;
