import * as React from "react";

// Components.
import { ErrorMessage, Formik } from "formik";
import { FormErrorMessage, SassyForm } from "components";
import { Col, Input, Row } from "reactstrap";
import StaticMultiField from "components/static-field/static-field-multi";
import FormLabel from "components/forms/form-label";
import MaxLimitHint from "components/forms/max-limit-hint";
import StaticField from "components/static-field/static-field";
import { ComboboxOption } from "components/combobox";

// Form features.
import getSchema from "./form-create-material-schema";
import getDeserialized from "./form-create-material-adapter";

// Form sections.
import MediaSection from "./sections/form-create-material-media-section";
import CopyrightSection from "./sections/form-create-material-copyright-section";
import PriceSection from "./sections/form-create-material-price-section";

// Utils.
import { IMAGE_FILE_TYPES, VIDEO_FILE_TYPES } from "constants/types";
import {
  MAX_MATERIAL_VIDEO_SIZE,
  // MAX_MATERIAL_PREVIEW_SIZE,
  MAX_MATERIAL_THUMBNAIL_SIZE,
  MAX_LIMIT_TITLES,
} from "constants/env";
import { MAX_LIMIT_DESCRIPTIONS } from "constants/";

import type { FormCreateMaterialProps } from ".";

/**
 * @author kashan-ahmad
 * @version 0.0.2
 *
 * @changelog
 * - 0.0.2: Extracted `MediaSection` and `CopyrightSection` from the form.
 */
function FormCreateMaterial({
  state,
  staticData,
  submissionListener,
  footer,
}: FormCreateMaterialProps): JSX.Element {
  const { grades, subjects, curriculums, materialTypes, getStaticData } =
    staticData;

  // Options for the tags are generated based on the title.
  const [tagOptions, setTagOptions] = React.useState(
    // @ts-ignore
    state?.title ? state?.title?.split(" ") : []
  );

  const selectableTagOptions = tagOptions.map((value) => ({
    value,
    label: value,
  }));

  return (
    <Formik
      onSubmit={submissionListener}
      initialValues={getDeserialized(state as unknown as LearningMaterial)}
      validationSchema={getSchema({
        maxVideoSize: MAX_MATERIAL_VIDEO_SIZE,
        // maxPreviewSize: MAX_MATERIAL_PREVIEW_SIZE,
        maxThumbnailSize: MAX_MATERIAL_THUMBNAIL_SIZE,
        maxTitleLength: MAX_LIMIT_TITLES,
        maxDescriptionLength: MAX_LIMIT_DESCRIPTIONS,
        allowedImageFormats: IMAGE_FILE_TYPES,
        allowedVideoFormats: VIDEO_FILE_TYPES,
      })}
    >
      {({ values, errors, handleChange, setFieldTouched, setFieldValue }) => {
        return (
          <SassyForm className="vstack gap-3">
            {/* Title */}
            <SassyForm.Input
              name="title"
              label="Title"
              onBlur={() => {
                setFieldTouched("title", true);
                setTagOptions(values.title.split(" "));
              }}
              placeholder="Provide a title for your material"
            />

            {/* Description */}
            <SassyForm.Input
              type="textarea"
              name="description"
              label="Description"
              value={values.description}
              maxLength={Number(MAX_LIMIT_DESCRIPTIONS)}
              placeholder="Provide a detailed description to support the purchase decision"
            />

            {/* Curriculums */}
            <StaticMultiField
              staticKey="curriculums"
              name="curriculums"
              placeholder="Select the appropriate curricula"
              onBlur={() => setFieldTouched("curriculums", true)}
              maxValues={5}
              value={
                values.curriculums.map((id: number) => ({
                  value: id,
                  label: getStaticData(id, curriculums.data)!,
                })) satisfies ComboboxOption[]
              }
              onChange={(selections) => {
                handleChange({
                  target: {
                    name: "curriculums",
                    value: selections.map(({ value }) => value),
                  },
                });
              }}
              slotAbove={
                <FormLabel hint={<MaxLimitHint limit={5} />}>
                  Choose curricula
                </FormLabel>
              }
              slotBelow={
                <ErrorMessage
                  name="curriculums"
                  render={(message) => <FormErrorMessage {...{ message }} />}
                />
              }
            />

            {/* Grades */}
            <StaticMultiField
              staticKey="grades"
              name="grades"
              placeholder="Select the appropriate grade(s)"
              maxValues={5}
              onBlur={() => setFieldTouched("grades", true)}
              value={
                values.grades.map((id: number) => ({
                  value: id,
                  label: getStaticData(id, grades.data)!,
                })) satisfies ComboboxOption[]
              }
              onChange={(selections) =>
                handleChange({
                  target: {
                    name: "grades",
                    value: selections.map(({ value }) => value),
                  },
                })
              }
              slotAbove={
                <FormLabel hint={<MaxLimitHint limit={5} />}>
                  Choose grades
                </FormLabel>
              }
              slotBelow={
                <ErrorMessage
                  name="grades"
                  render={(message) => <FormErrorMessage {...{ message }} />}
                />
              }
            />

            {/* Subjects */}
            <StaticMultiField
              staticKey="subjects"
              name="subjects"
              placeholder="Select the appropriate grade(s)"
              maxValues={5}
              onBlur={() => setFieldTouched("subjects", true)}
              value={
                values.subjects.map((id: number) => ({
                  value: id,
                  label: getStaticData(id, subjects.data)!,
                })) satisfies ComboboxOption[]
              }
              onChange={(selections) =>
                handleChange({
                  target: {
                    name: "subjects",
                    value: selections.map(({ value }) => value),
                  },
                })
              }
              slotAbove={
                <FormLabel hint={<MaxLimitHint limit={5} />}>
                  Choose subject(s)
                </FormLabel>
              }
              slotBelow={
                <ErrorMessage
                  name="subjects"
                  render={(message) => <FormErrorMessage {...{ message }} />}
                />
              }
            />

            {/* Topic */}
            <SassyForm.Input
              name="topic"
              label="Topic"
              placeholder="Topics help the buyer easily sort/find the material"
            />
            {/* Material Types */}
            <StaticField
              staticKey="materialTypes"
              name="materialTypes"
              onBlur={() => setFieldTouched("materialTypes", true)}
              placeholder="Select the appropriate category for the material you are creating"
              value={
                values.materialTypes.map((id: number) => ({
                  value: id,
                  label: getStaticData(id, materialTypes.data)!,
                }))[0] satisfies ComboboxOption
              }
              onChange={(selection) =>
                handleChange({
                  target: {
                    name: "materialTypes",
                    value: [selection.value],
                  },
                })
              }
              slotAbove={
                <FormLabel hint={<MaxLimitHint limit={1} />}>
                  Choose material type
                </FormLabel>
              }
              slotBelow={
                <ErrorMessage
                  name="materialTypes"
                  render={(message) => <FormErrorMessage {...{ message }} />}
                />
              }
            />

            {/* Tags */}
            <SassyForm.Select
              {...{ handleChange, setFieldTouched }}
              isMulti
              // @ts-ignore
              creatable
              name="tags"
              label="Tags"
              maxSelections={10}
              closeMenuOnSelect={false}
              options={selectableTagOptions}
              placeholder="Tags help the buyer easily sort/find the material"
              value={values.tags.map((value) => ({
                value,
                label: value,
              }))}
            />

            {/* Booleans. */}
            <Row className="mb-3 g-2 gy-3">
              <Col xs={12} sm={6}>
                <div className="fw-semi-bold mb-1 text-secondary">
                  Are any quizzes/questions included?
                </div>
                <div role="group" className="hstack gap-3">
                  <label htmlFor="hasQuizYes">
                    <Input
                      type="radio"
                      id="hasQuizYes"
                      name="hasQuiz"
                      checked={values.hasQuiz}
                      onChange={() =>
                        handleChange({
                          target: {
                            value: true,
                            name: "hasQuiz",
                          },
                        })
                      }
                    />
                    <span className="ms-1">Yes</span>
                  </label>
                  <label htmlFor="hasQuizNo">
                    <Input
                      type="radio"
                      id="hasQuizNo"
                      name="hasQuiz"
                      checked={!values.hasQuiz}
                      onChange={() =>
                        handleChange({
                          target: {
                            value: false,
                            name: "hasQuiz",
                          },
                        })
                      }
                    />
                    <span className="ms-1">No</span>
                  </label>
                </div>
              </Col>
              <Col xs={12} sm={6}>
                <div className="fw-semi-bold mb-1 text-secondary">
                  Is an answer key included?
                </div>
                <div role="group" className="hstack gap-3">
                  <label htmlFor="hasKeyYes">
                    <Input
                      type="radio"
                      id="hasKeyYes"
                      name="hasKey"
                      checked={values.hasKey}
                      onChange={() =>
                        handleChange({
                          target: {
                            value: true,
                            name: "hasKey",
                          },
                        })
                      }
                    />
                    <span className="ms-1">Yes</span>
                  </label>
                  <label htmlFor="hasKeyNo">
                    <Input
                      type="radio"
                      id="hasKeyNo"
                      name="hasKey"
                      checked={!values.hasKey}
                      onChange={() =>
                        handleChange({
                          target: {
                            value: false,
                            name: "hasKey",
                          },
                        })
                      }
                    />
                    <span className="ms-1">No</span>
                  </label>
                </div>
              </Col>
            </Row>
            <SassyForm.Select
              {...{ handleChange, setFieldTouched }}
              // @ts-ignore
              creatable
              name="seoTags"
              label="SEO Tags"
              maxSelections={10}
              closeMenuOnSelect={false}
              options={selectableTagOptions}
              placeholder="SEO Tags help buyers find this material via Google/Search engine queries"
              value={values.seoTags.map((value) => ({
                value,
                label: value,
              }))}
            />
            <PriceSection {...{ values, handleChange, setFieldValue }} />
            <MediaSection
              {...{ values, errors, handleChange, setFieldTouched }}
            />
            <CopyrightSection {...{ values, handleChange }} />
            {footer && footer}
          </SassyForm>
        );
      }}
    </Formik>
  );
}

export default FormCreateMaterial;
