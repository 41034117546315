import * as React from "react";
import { Outlet } from "react-router-dom";
import RawPhoneNumberContext, {
  initialRawPhoneNumberState,
  initialRawPhoneNumberContext,
} from "./raw-phone-number-context";

export default function RawPhoneNumberContextProvider() {
  const [state, setState] = React.useState(initialRawPhoneNumberState);

  return (
    <RawPhoneNumberContext.Provider
      value={{ ...initialRawPhoneNumberContext, state, setState }}
    >
      <Outlet />
    </RawPhoneNumberContext.Provider>
  );
}
