import {IconChevronLeft} from "@tabler/icons";
import React from "react";
import {Link} from "react-router-dom";

/**
 * @author Abdullah-Sajjad026
 */
function BackBtn({
  to,
  className = "",
}: {
  to: string;
  className?: string;
  //   href?: string;
}) {
  //   const Component = to ? Link : "a";

  return (
    <Link to={to} className={`btn back-btn ${className} `}>
      <IconChevronLeft />
    </Link>
  );
}

export default BackBtn;
