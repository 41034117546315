import React from "react";
import { ListGroup } from "react-bootstrap";
import { SassyCard } from "components/cards";
import { FormErrorMessage } from "components";

type Props = {
  errors: string[];
};

export default function OnboardingTutorErrorsList({ errors }: Props) {
  return (
    <>
      <div>
        <SassyCard.Title>Onboarding Incomplete</SassyCard.Title>
        <p className="mb-0">Please complete the following:</p>
      </div>
      <ListGroup className="mb-0 text-secondary">
        {errors.map((error, index) => (
          <ListGroup.Item key={index}>
            <FormErrorMessage message={error} />
          </ListGroup.Item>
        ))}
      </ListGroup>
    </>
  );
}
