/* eslint-disable eqeqeq */
import React from "react";
import moment from "moment";
import SimpleBar from "simplebar-react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {IconSend} from "@tabler/icons";
import {Button, Input} from "reactstrap";
import {pusherService} from "service/pusher-service";

import {AjaxCallStatus, ReduxStore} from "constants/";
import {useApiEffect, useUser} from "hooks";
import {ListItemUser} from "components/list-items";
import {Loader, SassyAlert} from "components";
import {messages} from "constants/strings";
import {getInboxMessages, sendNewMessage} from "store/store-messages";
/**
 * Messages Inbox
 */
export default function MessagesInbox(): JSX.Element {
  // getting receiver id from url
  const receiverId = parseInt(useParams().receiverId!);
  const {id: userId} = useUser();
  const messagesEndRef = React.useRef(null);

  // inbox messages state
  const [inbox, setInbox] = React.useState<{
    state: AjaxCallStatus;
    data: {[key: string]: any}[];
  }>({
    state: "loading",
    data: [],
  });

  // new message input state
  const [newMessage, setNewMessage] = React.useState("");

  const dispatch: (action: any) => void = useDispatch();
  const {messagesUsers} = useSelector((store: ReduxStore) => ({
    messagesUsers: store.messages.connectedUsers,
  }));
  // getting receiver from connected users
  const receiver = messagesUsers.data.find((u) => u.id === receiverId);

  React.useEffect(() => {
    // resetting inbox state to loading when receiver id changes
    setInbox({
      state: "loading",
      data: [],
    });

    // resetting message input when receiver id changes
    setNewMessage("");

    dispatch(
      // getting inbox messages for receiver id
      getInboxMessages({
        receiverId,
        onSuccess: (data) =>
          setInbox({state: "loaded", data: data?.chat || []}),
        onFailure: (err) => setInbox({state: "erred", data: []}),
      })
    );

    // subscribing to pusher channel for new messages for receiver id and current user id
    const unsubscribePusher = pusherService.subscribe({
      channelName: "my-channel",
      eventName: "my-event",
      callback: function (data) {
        if (
          (data.sent_by == receiverId && data.sent_to == userId) ||
          (data.sent_by == userId && data.sent_to == receiverId)
        )
          setInbox((prev) => ({...prev, data: [...prev.data, data]}));
      },
    });

    return () => {
      // unsubscribing from pusher channel when component unmounts so that we don't get duplicate messages
      unsubscribePusher();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receiverId]);

  React.useEffect(() => {
    // @ts-ignore
    // scrolling to bottom of messages when new message is received
    messagesEndRef?.current?.scrollIntoView({behavior: "smooth"});
  }, [inbox.data]);

  /**
   * Send new message
   */
  const sendMessage = (e) => {
    e.preventDefault();

    setNewMessage("");
    dispatch(
      sendNewMessage({
        message: newMessage,
        receiverId: receiverId,
        senderId: userId,
      })
    );
  };

  return (
    <section className="messages-inbox d-flex flex-column h-100">
      {messagesUsers.state === "loaded" && receiver && (
        <>
          <header className="bg-white p-2 border">
            <ListItemUser
              avatarSrc={receiver.profile_picture}
              username={receiver.name}
              // description="Active Now"
              // rightWing={
              //   <Button
              //     outline
              //     color="secondary"
              //     // type="button"
              //     className="hstack gap-2"
              //   >
              //     <IconInfoCircle size="17" />
              //     Details
              //   </Button>
              // }
            />
          </header>

          <div className="flex-grow-1 overflow-auto">
            <div className="p-3">
              {inbox.state === "loading" ? (
                <Loader isInline />
              ) : inbox.state === "erred" ? (
                <SassyAlert color="danger" title={messages.AN_ERR} />
              ) : (
                <SimpleBar
                  style={{
                    maxHeight: "76vh",
                    minHeight: "400px",
                    overflow: "auto",
                  }}
                >
                  <div className="d-flex flex-column gap-3">
                    {inbox.data.length === 0 ? (
                      <span className="text-center">No Messages</span>
                    ) : (
                      inbox.data.map((msg: any, index: number) => (
                        <div
                          key={msg.id}
                          style={{minWidth: "170px"}}
                          className={`${
                            msg.sent_to == receiverId
                              ? "align-self-end ms-2"
                              : "align-self-start me-2"
                          }`}
                          ref={
                            inbox.data.length - 1 === index
                              ? messagesEndRef
                              : null
                          }
                        >
                          <div className="p-3 rounded bg-white w-100 fs-6">
                            {msg.message}
                          </div>
                          <div
                            className={` text-muted mt-2 font-size-11 ${
                              msg.sent_to == receiverId
                                ? "text-end"
                                : "text-start"
                            }`}
                          >
                            {moment(
                              msg.created_at,
                              "YYYY-MM-DD HH:mm:ss"
                            ).format("hh:mm A | MMM DD, YY")}
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                  <div ref={messagesEndRef} />
                </SimpleBar>
              )}
            </div>
          </div>
          <div className="send-input mt-auto text-center p-3 rounded border border-2 bg-white ">
            <form onSubmit={sendMessage} className="hstack gap-2 w-80">
              <Input
                type="text"
                // rows={1}
                // className="form-control"
                name="user-message"
                id="user-message"
                placeholder="Enter message here"
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
              />
              <Button type="submit" color="success">
                <IconSend />
              </Button>
            </form>
          </div>
        </>
      )}
    </section>
  );
}
