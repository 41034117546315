import Component from "./session-status-card";

// Status.
import Expired from "./status/expired";
import Pending from "./status/pending";
import Accepted from "./status/accepted";
import Rejected from "./status/rejected";
import Cancelled from "./status/cancelled";
import Rescheduled from "./status/rescheduled";

// Renderers.
import LearnerDefault from "./renderers/learner-default";
import LearnerContainedDetails from "./renderers/learner-contained-details";
import TutorDefault from "./renderers/tutor-default";
import TutorContainedDetails from "./renderers/tutor-contained-details";

export const SessionStatusCard = Object.assign(Component, {
  Status: {
    Pending,
    Expired,
    Accepted,
    Rejected,
    Cancelled,
    Rescheduled,
  },
  Renderer: {
    LearnerDefault,
    LearnerContainedDetails,
    TutorDefault,
    TutorContainedDetails,
  },
});

export * from "./session-status-card-types";
