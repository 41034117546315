import {apiService} from "service";
import {getAuthHeaders, getFormDataFromObject} from "utils";
import {API_MAIN_URL} from "constants";

/**
 * Fetch a list of all payments methods for the logged in user.
 * @param {Object} object
 * @param {Boolbacks['onSuccess']} object.onSuccess
 * @param {Boolbacks['onFailure']} object.onFailure
 */
export const fetchPaymentMethods =
  ({onSuccess, onFailure}) =>
  /**
   * @param {import("redux").Dispatch<TransactionAction>} dispatch
   */
  (dispatch) =>
    apiService.post({
      headers: getAuthHeaders(),
      url: `${API_MAIN_URL}/payment/payment-card-list`,
      onSuccess: ({data}) => {
        dispatch({
          type: "SET_PAYMENT_METHODS",
          payload: data,
        });

        onSuccess?.(data);
      },
      onFailure: (error) => {
        dispatch({
          type: "ERRED_PAYMENT_METHODS",
        });

        onFailure?.(error);
      },
    });

/**
 * Add a payment method to a user's list of methods.
 * @param {Object} object
 * @param {Boolbacks['onSuccess']} object.onSuccess
 * @param {Boolbacks['onFailure']} object.onFailure
 * @param {WeirdPaymentMethod} weirdPaymentMethod
 */
export const addPaymentMethod =
  ({onSuccess, onFailure, weirdPaymentMethod}) =>
  /**
   * @param {import("redux").Dispatch<TransactionAction>} dispatch
   */
  (dispatch) =>
    apiService.post({
      headers: getAuthHeaders(),
      data: getFormDataFromObject(weirdPaymentMethod),
      url: `${API_MAIN_URL}/payment/save-payment-card`,
      onFailure: (error) => onFailure?.(error),
      onSuccess: ({data}) => {
        dispatch({
          type: "SET_PAYMENT_METHODS",
          payload: data,
        });

        onSuccess?.(data);
      },
    });

/**
 * Change the default status of a payment method.
 * @param {Object} props
 * @param {PaymentMethod['_id']} _id
 * @param {Boolbacks['onSuccess']} props.onSuccess
 * @param {Boolbacks['onFailure']} props.onFailure
 */
export const makePaymentMethodDefault = ({_id, ...props}) =>
  addPaymentMethod({...props, weirdPaymentMethod: {_id: _id}});

/**
 * Remove a payment method to a user's list of methods.
 * @param {Object} object
 * @param {PaymentMethod['_id']} _id
 * @param {Boolbacks['onSuccess']} object.onSuccess
 * @param {Boolbacks['onFailure']} object.onFailure
 */
export const removePaymentMethod =
  ({_id, onSuccess, onFailure}) =>
  /**
   * @param {import("redux").Dispatch<TransactionAction>} dispatch
   */
  (dispatch) =>
    apiService.post({
      headers: getAuthHeaders(),
      data: getFormDataFromObject({_id}),
      url: `${API_MAIN_URL}/payment/payment-card-delete`,
      onFailure: (error) => onFailure?.(error),
      onSuccess: ({data}) => {
        dispatch({
          type: "REMOVE_PAYMENT_METHOD",
          payload: _id,
        });

        onSuccess?.(data);
      },
    });

/// Transaction Amount.
/**
 * Fetch the transaction amounts for the logged in user.
 * @param {Object} object
 * @param {User['type']} props.userType
 * @param {Boolbacks['onSuccess']} object.onSuccess
 * @param {Boolbacks['onFailure']} object.onFailure
 */
export const fetchTransactionAmount =
  ({userType, onSuccess, onFailure}) =>
  /**
   * @param {import("redux").Dispatch<TransactionAction>} dispatch
   */
  (dispatch) =>
    apiService.post({
      headers: getAuthHeaders(),
      url:
        userType === "tutor"
          ? `${API_MAIN_URL}/payment/transaction-detail`
          : `${API_MAIN_URL}/payment/transaction-detail-learner`,
      onSuccess: ({data}) => {
        dispatch({
          type: "SET_TRANSACTION_AMOUNT",
          payload: data,
        });

        onSuccess?.(data);
      },
      onFailure: (error) => {
        dispatch({
          type: "ERRED_TRANSACTION_AMOUNT",
        });

        onFailure?.(error);
      },
    });

/// Transaction History.
export const fetchTransactionHistory =
  ({page, onSuccess, onFailure, product_type = "tutor"}) =>
  /**
   * @param {import("redux").Dispatch<TransactionAction>} dispatch
   */
  (dispatch) =>
    apiService.postAndGet({
      params: [{page}],
      headers: getAuthHeaders(),
      data: getFormDataFromObject({product_type}),
      url: `${API_MAIN_URL}/payment/transaction-history`,
      onSuccess: ({data}) => {
        dispatch({
          type: "SET_PAYMENT_HISTORY",
          payload: data,
        });

        onSuccess?.(data);
      },
      onFailure: (error) => {
        dispatch({
          type: "ERRED_PAYMENT_HISTORY",
        });

        onFailure?.(error);
      },
    });

export const fetchTotalTransactions =
  ({start_date, end_date, page, onSuccess, onFailure}) =>
  async (dispatch) => {
    const data = new FormData();
    data.append("page", page);
    // data.append("limit", 6);
    if (start_date) data.append("start_date", start_date);
    if (end_date) data.append("end_date", end_date);

    apiService.post({
      url: `${API_MAIN_URL}/payment/transaction-history-detail`,
      data,
      headers: getAuthHeaders(),
      onSuccess: ({data}) => {
        dispatch({type: "SET_TOTAL_TRANSACTIONS", payload: data});
        onSuccess?.(data);
      },
      onFailure: ({error}) => {
        dispatch({type: "ERRED_TOTAL_TRANSACTIONS"});

        onFailure?.(error);
      },
    });
  };

export const fetchMonthlyTransactionsStats =
  ({monthCount = 7}) =>
  async (dispatch) => {
    apiService.get({
      url: `${API_MAIN_URL}/payment/orders-graph`,
      data: [{key: "month_count", value: monthCount}],
      headers: getAuthHeaders(),
      onSuccess: ({data}) => {
        dispatch({
          type: "SET_MONTHLY_TRANSACTIONS_STATS",
          payload: data?.reverse()?.map((i) => ({
            ...i,
            Sessions: i.sessionsTotal,
            Materials: i.materialTotal,
          })),
        });
      },
      onFailure: ({error}) => {
        dispatch({type: "ERRED_MONTHLY_TRANSACTIONS_STATS"});
      },
    });
  };

export const fetchDailyTransactionsStats =
  ({onSuccess, onFailure, days_count = 7}) =>
  async (dispatch) => {
    apiService.get({
      url: `${API_MAIN_URL}/payment/daily-sale-graph`,
      data: [{key: "days_count", value: days_count}],
      headers: getAuthHeaders(),
      onSuccess: ({data}) => {
        dispatch({
          type: "SET_DAILY_TRANSACTIONS",
          payload: data,
        });
      },
      onFailure: ({error}) => {
        dispatch({
          type: "ERRED_DAILY_TRANSACTIONS",
        });
      },
    });
  };
