import ALLOWED_FORMATS from "./form-tutor-certificate-formats";
import { string, date, mixed, object } from "yup";
import { MAX_LIMIT_DESCRIPTIONS, TWO_MB } from "constants";
import { messages } from "constants/strings";

const tutorCertificateFormValidationSchema = object({
  certificate_name: string().required("Please enter the Certificate Name"),
  issuing_organization: string().required("Please enter the Organization Name"),
  description: string().max(
    MAX_LIMIT_DESCRIPTIONS,
    `Maximum ${MAX_LIMIT_DESCRIPTIONS} characters!`
  ),
  issue_date: date()
    .max(new Date(), messages.NOT_VALID_DATE)
    .required("Please select an Issue date"),

  document: mixed()
    .test({
      name: "fileSize",
      message: messages.FILE_SIZE,
      test: (value) => !value || value.size <= TWO_MB,
    })
    .test({
      name: "fileFormat",
      message: messages.FILE_FORMAT,
      test: (value) => !value || ALLOWED_FORMATS.includes(value.type),
    }),
});

export default tutorCertificateFormValidationSchema;
