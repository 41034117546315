import React, {useEffect} from "react";
import {Col, Row} from "reactstrap";
import {useSelector} from "react-redux";
import {IconUser} from "@tabler/icons";
import {IconBook, IconBulb, IconStairs} from "@tabler/icons";
import {isEmpty} from "lodash";
import {useStaticDataV2} from "hooks";
import {Icon, Loader} from "components";

const GoalsBlock = ({state}) => {
  const [goalsPreferences, setGoalsPreferences] = state;

  const {goals} = useStaticDataV2(["goals"]);
  const {state: allGoalsState, data: allGoalsData} = goals;

  const {goal: storedGoals} = useSelector(
    (state) => state.learnerDashboard.userInfo.user
  );

  useEffect(() => {
    if (allGoalsState === "loaded") {
      setGoalsPreferences(storedGoals.map((i) => i.goal_id));
    }
  }, [allGoalsState]);

  const _handleGoalClick = (goalId) => {
    if (goalsPreferences.includes(goalId)) {
      const updatedGoalsPreferences = goalsPreferences.filter(
        (gId) => gId !== goalId
      );
      setGoalsPreferences(updatedGoalsPreferences);
    } else {
      setGoalsPreferences([goalId, ...goalsPreferences]);
    }
  };

  const _getmyIcon = (icon) => {
    switch (icon) {
      case "FiUser":
        return <IconUser />;
      case "MdOutlineMenuBook":
        return <IconBook />;
      case "MdLightbulbOutline":
        return <IconBulb />;
      case "MdStairs":
        return <IconStairs />;
      default:
        break;
    }
  };

  if (allGoalsState === "loading") {
    return <Loader />;
  }

  return allGoalsState === "loaded" ? (
    <Row className="preference-goal w-100 mx-0">
      <Col xs="12" className="ps-0">
        <h5 className="preference-head p=0">
          <span>What's your goal?</span>
        </h5>
      </Col>
      <Col md="9" sm="12" xs="12" className="m-0 ps-0">
        <Row>
          {!isEmpty(allGoalsData) &&
            allGoalsData
              // Filter out the "I want to be a tutor" goal.
              .filter((goal) => goal.id !== 4)
              .map((goal) => {
                return (
                  <Col lg="6" md="12" sm="12" xs="12" key={goal.id}>
                    <div
                      className={`${
                        goalsPreferences.includes(goal.id) && "border-success"
                      } preference-goaltitle d-flex align-items-center`}
                      onClick={() => _handleGoalClick(goal.id)}
                    >
                      <Icon.Rounded
                        className={`${
                          goalsPreferences.includes(goal.id)
                            ? "text-white"
                            : "bg-opacity-10 text-success"
                        } bg-success rounded-circle`}
                        size="md"
                      >
                        {_getmyIcon(goal.icon)}
                      </Icon.Rounded>
                      <span className="p-2 preference-content">
                        {goal.title}
                        <br />
                      </span>
                    </div>
                  </Col>
                );
              })}
        </Row>
      </Col>
    </Row>
  ) : null;
};

export default GoalsBlock;
