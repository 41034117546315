import axios from "axios";
import {
  FETCH_GOALS,
  FETCH_LANGUAGES,
  FETCH_LANGUAGES_ERROR,
  FETCH_GOALS_ERROR,
  FETCH_LEARNING_STYLES,
  FETCH_LEARNING_STYLES_ERROR,
  STORE_LANGUAGES,
  STORE_LEARNING_STYLES,
  STORE_USERINFO,
  USERINFO_ERROR,
  STORE_ACCOUNT_INFO,
  ACCOUNT_INFO_ERROR,
  STORE_GOALS,
  FETCH_TIMESLOTS,
  FETCH_TIMESLOTS_ERROR,
  STORE_TIMESLOTS,
  TOPICS_INFO_ERROR,
  STORE_TOPICS_INFO,
  // FETCH_SUBJECTS,
  // FETCH_SUBJECTS_ERROR,
  // FETCH_GRADES,
  // FETCH_GRADES_ERROR,
  // FETCH_CURRICULUM,
  // FETCH_CURRICULUM_ERROR,
  FETCH_INTERESTS,
  FETCH_INTERESTS_ERROR,
  GET_PROFILE_DATA,
  PROFILE_DATA_ERROR,
  ONBOARDING_STATUS,
  POST_PURCHASE_LEARNING_ERROR,
  POST_PURCHASE_LEARNING,
  FETCH_MATERIAL_ERROR,
  FETCH_MATERIAL,
  FETCH_GRADES_ERROR,
  FETCH_GRADES,
  FETCH_SUBJECTS,
  FETCH_SUBJECTS_ERROR,
  FETCH_CURRICULUM,
  FETCH_CURRICULUM_ERROR,
  FETCH_POPULAR_TUTORS,
} from "./actionTypes";
import {API_MAIN_URL} from "../../constants";

import {apiService} from "service";
import {getAuthHeaders} from "utils";
import ToastFactory from "utils/toast-factory";
import {useRemoteProfile} from "hooks/profile";

const toast = ToastFactory();

// PROFILE DATA
export const getProfileData = () => async (dispatch) => {
  const token = localStorage.getItem("token");

  const config = {
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    },
  };
  try {
    const res = await axios.get(`${API_MAIN_URL}/learner/get-profile`, config);
    if (res.data?.data) {
      localStorage.setItem("TIMEZONE", res.data?.data?.timezone);
    }
    dispatch({
      type: GET_PROFILE_DATA,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: PROFILE_DATA_ERROR,
      payload: error.message,
    });
  }
  // todo: implement this. tested

  // apiService.get({
  //   url: `${API_MAIN_URL}/learner/get-profile`,
  //   headers: getAuthHeaders(),
  //   onSuccess: (res) => {
  //     console.log(res);
  //     localStorage.setItem("TIMEZONE", res.data?.timezone);
  //     localStorage.setItem("user", JSON.stringify(res.data));
  //     dispatch({
  //       type: GET_PROFILE_DATA,
  //       payload: {data: res.data},
  //     });
  //   },
  //   onFailure: (error) => {
  //     dispatch({
  //       type: PROFILE_DATA_ERROR,
  //       payload: error.message,
  //     });
  //   },
  // });
};

//---Languages--///
export const fetchLanguages = () => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.post(
      `${API_MAIN_URL}/content/languages-list`,
      config
    );
    dispatch({
      type: FETCH_LANGUAGES,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_LANGUAGES_ERROR,
      payload: error.message,
    });
  }
};
//---Materials--///
export const fetchMaterial = () => async (dispatch) => {
  const token = localStorage.getItem("token");

  const config = {
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    },
  };
  try {
    const res = await axios.get(
      `${API_MAIN_URL}/learner/learning-material/list`,
      config
    );
    dispatch({
      type: FETCH_MATERIAL,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_MATERIAL_ERROR,
      payload: error.message,
    });
  }
};

//---Purchase Learning Materials--///
export const purchaseLearningMaterial =
  (orderId, userId) => async (dispatch) => {
    const token = localStorage.getItem("token");

    const formData = new FormData();
    formData.append("order_id", orderId);
    formData.append("lm_id", userId);

    const config = {
      headers: {
        "Content-Type": "application/json",
        "X-Auth-Token": token,
      },
    };
    try {
      const res = await axios.post(
        `${API_MAIN_URL}/contentbuilder/learning-material/orders`,
        formData,
        config
      );
      if (res.data.status) {
        dispatch({
          type: POST_PURCHASE_LEARNING,
          payload: res.data,
        });
        toast.success(res.data.message);
        dispatch(fetchMaterial());
      } else {
        toast.success(res.data.message);
      }
    } catch (error) {
      dispatch({
        type: POST_PURCHASE_LEARNING_ERROR,
        payload: error.message,
      });
      toast.error(error.message);
    }
  };

export const storeLanguages =
  (selectedLanguages, onSuccess, onFailure) => async (dispatch) => {
    apiService.post({
      url: `${API_MAIN_URL}/learner/store-language`,
      data: {languages: JSON.stringify(selectedLanguages)},
      headers: getAuthHeaders(),
      onSuccess: ({data}) => {
        onSuccess?.(data);

        dispatch({
          type: STORE_LANGUAGES,
        });
      },
      onFailure: (error) => {
        onFailure?.(error);
        toast.error(error.message);
        dispatch({
          type: FETCH_LANGUAGES_ERROR,
          payload: error.message,
        });
      },
    });

    // const token = localStorage.getItem("token");

    // const config = {
    //   headers: {
    //     "Content-Type": "application/json",
    //     "X-Auth-Token": token,
    //   },
    // };

    // const selectedLang = JSON.stringify(selectedLanguages);

    // const body = {
    //   languages: selectedLang,
    // };

    // try {
    //   const res = await axios.post(`${API_MAIN_URL}/learner/store-language`,

    //     body,
    //     config
    //   );
    //   toast.success("Languages updated successfully");
    //   dispatch({
    //     type: STORE_LANGUAGES,
    //   });
    // } catch (error) {
    //   dispatch({
    //     type: FETCH_LANGUAGES_ERROR,
    //     payload: error.message,
    //   });
    //   toast.error(`Languages Error: ${error.message}`);
    // }
  };

//---Goals--///

export const fetchGoals = () => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.post(`${API_MAIN_URL}/content/goals-list`, config);
    dispatch({
      type: FETCH_GOALS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_GOALS_ERROR,
      payload: error.message,
    });
  }
};

export const storeGoals =
  (selectedGoals, onSuccess, onFailure) => async (dispatch) => {
    apiService.post({
      url: `${API_MAIN_URL}/learner/store-goals`,
      headers: getAuthHeaders(),
      data: {goals: JSON.stringify(selectedGoals)},
      onSuccess: ({data}) => {
        dispatch({
          type: STORE_GOALS,
        });

        onSuccess?.();
      },
      onFailure: (error) => {
        onFailure?.();
        toast.error(error.message);

        dispatch({
          type: FETCH_GOALS_ERROR,
          payload: error.message,
        });
      },
    });

    // const token = localStorage.getItem("token");
    // const config = {
    //   headers: {
    //     "Content-Type": "application/json",
    //     "X-Auth-Token": token,
    //   },
    // };

    // const selectedGoal = JSON.stringify(selectedGoals);

    // const body = {
    //   goals: selectedGoal,
    // };
    // try {
    //   const res = await axios.post(
    //     `${API_MAIN_URL}/learner/store-goals`,
    //     body,
    //     config
    //   );
    //   dispatch({
    //     type: STORE_GOALS,
    //   });

    //   onSuccess?.();
    // } catch (error) {
    //   onFailure?.();
    //   dispatch({
    //     type: FETCH_GOALS_ERROR,
    //     payload: error.message,
    //   });
    // }
  };

//--Time slots----///

export const fetchTimeSlots = () => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.post(`${API_MAIN_URL}/content/time-slots`, config);
    dispatch({
      type: FETCH_TIMESLOTS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_TIMESLOTS_ERROR,
      payload: error.message,
    });
  }
};

export const storeTimeSlots =
  (selectedTimeSlots, onSuccess, onFailure) => async (dispatch) => {
    apiService.post({
      url: `${API_MAIN_URL}/learner/store-time-slots`,
      headers: getAuthHeaders(),
      data: {timeslots: JSON.stringify(selectedTimeSlots)},
      onSuccess: ({data}) => {
        dispatch({
          type: STORE_TIMESLOTS,
        });
        onSuccess?.();
      },
      onFailure: (error) => {
        onFailure?.();
        toast.error(error.message);

        dispatch({
          type: FETCH_TIMESLOTS_ERROR,
          payload: error.message,
        });
      },
    });
    // const token = localStorage.getItem("token");
    // const config = {
    //   headers: {
    //     "Content-Type": "application/json",
    //     "X-Auth-Token": token,
    //   },
    // };

    // const selectedTime = JSON.stringify(selectedTimeSlots);

    // const body = {
    //   timeslots: selectedTime,
    // };

    // try {
    //   const res = await axios.post(
    //     `${API_MAIN_URL}/learner/store-time-slots`,
    //     body,
    //     config
    //   );
    //   dispatch({
    //     type: STORE_TIMESLOTS,
    //   });
    //   onSuccess?.();
    // } catch (error) {
    //   onFailure?.();
    //   dispatch({
    //     type: FETCH_TIMESLOTS_ERROR,
    //     payload: error.message,
    //   });
    // }
  };

//--Learning styles--///
export const fetchLearningStyles = () => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.post(
      `${API_MAIN_URL}/content/learning-styles`,
      config
    );
    dispatch({
      type: FETCH_LEARNING_STYLES,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_LEARNING_STYLES_ERROR,
      payload: error.message,
    });
  }
};

export const storeLearningStyles =
  (selectedlearningStyles, onSuccess, onFailure) => async (dispatch) => {
    apiService.post({
      url: `${API_MAIN_URL}/learner/store-learning-style`,
      headers: getAuthHeaders(),
      data: {learning_styles: JSON.stringify(selectedlearningStyles)},
      onSuccess: ({data}) => {
        dispatch({
          type: STORE_LEARNING_STYLES,
        });
        onSuccess?.();
      },
      onFailure: (error) => {
        onFailure?.();
        toast.error(error.message);

        dispatch({
          type: FETCH_LANGUAGES_ERROR,
          payload: error.message,
        });
      },
    });

    // const token = localStorage.getItem("token");
    // const config = {
    //   headers: {
    //     "Content-Type": "application/json",
    //     "X-Auth-Token": token,
    //   },
    // };

    // const selectedLearnings = JSON.stringify(selectedlearningStyles);
    // const body = {
    //   learning_styles: selectedLearnings,
    // };

    // try {
    //   const res = await axios.post(
    //     `${API_MAIN_URL}/learner/store-learning-style`,
    //     body,
    //     config
    //   );
    //   dispatch({
    //     type: STORE_LEARNING_STYLES,
    //   });
    //   onSuccess?.();
    // } catch (error) {
    //   onFailure?.();
    //   dispatch({
    //     type: FETCH_LANGUAGES_ERROR,
    //     payload: error.message,
    //   });
    // }
  };

//---User Information--///

export const storeUser =
  (updatedProfile, onSuccess, onFailure) => async (dispatch) => {
    const {
      profile_pic,
      name,
      mobile_number,
      country_code,
      dob,
      user_type,
      address_firstline,
      address_secondline,
      city,
      state,
      country,
      sub_type,
      pincode,
      timezone,
    } = updatedProfile;

    // const config = {
    //   headers: {
    //     "Content-Type": "multipart/form-data",
    //     "X-Auth-Token": token,
    //   },
    // };

    const formData = new FormData();
    if (typeof profile_pic !== "string") {
      formData.append("profile_picture", profile_pic);
    }
    formData.append("name", name);
    formData.append("sub_type", sub_type);
    formData.append("dob", dob);
    formData.append("mobile_number", mobile_number);
    formData.append("country_code", country_code);
    formData.append("type", user_type);
    formData.append("address_line_1", address_firstline);
    formData.append("address_line_2", address_secondline);
    formData.append("city", city);
    formData.append("state", state);
    formData.append("country", country);
    formData.append("pincode", pincode);
    if (timezone) formData.append("timezone", timezone);

    apiService.post({
      url: `${API_MAIN_URL}/learner/update-profile`,
      headers: getAuthHeaders(),
      data: formData,
      onSuccess: (res) => {
        toast.success();
        if (res.data.timezone)
          localStorage.setItem("TIMEZONE", res.data.timezone);

        dispatch({
          type: STORE_USERINFO,
        });
        dispatch(getProfileData());

        onSuccess?.(res);
      },
      onFailure: (error) => {
        // handling formatting errors here
        if (typeof error.message === "object" && error.error.status == 422) {
          const erredFields = Object.keys(error.message);

          toast.error(
            `Your ${erredFields[0]} needs to be valid for us to update your profile.`
          );
        }
        onFailure?.({
          error_code: error.error.status,
          message: error.message,
          error,
        });
      },
    });

    // try {
    //   const res = await axios.post(
    //     `${API_MAIN_URL}/learner/update-profile`,
    //     fileData,
    //     config
    //   );

    //   if (res.data?.data?.timezone)
    //     localStorage.setItem("TIMEZONE", res.data?.data?.timezone);

    //   dispatch({
    //     type: STORE_USERINFO,
    //   });
    //   toast.success();
    //   onSuccess?.()
    // } catch (error) {
    //   toast.error();
    //   dispatch({ type: USERINFO_ERROR, payload: error.message });
    // }
  };
export const storeOnboarding = (onSuccess) => async (dispatch) => {
  const token = localStorage.getItem("token");

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      "X-Auth-Token": token,
    },
  };

  const fileData = new FormData();

  fileData.append("is_onboarded", 1);
  fileData.append("onboarding_status", "completed");

  try {
    await axios.post(
      `${API_MAIN_URL}/learner/update-profile`,
      fileData,
      config
    );
    dispatch({
      type: ONBOARDING_STATUS,
    });
    onSuccess?.();
  } catch (error) {
    // dispatch({ type: USERINFO_ERROR, payload: error.message })
  }
};

///STORE PASSWORD

export const storePassword = (accountData) => async (dispatch) => {
  const token = localStorage.getItem("token");
  const {email, oldPassword, newPassword, confirmPassword} = accountData;

  const config = {
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    },
  };
  const body = {
    email,
    old_password: oldPassword,
    password: newPassword,
    confirmPassword,
  };

  try {
    const res = await axios.post(
      `${API_MAIN_URL}/learner/update-password`,
      body,
      config
    );

    dispatch({
      type: STORE_ACCOUNT_INFO,
    });
    if (res.data.status) {
      toast.success(res.data.message);
    } else {
      toast.error(res.data.message);
    }
  } catch (error) {
    dispatch({
      type: ACCOUNT_INFO_ERROR,
      payload: error.response?.data?.message,
    });
    toast.error(error.response?.data?.message);
  }
};

export const fetchCurriculum = () => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.post(
      `${API_MAIN_URL}/content/curriculums-list`,
      config
    );
    dispatch({
      type: FETCH_CURRICULUM,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_CURRICULUM_ERROR,
      payload: error.message,
    });
  }
};

export const fetchGrade = () => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.post(`${API_MAIN_URL}/content/grades-list`, config);
    dispatch({
      type: FETCH_GRADES,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_GRADES_ERROR,
      payload: error.message,
    });
  }
};
export const fetchSubject = () => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.post(
      `${API_MAIN_URL}/content/subjects-list`,
      config
    );
    dispatch({
      type: FETCH_SUBJECTS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_SUBJECTS_ERROR,
      payload: error.message,
    });
  }
};

export const fetchInterest = () => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.post(
      `${API_MAIN_URL}/content/interests-list`,
      config
    );
    dispatch({
      type: FETCH_INTERESTS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_INTERESTS_ERROR,
      payload: error.message,
    });
  }
};

//STORE TOPICS
export const storeTopics =
  (curriculumTags, gradeTags, interestTags, languageTags, timezone, callback) =>
  async (dispatch) => {
    const data = {
      grades: JSON.stringify(gradeTags),
      subjects: JSON.stringify(interestTags),
      languages: JSON.stringify(languageTags),
      curriculums: JSON.stringify(curriculumTags),
      timezone,
    };

    toast.loading();
    apiService.post({
      url: `${API_MAIN_URL}/learner/store-topic`,
      headers: getAuthHeaders(),
      data,
      onSuccess: (res) => {
        // console.log(res);
        toast.dismiss();
        dispatch({
          type: STORE_TOPICS_INFO,
        });

        callback();
      },
      onFailure: (error) => {
        // console.log(error);
        toast.error(
          typeof error.message === "object"
            ? `There was an error with your selected ${
                Object.keys(error.message)[0]
              }`
            : error.message
        );
        dispatch({type: TOPICS_INFO_ERROR, payload: error.message});
      },
    });

    // const token = localStorage.getItem("token");
    // const config = {
    //   headers: {
    //     "Content-Type": "application/json",
    //     "X-Auth-Token": token,
    //   },
    // };

    // const body = {
    //   grades: JSON.stringify(gradeTags),
    //   subjects: JSON.stringify(interestTags),
    //   languages: JSON.stringify(languageTags),
    //   curriculums: JSON.stringify(curriculumTags),
    //   timezone,
    // };

    // try {
    //   await axios.post(`${API_MAIN_URL}/learner/store-topic`, body, config);

    //   dispatch({
    //     type: STORE_TOPICS_INFO,
    //   });

    //   callback();
    // } catch (error) {
    //   dispatch({ type: TOPICS_INFO_ERROR, payload: error.message });
    // }
  };

// Referals

// export const fetchReferals =
//   (name = "", start_date = "", months = "") =>
//   async (dispatch) => {
//     const token = localStorage.getItem("token");

//     const config = {
//       headers: {
//         "Content-Type": "application/json",
//         "X-Auth-Token": token,
//       },
//     };
//     try {
//       const res = await axios.get(`${API_MAIN_URL}/get-referrals-list`, config);
//       dispatch({
//         type: FETCH_REFERALS,
//         payload: res.data,
//       });
//     } catch (error) {
//       dispatch({
//         type: FETCH_REFERALS_ERROR,
//         payload: error.message,
//       });
//     }
//   };

export const fetchPopularTutors = () => async (dispatch) => {
  const data = [
    {key: "limit", value: 3},
    {key: "sort", value: "Trending"},
  ];

  apiService.get({
    url: `${API_MAIN_URL}/tutor/search`,
    headers: getAuthHeaders(),
    data,
    onSuccess: ({data}) => {
      dispatch({
        type: FETCH_POPULAR_TUTORS,
        payload: data,
      });
    },
    onError: (err) => {},
  });
};

export const getLearningMaterialsProgress = async ({onSuccess, onFailure}) => {
  apiService.get({
    url: `${API_MAIN_URL}/learner/learning-material/get-progress`,
    headers: getAuthHeaders(),
    onSuccess: ({data}) => onSuccess?.(data),
    onFailure: ({error}) => onFailure?.(error),
  });
};

export const getPopularLearningMaterials = async ({
  onSuccess,
  onFailure,
  limit = 3,
}) => {
  apiService.get({
    url: `${API_MAIN_URL}/contentbuilder/learning-material/trending?limit=${limit}`,
    headers: getAuthHeaders(),
    onSuccess: ({data}) => {
      onSuccess?.(data);
    },
    onFailure: ({error}) => {
      onFailure?.(error);
    },
  });
};
