import React from "react";
import Component from "./static-field";
import StaticMultiField from "./static-field-multi";

// Types.
import type { ComboboxOption } from "components/combobox";
import type { StaticDataV3Key } from "constants/types/types-static-data";

export const StaticField = Object.assign(Component, {
  Multi: StaticMultiField,
});

export type StaticFieldOption = ComboboxOption;

export type BaseStaticFieldProps = Omit<
  React.ComponentPropsWithoutRef<"div">,
  "onChange"
> & {
  /**
   * The type of static data to use.
   */
  staticKey: StaticDataV3Key;

  /**
   * The values to be excluded from the list of options.
   *
   * @default []
   */
  excludedValues?: StaticFieldOption["value"][];

  slotAbove?: React.ReactNode;
  slotBelow?: React.ReactNode;
  hasSuggestions?: boolean;
};

export * from "./static-field";
export * from "./static-field-multi";
