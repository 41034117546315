import {IconNotebook, IconSearch} from "@tabler/icons";
import {TabBar} from "components";
import React from "react";
import {useState} from "react";
import {Link} from "react-router-dom";
import {CardBody} from "reactstrap";
import LearnerAllTasks from "./learner-all-tasks";
import LearnerMaterialTasks from "./learner-material-tasks";
import LearnerSessionTasks from "./learner-session-tasks";

import LearningMaterialService from "service/learning-materials";
import LearningMaterialMiddleware from "middlewares/learning-materials";
import {useApiEffect} from "hooks";
import {useDispatch, useSelector} from "react-redux";
import {fetchLearnerOrderedBundles} from "store";
import {WEBSITE_URL} from "constants";
import {SassyCard} from "components/cards";


/**
 * @author Abdullah-Sajjad026
 */
const LearnerDashboardTasks = () => {
  const [activeTab, setActiveTab] = useState(1);
  const dispatch = useDispatch();
  let allTasks = [];

  const [purchasedLMs, setPurchasesLMs] = useState({
    state: "loading",
    data: [],
  });

  const learningMaterialService = new LearningMaterialService();
  const lmMiddleWare = new LearningMaterialMiddleware();

  const orderedBundles = useSelector(
    (state) => state.learnerTutoring.orderedBundles
  );

  useApiEffect(() => {
    learningMaterialService.fetchPurchased({
      filter: lmMiddleWare.FILTER_INCOMPLETE,
      currentPage: 1,
      limit: 3,
      onSuccess: (res) => {
        setPurchasesLMs({state: "loaded", data: res.data});
      },
      onFailure: (error) => {
        setPurchasesLMs({state: "erred", data: []});
      },
    });

    dispatch(fetchLearnerOrderedBundles({}));
  }, []);

  if (purchasedLMs.state === "loaded" && orderedBundles.state === "loaded") {
    for (let i = 0; i < 10; i++) {
      if (i % 2 === 0) {
        if (orderedBundles.data?.[i]) {
          allTasks.push({taskType: "session", ...orderedBundles.data[i]});
        } else if (purchasedLMs.data?.[i]) {
          allTasks.push({
            taskType: "learning-material",
            ...purchasedLMs.data[i],
          });
        }
      } else {
        if (purchasedLMs.data?.[i]) {
          allTasks.push({
            taskType: "learning-material",
            ...purchasedLMs.data[i],
          });
        } else if (orderedBundles.data?.[i]) {
          allTasks.push({taskType: "session", ...orderedBundles.data[i]});
        }
      }
    }
  }

  return (
    <SassyCard>
      <TabBar>
        <TabBar.Item
          activeTab={activeTab}
          onSwitchTab={setActiveTab}
          tabNumber={1}
        >
          All
        </TabBar.Item>
        <TabBar.Item
          activeTab={activeTab}
          onSwitchTab={setActiveTab}
          tabNumber={2}
        >
          Sessions
        </TabBar.Item>
        <TabBar.Item
          activeTab={activeTab}
          onSwitchTab={setActiveTab}
          tabNumber={3}
        >
          Materials
        </TabBar.Item>
      </TabBar>
      <CardBody>
        {activeTab === 1 ? (
          <>
            <LearnerAllTasks
              allTasks={allTasks}
              state={
                [purchasedLMs.state, orderedBundles.state].includes("erred")
                  ? "erred"
                  : [purchasedLMs.state, orderedBundles.state].includes(
                      "loading"
                    )
                  ? "loading"
                  : "loaded"
              }
            />
          </>
        ) : activeTab === 2 ? (
          <>
            <LearnerSessionTasks sessionTasks={{...orderedBundles}} />
          </>
        ) : (
          activeTab === 3 && (
            <>
              <LearnerMaterialTasks lmTasks={{...purchasedLMs}} />
            </>
          )
        )}

        <div className="mt-3 d-flex flex-column flex-sm-row gap-3">
          <a
            href={`${WEBSITE_URL}/tutor/search`}
            className="btn flex-grow-1 text-info bg-soft-info d-flex justify-content-center align-items-center gap-2 text-capitalize"
          >
            <IconSearch />
            Let's find your next tutor
          </a>
          <Link
            // href={`${WEBSITE_URL}`}
            to="/learning-materials"
            className="btn flex-grow-1 text-success bg-soft-success d-flex justify-content-center align-items-center gap-2 text-capitalize"
          >
            <IconNotebook />
            Let's buy some learning materials
          </Link>
        </div>
      </CardBody>
    </SassyCard>
  );
};

export default LearnerDashboardTasks;
