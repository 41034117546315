import React from "react";
import moment from "moment";
import {getAdjustedTime} from "utils";
import {Avatar} from "components";
import {IconNotebook} from "@tabler/icons";

export default function SessionConductInfo({
  userImage,
  userName,
  subjectName,
  date,
  startTime,
  endTime,
}: {
  startTime: string;
  endTime: string;
  date: string;
  subjectName: string;
  userName: string;
  userImage: string;
}) {
  return (
    <div className="d-flex flex-column align-items-center gap-3 text-secondary">
      <div className="hstack gap-3 justify-content-center">
        <div className="hstack gap-2">
          <Avatar src={userImage} className="img-fluid" />
          <span>{userName}</span>
        </div>

        <span className="green-dot"></span>

        <div className="hstack gap-2">
          <IconNotebook />
          <span>{subjectName}</span>
        </div>
      </div>
      <div className="p-2 rounded bg-gray-300 border-1">
        <h3 className="font-size-14 fw-bold m-0 ">
          {/* {date} - {startTime} to {endTime} */}
          {moment(date, "YYYY-MM-DD").format("DD MMM YYYY")} -{" "}
          {getAdjustedTime(startTime, "HH:mm:ss").format("hh:mm A")} to{" "}
          {getAdjustedTime(endTime, "HH:mm:ss").format("hh:mm A")}
        </h3>
      </div>
    </div>
  );
}
