import * as React from "react";
import { ListGroup } from "reactstrap";
import { SassyAlert } from "components";

/**
 * The result displayer of the form-search component.
 * @param {Object} props
 *
 * @param {Array} props.data
 * @param {RequestState} props.state
 * @param {string} props.indexTitleKey
 * @param {string} props.indexDescriptionKey
 * @param {(record: any) => void} props.onSelectRecord
 *
 * @returns {JSX.Element}
 *
 * @author kashan-ahmad
 * @version 0.0.1
 */
const SearchFormResult = ({
  state,
  data = [],
  onSelectRecord,
  indexTitleKey,
  indexDescriptionKey,
}) => (
  <div className="w-100">
    {
      {
        idle: (
          <SassyAlert description="Perform a search and it'll appear here" />
        ),
        loading: <SassyAlert color="success" description="Loading..." />,
        erred: (
          <SassyAlert
            color="danger"
            description="There was an error, please try again"
          />
        ),
        loaded:
          data && data.length === 0 ? (
            <SassyAlert color="warning" description="No results found" />
          ) : (
            <ListGroup className="vstack gap-2">
              {data.map((object, index) => (
                <li key={index} className="p-0 rounded text-secondary">
                  <SassyAlert
                    as="button"
                    title={object[indexTitleKey]}
                    onClick={() => onSelectRecord(object)}
                    description={object[indexDescriptionKey]}
                    className="hover:bg-gray-300 focus:bg-gray-300 w-100 text-start"
                  />
                </li>
              ))}
            </ListGroup>
          ),
      }[state]
    }
  </div>
);

export default SearchFormResult;
