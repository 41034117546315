export const breakpoints = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
};

export const breakpointNames = {
  sm: "576px",
  md: "768px",
  lg: "992px",
  xl: "1200px",
  xxl: "1400px",
};

const rootStyle = getComputedStyle(document.body);

export const COLORS = {
  primaryColor: rootStyle.getPropertyValue("--primary-color").trim(),
  primaryColor1: rootStyle.getPropertyValue("--primary-color-1").trim(),
  primaryColor2: rootStyle.getPropertyValue("--primary-color-2").trim(),
  primaryColor3: rootStyle.getPropertyValue("--primary-color-3").trim(),
  primaryColor4: rootStyle.getPropertyValue("--primary-color-3").trim(),

  secondaryColor: rootStyle.getPropertyValue("--secondary-color").trim(),
  secondaryColor1: rootStyle.getPropertyValue("--secondary-color-1").trim(),
  secondaryColor2: rootStyle.getPropertyValue("--secondary-color-2").trim(),
  secondaryColor3: rootStyle.getPropertyValue("--secondary-color-3").trim(),
  secondaryColor4: rootStyle.getPropertyValue("--secondary-color-4").trim(),
  secondaryColor5: rootStyle.getPropertyValue("--secondary-color-5").trim(),
  secondaryColor6: rootStyle.getPropertyValue("--secondary-color-6").trim(),

  grayColor1: rootStyle.getPropertyValue("--gray-1").trim(),
  grayColor3: rootStyle.getPropertyValue("--gray-3").trim(),
  grayColor6: rootStyle.getPropertyValue("--gray-6").trim(),
  grayColor9: rootStyle.getPropertyValue("--gray-9").trim(),
  grayColor10: rootStyle.getPropertyValue("--gray-10").trim(),
};

export function isMobileWidth() {
  return window.innerWidth <= breakpoints.sm;
}

export function isTabletWidth() {
  return window.innerWidth <= breakpoints.md;
}
