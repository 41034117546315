import React from "react";
import { getEnvVar } from "utils/utils-env";
import {
  removeOnboardingSkillsSubj,
  removeToken,
  removevailibilityData,
} from "utils/utils-auth";

// Hooks.
import useToast from "hooks/use-toast";

// Components.
import { ConfirmActionModal } from "modules/modals/confirmation";
import { SimpleModalBody } from "modules/modals/components/modal-body/variants";
import { DeletionModalFooter } from "modules/modals/components/modal-footer/variants";

export default function useAuth() {
  const toast = useToast();

  const logout = React.useCallback(() => {
    toast.render(
      <ConfirmActionModal
        checkLogout={true}
        body={
          <SimpleModalBody>Are you sure you want to logout?</SimpleModalBody>
        }
        footer={
          <DeletionModalFooter
            deleteButtonText="Logout"
            onCancel={() => toast.dismiss()}
            onDelete={() => {
              // Set the access token.
              removeToken();
              removevailibilityData();
              // Redirect to the website.
              window.location.assign(
                `${getEnvVar("WEBSITE_URL")}/auth/login?isLoggedOut=true`
              );
            }}
          />
        }
      />
    );
  }, [toast]);

  return {
    logout,
  };
}
