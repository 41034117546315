import * as React from "react";
import { FETCH_NOTIFICATIONS_INTERVAL } from "constants";
import {
  fetchNotifications,
  useNotificationDispatch,
  useNotificationSelector,
} from "store";

/**
 * @returns {import("constants/types/types-notifications").NotificationState}
 */
function useNotifications() {
  const dispatch = useNotificationDispatch();
  const notificationState = useNotificationSelector((state) => state);

  React.useEffect(() => {
    // Initial request.
    notificationState.isLoaded || dispatch(fetchNotifications({}));

    const interval = setInterval(
      () =>
        // Once after a fixed period.
        dispatch(fetchNotifications({})),
      FETCH_NOTIFICATIONS_INTERVAL
    );

    return () => clearInterval(interval);
  }, []);

  return notificationState;
}

export default useNotifications;
