import { useRemoteProfileValue } from "hooks/profile";
import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

/**
 * Navigator to check if navigation towards any route is allowed for a user or not.
 *
 * @returns {JSX.Element}
 *
 * @author kashan-ahmad
 * @version 0.0.1
 */
function Navigator() {
  // Store.

  const profile = useRemoteProfileValue();

  return profile?.is_onboarded ? (
    // If the user is onboarded.
    <Outlet />
  ) : (
    <Navigate to="/placeholder" />
  );
}

export default Navigator;
