import {Icon} from "./";
import {IconCircleX} from "@tabler/icons";

function MyModalHeader({title, toggleModal}) {
  return (
    <header className="d-flex justify-content-between">
      <h1 className="fs-3 fw-bold m-0 text-secondary">{title}</h1>
      <Icon
        tag="button"
        onClick={toggleModal}
        size="sm"
        className="text-danger"
      >
        <IconCircleX />
      </Icon>
    </header>
  );
}

export default MyModalHeader;
