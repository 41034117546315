import { Input, Label } from "reactstrap";
import { ErrorMessage, Field } from "formik";
import FormErrorMessage from "components/error-message-form";
import SassyFormLabel from "../sassy-form-label";

/**
 * A formik-based form field wrapper but sassy, not for general usage.
 * Intended to be used by the sassy siblings. Wraps the field with a label and an error message.
 *
 * @param {Object} props
 *
 * @param {string} props.name
 * The name of the field.
 *
 * @param {string} props.label
 * The label of the field
 *
 * @returns {JSX.Element}
 */
function SassyFormFieldCheckbox({ label, name }) {
  return (
    <>
      <SassyFormLabel htmlFor={name} className="d-flex gap-2">
        <Field
          as={Input}
          id={name}
          name={name}
          type="checkbox"
          data-testid={`${name}Field`}
          className="flex-shrink-0"
        />
        <div>{label}</div>
      </SassyFormLabel>
      <ErrorMessage
        name={name}
        render={(message) => (
          <FormErrorMessage data-testid={`${name}Error`} {...{ message }} />
        )}
      />
    </>
  );
}

export default SassyFormFieldCheckbox;
