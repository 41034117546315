import React from "react";

// Hooks.
import useToast from "hooks/use-toast";
import { useStaticDataV3 } from "hooks/static-data";
import { useRemoteSkills } from "hooks/tutor/skills";

// Utils.
import { boxStyles, gridStyles } from "constants/config";

// Components.
import RatingField from "components/forms/rating-field";
import { IconDotsVertical, IconTrash } from "@tabler/icons";
import { Col, Dropdown, ListGroup, Row } from "react-bootstrap";
import { ConfirmDeletionModal } from "modules/modals/confirmation";
import { SimpleModalBody } from "modules/modals/components/modal-body/variants";
import { DeletionModalFooter } from "modules/modals/components/modal-footer/variants";

type Props = {
  header?: React.ReactNode;

  /**
   * Props to be passed to the `Col` component.
   *
   * @default { xs: 12, md: 6 }
   */
  colProps?: React.ComponentProps<typeof Col>;
};

export default function Skills({ header, colProps }: Props) {
  const toast = useToast();
  const { skills, deleteSkill } = useRemoteSkills();
  const { skills: staticSkills, getStaticData } = useStaticDataV3(["skills"]);

  if (!skills.length) return <></>;

  function onClickDelete(skillId: string | number) {
    toast.render(
      <ConfirmDeletionModal
        body={
          <SimpleModalBody>
            Are you sure you want to delete this skill?
          </SimpleModalBody>
        }
        footer={
          <DeletionModalFooter
            onCancel={() => toast.dismiss()}
            onDelete={() => {
              toast.loading();

              deleteSkill({
                skillId,
                onFailure: ({ message }) => toast.error(message),
                onSuccess: () => toast.success(),
              });
            }}
          />
        }
      />
    );
  }

  /**
   * Default breakpoints for the `Col` component.
   */
  const breakpoints = {
    ...colProps,
    xs: colProps?.xs || 12,
    md: colProps?.md || 6,
  };

  return (
    <>
      {header}
      <ListGroup>
        <Row className={gridStyles.gutters.sm.base}>
          {skills.map(({ id, skill: skillId, rating }, i) => (
            <Col key={i} {...breakpoints}>
              <ListGroup.Item
                className={`h-100 rounded position-relative d-flex align-items-center ${boxStyles.p}`}
              >
                <Row
                  className={`w-100 align-items-center ${gridStyles.gutters.sm.base}`}
                >
                  <Col xs={12} md={6}>
                    {getStaticData(skillId, staticSkills.data)}
                  </Col>
                  <Col xs={12} md={6} className="d-flex justify-content-end">
                    <RatingField
                      readOnly
                      className="me-3"
                      value={parseInt(`${rating}`)}
                    />
                  </Col>
                </Row>
                <Dropdown
                  align="end"
                  className="w-auto p-0 m-0 border-0 position-absolute end-0 top-50 translate-middle z-1"
                >
                  <Dropdown.Toggle variant="link" className="p-0">
                    <IconDotsVertical />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => onClickDelete(id)}
                      className={`d-flex align-items-center ${boxStyles.xGap}`}
                    >
                      <IconTrash size={16} />
                      Delete
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </ListGroup.Item>
            </Col>
          ))}
        </Row>
      </ListGroup>
    </>
  );
}
