import React from "react";

export type Suggestion = {
  value: number | string;
  label: number | string;
};

export type SuggestionsProps<T> = React.ComponentPropsWithoutRef<"div"> & {
  /**
   * Suggestions to display.
   * @example [{ value: "test", label: "test" }]
   */
  suggestions: T[];

  /**
   * Key to display in the suggestions.
   * @example "label"
   */
  keyToDisplay?: string;

  /**
   * Function to call when a suggestion is clicked.
   * @example (suggestion) => console.log({ suggestion })
   * @param suggestion The suggestion that was clicked.
   */
  onClickSuggestion: (suggestion: T) => void;
};

/**
 * Component to display a list of suggestions.
 * @example <Suggestions suggestions={[{ value: "test", label: "test" }]} />
 */
export default function Suggestions<T = Suggestion>({
  suggestions,
  onClickSuggestion,
  keyToDisplay = "value",
  ...props
}: SuggestionsProps<T>) {
  return (
    <div {...props} data-testclass="suggestions">
      Suggested:&nbsp;
      {suggestions.map((suggestion, index) => {
        const key = suggestion[keyToDisplay];

        // If the key is not a string or number, return null.
        if (typeof key !== "string" && typeof key !== "number") {
          console.error(`keyToDisplay must be a string or number`);
          return null;
        }

        return (
          <button
            key={key}
            type="button"
            data-testclass="suggestion"
            className="btn-link text-primary"
            onClick={() => onClickSuggestion(suggestion)}
          >
            {/* If the suggestion is not the last one, add a comma after it. */}
            {index < suggestions.length - 1 ? `${key},` : key}&nbsp;
          </button>
        );
      })}
    </div>
  );
}
