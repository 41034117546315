import React, { useEffect, useRef, useState } from "react";
import moment from "moment";

// Plugins.
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import BootstrapTheme from "@fullcalendar/bootstrap";
import interactionPlugin from "@fullcalendar/interaction";

// Static
import "./calendar.scss";

/**
 * @typedef CalendarRangeProps
 *
 * @property {string} startDate
 * The starting date of the range/event.
 * Must be in `YYYY-MM-DD` format
 *
 * @property {string} endDate
 * The starting date of the range/event.
 * Must be in `YYYY-MM-DD` format
 *
 * @property {(date: string, doReset: boolean) => void} onChangeStartDate
 * The handler to report a new `startDate` to.
 * Note the `doReset` parameter. This indicates
 * that the calendar is resetting the range in
 * order to initiate a new range. If this is `true`,
 * you need to reset the `endDate` property and a
 * new date range will be set.
 *
 * @property {(date: string) => void} onChangeEndDate
 * The handler to report a new `endDate` to.
 */

/**
 * A @fullcalendar/react based calendar
 * for displaying a date range using the
 * package's `events` API.
 *
 * Date validation is done automatically.
 * To elaborate, dates will always be in
 * accordance with real-time, ending date
 * can't precede the starting date.
 *
 * Not to mention that the calendar resets
 * itself if the user has selected a range
 * and then selects another date. This
 * smoothly removes the previous range and
 * initiates a new one, provided you handle
 * the events correctly.
 *
 * @param {CalendarRangeProps} Object
 *
 * @returns {JSX.Element}
 * @version 0.0.2
 * @author kashan-ahmad
 *
 * @changelog
 * - 0.0.2: Added reset functionality.
 * - 0.0.1: Initial version.
 */
function CalendarRange({
  endDate,
  startDate,
  onChangeEndDate,
  onChangeStartDate,
}) {
  const ref = useRef();
  const eventNodes = useRef([]);
  const [dateRange, setDateRange] = React.useState([]);
  const [selectedMonth, setSelectedMonth] = React.useState();

  React.useEffect(() => {
    if (endDate) {
      setDateRange([
        {
          id: 0,
          start: startDate,
          // Problem here is that the fullCalendar
          // shows the event ending right before the
          // provided end date. So we add a day to the
          // event's tenure and the display it as a
          // normal start date to end date event.
          end: moment(moment(endDate, "YYYY-MM-DD"))
            .add(1, "d")
            .format("YYYY-MM-DD"),
          display: "background",
        },
      ]);

      resetEventNodes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDate]);

  const resetEventNodes = () => {
    // Remove the styles for a selected node
    // from the formerly selected nodes.
    eventNodes.current.forEach((node) =>
      node.classList.remove("fc-day-selected")
    );

    // Empty the array since the events no longer exist.
    eventNodes.current.splice(0, eventNodes.length);
  };

  const resetCalendar = () => {
    // @see https://fullcalendar.io/docs/react#calendar-api
    ref.current?.getApi().removeAllEvents();

    resetEventNodes();
  };

  /**
   * Sets the styles on a node.
   * @param {HTMLElement} dateElement
   * The element received from the `DateClickArg` property
   * that is returned by the Calendar.dateClick callback.
   */
  const setEventNode = (dateElement) => {
    const dateNode = dateElement.children[0].children[0].children[0];

    // Set the selected date styles.
    dateNode.classList.add("fc-day-selected");
    eventNodes.current.push(dateNode);
  };

  // * This will check for the current month and selected month
  // * if selected month is less or equal to current month than disable the back button
  // * and then disable the previous dates as weel of current month
  useEffect(() => {
    const calendarApi = ref.current.getApi();

    // Disable the previous button if the selected month is less than or equal to the current month
    if (calendarApi) {
      const currentMonth = new Date().getMonth();
      const prevButton = document.querySelector(".fc-prev-button");

      if (selectedMonth <= currentMonth) {
        prevButton.disabled = true;
      } else {
        prevButton.disabled = false;
      }

      // * This will disable previous dates from todays date
      calendarApi.setOption("validRange", {
        start: new Date(),
        end: null,
      });
    }
  }, []);

  /**
   * @param {import("@fullcalendar/interaction").DateClickArg} date
   */
  const onClick = (date) => {
    // The calendar needs to be reset when
    // the range is completely set.
    // This initiates a new range.
    if (startDate && endDate) {
      resetCalendar();
      setEventNode(date.dayEl);
      onChangeStartDate(date.dateStr, true);
      return;
    }

    // The first selection indicates the first date of the range.
    if (!startDate) {
      setEventNode(date.dayEl);
      onChangeStartDate(date.dateStr);
      return;
    }

    // Otherwise it's the second date.
    // Which needs to be after the first date, in terms of real time.
    if (
      moment(date.dateStr, "YYYY-MM-DD").isAfter(
        moment(startDate, "YYYY-MM-DD")
      )
    ) {
      setEventNode(date.dayEl);
      onChangeEndDate(date.dateStr);
    }
  };

  return (
    <div className="calendar">
      <FullCalendar
        ref={ref}
        plugins={[BootstrapTheme, dayGridPlugin, interactionPlugin]}
        themeSystem="bootstrap"
        dateClick={onClick}
        events={dateRange}
        headerToolbar={{
          left: "prev",
          center: "title",
          right: "next",
        }}
        datesSet={(dateInfo) => {
          // Extract the month value from the current date range
          const month = dateInfo.view.currentStart.getMonth();

          // Updates selected state with the selected month value
          setSelectedMonth(month);
        }}
      />
    </div>
  );
}

export default CalendarRange;
