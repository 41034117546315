import * as Yup from "yup";
import { RemoteProfile } from "constants/types";
import { getEnvInt } from "utils/utils-env";

export const nameSchema = Yup.string()
  .matches(
    /^[a-zA-Z .]+$/,
    "Full Name can only contain alphabets, spaces and dots"
  )
  .required("Full Name is required");

export const dobSchema = Yup.date()
  .required("Date of Birth is required")
  .max(new Date(), "Date of Birth must be in the past");

export const timezoneSchema = Yup.object({
  value: Yup.string().required("Timezone is required"),
});

export const titleSchema = Yup.string().required("Profile Title is required");

export const languagesSchema = Yup.array()
  .of(Yup.object())
  .min(1, "At least one Language is required");

export const mobileNumberSchema = Yup.object({
  number: Yup.string().required("Mobile Number is required"),
});

const maxLength = getEnvInt("MAX_BIO_CHARACTERS");
export const bioSchema = Yup.string()
  .required("Bio is required")
  .min(100, "Bio must be at least 100 characters")
  .max(maxLength, `Bio must be less than ${maxLength} characters`);

export const profileSchema = new Map<keyof RemoteProfile, unknown>([
  ["dob", dobSchema],
  ["bio", bioSchema],
  ["name", nameSchema],
  ["title", titleSchema],
  ["timezone", timezoneSchema],
  ["languages", languagesSchema],
  ["mobile_number", mobileNumberSchema],
]);
