import { capitalize } from "utils";

export const transactionStatus = {
  charge: "Paid",
};

export const requirements = {
  ALL: "Please fill all of the required fields",
  INIT: (label) => `${capitalize(label)} is required`,
  AT_LEAST_ONE: (label) => `At least 1 ${label} is required`,
  MIN_CHARS: (limit, label) =>
    `${capitalize(label)} must be at least ${limit} characters or longer`,
  FEATURE: "A valid Feature is required",
  TIMEZONE: "Please select your Timezone",
};

export const tutorBankAddRequirements = {
  BRANCH_CITY: "Branch City is required",
  EMAIL: "Email Address is required",
  ADDRESS: "Address is required",
  CURRENCY: "Currency is required",
  BANK_NAME: "Bank Name is required",
  LAST_NAME: "Last Name is required",
  BRANCH_STATE: "Branch State / Province is required",
  COUNTRY: "Branch Country is required",
  FIRST_NAME: "First Name is required",
  BRANCH_NAME: "Branch Name is required",
  ACCOUNT_TYPE: "At least 1 option is required",
  POSTAL_CODE: "Bank's Zip / Postal Code is required",
  ZONE_CODE: "Zone Code is required",
  ACCOUNT_NUMBER: "Account Number or IBAN Number is required",
  ROUTING_NUMBER: "Routing Number is required",
  BANK_SWIFT_BIC: "Bank Swift BIC is required",
  BANK_ACCOUNT_TYPE: "Account Type is required",
  SPECIFIC_COUNTRY: "This field is required for your Country",
  BUSINESS_NAME: "Business Name is required for business accounts",
  PERSONAL_TAX_ID: "Personal Tax ID is required for personal accounts",
  BUSINESS_TAX_ID: "Business Tax ID is required for business accounts",
};

export const questions = {
  YOU_SURE: "Are you sure about that?",
  DONT_AGREE: "You must agree to continue",
};

export const prompts = {
  IBAN: "Enter Account Number or IBAN Number here",
  ROUTING_NUMBER: "Enter Routing Number or Sort Code or IFSC Number",
  PHONE_NUMBER: "Enter your Phone Number without dashes and country code",
  BANK_FIRST_NAME: "Enter the First Name as registered with your bank account",
  BANK_LAST_NAME: "Enter the Last Name as registered with your bank account",
  BANK_MAIL: "Enter the Email Address as registered with your bank account",
  BANK_INDIA:
    "If you select India as the country, you will have to enter your Place of birth and Date of birth",
  BANK_ACCOUNT_TYPE:
    "If you have a Personal Account, then you must enter your Personal Tax ID. \n If you have a Business Account, then you must enter your Business Name and Business Tax ID",
};

export const messages = {
  LOADING: "loading....",
  TOO_LONG: "Too long",

  SESSION_REQUEST_SENT: "Session Invitation has been sent",
  SESSION_REQUEST_ACCEPTED: "Session Invitation has been accepted",
  TOO_SHORT: "Too short",
  COPIED: "Copied to clipboard",
  DIGITS_ONLY: "Digits only please",
  CHANGES_SAVED: "All changes saved",
  ERR_DUPLICATE: "Record already exists",
  INVALID: (item) => `${item} is invalid`,
  MISMATCH: (arr) => `${arr.join(" & ")} don't match`,
  ERR_STEP: (stepName) => `Please complete this step to continue: ${stepName}`,
  ERR_CONN:
    "Connection error! Please check your internet connection and try again",
  FILE_SIZE: "File Size limit exceeded",
  FILE_FORMAT: "The chosen file format is not right",
  REMOVING: (label) => `Removing the ${label}....`,
  REMOVING_ERR: (label) => `Failed to remove the ${label}! Please try again`,
  REMOVED: (label) => `${capitalize(label)} removed successfully`,
  SEARCHING: (label) => `Searching for ${label}....`,
  SEARCH_EMPTY: "No results found for the given criteria",
  UPSERTED: (label) => `${capitalize(label)} created/updated successfully`,
  ACCOUNT_DEFAULT_ALR: (label) => `It's already the default ${label}`,
  ACCOUNT_DEFAULT_ERR_REMOVE: (label) => `Can't remove the default ${label}`,
  ACCOUNT_DEFAULT_ERR_SET: (label) =>
    `Can't set this ${label} as the default right now. Please refresh and try again`,
  BANK_ADDING: "Loading....",
  BANK_ADDED: "Bank added successfully!",
  AN_ERR: "We apologize, there was an error. Please reload and try again",
  LIMIT_REACHED: (label) =>
    `${capitalize(label)} limit reached, please contact support`,
  ERR_LIMIT_REACHED: (limit, label) =>
    `Nope, you can't have more than ${limit} ${label}`,
  ERR_LIMIT_REACHED_DESC: (label) =>
    `Maximum number of ${label} reached, remove the existing ones to add more`,
  ERR_LOADING_FILTERS: "Unable to load filters, please try again.",
  BANK_ERR_EXISTING:
    "This bank account is already linked with a user of TheTutor",
  BANK_ERR_UNMATCHED:
    "The information you entered does not match the Selected Bank's information, please try again",
  BANK_ERR_EXPIRED: "The session just timed out, please try again",
  FEATURES_EXCEEDED: (max) => `Can't have more than ${max} features!`,
  PRODUCT_DEACTIVATION:
    "Each product can be de-activated and so can be each of the session within",
  ERROR_LOADING_SESSIONS_CALENDAR:
    "An error has occurred while loading your sessions' schedule.",
  SLOT_NOT_SELECTED: "Please enter Time Details",
  SLOT_ALR_SELECTED:
    "This is a conflicting time slot, kindly choose some other time",
  NO_BUNDLE_CREDIT:
    "You don't have remaining credits to send a Session Request.",
  NOT_VALID_DATE:
    "Please select a valid date (equal to or before today's date)",
  NO_LEARNER_WITH_CREDITS:
    "None of your Learners have remaining credits with you",
  NO_LEARNERS_FOUND: "No Learners Found",
  ERROR_LOADING_INVOICE:
    "An error occurred while generating the invoice for this Transaction.",
  BACKUP_AVAILABILITY_EXPLANATION:
    "Your availability will be backed up locally and cleared from the server",
  UPDATE_TIMEZONE_ERROR_EXPLANATION:
    "The changes you made to your profile will be saved, including your timezone",
  RESTORE_AVAILABILITY_EXPLANATION:
    "Your availability will be restored from the local backup and saved again",
};

export const actionTypes = {
  ACTION_TYPE_ADD: "Add",
  ACTION_TYPE_EDIT: "Edit",
  ACTION_TYPE_CREATE: "Create",
};

const strings = {
  DEFAULT_ERROR_MESSAGE: "Something went wrong",
  DEFAULT_SUCCESS_MESSAGE: "All changes saved",
  DEFAULT_EMPTY_MESSAGE: "No results found",

  PAYOUT_TERMS:
    "The withdrawal amount will be subject to transaction fees, foreign exchange fees and any other relevant charges or taxes as applicable by the payment partner or your bank.",
  PAYOUT_DURATION:
    "Our payment partner requires 7 - 10 business days to transfer the funds. Post which the time to credit your account depends on your bank and its intermediary.",
  PAYOUT_MINIMUM:
    "Minimum amount that you can withdraw USD 50.00. Maximum amount that you can withdraw USD 10,000.00.",

  TOTAL_EARNINGS_TOOLTIP:
    "This is the total amount earned through completed sessions i.e. sessions that were bought, scheduled and conducted",
  BOOKED_EARNINGS_TOOLTIP:
    "These are potential earnings that would be realised once the sessions have been successfully conducted.",
  WITHDRAWABLE_EARNINGS_TOOLTIP:
    "These are your earnings of sessions that you have conducted. You can initiate a withdraw request.",
};

export default strings;
