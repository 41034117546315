import * as React from "react";

const Layout = React.lazy(() => import("./layout-vertical.js"));

const VerticalLayout = (props) => (
  <React.Suspense>
    <Layout {...props} />
  </React.Suspense>
);

export default VerticalLayout;
