import UserAdapter from "adapters/user";
import { MATERIAL_TYPE_AMBIGUOUS } from "constants";

/* eslint-disable eqeqeq */
export default class LearningMaterialAdapter {
  userAdapter = new UserAdapter();

  serialize(state) {
    return {
      page_no: state.currentPage || 1,
      number_of_pages: state.numOfPages || 1,
      data: !Array.isArray(state.records)
        ? []
        : state.records.map((record) => ({
            id: record.id || "",
            title: record.title || "",
            description: record.description || "",
            quiz_or_questions: record.hasQuiz ? 1 : 0,
            answers_key: record.hasAnswerKey ? 1 : 0,
            copyright: record.hasCopyright ? 1 : 0,
            product_thumbnail: record.thumbnail || "",
            product_preview: record.preview || "",
            published: record.isPublished ? 1 : 0,
            content_type: record.contentType ?? MATERIAL_TYPE_AMBIGUOUS,
            slug: record.slug || "",
            total_bought: record.numOfPlacedOrders || 0,
            tutor: record.seller
              ? this.userAdapter.serialize(record.seller)
              : {},
            price: record.price || 0,
            discount: record.discount || 0,
            completed_percentage: record.completionPercentage || 0,
          })),
    };
  }

  deserialize(state) {
    const deserializer = (record) => ({
      id: record.id || "",
      title: record.title || "",
      description: record.description || "",
      hasQuiz: record.quiz_or_questions == 1,
      hasAnswerKey: record.answers_key == 1,
      hasCopyright: record.copyright == 1,
      thumbnail: record.product_thumbnail || "",
      preview: record.product_preview || "",
      isPublished: record.published == 1,
      contentType: record.content_type ?? MATERIAL_TYPE_AMBIGUOUS,
      slug: record.slug || "",
      numOfPlacedOrders: record.total_bought || 0,
      tags: this.deserializeTags(record.tags),
      seller: record.tutor ? this.userAdapter.deserialize(record.tutor) : {},
      price: record.price || 0,
      discount: record.discount || 0,
      completionPercentage: record.completed_percentage || 0,
    });

    return {
      currentPage: state.page_no || 1,
      numOfPages: state.number_of_pages || 1,
      records: Array.isArray(state.data)
        ? state.data.map((record) => deserializer(record))
        : [deserializer(state.data)],
    };
  }

  deserializeTags(tags) {
    if (!Array.isArray(tags)) return [];

    return tags.map((value) => value.title);
  }
}
