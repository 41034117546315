import React from "react";
import { ONE_MB } from "constants/integers";
import { useTutorPhoto } from "hooks/photo/tutor";
import ProfilePictureForm from "modules/forms/form-profile-picture/form-profile-picture";
import templateAvatar from "assets/images/person.svg";
import { ratios } from "constants/config";

type Props = {
  value?: string;
};

export default function ProfilePhotoTutorForm({ value }: Props) {
  const { photo, onChangeListener } = useTutorPhoto({
    key: "profile_picture",
    maxSize: {
      label: "1MB",
      value: ONE_MB,
    },
    modalSize: "sm",
    modalTitle: "Preview Profile Photo",
    modalAspectRatio: ratios.profilePhoto,
  });

  return (
    <ProfilePictureForm
      value={photo}
      maxSize={ONE_MB}
      onChange={onChangeListener}
      initialValue={value || templateAvatar}
    />
  );
}
