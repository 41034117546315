import * as React from "react";
import { Loader } from "components";

// views

import TutoringLearnerSchedule from "./tutoring-learner-schedule";
import TutoringLearnerSessions from "./tutoring-learner-sessions";
import TutoringLearnerUpcomingSessionDetailed from "./tutoring-learner-upcoming-session-detailed";

const View = React.lazy(() => import("./tutoring-learner"));

const TutoringLearner = () => (
  <React.Suspense fallback={<Loader />}>
    <View />
  </React.Suspense>
);

export {
  TutoringLearner,
  TutoringLearnerSchedule,
  TutoringLearnerSessions,
  TutoringLearnerUpcomingSessionDetailed,
};
export const requestTypes = [
  {
    id: 2,
    title: "Accepted",
    color: "#3E6D9C",
  },
  {
    id: 1,
    title: "Pending",
    color: "#B8C7D6",
  },
  {
    id: 3,
    title: "Rejected",
    color: "#EA8686",
  },
  {
    id: 4,
    title: "Rescheduled",
    color: "#5CDB95",
  },
  {
    id: 5,
    title: "Cancelled",
    color: "#EA8686",
  },
];
