export const mytickets = [
    {
        'id' : '#1222' ,
        title : 'Lorem ipsum set amet ok java' ,
        date : '26 sep 2021' ,
        status : true ,
        description : 'Become an expert at SQL!Be comfortable putting SQL and PostgreSQL on their resume Learn to perform GROUP BY statements Replicate real-world situations and query reports' ,
        resolution : 'Become an expert at SQL!Be comfortable putting SQL and PostgreSQL on their resume Learn to perform GROUP BY statements Replicate real-world situations and query reports', 
         attachments : [
            {doc_title : "1. Card Pyament status ",doc_url : 'https://images.unsplash.com/photo-1487017159836-4e23ece2e4cf?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=871&q=80'},
            {doc_title : "2. Tutor Pyament status ",doc_url : 'https://images.unsplash.com/photo-1487017159836-4e23ece2e4cf?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=871&q=80'},
            {doc_title : "3. Pyament status ",doc_url : 'https://images.unsplash.com/photo-1487017159836-4e23ece2e4cf?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=871&q=80'},
        ]
    },
    {
        'id' : '#1255' ,
        title : 'Lorem ipsum set amet ok python' ,
        date : '27 sep 2021' ,
        status : true ,
        description : 'Become an expert at SQL!Be comfortable test SQL and PostgreSQL on their resume Learn to perform GROUP BY statements Replicate real-world situations and query reports' ,
        resolution : 'Become an expert at SQL!Be comfortable putting SQL and PostgreSQL on their resume Learn to perform GROUP BY statements Replicate real-world situations and query reports', 
         attachments : [
            {doc_title : "1. Card Pyament status ",doc_url : 'https://images.unsplash.com/photo-1487017159836-4e23ece2e4cf?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=871&q=80'},
            {doc_title : "2. Tutor Pyament status ",doc_url : 'https://images.unsplash.com/photo-1487017159836-4e23ece2e4cf?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=871&q=80'},
            {doc_title : "3. Pyament status ",doc_url : 'https://images.unsplash.com/photo-1487017159836-4e23ece2e4cf?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=871&q=80'},
        ]
    },
    {
        'id' : '#1262' ,
        title : 'Lorem ipsum set amet hello javascript' ,
        date : '28 sep 2021' ,
        status : true ,
        description : 'Become an expert at SQL!Be test  putting SQL and PostgreSQL on their resume Learn to perform GROUP BY statements Replicate real-world situations and query reports' ,
        resolution : 'Become an expert at SQL!Be comfortable putting SQL and PostgreSQL on their resume Learn to perform GROUP BY statements Replicate real-world situations and query reports', 
         attachments : [
            {doc_title : "1. Card Pyament status ",doc_url : 'https://images.unsplash.com/photo-1487017159836-4e23ece2e4cf?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=871&q=80'},
            {doc_title : "2. Tutor Pyament status ",doc_url : 'https://images.unsplash.com/photo-1487017159836-4e23ece2e4cf?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=871&q=80'},
            {doc_title : "3. Pyament status ",doc_url : 'https://images.unsplash.com/photo-1487017159836-4e23ece2e4cf?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=871&q=80'},
        ]
    },
    {
        'id' : '#1242' ,
        title : 'Lorem ipsum set amet wooow' ,
        date : '29 sep 2021' ,
        status : false ,
        description : 'Become an expert at SQL!Be comfortable putting SQL and PostgreSQL on their resume Learn to perform GROUP BY statements Replicate real-world situations and query reports' ,
        resolution : 'Become an expert at SQL!Be comfortable putting SQL and PostgreSQL on their resume Learn to perform GROUP BY statements Replicate real-world situations and query reports', 
         attachments : [
            {doc_title : "1. Card Pyament status ",doc_url : 'https://images.unsplash.com/photo-1487017159836-4e23ece2e4cf?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=871&q=80'},
            {doc_title : "2. Tutor Pyament status ",doc_url : 'https://images.unsplash.com/photo-1487017159836-4e23ece2e4cf?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=871&q=80'},
            {doc_title : "3. Pyament status ",doc_url : 'https://images.unsplash.com/photo-1487017159836-4e23ece2e4cf?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=871&q=80'},
        ]
    },
    {
        'id' : '#1212' ,
        title : 'Lorem ipsum set ametv tes title' ,
        date : '30 sep 2021' ,
        status : false ,
        description : 'Become an expert at SQL!Be comfortable putting SQL and PostgreSQL on their resume Learn to perform GROUP BY statements Replicate real-world situations and query reports' ,
        resolution : 'Become an expert at SQL!Be comfortable putting SQL and PostgreSQL on their resume Learn to perform GROUP BY statements Replicate real-world situations and query reports', 
         attachments : [
            {doc_title : "1. Card Pyament status ",doc_url : 'https://images.unsplash.com/photo-1487017159836-4e23ece2e4cf?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=871&q=80'},
            {doc_title : "2. Tutor Pyament status ",doc_url : 'https://images.unsplash.com/photo-1487017159836-4e23ece2e4cf?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=871&q=80'},
            {doc_title : "3. Pyament status ",doc_url : 'https://images.unsplash.com/photo-1487017159836-4e23ece2e4cf?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=871&q=80'},
        ]
    },
    {
        'id' : '#1298' ,
        title : 'Lorem ipsum set amet  jababammmi' ,
        date : '1 oct 2021' ,
        status : true ,
        description : 'Become an expert at SQL!Be comfortable putting SQL and PostgreSQL on their resume Learn to perform GROUP BY statements Replicate real-world situations and query reports' ,
        resolution : 'Become an expert at SQL!Be comfortable putting SQL and PostgreSQL on their resume Learn to perform GROUP BY statements Replicate real-world situations and query reports', 
        attachments : [
            {doc_title : "1. Card Pyament status ",doc_url : 'https://images.unsplash.com/photo-1487017159836-4e23ece2e4cf?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=871&q=80'},
            {doc_title : "2. Tutor Pyament status ",doc_url : 'https://images.unsplash.com/photo-1487017159836-4e23ece2e4cf?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=871&q=80'},
            {doc_title : "3. Pyament status ",doc_url : 'https://images.unsplash.com/photo-1487017159836-4e23ece2e4cf?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=871&q=80'},
        ]
    },
    {
        'id' : '#1256' ,
        title : 'Lorem ipsum set amet swoooooooii' ,
        date : '26 sep 2021' ,
        status : true ,
        description : 'Become an expert at SQL!Be comfortable putting SQL and PostgreSQL on their resume Learn to perform GROUP BY statements Replicate real-world situations and query reports' ,
        resolution : 'Become an expert at SQL!Be comfortable putting SQL and PostgreSQL on their resume Learn to perform GROUP BY statements Replicate real-world situations and query reports', 
         attachments : [
            {doc_title : "1. Card Pyament status ",doc_url : 'https://images.unsplash.com/photo-1487017159836-4e23ece2e4cf?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=871&q=80'},
            {doc_title : "2. Tutor Pyament status ",doc_url : 'https://images.unsplash.com/photo-1487017159836-4e23ece2e4cf?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=871&q=80'},
            {doc_title : "3. Pyament status ",doc_url : 'https://images.unsplash.com/photo-1487017159836-4e23ece2e4cf?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=871&q=80'},
        ]
    },
    {
        'id' : '#1278' ,
        title : 'Lorem ipsum set amet sisisisisii' ,
        date : '26 sep 2021' ,
        status : false ,
        description : 'Become an expert at SQL!Be comfortable putting SQL and PostgreSQL on their resume Learn to perform GROUP BY statements Replicate real-world situations and query reports' ,
        resolution : 'Become an expert at SQL!Be comfortable putting SQL and PostgreSQL on their resume Learn to perform GROUP BY statements Replicate real-world situations and query reports', 
         attachments : [
            {doc_title : "1. Card Pyament status ",doc_url : 'https://images.unsplash.com/photo-1487017159836-4e23ece2e4cf?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=871&q=80'},
            {doc_title : "2. Tutor Pyament status ",doc_url : 'https://images.unsplash.com/photo-1487017159836-4e23ece2e4cf?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=871&q=80'},
            {doc_title : "3. Pyament status ",doc_url : 'https://images.unsplash.com/photo-1487017159836-4e23ece2e4cf?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=871&q=80'},
        ]
    },
]
