import React from "react";
import View from "./subjects-form-view";
import Provider from "./subjects-form-provider";

// Formik.
import { Formik } from "formik";
import { boxStyles } from "constants/config";
import BaseForm from "components/forms/base-form";
import { MyFormProps } from "constants/types/types-form";

export default function SubjectsForm({ footer }: MyFormProps) {
  return (
    <Provider>
      {(props) => (
        <Formik {...props}>
          {(formikProps) => (
            <BaseForm className={`d-flex flex-column ${boxStyles.yGap}`}>
              <View {...formikProps} />
              {footer}
            </BaseForm>
          )}
        </Formik>
      )}
    </Provider>
  );
}
