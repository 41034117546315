/* eslint-disable react-hooks/exhaustive-deps */

import React, {useEffect} from "react";
import {Button} from "reactstrap";
import "../../../assets/css/profile.css";

import {connect} from "react-redux";
import // fetchCurriculum,
// fetchSubjects,
// fetchDegree,
// fetchQualifications,
// storeQualifications,
// storeExperience,
// storeSkills,
// storeUser,
// storeCertificates,
// storeSubjects,
"../../../store/tutordashboard/action";
// import {fetchLanguages} from "../../../store/learnerdashboard/action";
import "./tutor-on-board.scss";
import AddSubjects from "./AddSubjects";
import AddSkills from "./AddSkills";
import AddBio from "./AddBio";
import {Loader, SassyCard, SassyCardBody} from "components/";
import {isEmpty} from "lodash";
import {
  TutorProfileCertificatesView,
  TutorProfileExperiencesView,
  TutorProfileQualificationsView,
} from "views/view-profile";
import {ETutorOnboarding} from "constants/enums/ETutorOnboarding";
import ToastFactory from "utils/toast-factory";
import {messages} from "constants/strings";
import {useTutorProfile} from "hooks/profile";
import useTour from "hooks/use-tour";

/**
 * @author Abdullah-Sajjad026
 */
function ProfileSetup(props) {
  const {renderTour, tourState} = useTour();
  const {isValidBasicInfo} = useTutorProfile();
  const toastFactory = ToastFactory();
  const loader = props.loading || props.subjectloading;

  const [canContinue, setCanContinue] = React.useState(
    isValidBasicInfo() &&
      !isEmpty(props.storedSkills) &&
      !isEmpty(props.storedSubjects) &&
      !isEmpty(props.storedExperience) &&
      !isEmpty(props.storedQualification)
  );

  const finalSubmit = () => {
    let missedStep = "";

    const submissionValidation = {
      "basic information": isValidBasicInfo(),
      skills: !isEmpty(props.storedSkills),
      subjects: !isEmpty(props.storedSubjects),
      experience: !isEmpty(props.storedExperience),
      qualifications: !isEmpty(props.storedQualification),
    };

    Object.entries(submissionValidation).forEach(([stepName, isStepAdded]) => {
      if (!missedStep && !isStepAdded) {
        missedStep = stepName;
        return;
      }
    });

    if (!missedStep) {
      props.setStep("Bank Setup");
    } else {
      toastFactory.error(messages.ERR_STEP(missedStep));
    }
  };

  // Effect: Handle tour.
  useEffect(() => {
    renderTour("/tour/onboarding/tutor");
  }, [renderTour]);

  useEffect(() => {
    setCanContinue(
      isValidBasicInfo() &&
        !isEmpty(props.storedSkills) &&
        !isEmpty(props.storedSubjects) &&
        !isEmpty(props.storedExperience) &&
        !isEmpty(props.storedQualification)
    );
  }, [
    // when any of these booleans changes, we need to re-evaluate if we can continue.
    isValidBasicInfo(),
    !isEmpty(props.storedSkills),
    !isEmpty(props.storedSubjects),
    !isEmpty(props.storedExperience),
    !isEmpty(props.storedQualification),
  ]);

  if (loader || tourState === "loading") {
    return <Loader />;
  }

  return (
    <div className="recent-grid p-3 p-md-4 pt-0 pt-md-2">
      <SassyCard id={ETutorOnboarding.BASIC_INFO} className="mb-5">
        <SassyCardBody className="g-0 align-items-start">
          <h2 className="onboarding-tabs-header" data-tourstep="basicInfoForm">
            1. Basic Information
          </h2>
          <AddBio formSize={12} />
        </SassyCardBody>
      </SassyCard>

      <SassyCard id={ETutorOnboarding.QUALIFICATIONS} className="mb-5">
        <SassyCardBody className="g-0 align-items-start">
          <h2
            className="onboarding-tabs-header"
            data-tourstep="qualificationsForm"
          >
            2. Qualifications
          </h2>
          <div className=" vstack gap-4">
            <TutorProfileQualificationsView
              qualifications={props.user.qualifications}
              isEditing={true}
              isListEditable={false}
              allowFormCancel={false}
            />
          </div>
        </SassyCardBody>
      </SassyCard>

      <SassyCard id={ETutorOnboarding.EXPERIENCE} className="mb-5">
        <SassyCardBody className="g-0 align-items-start">
          <h2
            className="onboarding-tabs-header"
            data-tourstep="experiencesForm"
          >
            3. Experiences
          </h2>
          <div className=" vstack gap-4">
            <TutorProfileExperiencesView
              experiences={props.user.experiences}
              isEditing={true}
              isListEditable={false}
              allowFormCancel={false}
            />
          </div>
        </SassyCardBody>
      </SassyCard>

      <SassyCard id={ETutorOnboarding.SKILLS} className="mb-5">
        <SassyCardBody className="g-0 align-items-start">
          <h2 className="onboarding-tabs-header" data-tourstep="skillsForm">
            4. Skills
          </h2>
          <AddSkills />
        </SassyCardBody>
      </SassyCard>

      <SassyCard id={ETutorOnboarding.CERTS} className="mb-5">
        <SassyCardBody className="g-0 align-items-start">
          <h2
            className="onboarding-tabs-header"
            data-tourstep="certificatesForm"
          >
            5. Other Certificates & Videos (Optional)
          </h2>
          <div className=" vstack gap-4">
            <TutorProfileCertificatesView
              certificates={props.user.certificates}
              isEditing={true}
              isListEditable={false}
              allowFormCancel={false}
            />
          </div>
        </SassyCardBody>
      </SassyCard>

      <SassyCard id={ETutorOnboarding.SUBJECTS} className="mb-2">
        <SassyCardBody className="g-0 align-items-start">
          <h2 className="onboarding-tabs-header" data-tourstep="subjectsForm">
            6. Subjects
          </h2>
          <AddSubjects />
        </SassyCardBody>
      </SassyCard>

      <div className="mt-4 mb-4 general-btn">
        <div
          className="d-inline-block my-2"
          role={!canContinue ? "tooltip" : "button"}
          aria-label="Please fill all of the required fields to continue"
          data-microtip-position="top-right"
          data-microtip-size="medium"
        >
          <Button
            type="button"
            data-testid="nextStepButton"
            data-tourstep="continueButton"
            className="text-dark continue-button"
            onClick={() => finalSubmit()}
            disabled={!canContinue}
          >
            <span style={{color: "#ffffff"}}>Continue</span>
          </Button>
        </div>
      </div>
    </div>
  );
}

const mapToStateProps = (state) => ({
  loading: state.tutorDashboard.userInfo.loading,
  user: state.tutorDashboard.userInfo.user,
  storedQualification: state.tutorDashboard.userInfo.user?.qualifications,
  storedExperience: state.tutorDashboard.userInfo.user?.experiences,
  storedSkills: state.tutorDashboard.userInfo.user?.skills,
  storedCertificate: state.tutorDashboard.userInfo.user?.certificates,
  storedSubjects: state.tutorDashboard.userInfo.user?.subjects,
});

export default connect(mapToStateProps, {})(ProfileSetup);
