import { Star } from "@smastrom/react-rating";

export const integers = {
  DONE: 1500,
  SLIDESHOW: 5000,
  REDIRECTION: 3000,
};

export const ratingStarStyles = {
  itemStrokeWidth: 0.7,
  itemShapes: Star,
  activeFillColor: "#F3D11A",
  activeStrokeColor: "#F3D11A",
  inactiveFillColor: "#f3d21a00",
  inactiveStrokeColor: "#05386B",
};

/**
 * Styled of a container, such as a card, a box, a wrapper, etc.
 */
export const boxStyles = {
  className: "p-3",
  yGap: "gap-3",
  xGap: "gap-2",
  mt: "mt-3",
  g: "gx-2 gy-3",
  p: "p-3",
  px: "px-3",
  py: "py-3",
  bg: {
    light: "bg-white",
    gray: "bg-gray-100",
  },
};

/**
 * Styles of a wrapper, such as the content wrapper, the page wrapper, etc.
 */
export const wrapperStyles = {
  ...boxStyles,
  p: "p-3 p-md-4",
  yGap: "gap-3 gap-md-4",
  xGap: "gap-3 gap-md-4",
};

/**
 * Styles of a grid, such as a row, a column, etc.
 */
export const gridStyles = {
  gutters: {
    sm: {
      base: "g-2",
      gy: "gy-2",
      gx: "gx-2",
    },
  },
  g: "g-3 g-md-4",
};

export const ratios = {
  coverPhoto: "4 / 1",
  profilePhoto: "1 / 1",
};

const config = {
  integers,
};

export default config;
