import React from "react";

// Hooks.
import routes from "router/routes";

// Config.
import { boxStyles, wrapperStyles } from "constants/config";

// Components.
import { SassyCard } from "components/cards";
import { useLocation } from "react-router-dom";
import {
  IconUser,
  IconBook,
  IconHome,
  IconLogout,
  IconMenu2,
  IconBriefcase,
  IconCertificate,
  IconArrowRight,
} from "@tabler/icons";

// react-bootstrap Components.
import { Button, Col, ListGroup } from "react-bootstrap";

// Static.
import logo from "assets/images/logo/logo.svg";
import { isTabletWidth } from "utils";
import { externalRoutes } from "router/router-routes-external";
import { useAuth } from "hooks/auth";

export const navItems = [
  {
    title: "Setup Profile",
    description:
      "Tutors with 100% complete profiles tend to get more students.",
    Icon: IconUser,
    href: routes.tutor.onboarding.url,
  },
  {
    title: "Terms & Conditions",
    Icon: IconCertificate,
    href: routes.tutor.onboardingTerms.url,
  },
  {
    title: "Availability",
    description:
      "Your availability lets students know when you are free to teach.",
    Icon: IconBook,
    href: routes.tutor.onboardingAvailability.url,
  },
  {
    title: "Service",
    description:
      "Services are the 1-1 Tutoring sessions you offer to students.",
    Icon: IconBriefcase,
    href: routes.tutor.onboardingService.url,
  },
];

function Brand() {
  return (
    <div style={{ width: "72px" }} className="py-md-3">
      <img src={logo} alt="" className="w-100 h-100 object-contain" />
    </div>
  );
}

function NavHeader() {
  const { pathname } = useLocation();

  return (
    <ListGroup
      as="ul"
      variant="flush"
      className="flex-column justify-content-end w-100"
    >
      {navItems.map(({ title, description, Icon, href }, index) => (
        <ListGroup.Item
          key={index}
          as="div"
          // currently we want to stop navigation from these sidebar items so we are rendering it just as a div.
          // action
          // as={Link}
          // to={href}
          // disabled={pathname === routes.tutor.onboardingTerms.url}
          variant={pathname === href ? "success" : undefined}
          className={`d-flex align-items-center rounded bg-opacity-25 text-secondary position-relative ${boxStyles.xGap}`}
        >
          <Icon
            size={24}
            strokeWidth={1.5}
            color="currentColor"
            className="flex-shrink-0"
          />

          <div>
            <div className="mb-0 fw-semi-bold">{title}</div>
            {description && <small className="text-muted">{description}</small>}
          </div>
          {/* Click indicator */}
          <IconArrowRight
            size={16}
            strokeWidth={1.5}
            className={`position-absolute top-0 end-0 translate-end-y mt-2 me-2 ${
              pathname === href ? "text-secondary" : "text-muted"
            }`}
          />
        </ListGroup.Item>
      ))}
    </ListGroup>
  );
}

function NavFooter() {
  const { logout } = useAuth();

  return (
    <ListGroup
      as="ul"
      variant="flush"
      className="flex-column justify-content-end w-100"
    >
      <ListGroup.Item
        action
        as={"a"}
        href={externalRoutes.website.home}
        className={`d-flex align-items-center rounded bg-opacity-25 text-secondary position-relative ${boxStyles.xGap}`}
      >
        <IconHome
          size={24}
          strokeWidth={1.5}
          color="currentColor"
          className="flex-shrink-0"
        />
        <div>
          <div className="mb-0 fw-semi-bold">Home</div>
        </div>
        {/* Click indicator */}
        <IconArrowRight
          size={16}
          strokeWidth={1.5}
          className="position-absolute top-0 end-0 translate-end-y mt-2 me-2 text-muted"
        />
      </ListGroup.Item>
      <ListGroup.Item
        action
        as={"button"}
        onClick={logout}
        className={`d-flex align-items-center rounded bg-opacity-25 text-secondary position-relative ${boxStyles.xGap}`}
      >
        <IconLogout
          size={24}
          strokeWidth={1.5}
          color="currentColor"
          className="flex-shrink-0"
        />
        <div>
          <div className="mb-0 fw-semi-bold">Logout</div>
        </div>
        {/* Click indicator */}
        <IconArrowRight
          size={16}
          strokeWidth={1.5}
          className="position-absolute top-0 end-0 translate-end-y mt-2 me-2 text-muted"
        />
      </ListGroup.Item>
    </ListGroup>
  );
}

function TabletHeader() {
  const [isCollapsed, setIsCollapsed] = React.useState(true);

  return (
    <>
      <div className="d-flex justify-content-between align-items-center w-100">
        <Brand />
        <Button
          variant="outline-secondary"
          aria-controls="onboarding-nav"
          onClick={() => setIsCollapsed(!isCollapsed)}
        >
          <IconMenu2 size={16} />
        </Button>
      </div>
      {!isCollapsed && (
        <div id="onboarding-nav">
          <NavHeader />
          <hr className="divider" />
          <NavFooter />
        </div>
      )}
    </>
  );
}

export default function OnboardingTutorLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <main
      className={`min-vh-100 d-flex flex-column flex-md-row overflow-hidden ${wrapperStyles.xGap}`}
    >
      <Col as="aside" xs={12} md={4} xl={3} className="vh-md-100">
        <SassyCard className="h-100">
          <SassyCard.Body as="nav" className="flex-grow-0 h-100">
            {isTabletWidth() ? (
              <TabletHeader />
            ) : (
              <div className="d-flex flex-column justify-content-between align-items-center w-100 h-100">
                <Brand />
                <div className="d-flex flex-column justify-content-between h-100">
                  <NavHeader />
                  <NavFooter />
                </div>
              </div>
            )}
          </SassyCard.Body>
        </SassyCard>
      </Col>
      <div
        style={{ maxHeight: "100vh" }}
        className={`d-flex flex-column flex-grow-1 overflow-auto overflow-handler ${wrapperStyles.yGap}`}
      >
        {children}
      </div>
    </main>
  );
}
