import backimage from "assets/images/siderUpdateImage-cover.png";
import companyLogo from "assets/images/logo/logo-desaturated.png";
import {Container, Col} from "reactstrap";
import {Link} from "react-router-dom";

function OnboardingSidebar() {
  return (
    <div
      className="top-0 py-3 py-sm-0 start-0 bottom-0 col-12 col-sm-12 col-md-6 left-banner"
      style={{
        backgroundImage: `url(${backimage})`,
      }}
    >
      <Container className="h-100 px-0 px-xl-5 p-0">
        <div className="position-relative zindex-fixed d-flex flex-column justify-content-between h-100 p-4 p-sm-0 p-xl-5 p-md-3 me-lg-5 py-sm-4 overlay-top">
          <Link className="pt-md-4" to="/">
            <div className="logo-container">
              <img className="" src={companyLogo} alt="signup"></img>
            </div>
          </Link>
          <Col
            md="10"
            sm="10"
            xs="10"
            className="text-light py-5 py-md-0 pb-md-3 pb-lg-3 pt-sm-5 mt-sm-5"
          >
            <p
              style={{
                fontSize: "36px",
                lineHeight: "52px",
                fontWeight: 600,
              }}
            >
              You are just a few clicks away from creating your profile!{" "}
            </p>
          </Col>
        </div>
        <div className="overlay-sidebar"></div>
      </Container>
    </div>
  );
}

export default OnboardingSidebar;
