import React from "react";
import {Loader} from "components";

const View = React.lazy(() => import("./tutoring-tutor-sessions"));

/**
 * @author Abdullah-Sajjad026
 */
function TutoringTutorSessions(props) {
  return (
    <React.Suspense fallback={<Loader />}>
      <View {...props} />
    </React.Suspense>
  );
}

export default TutoringTutorSessions;
