import React from "react";
import LearnerSubjectsTutorsView from "./learner-subjects-tutors-view";
import LearnerSubjectsTutorsViewModel from "./learner-subjects-tutors-view-model";

function LearnerSubjectsTutors() {
  return (
    <LearnerSubjectsTutorsViewModel>
      {(eligibleSubjectsTutors) => (
        <LearnerSubjectsTutorsView {...{eligibleSubjectsTutors}} />
      )}
    </LearnerSubjectsTutorsViewModel>
  );
}

export default LearnerSubjectsTutors;
