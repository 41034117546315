const routes = {
  tutor: {
    onboarding: {
      name: "Onboarding",
      path: "onboarding/tutor",
      url: "/onboarding/tutor",
    },
    onboardingBasics: {
      index: true,
      name: "Basic Information",
    },
    onboardingAvailability: {
      name: "Availability",
      path: "availability",
      url: "/onboarding/tutor/availability",
    },
    onboardingService: {
      name: "Create A Service",
      path: "service",
      url: "/onboarding/tutor/service",
    },
    onboardingTerms: {
      name: "Terms & Conditions",
      path: "terms",
      url: "/onboarding/tutor/terms",
    },
    profile: {
      name: "Profile",
      path: "profile",
      url: "/profile",
    },
    profileBasics: {
      index: true,
      name: "Basic Information",
    },
    editProfile: {
      name: "Edit Profile",
      path: "profile/edit",
      url: "/profile/edit",
    },
    qualifications: {
      name: "Qualifications",
      path: "qualifications",
      url: "/profile/qualifications",
    },
    editQualifications: {
      name: "Edit Qualifications",
      path: "qualifications/edit",
      url: "/profile/qualifications/edit",
    },
    experiences: {
      name: "Experiences",
      path: "experiences",
      url: "/profile/experiences",
    },
    editExperiences: {
      name: "Edit Experiences",
      path: "experiences/edit",
      url: "/profile/experiences/edit",
    },
    skills: {
      name: "Skills",
      path: "skills",
      url: "/profile/skills",
    },
    certificates: {
      name: "Certificates",
      path: "certificates",
      url: "/profile/certificates",
    },
    editCertificates: {
      name: "Edit Certificates",
      path: "certificates/edit",
      url: "/profile/certificates/edit",
    },
    subjects: {
      name: "Subjects",
      path: "subjects",
      url: "/profile/subjects",
    },
    manageProfile: {
      name: "Manage Profile",
      path: "manage",
      url: "/profile/manage",
    },
    services: {
      name: "Services",
      path: "services",
      url: "/1-1-tutoring/services",
    },
    editServices: {
      name: "Edit a Service",
      path: "services/edit",
      url: "/1-1-tutoring/services/edit",
    },
    createServices: {
      name: "Create a Service",
      path: "services/create",
      url: "/1-1-tutoring/services/create",
    },
    materials: {
      name: "Learning Materials",
      path: "materials",
      url: "/materials",
    },
    createMaterials: {
      name: "Create a Learning Material",
      path: "materials/create",
      url: "/materials/create",
    },
    editMaterials: {
      name: "Edit a Learning Material",
      path: "materials/edit",
      url: "/materials/edit",
    },
    chooseMaterialType: {
      name: "Choose Learning Material Type",
      path: "materials/choose",
      url: "/materials/choose",
    },
    publishedMaterials: {
      name: "Published Learning Materials",
      path: "materials/published",
      url: "/materials/published",
    },
    draftMaterials: {
      name: "Draft Learning Materials",
      path: "materials/draft",
      url: "/materials/draft",
    },
    uploadMaterials: {
      name: "Upload Learning Materials",
      path: "materials/upload/:contentId",
      url: "/materials/upload",
    },
    setMaterialPrice: {
      name: "Set Learning Material Price",
      path: "materials/pricing/:contentId",
      url: "/materials/pricing",
    },
  },
  learner: {
    credits: {
      name: "Credits",
      path: "1-1-tutoring/credits",
      url: "/1-1-tutoring/credits",
    },
    creditsSearch: {
      name: "Search for a Tutor",
      path: "1-1-tutoring/credits/tutor/:tutor_id",
      url: "/1-1-tutoring/credits/tutor",
    },
  },
};

export default routes;
export const tutorRoutes = routes.tutor;
export const learnerRoutes = routes.learner;
