import React from "react";
import { Alert } from "reactstrap";
import IToolTip from "components/itooltip";

// Static.
import styles from "./transaction-card.module.scss";
import { getAbbrPriceFrom } from "utils";

/**
 * @typedef {Object} TransactionCardProps
 *
 * @property {keyof JSX.IntrinsicElements} tag The HTML tag, defaults to div.
 * @property {string} title The top left text on the card.
 * @property {number} amount The amount of the transaction.
 * @property {string | undefined} caption The caption to assist the amount.
 * @property {number | undefined} secondaryAmount Some secondary amount to be displayed.
 * @property {string | undefined} secondaryCaption Caption for the secondary amount.
 * @property {BootstrapColors} color Defaults to primary.
 */

/**
 * A generic display-card for transactions.
 * @param {TransactionCardProps & import("reactstrap").AlertProps} object
 * @returns {JSX.Element}
 *
 * @author kashan-ahmad
 * @version 0.0.1
 */
function TransactionCard({
  tag = "div",
  title,
  amount,
  caption,
  tooltipContent,
  secondaryTooltipContent,
  secondaryAmount,
  secondaryCaption,
  color = "primary",
  className = "",
  ...props
}) {
  return (
    <Alert
      {...props}
      {...{ tag, color }}
      className={`vstack justify-content-between text-secondary p-2 pb-1 px-3 rounded-3 mb-0 ${className} ${
        {
          primary: styles.primary,
          success: styles.success,
          danger: styles.danger,
        }[color] || styles.alert
      }`}
    >
      <div className="fw-bold mb-2">{title}</div>
      <div className="hstack gap-3 flex-wrap">
        {secondaryAmount !== undefined && (
          <div className="vstack mb-1 pe-3 text-secondary border-end border-gray-400 w-auto flex-grow-0">
            {secondaryCaption && (
              <div>
                {secondaryCaption}
                {secondaryTooltipContent && (
                  <IToolTip content={secondaryTooltipContent} />
                )}
              </div>
            )}
            <div className="fw-bold fs-4">
              {getAbbrPriceFrom(secondaryAmount)}
            </div>
          </div>
        )}
        <div className="vstack text-secondary">
          <div>
            {caption} {tooltipContent && <IToolTip content={tooltipContent} />}
          </div>
          <div className="fw-bold fs-2">{getAbbrPriceFrom(amount)}</div>
        </div>
      </div>
    </Alert>
  );
}

export default TransactionCard;
