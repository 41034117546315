import React, { useEffect } from "react";
import { getEnvInt } from "utils/utils-env";
import { boxStyles } from "constants/config";
import { useRemoteProfile } from "hooks/profile";
import { Col, Form, Row } from "react-bootstrap";
import FormErrorMessage from "components/error-message-form";

// Types.
import type { FormikProps } from "formik";
import type { BioFormState } from "./bio-form-types";

export default function BioFormView({
  values,
  errors,
  touched,
  handleBlur,
  handleChange,
  setFieldValue,
}: FormikProps<BioFormState>) {
  const { profile } = useRemoteProfile();

  const isUsingTemplate = !profile.bio && !touched.bio;

  // Effect: Set the form value to the bio template if the user hasn't yet modified the field.
  useEffect(() => {
    if (isUsingTemplate) {
      setFieldValue("bio", profile.bio_template);
    }
  }, [isUsingTemplate, profile.bio_template, setFieldValue]);

  return (
    <Row className={boxStyles.g}>
      <Col xs={12}>
        <Form.Group>
          <Form.Control
            id="bio"
            type="text"
            as="textarea"
            value={values.bio}
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder={"Enter Bio"}
          />
          <div className="text-end">
            <small>
              {values.bio ? values.bio.length : 0}/
              {getEnvInt("MAX_BIO_CHARACTERS")}
            </small>
          </div>
          {isUsingTemplate && (
            <small>
              <i>
                <b>Note:</b> This bio is a template and must be saved to be
                considered permanent.
              </i>
            </small>
          )}
          {touched.bio && errors.bio && (
            <Form.Text className="text-muted">
              <FormErrorMessage message={errors.bio as string} />
            </Form.Text>
          )}
        </Form.Group>
      </Col>
    </Row>
  );
}
