import {Loader, SassyAlert} from "components";
import {useStaticDataV3} from "hooks/static-data";
import {TaskItem} from "modules";
import React from "react";
import {ListGroup} from "reactstrap";
import {getTutorProfileUrl, parseMessage} from "utils";

const LearnerMaterialTasks = ({lmTasks}) => {
  const {data, state} = lmTasks;

  const {
    subjects: {state: subjectsState},
  } = useStaticDataV3(["subjects"]);

  return [state, subjectsState].includes("erred") ? (
    <SassyAlert
      title="There was a problem loading your learning materials"
      color="danger"
    />
  ) : [state, subjectsState].includes("loading") ? (
    <Loader />
  ) : (
    <ListGroup className=" gap-4 items-bottom-borders">
      {data.length === 0 ? (
        <SassyAlert
          title={parseMessage("No pending learning materials")}
          color="success"
        />
      ) : (
        data.map((lm, index) => (
          <TaskItem
            type="learning-material"
            key={index}
            userName={lm.tutor.name}
            userLink={getTutorProfileUrl(lm.tutor.name, lm.tutor.id)}
            taskProductName={lm.title}
            remainingProgress={100 - lm.completed_percentage}
            usedProgress={lm.completed_percentage}
            ctaLink={`/learning-materials/details/${lm.id}/${lm.slug}`}
          />
        ))
      )}
    </ListGroup>
  );
};

export default LearnerMaterialTasks;
