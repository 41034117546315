import {
  FETCH_LANGUAGES,
  FETCH_LANGUAGES_ERROR,
  UPDATE_LANGUAGES,
  FETCH_GOALS,
  UPDATE_GOALS,
  FETCH_GOALS_ERROR,
  FETCH_SUBJECTS,
  FETCH_SUBJECTS_ERROR,
  FETCH_TIMESLOTS,
  UPDATE_TIMESLOTS,
  FETCH_TIMESLOTS_ERROR,
  // STORE_TIMESLOTS,
  FETCH_LEARNING_STYLES,
  FETCH_LEARNING_STYLES_ERROR,
  UPDATE_LEARNING_STYLES,
  STORE_USERINFO,
  USERINFO_ERROR,
  STORE_ACCOUNT_INFO,
  ACCOUNT_INFO_ERROR,
  STORE_TOPICS_INFO,
  TOPICS_INFO_ERROR,
  FETCH_CURRICULUM,
  FETCH_GRADES,
  UPDATE_GRADES,
  FETCH_GRADES_ERROR,
  FETCH_CURRICULUM_ERROR,
  UPDATE_CURRICULUM,
  UPDATE_INTERESTS,
  FETCH_INTERESTS,
  FETCH_INTERESTS_ERROR,
  STORE_LANGUAGES,
  GET_PROFILE_DATA,
  FETCH_MATERIAL,
  FETCH_MATERIAL_ERROR,
  // GET_USER_DATA,
  // PROFILE_DATA_ERROR,
  FETCH_REFERALS,
  FETCH_REFERALS_ERROR,
  PROFILE_DATA_ERROR,
  FETCH_POPULAR_TUTORS,
  FETCH_POPULAR_TUTORS_ERROR,
} from "./actionTypes";

export const initialState = {
  userInfo: {
    loading: true,
    user: null,
    error: null,
    isData: false,
  },
  language: {
    loading: true,
    language: "english",
    languages: [],
    selectedLanguages: [],
    storedLanguages: [],
    error: null,
    isData: false,
  },

  goals: {
    loading: true,
    goal: null,
    goals: [],
    selectedgoals: [],
    storedGoals: [],
    isData: false,
  },
  timeSlot: {
    loading: true,
    timeSlot: null,
    timeSlotsRow: [],
    selectedTimeSlots: [],
    storedTimeSlots: [],
    isData: false,
  },

  learningStyle: {
    loading: true,
    learningStyle: null,
    learningStyles: [],
    selectedlearningStyles: [],
    storedLearningStyles: [],
    isData: false,
  },
  accountInfo: {
    loading: true,
    account: null,
    error: null,
    isData: false,
  },
  paymentInfo: {
    loading: true,
    payment: null,
    error: null,
    isData: false,
  },
  curriculum: {
    loading: true,
    curriculum: null,
    curriculums: [],
    selectedCurriculums: [],
    isData: false,
  },
  grade: {
    loading: true,
    grade: null,
    grades: [],
    selectedGrades: [],
    isData: false,
  },
  subject: {
    loading: true,
    subject: null,
    subjects: [],
    selectedSubjects: [],
    isData: false,
  },
  interest: {
    loading: true,
    interest: null,
    interests: [],
    selectedInterests: [],
    isData: false,
  },
  topics: {
    loading: true,
    topics: null,
    error: null,
    isData: false,
  },
  referals: {
    loading: true,
    referals: [],
    error: null,
    isData: false,
  },
  learningMaterial: [],
  popularTutors: {
    loading: true,
    popularTutors: [],
    error: null,
    isData: false,
  },
};

const learnerDashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case STORE_USERINFO:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
        },
      };
    case GET_PROFILE_DATA:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          user: action.payload.data,
          loading: false,
          isData: true,
        },
        language: {
          ...state.language,
          selectedLanguages: action.payload.data.language,
          storedLanguages: action.payload.data.language,
        },
        goals: {
          ...state.goals,
          storedGoals: action.payload.data.goal,
        },
        learningStyle: {
          ...state.learningStyle,
          storedLearningStyles: action.payload.data.learningstyle,
        },
        timeSlot: {
          ...state.timeSlot,
          selectedTimeSlots: action.payload.data.timeslot,
        },
      };
    case PROFILE_DATA_ERROR:
      return {
        ...state,
        userInfo: {
          loading: false,
          user: null,
          error: action.payload,
        },
      };
    case USERINFO_ERROR:
      return {
        ...state,
        userInfo: {
          loading: false,
          user: null,
          error: action.payload,
        },
      };

    case FETCH_LANGUAGES:
      return {
        ...state,
        language: {
          ...state.language,
          loading: false,
          languages: action.payload.data,
        },
      };
    case UPDATE_LANGUAGES:
      return {
        ...state,
        language: {
          ...state.language,
          selectedLanguages: action.payload,
        },
      };
    case STORE_LANGUAGES:
      return {
        ...state,
        language: {
          ...state.language,
          isData: true,
        },
      };

    // case FETCH_LANGUAGES_ERROR:
    //   return {
    //     ...state,
    //     language: {
    //       ...state.language,
    //       loading: false,
    //       error: action.payload,
    //     },
    //   }
    case FETCH_GOALS:
      return {
        ...state,
        goals: {
          ...state.goals,
          loading: false,
          goals: action.payload.data,
        },
      };
    case FETCH_GOALS_ERROR:
      return {
        ...state,
        goals: {
          ...state.goals,
          loading: false,
          error: action.payload,
        },
      };
    case FETCH_MATERIAL:
      return {
        ...state,
        learningMaterial: action.payload.data,
      };
    // case FETCH_MATERIAL_ERROR:
    //   return {
    //     ...state,
    //     learningMaterial: action.payload
    //   }
    case UPDATE_GOALS:
      return {
        ...state,
        goals: {
          ...state.goals,
          selectedGoals: action.payload,
        },
      };
    case FETCH_SUBJECTS:
      return {
        ...state,
        subject: {
          ...state.subject,
          loading: false,
          subjects: action.payload.data,
        },
      };
    case FETCH_SUBJECTS_ERROR:
      return {
        ...state,
        subject: {
          ...state.subject,
          loading: false,
          error: action.payload,
        },
      };
    case FETCH_TIMESLOTS:
      return {
        ...state,
        timeSlot: {
          ...state.timeSlot,
          loading: false,
          timeSlotsRow: action.payload.data,
        },
      };
    case FETCH_TIMESLOTS_ERROR:
      return {
        ...state,
        timeSlot: {
          ...state.timeSlot,
          loading: false,
          error: action.payload,
        },
      };
    case UPDATE_TIMESLOTS:
      return {
        ...state,
        timeSlot: {
          ...state.timeSlot,
          selectedTimeSlots: action.payload,
        },
      };

    case FETCH_LEARNING_STYLES:
      return {
        ...state,
        learningStyle: {
          ...state.learningStyle,
          loading: false,
          learningStyles: action.payload.data,
        },
      };
    case FETCH_LEARNING_STYLES_ERROR:
      return {
        ...state,
        learningStyle: {
          ...state.learningStyle,
          loading: false,
          error: action.payload,
        },
      };
    case UPDATE_LEARNING_STYLES:
      return {
        ...state,
        learningStyle: {
          ...state.learningStyle,
          selectedlearningStyles: action.payload,
        },
      };

    case STORE_ACCOUNT_INFO:
      return {
        ...state,
        accountInfo: {
          ...state.accountInfo,
          loading: false,
          account: action.payload,
          error: null,
        },
      };
    case ACCOUNT_INFO_ERROR:
      return {
        ...state,
        accountInfo: {
          loading: false,
          account: null,
          error: action.payload,
        },
      };
    case FETCH_CURRICULUM:
      return {
        ...state,
        curriculum: {
          ...state.curriculum,
          loading: false,
          curriculums: action.payload.data,
        },
      };
    case UPDATE_CURRICULUM:
      return {
        ...state,
        curriculum: {
          ...state.curriculum,
          selectedCurriculums: action.payload,
        },
      };
    case FETCH_CURRICULUM_ERROR:
      return {
        ...state,
        curriculum: {
          loading: false,
          error: action.payload,
        },
      };

    case FETCH_GRADES:
      return {
        ...state,
        grade: {
          ...state.grade,
          loading: false,
          grades: action.payload.data,
        },
      };
    case UPDATE_GRADES:
      return {
        ...state,
        grade: {
          ...state.grade,
          selectedGrades: action.payload,
        },
      };
    case FETCH_GRADES_ERROR:
      return {
        ...state,
        grade: {
          ...state.grade,
          loading: false,
          error: action.payload,
        },
      };
    case FETCH_INTERESTS:
      return {
        ...state,
        interest: {
          ...state.interest,
          loading: false,
          interests: action.payload.data,
        },
      };
    case UPDATE_INTERESTS:
      return {
        ...state,
        interest: {
          ...state.interest,
          selectedInterests: action.payload,
        },
      };
    case FETCH_INTERESTS_ERROR:
      return {
        ...state,
        interest: {
          ...state.interest,
          loading: false,
          error: action.payload,
        },
      };

    case STORE_TOPICS_INFO:
      return {
        ...state,
        topics: {
          ...state.topics,
          loading: false,
          topics: action.payload,
          error: null,
        },
      };
    case TOPICS_INFO_ERROR:
      return {
        ...state,
        topics: {
          loading: false,
          topics: null,
          error: action.payload,
        },
      };
    case FETCH_REFERALS:
      return {
        ...state,
        referals: {
          ...state.referals,
          loading: false,
          referals: action.payload.data,
          error: null,
        },
      };
    case FETCH_REFERALS_ERROR:
      return {
        ...state,
        referals: {
          ...state.referals,
          loading: false,
          error: action.payload,
        },
      };
    case FETCH_POPULAR_TUTORS:
      return {
        ...state,
        popularTutors: {
          loading: false,
          popularTutors: action.payload,
          isData: true,
        },
      };
    case FETCH_POPULAR_TUTORS_ERROR:
      return {
        ...state,
        popularTutors: {
          loading: false,
          popularTutors: [],
          isData: false,
          error: action.payload,
        },
      };
    // General profile update action.
    case "SET_LEARNER_PROFILE":
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default learnerDashboardReducer;
