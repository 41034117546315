import { AnyAction } from "redux";
import { useApiEffect } from "hooks";
import { useDispatch, useSelector } from "react-redux";
import { fetchTutorLearningMaterial } from "store/store-content-builder";
import { DEFAULT_LEARNING_MATERIALS_PER_PAGE } from "constants/integers";

type Props = {
  to: string;
  from: string;
  currentPage: number;
  itemsPerPage: number;
  status: "drafted" | "published";
  deps: Array<any>;
};

/**
 * Hooks into the store of learning materials for a tutor/the content builder.
 */
export default function useContentBuilderMaterials(
  {
    to,
    from,
    currentPage = 1,
    status = "published",
    itemsPerPage = DEFAULT_LEARNING_MATERIALS_PER_PAGE,
    deps = [],
  }: Props = {} as Props
) {
  const dispatch = useDispatch();

  const { data, state } = useSelector(
    (state: any) => state.contentBuilder.learningMaterial
  );

  const {
    list: learningMaterials,
    total_drafted: numOfDraftedMaterials,
    total_published: numOfPublishedMaterials,
  } = data;

  useApiEffect(() => {
    const proposal: Record<any, any> = {
      status,
      end_date: to,
      start_date: from,
      page: currentPage,
      limit: itemsPerPage,
    };

    dispatch(fetchTutorLearningMaterial(proposal) as unknown as AnyAction);
  }, deps);

  return {
    state,
    learningMaterials,
    numOfDraftedMaterials,
    numOfPublishedMaterials,
  };
}
