import useApiEffect from "./use-api-effect";
import { staticDataV2QueryType } from "constants/types";
import {
  fetchStaticDataV2,
  useStaticDataV2Selector,
  useStaticDataV2Dispatch,
} from "store/store-static-v2";

/**
 * Request one or more of the static data entities.
 * @param {StaticDataV2Action['payload']['key'][]} keys
 * @returns {StaticDataV2State}
 *
 * @author kashan-ahmad
 * @version 1.0.0
 */
function useStaticDataV2(keys) {
  const dispatch = useStaticDataV2Dispatch();
  const state = useStaticDataV2Selector((state) => state);

  useApiEffect(() => {
    keys.forEach((key) => {
      // A query type contains the metadata required to perform a request.
      const queryType = staticDataV2QueryType[key];

      // If this data is missing, then it's an invalid key.
      if (!queryType) throw new Error(`😡 Invalid static data key: ${key}!`);

      // Sadly, state has to be updated at each request's resolution, not to mention that previously it happened in duplicated functions and duplicated reducers.
      dispatch(
        fetchStaticDataV2(
          queryType.url,
          key,
          queryType.method,
          queryType.actionType
        )
      );
    });
  }, []);

  return state;
}

export default useStaticDataV2;
