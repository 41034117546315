import SwitchOn from "./switch-on";
import SwitchOff from "./switch-off";
import ReactSwitch from "react-switch";

/**
 * A general purpose switch component.
 *
 * @param {Object} object The properties
 * passed to the component.
 * @param {string | undefined} object.id The id of the switch.
 * @param {boolean} object.checked The
 * state of the switch.
 * @param {(checked: boolean, event: MouseEvent, id: any) => void } object.onChange The change handler.
 * @returns {JSX.Element}
 *
 * @version 0.0.1
 * @author kashan-ahmad
 *
 * @changelog
 * - 0.0.1: Initial version.
 */
const Switch = (props) => (
  <ReactSwitch
    {...props}
    uncheckedIcon={<SwitchOff />}
    checkedIcon={<SwitchOn />}
    offColor="#8CA6C0"
    onColor="#5CDB95"
  />
);

export default Switch;
