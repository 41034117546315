/* eslint-disable eqeqeq */
import React from "react";

// Hooks.
import useToast from "hooks/use-toast";
import { useNavigate } from "react-router-dom";
import { useRemoteProfile } from "hooks/profile";
import { useOnboardingValidator } from "../hooks";
import { navItems } from "../layout/onboarding-tutor-layout";

// Components.
import {
  Header,
  Container,
  ErrorsList,
  Footer,
  CancelButton,
} from "../components";
import { FormErrorMessage } from "components";
import TermsHeadless from "views/onboarding/onboarding-tutor/TermsHeadless";
import { Button } from "react-bootstrap";

export default function OnboardingTutorTerms() {
  const toast = useToast();
  const navigate = useNavigate();
  const { getBasicErrors, isStepAlreadyPassed } = useOnboardingValidator();
  const { profile, setProfile, updateProfile } = useRemoteProfile();

  const agrees =
    profile.agrees_to_commission &&
    profile.agrees_to_data_sharing &&
    profile.agrees_to_privacy_policy &&
    profile.agrees_to_stay_on_platform;

  const setAgrees = (key: string, value: boolean) =>
    setProfile({
      profile: {
        [key]: value ? 1 : 0,
      },
    });

  const [error, setError] = React.useState("");

  function submitListener() {
    setError("");

    // Validate terms and conditions.
    if (!agrees) {
      setError("Please agree to all terms and conditions.");
      return;
    }

    // Validate if all steps of onboarding are completed.
    const errors = getBasicErrors(profile);

    if (errors.length) {
      toast.render(<ErrorsList errors={errors} />);
      return;
    }

    // inform the backend that the user has completed the terms and conditions step
    isStepAlreadyPassed("terms") ||
      updateProfile({
        profile: {
          onboarding_status: "terms",
        },
        onSuccess: () => {},
        onFailure: ({ message }) => {
          // toast.error(message);
        },
      });

    // Navigate to next step.
    navigate(navItems[2].href);
  }

  return (
    <>
      <Header title="Terms & Conditions" />
      <Container className="flex-grow-1">
        <TermsHeadless
          agree1={profile.agrees_to_privacy_policy == 1}
          agree2={profile.agrees_to_stay_on_platform == 1}
          agree3={profile.agrees_to_data_sharing == 1}
          agree4={profile.agrees_to_commission == 1}
          setAgree1={(value: boolean) =>
            setAgrees("agrees_to_privacy_policy", value)
          }
          setAgree2={(value: boolean) =>
            setAgrees("agrees_to_stay_on_platform", value)
          }
          setAgree3={(value: boolean) =>
            setAgrees("agrees_to_data_sharing", value)
          }
          setAgree4={(value: boolean) =>
            setAgrees("agrees_to_commission", value)
          }
        />
        {error && <FormErrorMessage message={error} />}
        <div className="text-end">
          <Button variant="secondary" onClick={submitListener}>
            Save & Continue
          </Button>
        </div>
      </Container>
      <Footer>
        <CancelButton onClick={() => navigate(navItems[0].href)}>
          Back
        </CancelButton>
      </Footer>
    </>
  );
}
