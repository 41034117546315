import { Loader } from "components";
import React from "react";

const View = React.lazy(() => import("./content-builder-upload"));

const ContentBuilderUpload = (props) => (
  <React.Suspense fallback={<Loader />}>
    <View {...props} />
  </React.Suspense>
);

export default ContentBuilderUpload;
