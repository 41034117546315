import React from "react";
import {Nav} from "reactstrap";

// Static.
import "./tab-bar.scss";

/**
 * A generic tab bar.
 * @param {import("reactstrap").NavProps} object
 * @returns {JSX.Element}
 * @author kashan-ahmad
 * @version 0.0.1
 *
 */
const TabBar = ({
  children,
  className = "",
  tabs = true,
  orientation = "row",
  ...props
}) => (
  <Nav
    {...props}
    tabs={tabs}
    className={`nav-tabs-custom ${
      orientation === "column" ? "flex-column" : ""
    } ${className}`}
  >
    {children}
  </Nav>
);

export default TabBar;
