const INITIAL_STATE = {
  data: [],
  page_no: 1,
  number_of_pages: 1,
  isErred: false,
  isLoaded: false,
};

/**
 * @param {import("constants/types/types-notifications").NotificationState} state
 * @param {import("constants/types/types-notifications").NotificationAction} action
 * @returns {import("constants/types/types-notifications").NotificationState}}
 */
const reducer = (state = INITIAL_STATE, action) =>
  ({
    SET_NOTIFICATIONS: {
      ...state,
      ...action.payload,
      isLoaded: true,
      isErred: false,
    },
    ERRED_NOTIFICATIONS: {
      ...state,
      isLoaded: true,
      isErred: true,
    },
  }[action.type] || state);

export default reducer;
