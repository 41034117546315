import * as React from "react";

// Components.
import { ListGroup, ListGroupItem, Row, Col } from "reactstrap";

// Utils.
import { isEmpty } from "lodash";

// Static.
import { DeleteButton, EditButton } from "components";
import { Button } from "react-bootstrap";
import { boxStyles } from "constants/config";
import { IconPencil } from "@tabler/icons";
import MaxLimitHint from "components/forms/max-limit-hint";
import { getEnvVar } from "utils/utils-env";

/**
 * @typedef {Object} TutorQualificationsListProps
 *
 */

/**
 * A list displaying all the past experiences of a tutor.
 *
 * @param {TutorQualificationsListProps} props
 * @returns {JSX.Element}
 *
 * @author Abdullah-Sajjad026
 * @version 0.0.1
 */
function TutorQualificationsList({
  isEditing,
  setIsEditing,
  qualifications,
  onEditQualification,
  onRemoveQualification,
}) {
  if (!qualifications || !qualifications.length) return <></>;

  return (
    <div className="position-relative">
      <div className="d-flex justify-content-between">
        <h1 className="card-title mb-0 d-flex align-items-center">
          Your Qualifications&nbsp;
          <MaxLimitHint limit={getEnvVar("TUTOR_MAX_QUALIFICATIONS")} />
        </h1>
        {!isEditing && (
          <Button
            variant="secondary"
            className={`d-flex align-items-center ${boxStyles.xGap}`}
            onClick={() => setIsEditing(true)}
          >
            <IconPencil size={16} />
            Edit
          </Button>
        )}
      </div>
      <ListGroup>
        {qualifications
          .sort(function (start, end) {
            return start.year_of_graduation - end.year_of_graduation;
          })
          .reverse()
          .map((qualificationItem, index) => {
            const { institute, year_of_graduation, qualification } =
              qualificationItem;

            return (
              <ListGroupItem
                key={index}
                className="p-0 py-3 border-top-0 border-end-0 border-start-0 border-bottom-0 text-secondary vstack gap-1 position-relative"
              >
                <Row className="w-100 mx-0">
                  <Col xs="12" sm="9">
                    <Row
                      className="py-3 mx-2 qualify-duration"
                      key={qualificationItem.id}
                    >
                      <Col md="4">
                        <h2 className="qualify-head">
                          {" "}
                          {!isEmpty(year_of_graduation) && year_of_graduation}
                          {/* -<span>{item.to}</span> */}
                        </h2>
                      </Col>
                      <Col md="6" className="qualify-education">
                        <h2 className="qualify-course">
                          {!isEmpty(qualification) && qualification}
                        </h2>
                        <span className="qualify-college">
                          {!isEmpty(institute) && institute}
                        </span>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs="12" sm="3" className="mt-3 mt-sm-0">
                    {isEditing && (
                      <div className="hstack gap-2 z-1">
                        {onEditQualification && (
                          <EditButton
                            onClick={() =>
                              onEditQualification(qualificationItem)
                            }
                          />
                        )}
                        {onRemoveQualification && (
                          <DeleteButton
                            onClick={() =>
                              onRemoveQualification(qualificationItem.id)
                            }
                          />
                        )}
                      </div>
                    )}
                  </Col>
                </Row>
              </ListGroupItem>
            );
          })}
      </ListGroup>
    </div>
  );
}

export default TutorQualificationsList;
