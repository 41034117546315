import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { navItems } from "../layout/onboarding-tutor-layout";
import { Header, Footer, Container, CancelButton } from "../components";
import Availability from "views/tutoring/tutoring-tutor/tutoring-tutor-availability";
import { useRemoteProfile } from "hooks/profile";
import useToast from "hooks/use-toast";
import { useOnboardingValidator } from "../hooks";

export default function OnboardingTutorAvailability() {
  const toast = useToast();
  const navigate = useNavigate();
  const { updateProfile } = useRemoteProfile();
  const { isStepAlreadyPassed } = useOnboardingValidator();

  const onSubmitHandler = () => {
    // inform the backend that the user has completed the availability step
    isStepAlreadyPassed("availability") ||
      updateProfile({
        profile: {
          onboarding_status: "availability",
        },
        onSuccess: () => {},
        onFailure: ({ message }) => {
          toast.error(message);
        },
      });

    // navigate to the next step
    navigate(navItems[3].href);
  };

  return (
    <>
      <Header title="Availability" />
      <Container className="flex-grow-1">
        <Availability
          variant="onboarding"
          onSubmit={onSubmitHandler}
          yesButton={
            <Button type="submit" variant="secondary">
              Save & Continue
            </Button>
          }
        />
      </Container>
      <Footer>
        <CancelButton onClick={() => navigate(navItems[1].href)}>
          Back
        </CancelButton>
      </Footer>
    </>
  );
}
