import React from "react";
import { SassyCard } from "components/cards";

type Props = {
  title: string;
};

export default function OnboardingTutorHeader({ title }: Props) {
  return (
    <SassyCard as="header">
      <SassyCard.Body>
        <h1 className="h2 mb-0">{title}</h1>
      </SassyCard.Body>
    </SassyCard>
  );
}
