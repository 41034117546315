import React from "react";
import { Loader } from "components";
import TutorTutoringAvailabilityField from "./tutoring-tutor-availability-field";

const View = React.lazy(() => import("./tutoring-tutor-availability"));

const TutoringTutorAvailability = (props) => (
  <React.Suspense fallback={<Loader />}>
    <View {...props} />
  </React.Suspense>
);

export default TutoringTutorAvailability;
export { TutorTutoringAvailabilityField };
