export const IMAGE_FILE_TYPES = ["jpg", "jpeg", "png", "gif"];

export const VIDEO_FILE_TYPES = ["mp4", "m4a", "webm"];

export const AUDIO_FILE_TYPES = ["mp3", "wav"];

export const DOC_FILE_TYPES = ["doc", "docx", "document"];

export const SHEET_FILE_TYPES = ["xlsx", "xls", "sheet"];

export const PPT_FILE_TYPES = ["ppt", "pptx", "ms-powerpoint"];

export const PLAIN_FILE_TYPES = ["txt", "plain"];
