// @flow
import {
  CHANGE_LAYOUT,
  CHANGE_LAYOUT_WIDTH,
  CHANGE_SIDEBAR_THEME,
  CHANGE_SIDEBAR_THEME_IMAGE,
  CHANGE_SIDEBAR_TYPE,
  CHANGE_TOPBAR_THEME,
  TOGGLE_RIGHT_SIDEBAR,
  SHOW_RIGHT_SIDEBAR,
  CHANGE_PRELOADER,
  HIDE_RIGHT_SIDEBAR,
  TOGGLE_BANNER,
} from "./actionTypes";

//constants
import {
  LAYOUT_TYPES,
  LAYOUT_WIDTH_TYPES,
  TOP_BAR_THEME_TYPES,
  LEFT_BAR_THEME_IMG_TYPES,
  LEFT_BAR_TYPES,
  LEFT_BAR_THEME_TYPES,
} from "../../constants/layout";

const INIT_STATE = {
  layoutType: LAYOUT_TYPES.VERTICAL,
  layoutWidth: LAYOUT_WIDTH_TYPES.FLUID,
  leftSideBarTheme: LEFT_BAR_THEME_TYPES.DARK,
  leftSideBarThemeImage: LEFT_BAR_THEME_IMG_TYPES.NONE,
  leftSideBarType: LEFT_BAR_TYPES.COMPACT,
  topbarTheme: TOP_BAR_THEME_TYPES.LIGHT,
  isPreloader: false,
  showRightSidebar: false,
  rightSideBarType: "",
  isMobile: false,
  showSidebar: true,
  leftMenu: false,
  showBanner: true,
};

const Layout = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_LAYOUT:
      return {
        ...state,
        layoutType: action.payload,
      };
    case CHANGE_PRELOADER:
      return {
        ...state,
        isPreloader: action.payload,
      };

    case CHANGE_LAYOUT_WIDTH:
      return {
        ...state,
        layoutWidth: action.payload,
      };
    case CHANGE_SIDEBAR_THEME:
      return {
        ...state,
        leftSideBarTheme: action.payload,
      };
    case CHANGE_SIDEBAR_THEME_IMAGE:
      return {
        ...state,
        leftSideBarThemeImage: action.payload,
      };
    case CHANGE_SIDEBAR_TYPE:
      return {
        ...state,
        leftSideBarType: action.payload.sidebarType,
      };
    case CHANGE_TOPBAR_THEME:
      return {
        ...state,
        topbarTheme: action.payload,
      };
    case TOGGLE_RIGHT_SIDEBAR:
      if (!state.showRightSidebar) {
        document.body.classList.add("right-bar-enabled");
      } else {
        document.body.classList.remove("right-bar-enabled");
      }
      return {
        ...state,
        showRightSidebar: !state.showRightSidebar,
        rightSideBarType: action.payload.rightSideBarType,
      };
    case SHOW_RIGHT_SIDEBAR:
      return {
        ...state,
        showRightSidebar: true,
      };
    case HIDE_RIGHT_SIDEBAR:
      return {
        ...state,
        showRightSidebar: false,
      };
    case TOGGLE_BANNER:
      return {
        ...state,
        showBanner: false,
      };
    default:
      return state;
  }
};

export default Layout;
