/*


  Note: --
    The code of this file has been commented as per certain requirements. 
    The commented code is completely working. Please don't remove tha code

 */

import {Col, Row} from "reactstrap";
import {Breadcrumb} from "../../components";
import HelpCenterImage from "../../assets/images/support/help-center-left.png";
import WhatsappImg from "../../assets/images/support/whatsapp-icon.png";
import DropMessage from "./DropMessage";
import {VerticalLayout} from "../../layout";
// import "../dashboard/dashboard-learner/portal-dashboard.scss";
// import "./support-dashboard.scss"
import "./support-form.scss";
import {useEffect} from "react";
import {useUser} from "hooks";
import {useSelector} from "react-redux";

/**
 * @author Abdullah-Sajjad026
 */
const SupportDashboard = (props) => {
  /**
   *
   *  This is old script for the form displayed on support. It is commented here to maybe used in future sometime.
   *
   */
  // useEffect(() => {
  //   var s = document.createElement("script");
  //   s.type = "text/javascript";
  //   s.innerHTML = `

  //   (function() {
  //     try{

  //         if( document.readyState == "complete" ){
  //           onloadActions_165012();
  //         }  else {
  //             window.addEventListener('load', function (){
  //               onloadActions_165012();
  //             }, false);
  //         }

  //         function onloadActions_165012(){
  //           constructDiv_165012();
  //           showZForm_165012();
  //         }

  //         function constructDiv_165012(){
  //           var iframeDiv = document.createElement("div");
  //           iframeDiv.setAttribute('id','e5zjEH2YVIKPEzG9sSk3AAlOvkRKyGn6wQ2fOQgWxsQ_165012');
  //           iframeDiv.setAttribute('class','zf_main_id_165012');

  //           var closeFormDiv = document.createElement("div");
  //           closeFormDiv.setAttribute('id','deleteform_165012');
  //           closeFormDiv.setAttribute('class','zf_lb_closeform_165012');

  //           var containerDiv = document.createElement("div");
  //           containerDiv.setAttribute('id','containerDiv_165012');
  //           containerDiv.setAttribute('class','zf_lB_Container_165012');
  //           containerDiv.appendChild(iframeDiv);
  //           containerDiv.appendChild(closeFormDiv);

  //           var wrapperDiv = document.createElement("div");
  //           wrapperDiv.setAttribute('class','zf_lB_Wrapper_165012');
  //           wrapperDiv.appendChild(containerDiv);

  //           var dimmerDiv = document.createElement("div");
  //           dimmerDiv.setAttribute('class','zf_lB_Dimmer_165012');
  //           dimmerDiv.setAttribute('elname','popup_box');

  //           var mainDiv = document.createElement("div");
  //           mainDiv.setAttribute('id','formsLightBox_165012');
  //           mainDiv.style.display = "none";
  //           mainDiv.appendChild(wrapperDiv);
  //           mainDiv.appendChild(dimmerDiv);

  //           document.body.appendChild(mainDiv);

  //         }

  //         function showZForm_165012(){
  //           var iframe = document.getElementById("e5zjEH2YVIKPEzG9sSk3AAlOvkRKyGn6wQ2fOQgWxsQ_165012").getElementsByTagName("iframe")[0];
  //           if(iframe == undefined ||iframe.length == 0){
  //             loadZForm_165012();

  //           }
  //           document.getElementById("formsLightBox_165012").style.display = "block";
  //           document.body.style.overflow = "hidden";
  //         }

  //         function loadZForm_165012() {
  //           var iframe = document.getElementById("e5zjEH2YVIKPEzG9sSk3AAlOvkRKyGn6wQ2fOQgWxsQ_165012").getElementsByTagName("iframe")[0];
  //           if(iframe == undefined ||iframe.length == 0){
  //             var f = document.createElement("iframe");
  //             f.src = getsrcurlZForm_165012('https://forms.zohopublic.com/pangiah/form/TheTutorSupport/formperma/e5zjEH2YVIKPEzG9sSk3AAlOvkRKyGn6wQ2fOQgWxsQ?lname=lname&email=email');
  //             f.style.border="none";
  //             f.style.minHeight="500px";
  //             f.style.minWidth="100%";
  //             f.style.overflow="hidden";
  //             var d = document.getElementById("e5zjEH2YVIKPEzG9sSk3AAlOvkRKyGn6wQ2fOQgWxsQ_165012");
  //             d.appendChild(f);

  //             var deleteForm = document.getElementById("deleteform_165012");
  //             deleteForm.onclick = function deleteZForm_165012() {
  //               var divCont = document.getElementById("formsLightBox_165012");
  //               divCont.style.display="none";

  //               document.body.style.overflow = "";

  //               var iframe = document.getElementById("e5zjEH2YVIKPEzG9sSk3AAlOvkRKyGn6wQ2fOQgWxsQ_165012").getElementsByTagName("iframe")[0];
  //               iframe.remove();
  //             }

  //             window.addEventListener('message', function (){
  //               var evntData = event.data;
  //               if( evntData && evntData.constructor == String ){
  //                 var zf_ifrm_data = evntData.split("|");
  //                 if ( zf_ifrm_data.length == 2 ) {
  //                   var zf_perma = zf_ifrm_data[0];
  //                   var zf_ifrm_ht_nw = ( parseInt(zf_ifrm_data[1], 10) + 15 ) + "px";
  //                   var iframe = document.getElementById("e5zjEH2YVIKPEzG9sSk3AAlOvkRKyGn6wQ2fOQgWxsQ_165012").getElementsByTagName("iframe")[0];
  //                   if ( (iframe.src).indexOf('formperma') > 0 && (iframe.src).indexOf(zf_perma) > 0 ) {
  //                     var prevIframeHeight = iframe.style.height;
  //                     if ( prevIframeHeight != zf_ifrm_ht_nw ) {
  //                     iframe.style.minHeight = zf_ifrm_ht_nw;
  //                       var containerDiv = document.getElementById("containerDiv_165012");
  //                       containerDiv.style.height=zf_ifrm_ht_nw;
  //                     }
  //                   }
  //                 }
  //               }

  //             }, false);
  //           }
  //         }

  //         function getsrcurlZForm_165012(zf_src) {
  //           try {
  //             if ( typeof ZFAdvLead !== "undefined" && typeof zfutm_zfAdvLead !== "undefined" ) {
  //               for( var prmIdx = 0 ; prmIdx < ZFAdvLead.utmPNameArr.length ; prmIdx ++ ) {
  //                     var utmPm = ZFAdvLead.utmPNameArr[ prmIdx ];
  //                     var utmVal = zfutm_zfAdvLead.zfautm_gC_enc( ZFAdvLead.utmPNameArr[ prmIdx ] );
  //                     if ( typeof utmVal !== "undefined" ) {
  //                       if ( utmVal != "" ){
  //                         if(zf_src.indexOf('?') > 0){
  //                           zf_src = zf_src+'&'+utmPm+'='+utmVal;//No I18N
  //                         }else{
  //                           zf_src = zf_src+'?'+utmPm+'='+utmVal;//No I18N
  //                         }
  //                       }
  //                     }
  //                   }
  //             }

  //             if ( typeof ZFLead !== "undefined" && typeof zfutm_zfLead !== "undefined" ) {
  //               for( var prmIdx = 0 ; prmIdx < ZFLead.utmPNameArr.length ; prmIdx ++ ) {
  //                     var utmPm = ZFLead.utmPNameArr[ prmIdx ];
  //                     var utmVal = zfutm_zfLead.zfutm_gC_enc( ZFLead.utmPNameArr[ prmIdx ] );
  //                     if ( typeof utmVal !== "undefined" ) {
  //                       if ( utmVal != "" ){
  //                         if(zf_src.indexOf('?') > 0){
  //                           zf_src = zf_src+'&'+utmPm+'='+utmVal;//No I18N
  //                         }else{
  //                           zf_src = zf_src+'?'+utmPm+'='+utmVal;//No I18N
  //                         }
  //                       }
  //                     }
  //                   }
  //             }
  //           }catch(e){}
  //           return zf_src;
  //         }
  //         var buttonElem = document.getElementById("zf_button_165012");
  //         buttonElem.style.display = "block";
  //         buttonElem.addEventListener("click", showZForm_165012);

  //     }catch(e){}
  //   })();	`;

  //   document.body.appendChild(s);

  //   return () => {
  //     document.body.removeChild(s);
  //   };
  // }, []);

  const userType = useUser().type;

  const {learnerInfo, tutorInfo} = useSelector((state) => ({
    learnerInfo: state.learnerDashboard.userInfo.user,
    tutorInfo: state.tutorDashboard.userInfo.user,
  }));

  const userName = userType === "learner" ? learnerInfo.name : tutorInfo.name;
  const userEmail =
    userType === "learner" ? learnerInfo.email : tutorInfo.email;
  const userMobile =
    userType === "learner"
      ? learnerInfo.mobile_number
      : tutorInfo.mobile_number;

  return (
    <section className="content">
      <iframe
        title="Support"
        frameborder="0"
        style={{minHeight: "500px", width: "99%", border: "none"}}
        src={`https://forms.zohopublic.com/pangiah/form/Support/formperma/aFLROjB8gogQcRpCmtNhxIJl233EhKK1LPPpg9VE_20?phone=${userMobile}&name=${userName}&email=${userEmail}`}
      ></iframe>
    </section>
  );

  /*


  Note: --
    The code of this file has been commented as per certain requirements. 
    The commented code is completely working. Please don't remove tha code

 */

  //   <section className="content learner-main-dashboard">
  //     <div className="dashboard">
  //       <Breadcrumb
  //         title="Dashboard"
  //         activeRouteName="support"
  //         className="border"
  //         listClassName="border"
  //       />
  //     </div>
  //     <div className="learner-main-dashboard-container ">
  //       <div className="card my-3">
  //         <div className="row g-0">
  //           {/* left side */}
  //           <div className="col-md-8">
  //             <div className="card-body">
  //               <h2 className="card-title help-center-title">Help Center</h2>
  //               <p className="card-text text-muted">
  //                 Type your Question or type Keyword
  //               </p>
  //               <form className="helper-search d-none d-lg-block">
  //                 <div className="row">
  //                   <div className="col-md-6">
  //                     <div className="position-relative">
  //                       <input
  //                         type="text"
  //                         className="form-control helper-search-input py-1 px-2"
  //                         placeholder="Search..."
  //                       />
  //                       {/* <span className="bx bx-search"></span> */}
  //                     </div>
  //                   </div>
  //                   <div className="col-md-6">
  //                     <button className="btn btn-secondary">Search</button>
  //                   </div>
  //                 </div>
  //               </form>

  //               <Row>
  //                 <Col lg="6" md="8" className="my-2">
  //                   <div className="d-flex bg-light-gray align-items-center">
  //                     <div className="flex-shrink-0">
  //                       <img
  //                         src={WhatsappImg}
  //                         className="img-fluid p-2 rounded-start"
  //                         alt="Whatsapp icon"
  //                       />
  //                     </div>
  //                     <div className="flex-grow-1 ms-3 py-2">
  //                       <h5 className="text-secondary">Whatsapp support</h5>
  //                       <p className="text-muted">
  //                         <i className="bx bx-phone"></i>
  //                         <span className="ml-1">+1 5263828282</span>
  //                       </p>
  //                       <p className="text-muted">
  //                         <i className="bx bx-time"></i>
  //                         <span className="ml-left">9AM - 5PM </span>
  //                       </p>
  //                     </div>
  //                   </div>
  //                 </Col>
  //               </Row>
  //             </div>
  //           </div>
  //           {/* righ side */}
  //           <div className="col-md-4">
  //             <img
  //               src={HelpCenterImage}
  //               className="d-none d-lg-block  rounded-start"
  //               style={{
  //                 position: "absolute",
  //                 right: "0",
  //                 bottom: "0",
  //               }}
  //               alt="help center"
  //             />
  //           </div>
  //         </div>
  //       </div>
  //       <div>
  //         {/*  */}
  //         <DropMessage />
  //       </div>
  //     </div>
  //   </section>
};

SupportDashboard.propTypes = {};

export default SupportDashboard;
