import { Loader } from "components";
import { lazy, Suspense } from "react";

const View = lazy(() => import("./learning-material-manage"));

const LearningMaterialManage = (props) => (
  <Suspense fallback={<Loader />}>
    <View {...props} />
  </Suspense>
);

export default LearningMaterialManage;
