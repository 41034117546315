export type StoreMessagesAction = {
  type: string;
  payload: any[];
};

type ConnectedUser = {
  id: number;
  name: string;
  email: string;
  dob: string;
  mobile_number: string;
  status: number;
  timezone: string;
  user_type: "tutor" | "learner";
  profile_picture: string;
};

const INITIAL_STATE: {
  connectedUsers: {
    state: "loading" | "loaded" | "erred";
    data: ConnectedUser[];
  };
} = {
  connectedUsers: {
    state: "loading",
    data: [],
  },
};

function storeMessagesReducer(
  state = INITIAL_STATE,
  action: StoreMessagesAction
) {
  return (
    {
      FETCHED_MESSAGES_USERS: {
        ...state,
        connectedUsers: {
          state: "loaded",
          data: action.payload,
        },
      },
      ERRED_MESSAGES_USERS: {
        ...state,
        connectedUsers: {
          state: "erred",
          data: [],
        },
      },
      RESET_MESSAGES_USERS: {
        ...state,
        connectedUsers: {
          state: "loading",
          data: [],
        },
      },
    }[action.type] || state
  );
}

export default storeMessagesReducer;
