import apiService from "service/api-service";
import { API_MAIN_URL } from "constants/env";
import { getAuthHeaders, getFormDataFromObject } from "utils";

// Types.
import type { Boolbacks } from "constants/types/types";
import type { RawPhoneNumber } from "constants/types/types-phone-number";

export default class PhoneNumberService {
  upsertPhoneNumber({
    rawPhoneNumber,
    ...boolBacks
  }: {
    rawPhoneNumber: RawPhoneNumber;
  } & Boolbacks<any>) {
    apiService.post({
      headers: getAuthHeaders(),
      data: getFormDataFromObject(rawPhoneNumber),
      url: `${API_MAIN_URL}/verify-updated-mobile-number`,
      ...boolBacks,
    });
  }
}
