import * as React from "react";
import OtpSchema from "./otp-form-schema";
import ToastFactory from "utils/toast-factory";
import { useLocation } from "react-router-dom";
import OtpService from "service/otp/otp-service";
import PhoneNumberService from "service/phone-number";
import PhoneNumberAdapter from "adapters/phone-number";

// Types.
import type { FormikHelpers } from "formik";
import { BaseFormProps } from "constants/types/types";
import { RawPhoneNumberContext } from "context/phone-number";

type Props = {
  children: ({
    schema,
    onSubmit,
    onResend,
    initialValue,
  }: {
    schema: OtpSchema;
    onResend: Function;
    initialValue: string;
    onSubmit: (
      values: { code: string },
      formikHelpers: FormikHelpers<{ code: string }>
    ) => void;
  }) => JSX.Element;
} & Pick<BaseFormProps, "onClose">;

/**
 * Data provider for the phone number form.
 *
 * @returns {JSX.Element}
 */
export default function OtpFormProvider({
  onClose,
  children,
}: Props): JSX.Element {
  const { state: phoneNumber } = useLocation();
  const { setState: setPhoneNumber } = React.useContext(RawPhoneNumberContext);

  const toastFactory = ToastFactory();

  const schema = new OtpSchema();
  const service = new OtpService();
  const phoneNumberService = new PhoneNumberService();
  const phoneNumberAdapter = new PhoneNumberAdapter();

  if (!phoneNumberAdapter.isRawPhoneNumber(phoneNumber)) {
    console.warn(
      "OtpForm requires a valid phone number of type RawPhoneNumber"
    );
    onClose();
  }

  function onResend() {
    toastFactory.loading();

    phoneNumberService.upsertPhoneNumber({
      rawPhoneNumber: phoneNumber,
      onFailure: ({ message }) => toastFactory.error(message),
      onSuccess: () => toastFactory.success("OTP resent"),
    });
  }

  function onSubmit(
    values: { code: string },
    _: FormikHelpers<{ code: string }>
  ) {
    toastFactory.loading();

    service.verify({
      ...phoneNumber,
      code: values.code,
      onFailure: ({ message }) => toastFactory.error(message),
      onSuccess: ({ message }) => {
        toastFactory.success(message);

        // Update the state with the new phone number.
        setPhoneNumber(phoneNumber);

        // Close the modal.
        onClose();
      },
    });
  }

  return children({
    schema,
    onSubmit,
    onResend,
    initialValue: "",
  });
}
