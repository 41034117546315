import * as React from "react";
import { Outlet } from "react-router-dom";
import { TabBar, TriggerScrollAnimation } from "components";

export default function TransactionsRootView() {
  return (
    <section className="content">
      <main className="d-flex flex-column">
        <TabBar className="position-relative flex-nowrap overflow-x-auto hidden-scrollbar">
          <TabBar.Item to="/transactions">Dashboard</TabBar.Item>
          <TabBar.Item to="/transactions/payment-methods">
            Payment Methods
          </TabBar.Item>
          <TabBar.Item to="/transactions/session">
            1:1 Tutoring Transactions
          </TabBar.Item>
          <TabBar.Item to="/transactions/material">
            Material Transactions
          </TabBar.Item>
          <TabBar.Item to="/transactions/all">All Transactions</TabBar.Item>
          <TriggerScrollAnimation />
        </TabBar>
        <div className="p-3 p-md-4">
          <Outlet />
        </div>
      </main>
    </section>
  );
}
