import { FormikProps } from "formik";
import * as React from "react";
import { Input } from "reactstrap";
import { FormCreateMaterialState } from "../form-create-material-adapter";

/**
 * @typedef {import("../form-create-material-adapter").FormCreateMaterialState} FormCreateMaterialState
 */

/**
 * The copyright section of the Tutor's Create/Update learning material form.
 * @author kashan-ahmad
 * @version 0.0.1
 */
function CopyrightSection({
  values,
  handleChange,
}: Pick<FormikProps<FormCreateMaterialState>, "values" | "handleChange">) {
  return (
    <section className="mt-4">
      <header>
        <div className="fw-semi-bold text-blue">Copyright</div>
        <p>Intellectual Property Rights: By uploading the selected material</p>
      </header>
      <div role="group" className="vstack gap-3">
        <label htmlFor="hasCopyrightYes" className="d-flex gap-2">
          <Input
            type="radio"
            id="hasCopyrightYes"
            name="hasCopyright"
            className="flex-shrink-0"
            checked={values.hasCopyright}
            onChange={() =>
              handleChange({
                target: {
                  value: true,
                  name: "hasCopyright",
                },
              })
            }
          />
          <p className="mt-0 mb-0 fw-normal">
            I certify that I have read and agree to the TheTutor.Me's terms of
            service and that the selected material does not infringe the
            copyrights, trademark rights, or any other rights of any third
            party.
          </p>
        </label>
        <label htmlFor="hasCopyrightNo" className="d-flex gap-2">
          <Input
            type="radio"
            id="hasCopyrightNo"
            name="hasCopyright"
            className="flex-shrink-0"
            checked={!values.hasCopyright}
            onChange={() =>
              handleChange({
                target: {
                  value: false,
                  name: "hasCopyright",
                },
              })
            }
          />
          <p className="mt-0 mb-0 fw-normal">
            I attest that I have used copyrighted and/or trademarked materials
            in my product and it does not infringe upon the Intellectual
            Property rights of others. I have either received express permission
            to use such materials, or I hereby certify that the use of such
            materials is otherwise non-infringing, for example as a fair use.
          </p>
        </label>
      </div>
    </section>
  );
}

export default CopyrightSection;
