import React from "react";
import Loader from "components/loader/loader";
import { messages } from "constants/strings";
import { Notifier, NotifierProps } from "..";
import NotifierMessage from "../components/notifier-message";

export default function LoadingNotifier({
  children,
  message = messages.LOADING,
}: NotifierProps) {
  return (
    <Notifier className="p-5 align-items-center justify-content-center">
      <Loader isInline />
      <NotifierMessage message={message} />
      {children}
    </Notifier>
  );
}
