import React from "react";
// Utils.
import { apiService } from "service";
import { API_MAIN_URL, MATERIAL_TYPE_FILES, RequestState } from "constants/";
import useApiEffect from "./use-api-effect";
import {
  parseMessage,
  getAuthHeaders,
  downloadResource,
  getFileNameFromUrl,
  getFormDataFromObject,
} from "utils";
import { messages } from "constants/strings";
import ToastFactory from "utils/toast-factory";
import { ConfirmDeletionModal } from "modules/modals/confirmation";
import { SimpleModalBody } from "modules/modals/components/modal-body/variants";
import { DeletionModalFooter } from "modules/modals/components/modal-footer/variants";
import { FormUploadFilesState } from "modules/forms/form-upload-files/form-upload-files-adapter";
import { useContentBuildingHelper } from "./content-builder";

/**
 * Hooks you into the tutors' learning-material/content files.
 * @author kashan-ahmad
 * @version 0.0.1
 */
function useContentFiles(contentId: LearningMaterial["id"]) {
  const toastFactory = ToastFactory();
  const {
    setIsPublishable,
    setCurrentStep,
    learningContent,
    setContentAndMoveStep,
  } = useContentBuildingHelper();

  const [state, setState] = React.useState<{
    data: Pick<FileMaterial, "id" | "file" | "title" | "description">[];
    status: RequestState;
  }>({
    data: [],
    status: "loading",
    // downloads: [],
  });

  const { data } = state;

  useApiEffect(() => {
    if (!contentId)
      throw new Error(
        `Missing parameter 'id' in ${useContentFiles.name} hook!`
      );

    apiService.get({
      headers: getAuthHeaders(),
      data: [{ key: "content_id", value: contentId }],
      url: `${API_MAIN_URL}/contentbuilder/learning-material/fetch-files/`,
      onFailure: () => setState({ ...state, status: "erred" }),

      onSuccess: ({ data }: { data: FileMaterial[] }) => {
        if (data.length > 0) {
          setIsPublishable(true);
          setCurrentStep(3);
        }

        setState({
          status: "loaded",
          data: data.map(({ id, file, title, description }) => ({
            id,
            file,
            title,
            description,
          })),
        });
      },
    });
  }, []);

  async function upsertFileListener(
    onSuccess: Function,
    state: FormUploadFilesState
  ) {
    toastFactory.loading();

    // File is a string(URL) when edited and an actual File when added.
    const file = typeof state.file === "string" ? "" : state.file;

    const data = getFormDataFromObject({
      file,
      // @ts-ignore
      id: state?.id || "",
      title: state.title,
      content_id: contentId,
      description: state.description,
      content_type: MATERIAL_TYPE_FILES,
    });

    apiService.post({
      data,
      headers: getAuthHeaders(),
      url: `${API_MAIN_URL}/contentbuilder/learning-material/upload-files`,
      onSuccess: ({ data }) => {
        setState((prevState) => ({ ...prevState, data }));

        setContentAndMoveStep(
          {
            ...(learningContent as LearningMaterial),
            content_type: MATERIAL_TYPE_FILES,
          },
          3
        );
        setIsPublishable(true);

        // toastFactory.
        toastFactory.success(messages.UPSERTED("file"));

        // Callback.
        onSuccess?.();
      },
      onFailure: ({ message }) => {
        toastFactory.error(
          message ? parseMessage(message) : parseMessage(messages.AN_ERR)
        );
      },
    });
  }

  function removeFileListener(index: number) {
    toastFactory.render(
      <ConfirmDeletionModal
        body={
          <SimpleModalBody>
            Are you sure you want to delete this file?
          </SimpleModalBody>
        }
        footer={
          <DeletionModalFooter
            onCancel={() => toastFactory.dismiss()}
            onDelete={() => {
              toastFactory.loading(messages.REMOVING("file"));

              apiService.post({
                method: "delete",
                headers: getAuthHeaders(),
                url: `${API_MAIN_URL}/contentbuilder/learning-material/delete-files?file_id=${state.data[index].id}`,
                onFailure: ({ message }) => {
                  // Well the API returns an error in case of a single file while deleting it side-by-side, so we delete it manually.
                  // if (state.data.length === 1) {
                  //   setState({
                  //     ...state,
                  //     data: [],
                  //   });

                  //   toastFactory.success(messages.REMOVED("file"));

                  //   return;
                  // }

                  toastFactory.error(message || messages.REMOVING_ERR("file"));
                },
                onSuccess: ({ data }) => {
                  if (data.length === 0) {
                    setIsPublishable(false);
                    setCurrentStep(1);
                  }

                  toastFactory.success(messages.REMOVED("file"));
                  setState({
                    ...state,
                    data: data || [],
                  });
                },
              });
            }}
          />
        }
      />
    );
  }

  function downloadFileListener(index) {
    const url = data[index].file;

    if (!url) toastFactory.error();

    downloadResource(url, getFileNameFromUrl(url));
  }

  return {
    state,
    upsertFileListener,
    removeFileListener,
    downloadFileListener,
  };
}

export default useContentFiles;
