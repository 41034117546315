import React from "react";

export type LabelProps = React.ComponentPropsWithoutRef<"label"> & {
  hint?: string;
};

export default function Label({
  hint,
  children,
  className = "",
  ...props
}: LabelProps) {
  return (
    <label {...props} className={`m-0 d-flex gap-1 align-items-center ${className}`}>
      <div className="card-title m-0">{children}</div>
      {hint && <div className="fs-7 text-muted">{hint}</div>}
    </label>
  );
}
