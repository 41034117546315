// import ShowMoreText from "react-show-more-text";
// import User from "assets/images/users/avatar-2.jpg";
// import smallStar from "assets/images/reviews/smallStar.png";
// import Flag from "assets/images/reviews/flagIcon.png";
// import Msg from "assets/images/reviews/msgIcon.png";
import {Avatar, Icon} from "components";
import moment from "moment";
import {IconStar} from "@tabler/icons";
import {Row, Card, CardBody} from "reactstrap";

const ReviewInfo = ({review}) => {
  return (
    <Card className="m-0">
      <CardBody>
        <Row>
          <div className="col-lg-4">
            <div className="d-flex ">
              <div className="me-3">
                <Avatar src={review.user.profile_picture} size="xs" />
              </div>
              <div className="">
                <h4 className="mb-1">{review.user.name}</h4>
                <p className="text-muted m-0">
                  {moment(review.created_at).format("DD MMM YYYY")}
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-8 gap-column-sm">
            <div className="d-flex justify-content-between">
              <div className="d-flex gap-2 my-1">
                {[...Array(review.rate)].map(() => (
                  <Icon size="sm">
                    <IconStar className="rating-star" />
                  </Icon>
                ))}
              </div>
              {/* <div className="d-flex">
                <div className="review-icon-color rounded mx-2">
                  <img className="mx-1" src={Msg} alt="" />
                </div>
                <div className="review-icon-color rounded mx-1">
                  <img className="mx-1" src={Flag} alt="" />
                </div>
              </div> */}
            </div>
            <div>
              <p className="mb-0 font-size-14 fw-medium">{review.review}</p>
              {/* <p className="text-muted">
                <ShowMoreText
                  lines={2}
                  more="Show more"
                  less="Show less"
                  className="content-css"
                  anchorClass="my-anchor-css-class"
                  expanded={false}
                  truncatedEndingComponent={"... "}
                >
                  In publishing and graphic design, Learn ipsum is a placeholder
                  text commonly used to demonstrate the visual form orelying on
                  meaningfull content. Lorem ipsum may be used as a placeholder
                  before final copy is available Lorem, ipsum dolor sit amet
                  consectetur adipisicing elit. Necessitatibus aut odio esse
                  culpa saepe qui ducimus accusantium nobis vitae, atque optio
                  sapiente animi possimus quas quibusdam, expedita, recusandae
                  tempore corporis.
                </ShowMoreText>
              </p> */}
            </div>
          </div>
        </Row>
      </CardBody>
    </Card>
  );
};

export default ReviewInfo;
