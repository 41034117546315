import * as React from "react";
import { ErrorMessage } from "formik";
import { FormGroup } from "reactstrap";
import SassyFormLabel from "../sassy-form-label";
import FormErrorMessage from "components/error-message-form";
import { useId } from "react";

/**
 * A formik-based input of type-file but sassy. Includes cool max size indicators and placeholders by itself.
 *
 * @param {import("reactstrap").FormGroupProps} props
 * @param {string} props.name
 * @param {React.ReactNode} props.label
 * @param {import("formik").FormikState['values']} props.values
 * @param {JSX.IntrinsicElements['input']['accept']} props.accept
 * @param {number} props.maxSize Maximum file size in Megabytes.
 * @param {import("formik").FormikHandlers['handleChange']} props.handleChange
 * @param {import("formik").FormikHelpers['setFieldTouched']} props.setFieldTouched
 *
 * @returns
 */
function SassyFormFieldFile({
  name,
  label,
  values,
  accept,
  maxSize,
  handleChange,
  setFieldTouched,
  ...props
}) {
  const inputId = useId();

  return (
    <FormGroup {...props}>
      <SassyFormLabel htmlFor={inputId} className="w-100 mb-0 vstack gap-1">
        {label}
        <div className="form-control mt-1 text-lowercase text-truncate">
          {values[name]?.name || "No file chosen"}
        </div>
        {maxSize && (
          <div
            data-testid={`${name}Size`}
            data-testclass="sassyFileSize"
            className="fs-8 text-blue"
          >
            Maximum file size: {maxSize} Mb
          </div>
        )}
      </SassyFormLabel>
      <input
        hidden
        type="file"
        id={inputId}
        name={name}
        accept={accept}
        data-testid={`${name}Field`}
        data-testclass="sassyFileField"
        onChange={(e) => {
          setFieldTouched(name);
          handleChange({
            target: {
              name,
              value: e.target.files[0],
            },
          });
        }}
      />
      <ErrorMessage
        name="document"
        render={(message) => <FormErrorMessage {...{ message }} />}
      />
    </FormGroup>
  );
}

export default SassyFormFieldFile;
