import * as React from "react";
import moment from "moment";

// Components.
import Avatar from "components/avatar";
import { Row, Col } from "reactstrap";
// import {Link} from "react-router-dom";
// import {RLearnerTutoring} from "constants/learner-views-routes";

// Utils.
import { getAdjustedTime } from "utils";

// Types.
import type { SessionStatusCardProps } from "./session-status-card-types";

export default function SessionStatusCard(session: SessionStatusCardProps) {
  const {
    date,
    name,
    agenda,
    end_time,
    // session_id,
    start_time,
    profile_picture,
    children,
  } = session;

  // const {isExpired} = useSessionStatus(session);

  return (
    // <div>
    //   <article className="bg-light-gray rounded border p-3">
    //     <Row className="w-100 mx-0 g-3">
    //       <Col xs={12} sm="8">
    //         <Row className="g-2">
    //           <Col xs="auto" className="p-0">
    //             <Avatar
    //               src={profile_picture}
    //               alt="Tutor Image"
    //               className="tutor-img"
    //               style={{width: "45px", height: "45px"}}
    //             />
    //           </Col>
    //           <Col
    //             className="d-flex flex-column align-items-start"
    //             // xs="auto"
    //             // sm="5"
    //             // md="auto"
    //             // xl="5"
    //           >
    //             {/* <Link
    //               to={RLearnerTutoring.getUpcomingSessionDetailsView(
    //                 session_id
    //               )}
    //               state={{
    //                 isExpired,
    //               }}
    //             > */}
    //             <p className="mb-1 font-size-14 text-secondary text-truncate-n1">
    //               {agenda}
    //             </p>
    //             <p className="tutor-name mb-2 text-muted">{name}</p>
    //             <div className="text-muted d-flex justify-content-start flex-row flex-md-column flex-lg-row gap-2">
    //               <p className="mb-0 text-nowrap">
    //                 {moment(date, "YYYY-MM-DD").format("DD MMM YYYY")}
    //               </p>
    //               <div>
    //                 <p className="mb-0 text-nowrap">
    //                   {getAdjustedTime(start_time, "HH:mm:ss").format(
    //                     "hh:mm A"
    //                   )}{" "}
    //                   -{" "}
    //                   {getAdjustedTime(end_time, "HH:mm:ss").format("hh:mm A")}
    //                 </p>
    //               </div>
    //             </div>
    //             {/* </Link> */}
    //           </Col>
    //         </Row>
    //       </Col>
    //       {children && (
    //         <Col className="p-0 text-end ms-auto" xs="12" sm="4" md="12" xl="4">
    //           {children}
    //         </Col>
    //       )}
    //     </Row>
    //   </article>
    // </div>

    <article className="bg-light-gray rounded border p-3">
      <div className="d-flex gap-2 flex-wrap align-items-center">
        <div>
          <Avatar
            src={profile_picture}
            alt="Tutor Image"
            className="tutor-img"
            style={{ width: "45px", height: "45px" }}
          />
        </div>
        <div className="flex-grow-1">
          <div className="d-flex justify-content-between gap-3 align-items-center flex-wrap ">
            <div
              className="d-flex flex-column align-items-start flex-grow-1"
              style={{ width: "230px" }}
            >
              <p className="mb-1 font-size-14 text-secondary text-truncate-n1">
                {agenda}
              </p>
              <p className="tutor-name mb-2 text-muted">{name}</p>
              <div className="text-muted d-flex justify-content-start flex-div flex-md-column flex-lg-row gap-2">
                <p className="mb-0 text-nowrap">
                  {moment(date, "YYYY-MM-DD").format("DD MMM YYYY")}
                </p>
                <div>
                  <p className="mb-0 text-nowrap">
                    {getAdjustedTime(start_time, "HH:mm:ss").format("hh:mm A")}{" "}
                    {/* - {getAdjustedTime(end_time, "HH:mm:ss").format("hh:mm A")} */}
                  </p>
                </div>
              </div>
            </div>
            {children && <div className="text-end ms-auto">{children}</div>}
          </div>
        </div>
      </div>
    </article>
  );
}
