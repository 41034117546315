/* eslint-disable eqeqeq */
import { isObjectValid } from "utils";
import {
  SET_ONBOARDING_STEP,
  CERTIFICATE_ERROR,
  DEGREE_ERROR,
  EXPERIENCE_ERROR,
  FETCH_CURRICULUM,
  FETCH_CURRICULUM_ERROR,
  FETCH_DEGREE,
  FETCH_QUALIFICATIONS,
  FETCH_SKILLS,
  FETCH_SUBJECTS,
  FETCH_SUBJECTS_ERROR,
  QUALIFICATIONS_ERROR,
  SKILLS_ERROR,
  STORE_CERTIFICATE,
  UPDATE_CERTIFICATE,
  STORE_EXPERIENCE,
  STORE_QUALIFICATIONS,
  DELETE_QUALIFICATIONS,
  STORE_SKILLS,
  STORE_TUTOR_INFO,
  USERINFO_ERROR,
  UPDATE_CURRICULUM,
  UPDATE_DEGREE,
  UPDATE_QUALIFICATIONS,
  UPDATE_SUBJECTS,
  STORE_LANGUAGES,
  FETCH_LANGUAGES,
  FETCH_LANGUAGES_ERROR,
  UPDATE_LANGUAGES,
  UPDATE_SKILLS,
  GET_TUTOR_DATA,
  STORE_SUBJECTS,
  DELETE_EXPERIENCE,
  DELETE_SKILLS,
  DELETE_CERTIFICATES,
  DELETE_SUBJECTS,
  UPDATE_EXPERIENCE,
  SET_TUTOR_LOADING_TRUE,
  GET_CONTENT_BUILDER_DATA,
  GET_LEARNING_TOPIC,
  UPDATE_TOPIC,
  GET_LEARNING_TYPE,
  UPDATE_TYPE,
  CREATE_LEARNING_MATERIAL,
  CREATE_LEARNING_MATERIAL_ERROR,
  UPDATE_AVAILABILITY,
  PROFILE_DATA_ERROR,
  // Settings.
  UPDATE_TUTOR_SETTINGS_BOOKING,
  UPDATE_TUTOR_SETTINGS_CALENDAR,
  UPDATE_TUTOR_SETTINGS_SWITCHES,

  // Off-Time
  SET_TUTOR_OFF_TIME,
  REMOVE_TUTOR_OFF_TIME,
} from "./actionTypes";

/**
 * @type {TutorProfile}
 */
const initialState = {
  onBoardingStep: 1,
  userInfo: {
    loading: true,
    user: null,
    userInfo: null,
    error: null,
    isData: false,
  },
  pricingPlage: {
    loading: false,
  },
  qualification: {
    loading: true,
    qualification: null,
    qualifications: [],
    storedQualification: [],
    selectedQualifications: [],
    isData: false,
  },
  language: {
    loading: true,
    language: "english",
    languages: [],
    selectedLanguages: [],
    storedLanguages: [],
    error: null,
    isData: false,
  },
  subject: {
    loading: true,
    storedSubjects: null,
    subjects: [],
    selectedSubjects: [],
    error: null,
    isData: false,
  },
  degree: {
    loading: true,
    degree: null,
    degrees: [],
    selectedDegrees: [],
    isData: false,
  },
  curriculum: {
    loading: true,
    curriculum: null,
    curriculums: [],
    selectedCurriculums: [],
    isData: false,
  },
  experience: {
    loading: true,
    experience: null,
    experiences: [],
    storedExperience: null,
    selectedExperience: null,
    error: null,
    isData: false,
  },
  skills: {
    loading: true,
    skill: null,
    skills: [],
    storedSkills: null,
    selectedSkills: [],
    error: null,
    isData: false,
  },
  certificate: {
    loading: true,
    certificate: null,
    certificates: [],
    storedCertificate: null,
    selectedCertificates: null,
    error: null,
    isData: false,
  },
  contentBuilderData: [],
  learningTopic: {
    loading: true,
    storedTopics: null,
    topics: [],
    selectedTopics: [],
    error: null,
    isData: false,
  },
  learningType: {
    loading: true,
    storedTypes: null,
    types: [],
    selectedTypes: [],
    error: null,
    isData: false,
  },
  // learningFormResponse: {
  //   loading: true,
  //   error: null,
  //   data: {},
  // },
  availability: [],
  offTimeHistory: [],
  settings: {
    booking: {
      min_booking_time: 1,
      max_advance_book_time: 2,
    },
    switches: {
      free_connect: 0,
      instant_connect: 0,
      freeSessionLength: 15,
    },
    calendar: {
      timezone: "UTC",
      week_start_on: 1,
      date_format: "yyyy-mm-dd",
    },
  },
  reviews: {
    state: "loading",
    data: [],
  },
};

/**
 * @param {TutorProfile} state
 * The current state of the tutor's profile.
 *
 * @param {{
 * type: string,
 * payload: any
 * }} action
 *
 * @returns {State}
 */
const tutorDashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ONBOARDING_STEP:
      return {
        ...state,
        onBoardingStep: action.payload,
      };
    case STORE_TUTOR_INFO:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          loading: false,
          error: null,
          isData: true,
        },
      };
    case "Store_Data_Pendding":
      return {
        ...state,
        pricingPlage: {
          loading: true,
        },
      };
    case "Store_Data_Success":
      return {
        ...state,
        pricingPlage: {
          loading: false,
        },
      };
    case SET_TUTOR_LOADING_TRUE:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          loading: true,
        },
      };
    case "UPDATE_TUTOR_USER":
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          isData: true,
          loading: false,
          user: { ...state.userInfo.user, ...action.payload },
        },
      };
    case GET_TUTOR_DATA:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          user: action.payload.data,
          loading: false,
          isData: true,
        },
        language: {
          ...state.language,
          selectedLanguages: action.payload.data.languages,
        },
        qualification: {
          ...state.qualification,
          storedQualification: action.payload.data.qualifications,
        },
        experience: {
          ...state.experience,
          storedExperience: action.payload.data.experiences,
          selectedExperience: action.payload.data.experiences,
        },
        skills: {
          ...state.skills,
          storedSkills: action.payload.data.skills,
        },
        certificate: {
          ...state.certificate,
          storedCertificate: action.payload.data.certificates,
        },
        subject: {
          ...state.subject,
          selectedSubjects: action.payload.data.subjects,
          storedSubjects: action.payload.data.subjects,
        },
        degree: {
          ...state.degree,
          selecteDegrees: action.payload.data.degree,
        },
        curriculum: {
          ...state.curriculum,
          selectedCurriculums: action.payload.data.curriculum,
        },
        offTimeHistory: action.payload.data.time_off
          ? action.payload.data.time_off.length > 0
            ? action.payload.data.time_off.map(
                ({ id, reason, start_date_time, end_date_time }) => ({
                  id,
                  reason,
                  endDate: end_date_time.split(" ")[0],
                  endTime: end_date_time.split(" ")[1],
                  startDate: start_date_time.split(" ")[0],
                  startTime: start_date_time.split(" ")[1],
                })
              )
            : []
          : [],
        availability: action.payload.data.availability,
        settings: {
          booking: {
            min_booking_time:
              action.payload.data.min_booking_time ||
              state.settings.booking.min_booking_time,
            max_advance_book_time:
              action.payload.data.max_advance_book_time ||
              state.settings.booking.max_advance_book_time,
          },
          switches: {
            free_connect: action.payload.data.free_connect,
            instant_connect: action.payload.data.instant_connect,
          },
          calendar: !isObjectValid(action.payload.data.calendar_setting)
            ? state.settings.calendar
            : action.payload.data.calendar_setting,
        },
      };
    case PROFILE_DATA_ERROR:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          user: null,
          userInfo: null,
          loading: false,
          error: action.payload,
        },
      };
    case USERINFO_ERROR:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          loading: false,
          user: null,
          userInfo: null,
          error: action.payload,
        },
      };
    case GET_CONTENT_BUILDER_DATA:
      return {
        ...state,
        contentBuilderData: action.payload,
      };
    case GET_LEARNING_TOPIC:
      return {
        ...state,
        learningTopic: {
          ...state.learningTopic,
          loading: false,
          topics: action.payload,
        },
      };
    case UPDATE_TOPIC:
      return {
        ...state,
        learningTopic: {
          ...state.learningTopic,
          selectedTopics: action.payload,
          storedTopics: action.payload,
        },
      };
    case GET_LEARNING_TYPE:
      return {
        ...state,
        learningType: {
          ...state.learningType,
          loading: false,
          types: action.payload.data,
        },
      };
    case UPDATE_TYPE:
      return {
        ...state,
        learningType: {
          ...state.learningType,
          selectedTopics: action.payload,
          storedTopics: action.payload,
        },
      };
    // case CREATE_LEARNING_MATERIAL:
    //   return {
    //     ...state,
    //     learningFormResponse: {
    //       ...state.learningFormResponse,
    //       data: action.payload,
    //     },
    //   };
    // case CREATE_LEARNING_MATERIAL_ERROR:
    //   return {
    //     ...state,
    //     learningFormResponse: {
    //       ...state.learningFormResponse,
    //       loading: false,
    //       error: action.payload,
    //     },
    //   };
    case FETCH_LANGUAGES:
      return {
        ...state,
        language: {
          ...state.language,
          loading: false,
          languages: action.payload.data,
        },
      };
    case UPDATE_LANGUAGES:
      return {
        ...state,
        language: {
          ...state.language,
          selectedLanguages: action.payload,
        },
      };
    case STORE_LANGUAGES:
      return {
        ...state,
        language: {
          ...state.language,
          isData: true,
        },
      };

    case FETCH_LANGUAGES_ERROR:
      return {
        ...state,
        language: {
          ...state.language,
          loading: false,
          error: action.payload,
        },
      };
    case FETCH_SUBJECTS:
      return {
        ...state,
        subject: {
          ...state.subject,
          loading: false,
          subjects: action.payload.data,
        },
      };
    case FETCH_SUBJECTS_ERROR:
      return {
        ...state,
        subject: {
          ...state.subject,
          loading: false,
          error: action.payload,
        },
      };
    case UPDATE_SUBJECTS:
      return {
        ...state,
        subject: {
          ...state.subject,
          selectedSubjects: action.payload,
          // storedSubjects: action.payload,
        },
      };
    case STORE_SUBJECTS:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          user: {
            ...state.userInfo.user,
            subjects: action.payload,
          },
        },
        subject: {
          ...state.subject,
          storedSubjects: action.payload,
          loading: false,
          error: null,
        },
      };
    case DELETE_SUBJECTS:
      return {
        ...state,
        subject: {
          ...state.subject,
        },
      };
    case FETCH_DEGREE:
      return {
        ...state,
        degree: {
          loading: false,
          degree: action.payload.data,
        },
      };
    case UPDATE_DEGREE:
      return {
        ...state,
        degree: {
          ...state.degree,
          selectedDegrees: action.payload,
        },
      };
    case DEGREE_ERROR:
      return {
        ...state,
        degree: {
          ...state.degree,
          loading: false,
          error: action.payload,
        },
      };
    case FETCH_CURRICULUM:
      return {
        ...state,
        curriculum: {
          loading: false,
          curriculum: action.payload.data,
        },
      };
    case UPDATE_CURRICULUM:
      return {
        ...state,
        curriculum: {
          ...state.curriculum,
          selectedCurriculums: action.payload,
        },
      };
    case FETCH_CURRICULUM_ERROR:
      return {
        ...state,
        curriculum: {
          ...state.curriculum,
          loading: false,
          error: action.payload,
        },
      };
    case FETCH_QUALIFICATIONS:
      return {
        ...state,
        qualification: {
          ...state.qualification,
          loading: false,
          qualification: action.payload.data,
        },
      };
    case QUALIFICATIONS_ERROR:
      return {
        ...state,
        qualification: {
          ...state.qualification,
          loading: false,
          error: action.payload,
          isData: false,
        },
      };

    case UPDATE_QUALIFICATIONS:
    case STORE_QUALIFICATIONS:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          user: {
            ...state.userInfo.user,
            qualifications: action.payload,
          },
        },
        qualification: {
          ...state.qualification,
          storedQualifications: action.payload,
          selectedExperience: action.payload,
          loading: false,
          error: null,
        },
      };
    case DELETE_QUALIFICATIONS:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          user: {
            ...state.userInfo.user,
            qualifications: state.userInfo.user.qualifications.filter(
              (c) => c.id !== action.payload
            ),
          },
        },
        qualification: {
          ...state.qualification,
          storedQualification: state.qualification.storedQualification.filter(
            (form) => form.id !== action.payload
          ),
        },
      };
    case UPDATE_EXPERIENCE:
    case STORE_EXPERIENCE:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          user: {
            ...state.userInfo.user,
            experiences: action.payload,
          },
        },
        experience: {
          ...state.experience,
          storedExperience: action.payload,
          selectedExperience: action.payload,
          loading: false,
          error: null,
        },
      };
    case EXPERIENCE_ERROR:
      return {
        ...state,
        experience: {
          ...state.experience,
          loading: false,
          error: action.payload,
        },
      };
    case DELETE_EXPERIENCE:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          user: {
            ...state.userInfo.user,
            experiences: state.userInfo.user.experiences.filter(
              (c) => c.id !== action.payload
            ),
          },
        },
        experience: {
          ...state.experience,
          storedExperience: state.experience.storedExperience.filter(
            (form) => form.id !== action.payload
          ),
        },
      };
    case FETCH_SKILLS:
      return {
        ...state,
        skills: {
          ...state.skills,
          loading: false,
          skills: action.payload.data,
        },
      };
    case STORE_SKILLS:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          user: {
            ...state.userInfo.user,
            skills: action.payload,
          },
        },
        skills: {
          ...state.skills,
          storedSkills: action.payload.data,
          loading: false,
          error: null,
        },
      };
    case UPDATE_SKILLS:
      return {
        ...state,
        skills: {
          ...state.skills,
          storedSkills: action.payload,
        },
      };
    case SKILLS_ERROR:
      return {
        ...state,

        skills: {
          ...state.skills,
          loading: false,
          error: action.payload,
        },
      };
    case DELETE_SKILLS:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          user: {
            ...state.userInfo.user,
            skills: state.userInfo.user.skills.filter(
              (s) => s.id !== action.payload
            ),
          },
        },
        skills: {
          ...state.skills,
          storedSkills: state.skills.storedSkills?.filter(
            (form) => form.id !== action.payload
          ),
        },
      };

    case UPDATE_CERTIFICATE:
    case STORE_CERTIFICATE:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          user: {
            ...state.userInfo.user,
            certificates: action.payload,
          },
        },
        certificate: {
          ...state.certificate,
          storedCertificate: action.payload,
          loading: false,
          error: null,
        },
      };
    case CERTIFICATE_ERROR:
      return {
        ...state,
        certificate: {
          ...state.certificate,
          loading: false,
          error: action.payload,
        },
      };
    case DELETE_CERTIFICATES:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          user: {
            ...state.userInfo.user,
            certificates: state.userInfo.user.certificates.filter(
              (c) => c.id !== action.payload
            ),
          },
        },
        certificate: {
          ...state.certificate,
          storedCertificate: state.certificate.storedCertificate.filter(
            (form) => form.id !== action.payload
          ),
        },
      };
    case UPDATE_AVAILABILITY:
      return {
        ...state,
        availability: action.payload,
      };
    // A simple update case.
    case UPDATE_TUTOR_SETTINGS_BOOKING:
      return {
        ...state,
        settings: {
          ...state.settings,
          booking: action.payload,
        },
      };
    case UPDATE_TUTOR_SETTINGS_SWITCHES:
      return {
        ...state,
        settings: {
          ...state.settings,
          switches: action.payload,
        },
      };

    // A simple update case.
    case UPDATE_TUTOR_SETTINGS_CALENDAR:
      return {
        ...state,
        settings: {
          ...state.settings,
          calendar: action.payload,
        },
      };
    // A simple update case.
    case SET_TUTOR_OFF_TIME:
      return {
        ...state,
        offTimeHistory: action.payload,
      };
    // A record is removed from the list
    // using it's index in the same list.
    case REMOVE_TUTOR_OFF_TIME:
      return {
        ...state,
        offTimeHistory: state.offTimeHistory.filter(
          (_, i) => i != action.payload
        ),
      };
    case "SET_TUTOR_REVIEWS":
      return {
        ...state,
        reviews: {
          state: "loaded",
          data: action.payload,
        },
      };
    case "ERRED_TUTOR_REVIEWS":
      return {
        ...state,
        reviews: {
          state: "erred",
          data: [],
        },
      };
    default:
      return state;
  }
};

export default tutorDashboardReducer;
