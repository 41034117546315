import { lazy, Suspense } from "react";
import { Loader } from "components";

const View = lazy(() => import("./learning-material-details"));

const LearningMaterialDetails = (props) => (
  <Suspense fallback={<Loader />}>
    <View {...props} />
  </Suspense>
);

export default LearningMaterialDetails;
