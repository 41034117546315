import moment from "moment";
import ReactApexChart from "react-apexcharts";

function PaymentsTrendsChart({chartData = [], type = "Earnings", onZoomed}) {
  const series = [
    {
      name: `Daily ${type}`,
      data: chartData.map((i) => i.Total),
    },
  ];

  const options = {
    chart: {
      events: {
        zoomed: function (chartContext, {xaxis, yaxis}) {
          var given = moment(xaxis.min);
          var current = moment().startOf("day");

          //Difference in number of days
          onZoomed?.(current.diff(given, "days") + 2);
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },

    colors: ["#34c38f"],
    xaxis: {
      type: "datetime",
      categories: chartData.map((i) => i.number),
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    tooltip: {
      x: {
        format: "dd MMM yyyy",
      },
    },
  };

  return (
    <>
      <ReactApexChart
        options={options}
        series={series}
        type="area"
        height="180"
        className="apex-charts"
      />
    </>
  );
}

export default PaymentsTrendsChart;
