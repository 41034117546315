export function setToken(token: string) {
  localStorage.setItem("token", token);
}

export function getToken() {
  return localStorage.getItem("token");
}

export function removeToken() {
  localStorage.removeItem("token");
}
export function removevailibilityData() {
  localStorage.removeItem("availibilityCopy");
}

export function setLogoutToken(token: string) {
  localStorage.setItem("logoutToken", token);
}

export function getLogoutToken() {
  return localStorage.getItem("logoutToken");
}

export function removeLogoutToken() {
  localStorage.removeItem("logoutToken");
}
export function removeOnboardingSkillsSubj() {
  localStorage.removeItem("useableSkills");
  localStorage.removeItem("useableSubjects");
}
