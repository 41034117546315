import { apiService } from "service";
import { API_MAIN_URL } from "constants";
import { DEFAULT_NOTIFICATIONS_PER_PAGE } from "constants";
import { getAuthHeaders, getFormDataFromObject } from "utils";

/**
 * @param {Boolbacks} object
 */
export const fetchNotifications =
  ({ onSuccess, onFailure }) =>
  /**
   * @param {import("redux").Dispatch<import("constants/types/types-notifications").NotificationAction>} dispatch
   */
  (dispatch) =>
    apiService.get({
      headers: getAuthHeaders(),
      url: `${API_MAIN_URL}/get-notifications-list`,
      data: [{ key: "limit", value: DEFAULT_NOTIFICATIONS_PER_PAGE }],
      onSuccess: ({ data, page_no, number_of_pages }) => {
        dispatch({
          type: "SET_NOTIFICATIONS",
          // page_no and number_of_pages are initially set,
          // in order to implement derived state instances.
          payload: { data, page_no, number_of_pages },
        });

        onSuccess?.({ data, page_no, number_of_pages });
      },
      onFailure: (error) => {
        dispatch({
          type: "ERRED_NOTIFICATIONS",
        });

        onFailure?.(error);
      },
    });

/**
 * @param {Boolbacks} object
 */
export const fetchMoreNotifications = ({ page_no, onSuccess, onFailure }) =>
  apiService.get({
    headers: getAuthHeaders(),
    url: `${API_MAIN_URL}/get-notifications-list`,
    data: [
      { key: "page", value: page_no },
      { key: "limit", value: DEFAULT_NOTIFICATIONS_PER_PAGE },
    ],
    onSuccess: ({ data }) => onSuccess?.(data),
    onFailure: (error) => onFailure?.(error),
  });

/**
 * The thing with this middleware is that the API returns a new set of marked notifications upon success and that new set needs to be considered the default notification set as it contains the updated/marked instances.
 *
 * In order to make that happen, we use the good ol' `SET_NOTIFICATIONS` action type that tells the reducer to consider these the new notifications.
 *
 * @param {Object} object
 *
 * @param {Array} object.ids The ids of the notifications to be marked.
 * @param {User['type']} object.userType
 * @param {Boolbacks['onSuccess']} onSuccess
 * @param {Boolbacks['onFailure']} onFailure
 */
export const markNotificationAsRead =
  ({ ids, userType, onSuccess, onFailure }) =>
  /**
   * @param {import("redux").Dispatch<import("constants/types/types-notifications").NotificationAction>} dispatch
   */
  (dispatch) => {
    const proposal = {
      headers: getAuthHeaders(),
      url: `${API_MAIN_URL}/${userType}/read-notifications`,
      onSuccess: ({ data }) => {
        dispatch({
          type: "SET_NOTIFICATIONS",
          // The payload is destructured & consumed directly by the reducer, hence the object is passed.
          payload: { data },
        });

        onSuccess?.(data);
      },
      onFailure: (error) => {
        dispatch({
          type: "ERRED_NOTIFICATIONS",
        });

        onFailure?.(error);
      },
    };

    if (ids)
      proposal["data"] = getFormDataFromObject({
        return_response: 2,
        notification_ids: JSON.stringify(ids),
        limit: DEFAULT_NOTIFICATIONS_PER_PAGE,
      });

    apiService.post(proposal);
  };
