import React from "react";
import Shepherd from "shepherd.js";
import {offset} from "@floating-ui/dom";
import {getAdaptedStep} from "adapters/tour-adapter";
import apiService from "service/api-service";

// Utils.
import {getEnvVar} from "utils/utils-env";
import {
  getAuthHeaders,
  setPageAsVisited,
  getFormDataFromObject,
} from "utils/utils-functions";

// Types.
import type {
  Tour,
  TourStep,
  RemoteTourAction,
} from "constants/types/types-tour";

/**
 * The tour hook.
 * Provides a wrapper for the currently used touring library.
 * @returns {Tour}
 */
export default function useTourLib(): Tour {
  // Notice that we've memoized everything.
  // This has been done after a careful observation on the performance metrics with and without memoization. Memoized hook takes the same amount of time to execute as the non-memoized hook on the first render. However, the non-memoized hook takes a lot more time to execute on the subsequent renders. This is because the non-memoized hook creates a new instance of the tour on every render. This is not the case with the memoized hook. The memoized hook creates a new instance of the tour only when the dependencies change. This is why the memoized hook is more performant.
  // The initial load time is 99.5ms. However, the subsequent renders take 0ms to execute the memoized hook, whereas the non-memoized hook takes a minimum of 99.5ms to execute on the subsequent renders. Some results saw the non-memoized hook taking 400ms to execute on the subsequent renders!
  // const timeBefore = performance.now();

  /**
   * The tour instance.
   * @type {Shepherd.Tour}
   */
  const tour: Shepherd.Tour = React.useMemo(() => {
    const tour = new Shepherd.Tour({
      // classPrefix doesn't work with the current version of Shepherd.
      // TODO: Raise this issue with the Shepherd team.
      classPrefix: "tour-",
      useModalOverlay: true,
      defaultStepOptions: {
        modalOverlayOpeningRadius: 4,
        modalOverlayOpeningPadding: 4,
        scrollTo: {behavior: "smooth", block: "center"},
        floatingUIOptions: {
          middleware: [offset({mainAxis: 24, crossAxis: 0})],
        },
      },
    });

    if (getEnvVar("NODE_ENV") === "production") {
      const eventListener = (action: RemoteTourAction) => {
        // Set the current page as visited so that the user doesn't see the tour again.
        setPageAsVisited();

        // Inform the API that the user has skipped or proceeded the tour.
        apiService.post({
          headers: getAuthHeaders(),
          url: `${getEnvVar("USER_SERVICE_URL")}/intro-insights`,
          data: getFormDataFromObject({
            action,
            viewed: 1,
            key: window.location.pathname,
          }),
        });
      };

      // Add event listeners to the tour.
      tour.on("cancel", () => eventListener("skipped"));
      tour.on("complete", () => eventListener("proceeded"));
    }

    return tour;
  }, []);

  /**
   * Starts the tour based on the added steps.
   * @returns {void}
   */
  const start: Tour["start"] = React.useCallback(() => tour.start(), [tour]);

  /**
   * Move to the next step.
   * @returns {void}
   */
  const forwards: Tour["forwards"] = React.useCallback(
    () => tour.next(),
    [tour]
  );

  /**
   * Move to the previous step.
   * @returns {void}
   */
  const backwards: Tour["backwards"] = React.useCallback(
    () => tour.back(),
    [tour]
  );

  /**
   * Stop the tour.
   * @returns {void}
   */
  const stop: Tour["stop"] = React.useCallback(() => tour.cancel(), [tour]);

  /**
   * Add a step to the tour.
   * @param step The step to add.
   */
  const addStep: Tour["addStep"] = React.useCallback(
    (step: TourStep) => tour.addStep(getAdaptedStep(step)),
    [tour]
  );

  const addSteps: Tour["addSteps"] = React.useCallback(
    (steps) => tour.addSteps(steps.map((step) => getAdaptedStep(step))),
    [tour]
  );

  // const timeAfter = performance.now();

  // console.log("Execution time:", (timeAfter - timeBefore) * 1000 + "ms");

  return {
    start,
    addStep,
    addSteps,
    forwards,
    backwards,
    stop,
  };
}
