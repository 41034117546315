import React, {useState} from "react";
import {Button, Col, Row, Container} from "reactstrap";
import {connect} from "react-redux";
import {
  storeLanguages,
  storeLearningStyles,
  storeGoals,
  storeTimeSlots,
  getProfileData,
} from "store/learnerdashboard/action";
import LanguagesBlock from "./preferences-languages";
import GoalsBlock from "./preferences-goals";
import BestTime from "./preferences-best-times/index";
import LearningStyleBlock from "./preferences-learning-styles";
import {useApiEffect} from "hooks";
import ToastFactory from "utils/toast-factory";
import {messages, requirements} from "constants/strings";

const LearnerProfilePreferencesView = ({
  storeLanguages,
  storeGoals,
  storeTimeSlots,
  storeLearningStyles,
}) => {
  const toastFactory = ToastFactory();
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [goalsPreferences, setGoalsPreferences] = useState([]);
  const [bestTimesPreferences, setBestTimesPreferences] = useState([]);
  const [learningStylesPreferences, setLearningStylesPreferences] = useState(
    []
  );

  const [submit, setSubmit] = useState(0);
  const [isUpdated, setIsUpdated] = useState([]);

  useApiEffect(() => {
    if (submit > 0) {
      if (!isUpdated.includes("languages")) {
        storeLanguages(
          selectedLanguages.map((i) => i.value),
          () => {
            setIsUpdated((prev) => [...prev, "languages"]);
          },
          () => toastFactory.dismiss()
        );
      }
      if (isUpdated.includes("languages") && !isUpdated.includes("goals")) {
        storeGoals(
          goalsPreferences,
          () => {
            setIsUpdated((prev) => [...prev, "goals"]);
          },
          () => toastFactory.dismiss()
        );
      }
      if (isUpdated.includes("goals") && !isUpdated.includes("bestTimes")) {
        storeTimeSlots(
          bestTimesPreferences,
          () => {
            setIsUpdated((prev) => [...prev, "bestTimes"]);
          },
          () => toastFactory.dismiss()
        );
      }
      if (
        isUpdated.includes("bestTimes") &&
        !isUpdated.includes("learningStyles")
      ) {
        storeLearningStyles(
          learningStylesPreferences,
          () => {
            setIsUpdated((prev) => [...prev, "learningStyles"]);
          },
          () => toastFactory.dismiss()
        );
      }

      if (
        isUpdated.includes("languages") &&
        isUpdated.includes("learningStyles") &&
        isUpdated.includes("goals") &&
        isUpdated.includes("bestTimes")
      ) {
        setSubmit(0);
        setIsUpdated([]);
        setTimeout(() => {
          toastFactory.success(messages.CHANGES_SAVED);
        }, 200);
      }
    }
  }, [submit, isUpdated]);

  const handleSubmit = async () => {
    if (selectedLanguages.length === 0) {
      toastFactory.error(requirements.AT_LEAST_ONE("language"));
      return;
    }
    if (goalsPreferences.length === 0) {
      toastFactory.error(requirements.AT_LEAST_ONE("goal"));
      return;
    }
    if (bestTimesPreferences.length === 0) {
      toastFactory.error(requirements.AT_LEAST_ONE("best learning-time"));
      return;
    }
    if (learningStylesPreferences.length === 0) {
      toastFactory.error(requirements.AT_LEAST_ONE("learning style"));
      return;
    }
    toastFactory.loading();

    setSubmit((prev) => prev + 1);
  };

  return (
    <div className="preference">
      <Container fluid>
        <LanguagesBlock state={[selectedLanguages, setSelectedLanguages]} />

        {/* What is your goal */}
        <GoalsBlock state={[goalsPreferences, setGoalsPreferences]} />

        {/*Best time to learn*/}
        <Row>
          <Col xs="12" className="preference-learn">
            <h5 className="preference-head">
              <span>What's best time for you to learn?</span>
            </h5>
          </Col>

          <Col md="9" className="m-0">
            <div className="preference-learncontent">
              <BestTime
                state={[bestTimesPreferences, setBestTimesPreferences]}
              />
            </div>
          </Col>
        </Row>

        {/* Learning style section */}
        <LearningStyleBlock
          state={[learningStylesPreferences, setLearningStylesPreferences]}
        />

        <div className="preference-btn1">
          <Button
            style={{background: "#ffffff", border: "2px solid #05386B"}}
            type="button"
            className="text-dark my-2 cancel-button"
          >
            <span style={{color: "#05386B"}}>Back</span>
          </Button>
          <Button
            type="button"
            className="text-dark my-2 ms-3 continue-button"
            onClick={() => handleSubmit()}
          >
            <span style={{color: "#ffffff"}}>Save</span>
          </Button>
        </div>
      </Container>
    </div>
  );
};

export default connect(() => ({}), {
  getProfileData,
  storeLanguages,
  storeGoals,
  storeTimeSlots,
  storeLearningStyles,
})(LearnerProfilePreferencesView);
