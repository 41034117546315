import React from "react";
import ListItemUser from "./list-item-user";

/**
 * Pre-configured list item to display a user's welcome message
 *
 * @param {import('./list-item-user').ListItemUserProps} params
 *
 * @returns {JSX.Element}
 */
export default function ListItemUserWelcome({ username, avatarSrc }) {
  return (
    <ListItemUser as="div" itemTag="div" className="vstack" {...{ avatarSrc }}>
      <div className="fs-5 fw-bold text-success">
        Welcome back, <span className="text-secondary">{username}</span>
      </div>
      <div className="fs-7 text-blue">
        Check your daily tasks & learning material
      </div>
    </ListItemUser>
  );
}
