import { Loader } from "components";
import React from "react";

const View = React.lazy(() => import("./tutoring-tutor-sidebar-calendar"));

const TutoringTutorSidebarCalendar = (props) => (
  <React.Suspense fallback={<Loader />}>
    <View {...props} />
  </React.Suspense>
);

export default TutoringTutorSidebarCalendar;
