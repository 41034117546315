import {
  SET_OFFERED_SESSIONS,
  ERRED_OFFERED_SESSIONS,
  SET_SESSIONS_COLLECTION,
  SET_CONNECTED_LEARNERS,
  ERRED_SET_CONNECTED_LEARNERS,
  SET_AVAILABLE_SLOTS,
  SET_TUTOR_CALENDAR_LISTINGS,
  ERRED_TUTOR_CALENDAR_LISTINGS,
  ERRED_AVAILABLE_SLOTS,
  RESET_RECENT_ACTIVITIES,
  SET_RECENT_ACTIVITIES,
  ERRED_RECENT_ACTIVITIES,
} from "./store-tutoring-tutor-types";
import { apiService } from "service";
import { getAuthHeaders } from "utils";
import moment from "moment";
import { isEmpty } from "lodash";
import {
  API_MAIN_URL,
  DEFAULT_CONNECTED_USERS_PER_PAGE,
  DEFAULT_SESSIONS_PER_PAGE,
} from "constants/";

/**
 * Request the api to return combinations of sessions.
 *
 * @param {Object} object The passed properties.
 * @param {Object} object.rawSessionData The session data
 * to create combinations from.
 * @param {() => void} object.onSuccess The success' callback.
 * @param {() => void} object.onFailure The failure's callback.
 */
export const fetchSessionCollection =
  ({ rawSessionData, onSuccess, onFailure }) =>
  async (dispatch) =>
    apiService.post({
      onFailure,
      data: rawSessionData,
      headers: getAuthHeaders(),
      url: `${API_MAIN_URL}/tutor/generate-session-combinations`,
      onSuccess: (response) => {
        dispatch({
          payload: response.data,
          type: SET_SESSIONS_COLLECTION,
        });

        // The callback.
        onSuccess?.(response.data);
      },
    });

/**
 * Fetch the sessions offered by a tutor.
 *
 * @param {Object} object The properties passed to the component.
 * @param {Array} object.gradeIds The array of `ods` of the grades to filter.
 * @param {Array} object.subjectIds The array of `ids` of the subjects to filter.
 * @param {Array} object.curriculumIds The array of `ids` of the curriculums
 * to filter.
 * @param {Function} object.onSuccess The success' callback.
 * @param {Function} object.onFailure The failure's callback.
 */
export const fetchOfferedSessions =
  ({
    gradeIds,
    subjectIds,
    curriculumIds,
    page = 1,
    limit = DEFAULT_SESSIONS_PER_PAGE,
    onSuccess,
    onFailure,
  }) =>
  async (dispatch) => {
    const data = [
      { key: "limit", value: limit },
      { key: "page", value: page },
    ];

    if (gradeIds) data.push({ key: "grade_id", value: gradeIds });

    if (subjectIds) data.push({ key: "subject_id", value: subjectIds });

    if (curriculumIds)
      data.push({ key: "curriculum_id", value: curriculumIds });

    // Request the sessions.
    apiService.get({
      data,
      headers: getAuthHeaders(),
      url: `${API_MAIN_URL}/tutor/get-sessions`,
      onFailure: (error) => {
        dispatch({
          type: ERRED_OFFERED_SESSIONS,
        });

        onFailure?.(error);
      },
      onSuccess: (response) => {
        dispatch({
          type: SET_OFFERED_SESSIONS,
          payload: response,
        });
        onSuccess?.();
      },
    });
  };

/**
 * Update the session collection with a predefined collection.
 * @param {Array} sessionCollection The collection to replace
 * the old one with.
 */
export const updateSessionCollection = (sessionCollection) => (dispatch) =>
  dispatch({
    payload: sessionCollection,
    type: SET_SESSIONS_COLLECTION,
  });

/**
 * Post a collection of sessions to the API.
 *
 * @param {Array} object.sessionCollection The collection of
 * sessions to be posted.
 * @param {() => void} object.onSuccess The success' callback.
 * @param {() => void} object.onFailure The failure's callback.
 */
export const postSessionCollection = async ({
  sessionCollection,
  onSuccess,
  onFailure,
}) => {
  apiService.post({
    url: `${API_MAIN_URL}/tutor/create-sessions`,
    headers: getAuthHeaders(),
    data: sessionCollection,
    onSuccess,
    onFailure,
  });
};

/**
 * Fetch a tutor's connected learners
 * @param {object} params - The params to filter the connected learners
 * @param {number | string | undefined}  [params.grade] - The grade id
 * @param {number | string | undefined} [params.subject] - The subject id
 * @param {number | string | undefined} [params.curriculum] - The curriculum id
 * @param {number | string | undefined} [params.bundleId] - The bundle id
 * @param {number | string | undefined} [params.learnerId] - The learner id
 * @param {string}  [params.name] - The learner name
 * @param {number} [params.page] - The page number
 * @param {number} [params.limit] - The limit
 */
export const fetchConnectedLearners =
  ({
    page = 1,
    grade,
    subject,
    curriculum,
    bundleId,
    learnerId,
    name,
    limit = DEFAULT_CONNECTED_USERS_PER_PAGE,
  }) =>
  async (dispatch) => {
    const data = [];

    page && data.push({ key: "page", value: page });
    limit && data.push({ key: "limit", value: limit });
    grade && data.push({ key: "grade", value: grade });
    subject && data.push({ key: "subject", value: subject });
    curriculum && data.push({ key: "curriculum", value: curriculum });

    if (bundleId) data.push({ key: "bundle_id", value: bundleId });
    if (learnerId) data.push({ key: "learner_id", value: learnerId });
    if (name) data.push({ key: "name", value: name });

    apiService.get({
      headers: getAuthHeaders(),
      url: `${API_MAIN_URL}/tutor/get-connected-learners`,
      data,
      onSuccess: ({ data }) => {
        dispatch({
          type: SET_CONNECTED_LEARNERS,
          payload: data,
        });
      },
      onFailure: ({ error }) => {
        dispatch({
          type: ERRED_SET_CONNECTED_LEARNERS,
        });
      },
    });
  };

export const sendSessionRequestToLearner =
  ({ sessionRequestData, onSuccess, onFailure }) =>
  async (dispatch) => {
    const formData = new FormData();

    Object.entries(sessionRequestData).forEach(([key, value]) =>
      formData.append(key, value)
    );

    apiService.post({
      url: `${API_MAIN_URL}/tutor/sessions/save-requests`,
      headers: getAuthHeaders(),
      data: formData,
      onSuccess: (res) => {
        onSuccess?.(res);
      },
      onFailure,
    });
  };

export const fetchTutorAvailableSlots =
  ({ bundleId, date, range, tutorId, onFailure }) =>
  async (dispatch) => {
    // dispatch({type: RESET_AVAILABLE_SLOTS});

    const data = [
      { key: "bundle_id", value: bundleId },
      { key: "date", value: date },
      { key: "range", value: range },
      { key: "tutor_id", value: tutorId },
    ];

    apiService.get({
      headers: getAuthHeaders(),
      url: `${API_MAIN_URL}/tutor/sessions/available-slots`,
      data,
      onSuccess: ({ data }) => {
        dispatch({
          type: SET_AVAILABLE_SLOTS,
          payload: data,
        });
      },
      onFailure: ({ error }) => {
        dispatch({ type: ERRED_AVAILABLE_SLOTS });
      },
    });
  };

export const fetchFinishedSessionsWithStudent =
  ({ learner_id, page, limit }) =>
  async (dispatch, getState) => {
    dispatch({ type: RESET_RECENT_ACTIVITIES });

    const data = [
      { key: "learner_id", value: learner_id },
      { key: "page", value: page },
      { key: "limit", value: limit },
    ];

    apiService.get({
      url: `${API_MAIN_URL}/tutor/activity-logs`,
      data,
      headers: getAuthHeaders(),
      onSuccess: (res) => {
        dispatch({
          type: SET_RECENT_ACTIVITIES,
          payload: res,
        });
      },
      onFailure: ({ error }) => {
        dispatch({ type: ERRED_RECENT_ACTIVITIES });
      },
    });
  };

export const fetchTutorSessionDetails =
  ({ sessionId, requestId, onSuccess, onFailure }) =>
  async (dispatch) => {
    apiService.get({
      headers: getAuthHeaders(),
      data: [
        { key: "session_id", value: sessionId },
        { key: "request_id", value: requestId },
      ],
      url: `${API_MAIN_URL}/tutor/sessions/detail`,
      onSuccess,
      onFailure,
    });
  };

// Calendar Listings.
export const fetchTutorCalendarListings =
  ({
    curriculums,
    grades,
    subjects,
    statuses,
    start_date,
    end_date,
    onSuccess,
    onFailure,
  }) =>
  async (dispatch) => {
    const data = [];
    if (start_date) data.push({ key: "start_date", value: start_date });
    if (end_date) data.push({ key: "end_date", value: end_date });
    if (!isEmpty(curriculums)) {
      data.push({ key: "curriculum", value: curriculums });
    }
    if (!isEmpty(grades)) {
      data.push({ key: "grade", value: grades });
    }
    if (!isEmpty(subjects)) {
      data.push({ key: "subject", value: subjects });
    }
    if (!isEmpty(statuses)) {
      data.push({ key: "status", value: statuses });
    }

    apiService.get({
      url: `${API_MAIN_URL}/tutor/get-calendar-sessions`,
      headers: getAuthHeaders(),
      data,
      onSuccess: (response) => {
        const sessionsData = response.data.map((m) => {
          const startTimeStamp = m.date + " " + m.start_time;
          const endTimeStamp = m.date + " " + m.end_time;
          // const { subject } = subjectsList.find(
          //   (s) => s.id === m.session.subject_id
          // );

          const startTime = moment(
            startTimeStamp,
            "YYYY-MM-DD HH:mm:ss"
          ).format();
          const endTime = moment(endTimeStamp, "YYYY-MM-DD HH:mm:ss").format();
          return {
            SessionId: m.session_id,
            RequestId: m.id,
            StartTime: startTime,
            EndTime: endTime,
            SubjectId: m.session.subject_id,
            LearnerName:
              m.sent_by.user_type === "learner"
                ? m.sent_by.name
                : m.sent_to.name,
            RequestStatus: m.status.id,
            IsUpcoming: moment(m.date, "YYYY-MM-DD").isSameOrAfter(new Date()),
          };
        });

        dispatch({
          type: SET_TUTOR_CALENDAR_LISTINGS,
          payload: sessionsData,
        });
      },
      onFailure: (error) => {
        dispatch({
          type: ERRED_TUTOR_CALENDAR_LISTINGS,
        });

        onFailure?.(error);
      },
    });
  };

export const rescheduleSession =
  ({
    requestId,
    date,
    startTime,
    endTime,
    day,
    rescheduledBy,
    onSuccess,
    onFailure,
  }) =>
  async (dispatch) => {
    const formData = new FormData();
    formData.append("request_id", requestId);
    formData.append("status", 4);
    formData.append("date", date);
    formData.append("start_time", startTime);
    formData.append("end_time", endTime);
    formData.append("day", day);
    formData.append("rescheduled_by", rescheduledBy);

    apiService.post({
      url: `${API_MAIN_URL}/tutor/sessions/request-response`,
      headers: getAuthHeaders(),
      data: formData,
      onSuccess,
      onFailure,
    });
  };

export const updateInstantConnectMeetingStatus =
  ({ meetingId, attendantId, status, onSuccess, onFailure }) =>
  async (dispatch) => {
    const formData = new FormData();

    formData.append("meeting_id", meetingId);
    formData.append("attendent_id", attendantId);
    formData.append("status", status);

    apiService.post({
      url: `${API_MAIN_URL}/instant-connect-status`,
      headers: getAuthHeaders(),
      data: formData,
      onSuccess: (res) => {
        onSuccess?.(res);
      },
      onFailure,
    });
  };

export const updateFreeConnectMeetingStatus =
  ({ meetingId, status, onSuccess, onFailure }) =>
  async () => {
    const formData = new FormData();

    formData.append("meeting_id", meetingId);
    formData.append("status", status);

    apiService.post({
      url: `${API_MAIN_URL}/free-connect-status`,
      headers: getAuthHeaders(),
      data: formData,
      onSuccess: (res) => {
        onSuccess?.(res);
      },
      onFailure: ({ error }) => onFailure?.(error),
    });
  };

/**
 * Fetch Tutor Upcoming Sessions
 * @param {Object} params
 * @param {number} [params.limit]
 * @param {number} [params.page]
 * @param {string} [ params.startDate]
 * @param {function} [params.onSuccess]
 * @param {function} [params.onFailure]
 */
export const fetchTutorUpcomingSession =
  ({ limit, page, onSuccess, onFailure }) =>
  async (dispatch) => {
    const data = [];
    if (limit) data.push({ key: "limit", value: limit });
    if (page) data.push({ key: "page", value: page });

    apiService.get({
      url: `${API_MAIN_URL}/tutor/upcoming-sessions-list`,
      headers: getAuthHeaders(),
      data,
      onSuccess: (res) => {
        const { data, ...meta } = res;
        dispatch({
          type: "SET_TUTOR_UPCOMING_SESSIONS",
          payload: { sessions: res.data, meta },
        });
        // console.log(data);
        onSuccess?.(res.data);
      },
      onFailure: ({ error }) => {
        dispatch({
          type: "ERRED_TUTOR_UPCOMING_SESSIONS",
        });

        onFailure?.(error);
      },
    });
  };

/**
 *
 * @param {Object} params
 * @param {number} params.page
 * @param {number} params.limit
 * @param {(res) => void} [params.onSuccess]
 * @param {(err) => void} [params.onFailure]
 *
 */
export const fetchTutorPreviousSessions =
  ({ limit, page, onSuccess, onFailure }) =>
  async (dispatch) => {
    apiService.get({
      url: `${API_MAIN_URL}/tutor/previous-sessions`,
      data: [
        { key: "page", value: page },
        { key: "limit", value: limit },
      ],
      headers: getAuthHeaders(),
      onSuccess: (res) => {
        const { message, status, active, ...payload } = res;
        dispatch({
          type: "FETCHED_TUTOR_PREVIOUS_SESSIONS",
          payload: { ...payload },
        });

        onSuccess?.(res);
      },
      onFailure: (error) => {
        dispatch({ type: "ERRED_TUTOR_PREVIOUS_SESSIONS" });

        onFailure?.(error);
      },
    });
  };

/**
 * Update Session Info
 * @param {Object} params
 * @param {string | number} params.id
 * @param {{agenda?: string, description: string}} params.data
 *
 * @param {function} [params.onSuccess]
 * @param {function} [params.onFailure]
 */

export const updateSessionInfo =
  ({ id, data, onSuccess, onFailure }) =>
  async (dispatch) => {
    const { agenda, description } = data;

    const formData = new FormData();
    formData.append("request_id", `${id}`);
    if (agenda) formData.append("agenda", agenda);
    if (description) formData.append("description", description);

    apiService.post({
      url: `${API_MAIN_URL}/learner/sessions/update-requests`,
      data: formData,
      headers: getAuthHeaders(),
      onSuccess: (res) => {
        onSuccess?.(res);
      },
      onFailure: (error) => {
        onFailure?.(error);
      },
    });
  };

/**
 *
 * @param {Object} params
 * @param {number} params.page
 * @param {number} params.limit
 * @param {(res) => void} [params.onSuccess]
 * @param {(err) => void} [params.onFailure]
 *
 */
export const fetchTutorMissedSessions =
  ({ limit, page, onSuccess, onFailure }) =>
  async (dispatch) => {
    apiService.get({
      url: `${API_MAIN_URL}/tutor/previous-sessions`,
      data: [
        { key: "session_type", value: "missed" },
        { key: "page", value: page },
        { key: "limit", value: limit },
      ],
      headers: getAuthHeaders(),
      onSuccess: (res) => {
        const { message, status, active, ...payload } = res;
        dispatch({
          type: "FETCHED_TUTOR_MISSED_SESSIONS",
          payload: { ...payload },
        });

        onSuccess?.(res);
      },
      onFailure: (error) => {
        dispatch({ type: "ERRED_TUTOR_MISSED_SESSIONS" });

        onFailure?.(error);
      },
    });
  };
