/* eslint-disable eqeqeq */
import React, {useState, useEffect} from "react";
import {Button, Col, Row, Label, Input, Container} from "reactstrap";
// import User from "assets/images/blog/user.png";
import SimpleReactValidator from "simple-react-validator";
import {TextField, Loader, DatePicker} from "components";
import {connect} from "react-redux";
import {storeUser} from "store/learnerdashboard/action";
import {get} from "lodash";
import PhoneInput from "react-phone-input-2";
import templateAvatar from "assets/images/person.svg";
import TimezoneSelect from "react-timezone-select";
import {
  MAX_LIMIT_NAME,
  MAX_LIMIT_TITLES,
  MAX_LIMIT_DESCRIPTIONS,
} from "constants";
import ToastFactory from "utils/toast-factory";
import {messages, requirements} from "constants/strings";
import {DUMMIES_MONTHS_NUMERIC} from "constants";
import * as moment from "moment";
import {Outlet} from "react-router-dom";
import {RawPhoneNumberContext} from "context/phone-number";
import ProfilePictureForm from "modules/forms/form-profile-picture/form-profile-picture";
import {ONE_MB} from "constants";
import {getMobileWithoutCode} from "utils";
import {useRemoteProfile} from "hooks/profile";

const validator = new SimpleReactValidator();

// validator.rules["lettersSpacesAndFullStop"] = {
//   message: "The :attribute may only contain letters, spaces, and a full stop.",
//   rule: /^[A-Za-z\s.]+$/,
// };

/**
 * @author Abdullah-Sajjad026
 */
const LearnerProfileGeneralView = ({storeUser, userProfile, loading}) => {
  // const navigate = useNavigate();

  // const {profile, updateProfile} = useRemoteProfile();
  // const userProfile = profile;

  const toastFactory = ToastFactory();
  const {state: phoneNumber, setState: setPhoneNumber} = React.useContext(
    RawPhoneNumberContext
  );

  const myName = get(userProfile, "name", "");
  const mobile =
    phoneNumber.mobile_number || get(userProfile, "mobile_number", "");
  const countryCode =
    phoneNumber.country_code || get(userProfile, "country_code", "");
  const userEmail = get(userProfile, "email", "");
  const subType = get(userProfile, "sub_type", "");
  const profilePic = get(userProfile, "profile_picture", "");
  const dateofbirth = get(userProfile, "dob", "");
  const storedTimeZone = get(userProfile, "timezone", "");
  //check user profile what it returns if created by parent
  const userType = get(userProfile, "user_type", "");

  const [formData, setFormData] = useState({
    name: myName,
    last_name: myName[1],
    email: userEmail,
    mobile_number: mobile,
    country_code: countryCode,
    profile_pic: profilePic,
    dob: dateofbirth,
    address_firstline: userProfile.address_line_1,
    address_secondline: userProfile.address_line_2,
    city: userProfile.city,
    state: userProfile.state,
    country: userProfile.country,
    pincode: userProfile.pincode,
    sub_type: subType,
    user_type: userType,
    timezone:
      storedTimeZone || Intl.DateTimeFormat().resolvedOptions().timeZone,
  });

  // DatePicker States
  const [selectedDay, setSelectedDay] = useState({
    label: dateofbirth.split("-")[2],
    value: dateofbirth.split("-")[2],
  });
  const [selectedMonth, setSelectedMonth] = useState(
    DUMMIES_MONTHS_NUMERIC.filter(
      (m) => m.value === parseInt(dateofbirth.split("-")[1])
    )[0]
  );
  const [selectedYear, setSelectedYear] = useState({
    label: dateofbirth.split("-")[0],
    value: dateofbirth.split("-")[0],
  });
  const [isValidDate, setIsValidDate] = useState(undefined);
  const selectedDate = `${selectedYear.value}-${(
    "0" + selectedMonth.value
  ).slice(-2)}-${("0" + selectedDay.value).slice(-2)}`;
  // ----

  const [, forceUpdate] = useState();

  useEffect(() => {
    document.addEventListener("keydown", function (event) {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        if (document.getElementById("registerBtn")) {
          document.getElementById("registerBtn").click();
        }
      }
    });
  }, []);

  const onChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };

  const {
    name,
    email,
    profile_pic,
    mobile_number,
    country_code,
    address_firstline,
    address_secondline,
    city,
    country,
    pincode,
    state,
    sub_type,
    timezone,
  } = formData;

  const handleSubmit = () => {
    if (validator.allValid()) {
      if (!formData.profile_pic) {
        toastFactory.error(requirements.INIT("Profile picture"));
        return;
      }

      if (!formData.name.match(/^[a-zA-Z .]+$/)) {
        toastFactory.error(
          "The name field can only contain English letters, spaces, and periods"
        );
        return;
      }

      if (isValidDate === false) {
        toastFactory.error(messages.INVALID("Date of birth"));
        return;
      }

      toastFactory.loading();

      // updateProfile({
      //   profile: {
      //     ...formData,
      //     timezone: timezone.value || timezone,
      //     dob: `${selectedYear.value}-${("0" + selectedMonth.value).slice(
      //       -2
      //     )}-${("0" + selectedDay.value).slice(-2)}`,
      //   },
      //   onSuccess: ({data}) => {
      //     toastFactory.success();
      //   },
      //   onFailure: (err) => {
      //     console.log({err});

      //     if (typeof err.message === "object") {
      //       // error 422 has been handeled in the action itself
      //       if (err.error_code != 422) {
      //         toastFactory.error(
      //           `There was an error with the ${
      //             Object.keys(err.message)[0]
      //           } value`
      //         );
      //       }
      //     } else {
      //       toastFactory.error(err.message);
      //     }
      //   },
      // });

      storeUser(
        {
          ...formData,
          timezone: timezone.value || timezone,
          dob: `${selectedYear.value}-${("0" + selectedMonth.value).slice(
            -2
          )}-${("0" + selectedDay.value).slice(-2)}`,
        },
        function onSuccess(res) {
          toastFactory.success();
        },
        function onFailure(err) {
          if (typeof err.message === "object") {
            // error 422 has been handled in the action itself
            if (err.error_code != 422) {
              toastFactory.error(
                `There was an error with the ${
                  Object.keys(err.message)[0]
                } value`
              );
            }
          } else {
            toastFactory.error(err.message);
          }
        }
      );
    } else {
      validator.showMessages();
      forceUpdate(1);
      return false;
    }
  };

  // Set phone number in the context.
  useEffect(() => {
    setPhoneNumber({
      country_code,
      mobile_number,
    });
  }, [country_code, mobile_number, setPhoneNumber]);

  // function onClickPhoneEditListener() {
  //   navigate("mobile");
  // }

  /**
   * Value to be consumed by the profile picture form.
   */
  const profilePictureValue =
    profile_pic instanceof File ? profile_pic : undefined;

  /**
   * Value to be consumed by the profile picture form.
   */
  const profilePictureInitialValue =
    typeof profilePic === "string" ? profilePic : templateAvatar;

  if (loading) {
    return <Loader />;
  }

  // console.log({profile});

  return (
    <div className="general">
      <Container fluid>
        <form className="general-personal">
          <h1 className="general-title py-3">Personal Information</h1>
          <Row>
            <Col xs={6} sm={4} md={3} xl={2} className="order-md-1">
              <ProfilePictureForm
                maxSize={ONE_MB}
                value={profilePictureValue}
                initialValue={profilePictureInitialValue}
                allowedFormats={["image/jpg", "image/jpeg", "image/png"]}
                onChange={(file) =>
                  setFormData({
                    ...formData,
                    profile_pic: file,
                  })
                }
              />
            </Col>
            <Col md="9" xl="10" className="mt-3">
              <Row>
                <Col md="12" sm="12" xs="12">
                  <label for="" className="fName float-start">
                    Full Name
                  </label>
                  <TextField
                    placeholder="Full Name"
                    onChange={(e) => onChange(e)}
                    name="name"
                    value={name}
                    className="user-account-input"
                    maxLength={MAX_LIMIT_NAME}
                  />
                  {validator.message("name", name, "required")}
                </Col>
                {/* <Col md="6" sm="12" xs="12">
                  <label for="" className="fName float-start">
                    Last name
                  </label>
                  <TextField
                    placeholder="Last Name"
                    value={last_name}
                    name="last_name"
                    onChange={(e) => onChange(e)}
                  />
                </Col> */}
                <Col md="12" sm="12" xs="12" className="mt-2">
                  <label for="" className="fName float-start">
                    Email Address
                  </label>
                  <TextField
                    placeholder="Email Address"
                    value={email}
                    name="email"
                    onChange={(e) => onChange(e)}
                    disabled
                    className="user-account-input"
                    maxLength={MAX_LIMIT_TITLES}
                  />
                  {validator.message("email", email, "required|email")}
                </Col>
                <Col md="6" sm="12" xs="12" className="mt-2">
                  <label for="">Mobile Number</label>
                  {/* This field has been disabled. */}
                  <div
                    role="group"
                    aria-label="Mobile number input group"
                    className="d-flex align-items-center rounded"
                  >
                    <PhoneInput
                      // disabled
                      country="ae"
                      id="inputPhone"
                      name="mobile_number"
                      value={
                        phoneNumber.country_code +
                        `${phoneNumber.mobile_number}`
                      }
                      inputClass="w-100 bg-gray-300 rounded-0"
                      onChange={(value, {dialCode}) => {
                        setFormData((prev) => ({
                          ...prev,
                          mobile_number: getMobileWithoutCode(value, dialCode),
                          country_code: dialCode,
                        }));
                      }}
                      onBlur={() => {
                        validator.current.showMessageFor("phone");
                        forceUpdate(1);
                      }}
                    />
                    {/* <IconButton
                      title="Edit"
                      className="align-self-stretch rounded-0"
                      onClick={onClickPhoneEditListener}
                    >
                      <IconPencil size={18} />
                    </IconButton> */}
                  </div>
                  {validator.message("phone", mobile_number, "required")}
                </Col>
                <Col md="6" sm="12" xs="12" className="mt-2">
                  <label for="" className="fName d-block">
                    Date of birth
                  </label>
                  {/* <TextField
                    placeholder=""
                    value={dob}
                    name="dob"
                    onChange={(e) => onChange(e)}
                    type={"date"}
                    className="user-account-input"
                  /> */}
                  <DatePicker
                    max={moment().subtract(1, "days")}
                    selectedDate={selectedDate}
                    isValidSelection={isValidDate}
                    setValidation={setIsValidDate}
                  >
                    {[
                      <DatePicker.Day
                        id="inputDob"
                        value={selectedDay}
                        className="flex-grow-1"
                        onChange={(val) => setSelectedDay(val)}
                      />,
                      <DatePicker.Month
                        value={selectedMonth}
                        className="flex-grow-1"
                        onChange={(val) => setSelectedMonth(val)}
                      />,
                      <DatePicker.Year
                        value={selectedYear}
                        className="flex-grow-1"
                        onChange={(val) => setSelectedYear(val)}
                      />,
                    ]}
                  </DatePicker>
                </Col>
                <Col xs="12">
                  <label htmlFor="" className="tutor-profile-setup-text mt-2">
                    Time Zone
                  </label>
                  <TimezoneSelect
                    placeholder="Select your Time Zone"
                    value={timezone}
                    onChange={(e) =>
                      onChange({
                        target: {
                          name: "timezone",
                          value: e,
                        },
                      })
                    }
                    className="timezone-select"
                  />
                </Col>

                <div className="text-left">
                  <h2 className="general-radiohead">Who are you?</h2>
                </div>
                <Col
                  className="text-left mt-2 text-success"
                  md="12"
                  sm="12"
                  xs="12"
                >
                  <Label htmlFor="learner-radio">
                    <Input
                      id="learner-radio"
                      type="radio"
                      className="radio general-personal-radio"
                      name="sub_type"
                      checked={sub_type == 1}
                      value={1}
                      onChange={(e) => onChange(e)}
                    />{" "}
                    <span style={{color: "#05386B"}}>Learner</span>
                  </Label>

                  <Label htmlFor="parent-radio" className="ms-3">
                    <Input
                      id="parent-radio"
                      type="radio"
                      name="sub_type"
                      checked={sub_type == 2}
                      onChange={(e) => onChange(e)}
                      value={2}
                      className="radio general-personal-radio"
                    />{" "}
                    <span style={{color: "#05386B"}}>Parent</span>
                  </Label>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <div className="general-info1">
              <h1 className="general-title">Address Information</h1>
            </div>
            <Col md="9" lg="10" xl="8" className="mt-2">
              <Row>
                <Col md="12" sm="12" xs="12">
                  <label for="" className="fName float-start">
                    Address first line
                  </label>
                  <TextField
                    placeholder="Address First Line"
                    value={
                      address_firstline === "null" ? "" : address_firstline
                    }
                    name="address_firstline"
                    onChange={(e) => onChange(e)}
                    className="user-account-input"
                    maxLength={MAX_LIMIT_DESCRIPTIONS}
                  />
                </Col>
                <Col md="12" sm="12" xs="12" className="mt-2">
                  <label for="" className="fName float-start">
                    Address second line
                  </label>
                  <TextField
                    placeholder="Address Second Line"
                    value={
                      address_secondline === "null" ? "" : address_secondline
                    }
                    name="address_secondline"
                    onChange={(e) => onChange(e)}
                    className="user-account-input"
                    maxLength={MAX_LIMIT_DESCRIPTIONS}
                  />
                </Col>
                <Col md="6" sm="12" xs="12" className="mt-2">
                  <label for="" className="fName float-start">
                    City
                  </label>
                  <TextField
                    placeholder="Enter your city"
                    value={city === "null" ? "" : city}
                    name="city"
                    onChange={(e) => onChange(e)}
                    className="user-account-input"
                    maxLength={MAX_LIMIT_TITLES}
                  />
                </Col>
                <Col md="6" sm="12" xs="12" className="mt-2">
                  <label for="" className="fName float-start">
                    State
                  </label>
                  <TextField
                    placeholder="Enter your state"
                    value={state === "null" ? "" : state}
                    name="state"
                    onChange={(e) => onChange(e)}
                    className="user-account-input"
                    maxLength={MAX_LIMIT_TITLES}
                  />
                </Col>
                <Col md="6" sm="12" xs="12" className="mt-2">
                  <label for="" className="fName float-start">
                    Country
                  </label>
                  <TextField
                    placeholder="Enter your Country"
                    value={country === "null" ? "" : country}
                    name="country"
                    onChange={(e) => onChange(e)}
                    className="user-account-input"
                  />
                </Col>
                <Col md="6" sm="12" xs="12" className="mt-2">
                  <label for="" className="fName float-start">
                    Pincode/Postcode/Zip Code
                  </label>
                  <TextField
                    placeholder="Enter your Pincode/Postcode/Zip Code"
                    value={pincode === "null" ? "" : pincode}
                    name="pincode"
                    onChange={(e) => onChange(e)}
                    className="user-account-input"
                  />
                </Col>
                <Col className="mt-2 general-btn">
                  {/* <Button
                    style={{
                      background: "#ffffff",
                      border: "2px solid #05386B",
                    }}
                    type="button"
                    className="text-dark my-2 cancel-button"
                  >
                    <span style={{color: "#05386B"}}>Cancel</span>
                  </Button> */}
                  <Button
                    type="button"
                    className="text-dark my-2 continue-button"
                    onClick={() => handleSubmit()}
                  >
                    <span style={{color: "#ffffff"}}>Save</span>
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </form>
      </Container>
      <Outlet />
    </div>
  );
};

LearnerProfileGeneralView.propTypes = {};

const mapToStateProps = (state) => ({
  userProfile: state.learnerDashboard.userInfo.user,
  loading: state.learnerDashboard.userInfo.loading,
  userPersonal: state.auth.userPersonal,
});

export default connect(mapToStateProps, {storeUser})(LearnerProfileGeneralView);
