import thunk from "redux-thunk";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import learningMaterialReducer from "./store-learning-material-reducer";
import { learningMaterialProgressReducer } from "./store-learning-material-progress";

export default configureStore({
  middleware: [thunk],
  reducer: combineReducers({
    entries: learningMaterialReducer,
    progress: learningMaterialProgressReducer,
  }),
});
