import React from "react";
import Select from "react-select";
import type { StateManagerProps } from "react-select/dist/declarations/src/useStateManager";

export type ComboboxOption = {
  label: number | string;
  value: number | string;
  placeholder?: string;
};

export type ComboboxClickHandler = (option: ComboboxOption) => void;

export type ComboboxProps = Omit<
  StateManagerProps,
  "value" | "options" | "onChange"
> & {
  value?: ComboboxOption;
  options: ComboboxOption[];
  onChange: ComboboxClickHandler;
};

export default function Combobox(props: ComboboxProps) {
  return <Select {...props} data-testclass="combobox" />;
}
