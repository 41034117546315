/* eslint-disable eqeqeq */
import * as React from "react";
import ReactSelect from "react-select";
import CreatableSelect from "react-select/creatable";
import makeAnimated from "react-select/animated";
import IToolTip from "./itooltip";
import { SassySelectProps, SelectOption } from "constants/";

/**
 * A react-select based select component.
 * Introduces defaults and some fixes such as
 * the options taking half the length of the parent.

 * @version 0.0.1
 * @author Abdullah-Sajjad026
 *
 * @changelog
 * - 0.0.3: Added the `closeMenuOnSelect` prop.
 * - 0.0.2: Made `label` optional.
 * - 0.0.1: Initial version.
 *
 */

function SassySelect({
  isMulti = true,
  options = [],
  label,
  onChange,
  subTitle,
  description,
  selectedOptions,
  creatable = false,
  maxSelections = 100,
  disabled = false,
  closeMenuOnSelect = false,
  className = "",
  hasSuggestions = false,
  selectedSuggestionClassName = "suggested-link-selected bg-white ",
  suggestionClassName = "suggested-link",
  maxSuggestions = 5,
  specificSuggestionsIds = [],
  ...props
}: SassySelectProps) {
  const Component = creatable ? CreatableSelect : ReactSelect;

  const suggestions = hasSuggestions
    ? specificSuggestionsIds.length > 0
      ? options
          .filter((o) => specificSuggestionsIds.includes(parseInt(o.value)))
          .slice(0, maxSuggestions)
      : options.slice(0, maxSuggestions)
    : [];

  return (
    <div className={`templating-select select2-container ${className}`}>
      {label && (
        <label className="text-blue">
          {label}{" "}
          {subTitle && <span className="fs-8 text-blue-300">{subTitle}</span>}
          {Array.isArray(selectedOptions) &&
            selectedOptions.length >= maxSelections && (
              <IToolTip
                position="top-right"
                content="You've reached maximum selections."
              />
            )}
        </label>
      )}
      <Component
        {...props}
        data-testclass="sassySelect"
        isMulti={isMulti}
        options={options}
        isClearable={false}
        onChange={onChange}
        isDisabled={disabled}
        value={selectedOptions}
        components={makeAnimated()}
        classNamePrefix="select2-selection"
        closeMenuOnSelect={closeMenuOnSelect}
        isOptionDisabled={() =>
          Array.isArray(selectedOptions) &&
          selectedOptions.length >= maxSelections
        }
      />
      {hasSuggestions && (
        <div className="mt-1">
          <span className="pSubject">Suggested: </span>
          {suggestions.map((suggestion, index) => {
            const isSelectedSuggestion = Array.isArray(selectedOptions)
              ? selectedOptions.find(
                  // loose quality checking purposefully
                  (o) => o.value == suggestion.value
                )
              : selectedOptions.value == suggestion.value;
            const isMaxReached =
              Array.isArray(selectedOptions) &&
              selectedOptions.length >= maxSelections;

            return (
              <button
                key={index}
                type="button"
                className={`b-1 font-size-12 ${suggestionClassName}  ${
                  isSelectedSuggestion
                    ? selectedSuggestionClassName
                    : isMaxReached
                    ? "text-decoration-line-through text-disabled"
                    : ""
                }`}
                onClick={() => {
                  if (isSelectedSuggestion) {
                    if (isMulti) {
                      onChange(
                        [...(selectedOptions as SelectOption[])].filter(
                          // loose quality checking purposefully
                          (o) => o.value != suggestion.value
                        )
                      );
                    } else {
                      onChange("" as unknown as SelectOption);
                    }
                  } else {
                    if (isMaxReached) return;
                    else {
                      if (isMulti)
                        onChange([
                          ...(selectedOptions as SelectOption[]),
                          suggestion,
                        ]);
                      else onChange(suggestion);
                    }
                  }
                }}
                disabled={isMaxReached && !isSelectedSuggestion}
              >
                {suggestion.label}
                {/* adding comma after each suggestion except for last one */}
                {suggestions.length - 1 === index ? "" : ","}&nbsp;
              </button>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default SassySelect;
