/* eslint-disable eqeqeq */

export type FormCreateMaterialState = {
  title: string;
  topic: string;
  description: string;
  grades: number[];
  subjects: number[];
  curriculums: number[];
  materialTypes: number[];
  tags: string[];
  seoTags: string[];
  hasQuiz: boolean;
  hasKey: boolean;
  hasCopyright: boolean;
  price: number;
  discount: number;
  totalPrice: number;
  thumbnail: File | string | null;
  preview: File | string | null;
  video: File | string | undefined;
};

export const INITIAL_STATE: FormCreateMaterialState = {
  title: "",
  topic: "",
  description: "",
  grades: [],
  subjects: [],
  curriculums: [],
  materialTypes: [],
  tags: [],
  seoTags: [],
  hasQuiz: false,
  hasKey: false,
  hasCopyright: true,
  thumbnail: null,
  preview: null,
  video: undefined,
  price: 0,
  discount: 0,
  totalPrice: 0,
};

/**
 * Deserialize the API's response tags into the form's state.
 * @param tags The tags array from the API's response.
 * @returns
 */
function getDeserializedTags(tags: { title: string; [key: string]: any }[]) {
  if (!Array.isArray(tags)) return [];

  return tags.map((value) => value.title);
}

/**
 * Deserialize an array of objects into a simple array of numerics ids, as required by the form.
 */
function getDeserializedIds(
  array: { [key: string]: number | null }[],
  key: string
): number[] {
  if (!Array.isArray(array)) return [];

  return array.map((value) => value[key] ?? 0);
}

// TODO: add RawLearningMaterial type for the state
/**
 *  Deserialize the API's response into the form's state.
 */
function getDeserialized(state: LearningMaterial): FormCreateMaterialState {
  /*
  API's response to be adapted.
  {
    "id": 96,
    "user_id": 416,
    "title": "Lorem Ipsum dolor set amet",
    "description": "lorem ipsum dolor set amet",
    "quiz_or_questions": 1,
    "answers_key": 0,
    "copyright": 1,
    "created_at_": "2023-07-07 08:03:35",
    "product_thumbnail": "https://d17thj9kqp1mkn.cloudfront.net/development_services/416/e12302fe6b2d3f5d9e66439f5aed2aae.jpg",
    "product_preview": "https://d17thj9kqp1mkn.cloudfront.net/development_services/416/3f924ee70c66ed0ff1c29714046c933d.jpg",
    "created_at": "2023-07-07T08:03:35.000000Z",
    "updated_at": "2023-07-07T10:53:00.000000Z",
    "published": 0,
    "content_type": 0,
    "views": 0,
    "parent_id": 96,
    "version": 0,
    "slug": "lorem-ipsum-dolor-set-amet",
    "status": 1,
    "popular": 0,
    "trending": 0,
    "product_video": null,
    "curriculums": [
        {
            "id": 180,
            "content_id": 96,
            "curriculum_id": 16,
            "created_at": "2023-07-07T08:03:35.000000Z",
            "updated_at": null
        },
        {
            "id": 181,
            "content_id": 96,
            "curriculum_id": 24,
            "created_at": "2023-07-07T08:03:35.000000Z",
            "updated_at": null
        }
    ],
    "subjects": [
        {
            "id": 154,
            "content_id": 96,
            "subject_id": 19,
            "created_at": "2023-07-07T08:03:36.000000Z",
            "updated_at": null
        },
        {
            "id": 155,
            "content_id": 96,
            "subject_id": 202,
            "created_at": "2023-07-07T08:03:36.000000Z",
            "updated_at": null
        }
    ],
    "topics": [
        {
            "id": 288,
            "content_id": 96,
            "topic_id": 0,
            "created_at": "2023-07-07T10:53:00.000000Z",
            "updated_at": null,
            "title": "frontend development"
        }
    ],
    "grades": [
        {
            "id": 157,
            "content_id": 96,
            "grade_id": 1,
            "created_at": "2023-07-07T08:03:36.000000Z",
            "updated_at": null
        },
        {
            "id": 158,
            "content_id": 96,
            "grade_id": 5,
            "created_at": "2023-07-07T08:03:36.000000Z",
            "updated_at": null
        }
    ],
    "price": 7,
    "types": [
        {
            "id": 115,
            "content_id": 96,
            "type_id": 6,
            "created_at": "2023-07-07T08:03:36.000000Z",
            "updated_at": null
        }
    ],
    "tags": [
        {
            "id": 423,
            "content_id": 96,
            "title": "Ipsum",
            "created_at": "2023-07-07T10:53:00.000000Z",
            "updated_at": null
        }
    ],
    "seo_tags": [
        {
            "id": 356,
            "content_id": 96,
            "title": "Ipsum",
            "created_at": "2023-07-07T10:53:00.000000Z",
            "updated_at": null
        }
    ],
    "pricing_plans": [
        {
            "id": 138,
            "lm_id": 96,
            "plan_id": 1,
            "price": 7,
            "discount": 0,
            "is_percentage": 1,
            "features": null,
            "created_at": "2023-07-07 10:53:00",
            "updated_at": "2023-07-07 10:53:00",
            "actual_price": 7
        }
    ]
  }
  
  */

  return {
    title: state.title || INITIAL_STATE.title,
    description: state.description || INITIAL_STATE.description,
    topic: Array.isArray(state.topics)
      ? state.topics?.[0]?.title
      : INITIAL_STATE.topic,
    grades: getDeserializedIds(state.grades, "grade_id"),
    subjects: getDeserializedIds(state.subjects, "subject_id"),
    curriculums: getDeserializedIds(state.curriculums, "curriculum_id"),
    materialTypes: getDeserializedIds(state.types, "type_id"),
    tags: getDeserializedTags(state.tags),
    seoTags: getDeserializedTags(state.seo_tags),
    hasQuiz: state.quiz_or_questions
      ? state.quiz_or_questions == 1
      : INITIAL_STATE.hasQuiz,
    hasKey: state.answers_key ? state.answers_key == 1 : INITIAL_STATE.hasKey,
    hasCopyright:
      state.copyright != undefined
        ? state.copyright == 1
        : INITIAL_STATE.hasCopyright,
    thumbnail: state.product_thumbnail || INITIAL_STATE.thumbnail,
    preview: state.product_preview || INITIAL_STATE.preview,
    video: state.product_video || INITIAL_STATE.video,
    price: state.pricing_plans?.[0]?.actual_price || INITIAL_STATE.price,
    discount: state.pricing_plans?.[0]?.discount || INITIAL_STATE.discount,
    totalPrice: state.pricing_plans?.[0]?.price || INITIAL_STATE.totalPrice,
  };
}

export default getDeserialized;
