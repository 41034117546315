/* eslint-disable eqeqeq */
import { Label } from "..";
import { Row, Col } from "reactstrap";
import { getAdjustedTime } from "utils";
import NotificationAction from "./notification-action";
import NotificationAvatar from "./notification-avatar";
import NotificationDetails from "./notification-details";

/**
 * A compact variant of the notification for the dropdown.
 *
 * @param {import("./notification-compact/notification-compact").NotificationCompactProps & import("constants/types/types-notifications").Notification & import("constants/types/types-notifications").NotificationEventListeners} object
 *
 * @returns {JSX.Element}
 *
 * @version 0.0.2
 * @author kashan-ahmad
 *
 * @changelog
 * - 0.0.4: Removed onConfirm, onCancel, onReschedule. If these handlers were passed to props then shall be passed into notification action directly.
 * - 0.0.3: Added the `NotificationAvatar` component.
 * - 0.0.2: Added the `onClick`, `onConfirm`, `onCancel`,
 * and `onReschedule` properties.
 * - 0.0.1: Initial version.
 */
function NotificationDefault({
  seen,
  onClick,
  grade_id,
  subject_id,
  curriculum_id,
  created_at,
  description,
  timeline,
  profile_picture,
  notificationType,
  ...props
}) {
  return (
    <Row
      onClick={(e) => onClick?.(e)}
      className={`hover:bg-gray-300 align-items-center px-4 py-3 gx-2 ${
        seen == 0 ? "bg-gray-300 cursor-pointer" : ""
      }`}
    >
      <Col xs={4} lg={3} xxl={2} className="d-flex align-items-center gap-3">
        <NotificationAvatar {...{ notificationType, profile_picture }} />
        <div className="d-flex flex-column gap-2">
          {
            <span className="text-muted font-size-12">
              {timeline ?? getAdjustedTime(created_at).fromNow()}
            </span>
          }
          <Label
            color={notificationType?.color || "primary"}
            className="font-size-12"
          >
            {notificationType?.label}
          </Label>
        </div>
      </Col>
      <Col xs={8} lg={9} xxl={10}>
        <div className="d-flex flex-column flex-md-row gap-2 align-items-md-center">
          <div className="d-flex flex-column gap-1">
            <p className={`m-0 ${seen == 0 ? "fw-bold" : ""}`}>{description}</p>
            <NotificationDetails
              gradeIds={grade_id}
              subjectId={subject_id}
              curriculumIds={curriculum_id}
            />
          </div>
          <div className="flex-grow-1 text-end">
            {notificationType?.action && (
              <NotificationAction
                {...props}
                action={notificationType.action}
                className="justify-content-end"
              />
            )}
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default NotificationDefault;
