import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { StaticField } from "components/static-field";
import RatingField from "components/forms/rating-field";
import FormErrorMessage from "components/error-message-form";
import { boxStyles, ratingStarStyles } from "constants/config";

// Types.
import type { FormikProps } from "formik";
import type { SkillsFormState } from "./skills-form-types";
import { useRemoteSkills } from "hooks/tutor/skills";

export default function SkillsFormView({
  values,
  errors,
  touched,
  setFieldValue,
  setFieldTouched,
}: FormikProps<SkillsFormState>) {
  const { skills } = useRemoteSkills();

  return (
    <Row className={boxStyles.g}>
      <Col xs={12} md={6}>
        <Form.Group>
          <StaticField
            id="skills"
            staticKey="skills"
            value={values.skill}
            onBlur={() => setFieldTouched("skill", true)}
            onChange={(value) => setFieldValue("skill", value)}
            excludedValues={skills.map(({ skill }) => parseInt(`${skill}`))}
          />
          {touched.skill && errors.skill?.value && (
            <Form.Text className="text-muted">
              <FormErrorMessage message={errors.skill.value as string} />
            </Form.Text>
          )}
        </Form.Group>
      </Col>
      <Col xs={12} md={6}>
        <Form.Group>
          <RatingField
            value={values.rating}
            onBlur={() => setFieldTouched("rating", true)}
            onChange={(rating: number) => setFieldValue("rating", rating)}
          />
          {touched.rating && errors.rating && (
            <Form.Text className="text-muted">
              <FormErrorMessage message={errors.rating as string} />
            </Form.Text>
          )}
        </Form.Group>
      </Col>
    </Row>
  );
}
