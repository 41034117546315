import { IconHeart } from "@tabler/icons";
import { Button, Card, CardBody } from "reactstrap";

function BestSellingProductCardPlaceholder() {
  return (
    <Card aria-hidden={true} className="mb-0 border-1 rounded overflow-hidden">
      <div className="w-100 glow placeholder placeholder-wave h-3 maxh-3 thumbnail-wrapper"></div>
      <CardBody className="vstack gap-3">
        <div className="w-75 placeholder placeholder-sm placeholder-wave bg-secondary" />
        <div className="w-50 bg-gray-400 placeholder" />
        <div className="hstack align-items-center justify-content-between">
          <div className="vstack gap-2">
            <div className="p-3 w-50 mt-2 bg-dark placeholder placeholder-wave" />
            <div className="w-50 bg-gray-400 placeholder" />
          </div>
          <div className="w-50 bg-success placeholder" />
        </div>
        <div className="w-50 mt-2 hstack gap-2">
          <span className="w-100 bg-gray-500 placeholder" />
          <span className="w-100 bg-gray-500 placeholder" />
          <span className="w-100 bg-gray-500 placeholder" />
        </div>
        <div className="hstack gap-2 mt-2">
          <Button outline color="secondary">
            <IconHeart />
          </Button>
          <Button className="w-100 bg-secondary placeholder placeholder-glow" />
        </div>
      </CardBody>
    </Card>
  );
}

export default BestSellingProductCardPlaceholder;
