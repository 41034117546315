import { Alert } from "reactstrap";

// Static.
import styles from "./product-stats-card.module.scss";

/**
 * @typedef {Object} ProductStatsCardProps
 *
 * @property {string} tag Default="div"
 * @property {string} title
 * @property {string} amount
 * @property {string} caption
 * @property {string} secondaryAmount
 * @property {string} secondaryCaption
 * @property {string} color Default="primary"
 * @property {string} hasProduct Default=true
 * @property {string} message Default="No stats to show"
 */

/**
 * @param {import("reactstrap").AlertProps & ProductStatsCardProps} props
 *
 * @returns {JSX.Element}
 *
 * @author Abdullah-Sajjad026
 * @version 0.0.1
 */
function ProductStatsCard({
  tag = "div",
  title,
  amount,
  caption,
  secondaryAmount,
  secondaryCaption,
  color = "primary",
  className = "",
  hasProduct = true,
  message = "No stats to show",
  ...props
}) {
  return (
    <Alert
      {...props}
      {...{ tag, color }}
      className={`text-secondary w-100 p-2 rounded-3 mb-0 d-flex align-items-center ${
        {
          primary: styles.primary,
          success: styles.success,
          danger: styles.danger,
        }[color] || styles.alert
      } ${className}`}
    >
      {hasProduct ? (
        <div className="d-flex justify-content-around justify-content-sm-center justify-content-md-around justify-content-lg-center justify-content-xl-around align-items-center gap-3 h-100 w-100">
          <div>
            <h3 className="fs-4  mb-1 fw-bolder">{amount}</h3>
            <p className="text-secondary text-wrap">{caption}</p>
          </div>
          <div className="text-end">
            <h3 className="fs-4 mb-1 fw-bolder">{secondaryAmount}</h3>
            <p className="text-secondary text-wrap">{secondaryCaption}</p>
          </div>
        </div>
      ) : (
        message
      )}
    </Alert>
  );
}

export default ProductStatsCard;
