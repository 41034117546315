import React from "react";
import { Button } from "react-bootstrap";
import { ImageModal } from "modules/modals/image";
import { RemoteProfile } from "constants/types/types-profile";
import useRemoteProfile from "hooks/profile/use-remote-profile";
import { ModalFooter, ModalHeader } from "modules/modals/components";
import useToast from "hooks/use-toast";
import { boxStyles } from "constants/config";
import { FormErrorMessage } from "components";
import { ToastSize } from "constants/types";

type Props = {
  /**
   * The key of the profile that will be updated.
   *
   * @example
   * ```tsx
   * // This will update the profile_picture of the tutor.
   * useTutorPhoto({ key: "profile_picture" });
   * ```
   */
  key: keyof RemoteProfile;

  /**
   * The maximum size of the photo.
   * If the photo exceeds the maximum size, an error will be shown.
   *
   * @example
   * ```tsx
   * // This will show an error if the photo exceeds 1MB.
   * useTutorPhoto({ maxSize: {
   *  label: "1MB",
   *  value: 1 * 1024 * 1024,
   * } });
   * ```
   */
  maxSize?: {
    label: string;
    value: number;
  };

  /**
   * The size of the modal.
   *
   * @default "md"
   */
  modalSize?: ToastSize;

  /**
   * The title of the photo's preview modal.
   */
  modalTitle: string;

  /**
   * The aspect ratio of the photo's preview inside modal.
   */
  modalAspectRatio: string;
};

export default function useTutorPhoto({
  key,
  maxSize,
  modalSize,
  modalTitle,
  modalAspectRatio,
}: Props) {
  // Hooks.
  const toast = useToast();
  const { updateProfile } = useRemoteProfile();

  // State.
  const [photo, setPhoto] = React.useState<File>();

  // Listener for the photo change.
  const onChangeListener = React.useCallback(
    (file?: File) => {
      if (!file) return;

      let error = "";

      // Check if the file exceeds the maximum size.
      if (maxSize && file.size > maxSize.value) {
        error = `The photo exceeds the maximum size of ${maxSize.label}.`;
      }

      /**
       * Listener for the change event inside the modal.
       */
      function onReChangeListener() {
        // We need to manually open the file dialog.
        // To do this, we create an input element and click it.
        const input = document.createElement("input");
        input.type = "file";
        input.accept = "image/*";

        // Listen for the change event.
        // We're basically recursively calling the current function.
        input.onchange = (e) => {
          const target = e.target as HTMLInputElement;
          if (!target.files) return;
          onChangeListener(target.files[0]);
        };

        // Click the input.
        input.click();

        // Once the input is clicked, our modal is no longer needed.
        toast.dismiss();
      }

      /**
       * Listener for the confirm event inside the modal.
       * This will update the photo.
       */
      function onConfirmListener() {
        if (error) return;

        // Close the modal.
        toast.dismiss();

        // Update the photo.
        toast.loading("Uploading...");

        updateProfile({
          profile: {
            [key]: file,
          },
          onFailure: ({ message }) => toast.error(message),
          onSuccess: () => {
            // Update the photo.
            setPhoto(file);

            // Show a success message.
            toast.success("Profile Photo Saved Successfully");
          },
        });
      }

      // Render a modal that will show the preview of the image.
      // The modal will have two buttons: change and confirm.
      // The change button will open the file dialog and allow the user to change the image.
      // The confirm button will update the photo.
      toast.render(
        <ImageModal
          src={URL.createObjectURL(file)}
          style={{
            width: "100%",
            objectFit: "cover",
            aspectRatio: modalAspectRatio,
          }}
          header={<ModalHeader>{modalTitle}</ModalHeader>}
          footer={
            <div className={`d-flex flex-column ${boxStyles.yGap}`}>
              {error && <FormErrorMessage message={error} />}
              <ModalFooter>
                <Button
                  variant="outline-secondary"
                  onClick={onReChangeListener}
                >
                  Change
                </Button>
                <Button
                  disabled={!!error}
                  variant="secondary"
                  onClick={onConfirmListener}
                >
                  Confirm
                </Button>
              </ModalFooter>
            </div>
          }
        />,
        {
          size: modalSize,
        }
      );
    },
    [
      key,
      toast,
      maxSize,
      modalSize,
      modalTitle,
      updateProfile,
      modalAspectRatio,
    ]
  );

  return {
    photo,
    setPhoto,
    onChangeListener,
  };
}
