/**
 * @type {PaymentHistoryState}
 */
const INITIAL_STATE = {
  data: [],
  total: 0,
  per_page: 0,
  current_page: 1,
  state: "loading",
};

/**
 * @param {PaymentHistoryState} state
 * @param {PaymentHistoryAction} action
 * @returns {PaymentHistoryState}
 */
const reducer = (state = INITIAL_STATE, action) =>
  ({
    SET_PAYMENT_HISTORY: {
      ...state,
      ...action.payload,
      data: action.payload?.data ?? [],
      state: "loaded",
    },
    ERRED_PAYMENT_HISTORY: {
      ...state,
      state: "erred",
    },
    LOADING_PAYMENT_HISTORY: {
      ...state,
      state: "loading",
    },
  }[action.type] || state);

export default reducer;
