import { BorderedCameraIcon, BorderedNotebookIcon } from "components";
import { DEFAULT_PROGRESS_VALUE } from "constants";
import React from "react";
import { Link } from "react-router-dom";
import { Progress } from "reactstrap";

const TaskItem = ({
  type = "material",
  userName = "Abdullah_Sajjad",
  userLink = "",
  taskProductName = "English",
  ctaLink = "",
  usedProgress = "5",
  remainingProgress = "2",
}) => {
  return (
    <div className="d-flex gap-3 pb-3">
      <div>
        {type === "session" ? (
          <BorderedCameraIcon size="lg" />
        ) : (
          <BorderedNotebookIcon size="lg" />
        )}
      </div>
      <div className="flex-grow-1 d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-md-between gap-3">
        <div className="task-info" style={{ width: "80%" }}>
          <p className="text-secondary fw-semi-bold font-size-14">
            {type === "session" ? (
              <>
                Book your next{" "}
                <span className="text-info">{taskProductName}</span> session
                with{" "}
                <a className="text-info" href={userLink}>
                  {userName}
                </a>
              </>
            ) : (
              <>
                Complete your learning material named{" "}
                <span className="text-info">
                  {taskProductName.substring(0, 30)} ...
                </span>
                {/* <a className="text-info" href={userLink}>
                  @{userName}
                </a> */}
              </>
            )}
          </p>
          <div className="d-flex flex-column flex-sm-row align-items-sm-center gap-2">
            <div>
              {usedProgress}
              <span className="text-muted">
                {type !== "session" ? "% completed" : " used"}
              </span>
            </div>
            <div className="flex-grow-1">
              <Progress
                color="success"
                className="bg-light border"
                value={
                  (usedProgress / (usedProgress + remainingProgress)) * 100 ||
                  DEFAULT_PROGRESS_VALUE
                }
              />
            </div>
            <div>
              {remainingProgress}
              <span className="text-muted">
                {type !== "session" ? "% " : " "}left
              </span>
            </div>
          </div>
        </div>
        <Link
          to={ctaLink}
          className="btn btn-outline-success ms-md-3 text-nowrap"
        >
          {type === "session" ? "Book Session" : "View Now"}
        </Link>
      </div>
    </div>
  );
};

export default TaskItem;
