/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable eqeqeq */
import type { BioFormState, RemoteBioFormState } from "./bio-form-types";

export default function useBioFormAdapter() {
  function serialize(state: BioFormState): RemoteBioFormState {
    return {
      bio: state.bio,
    };
  }

  function deserialize(remoteState: RemoteBioFormState): BioFormState {
    return {
      bio: remoteState.bio || "",
    };
  }

  return {
    serialize,
    deserialize,
  };
}
