import apiService from "service/api-service";
import { API_MAIN_URL } from "constants/env";
import { getAuthHeaders, getFormDataFromObject } from "utils";

// Types.
import type { Boolbacks } from "constants/types/types";
import { RawPhoneNumber } from "constants/types/types-phone-number";

export default class OtpService {
  verify({
    code,
    country_code,
    mobile_number,
    ...boolBacks
  }: {
    code: string;
  } & Boolbacks<any> &
    RawPhoneNumber) {
    apiService.post({
      headers: getAuthHeaders(),
      data: getFormDataFromObject({ code, country_code, mobile_number }),
      url: `${API_MAIN_URL}/verify-updated-otp`,
      ...boolBacks,
    });
  }
}
