const INITIAL_STATE = {
  banksData: {
    isLoaded: false,
    isErred: false,
    data: [],
  },
  earningsData: {
    isLoaded: false,
    isErred: false,
    pending: { data: [], sum: null },
    available: { data: [], sum: null },
    intransit: { data: [], sum: null },
    withdrawn: { data: [], sum: null },
    deducted: { data: [], sum: null },
    remaining: { data: [], sum: null },
  },
  monthlyEarningsStats: {
    isLoaded: false,
    isErred: false,
    data: [],
  },
  dailyEarningsStats: {
    isLoaded: false,
    isErred: false,
    data: [],
  },
  withdrawals: {
    isLoaded: false,
    isErred: false,
    data: [],
    total: 0,
    per_page: 0,
    current_page: 1,
  },
};

const reducer = (state = INITIAL_STATE, action) =>
  ({
    SET_BANKS_DATA: {
      ...state,
      banksData: {
        isLoaded: true,
        isErred: false,
        data: action.payload,
      },
    },
    ERRED_BANKS_DATA: {
      ...state,
      banksData: {
        isLoaded: true,
        isErred: true,
        data: [],
      },
    },
    RESET_BANKS_DATA: {
      ...state,
      banksData: {
        isLoaded: false,
        isErred: false,
        data: [],
      },
    },
    REMOVED_BANK_ACCOUNT: {
      ...state,
      banksData: {
        isLoaded: true,
        isErred: false,
        data: action.payload,
      },
    },

    SET_EARNINGS_SUM_BY_STATUS: {
      ...state,
      earningsData: {
        ...state.earningsData,
        [action.payload?.status]: {
          ...state.earningsData[action.payload?.status],
          sum: action.payload?.data,
        },
      },
    },
    SET_EARNINGS_SUM: {
      ...state,
      earningsData: {
        ...state.earningsData,
        ...action.payload,
        isLoaded: true,
        isErred: false,
      },
    },
    RESET_EARNINGS_SUM: {
      ...state,
      earningsData: { ...INITIAL_STATE.earningsData },
    },

    ERRED_EARNINGS_SUM: {
      ...state,
      earningsData: {
        isErred: true,
        isLoaded: true,
        ...INITIAL_STATE.earningsData,
      },
    },
    SET_MONTHLY_EARNINGS_STATS: {
      ...state,
      monthlyEarningsStats: {
        isLoaded: true,
        isErred: false,
        data: action.payload,
      },
    },
    ERRED_MONTHLY_EARNINGS_STATS: {
      ...state,
      monthlyEarningsStats: {
        isLoaded: true,
        isErred: true,
        data: [],
      },
    },
    LOADING_WITHDRAWALS_HISTORY: {
      ...state,
      withdrawals: {
        isLoaded: false,
        isErred: false,
        data: [],
      },
    },
    SET_WITHDRAWALS_HISTORY: {
      ...state,
      withdrawals: {
        ...state.withdrawals,
        ...action.payload,
        data: action.payload?.data ?? [],
        isLoaded: true,
        isErred: false,
      },
    },
    ERRED_WITHDRAWALS_HISTORY: {
      ...state,
      withdrawals: {
        isErred: true,
        isLoaded: true,
        ...state.withdrawals,
      },
    },
    SET_DAILY_EARNINGS: {
      ...state,
      dailyEarningsStats: {
        isLoaded: true,
        isErred: false,
        data: action.payload,
      },
    },
    ERRED_DAILY_EARNINGS: {
      ...state,
      dailyEarningsStats: {
        isErred: true,
        isLoaded: true,
        data: [],
      },
    },
  }[action.type] || state);

export default reducer;
