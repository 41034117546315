/**
 * @type {TransactionState}
 */
const INITIAL_STATE = {
  paymentMethods: {
    data: [],
    isErred: false,
    isLoaded: false,
  },
  transactionAmount: {
    sessions: 0,
    materials: 0,
    total: 0,
    state: "loading",
  },
  monthlyTransactionsStats: {
    isLoaded: false,
    isErred: false,
    data: [],
  },
  dailyTransactionsStats: {
    isLoaded: false,
    isErred: false,
    data: [],
  },
  totalTransactions: {
    state: "loading",
    data: [],
  },
};

/**
 * @param {TransactionState} state
 * @param {TransactionAction} action
 * @returns {TransactionState}
 */
const reducer = (state = INITIAL_STATE, action) =>
  ({
    SET_PAYMENT_METHODS: {
      ...state,
      paymentMethods: {
        isErred: false,
        isLoaded: true,
        data: action.payload,
      },
    },
    ERRED_PAYMENT_METHODS: {
      ...state,
      paymentMethods: {
        isErred: true,
        isLoaded: true,
      },
    },
    REMOVE_PAYMENT_METHOD: {
      ...state,
      paymentMethods: {
        ...state.paymentMethods,
        data: state.paymentMethods.data.filter(
          ({_id}) => _id !== action.payload
        ),
      },
    },
    SET_TRANSACTION_AMOUNT: {
      ...state,
      transactionAmount: {
        ...state.transactionAmount,
        sessions: action.payload?.Tutor,
        materials: action.payload?.Materials,
        total: action.payload?.Total_amount,
        state: "loaded",
      },
    },
    ERRED_TRANSACTION_AMOUNT: {
      ...state,
      transactionAmount: {
        ...state.transactionAmount,
        state: "erred",
      },
    },
    SET_MONTHLY_TRANSACTIONS_STATS: {
      ...state,
      monthlyTransactionsStats: {
        isLoaded: true,
        isErred: false,
        data: action.payload,
      },
    },
    ERRED_MONTHLY_TRANSACTIONS_STATS: {
      ...state,
      monthlyTransactionsStats: {
        isLoaded: true,
        isErred: true,
        data: [],
      },
    },

    SET_DAILY_TRANSACTIONS: {
      ...state,
      dailyTransactionsStats: {
        isLoaded: true,
        isErred: false,
        data: action.payload,
      },
    },
    ERRED_DAILY_TRANSACTIONS: {
      ...state,
      dailyTransactionsStats: {
        isErred: true,
        isLoaded: true,
        data: [],
      },
    },
    SET_TOTAL_TRANSACTIONS: {
      ...state,
      totalTransactions: {
        state: "loaded",
        data: action.payload,
      },
    },
    LOADING_TOTAL_TRANSACTIONS: {
      ...state,
      totalTransactions: {
        state: "loading",
        data: [],
      },
    },
    ERRED_TOTAL_TRANSACTIONS: {
      ...state,
      totalTransactions: {
        state: "erred",
        data: [],
      },
    },
  }[action.type] || state);

export default reducer;
