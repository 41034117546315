import * as React from "react";

/**
 * Hook that performs a side effect on the
 * second render, skipping the first one.
 * @param {Function} fn Imperative function that
 * can return a cleanup function.
 * @param {Array} deps If present, effect will only
 * activate if the values in the list change.
 *
 * @see https://stackoverflow.com/a/53180013
 */
function useUpdateEffect(fn, deps) {
  const didMountRef = React.useRef(false);

  React.useEffect(() => {
    if (didMountRef.current) {
      return fn();
    }

    didMountRef.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}

export default useUpdateEffect;
