import { ListGroup } from "reactstrap";

/**
 * A common wrapper to normalize all the lists.
 *
 * @param {JSX.IntrinsicElements['ul']} props
 *
 * @returns {JSX.Element}
 *
 * @author kashan-ahmad
 * @version 0.0.1
 */
function ListWrapper({ children, className = "", ...rest }) {
  return (
    <ListGroup
      {...rest}
      className={`mb-0 p-0 rounded overflow-hidden ${className}`}
    >
      {children}
    </ListGroup>
  );
}

export default ListWrapper;
