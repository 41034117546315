import SessionActionModal from "./session-action-modal";
import SessionCancelModal from "./variants/cancel";
import SessionRescheduledModal from "./variants/rescheduled";
import SessionScheduledModal from "./variants/scheduled";

export default Object.assign(SessionActionModal, {
  Scheduled: SessionScheduledModal,
  Rescheduled: SessionRescheduledModal,
  Cancel: SessionCancelModal,
});
