import * as React from "react";
import { apiService } from "service";
import { isFirstVisit } from "utils";
import { Boolbacks, RequestState, TourData, isTourData } from "constants/types";

type GetTourDataProps = {
  path: string;
} & Boolbacks;

type GetMultipleTourDataProps = {
  paths: string[];
} & Boolbacks;

/**
 * The hook to load the tour data.
 */
export default function useTourData() {
  const [tourState, setTourState] = React.useState<RequestState>(
    isFirstVisit() ? "loading" : "loaded"
  );

  /**
   * Load the tour data.
   * @param {Boolbacks} callbacks
   */

  const getTourData = React.useCallback(
    ({ path, onSuccess, onFailure }: GetTourDataProps) => {
      setTourState("loading");

      // Load the tour asynchronously.
      apiService.get({
        url: `${path}.json`,
        onSuccess: (response: unknown) => {
          // Check if the response is valid.
          if (isTourData(response)) {
            // Set the tour data.
            setTourState("loaded");
            // Call the success callback.
            onSuccess({ data: response });
            return;
          }

          // When the response is invalid.
          setTourState("erred");

          // Call the failure callback.
          onFailure({ message: "Invalid tour data" });
        },
        onFailure: ({ message }) => {
          // When the request fails.
          setTourState("erred");

          // Call the failure callback.
          onFailure({ message });
        },
      });
    },
    []
  );

  /**
   * Load multiple tour data.
   * @param {GetMultipleTourDataProps} params
   */
  const getCombinedTourData = React.useCallback(
    ({ paths, onSuccess, onFailure }: GetMultipleTourDataProps) => {
      setTourState("loading");

      /**
       * The combined tour data.
       */
      let tourData: TourData = {
        steps: [],
      };

      /**
       * The errors.
       * @type {Array<{ message: string }>}
       */
      const errors: string[] = [];

      /**
       * The promises.
       *
       */
      const promises = paths.map((path) =>
        apiService.get({
          url: `${path}.json`,
          onSuccess: (response: unknown) => {
            // Check if the response is valid.
            if (isTourData(response)) {
              tourData = {
                ...tourData,
                ...response,
                steps: [...tourData.steps, ...response.steps],
              };
              return;
            }

            // When the response is invalid.
            errors.push("Invalid tour data");
            return;
          },
          onFailure: ({ message }) => {
            // When the request fails.
            errors.push(message);
            return;
          },
        })
      );

      Promise.all(promises).then(() => {
        if (errors.length > 0) {
          // When the request fails.
          setTourState("erred");

          // Call the failure callback.
          onFailure({ message: errors.join(", ") });
          return;
        }

        // Set the tour data.
        setTourState("loaded");

        // Call the success callback.
        onSuccess({ data: tourData });
      });
    },
    []
  );

  return {
    tourState,
    getTourData,
    getCombinedTourData,
  };
}
