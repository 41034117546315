import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

// const chartData = [
//   {
//     month: "OCT",
//     materials: 80,
//     tutoring: 60,
//     amount: 60,
//   },
//   {
//     month: "NOV",
//     materials: 60,
//     tutoring: 40,
//     amount: 40,
//   },
//   {
//     month: "DEC",
//     materials: 65,
//     tutoring: 55,
//     amount: 50,
//   },
//   {
//     month: "JAN",
//     materials: 50,
//     tutoring: 45,
//     amount: 40,
//   },
//   {
//     month: "FEB",
//     materials: 70,
//     tutoring: 65,
//     amount: 50,
//   },
//   {
//     month: "MAR",
//     materials: 76,
//     tutoring: 65,
//     amount: 60,
//   },
//   {
//     month: "APR",
//     materials: 450,
//     tutoring: 40,
//     amount: 35,
//   },
// ];

function PaymentsBuyingsChart({
  // chartData,
  // barsSettings,
  data,
  xAxisKey = "month",
  yAxisKey = "amount",
}) {
  const barsSettings = [
    {
      name: "Materials Total",
      dataKey: "Materials",
      fillColor: "#48B3E0",
      label: "Materials",
    },
    {
      name: "1:1 Tutoring",
      dataKey: "Sessions",
      fillColor: "#ffc658",
      label: "Session",
    },
  ];

  return (
    <ResponsiveContainer>
      <BarChart
        data={data}
        margin={{
          top: 30,
        }}
      >
        <XAxis dataKey={xAxisKey} />
        <YAxis />
        <Legend wrapperStyle={{top: 0}} />
        <Tooltip />
        {barsSettings.map(({dataKey, fillColor, label}, index) => {
          return (
            <Bar
              key={index}
              dataKey={dataKey}
              fill={fillColor}
              label={() => label}
            />
          );
        })}
      </BarChart>
    </ResponsiveContainer>
  );
}

export default PaymentsBuyingsChart;
