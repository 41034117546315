import React, { useRef } from "react";

function TextField({
  type,
  placeholder,
  icon,
  label,
  state,
  onChange,
  className,
  maxLength,
  pattern,
  value,
  name,
  disabled,
  min,
  max,
  onBlur,
  inputRef,
  required = "false",
}) {
  return (
    <div
      className={` form-group my-2 text-left ${className}`}
      style={{ position: "relative" }}
    >
      {label ? (
        <label className="pb-1 form-label label-text" htmlFor="">
          {label}
        </label>
      ) : null}
      <input
        className={`form-control ft-12 ${!icon ? "form-control-noicon" : ""}`}
        type={type ? type : "text"}
        placeholder={placeholder}
        onChange={onChange}
        maxLength={maxLength}
        pattern={0 - 9}
        value={value}
        name={name}
        disabled={disabled}
        max={max ? max : undefined}
        min={min}
        onBlur={onBlur}
        ref={inputRef}
        required={required}
      />
      {icon ? <span className="font-icons">{icon}</span> : null}
    </div>
  );
}

export default TextField;
