import React from "react";
import { Breadcrumb, Icon } from ".";
import { Link } from "react-router-dom";
import { IconChevronLeft } from "@tabler/icons";

/**
 * @typedef {Object} PageHeaderProps
 *
 * @property {string} title
 * The title of the page.
 *
 * @property {boolean} allowBackButton
 * Default = true;
 *
 * @property {import("./breadcrumb").BreadCrumbProps['routes']} routes
 *
 * @property {string | undefined} subTitle
 * The subtitle right below the title, smaller and dimmer.
 *
 * @property {string | undefined} routeOnBack
 * The route to navigate to when the back button gets clicked.
 * Defaults to the last route in the browser's history object.
 *
 * @property {React.ReactElement | undefined} description
 * The description below the subtitle, can be anything you want.
 *
 * @property {React.ReactElement | undefined} rightWing
 * Anything you wanna place on the right end of the header.
 *
 * @property {string | undefined} backgroundClassName
 */

/**
 * @param {PageHeaderProps & JSX.IntrinsicElements['header']} object
 * @author kashan-ahmad
 * @version 0.0.1
 */
const PageHeader = ({
  title,
  subTitle,
  routeOnBack,
  description,
  rightWing,
  routes,
  allowBackButton = true,
  className = "",
  backgroundClassName = "bg-light-gray",
  ...props
}) => (
  <header
    {...props}
    className={`border-bottom ${className} ${backgroundClassName}`}
  >
    {/* <Breadcrumb routes={routes} /> */}
    <div className="d-flex flex-column flex-md-row align-items-md-center p-3 px-3 px-md-4 gap-3">
      <div className="d-flex align-items-center gap-3 flex-grow-1">
        {allowBackButton && (
          <Link
            to={routeOnBack || -1}
            title="Navigate backwards"
            className="btn bg-light border py-1 px-2"
          >
            <Icon size="sm">
              <IconChevronLeft className="text-secondary" />
            </Icon>
          </Link>
        )}
        <div>
          <h1 className="fs-4 fw-bold text-secondary mb-1 text-capitalize">
            {title}
          </h1>
          {subTitle && (
            <p className="font-size-13 text-secondary-dim m-0">{subTitle}</p>
          )}
          {description}
        </div>
      </div>
      {rightWing}
    </div>
  </header>
);

export default PageHeader;
