import React from "react";
// import {Link, useLocation} from "react-router-dom";
import { useSelector } from "react-redux";

// // Components.
// import {Button} from "reactstrap";
// import {RLearnerTutoring} from "constants/learner-views-routes";

// Types.
import type { ReduxStore } from "constants/";
import type { SessionStatusCardVariantProps } from "../session-status-card-types";
// import {getModalRouteProps} from "utils";
import Label from "components/label";
// import useSessionActions from "hooks/sessions/useSessionActions";
// import {useStaticDataV3} from "hooks/static-data";
import SessionDetailsButton from "../session-details-button";
import IToolTip from "components/itooltip";

export default function Rescheduled({
  isExpired,
  renderer,
  isDetailOpen,
  onViewDetails,
  onActionSuccess,
  name,
  start_time,
  end_time,
  date,
  profile_picture,
  id,
  subject_id,
  ...session
}: SessionStatusCardVariantProps) {
  // const location = useLocation();
  const { session_id, rescheduled_by } = session;

  const currentUserDetails = useSelector(
    (state: ReduxStore) => state.auth.userDetails
  );

  return (
    <>
      {/* Rescheduled Session */}

      <div className="vstack gap-2 flex-md-wrap flex-lg-nowrap align-items-end">
        <div className="d-flex my-2">
          <Label
            tag="span"
            color="info"
            className="text-secondary p-0"
            style={{ minWidth: "89px", whiteSpace: "break-spaces" }}
          >
            Rescheduled
          </Label>
          <IToolTip
            content={`Pending ${
              rescheduled_by === currentUserDetails.id
                ? currentUserDetails.user_type === "tutor"
                  ? "Learner"
                  : "Tutor"
                : currentUserDetails.user_type === "tutor"
                ? "Tutor"
                : "Learner"
            } Confirmation`}
            position="left"
          />
        </div>

        {isExpired || (
          // <Button
          //   color="danger"
          //   outline
          //   onClick={() =>
          //     handleSessionCancel({
          //       onSuccess: onActionSuccess,
          //       startTime: start_time,
          //       endTime: end_time,
          //       date,
          //       userName: name,
          //       userPicture: profile_picture,
          //       requestId: id,
          //       subjectName: getStaticData(subject_id, subjects.data),
          //     })
          //   }
          // >
          //   Cancel
          // </Button>
          <SessionDetailsButton
            sessionId={session_id}
            requestId={id}
            {...{ isDetailOpen, onViewDetails, renderer }}
          />
        )}
      </div>

      {/* {rescheduled_by === currentUserDetails.id ? (
        <>
          <div className="hstack gap-2 flex-md-wrap flex-lg-nowrap">
            <Label
              tag="span"
              color="info"
              className="my-2 text-secondary"
              props={{}}
            >
              Rescheduled. Pending Acceptance
            </Label>
            {isExpired || (
              <Button
                color="danger"
                outline
                onClick={() =>
                  handleSessionCancel({
                    onSuccess: onActionSuccess,
                    startTime: start_time,
                    endTime: end_time,
                    date,
                    userName: name,
                    userPicture: profile_picture,
                    requestId: id,
                    subjectName: getStaticData(subject_id, subjects.data),
                  })
                }
              >
                Cancel
              </Button>
            )}
          </div>
        </>
      ) : (
        <>
          <Label
            tag="span"
            color="info"
            className="my-2 text-secondary"
            props={{}}
          >
            Rescheduled Session
          </Label>
          {isExpired || (
            <div className="d-flex gap-2 justify-content-end">
              <Button
                type="button"
                color="success"
                onClick={() =>
                  handleSessionConfirm({
                    onSuccess: onActionSuccess,
                    requestId: id,
                  })
                }
              >
                Accept
              </Button>
              <Link
                type="button"
                className="btn btn-outline-info"
                            // TODO: fix link when uncommenting

                to={RLearnerTutoring.getSessionRescheduleView(session_id)}
              >
                Reschedule
              </Link>
              <Link
                className="btn btn-outline-danger"
                {...getModalRouteProps("/modal/session/reject", location, {
                  requestId: id,
                })}
              >
                Reject
              </Link>
            </div>
          )}
        </>
      )} */}
    </>
  );
}
