import { useCallback } from "react";

// Hooks.
import useToast from "hooks/use-toast";
import useRemoteProfile from "hooks/profile/use-remote-profile";
import useBasicInfoFormSchema from "./use-basic-info-form-schema";
import useBasicInfoFormAdapter from "./use-basic-info-form-adapter";

// Types.
import type { BasicInfoFormState } from "./basic-info-form-types";

type Props = {
  children: ({
    initialValues,
    validationSchema,
    onSubmit,
  }: {
    initialValues: BasicInfoFormState;
    validationSchema: unknown;
    onSubmit: (state: BasicInfoFormState) => void;
  }) => JSX.Element;
  onSuccessfulSubmission?: Function;
};

export default function BasicInfoFormProvider({
  children,
  onSuccessfulSubmission,
}: Props) {
  const toast = useToast();
  const { profile, updateProfile } = useRemoteProfile();

  // Features.
  const validationSchema = useBasicInfoFormSchema();
  const { serialize, deserialize } = useBasicInfoFormAdapter();

  /**
   * Initial values of the form.
   */
  const initialValues = deserialize(profile);

  const onSubmit = useCallback(
    (state: BasicInfoFormState) => {
      toast.loading();
      if (!profile.profile_picture) {
        toast.error("Please Upload Profile Picture");
        return;
      }
      const serialzedProfile = serialize(state);

      updateProfile({
        profile: {
          ...serialzedProfile,
          // Languages are required in the format of a simple array.
          // @example [1, 2, 3, 4, 6]
          languages: JSON.stringify(
            serialzedProfile.languages.map((l) => l.id)
          ),
        },
        onByPass: () => toast.dismiss(),
        onFailure: ({ message }) => toast.error(message),
        onSuccess: () => {
          toast.success();
          onSuccessfulSubmission?.();
        },
      });
    },
    [onSuccessfulSubmission, serialize, toast, updateProfile]
  );

  return children({
    onSubmit,
    initialValues,
    validationSchema,
  });
}
