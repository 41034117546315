import React from "react";
import { WEBSITE_URL } from "../../constants";
import { useCopyToClipboard } from "usehooks-ts";

// images
import CardImage from "../../assets/images/refer/cardImage.png";

// redux
import { connect } from "react-redux";

// lodash
import { get } from "lodash";
import {
  IconBrandFacebook,
  IconBrandLinkedin,
  IconBrandTwitter,
  IconCopy,
  IconMail,
} from "@tabler/icons";
import { Icon } from "components";
import ToastFactory from "utils/toast-factory";
import { messages } from "constants/strings";
import { SassyCard } from "components/cards";

const ReferCard = ({ userDetails, learnerData, tutorData }) => {
  const userType = get(userDetails, "user_type", "");
  const [, copy] = useCopyToClipboard();

  const referalId =
    userType === "tutor" ? tutorData.referal_id : learnerData.referal_id;

  // social media urls
  const referUrl = `${WEBSITE_URL}/auth/signup/${userType}?ref=${referalId}`;
  const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${referUrl}`;
  const linkedinUrl = `http://www.linkedin.com/shareArticle?mini=true&url=${referUrl}`;
  const twitterUrl = `https://twitter.com/share?url=${referUrl}`;
  // const instagramUrl = `https://twitter.com/share?url=${referUrl}`;

  const toastFactory = ToastFactory();

  return (
    <>
      <SassyCard>
        <div className="row g-0 align-items-end">
          <div className="col-md-8">
            <div className="card-body">
              <h1 className="card-title referCardHeading">Refer a friend!</h1>
              <p className="card-tex referCardText">
                Invite your friends to TheTutor.me & get access to new features,
                <br /> extra tools and tips, as well as FREE marketing and
                promotion
              </p>
              <div className="d-flex review-icon-color rounded justify-content-between align-items-center col-12 px-3 my-3">
                <p className="text-start text-wrap text-break pt-3 pl-2">
                  {referUrl}
                </p>
                <div>
                  <Icon
                    tag="button"
                    className="text-secondary"
                    onClick={() => {
                      copy(referUrl);
                      toastFactory.success(messages.COPIED);
                    }}
                    size="sm"
                  >
                    <IconCopy />
                  </Icon>
                  {/* <button
                    type="button"
                    className="btn btn-link"
                    onClick={() => {
                      copy(referUrl);
                      toast.success(
                        "Referral link has been copied to your clipboard."
                      );
                    }}
                  >
                    <img src={copyImg} className="img-fluid mx-2 " alt="mail" />
                  </button> */}
                </div>
              </div>

              <div className="d-flex flex-column flex-sm-row align-items-center ">
                <p className="referShare pt-3">Share:</p>

                <div className="mx-3 hstack gap-3 justify-content-center pb-3 pb-sm-0">
                  <Icon.Rounded
                    tag="a"
                    href={`mailto:?subject=MY Thetutor Referela code &body=Check out this site ${WEBSITE_URL}/auth/signup/${referalId}`}
                    className="bg-light-gray"
                    size="md"
                  >
                    <IconMail />
                  </Icon.Rounded>
                  <Icon.Rounded
                    tag="a"
                    href={facebookShareUrl}
                    target="_blank"
                    rel="noreferrer"
                    className="bg-light-gray"
                    size="md"
                  >
                    <IconBrandFacebook />
                  </Icon.Rounded>
                  <Icon.Rounded
                    tag="a"
                    href={linkedinUrl}
                    target="_blank"
                    rel="noreferrer"
                    className="bg-light-gray"
                    size="md"
                  >
                    <IconBrandLinkedin />
                  </Icon.Rounded>
                  <Icon.Rounded
                    tag="a"
                    href={twitterUrl}
                    target="_blank"
                    rel="noreferrer"
                    className="bg-light-gray"
                    size="md"
                  >
                    <IconBrandTwitter />
                  </Icon.Rounded>
                  {/* <a
                    href={`mailto:?subject=MY Thetutor Referela code &body=Check out this site ${WEBSITE_URL}/auth/signup/${referalId}`}
                    className=""
                  >
                    <img src={Mail} className="img-fluid mx-2" alt="mail" />
                  </a> */}
                  {/* <a
                    href={facebookShareUrl}
                    target="_blank"
                    rel="noreferrer"
                    className=""
                  >
                    <img src={Fb} className="img-fluid mx-2" alt="fb" />
                  </a> */}
                  {/* <a
                    href={linkedinUrl}
                    target="_blank"
                    rel="noreferrer"
                    className=""
                  >
                    <img
                      src={LinkedIn}
                      className="img-fluid mx-2"
                      alt="linkedin"
                    />
                  </a> */}
                  {/* insta share link does not exist */}
                  {/* <a href="#" target="_blank" className="">
                    <img src={Insta} className="img-fluid mx-2" alt="insta" />
                  </a> */}
                  {/* <a
                    href={twitterUrl}
                    target="_blank"
                    rel="noreferrer"
                    className=""
                  >
                    <img
                      src={Twitter}
                      className="img-fluid mx-2"
                      alt="twitter"
                    />
                  </a> */}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 d-none d-md-block">
            <img
              src={CardImage}
              className="img-fluid rounded-start"
              alt="..."
            />
          </div>
        </div>
      </SassyCard>
    </>
  );
};

const mapToStateProps = (state) => ({
  userDetails: state.auth.userDetails,
  learnerData: state.learnerDashboard.userInfo.user,
  tutorData: state.tutorDashboard.userInfo.user,
});
export default connect(mapToStateProps, {})(ReferCard);
