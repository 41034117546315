import React from "react";

/**
 * @typedef {Object} SassyImgProps
 * @property {string} fallbackImgSrc
 */

/**
 * An image that has sass. In other words, it has a fallback image.
 * Not to mention that the fallback works even if the provided source fails to load.
 * @param {SassyImgProps & JSX.IntrinsicElements['img']} object
 * @returns {JSX.Element}
 * @author kashan-ahmad
 * @version 0.0.1
 */
const SassyImg = ({ src, fallbackImgSrc, className = "", ...props }) => (
  // eslint-disable-next-line jsx-a11y/alt-text
  <img
    {...props}
    // It's a flexbox to allow the ALT text to be in the middle in-case it's
    // provided and fallback image isn't.
    className={`d-flex justify-content-center align-items-center ${className}`}
    src={src || fallbackImgSrc}
    onError={({ target }) => {
      // Removing the event listener prevents an infinite event chain.
      target["onerror"] = null;
      target["src"] = fallbackImgSrc;
    }}
  />
);

export default SassyImg;
