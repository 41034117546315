import { RTutorTutoring } from "constants/tutor-views-routes";
import moment from "moment";
import { Link } from "react-router-dom";
import {
  hexToRgba,
  getCalendarListingIcon,
  getCalendarListingColor,
} from "utils";

//  export const toggleSettingBar = () => {
//     setSettingBar((prevState) => !prevState);

//     if (settingBar) {
//       document.body.classList.add("right-bar-enabled");
//     } else {
//       document.body.classList.remove("right-bar-enabled");
//     }
//   };

export const eventTemplate = (props) => {
  // const sTime = moment(props.StartTime).format("hh:mm");
  // const eTime = moment(props.EndTime).format("hh:mm");

  return (
    <div
      className="template-wrapper"
      style={{
        backgroundColor: `${hexToRgba(
          getCalendarListingColor(props.RequestStatus)
        )}`,
      }}
    >
      <div className="d-flex align-items-center">
        <p className="m-0 text-secondary fs-7 fw-bold flex-grow-1 text-truncate">
          {props.Subject}
        </p>
        <span
          className="session-type-icon-box-sm"
          style={{
            backgroundColor: `${getCalendarListingColor(props.RequestStatus)}`,
          }}
        >
          {getCalendarListingIcon(props.RequestStatus)}
        </span>
      </div>
      {/* <p className="subject text-break mb-1">{props.Subject}</p>
      <div className="time-container mb-1">
        <span className="time-box">
          {sTime} - {eTime}
        </span>
      </div>
      <p className="tutor-name mb-1">{props.LearnerName}</p>
      <span className="event-icon-box">
        <span
          className="session-type-icon-box"
          style={{
            backgroundColor: `${getCalendarListingColor(props.RequestStatus)}`,
          }}
        >
          {getCalendarListingIcon(props.RequestStatus)}
        </span>
      </span> */}
    </div>
  );
};

export const onCellClick = (args) => {
  args.cancel = true;
};

export const footerTemplate = (props) => {
  return (
    <div className="quick-info-footer">
      <div className="event-footer d-flex justify-content-between align-items-center">
        <div className="text-start">
          <span>Learner: </span>
          <h5>{props.LearnerName}</h5>
        </div>
        <Link
          to={RTutorTutoring.getSessionDetailsView(
            props.SessionId,
            props.RequestId
          )}
          className="btn btn-secondary"
        >
          More Details
        </Link>

        {/* {props.RequestStatus === 5 ? (
          <Alert color="danger">Cancelled</Alert>
        ) : (
          <>
            <div className="text-start">
              <span>Learner: </span>
              <h5>{props.LearnerName}</h5>
            </div>
            <Link
              to={`/1-1-tutoring/session-request/details/${props.SessionId}`}
              className="btn btn-secondary"
            >
              More Details
            </Link>
          </>
        )} */}
      </div>
    </div>
  );
};

export const onNavigate = (args, calendarNavigation, updateMe) => {
  let mode = calendarNavigation.current.viewMode;

  // console.log(args);

  if (args?.currentView) {
    mode = args.currentView;
  }
  const today = moment(args.currentDate);
  const start =
    mode === "Day"
      ? today
      : ["Week", "WorkWeek"].includes(mode)
      ? today.clone().startOf("week")
      : mode === "Month"
      ? today.clone().startOf("month")
      : today;
  const end =
    mode === "Day"
      ? today
      : ["Week", "WorkWeek"].includes(mode)
      ? today.clone().endOf("week")
      : mode === "Month"
      ? today.clone().endOf("month")
      : today;

  calendarNavigation.current.viewMode = mode;
  calendarNavigation.current.end_date = end.format("YYYY-MM-DD");
  calendarNavigation.current.start_date = start.format("YYYY-MM-DD");

  updateMe((prev) => prev + 1);
};
