import React, {useState} from "react";
import Expired from "../status/expired";
import Pending from "../status/pending";
import Rejected from "../status/rejected";
import Accepted from "../status/accepted";
import Cancelled from "../status/cancelled";
import Rescheduled from "../status/rescheduled";
import {useSessionStatus} from "hooks/sessions";
import {
  SessionStatusCardRendererProps,
  SessionStatusCardRenderers,
} from "../session-status-card-types";
import {SessionStatusCard} from "..";
import {TutoringTutorSessionRequest} from "views";

export default function TutorContainedDetails(
  session: SessionStatusCardRendererProps
) {
  const [isDetailOpen, setIsDetailOpen] = useState<boolean>(false);

  const {status, isExpired} = useSessionStatus(session);

  const Status =
    {
      1: Pending,
      2: Accepted,
      3: Rejected,
      4: Rescheduled,
      5: Cancelled,
    }[status] || Expired;

  return (
    <>
      <SessionStatusCard {...session}>
        <Status
          {...session}
          isExpired={isExpired}
          isDetailOpen={isDetailOpen}
          onViewDetails={() => setIsDetailOpen((prev) => !prev)}
          renderer={SessionStatusCardRenderers.TutorContainedDetails}
        />
      </SessionStatusCard>
      {isDetailOpen && (
        <TutoringTutorSessionRequest
          sessionId={session.session_id}
          requestId={session.id}
          isEdit={false}
        />
      )}
    </>
  );
}
