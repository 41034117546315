/**
 * HOC that makes a component clickable by assigning the right listeners and
 * attributes.
 *
 * Make sure the targetted component allows the `onKeyDown` and `className`
 * props.
 *
 * @returns {JSX.Element}
 */
function withClickableProps(Component) {
  return function ClickableComponent({ className = "", ...props }) {
    return (
      <Component
        {...props}
        tabIndex={0}
        className={`focusable ${className}`}
        onKeyDown={(e) => {
          if (e.code === "Space" || e.code === "Enter") {
            e.preventDefault();
            e.target.click();
          }
        }}
      />
    );
  };
}

export default withClickableProps;
