const RTutorTutoring = {
  baseRoute: "/1-1-tutoring",
  landingView: "/1-1-tutoring",
  createService: "/1-1-tutoring/services/create",
  editService: "/1-1-tutoring/services/edit",
  manageServices: "/1-1-tutoring/services",
  availability: "/1-1-tutoring/availability",

  sessionsBaseView: "/1-1-tutoring/sessions",
  newSession: "/1-1-tutoring/sessions/new",

  getSessionsView: (type: "upcoming" | "all" | "missed" = "upcoming") =>
    `/1-1-tutoring/sessions?type=${type}`,
  getSessionDetailsView: (
    sessionId: string | number,
    requestId: string | number
  ) => `/1-1-tutoring/sessions/details/${sessionId}/req/${requestId}`,
  getSpecificNewSessionView: (studentId: string | number, bundleId: number) =>
    `/1-1-tutoring/sessions/new/student/${studentId}/bundle/${bundleId}`,
};

export { RTutorTutoring };
