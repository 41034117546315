import React from "react";
import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Collapse,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";

import blog1 from "assets/images/blog/blog-1.png";
import blog2 from "assets/images/blog/blog-2.png";
import blog3 from "assets/images/blog/blog-3.png";
function TicketDetails({ data }) {
  const { id, title, date, status, description, resolution, attachments } =
    data;
  const ImageCard = (image_url, image_title, date) => {
    return (
      <>
        <Col lg="4" md="3" sm="3" xm="1">
          <Card>
            <img
              src={image_url ? image_url : blog1}
              className="img-fluid rounded"
              alt="attachment one"
            />
            <CardBody className="p-1">
              <p className="text-secondary mb-0 py-1">{image_title}</p>
              <p className="card-text">{date})</p>
            </CardBody>
          </Card>
        </Col>
      </>
    );
  };

  return (
    <>
      <TabContent activeTab="1" className="p-3 text-muted bg-light">
        <TabPane tabId="1">
          <Row>
            <Col sm="12">
              <Card className="shadow-none">
                <p className="d-flex justify-content-between">
                  {status == true ? (
                    <span className={`badge text-green bg-open-badge`}>
                      Open
                    </span>
                  ) : (
                    <span className={`badge text-green bg-closed-badge`}>
                      Close
                    </span>
                  )}
                  <span className="text-muted">
                    <i className="bx bx-calendar-alt"></i> {date}
                  </span>
                </p>
                <h3 className="text-seondary">{title}</h3>
                <div className="support-description mt-3">
                  <h6 className="text-muted mb-1">Desciption</h6>
                  <p>{description}</p>
                </div>

                <div className="support-resolution mt-3">
                  <h6 className="text-muted mb-2">Resolution</h6>
                  <p>{resolution}</p>
                </div>
                <hr />
                <h5 className="text-seondary">Attachments</h5>

                <Row>
                  {/* {attachments > 0 && attachments.map((item,i) => (
                        <ImageCard  key={item.doc_title}  image_title={item.doc_title} image_url={item.doc_url} date={"29 dec 2019"} />
                    ))} */}
                  {/* {attachments.map((item, i ) => (
                   
                ))} */}
                  <Col lg="4" md="3" sm="3" xm="1">
                    <Card>
                      <img
                        src={blog1}
                        className="img-fluid rounded"
                        alt="attachment one"
                      />
                      <CardBody className="p-1">
                        <p className="text-secondary mb-0 py-1">
                          1. Card Pyament status
                        </p>
                        <p className="card-text">{"29 dec 2019"})</p>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="4" md="3" sm="3" xm="1">
                    <Card>
                      <img
                        src={blog1}
                        className="img-fluid rounded"
                        alt="attachment one"
                      />
                      <CardBody className="p-1">
                        <p className="text-secondary mb-0 py-1">
                          1. Card Pyament status
                        </p>
                        <p className="card-text">{"29 dec 2019"})</p>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="4" md="3" sm="3" xm="1">
                    <Card>
                      <img
                        src={blog1}
                        className="img-fluid rounded"
                        alt="attachment one"
                      />
                      <CardBody className="p-1">
                        <p className="text-secondary mb-0 py-1">
                          1. Card Pyament status
                        </p>
                        <p className="card-text">{"29 dec 2019"})</p>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </>
  );
}

export default TicketDetails;
