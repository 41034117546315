/* eslint-disable eqeqeq */
// Hooks.
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

// Utils.
import { useStaticDataV3 } from "hooks/static-data";
import { setProfile as setRemoteProfile } from "store/profile";

// Types.
import type { RemoteProfile, UserType } from "constants/types";
import { hasValidData } from "utils";

export default function useSetRemoteProfile() {
  const dispatch = useDispatch();

  const {
    getStaticData,
    skills: staticSkills,
    subjects: staticSubjects,
  } = useStaticDataV3();

  let remoteProfile: RemoteProfile = useSelector(
    (store: any) => store.profile
  ) as RemoteProfile;
  /**
   * Function to set the profile in the store.
   */
  return useCallback(
    ({
      userType,
      profile,
    }: {
      userType?: UserType;
      profile: Partial<RemoteProfile>;
    }) => {
      const updatedProfile = { ...remoteProfile, ...profile };

      // If the user hasn't set a bio, we update the template bio, that we provide, to the user.
      if (!updatedProfile.bio) {
        const { name, skills, subjects } = updatedProfile;

        /**
         * If no name is provided, use the name from the profile.
         * If no name is provided in the profile, use a default name.
         */
        const useableName = name || remoteProfile.name || "<Your Name>";

        const useableSubjects: string[] = (() => {
          // If subjects are provided, use them.

          // if (subjects && subjects.length) {
          //   debugger;
          //   const data = subjects.map(({ subject_id }) =>
          //     subjCopy.find((item) => item.subject_id === subject_id)
          //   );
          //   console.log(data, "jhkhk");
          // }

          if (subjects && subjects.length) {
            return subjects.map(
              ({ subject_id }) =>
                getStaticData(subject_id!, staticSubjects.data)!
            );
          }

          // If subjects are provided in the profile, use them.
          if (remoteProfile.subjects && remoteProfile.subjects.length) {
            return remoteProfile.subjects.map(
              ({ subject_id }) =>
                getStaticData(subject_id!, staticSubjects.data)!
            );
          }

          // If no subjects are provided, use a default.
          return ["<Subject 1>", "<Subject 2>"];
        })();

        const useableSkills: string[] = (() => {
          // If skills are provided, use them.
          if (skills && skills.length)
            return skills.map(
              ({ skill }) => getStaticData(skill!, staticSkills.data)!
            );

          // If skills are provided in the profile, use them.
          if (remoteProfile.skills && remoteProfile.skills.length)
            return remoteProfile.skills.map(
              ({ skill }) => getStaticData(skill!, staticSkills.data)!
            );

          // If no skills are provided, use a default.
          return ["<Skill 1>", "<Skill 2>"];
        })();

        // * =========== This Block is done because on refresh we lose data and useableSubjects & useableSkills returns empty array ===========
        //* SET: the items to localStorage if useableSubjects has data and is not empty
        if (hasValidData(useableSubjects)) {
          localStorage.setItem(
            "useableSubjects",
            JSON.stringify(useableSubjects)
          );
        }

        //* SET: the items to localStorage if useableSkills has data and is not empty
        if (hasValidData(useableSkills)) {
          localStorage.setItem("useableSkills", JSON.stringify(useableSkills));
        }

        // * GET: the useableSubjects from localstorage and save in a variable
        const storedUseableSubjects = localStorage.getItem("useableSubjects");
        const useAbleSubjects2 = storedUseableSubjects
          ? JSON.parse(storedUseableSubjects)
          : null;

        // * GET: the useableSkills from localstorage and save in a variable
        const storedUseableSkills = localStorage.getItem("useableSkills");
        const useAbleSkills2 = storedUseableSkills
          ? JSON.parse(storedUseableSkills)
          : null;
        // * ============================================================================================================

        updatedProfile.bio_template = `Hi, I am ${useableName}. I am a passionate tutor who enjoys teaching and impacting the lives of my students. I specialize in teaching ${
          //* if useableSubjects is mpty then use the localstorage data for useableSubjects
          hasValidData(useableSubjects)
            ? useableSubjects.join(", ")
            : useAbleSubjects2?.join?.(", ")
        }. I have extensive knowledge and experience in teaching across different grades and curricula. My Skills range from ${
          //* if useableSkills is mpty then use the localstorage data for useableSkills
          hasValidData(useableSkills)
            ? useableSkills.join(" to ")
            : useAbleSkills2?.join?.(" to ")
        }. Lastly, I am someone who is patient, friendly and dedicated to help you achieve your academic goals.`;
      }

      // Store the timezone in local storage.
      localStorage.setItem("TIMEZONE", updatedProfile.timezone);

      // Set the profile in the old store if the user is a tutor.
      dispatch({
        payload: { data: updatedProfile },
        type:
          (userType ? userType : remoteProfile.user_type) === "tutor"
            ? "GET_TUTOR_DATA"
            : "GET_PROFILE_DATA",
      });

      // Set the profile in the new store.
      dispatch(setRemoteProfile(updatedProfile));
    },
    [
      dispatch,
      remoteProfile,
      staticSubjects.data,
      staticSkills.data,
      getStaticData,
    ]
  );
}
