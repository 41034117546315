import { Loader } from "components";
import React from "react";

const View = React.lazy(() => import("./create-content-wrapper"));

export default function CreateContentWrapper() {
  return (
    <React.Suspense fallback={<Loader />}>
      <View />
    </React.Suspense>
  );
}
