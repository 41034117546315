import React, { useCallback, useEffect, useState } from "react";
import Course1 from "assets/images/material/course-1.png";
import Course2 from "assets/images/material/course-2.png";
import Course3 from "assets/images/material/course-3.png";
import DashboardBlogCarousel from "./dashboard-blog-carousel";
import { Col, Row } from "reactstrap";
import axios from "axios";
import { Loader } from "components";

const DashboardBlog = () => {
  const [blogsData, setBlogsData] = useState([]);
  const [isData, setIsData] = useState(false);

  const fetchMyAPI = useCallback(async () => {
    try {
      const res = await axios.get(
        `https://cms.thetutor.me/api/tutor-blogs?populate[Image][populate]=*&populate[Tags[populate]=*&sort[0]=createdAt:desc&pagination[pageSize]=6&pagination[page]=1`
      );

      setBlogsData(res.data.data);
      setIsData(true);
    } catch (error) {
      setIsData(true);
      setBlogsData([]);
    }
  }, []);

  useEffect(() => {
    fetchMyAPI();
  }, [fetchMyAPI]);

  if (!isData) return <Loader />;

  return (
    <section className="dash-blog">
      {blogsData.length > 0 && (
        <>
          <h2 className="dash-blog-header">Blogs</h2>
          <Row>
            <Col xl={"6"} lg={"6"} md={"6"} sm={"12"} xs={"12"}>
              <DashboardBlogCarousel blogsData={blogsData.slice(0, 3)} />
            </Col>

            <Col xl={"6"} lg={"6"} md={"6"} sm={"12"} xs={"12"}>
              <Row>
                <Col xl={"12"} lg={"12"} md={"12"} sm={"12"} xs={"12"}>
                  <div className="dash-blog-right-banner-1">
                    {blogsData.length >= 4 ? (
                      <a
                        href={`${process.env.REACT_APP_WEBSITE_URL}/blog-detail/${blogsData[3].id}/${blogsData[3].attributes.Slug}`}
                        target="blank"
                      >
                        <div
                          className="dash-blog-carousel h-100"
                          style={{
                            background: `linear-gradient(
      to right bottom,rgb(0 0 0 / 34%), rgb(3 3 3 / 36%)),url(${blogsData[3].attributes.Image.Image.data.attributes.url})`,
                            backgroundRepeat: `no-repeat`,
                            backgroundSize: `cover`,
                          }}
                        >
                          <div className="d-flex align-items-end h-100">
                            <h5 className="text-white mb-0 p-2">
                              {blogsData[3].attributes.Title}
                            </h5>
                          </div>
                        </div>
                      </a>
                    ) : (
                      <>
                        <img
                          src={Course1}
                          alt=""
                          className="dash-blog-right-img"
                        />
                        <p className="dash-blog-right-p">
                          100% Money back Guaranteed
                        </p>
                      </>
                    )}
                  </div>
                </Col>
                <Col>
                  <Row>
                    <Col xl={"6"} lg={"6"} md={"6"} sm={"12"} xs={"12"}>
                      <div className="dash-blog-right-banner-2-content">
                        {blogsData.length >= 5 ? (
                          <a
                            href={`${process.env.REACT_APP_WEBSITE_URL}/blog-detail/${blogsData[4].id}/${blogsData[4].attributes.Slug}`}
                            target="blank"
                          >
                            <div
                              className="dash-blog-carousel h-100"
                              style={{
                                background: `linear-gradient(
            to right bottom,rgb(0 0 0 / 34%), rgb(3 3 3 / 36%)),url(${blogsData[4].attributes.Image.Image.data.attributes.url})`,
                                backgroundRepeat: `no-repeat`,
                                backgroundSize: `cover`,
                              }}
                            >
                              <div className="d-flex align-items-end h-100">
                                <h5 className="text-white mb-0 p-2">
                                  {blogsData[4].attributes.Title}
                                </h5>
                              </div>
                            </div>
                          </a>
                        ) : (
                          <>
                            <img
                              src={Course2}
                              alt=""
                              className="dash-blog-right-img"
                            />
                            <p className="dash-blog-right-p-2">
                              Open new possibilities
                            </p>
                          </>
                        )}
                      </div>
                    </Col>
                    <Col xl={"6"} lg={"6"} md={"6"} sm={"12"} xs={"12"}>
                      <div className="dash-blog-right-banner-2-content">
                        {blogsData.length >= 6 ? (
                          <a
                            href={`${process.env.REACT_APP_WEBSITE_URL}/blog-detail/${blogsData[5].id}/${blogsData[5].attributes.Slug}`}
                            target="blank"
                          >
                            <div
                              className="dash-blog-carousel h-100"
                              style={{
                                background: `linear-gradient(
          to right bottom,rgb(0 0 0 / 34%), rgb(3 3 3 / 36%)),url(${blogsData[5].attributes.Image.Image.data.attributes.url})`,
                                backgroundRepeat: `no-repeat`,
                                backgroundSize: `cover`,
                              }}
                            >
                              <div className="d-flex align-items-end h-100">
                                <h5 className="text-white mb-0 p-2">
                                  {blogsData[5].attributes.Title}
                                </h5>
                              </div>
                            </div>
                          </a>
                        ) : (
                          <>
                            <img
                              src={Course3}
                              alt=""
                              className="dash-blog-right-img"
                            />
                            <p className="dash-blog-right-p-2">
                              Learn More. Achieve More
                            </p>
                          </>
                        )}
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      )}
    </section>
  );
};

export default DashboardBlog;
