import React from "react";
import { useMatch, useNavigate } from "react-router-dom";
import { NavItem, NavLink } from "reactstrap";

/**
 * @typedef {Object} TabBarItemProps
 * @property {import("react-router-dom").LinkProps['to']} [to]
 * @property {number} tabNumber The number of the tab assigned to this item. Don't forward if you're using the `to` attribute.
 * @property {number} activeTab The number of the active tab in the bar. Don't forward if you're using the `to` attribute.
 * @property {Function} onSwitchTab The handler to be called upon a click. Don't forward if you're using the `to` attribute.
 * @property {keyof JSX.IntrinsicElements} [wrapperTag] = 'button'
 * The HTML tag of the text wrapper.
 * @property {string} [wrapperClassName] The CSS classes for the text wrapper.
 */

/**
 * A generic tab bar item.
 * @param {import("reactstrap").NavItemProps & TabBarItemProps} object
 * @returns {JSX.Element}
 * @author kashan-ahmad
 * @version 1.0.0
 */
function TabBarItem({
  to,
  active,
  pathname,
  children,
  tabNumber,
  activeTab,
  onSwitchTab,
  wrapperTag = "button",
  wrapperClassName = "",
  ...props
}) {
  const navigate = useNavigate();
  const matches = useMatch(to || "");

  // Rendered as a doppelganger of the `Link` element if the `to` prop is provided.
  // Otherwise it's just a number-based tabber.
  const configuredProps = to
    ? {
        active:
          !!matches ||
          activeTab === (tabNumber === undefined ? children : tabNumber),
        onClick: () => navigate(to),
      }
    : {
        // The children act as the tab's value if a number isn't provided.
        active: activeTab === (tabNumber === undefined ? children : tabNumber),
        onClick: () =>
          onSwitchTab(tabNumber === undefined ? children : tabNumber),
      };

  return (
    <NavItem {...props}>
      <NavLink
        tag={wrapperTag}
        className={`text-nowrap ${wrapperClassName}`}
        {...configuredProps}
      >
        {children}
      </NavLink>
    </NavItem>
  );
}

export default TabBarItem;
