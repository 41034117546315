import React, {useRef} from "react";
import {Col} from "reactstrap";
import moment from "moment";
// import {nanoid} from "nanoid";
const Slot = ({day, startTime, endTime, isReserved, active, setTime}) => {
  const slotRef = useRef();

  let timestamp = day + " " + startTime;

  const isPassedTime = moment(timestamp, "DD/MM/YYYY HH:mm").isSameOrBefore(
    new Date()
  );

  const handleClick = (e) => {
    let startTimeString = moment(timestamp, "DD/MM/YYYY HH:mm").format(
      "DD/MM/YYYY HH:mm"
    );
    setTime({id: timestamp, startTimeString, endTime});
  };
  return (
    <Col
      xs="12"
      id={moment(day + " " + startTime).format("X")}
      className={`py-2 px-4 text-center cursor-pointer ${
        active ? "bg-green text-white" : " text-green"
      } ${
        isPassedTime || isReserved
          ? "text-danger cursor-default text-decoration-line-through"
          : ""
      }`}
      onClick={isReserved !== 1 && !isPassedTime ? handleClick : () => {}}
    >
      <div ref={slotRef}>
        {/* {isReserved === 1 && (
          <span>
            Reserved <br />
          </span>
        )} */}
        {startTime}
      </div>
    </Col>
  );
};

export default React.memo(Slot);
