import React from "react";
import SessionDetailsButton from "../session-details-button";

// Types.
import type {SessionStatusCardVariantProps} from "../session-status-card-types";

export default function Cancelled({
  isExpired,
  renderer,
  isDetailOpen,
  onViewDetails,
  session_id,
  id,
  ...session
}: SessionStatusCardVariantProps) {
  return (
    <>
      {/* Cancelled Session */}
      <div className="vstack gap-2 align-items-end">
        <span className="bg-danger bg-soft text-danger p-1 rounded">
          Cancelled
        </span>
        <SessionDetailsButton
          sessionId={session_id}
          requestId={id}
          {...{renderer, isDetailOpen, onViewDetails}}
        />
      </div>
    </>
  );
}
