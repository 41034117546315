import React from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import SessionIcon from "assets/images/video-icon.png";
import MaterialIcon from "assets/images/uploadFile.png";
import { Link } from "react-router-dom";

const TutorStartPlaceholderView = ({ isOnboarding }) => {
  return (
    <section className="content h-100">
      <Container className="h-100 ">
        <div className="w-100 h-100 d-flex align-items-center">
          <Row className="flex-grow-1">
            <Col sm="6">
              <Link
                to="/1-1-tutoring"
                className="text-secondary flex-grow-1 h-100"
              >
                <Card
                  className="mb-0 h-100 ms-auto me-auto me-sm-0"
                  style={{ maxWidth: "400px" }}
                >
                  <CardBody className="text-center py-4">
                    <span className="d-inline-block mb-2">
                      <img
                        src={SessionIcon}
                        alt="Tutoring icon"
                        style={{ width: "50px" }}
                      />
                    </span>
                    <h3>Create Service</h3>
                    <p className="mt-2 w-80 mx-auto">
                      Add details about your sessions to reach a wider audience.
                    </p>
                  </CardBody>
                </Card>
              </Link>
            </Col>
            <Col sm="6" className="mt-3 mt-sm-0">
              <Link
                to="/1-1-tutoring"
                className="text-secondary flex-grow-1 h-100"
              >
                <Card
                  className="mb-0 h-100 me-auto ms-auto ms-sm-0"
                  style={{ maxWidth: "400px" }}
                >
                  <CardBody className="text-center py-4">
                    <span className="d-inline-block mb-2">
                      <img
                        src={MaterialIcon}
                        alt="Tutoring icon"
                        style={{ width: "50px" }}
                      />
                    </span>
                    <h3>Upload or Build Material</h3>
                    <p className="mt-2 w-80 mx-auto">
                      Create and share material with your learners.{" "}
                    </p>
                  </CardBody>
                </Card>
              </Link>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  );
};

export default TutorStartPlaceholderView;
