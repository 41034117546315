import { Field } from "formik";
import { Input } from "reactstrap";
import SassyFormField from "./sassy-form-field";

/**
 * A formik-based text-field but sassy. Uses formik's field component that
 * handles the formik context by itself.
 *
 * @param {JSX.IntrinsicElements['input'] & {label: string} } props
 *
 * @returns {JSX.Element}
 *
 * @author kashan-ahmad
 *
 * @version 0.0.3
 *
 * @changelog
 * - 0.0.3: Added `withNumericValue` HOC for numeric input elements.
 * - 0.0.2: Added `maxLength` indicators for textarea elements.
 * - 0.0.1: Initial version.
 */
function SassyFormFieldText({ name, type, value, label, maxLength, ...rest }) {
  const fieldProps = {
    ...rest,
    id: name,
    as: Input,
    type: type,
    name: name,
    maxLength,
    "data-testid": `${name}Field`,
  };

  return (
    <SassyFormField {...{ name, label }}>
      <div className="position-relative">
        <Field {...fieldProps} data-testclass="sassyField" />
        {/* Max characters indicator. */}
        {maxLength && type === "textarea" && (
          <div
            data-testclass="sassyFieldLength"
            className="text-blue fs-8 position-absolute top-100 end-0 translate-end mt-1"
          >
            {!value ? 0 : value.length}/{maxLength}
          </div>
        )}
      </div>
    </SassyFormField>
  );
}

export default SassyFormFieldText;
