import moment from "moment";
import { getAdjustedTime } from "utils";
import { Label } from "../..";

/**
 * @typedef {Object} NotificationCompactBodyProps
 *
 * @property {string} displayTime
 * @property {import("constants/types/types-notifications").Notification['description']} description
 * @property {import("constants/types/types-notifications").NotificationType} notificationType
 */

/**
 * A compact variant of the body for the compact notification.
 *
 * @param {NotificationCompactBodyProps} object The forwarded props.
 * @returns {JSX.Element} The component.
 *
 * @version 0.0.2
 * @author kashan-ahmad
 *
 * @changelog
 * - 0.0.2: Added typedef for props.
 * - 0.0.1: Initial version.
 */
const NotificationCompactBody = ({
  seen,
  displayTime,
  description,
  timeline,
  notificationType,
  ...props
}) => (
  <div {...props} className="gap-column-sm flex-grow-1">
    <div className="d-flex justify-content-between align-items-center gap-1">
      <Label color={notificationType?.color || "primary"}>
        {notificationType?.label}
      </Label>
      {
        <span className="text-muted fs-8">
          {timeline ?? getAdjustedTime(displayTime).fromNow()}
        </span>
      }
    </div>
    <p className={`m-0 ${seen == 0 ? "fw-bold" : ""}`}>{description}</p>
  </div>
);

export default NotificationCompactBody;
