import { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Col, Modal, ModalBody, Row } from "reactstrap";
import { Loader, MyModalHeader, SassyAlert } from "components";
import { fetchSingleBankDetails } from "store";
import { useApiEffect, useBanks } from "hooks";

const BankDetailsModal = ({
  isOpen,
  toggleModal,
  accountHandle,
  is_default,
}) => {
  const [bankDetails, setBankDetails] = useState({
    state: "loading",
    data: {},
  });

  const dispatch = useDispatch();

  useApiEffect(() => {
    dispatch(
      fetchSingleBankDetails({
        accountHandle,
        onSuccess: (data) => setBankDetails({ state: "loaded", data }),
        onFailure: (error) => setBankDetails({ state: "erred" }),
      })
    );
  }, []);

  const { onRemoveListener, onMakeDefaultListener } = useBanks();

  return (
    <Modal
      isOpen={isOpen}
      toggleModal={toggleModal}
      className="text-secondary"
      size="lg"
    >
      <div className="py-3 px-4">
        <MyModalHeader title="Bank Account Details" toggleModal={toggleModal} />
      </div>

      <ModalBody className="p-3">
        {bankDetails.state === "erred" ? (
          <SassyAlert
            color="danger"
            className="m-3 mt-0 mb-3"
            title="There was an error while loading the bank details."
          />
        ) : bankDetails.state === "loading" ? (
          <Loader isInline />
        ) : (
          <>
            <Row className="gap-column-lg w-100 mx-0">
              <Col md="6" className="mt-3">
                <p className="mb-1"> Holder Name</p>
                <h5 className="text-truncate">
                  {bankDetails.data.displayName}
                </h5>
              </Col>
              <Col md="6">
                <p className="mb-1"> Holder Email</p>
                <h5 className="text-truncate">{bankDetails.data.email}</h5>
              </Col>
              <Col md="6">
                <p className="mb-1"> Country</p>
                <h5 className="text-truncate">{bankDetails.data.country}</h5>
              </Col>
              <Col md="6">
                <p className="mb-1"> Currency</p>
                <h5 className="text-truncate">{bankDetails.data.currency}</h5>
              </Col>
              <Col md="6">
                <p className="mb-1"> Bank Name</p>
                <h5 className="text-truncate">
                  {bankDetails.data.preferredBankAccount.bankName}
                </h5>
              </Col>
              <Col md="6">
                <p className="mb-1"> Bank Account Number</p>
                <h5 className="text-truncate">
                  {bankDetails.data.preferredBankAccount.accountNumber}
                </h5>
              </Col>
              <Col md="6">
                <p className="mb-1"> Routing Number</p>
                <h5 className="text-truncate">
                  {bankDetails.data.preferredBankAccount.routingNumber}
                </h5>
              </Col>
              <Col md="6">
                <p className="mb-1"> Bank Account Type</p>
                <h5 className="text-truncate">
                  {bankDetails.data.accountType}
                </h5>
              </Col>
            </Row>
            <Row className="w-100 mx-0 mt-5 ">
              <div className="d-flex gap-row-md justify-content-center">
                <Button
                  color="danger"
                  variant="outline"
                  className={!is_default ? "flex-grow-1" : ""}
                  onClick={() => {
                    toggleModal();
                    onRemoveListener({
                      accountHandle,
                      is_default,
                    });
                  }}
                >
                  Remove Bank
                </Button>
                {!is_default && (
                  <Button
                    color="secondary"
                    className="flex-grow-1"
                    onClick={() => {
                      toggleModal();
                      onMakeDefaultListener({
                        accountHandle,
                        is_default,
                      });
                    }}
                  >
                    Make Default Bank
                  </Button>
                )}
              </div>
            </Row>
          </>
        )}
      </ModalBody>
    </Modal>
  );
};

export default BankDetailsModal;
