import React from "react";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import { SassyAlert } from "components";
import {
  getCurrentTimezone,
  getMeetingLink,
  getTutorProductBuyUrl,
  getTutorProfileUrl,
} from "utils";
import { RLearnerTutoring } from "constants/learner-views-routes";
import { RawLearnerUpcomingSessionDetailed } from "constants/";
import { useLearnerProfile, useTutorProfile } from "hooks/profile";
import { time } from "utils/si-factory";
import { RTutorTutoring } from "constants/tutor-views-routes";
import { useUser } from "hooks";
import { MeetingStatusTitles } from "../constants/enums/ETutoring";

export default function SessionActionsButtons({
  session,
  onAccept,
  onReject,
  onCancel,
  onEditTime,
  onReschedule,
  isEditing,
  isPreviousSession,
}: {
  session: RawLearnerUpcomingSessionDetailed;
  [key: string]: any;
}) {
  const requestStatusId = session.request_status;
  // const meetingStatusId = session.meeting?.meeting_status?.id;
  const meetingStatusTitle = session.meeting?.meeting_status?.title;
  const { type } = useUser();
  const isLearner = type === "learner";

  // const sessionPartner = isLearner ? session.tutor : session.learner;

  // todo: implement useRemoteProfile
  // currently only these two places have latest timezone of user. all others are invalid except the new useRemoteProfile
  const { profile: learnerProfile } = useLearnerProfile();
  const { profile: tutorProfile } = useTutorProfile();

  // The current user's profile.
  const profile = isLearner ? learnerProfile : tutorProfile;

  /**
   * Date/time formatting library set to the user's or the default timezone.
   */

  const currTime = time.getLibPerZone(
    localStorage.getItem("TIMEZONE") || getCurrentTimezone()
  );

  /**
   * The end time of the current session. Notice the format conversion.
   */
  // const sessionStartTime =
  //   // this will create a datetime of the given date and time and set its zone to specified zone.
  //   time
  //     .getLib()
  //     .fromFormat(
  //       `${session.date} ${session.start_time}`,
  //       "yyyy-MM-dd HH:mm:ss",
  //       {
  //         zone: profile.timezone || getCurrentTimezone(),
  //       }
  //     );

  /**
   * The end time of the current session. Notice the format conversion.
   */
  const sessionEndTime =
    // this will create a datetime of the given date and time and set its zone to specified zone.
    time
      .getLib()
      .fromFormat(
        `${session.date} ${session.end_time}`,
        "yyyy-MM-dd HH:mm:ss",
        {
          zone: profile.timezone || getCurrentTimezone(),
        }
      );

  /**
   * Has the session had ended already?
   */
  const isExpired = sessionEndTime < currTime;

  // const isInDuration = sessionStartTime < currTime && sessionEndTime > currTime;

  const canReschedule = session.allow_reschedule === 1;
  const canCancel = session.allow_cancel === 1;

  if ([3, 5].includes(requestStatusId))
    return (
      <SassyAlert
        color="danger"
        title={`This is a ${
          requestStatusId === 3
            ? "rejected"
            : requestStatusId === 5
            ? "cancelled"
            : ""
        } session`}
        rightWing={
          session.remaning_sessions > 0 ? (
            <Link
              className="btn btn-secondary"
              to={
                isLearner
                  ? RLearnerTutoring.getTutorAvailableSessionsView(
                      session.tutor.user_id
                    )
                  : // RTutorTutoring.newSession
                    RTutorTutoring.getSpecificNewSessionView(
                      session?.learner_id,
                      session?.bundle_id
                    )
              }
            >
              Book Again
            </Link>
          ) : isLearner ? (
            <a
              className="btn btn-secondary text-nowrap align-self-center"
              href={getTutorProductBuyUrl({
                gradeId: session.grade_id,
                subjectId: session.subject_id,
                curriculumId: session.curriculum_id,
                bundleId: session.bundle_id,
                tutorUserId: session.tutor.user_id,
                tutorName: session.tutor.name,
              })}
              target="_blank"
              rel="noopener noreferrer"
            >
              Book New
            </a>
          ) : (
            <></>
          )
        }
      />
    );

  if (
    requestStatusId === 2 &&
    meetingStatusTitle === MeetingStatusTitles.Completed &&
    isExpired
  ) {
    if (isLearner)
      return (
        <SassyAlert
          color="primary"
          className="mt-3"
          title={`Would you like to learn more from ${session.tutor.name}?
        Let's quickly book a follow-up session.`}
          rightWing={
            <Link
              className="btn btn-secondary w-100 w-sm-auto"
              to={
                session.remaning_sessions > 0
                  ? RLearnerTutoring.getTutorAvailableSessionsView(
                      session.tutor.user_id
                    )
                  : getTutorProfileUrl(
                      session.tutor.name,
                      session.tutor.user_id
                    )
              }
            >
              Book New
            </Link>
          }
        />
      );
    else
      return (
        <SassyAlert
          color="success"
          title="This is a completed session"
          className="mt-3"
          rightWing={
            session.remaning_sessions > 0 ? (
              <Link
                className="btn btn-secondary w-100 w-sm-auto"
                to={
                  RTutorTutoring.getSpecificNewSessionView(
                    session.learner_id,
                    session.bundle_id
                  )
                  // RTutorTutoring.newSession
                }
              >
                Book New
              </Link>
            ) : (
              <></>
            )
          }
        />
      );
  }

  if (
    (!isPreviousSession && isExpired) ||
    [
      MeetingStatusTitles.Unattended,
      MeetingStatusTitles.MissedByYou,
      MeetingStatusTitles.MissedByTutor,
      MeetingStatusTitles.MissedByLearner,
    ].includes((meetingStatusTitle as MeetingStatusTitles) || "")
  )
    return !isEditing ? (
      <SassyAlert
        color="info"
        title="This session can be rescheduled"
        rightWing={
          canReschedule &&
          onEditTime && (
            <Button color="info" onClick={onEditTime}>
              Reschedule
            </Button>
          )
        }
      />
    ) : (
      <div className="d-flex gap-2">
        {canReschedule && onReschedule && (
          <button
            type="button"
            className={`btn btn-outline-primary`}
            onClick={onReschedule}
          >
            Update Time
          </button>
        )}
        {canReschedule && onEditTime && (
          <button
            type="button"
            className="btn btn-outline-info"
            onClick={onEditTime}
          >
            Cancel Rescheduling
          </button>
        )}
      </div>
    );

  return (
    <div className="buttons-container">
      {/* Accepted Session */}
      {!isPreviousSession && requestStatusId === 2 && (
        <div className="d-flex gap-2">
          {!isEditing && (
            <button
              type="button"
              onClick={() =>
                window.location.assign(
                  getMeetingLink(
                    // @ts-ignore
                    session.meeting!.meeting_id,
                    // @ts-ignore
                    session.meeting!.meeting_type.type,
                    isLearner ? "prejoining" : "meet"
                  )
                )
              }
              className="btn btn-secondary"
            >
              Join
            </button>
          )}
          {canReschedule && onReschedule && (
            <button
              type="button"
              className={`btn btn-outline-${isEditing ? "primary" : "info"} `}
              onClick={isEditing ? onReschedule : onEditTime}
            >
              {isEditing ? "Update Time" : "Reschedule"}
            </button>
          )}
          {isEditing && (
            <button
              type="button"
              className="btn btn-outline-info"
              onClick={onEditTime}
            >
              Cancel Rescheduling
            </button>
          )}

          {/* TODO: check if in duration check is needed here */}
          {!isEditing && onCancel && (
            <button
              type="button"
              className="btn btn-outline-danger"
              onClick={onCancel}
            >
              Cancel
            </button>
          )}
        </div>
      )}

      {/* Pending confirmation */}
      {requestStatusId === 1 &&
        (session.sent_by === profile.id ? (
          // is sent by same user
          <>
            <SassyAlert color="info" className="mt-1 mb-2">
              Waiting for a response by the {isLearner ? "tutor" : "learner"} on
              your session booking
            </SassyAlert>
            <div className="gap-row-sm">
              {canReschedule && onReschedule && (
                <>
                  <button
                    type="button"
                    className={`btn btn-outline-${
                      isEditing ? "primary" : "info"
                    } `}
                    onClick={isEditing ? onReschedule : onEditTime}
                  >
                    {isEditing ? "Update Time" : "Reschedule"}
                  </button>
                  {isEditing && (
                    <button
                      type="button"
                      className="btn btn-outline-info "
                      onClick={onEditTime}
                    >
                      Cancel Reschedule
                    </button>
                  )}
                </>
              )}
              {canCancel && !isEditing && onCancel && (
                <button
                  type="button"
                  className="btn btn-outline-danger "
                  onClick={onCancel}
                >
                  Cancel
                </button>
              )}
            </div>
          </>
        ) : (
          // is sent by other user
          <>
            <div className="d-flex gap-2">
              {onAccept && (
                <>
                  {!isEditing && (
                    <button
                      type="button"
                      className="btn btn-success"
                      onClick={onAccept}
                    >
                      Confirm
                    </button>
                  )}
                </>
              )}
              {canReschedule && onReschedule && (
                <>
                  <button
                    type="button"
                    className={`btn btn-outline-${
                      isEditing ? "primary" : "info"
                    } `}
                    onClick={isEditing ? onReschedule : onEditTime}
                  >
                    {isEditing ? "Update Time" : "Reschedule"}
                  </button>
                  {isEditing && (
                    <button
                      type="button"
                      className="btn btn-outline-info"
                      onClick={onEditTime}
                    >
                      Cancel Rescheduling
                    </button>
                  )}
                </>
              )}
              {onReject && (
                <button
                  type="button"
                  className=" btn btn-outline-danger"
                  onClick={onReject}
                >
                  Reject
                </button>
              )}
            </div>
          </>
        ))}

      {/* Rescheduled request */}
      {requestStatusId === 4 &&
        (session.rescheduled_by === profile.id ? (
          <>
            <SassyAlert color="info" className="mt-1 mb-2">
              Waiting for a response by the {isLearner ? "tutor" : "learner"} on
              your session booking
            </SassyAlert>

            {canCancel && onCancel && (
              <button
                type="button"
                className="btn btn-outline-danger "
                onClick={onCancel}
              >
                Cancel
              </button>
            )}
          </>
        ) : (
          <>
            <SassyAlert color="info" className="mb-2">
              The session was previously rescheduled
            </SassyAlert>

            <div className="d-flex gap-2">
              {onAccept && !isEditing && (
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={onAccept}
                >
                  Confirm
                </button>
              )}

              {canReschedule && onReschedule && (
                <>
                  <button
                    type="button"
                    className={`btn btn-outline-${
                      isEditing ? "primary" : "info"
                    } `}
                    onClick={isEditing ? onReschedule : onEditTime}
                  >
                    {isEditing ? "Update Time" : "Reschedule"}
                  </button>
                  {isEditing && (
                    <button
                      type="button"
                      className="btn btn-outline-info"
                      onClick={onEditTime}
                    >
                      Cancel Rescheduling
                    </button>
                  )}
                </>
              )}
              {onReject && (
                <button
                  type="button"
                  className=" btn btn-outline-danger"
                  onClick={onReject}
                >
                  Reject
                </button>
              )}
            </div>
          </>
        ))}
    </div>
  );
}
