import { DEFAULT_CONNECTED_USERS_PER_PAGE } from "constants";
import {
  ERRED_MISSED_SESSIONS,
  ERRED_PREVIOUS_SESSIONS,
  FETCHED_CALENDAR_SESSIONS,
  FETCHED_MISSED_SESSIONS,
  FETCHED_ORDERED_BUNDLES,
  FETCHED_PREVIOUS_SESSIONS,
  FETCHED_RECENT_TUTORS,
  FETCHED_UPCOMING_SESSIONS,
  POST_SESSION_REVIEW,
  RESET_CALENDAR_SESSIONS,
  RESET_MISSED_SESSIONS,
  RESET_ORDERED_BUNDLES,
  RESET_PREVIOUS_SESSIONS,
} from "./store-tutoring-learner-types";

const INITIAL_STATE = {
  upcomingSessions: {
    state: "loading",
    data: [],
    meta: {},
  },
  calendarSessions: {
    state: "loading",
    data: [],
  },
  recentTutors: {
    state: "loading",
    data: [],
  },
  previousSessions: {
    state: "loading",
    data: [],
  },
  missedSessions: {
    state: "loading",
    data: [],
  },
  orderedBundles: {
    state: "loading",
    data: [],
    page: 1,
    limit: DEFAULT_CONNECTED_USERS_PER_PAGE,
  },
  learnerSubjectsTutors: {
    state: "loading",
    data: [],
  },
  // when review of a session is added, this will update. and depending on this change we will refetch previous sessions list.
  sessionsReviewsCount: 0,
};

function learnerTutoringReducer(state = INITIAL_STATE, action) {
  return (
    {
      [FETCHED_UPCOMING_SESSIONS]: {
        ...state,
        upcomingSessions: {
          state: "loaded",
          data: action.payload?.sessions,
          meta: action.payload?.meta,
        },
      },
      ERRED_UPCOMING_SESSIONS: {
        ...state,
        upcomingSessions: {
          state: "erred",
          data: [],
          meta: {},
        },
      },
      RESET_LEARNER_UPCOMING_SESSIONS: {
        ...state,
        upcomingSessions: {
          state: "loading",
          data: [],
          meta: {},
        },
      },
      [FETCHED_RECENT_TUTORS]: {
        ...state,
        recentTutors: {
          state: "loaded",
          data: action.payload,
        },
      },
      ERRED_RECENT_TUTORS: {
        ...state,
        recentTutors: {
          state: "erred",
          data: [],
        },
      },
      [RESET_CALENDAR_SESSIONS]: {
        ...state,
        calendarSessions: {
          state: "loading",
          data: [],
        },
      },
      [FETCHED_CALENDAR_SESSIONS]: {
        ...state,
        calendarSessions: {
          state: "loaded",
          data: action.payload,
        },
      },
      ERRED_CALENDAR_SESSIONS: {
        ...state,
        calendarSessions: {
          state: "erred",
          data: [],
        },
      },
      [FETCHED_PREVIOUS_SESSIONS]: {
        ...state,
        previousSessions: {
          state: "loaded",
          data: action.payload,
        },
      },
      [RESET_PREVIOUS_SESSIONS]: {
        ...state,
        previousSessions: {
          state: "loading",
          data: [],
        },
      },
      [ERRED_PREVIOUS_SESSIONS]: {
        ...state,
        previousSessions: {
          state: "erred",
          data: [],
        },
      },
      [FETCHED_MISSED_SESSIONS]: {
        ...state,
        missedSessions: {
          state: "loaded",
          data: action.payload,
        },
      },
      [RESET_MISSED_SESSIONS]: {
        ...state,
        missedSessions: {
          state: "loading",
          data: [],
        },
      },
      [ERRED_MISSED_SESSIONS]: {
        ...state,
        missedSessions: {
          state: "erred",
          data: [],
        },
      },
      [POST_SESSION_REVIEW]: {
        ...state,
        sessionsReviewsCount: state.sessionsReviewsCount + 1,
      },
      [FETCHED_ORDERED_BUNDLES]: {
        ...state,
        orderedBundles: {
          ...action.payload,
          state: "loaded",
        },
      },
      ERRED_ORDERED_BUNDLES: {
        ...state,
        orderedBundles: {
          state: "erred",
          data: [],
        },
      },
      [RESET_ORDERED_BUNDLES]: {
        ...state,
        orderedBundles: {
          state: "loading",
          data: [],
        },
      },
      UPDATE_LEARNER_SUBJECTS_TUTORS: {
        ...state,
        learnerSubjectsTutors: {
          ...state.learnerSubjectsTutors,
          data: [action.payload, ...state.learnerSubjectsTutors.data],
        },
      },
      LEARNER_SUBJECTS_TUTORS_LOADED: {
        ...state,
        learnerSubjectsTutors: {
          ...state.learnerSubjectsTutors,
          state: "loaded",
        },
      },
    }[action.type] || state
  );
}

export default learnerTutoringReducer;
