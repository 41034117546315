import {IconInfoCircle} from "@tabler/icons";

const IToolTip = ({content, position = "top-right", size = "medium"}) => {
  return (
    <button
      type="button"
      className="ms-1"
      role="tooltip"
      aria-label={content}
      data-microtip-position={position}
      data-microtip-size={size}
    >
      <IconInfoCircle style={{width: "14px", height: "14px"}} />
    </button>
  );
};

export default IToolTip;
