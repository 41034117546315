import React from "react";
import Expired from "../status/expired";
import Pending from "../status/pending";
import Rejected from "../status/rejected";
import Accepted from "../status/accepted";
import Cancelled from "../status/cancelled";
import Rescheduled from "../status/rescheduled";
import SessionStatusCard from "../session-status-card";
import { useSessionStatus } from "hooks/sessions";

// Types.
import {
  SessionStatusCardRendererProps,
  SessionStatusCardRenderers,
} from "../session-status-card-types";
import { fetchUpcomingSessions } from "store";
import { useDispatch } from "react-redux";

/**
 * Standalone renderer for the card, deals with crucial details by itself.
 */
export default function TutorDefault(session: SessionStatusCardRendererProps) {
  const { status, isExpired } = useSessionStatus(session);

  const dispatch: (action: any) => void = useDispatch();

  const onActionSuccess = () => {
    // refetching upcoming sessions when any actions is performed by actions buttons of the card.
    dispatch({ type: "RESET_LEARNER_UPCOMING_SESSIONS" });
    // TODO: add pagination
    dispatch(fetchUpcomingSessions({}));
  };

  const Status =
    {
      1: Pending,
      2: Accepted,
      3: Rejected,
      4: Rescheduled,
      5: Cancelled,
    }[status] || Expired;

  return (
    <SessionStatusCard {...session}>
      <Status
        {...session}
        isExpired={isExpired}
        renderer={SessionStatusCardRenderers.TutorDefault}
        onActionSuccess={onActionSuccess}
      />
    </SessionStatusCard>
  );
}
