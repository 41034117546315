import { RawTutorProfile } from "constants/types/types-profile";
import { useSelector } from "react-redux";

type ReturnType = {
  profile: RawTutorProfile;
  isValidBasicInfo: () => boolean;
};

/**
 * Hook into the profile of a tutor.
 *
 * @returns
 */
export default function useTutorProfile(): ReturnType {
  const rawTutorProfile: RawTutorProfile = useSelector((store: any) => ({
    ...store.tutorDashboard.userInfo.user,
    availability: store.tutorDashboard.availability,
  }));

  function isValidBasicInfo(): boolean {
    return (
      !!rawTutorProfile.bio &&
      !!rawTutorProfile.dob &&
      !!rawTutorProfile.name &&
      !!rawTutorProfile.title &&
      !!rawTutorProfile.mobile_number &&
      !!rawTutorProfile.profile_picture &&
      !!rawTutorProfile.languages.length
    );
  }

  return { profile: rawTutorProfile, isValidBasicInfo };
}
