import React from "react";
import { boxStyles } from "constants/config";
import { Combobox } from "components/combobox";
import { Suggestions } from "components/suggestions";
import useStaticOptions from "hooks/use-static-options/use-static-options";

// types
import type { BaseStaticFieldProps } from ".";
import type { ComboboxProps } from "components/combobox";

export type StaticFieldProps = BaseStaticFieldProps &
  Omit<ComboboxProps, "options">;

export default function StaticField({
  value,
  onChange,
  children,
  staticKey,
  slotBelow,
  slotAbove,
  excludedValues = [],
  className = "",
  hasSuggestions = false,
  ...props
}: StaticFieldProps) {
  // Create the options.
  const options = useStaticOptions(staticKey).filter(
    (option) => !excludedValues.includes(option.value)
  );

  return (
    <div
      data-testclass="staticField"
      className={`d-flex flex-column ${boxStyles.xGap}`}
    >
      {slotAbove}
      <Combobox {...props} {...{ value, options, onChange }} />
      {/* {hasSuggestions && (
        <Suggestions
          keyToDisplay="label"
          onClickSuggestion={onChange}
          suggestions={options
            .filter(
              // eslint-disable-next-line eqeqeq
              (option) => !!!value || value.value != option.value
            ) // Only showing the first 5.
            .slice(0, 5)}
        />
      )} */}
      {slotBelow}
    </div>
  );
}
