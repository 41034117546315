import React, { useState } from "react";

// Icons.
import { IconChevronLeft } from "@tabler/icons";

// Components.
import { IconCalendarPlus, IconCalendarMinus } from "@tabler/icons";
import { TabBar } from "components";

// Utils.
import { useDispatch } from "react-redux";
import { toggleRightSidebar } from "store/actions";

// Features.
import TutoringTutorSidebarOfftime from "./tutoring-tutor-sidebar-offtime";
import TutoringTutorSidebarCalendar from "./tutoring-tutor-sidebar-calendar";
import TutoringTutorSidebarAvailability from "./tutoring-tutor-sidebar-availability";

/**
 * The sidebar of the tutor.
 * @param {Object} object
 * @param {"main" | "calendar-settings"} object.rightSideBarType The type of the sidebar to display.
 * @returns
 */
function TutoringTutorSidebar({ rightSideBarType }) {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(1);

  const onSwitchTabListener = (tab) => {
    activeTab !== tab && setActiveTab(tab);
    document.getElementById("right-bar").scrollTop = 0;
  };

  return (
    <>
      <div className="back-btn-box my-4 px-4 d-flex align-items-center">
        <button
          className="btn back-btn"
          onClick={() => dispatch(toggleRightSidebar())}
        >
          <IconChevronLeft />
        </button>
        <span className="ms-3 ">Back</span>
      </div>
      {rightSideBarType === "main" && (
        <>
          <TabBar>
            <TabBar.Item
              tabNumber={1}
              activeTab={activeTab}
              onSwitchTab={onSwitchTabListener}
            >
              <IconCalendarPlus className="d-sm-none" />
              <div className="d-none d-sm-block">My Availability</div>
            </TabBar.Item>
            <TabBar.Item
              tabNumber={2}
              activeTab={activeTab}
              onSwitchTab={onSwitchTabListener}
            >
              <IconCalendarMinus className="d-sm-none" />
              <div className="d-none d-sm-block">Manage time off</div>
            </TabBar.Item>
          </TabBar>
          {
            {
              1: (
                <TutoringTutorSidebarAvailability
                  switchTab={() => onSwitchTabListener(2)}
                />
              ),
              2: (
                <TutoringTutorSidebarOfftime
                  switchTab={() => onSwitchTabListener(1)}
                />
              ),
            }[activeTab]
          }
        </>
      )}

      {rightSideBarType === "calendar-settings" && (
        <TutoringTutorSidebarCalendar />
      )}
    </>
  );
}

export default TutoringTutorSidebar;
