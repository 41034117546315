import React from "react";

// ticket list component
const TicketList = ({ticketNo, title, date, status, onTicketClick}) => {
  return (
    <div className="list-group" onClick={() => onTicketClick(ticketNo)}>
      <a
        href="#"
        className="list-group-item list-group-item-action"
        aria-current="true"
      >
        <div className="d-flex w-100 justify-content-between">
          <div>
            <span>
              <small className="text-muted">{ticketNo}</small>
            </span>
            <h5 className="mb-1 text-secondary">{title}</h5>
          </div>
          <div className="d-flex flex-column">
            <span className="mb-1">
              <small>{date}</small>
            </span>
            {status === true ? (
              <span className="badge text-green bg-open-badge">Open</span>
            ) : (
              <span className="badge text-green bg-closed-badge">Closed</span>
            )}
          </div>
        </div>
      </a>
    </div>
  );
};

export default TicketList;
