const defaultStartTime = "09:00";
const defaultEndTime = "17:00";

const defaultTimes = [{ startTime: defaultStartTime, endTime: defaultEndTime }];

/**
 * Default availability for a tutor.
 */
export const DEFAULT_AVAILABILITY = [
  {
    av_id: 0,
    name: "Monday",
    isActive: true,
    times: defaultTimes,
    errors: [],
  },
  {
    av_id: 1,
    name: "Tuesday",
    isActive: true,
    times: defaultTimes,
    errors: [],
  },
  {
    av_id: 2,
    isActive: true,
    name: "Wednesday",
    times: defaultTimes,
    errors: [],
  },
  {
    av_id: 3,
    isActive: true,
    name: "Thursday",
    times: defaultTimes,
    errors: [],
  },
  {
    av_id: 4,
    name: "Friday",
    isActive: true,
    times: defaultTimes,
    errors: [],
  },
  {
    av_id: 5,
    isActive: true,
    name: "Saturday",
    times: defaultTimes,
    errors: [],
  },
  {
    av_id: 6,
    name: "Sunday",
    isActive: true,
    times: defaultTimes,
    errors: [],
  },
];

/**
 * Dummy availability for a tutor.
 */
export const DUMMY_AVAILABILITY = [
  {
    av_id: 0,
    name: "Monday",
    isActive: false,
    times: [{ startTime: "", endTime: "" }],
    errors: [],
  },
  {
    av_id: 1,
    name: "Tuesday",
    isActive: false,
    times: [{ startTime: "", endTime: "" }],
    errors: [],
  },
  {
    av_id: 2,
    isActive: false,
    name: "Wednesday",
    times: [{ startTime: "", endTime: "" }],
    errors: [],
  },
  {
    av_id: 3,
    isActive: false,
    name: "Thursday",
    times: [{ startTime: "", endTime: "" }],
    errors: [],
  },
  {
    av_id: 4,
    name: "Friday",
    isActive: false,
    times: [{ startTime: "", endTime: "" }],
    errors: [],
  },
  {
    av_id: 5,
    isActive: false,
    name: "Saturday",
    times: [{ startTime: "", endTime: "" }],
    errors: [],
  },
  {
    av_id: 6,
    name: "Sunday",
    isActive: false,
    times: [{ startTime: "", endTime: "" }],
    errors: [],
  },
];

export const DUMMY_SERIALIZED_AVAILABILITY = [
  {
    day: 1,
    status: 0,
    from: "00:00:00",
    to: "01:00:00",
    slots: [],
  },
  {
    day: 2,
    status: 0,
    from: "00:00:00",
    to: "01:00:00",
    slots: [],
  },
  {
    day: 3,
    status: 0,
    from: "00:00:00",
    to: "01:00:00",
    slots: [],
  },
  {
    day: 4,
    status: 0,
    from: "00:00:00",
    to: "01:00:00",
    slots: [],
  },
  {
    day: 5,
    status: 0,
    from: "00:00:00",
    to: "01:00:00",
    slots: [],
  },
  {
    day: 6,
    status: 0,
    from: "00:00:00",
    to: "01:00:00",
    slots: [],
  },
  {
    day: 7,
    status: 0,
    from: "00:00:00",
    to: "01:00:00",
    slots: [],
  },
];
