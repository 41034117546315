import React from "react";

export default function SimpleModalBody({
  children,
  className = "",
  ...props
}: React.ComponentPropsWithoutRef<"p">) {
  return (
    <p {...props} className={`mb-0 ${className}`}>
      {children}
    </p>
  );
}
