import { MATERIAL_TYPE_FILES, MATERIAL_TYPE_PAGES } from "constants/integers";

const MATERIAL_DUMMIES = [
  {
    id: 8,
    user_id: 416,
    title: "Introduction to New Material",
    description:
      "Introduction to New MaterialIntroduction to New MaterialIntroduction to New MaterialIntroduction to New Material",
    quiz_or_questions: 0,
    answers_key: 0,
    copyright: 1,
    created_at_: "2022-10-07 14:18:40",
    product_thumbnail:
      "https://d17thj9kqp1mkn.cloudfront.net/development_services/416/49e95301d8fcdbc23f623e0f2d1ed1e6.jpg",
    product_preview:
      "https://d17thj9kqp1mkn.cloudfront.net/development_services/416/051cc6849fe65d02df0dc53f09c25da2.jpg",
    created_at: null,
    updated_at: "2022-10-11T09:37:16.000000Z",
    published: 1,
    content_type: MATERIAL_TYPE_FILES,
    views: 0,
    parent_id: 8,
    version: 0,
    slug: "introduction-to-new-material",
    status: 6,
    curriculums: [
      {
        id: 15,
        content_id: 8,
        curriculum_id: 1,
        created_at: null,
        updated_at: null,
      },
      {
        id: 16,
        content_id: 8,
        curriculum_id: 24,
        created_at: null,
        updated_at: null,
      },
      {
        id: 17,
        content_id: 8,
        curriculum_id: 3,
        created_at: null,
        updated_at: null,
      },
    ],
    subjects: [
      {
        id: 17,
        content_id: 8,
        subject_id: 202,
        created_at: null,
        updated_at: null,
      },
      {
        id: 18,
        content_id: 8,
        subject_id: 194,
        created_at: null,
        updated_at: null,
      },
    ],
    topics: [
      {
        id: 35,
        content_id: 8,
        topic_id: 0,
        created_at: null,
        updated_at: null,
        title: "Introduction to New Material",
      },
    ],
    grades: [
      {
        id: 12,
        content_id: 8,
        grade_id: 1,
        created_at: null,
        updated_at: null,
      },
      {
        id: 13,
        content_id: 8,
        grade_id: 3,
        created_at: null,
        updated_at: null,
      },
    ],
    price: 10,
    types: [
      {
        id: 16,
        content_id: 8,
        type_id: 6,
        created_at: null,
        updated_at: null,
      },
    ],
    tags: [
      {
        id: 37,
        content_id: 8,
        title: "Introductio",
        created_at: null,
        updated_at: null,
      },
    ],
    seo_tags: [
      {
        id: 37,
        content_id: 8,
        title: "Introductio",
        created_at: null,
        updated_at: null,
      },
    ],
  },
  {
    id: 3,
    user_id: 416,
    title: "Hello World",
    description: "Hello",
    quiz_or_questions: 0,
    answers_key: 0,
    copyright: 1,
    created_at_: "2022-10-07 07:14:39",
    product_thumbnail:
      "https://d17thj9kqp1mkn.cloudfront.net/development_services/416/1528292ae851d1d58f534b57976162f1.jpg",
    product_preview:
      "https://d17thj9kqp1mkn.cloudfront.net/development_services/416/1528292ae851d1d58f534b57976162f1.jpg",
    created_at: null,
    updated_at: "2022-10-11T12:10:17.000000Z",
    published: 1,
    content_type: MATERIAL_TYPE_PAGES,
    views: 0,
    parent_id: 3,
    version: 0,
    slug: "hello-world",
    status: 6,
    curriculums: [
      {
        id: 5,
        content_id: 3,
        curriculum_id: 16,
        created_at: null,
        updated_at: null,
      },
    ],
    subjects: [
      {
        id: 4,
        content_id: 3,
        subject_id: 153,
        created_at: null,
        updated_at: null,
      },
    ],
    topics: [
      {
        id: 45,
        content_id: 3,
        topic_id: 0,
        created_at: null,
        updated_at: null,
        title: "Hello",
      },
    ],
    grades: [
      {
        id: 4,
        content_id: 3,
        grade_id: 1,
        created_at: null,
        updated_at: null,
      },
    ],
    price: 9,
    types: [
      {
        id: 6,
        content_id: 3,
        type_id: 5,
        created_at: null,
        updated_at: null,
      },
    ],
    tags: [
      {
        id: 51,
        content_id: 3,
        title: "Hello",
        created_at: null,
        updated_at: null,
      },
    ],
    seo_tags: [
      {
        id: 47,
        content_id: 3,
        title: "Hello",
        created_at: null,
        updated_at: null,
      },
    ],
  },
  {
    id: 1,
    user_id: 416,
    title: "Hey There",
    description: "Hey",
    quiz_or_questions: 0,
    answers_key: 0,
    copyright: 1,
    created_at_: "2022-10-06 15:34:30",
    product_thumbnail:
      "https://d17thj9kqp1mkn.cloudfront.net/development_services/416/d48788168076b999d36c4f3ccb75ba2f.jpg",
    product_preview:
      "https://d17thj9kqp1mkn.cloudfront.net/development_services/416/d48788168076b999d36c4f3ccb75ba2f.jpg",
    created_at: null,
    updated_at: "2022-10-11T12:10:08.000000Z",
    published: 1,
    content_type: MATERIAL_TYPE_FILES,
    views: 0,
    parent_id: 1,
    version: 0,
    slug: "hey",
    status: 6,
    curriculums: [
      {
        id: 2,
        content_id: 1,
        curriculum_id: 24,
        created_at: null,
        updated_at: null,
      },
    ],
    subjects: [
      {
        id: 1,
        content_id: 1,
        subject_id: 153,
        created_at: null,
        updated_at: null,
      },
    ],
    topics: [
      {
        id: 44,
        content_id: 1,
        topic_id: 0,
        created_at: null,
        updated_at: null,
        title: "Hello World",
      },
    ],
    grades: [
      {
        id: 1,
        content_id: 1,
        grade_id: 1,
        created_at: null,
        updated_at: null,
      },
    ],
    price: 90,
    types: [
      {
        id: 20,
        content_id: 1,
        type_id: 1,
        created_at: null,
        updated_at: null,
      },
    ],
    tags: [
      {
        id: 49,
        content_id: 1,
        title: "Hey",
        created_at: null,
        updated_at: null,
      },
      {
        id: 50,
        content_id: 1,
        title: "There",
        created_at: null,
        updated_at: null,
      },
    ],
    seo_tags: [
      {
        id: 46,
        content_id: 1,
        title: "Hey",
        created_at: null,
        updated_at: null,
      },
    ],
  },
];

export default MATERIAL_DUMMIES;
