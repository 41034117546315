import * as React from "react";

const Module = React.lazy(() => import("./phone-number-modal"));

const PhoneNumberModal = (props: any) => (
  <React.Suspense>
    <Module {...props} />
  </React.Suspense>
);

export default PhoneNumberModal;
