import React from "react";
import { boxStyles } from "constants/config";
import { Card } from "react-bootstrap";

type Props = Parameters<typeof Card.Body>[0];

export default function SassyCardBody({
  children,
  className = "",
  ...props
}: Props) {
  return (
    <Card.Body
      {...props}
      className={`d-flex flex-column gap-3 ${boxStyles.className} ${className}`}
    >
      {children}
    </Card.Body>
  );
}
