import React from "react";
import BaseModal from "../base-modal";
import useToast from "hooks/use-toast";
import { useDispatch } from "react-redux";
import { messages } from "constants/strings";
import { Button, Modal } from "react-bootstrap";
import { useRemoteProfile } from "hooks/profile";
import { useRemoteAvailability } from "hooks/availability";
import { useLocation, useNavigate } from "react-router-dom";

// Store.
import { DUMMY_SERIALIZED_AVAILABILITY } from "constants/dummies/dummies-availability";

export default function TimezoneUpdateErrorModal() {
  const toast = useToast();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { profile, updateProfile } = useRemoteProfile();
  const { availability, updateAvailability } = useRemoteAvailability();
  const dispatch: (dispatch: any) => void = useDispatch();

  const [currentStep, setCurrentStep] = React.useState(1);

  // A fallback towards the dummy data in case the other goes missing.
  const saferAvailability =
    availability.length > 0 ? availability : DUMMY_SERIALIZED_AVAILABILITY;

  // Extract the user's updated profile from state.
  const updatedProfile = state?.updatedProfile || profile;

  // Extract the error message from the state.
  const errorMessage = state?.errorMessage || "";

  /**
   * When the backup button is clicked.
   */
  function onClickBackupListener() {
    toast.loading();

    localStorage.setItem(
      "BACKUP_PROFILE_AVAILABILITY",
      JSON.stringify(saferAvailability)
    );

    const onSuccess = () => {
      toast.success("Backed up successfully");
      setCurrentStep(2);
    };

    // Clear the user's availability.
    updateAvailability({
      availability: JSON.stringify(
        (saferAvailability as object[]).map((instance) => ({
          ...instance,
          status: 0,
          slots: [],
        }))
      ),
      onSuccess,
      onFailure: ({ message }) => toast.error(message),
    });
  }

  /**
   * When the update button is clicked.
   */
  function onClickUpdateListener() {
    toast.loading();

    // Update the user's profile that they so crave.
    updateProfile({
      profile: updatedProfile,
      onSuccess: () => {
        toast.success();

        dispatch({
          type: "SET_WRONG_TIMEZONE_UPDATE_STATUS",
          payload: "updated",
        });

        setCurrentStep(3);
      },
      onFailure: ({ message }) => toast.error(message),
    });
  }

  /**
   * When the restore button is clicked.
   */
  function onClickRestoreListener() {
    toast.loading();

    const availability = localStorage.getItem("BACKUP_PROFILE_AVAILABILITY")!;

    // Request the server to set the availability from local cache.
    // Clear the user's availability.
    updateAvailability({
      availability,
      onSuccess: () => {
        toast.success("Restored successfully");

        // Close the modal.
        navigate(-1);
      },
      onFailure: () => toast.error(),
    });
  }

  return (
    <BaseModal show onHide={() => navigate(-1)}>
      <Modal.Header closeButton>
        <Modal.Title>Steps required to update your timezone</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column gap-3">
        <div className="alert alert-danger m-0">{errorMessage}</div>
        <div className="alert alert-primary m-0 p-2 d-flex gap-2 align-items-center justify-content-between">
          Step 1
          <Button
            role="tooltip"
            variant="secondary"
            data-microtip-position="bottom"
            data-microtip-size="medium"
            disabled={currentStep !== 1}
            onClick={onClickBackupListener}
            className="flex-grow-1 flex-shrink-0"
            aria-label={messages.BACKUP_AVAILABILITY_EXPLANATION}
          >
            <strong>Backup</strong> availability
          </Button>
        </div>
        <div className="alert alert-primary m-0 p-2 d-flex gap-2 align-items-center justify-content-between">
          Step 2
          <Button
            role="tooltip"
            variant="secondary"
            data-microtip-position="bottom"
            data-microtip-size="medium"
            disabled={currentStep !== 2}
            onClick={onClickUpdateListener}
            className="flex-grow-1 flex-shrink-0"
            aria-label={messages.UPDATE_TIMEZONE_ERROR_EXPLANATION}
          >
            <strong>Update</strong> timezone
          </Button>
        </div>
        <div className="alert alert-primary m-0 p-2 d-flex gap-2 align-items-center justify-content-between">
          Step 3
          <Button
            role="tooltip"
            variant="secondary"
            data-microtip-position="top"
            data-microtip-size="medium"
            disabled={currentStep !== 3}
            onClick={onClickRestoreListener}
            className="flex-grow-1 flex-shrink-0"
            aria-label={messages.RESTORE_AVAILABILITY_EXPLANATION}
          >
            <strong>Restore</strong> availability
          </Button>
        </div>
      </Modal.Body>
    </BaseModal>
  );
}
