// Hooks.
import useToast from "hooks/use-toast";
import { useRemoteSkills } from "hooks/tutor/skills";
import useSkillsFormSchema from "./use-skills-form-schema";
import useSkillsFormAdapter from "./use-skills-form-adapter";

// Types.
import type { SkillsFormState } from "./skills-form-types";
import { FormikHelpers } from "formik";

type Props = {
  children: ({
    initialValues,
    validationSchema,
    onSubmit,
  }: {
    validationSchema: unknown;
    initialValues: SkillsFormState;
    onSubmit: (
      state: SkillsFormState,
      helpers: FormikHelpers<SkillsFormState>
    ) => void;
  }) => JSX.Element;
};

export default function SkillsFormProvider({ children }: Props) {
  const toast = useToast();
  const { updateSkills } = useRemoteSkills();

  // Features.
  const { serialize } = useSkillsFormAdapter();
  const validationSchema = useSkillsFormSchema();

  /**
   * Initial values of the form.
   */
  const initialValues = {
    rating: 0,
    skill: {
      label: "",
      value: "",
    },
  };

  function onSubmit(state: SkillsFormState, { resetForm }) {
    toast.loading();

    updateSkills({
      skills: [serialize(state)],
      onFailure: ({ message }) => toast.error(message),
      onSuccess: () => {
        resetForm();
        toast.success();
      },
    });
  }

  return children({
    onSubmit,
    initialValues,
    validationSchema,
  });
}
