import React from "react";
import {Loader} from "components";

const View = React.lazy(() => import("./onboarding-learner-step1"));

const LearnerOnboardingStep1 = (props) => (
  <React.Suspense fallback={<Loader />}>
    <View {...props} />
  </React.Suspense>
);

export default LearnerOnboardingStep1;
