import { boxStyles } from "constants/config";
import React from "react";

export default function FormFooter({
  children,
  className = "",
  ...props
}: React.ComponentPropsWithoutRef<"footer">) {
  return (
    <footer
      {...props}
      className={`text-end justify-content-end ${boxStyles.mt} ${className}`}
    >
      {children}
    </footer>
  );
}
