import { requirements } from "constants/strings";
import { object, string } from "yup";

export default class PhoneNumberSchema {
  constructor() {
    return object({
      phoneNumber: string().required(requirements.INIT("mobile number")),
    });
  }
}
