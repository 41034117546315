import React, { useState } from "react";
import { Row } from "reactstrap";
import { TextField } from "../../components";
import Select from "react-select";
import "../dashboard/dashboard-learner/portal-dashboard.scss";

import { sessions } from "./session-data";
import SessionLIst from "./SessionLIst";
import RefundersList from "./RefundersList";

function DropMessage() {
  // session sate
  const [sessionsObject, setSessionsObject] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [sessionSelection, setSessionSelection] = useState([]);

  // meterials state
  const [materialObject, setMaterialObject] = useState([]);
  const [materialSelection, setMaterialSelection] = useState([]);

  // refund states
  const [creditsObject, setCreditsObject] = useState([]);
  const [creditSelection, setCreditSelection] = useState([]);

  const CategoryOptions = [
    { value: "default", label: "1:1 Tutor" },
    { value: "materials", label: "Materials" },
    { value: "transactions", label: "Transactions" },
    { value: "others", label: "Others" },
    { value: "refund", label: "Request refund" },
  ];

  //   --------------------SESSION FUNCTION SECTION -----------------------/
  function SesssionSelect() {
    const sessionOptions = [
      { value: "mathmetics", label: "Mathmetics" },
      { value: "science ", label: "Science" },
      { value: "geography", label: "Geography" },
      { value: "english", label: "English" },
    ];
    return (
      <Select
        options={sessionOptions}
        value={sessionSelection}
        onChange={(e) => handleSessionSelect(e)}
        components={<customComp />}
        placeholder="Choose session by Learner's name, Session title or Subject"
      />
    );
  }

  const handleSessionSelect = (e) => {
    setSessionSelection(e);
    let sessiondata = sessions.filter((val) => {
      if (val.subject.toLowerCase() == e.value.toLowerCase()) {
        return sessions;
      }
    });
    setSessionsObject(sessiondata);
  };

  // --------------- MATERIAL SESION -----------------------//
  function MaterialsSelect() {
    const materialOptions = [
      { value: "mathmetics", label: "Mathmetics" },
      { value: "science ", label: "Science" },
      { value: "geography", label: "Geography" },
      { value: "english", label: "English" },
    ];
    return (
      <Select
        options={materialOptions}
        value={materialSelection}
        onChange={(e) => handleMaterialSelect(e)}
        placeholder="Choose Material by Title"
      />
    );
  }

  const handleMaterialSelect = (e) => {
    setMaterialSelection(e);
    let materialSessionData = sessions.filter((val) => {
      if (val.subject.toLowerCase() == e.value.toLowerCase()) {
        return sessions;
      }
    });
    setMaterialObject(materialSessionData);
  };

  function RefrenceIdSelect() {
    const referenceOptions = [
      { value: "7543", label: "#7543" },
      { value: "4511 ", label: "#4531" },
      { value: "5566", label: "#5566" },
      { value: "5611", label: "#5611" },
    ];
    return (
      <Select options={referenceOptions} placeholder="Choose by refence id" />
    );
  }

  // credit select
  function CreditsSelect() {
    const creditsOptions = [
      { value: "mathmetics", label: "Mathmetics" },
      { value: "science ", label: "Science" },
      { value: "geography", label: "Geography" },
      { value: "english", label: "English" },
    ];
    return (
      <Select
        options={creditsOptions}
        value={creditSelection}
        onChange={(e) => handleCreditSelection(e)}
        placeholder="Choose available credits"
      />
    );
  }

  const handleCreditSelection = (e) => {
    setCreditSelection(e);
    let sessiondata = sessions.filter((val) => {
      if (val.subject.toLowerCase() == e.value.toLowerCase()) {
        return sessions;
      }
    });
    setCreditsObject(sessiondata);
  };

  // console.log("select >>" , selectedOptions); // for debugging only

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="card-title">Drop Message</div>
          <Row>
            <div className="col-sm-12 col-md-6 col-lg-6 ">
              <form action="form">
                <div className="form-group mb-3">
                  <label htmlFor="cartefory">Select Category</label>
                  {/* <select className="form-select" arialabel="Default select example">
                            <option selected value="1:1 Tutor">1:1 Tutor</option>
                            <option value="meterials" >Meterials</option>
                            <option value="transactions">Transactions</option>
                            <option value="others">Others</option>
                        </select> */}
                  <Select
                    isMulti={false}
                    name="category"
                    value={selectedOptions}
                    options={CategoryOptions}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Select Category"
                    onChange={setSelectedOptions}
                  />
                </div>
                {selectedOptions.value == "default" && (
                  <div className="form-group mb-3">
                    <label htmlFor="session text-musted">Select Session</label>
                    <SesssionSelect />
                  </div>
                )}

                {selectedOptions.value == "materials" && (
                  <div className="form-group mb-3">
                    <label htmlFor="session text-musted">
                      Select Meterials
                    </label>
                    <MaterialsSelect />
                  </div>
                )}

                {selectedOptions.value == "transactions" && (
                  <div className="form-group mb-3">
                    <label htmlFor="session text-musted">
                      Select Refrence id
                    </label>
                    <RefrenceIdSelect />
                  </div>
                )}

                {selectedOptions.value == "refund" && (
                  <div className="form-group mb-3">
                    <label htmlFor="session text-musted">
                      Select credits you want to refund
                    </label>
                    <CreditsSelect />
                  </div>
                )}

                {selectedOptions.value == "default" && (
                  <>
                    <div
                      className="list-group mb-3"
                      style={{
                        minHeight: "30vh",
                        height: "30vh",
                        overflowY: "scroll",
                      }}
                    >
                      {sessionsObject.map((item) => (
                        <SessionLIst key={item.title} rightTab={true} />
                      ))}
                    </div>
                    <div className="form-group mb-3">
                      <label htmlFor="subject">Add subject</label>
                      {/* <input type="text" className='form-control' placeholder='Subject' /> */}
                      <TextField
                        type="text"
                        className="user-account-input"
                        name="Subject"
                        placeholder="Subject"
                      />
                    </div>
                    <div className="form-group mb-3">
                      <textarea
                        name="description"
                        className="form-control drop-msg-desc-text-area"
                        id="description"
                        rows="50"
                        placeholder="Description"
                      ></textarea>
                    </div>
                    <div className="form-group">
                      <label htmlFor="attachment">Attachment</label>
                      <div className="col-md-12 mb-3">
                        <button className="btn attachment-btn">
                          <i className="bx bx-cloud-upload"></i> Upload
                          Screenshot
                          <input type="file" />
                        </button>
                      </div>
                    </div>
                  </>
                )}

                {(selectedOptions.value == "materials" ||
                  selectedOptions.value == "transactions" ||
                  selectedOptions.value == "others") && (
                  <>
                    <div
                      className="list-group mb-3"
                      style={{
                        minHeight: "30vh",
                        height: "30vh",
                        overflowY: "scroll",
                      }}
                    >
                      {materialObject.map((item) => (
                        <SessionLIst key={item.title} rightTab={false} />
                      ))}
                    </div>
                    <div className="form-group mb-3">
                      <label htmlFor="subject">How can we help</label>
                      {/* <input type="text" className='form-control' placeholder='Subject' /> */}
                      <TextField
                        type="text"
                        className="user-account-input"
                        name="Subject"
                        placeholder="Subject"
                      />
                    </div>
                    <div className="form-group mb-3">
                      <textarea
                        name="description"
                        className="form-control drop-msg-desc-text-area"
                        id="description"
                        rows="50"
                        placeholder="Description"
                      ></textarea>
                    </div>
                    <div className="form-group">
                      <label htmlFor="attachment">Attachment</label>
                      <div className="col-md-12 mb-3">
                        <button className="btn attachment-btn">
                          <i className="bx bx-cloud-upload"></i> Upload
                          Screenshot
                          <input type="file" />
                        </button>
                      </div>
                    </div>
                  </>
                )}
                {selectedOptions.value == "refund" && (
                  <>
                    <div
                      className="list-group mb-3"
                      style={{
                        minHeight: "30vh",
                        height: "30vh",
                        overflowY: "scroll",
                      }}
                    >
                      {creditsObject.map((item) => (
                        <RefundersList key={item.title} rightTab={true} />
                      ))}
                    </div>
                    <div className="form-group mb-3">
                      <label htmlFor="reson">Select your reason</label>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="issue"
                          id="issue1"
                        />
                        <label className="form-check-label" htmlFor="issue1">
                          Time conflict
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="issue"
                          id="issue"
                        />
                        <label className="form-check-label" htmlFor="issue">
                          Issue with tutor
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="issue"
                          id="issue"
                        />
                        <label className="form-check-label" htmlFor="issue">
                          Session no longer required
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="issue"
                          id="issue"
                        />
                        <label className="form-check-label" htmlFor="issue">
                          Found another tutor
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="issue"
                          id="issue"
                        />
                        <label className="form-check-label" htmlFor="issue">
                          other
                        </label>
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <textarea
                        name="descForOthers"
                        className="form-control drop-msg-desc-text-area"
                        id="descForOthers"
                        rows="50"
                        placeholder="Description"
                      ></textarea>
                    </div>
                  </>
                )}

                <div className="form-group mb-3">
                  <button className="btn btn-secondary">Send</button>
                </div>
              </form>
            </div>
          </Row>
        </div>
      </div>
    </>
  );
}

export default DropMessage;
