/* eslint-disable eqeqeq */
import React from "react";
import { useUser } from "hooks";
import { useDispatch } from "react-redux";
import { NOTIFICATION_TYPES } from "constants";
import NotificationDefault from "./notification-default";
import NotificationCompact from "./notification-compact";
import { updateInstantConnectMeetingStatus } from "store";
import {
  markNotificationAsRead,
  useNotificationDispatch,
} from "store/store-notification";
import { useNavigate } from "react-router-dom";
import { updateFreeConnectMeetingStatus } from "store";
import {} from "utils";
import ToastFactory from "utils/toast-factory";

/**
 * @typedef {Object} NotificationProps
 *
 * @property {Function} onClick
 *
 * @property {boolean | undefined} isCompact `true` if you need the compact variant.
 * Defaults to `false` and renders out a variant based on the screen width.
 * Doesn't respond to the screen size as the variants are javascript-generated.
 * They greatly differ in markup, making CSS-based differentiation a lot harder.
 */

/**
 * Notification component for all your notification needs.
 *
 * @param {NotificationProps & import("constants/types/types-notifications").Notification} object
 * The forwarded props.
 *
 * @returns {JSX.Element}
 *
 * @version 0.0.3
 * @author kashan-ahmad, Abdullah-Sajjad026
 *
 * @changelog
 * - 0.0.1: Initial version.
 */
function Notification({ isCompact, onClick, id, seen, type, ...props }) {
  const navigate = useNavigate();
  const { type: userType } = useUser();
  const toastFactory = new ToastFactory();
  const Component = isCompact ? NotificationCompact : NotificationDefault;

  const dispatch = useDispatch();
  const dispatchNotifications = useNotificationDispatch();

  const notificationType = NOTIFICATION_TYPES.find(
    (TYPE) => TYPE.id == type
  ) || {
    label: "Notification",
  };

  /* this confirm handler shall be called only if props object has link property and that shall be true if it is an instant-connect notification.
   This check of conditional executing is implemented in NotificationActionConfirm.
   */

  function onConfirm(props) {
    if (props.link) {
      const link = props.link;

      const meetingId = link.substring(
        link.indexOf("/meet/") + 6,
        link.indexOf("?")
      );
      const attendantId = props.sent_to;

      if (notificationType.id == "9") {
        dispatch(
          updateFreeConnectMeetingStatus({
            meetingId,
            status: "started",
            onSuccess: (res) => {
              if (res.status && res.status_code) {
                window.open(props.link, "_blank");
              }
            },
            onFailure: (error) => {
              toastFactory.error(error.message);
            },
          })
        );
      } else if (notificationType.id == "1") {
        dispatch(
          updateInstantConnectMeetingStatus({
            meetingId,
            attendantId,
            status: "started",
            onSuccess: (res) => {
              if (res.status && res.status_code) {
                window.open(props.link, "_blank");
              }
            },
            onFailure: (error) => {
              toastFactory.error(error.message);
            },
          })
        );
      }
    }
  }

  function clickListener() {
    onClick?.();

    // Navigate to the notifications view.
    navigate("/notifications");

    // Mark notification as read if it's not already read.
    seen == 0 &&
      dispatchNotifications(
        markNotificationAsRead({
          userType,
          ids: [parseInt(id)],
        })
      );
  }

  return (
    <Component
      {...props}
      {...{ id, seen }}
      onClick={clickListener}
      onConfirm={() => onConfirm(props)}
      notificationType={notificationType}
    />
  );
}

export default Notification;
