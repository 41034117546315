import { useMemo } from "react";
import { createSelectablesV3 } from "utils";
import { useStaticDataV3 } from "hooks/static-data";
import { StaticDataV3Key } from "constants/types/types-static-data";

/**
 * Hook to get the options for a static combobox.
 * @param staticKey The static key.
 */
export default function useStaticOptions(staticKey: StaticDataV3Key) {
  // Get the static data.
  // The data is preloaded in the app.js file.
  const { data } = useStaticDataV3([staticKey])[staticKey];

  // Create the options.
  return useMemo(() => createSelectablesV3(data), [data]);
}
