import {
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";

/**
 * A list wrapper to evade code duplication.
 *
 * @param {JSX.IntrinsicElements['ul']} props
 *
 * @returns {JSX.Element}
 *
 * @author kashan-ahmad
 * @version 0.0.1
 */
function ListWrapper({ className = "", children, ...props }) {
  return (
    <ul {...props} className={`m-0 p-0 ${className}`}>
      {children}
    </ul>
  );
}

/**
 * A renderer to render the list of badges.
 *
 * @param {BadgesListProps['badgeProps'] & BadgesListProps['entities']} props
 *
 * @returns {JSX.Element}
 *
 * @author kashan-ahmad
 * @version 0.0.1
 */
function BadgesRenderer({ entities, ...props }) {
  return entities.map((word, index) => (
    <li key={index}>
      <Badge {...props}>{word}</Badge>
    </li>
  ));
}

/**
 * @typedef {Object} BadgesListProps
 *
 * @property {number}  props.breakAt The number to break the list at.
 * @property {string[]} props.entities The list of entities to display.
 * @property {boolean} props.hasDropdown Default=false; Whether to show the
 * overflowing badges in a dropdown or not.
 *
 * @property {undefined |import("reactstrap").BadgeProps } props.badgeProps
 * @property {undefined |import("reactstrap").DropdownProps } props.dropdownProps
 * @property {undefined |import("reactstrap").DropdownMenuProps } props.dropdownMenuProps
 */

/**
 * A list of badges that displays a dropdown at a certain point to handle
 * overflow.
 *
 * @param {BadgesListProps & JSX.IntrinsicElements['ul']} props
 *
 * @returns {JSX.Element}
 *
 * @author kashan-ahmad
 * @version 0.0.1
 */
function BadgesList({
  breakAt,
  entities,
  badgeProps,
  dropdownProps,
  dropdownMenuProps,
  hasDropdown = false,
  ...rest
}) {
  // When there's no shit to render.
  if (!entities) return null;

  // When there's some shit but it's not shitty enough.
  if (entities.length === 0) return null;

  // When there's shit but it's needed without the dropdown.
  if (!breakAt || !hasDropdown || entities.length <= breakAt)
    return (
      <ListWrapper {...rest}>
        <BadgesRenderer {...badgeProps} {...{ entities }} />
      </ListWrapper>
    );

  const dropdownItems = entities.slice(breakAt);

  return (
    <ListWrapper {...rest}>
      {/* Render the list normally. */}
      <BadgesRenderer {...badgeProps} entities={entities.slice(0, breakAt)} />
      {/* Then render the dropdown. */}
      <UncontrolledDropdown {...dropdownProps}>
        <DropdownToggle {...badgeProps} color="inherit" aria-label="Show more">
          +{dropdownItems.length}
        </DropdownToggle>
        <DropdownMenu
          {...dropdownMenuProps}
          style={{ maxHeight: "10rem", overflow: "auto" }}
        >
          {dropdownItems.map((item, index) => (
            <DropdownItem key={index} className="p-2">
              <Badge {...badgeProps}>{item}</Badge>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
    </ListWrapper>
  );
}

export default BadgesList;
