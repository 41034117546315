import * as React from "react";
import { Icon } from "components";
import { parseMessage, scrollErrorIntoView } from "utils";
import { IconAlertCircle } from "@tabler/icons";

type Props = {
  message?: string;
} & JSX.IntrinsicElements["div"];

/**
 * Generic error message to be used with form fields.
 *
 * @returns {JSX.Element}
 */
function FormErrorMessage({
  message,
  className = "",
  ...props
}: Props): JSX.Element {
  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (!ref.current) return;

    ref.current.scrollIntoView({ behavior: "smooth", block: "center" });
  });

  if (!message) return <></>;

  // Sometimes, in complex forms, characters are passed from the parent error messages to the child ones.
  // And if you're wondering why we're checking for a length of 3, it's because an emoji is 2-3 characters long.
  if (typeof message !== "string" || message.length < 3) return <></>;

  return (
    <div
      {...props}
      ref={ref}
      data-testclass="formError"
      className={`d-flex gap-1 mt-1 text-danger ${className}`}
    >
      <Icon size="xs" style={{ transform: "translateY(1px)" }}>
        <IconAlertCircle />
      </Icon>
      <div className="fs-7 fw-bold">{parseMessage(message)}</div>
    </div>
  );
}

export default FormErrorMessage;
