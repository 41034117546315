import * as React from "react";
import { RawPhoneNumber } from "constants/types/types-phone-number";

type Context = {
  onClose: Function;
  onCancel: Function;
  state: RawPhoneNumber;
  setState: React.Dispatch<React.SetStateAction<RawPhoneNumber>>;
};

export const initialRawPhoneNumberState: RawPhoneNumber = {
  country_code: "",
  mobile_number: "",
};

export const initialRawPhoneNumberContext = {
  onClose: () => {},
  setState: () => {},
  onCancel: () => {},
  state: initialRawPhoneNumberState,
};

const RawPhoneNumberContext = React.createContext<Context>(
  initialRawPhoneNumberContext
);

export default RawPhoneNumberContext;
export { default as RawPhoneNumberContextProvider } from "./raw-phone-number-context-provider";
