import React from "react";
import { Rating } from "@smastrom/react-rating";
import { ratingStarStyles } from "constants/config";

type Props = Parameters<typeof Rating>[0];

export default function RatingField(props: Props) {
  return (
    <Rating
      {...props}
      data-testid="ratingSelect"
      itemStyles={ratingStarStyles}
      style={{ maxWidth: 250, maxHeight: 35 }}
    />
  );
}
