import React from "react";
import { Row, Col, Container, Progress } from "reactstrap";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import { IconArrowBigRight } from "@tabler/icons";
import HelpImage from "../../../assets/images/helpIcon.png";
import "../../../assets/css/profile.css";

function TutorOnboardLayout({ onboardingStep, ...props }) {
  const userName = props.userDetails && props.userDetails.name;

  const qualification = !isEmpty(props.storedQualification);
  const experience = !isEmpty(props.storedExperience);
  const skills = !isEmpty(props.storedSkills);
  const certificates = !isEmpty(props.storedCertificate);
  const subjects = !isEmpty(props.storedSubjects);

  const progressPercent =
    20 +
    (qualification && 20) +
    (experience && 20) +
    (skills && 10) +
    (certificates && 20) +
    (subjects && 10);

  return (
    <section className="content">
      <Container fluid className="tutor-profile-setup">
        <Row style={{ backgroundColor: "#f8f8fb" }}>
          <Col className="p-0">
            <div className="card rounded-0">
              <div className="p-4 align-items-center d-flex justify-content-between">
                <div>
                  <h1 className="sthiSt">
                    Hi <span>&#128075;</span>, {userName}
                  </h1>
                  <span className="fillText heading-text">
                    Filling out all the information required to setup your
                    profile takes less then 10 minutes.
                  </span>
                </div>
              </div>
            </div>
          </Col>

          <Col md={onboardingStep === "Profile Setup" ? 9 : 12}>
            {props.children}
          </Col>
          {onboardingStep === "Profile Setup" && (
            <Col md={3} lg={3}>
              <div className="user-details">
                {props.onBoardingStep === 1 && (
                  <div className="card rounded-0 mb-3">
                    <div className="card-body">
                      <h5 className="card-title mb-5">
                        {progressPercent}%{" "}
                        <span className="proComTextStyle">
                          Profile Completed
                        </span>
                      </h5>
                      <div id="myProgress2" className="mt-4">
                        {/* <div id="myBars2"></div> */}
                      </div>
                      <Progress
                        value={progressPercent}
                        color="success"
                        className="progress-sm"
                      />
                      {/* <img alt="" src={ProfileCompleteImage} height={200} /> */}
                    </div>
                  </div>
                )}
                <>
                  <div className="card  rounded-0 mb-3">
                    <div className="card-body">
                      <p className="card-text inTextStylings">
                        This information is required to publish materials and
                        create a session. But, you may{" "}
                        {/* <Link to="#" className="text-danger">
                          SKIP
                        </Link>{" "} */}
                        this for now.
                      </p>
                    </div>
                  </div>
                  <div className="card rounded-0 bg-success bg-opacity-10 mb-4">
                    <div className="help-card">
                      <div>
                        <img alt="" src={HelpImage} />
                        <span className="helpCenterTextStyles">
                          Help Center
                        </span>
                      </div>
                      <span className="">
                        <IconArrowBigRight />
                      </span>
                    </div>
                  </div>
                </>
              </div>
            </Col>
          )}
        </Row>
      </Container>
    </section>
  );
}

const mapToStateProps = (state) => ({
  userDetails: state.auth.userDetails,
  storedQualification: state.tutorDashboard.qualification.storedQualification,
  storedExperience: state.tutorDashboard.experience.storedExperience,
  storedSkills: state.tutorDashboard.skills.storedSkills,
  storedCertificate: state.tutorDashboard.certificate.storedCertificate,
  storedSubjects: state.tutorDashboard.subject.storedSubjects,
  onBoardingStep: state.tutorDashboard.onBoardingStep,
});

export default connect(mapToStateProps, {})(TutorOnboardLayout);
