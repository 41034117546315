/* eslint-disable eqeqeq */
import * as React from "react";
import { useMemo } from "react";

// Components.
import { Formik } from "formik";
import { Row, Col, Button, CardTitle } from "reactstrap";
import { Loader, SassyAlert, SassyForm } from "components";

// Utils.
import ALLOWED_FORMATS from "./form-tutor-qualification-formats";
import validationSchema from "./form-tutor-qualification-schema";
import {
  createSelectablesV3,
  generateArrayOfYears,
  getFileNameFromUrl,
  getKeyFromMap,
} from "utils";
import { DEFAULT_LIMIT_TITLES } from "constants";
import { useStaticDataV3 } from "hooks/static-data";
import { boxStyles } from "constants/config";

/**
 * Form that manages the function of editing and adding the tutor's experience.
 *
 * @param {Object} props
 * @param {Function} props.onSubmit
 * @param {Function} props.onReset
 * @param {TutorExperience} props.state
 * @param {"add" | "edit"} props.variant
 *
 * @returns {JSX.Element}
 */
function TutorQualificationForm({
  state,
  variant = "add",
  onSubmit,
  onReset,
  onCloseForm,
  allowFormClose = true,
}) {
  const yearsOptions = useMemo(() => {
    return generateArrayOfYears().map((i) => ({ label: i, value: i }));
  }, []);

  const { qualifications, degrees } = useStaticDataV3([
    "qualifications",
    "degrees",
    // "curriculums",
  ]);

  if (
    qualifications.state === "loading" ||
    degrees.state === "loading"
    // || curriculums.state === "loading"
  )
    return <Loader />;

  if (
    qualifications.state === "erred" ||
    degrees.state === "erred"
    // || curriculums.state === "erred"
  )
    return (
      <SassyAlert title="An error has occurred while loading Qualifications Form." />
    );

  const qualificationsOptions = createSelectablesV3(qualifications.data);
  const degreesOptions = createSelectablesV3(degrees.data);
  // const curriculumsOptions = createOptions(curriculums.data, "title");

  return (
    <Formik
      enableReinitialize
      initialValues={state}
      onSubmit={(values, { resetForm }) => {
        onSubmit(values);
        resetForm();
      }}
      validationSchema={validationSchema}
    >
      {({ values, handleChange, setFieldTouched }) => (
        <SassyForm className="vstack gap-3" data-testid="qualificationsForm">
          <header className="hstack justify-content-between">
            <CardTitle className="text-capitalize">
              {variant} Qualification
            </CardTitle>
          </header>
          <Row>
            <Col sm={12}>
              <SassyForm.Input
                name="institute"
                maxLength={DEFAULT_LIMIT_TITLES}
                label="College / University Name"
                placeholder="Enter the institute name"
              />
            </Col>
            <Col sm={6}>
              <SassyForm.Select
                options={degreesOptions}
                name="degree"
                label="Degree"
                placeholder="Select a Degree"
                isMulti={false}
                returnKey="label"
                value={
                  values.degree
                    ? {
                        label: values.degree,
                        value: getKeyFromMap(degrees.data, values.degree),
                      }
                    : ""
                }
                {...{ handleChange, setFieldTouched }}
              />
            </Col>

            <Col sm={6}>
              <SassyForm.Select
                options={qualificationsOptions}
                name="qualification"
                label="Qualification"
                placeholder="Select a qualification"
                isMulti={false}
                returnKey="label"
                value={
                  values.qualification
                    ? {
                        label: values.qualification,
                        value: getKeyFromMap(
                          qualifications.data,
                          values.qualification
                        ),
                      }
                    : ""
                }
                {...{ handleChange, setFieldTouched }}
              />
            </Col>

            <Col xs={12}>
              <Row>
                {/* <Col sm={6}>
                  <SassyForm.Select
                    options={curriculumsOptions}
                    isMulti={false}
                    name="curriculum"
                    label="Curriculum"
                    placeholder="Select Curriculum"
                    returnKey="label"
                    value={
                      values.curriculum
                        ? {
                            label: values.curriculum,
                            value: getIdFromArrUsingKey(
                              curriculums.data,
                              "title",
                              values.curriculum
                            ),
                          }
                        : ""
                    }
                    {...{handleChange, setFieldTouched}}
                  />
                </Col> */}
                <Col sm={6}>
                  <SassyForm.Select
                    options={yearsOptions}
                    isMulti={false}
                    name="year_of_graduation"
                    label="Year Of Graduation"
                    placeholder="Select the Year of graduation"
                    value={
                      values.year_of_graduation
                        ? {
                            label: values.year_of_graduation,
                            value: values.year_of_graduation,
                          }
                        : ""
                    }
                    {...{ handleChange, setFieldTouched }}
                  />
                </Col>
              </Row>
            </Col>

            <Col sm={6}>
              <SassyForm.File
                maxSize={2}
                name="document"
                accept={ALLOWED_FORMATS.join(",")}
                label={
                  <div className="d-flex gap-1">
                    Upload Certificate
                    <span className="text-lowercase">(jpg, png, pdf)</span>
                  </div>
                }
                {...{ values, handleChange, setFieldTouched }}
              />
              {state.fileName && (
                <p>
                  <span className="fw-bold">Existing Document: </span>{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={state.fileName}
                    data-testid="uploadedFileUrl"
                    download={getFileNameFromUrl(state.fileName)}
                  >
                    {state.fileName?.substring(0, 20)} ...{" "}
                  </a>
                </p>
              )}
            </Col>
            <Col
              xs="12"
              className={`d-flex align-items-center justify-content-end ${boxStyles.xGap}`}
            >
              {allowFormClose && variant === "add" && (
                <Button
                  outline
                  type="button"
                  color="secondary"
                  onClick={onCloseForm}
                  data-testid="doneButton"
                >
                  Cancel
                </Button>
              )}
              {variant === "edit" && onReset && (
                <Button
                  outline
                  type="reset"
                  color="secondary"
                  onClick={onReset}
                  data-testid="cancelButton"
                >
                  Cancel Editing
                </Button>
              )}
              <Button type="submit" data-testid="submitButton">
                Save
              </Button>
            </Col>
          </Row>
        </SassyForm>
      )}
    </Formik>
  );
}

export default TutorQualificationForm;
