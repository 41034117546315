// Types.
import type { TourAction, TourState } from ".";

const initialState: TourState = {
  stepStatuses: [],
};

export default function tourReducer(
  state = initialState,
  action: TourAction
): TourState {
  switch (action.type) {
    case "SET_TOUR_STEP_STATUSES":
      return {
        ...state,
        stepStatuses: action.payload,
      };
    default:
      return state;
  }
}
