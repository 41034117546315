import moment from "moment/moment";
import BootstrapTable from "react-bootstrap-table-next";
import {Card, CardBody, CardHeader, CardTitle} from "reactstrap";

/**
 *
 */
const RefersHistoryTable = ({state, data}) => {
  const refersHistoryTableColumns = [
    {
      dataField: "created_at",
      text: "Date",
      formatter: (_, {created_at}) => moment(created_at).format("DD MMM YYYY"),
    },
    {
      dataField: "name",
      text: "Name",
    },
    // {
    //   dataField: "channel",
    //   text: "Channel",
    // },
    // {
    //   dataField: "reward",
    //   text: "Reward",
    //   formatter: (_, {reward}) => <h5 className="fw-bold fs-5">{reward}</h5>,
    // },
    // {
    //   dataField: "status",
    //   text: "Status",
    //   formatter: (_, {status}) => {
    //     return (
    //       <Badge
    //         pill
    //         className="px-2 py-1"
    //         color={
    //           status === "pending"
    //             ? "error"
    //             : status === "completed"
    //             ? "success"
    //             : "info"
    //         }
    //       >
    //         {capitalize(status)}
    //       </Badge>
    //     );
    //   },
    // },
  ];

  return (
    <>
      <div className="referral-history-table">
        <Card className="border-md-1 mb-0">
          <CardHeader className="px-3 py-3 bg-white d-flex align-items-center justify-content-between flex-wrap">
            <CardTitle className="mb-0 ">Referrals History</CardTitle>
            {/* <DateFilters
              onApplyFilters={(startDate, endDate) => {
                setStartDate(startDate);
                setEndDate(endDate);
              }}
              onRemoveFilters={() => {
                setStartDate("");
                setEndDate("");
              }}
            /> */}
          </CardHeader>
          <CardBody className="p-0 pb-3 vstack gap-5">
            <div>
              <BootstrapTable
                striped={false}
                bordered={false}
                keyField="email"
                data={data}
                columns={refersHistoryTableColumns}
                wrapperClasses="overflow-auto"
                headerWrapperClasses="table-light"
                classes="table align-middle table-nowrap table-check mb-0"
              />

              {/* <Pagination
                        type="pages"
                        className="m-3"
                        itemsPerPage={per_page}
                        totalItems={parseInt(total)}
                        currentPage={parseInt(page)}
                        onChangePage={(newPage) => {
                          dispatch({type: "LOADING_PAYMENT_HISTORY"});
                          navigate(
                            `/${title.toLowerCase()}/${product_type}/page/${newPage}`
                          );
                        }}
                      /> */}
            </div>
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default RefersHistoryTable;
