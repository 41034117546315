import { isMobile, resetAllScrollbars } from "utils";
import ShepherdTour from "shepherd.js/src/types/tour";
import ShepherdStep from "shepherd.js/src/types/step";
import {
  TourStep,
  TourStepStatus,
  AllowedStepPosition,
  RemoteTourStepStatus,
} from "constants/types/types-tour";

/**
 * Adapt the RemoteTourStep interface to the one used by the client.
 * @param step The step to adapt.
 * @returns {TourStepStatus} The adapted step.
 *
 * @example
 * // Returns { key: "step-1", isVisited: true, actionTaken: "complete" }
 *
 * getAdaptedTourStepStatus({
 *  key: "step-1",
 *  viewed: 1,
 *  action: "proceeded",
 * });
 *
 * @see {@link TourStepStatus}
 * @see {@link RemoteTourStepStatus}
 */
export function getAdaptedTourStepStatus(
  status: RemoteTourStepStatus
): TourStepStatus {
  return {
    key: status.key,
    isVisited: status.viewed === 1,
    actionTaken: status.action === "proceeded" ? "complete" : "cancel",
  };
}

/**
 * Adapt the TourStepStatus interface to the one used by the server.
 * @param step The step to adapt.
 * @returns {RemoteTourStepStatus} The adapted step.
 *
 * @example
 * // Returns { key: "step-1", viewed: 1, action: "proceeded" }
 *
 * getAdaptedRemoteTourStepStatus({
 *  key: "step-1",
 *  isVisited: true,
 *  actionTaken: "complete",
 * });
 *
 * @see {@link TourStepStatus}
 * @see {@link RemoteTourStepStatus}
 */
export function getAdaptedRemoteTourStepStatus(
  status: TourStepStatus
): RemoteTourStepStatus {
  return {
    key: status.key,
    viewed: status.isVisited ? 1 : 0,
    action: status.actionTaken === "complete" ? "proceeded" : "skipped",
  };
}

function getAdaptedTargetPosition(
  position: NonNullable<TourStep["target"]>["position"]
): AllowedStepPosition {
  // If the position is undefined, we assume it's "top".
  if (!position) return "top";

  // If the position doesn't include a "+", we assume it's a valid position.
  if (!position.includes("+")) return position as AllowedStepPosition;

  // If the position includes a "+", we assume it's a valid position for mobile and desktop.
  const [first, second] = position.split("+");

  // If the device is mobile, we return the first position.
  return isMobile()
    ? (first as AllowedStepPosition)
    : (second as AllowedStepPosition);
}

/**
 * Adapt the TourStep interface to the one used by Shepherd.js
 * @param target The target to adapt
 * @returns The adapted target
 */
function getAdaptedTarget(target?: TourStep["target"]) {
  return target
    ? {
        element: target.element,
        on: getAdaptedTargetPosition(target.position),
      }
    : undefined;
}

/**
 * Adapt the TourStep interface to the one used by Shepherd.js
 * @param closeButton The close button to adapt
 * @returns The adapted close button
 */
function getAdaptedCloseButton(closeButton?: TourStep["closeButton"]) {
  // If the data doesn't say the button is hidden, we assume it's visible.
  return closeButton
    ? {
        enabled: !closeButton.hidden,
        label: closeButton["aria-label"],
      }
    : {
        enabled: true,
        label: "Close",
      };
}

/**
 * Adapt the TourStep interface to the one used by Shepherd.js
 * @param actionButtons The action buttons to adapt
 * @returns The adapted action buttons
 */
function getAdaptedActionButtons(actionButtons: TourStep["actionButtons"]) {
  return actionButtons.map((button) => ({
    text: button.text,
    classes: button.className,
    label: button["aria-label"],
    secondary: button.isSecondary,
    // If the data doesn't say the button is disabled, we assume it's enabled.
    disabled: button.disabled ?? false,
    action(this: ShepherdTour) {
      ({
        forwards: this.next,
        backwards: this.back,
        stop: this.cancel,
        "forwards+scrollToTop": () => {
          // Reset the scrollbars to the top.
          resetAllScrollbars("smooth");

          // Move to the next step.
          this.next();
        },
      })[button.action]();
    },
  }));
}

/**
 * Adapt the TourStep interface to the one used by Shepherd.js
 * @param step The step to adapt
 * @returns The adapted step
 */
export function getAdaptedStep(step: TourStep): ShepherdStep.StepOptions {
  return {
    id: step.id,
    text: step.text,
    title: step.title,
    canClickTarget: false,
    classes: step.className,
    highlightClass: "shepherd-highlight",
    attachTo: getAdaptedTarget(step.target),
    cancelIcon: getAdaptedCloseButton(step.closeButton),
    buttons: getAdaptedActionButtons(step.actionButtons),
  };
}
