import styles from "./icon.module.scss";

/**
 * A general purpose (polymorphic) Icon component.
 *
 * Can be passed a `size` with one of the following values:
 * - sm
 * - md (default)
 * - lg
 *
 * A whole node can be passed as the component's child to be rendered inside.
 *
 * @returns {JSX.Element}
 * @version 0.0.1
 * @author kashan-ahmad
 */
function Icon({
  tag: Tag = "span",
  size = "sm",
  className = "",
  children,
  ...props
}) {
  return (
    <Tag {...props} className={`${styles[`icon-${size}`]} ${className}`}>
      {children}
    </Tag>
  );
}

export default Icon;
