import React from "react";
import Title from "react-bootstrap/ModalTitle";
import Header, { ModalHeaderProps } from "react-bootstrap/ModalHeader";

type Props = ModalHeaderProps;

export default function ModalHeader(props: Props) {
  return (
    <Header {...props}>
      <Title>{props.children}</Title>
    </Header>
  );
}
