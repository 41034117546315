import {
  REGISTER_SUCCESS,
  REGISTER_FAILED,
  LOGIN_SUCCESS,
  // LOGIN_FAILED,
  USER_LOADED,
  USER_UPDATE,
  LOGOUT,
  SET_TOKEN,
} from "./authActionTypes";

import { WEBSITE_URL } from "../../constants";
import { removeToken } from "utils/utils-auth";

export const initialState = {
  loading: true,
  userDetails: null,
  userPersonal: null,
  isRegistered: false,
  isAuth: false,
  token: localStorage.getItem("token"),
  userType: "",
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER_SUCCESS:
      localStorage.setItem("token", action.payload.access_token);
      return {
        ...state,
        loading: false,
        userDetails: action.payload,
        isRegistered: true,
        isAuth: true,
        userType: action.payload.user_type,
      };
    case SET_TOKEN:
      localStorage.setItem("token", action.payload);
      return {
        ...state,
        token: action.payload,
      };
    case USER_LOADED:
      localStorage.setItem("token", action.payload.access_token);
      localStorage.setItem("userType", action.payload.user_type);
      return {
        ...state,
        userDetails: action.payload,
        isRegistered: true,
        isAuth: true,
        loading: false,
        user_type: action.payload.user_type,
      };
    case USER_UPDATE:
      return {
        ...state,
        loading: false,
        userDetails: {
          ...state.userDetails,
          dob: action.payload.data.dob,
          name: action.payload.data.name,
          phone: action.payload.data.mobile_number,
        },
      };
    case LOGIN_SUCCESS:
      localStorage.setItem("token", action.payload.access_token);
      // localStorage.setItem("userType", action.payload.user_type);

      return {
        ...state,
        loading: false,
        userDetails: action.payload,
        isRegistered: true,
        isAuth: true,
        token: localStorage.getItem("token"),
        userType: action.payload.user_type,
      };
    case REGISTER_FAILED:
      // Clear the store.
      // This will re-trigger the authentication effect in app.js
      return {
        ...state,
        loading: true,
        userDetails: null,
        userPersonal: null,
        isRegistered: false,
        isAuth: false,
        token: null,
        userType: "",
      };

    default:
      return state;
  }
};

export default authReducer;
