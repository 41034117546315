const ACTION_TYPES = [
  "SET_GOALS",
  "ERRED_GOALS",
  "SET_GRADES",
  "ERRED_GRADES",
  "SET_DEGREES",
  "ERRED_DEGREES",
  "SET_SUBJECTS",
  "ERRED_SUBJECTS",
  "SET_SKILLS",
  "ERRED_SKILLS",
  "SET_INTERESTS",
  "ERRED_INTERESTS",
  "SET_LANGUAGES",
  "ERRED_LANGUAGES",
  "SET_CURRICULUMS",
  "ERRED_CURRICULUMS",
  "SET_REQUEST_TYPES",
  "ERRED_REQUEST_TYPES",
  "SET_QUALIFICATIONS",
  "ERRED_QUALIFICATIONS",
  "SET_MATERIAL_TYPES",
  "ERRED_MATERIAL_TYPES",
  "SET_LEARNING_STYLES",
  "ERRED_LEARNING_STYLES",
];

export default ACTION_TYPES;
