import React from "react";
import { SassyCard } from "components/cards";
import { boxStyles } from "constants/config";

type Props = {
  className?: string;
  children: React.ReactNode;
};

export default function OnboardingTutorContainer({
  children,
  className = "",
}: Props) {
  return (
    <SassyCard as="footer" className={className}>
      <SassyCard.Body>
        <div className={`d-flex ${boxStyles.xGap}`}>{children}</div>
      </SassyCard.Body>
    </SassyCard>
  );
}
