import useApiEffect from "hooks/use-api-effect";
import useUser from "hooks/use-user";
import { useDispatch, useSelector } from "react-redux";
import { fetchTransactionAmount } from "store";

export default function useTransactions(deps: Array<any> = []) {
  const user = useUser();
  const dispatch: (action: any) => unknown = useDispatch();

  const transactions = useSelector(
    (state: any) => state.transactions.transactionAmount
  );

  useApiEffect(() => {
    dispatch(fetchTransactionAmount({ userType: user.type }));
  }, deps);

  return transactions;
}
