import type {CommonStoreState, CommonStoreAction} from ".";

const initialState: CommonStoreState = {
  wrongTimezoneUpdateStatus: "",
};

export default function commonStoreReducer(
  state = initialState,
  action: CommonStoreAction
) {
  return (
    {
      SET_WRONG_TIMEZONE_UPDATE_STATUS: {
        ...state,
        wrongTimezoneUpdateStatus: action.payload,
      },
    }[action.type] || state
  );
}
