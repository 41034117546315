import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useStaticDataV3 } from "hooks/static-data";
import { createSelectablesV3 } from "utils";

/**
 * The StaticSelect component to be reused where static data select is required.
 * @param {Object} object The properties
 * passed to the middleware.
 * @param {string} object.label - Possible Values: 'Grades' || 'Subjects' || 'Curriculums'

 * @param {any} object.selectedOptions
 * The state passed from parent component to be used as value inside select.
 * @param {any} object.setSelectedOptions
 * The function to update state in parent component and that state is reused as value in select.
 * @param {boolean} object.isMulti
 * The boolean to choose either select can has multiple options selected.
 * @param {boolean} object.isClearable
 * The boolean to choose either select component should be clearable or not.
 * @param {boolean} object.closeMenuOnSelect
 * The boolean which decides either select options list should get closed on selecting an option or not.
 *
 * @version 0.0.1
 * @author Abdullah-Sajjad026
 *
 */
function StaticSelect({
  label,
  selectedOptions,
  setSelectedOptions,
  isMulti,
  isClearable,
  closeMenuOnSelect,
}) {
  const { curriculums, grades, subjects } = useStaticDataV3([
    "curriculums",
    "grades",
    "subjects",
  ]);

  const curriculumProps = curriculums.data;
  const gradeProps = grades.data;
  const subjectProps = subjects.data;

  // creating options array to be passed in select based on label.
  const [options, setOptions] = useState();

  useEffect(() => {
    if (label === "Curriculums") {
      if (curriculumProps && curriculumProps.size > 0)
        setOptions([
          {
            label: "Curriculum",
            options: createSelectablesV3(curriculumProps),
          },
        ]);
    } else if (label === "Grades") {
      if (gradeProps && gradeProps.size > 0)
        setOptions([
          {
            label: "Grades",
            options: createSelectablesV3(gradeProps),
          },
        ]);
    } else if (label === "Subjects") {
      if (subjectProps && subjectProps.size > 0)
        setOptions([
          {
            label: "Subjects",
            options: createSelectablesV3(subjectProps),
          },
        ]);
    }
  }, [curriculumProps, gradeProps, subjectProps, label]);

  return (
    <Select
      defaultValue={null}
      isClearable={isClearable ?? false}
      value={selectedOptions}
      options={options}
      isMulti={isMulti ?? true}
      onChange={setSelectedOptions}
      required
      classNamePrefix="select2-selection"
      closeMenuOnSelect={closeMenuOnSelect ?? false}
    />
  );
}
export default StaticSelect;
