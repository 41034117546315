import React from "react";
import Modal from "react-bootstrap/Modal";
import Image, { ImageProps } from "react-bootstrap/Image";

type Props = ImageProps & {
  header?: React.ReactNode;
  footer?: React.ReactNode;
};

export default function ImageModal({ src, header, footer, ...props }: Props) {
  return (
    <>
      {header}
      <Modal.Body>
        <Image {...props} fluid rounded src={src} alt="Preview" />
      </Modal.Body>
      {footer}
    </>
  );
}
