/* eslint-disable eqeqeq */
import * as React from "react";

// Components.
import { MyModalHeader } from "components";
import { Field, Form, Formik } from "formik";
import { IconRefresh, IconSearch } from "@tabler/icons";
import { Input, Modal, ModalBody, ModalFooter } from "reactstrap";

// Utils.
import { apiService } from "service";
import { getAuthHeaders } from "utils";
import SearchFormResult from "./form-search-result";

/**
 * @typedef {Object} SearchFormProps
 *
 * @property {(record: any) => void} onSelectRecord
 * The callback for a searched item's click event.
 *
 * @property {string} url
 * The url to perform the search at.
 *
 * @property {string | undefined} paramName
 * The name of the parameter to send as the searched string. Defaults to `query`
 *
 * @property {string} title
 * The title of the modal.
 *
 * @property {string | undefined} initialQueryValue
 * The initial value of the search input. Used in the case where a search results are already being displayed.
 *
 * @property {string} indexTitleKey The key who's value is to be used as the search result's title.
 *
 * @property {string | undefined} indexDescriptionKey The key who's value is to be used as the search result's description.
 *
 * @property {Boolbacks['onSuccess'] | undefined} onSuccess
 * Callback for the success of a searched response.
 *
 * @property {Boolbacks['onFailure'] | undefined} onFailure
 * Callback for the failure of a searched response.
 */

/**
 * A generic modal-based search form that displays the searched results in itself and returns the clicked record to the parent.
 * @param {SearchFormProps & import("reactstrap").ModalProps}
 * @returns {JSX.Element}
 *
 * @author kashan-ahmad
 * @version 0.0.1
 */
function SearchForm({
  url,
  title,
  toggle,
  onReset,
  onSuccess,
  onFailure,
  indexTitleKey,
  onSelectRecord,
  indexDescriptionKey,
  paramName = "query",
  initialQueryValue = "",
  ...props
}) {
  /**
   * @type {[{data: Array, state: RequestState}]}
   */
  const [searchResult, setSearchResult] = React.useState({
    data: [],
    state: "idle",
  });

  function onSubmitListener({ query }) {
    setSearchResult({
      state: "loading",
    });

    apiService.get({
      url,
      headers: getAuthHeaders(),
      data: [{ key: paramName, value: query }],
      onSuccess: ({ data }) => {
        setSearchResult({
          data,
          state: "loaded",
        });

        onSuccess?.(data);
      },
      onFailure: (error) => {
        setSearchResult({
          state: "erred",
        });

        onFailure?.(error);
      },
    });
  }

  function onResetListener(handleReset) {
    onReset?.();
    handleReset?.();
    setSearchResult({
      data: [],
      state: "idle",
    });
  }

  return (
    <Formik
      isInitialValid
      enableReinitialize
      onSubmit={onSubmitListener}
      initialValues={{ query: initialQueryValue }}
    >
      {({ handleReset, values }) => (
        <>
          <div className="hstack gap-2 w-100">
            <Input
              readOnly
              role="button"
              onClick={toggle}
              placeholder={title}
              aria-label="Open modal"
              defaultValue={values.query}
            />
            <button
              type="button"
              role="tooltip"
              aria-label="Reset search"
              data-microtip-position="top"
              className="p-2 border-1 rounded bg-light"
              onClick={() => onResetListener(handleReset)}
            >
              <IconRefresh size={20} aria-hidden={true} />
            </button>
          </div>
          <Modal {...props} toggle={toggle} autoFocus={false}>
            <ModalBody className="vstack gap-3">
              <MyModalHeader title={title} toggleModal={toggle} />
              <Form className="hstack gap-2">
                <Field
                  required
                  autoFocus
                  as={Input}
                  name="query"
                  placeholder={title}
                />
                <button
                  type="submit"
                  role="tooltip"
                  aria-label="Commit search"
                  data-microtip-position="top"
                  className="p-2 border-1 rounded bg-light"
                >
                  <IconSearch size={20} aria-hidden={true} />
                </button>
              </Form>
            </ModalBody>
            <ModalFooter className="justify-content-start">
              <SearchFormResult
                {...{
                  ...searchResult,
                  onSelectRecord,
                  indexTitleKey,
                  indexDescriptionKey,
                }}
              />
            </ModalFooter>
          </Modal>
        </>
      )}
    </Formik>
  );
}

export default SearchForm;
