import * as React from "react";
import { Formik } from "formik";
import { IconButton } from "components";
import OtpFields from "./otp-form-fields";
import Provider from "./otp-form-provider";
import { IconChevronLeft } from "@tabler/icons";
import { Button, Form, Modal } from "react-bootstrap";

/**
 * Form to update the phone number of a user.
 *
 * @returns {JSX.Element}
 */
export default function OtpForm({
  onClose,
  onCancel,
}: {
  onClose: Function;
  onCancel: Function;
}): JSX.Element {
  return (
    <Provider {...{ onClose }}>
      {({ schema, onSubmit, onResend, initialValue }) => (
        <Formik
          onSubmit={onSubmit}
          validationSchema={schema}
          initialValues={{
            code: initialValue,
          }}
        >
          {({ handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title>Verify OTP</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <OtpFields name="code" />
              </Modal.Body>
              <Modal.Footer className="d-flex justify-content-between">
                <IconButton
                  type="button"
                  title="Back"
                  tooltipPosition="right"
                  onClick={() => onCancel()}
                >
                  <IconChevronLeft size={18} />
                </IconButton>
                <div className="d-flex gap-2">
                  <Button
                    variant="outline-secondary"
                    onClick={() => onResend()}
                  >
                    Resend
                  </Button>
                  <Button type="submit" variant="secondary">
                    Confirm
                  </Button>
                </div>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      )}
    </Provider>
  );
}
