import BadgesList from "./list-badges";

/**
 * The same list of badges but small.
 *
 * @param {import("./list-badges").BadgesListProps & JSX.IntrinsicElements['ul']} props
 *
 * @returns {JSX.Element}
 *
 * @author kashan-ahmad
 *
 * @version 0.0.1
 */
export default function LeadingBadgesList({
  badgeProps,
  dropdownProps,
  className = "",
  ...rest
}) {
  return (
    <BadgesList
      {...rest}
      className={`hstack gap-1 flex-wrap ${className}`}
      dropdownProps={{
        className: "bg-transparent p-0 border-0",
      }}
      dropdownMenuProps={{
        className: "shadow-0 border-1",
      }}
      badgeProps={{
        className:
          "fs-7 px-2 py-1 bg-soft bg-info text-secondary border rounded-pill",
      }}
    />
  );
}
