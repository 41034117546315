export const contentBuilder = [
  {
    label: "2021 Complete Python Bootcamp From Zero to Hero in Python",
    subject: "Math",
    grade: "Grade 10",
    degree: "CBSE",
    rating: 5.0,
    enrollments: 85,
    bought: 41234,
    views: 43567,
    earned: 3761.0,
  },
  {
    label: "2021 Complete Python Bootcamp",
    subject: "Science",
    grade: "Grade 8",
    degree: "BSCS",
    rating: 4.5,
    enrollments: 95,
    bought: 41234,
    views: 43567,
    earned: 3761.0,
  },
  {
    label: "2021 Complete Physics degree",
    subject: "Physics",
    grade: "Grade 9",
    degree: "MS.Physics",
    rating: 4.4,
    enrollments: 65,
    bought: 41234,
    views: 43567,
    earned: 3761.0,
  },
  {
    label: "2021 Complete Physics degree",
    subject: "Physics",
    grade: "Grade 9",
    degree: "MS.Physics",
    rating: 4.4,
    enrollments: 65,
    bought: 41234,
    views: 43567,
    earned: 3761.0,
  },
  {
    label: "2021 Complete Physics degree",
    subject: "Physics",
    grade: "Grade 9",
    degree: "MS.Physics",
    rating: 4.4,
    enrollments: 65,
    bought: 41234,
    views: 43567,
    earned: 3761.0,
  },
];

export const topic = [
  {
    id: 1,
    topic: "Independent Work Packet",
  },
  {
    id: 2,
    topic: "Worksheets",
  },
  {
    id: 3,
    topic: "Lesson Plans",
  },
  {
    id: 4,
    topic: "Printables",
  },
  {
    id: 5,
    topic: "Activities",
  },
  {
    id: 6,
    topic: "Assessment",
  },
  {
    id: 7,
    topic: "Math Centers",
  },
  {
    id: 8,
    topic: "Literacy Center Ideas",
  },
  {
    id: 9,
    topic: "Projects",
  },
];

export const hours = [{ value: "--", label: "--", isDisabled: true }];
for (let i = 1; i <= 12; i++) {
  hours.push({
    label: i.toString()?.padStart(2, "0"),
    value: i.toString()?.padStart(2, "0"),
  });
}

export const disable12AmEndTimeHours = [
  { value: "--", label: "--", isDisabled: true },
];

for (let i = 1; i <= 12; i++) {
  let isDisabled = false;

  if (i === 12) {
    isDisabled = true;
  }

  disable12AmEndTimeHours.push({
    label: i.toString()?.padStart(2, "0"),
    value: i.toString()?.padStart(2, "0"),
    isDisabled: isDisabled,
  });
}

export const minutes = [
  { label: "--", value: "--", isDisabled: true },
  { label: "00", value: "00" },
  { label: "15", value: "15" },
  { label: "30", value: "30" },
  { label: "45", value: "45" },
];

export const period = [
  { label: "--", value: "--", isDisabled: true },
  { label: "AM", value: "AM" },
  { label: "PM", value: "PM" },
];
export const disable12AmEndTimePeriod = [
  { label: "--", value: "--", isDisabled: true },
  { label: "AM", value: "AM", isDisabled: true },
  { label: "PM", value: "PM" },
];

export const emptyItem = {
  hour: { label: "--", value: "--" },
  minute: { label: "--", value: "--" },
  period: { label: "--", value: "--" },
};
