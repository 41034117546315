import { Outlet } from "react-router-dom";

// Features.
import LearningMaterialManage from "./learning-material-manage";
import LearningMaterialDetails from "./learning-material-details";
import LearningMaterialDashboard from "./learning-material-dashboard";

// Store.
import { LearningMaterialProvider } from "store/store-learning-materials";

function LearningMaterial() {
  return (
    <LearningMaterialProvider>
      <Outlet />
    </LearningMaterialProvider>
  );
}
export {
  LearningMaterial,
  LearningMaterialManage,
  LearningMaterialDetails,
  LearningMaterialDashboard,
};
