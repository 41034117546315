const INITIAL_STATE = {
  fileMaterial: {
    data: [],
    state: "loading",
  },
  pricingPlans: {
    data: [],
    state: "loading",
  },
  learningMaterial: {
    data: {
      list: [],
      total_drafted: 0,
      total_published: 0,
    },
    page_no: 1,
    number_of_pages: 1,
    state: "loading",
  },
};

/**
 * @param {ContentBuilderState} state
 * @param {ContentBuilderAction} action
 * @returns {ContentBuilderState}
 */
const ContentBuilderReducer = (state = INITIAL_STATE, action) =>
  ({
    SET_LEARNING_MATERIAL: {
      ...state,
      learningMaterial: {
        ...action.payload,
        data: action.payload?.data || [],
        page_no: action.payload?.page_no || 1,
        number_of_pages: action.payload?.number_of_pages || 1,
        state: "loaded",
      },
    },
    ERRED_LEARNING_MATERIAL: {
      ...state,
      learningMaterial: {
        ...state.learningMaterial,
        state: "erred",
      },
    },
    LOADING_LEARNING_MATERIAL: {
      ...state,
      learningMaterial: {
        ...state.learningMaterial,
        state: "loading",
      },
    },
    SET_PRICING_PLANS: {
      ...state,
      pricingPlans: {
        state: "loaded",
        data: action.payload,
      },
    },
    ERRED_PRICING_PLANS: {
      ...state,
      pricingPlans: {
        ...state.pricingPlans,
        state: "erred",
      },
    },
  }[action.type] || state);

export default ContentBuilderReducer;
