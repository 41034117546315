const LAYOUT_TYPES = {
  HORIZONTAL: "horizontal",
  VERTICAL: "vertical",
};

const LAYOUT_WIDTH_TYPES = {
  FLUID: "fluid",
  BOXED: "boxed",
  SCROLLABLE: "scrollable",
};

const TOP_BAR_THEME_TYPES = {
  LIGHT: "light",
  DARK: "dark",
  COLORED: "colored",
};

const LEFT_BAR_THEME_IMG_TYPES = {
  NONE: "none",
  IMG1: "img1",
  IMG2: "img2",
  IMG3: "img3",
  IMG4: "img4",
};

const LEFT_BAR_TYPES = {
  DEFAULT: "default",
  COMPACT: "compact",
  ICON: "icon",
};

const LEFT_BAR_THEME_TYPES = {
  LIGHT: "light",
  COLORED: "colored",
  DARK: "dark",
  WINTER: "winter",
  LADYLIP: "ladylip",
  PLUMPLATE: "plumplate",
  STRONGBLISS: "strongbliss",
  GREATWHALE: "greatwhale",
};

export {
  LAYOUT_TYPES,
  LAYOUT_WIDTH_TYPES,
  TOP_BAR_THEME_TYPES,
  LEFT_BAR_THEME_IMG_TYPES,
  LEFT_BAR_TYPES,
  LEFT_BAR_THEME_TYPES,
};
