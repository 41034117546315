import { DEFAULT_PROGRESS_VALUE } from "constants";
import React from "react";
import { Card, CardBody, Progress } from "reactstrap";
import Icon from "./icon/icon";

const ProgressCard = ({
  qty,
  title,
  icon,
  leftWing,
  rightWing,
  progress,
  className = "",
}) => {
  return (
    <Card className={`bg-gray-100 mb-0 border-1 shadow-none ${className}`}>
      <CardBody className="vstack gap-2 px-3">
        <div className="hstack gap-2">
          <Icon.Rounded size="lg" className="bg-light text-success">
            {icon}
          </Icon.Rounded>
          <div className="fw-bold">
            <div className="fs-4 text-success">{qty}</div>
            {title}
          </div>
        </div>
        <div className="hstack justify-content-between">
          <span>{leftWing}</span>
          <span>{rightWing}</span>
        </div>
        <Progress
          color="success"
          className="bg-light border"
          value={progress || DEFAULT_PROGRESS_VALUE}
        />
      </CardBody>
    </Card>
  );
};

export default ProgressCard;
