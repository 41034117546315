/* eslint-disable eqeqeq */
export default class PaginationAdapter {
  serialize(state) {
    return {
      data: state.records,
      page_no: state.currentPage || 1,
      number_of_pages: state.numOfPages || 1,
    };
  }

  deserialize(state) {
    return {
      records: state.data,
      currentPage: state.page_no || 1,
      numOfPages: state.number_of_pages || 1,
    };
  }
}
