import PaginationAdapter from "adapters/pagination";

/* eslint-disable eqeqeq */
export default class LearningMaterialFilesAdapter {
  paginationAdapter = new PaginationAdapter();

  serialize(state) {
    const data = !Array.isArray(state.records)
      ? []
      : state.records.map((record) => ({
          id: record.id || "",
          content_id: record.materialId || "",
          title: record.title || "",
          description: record.description || "",
          file: record.fileSource || "",
        }));

    return this.paginationAdapter.serialize({ ...state, data });
  }

  deserialize(state) {
    const data = !Array.isArray(state.data)
      ? []
      : state.data.map((record) => ({
          id: record.id || "",
          materialId: record.content_id || "",
          title: record.title || "",
          description: record.description || "",
          fileSource: record.file || "",
        }));

    return this.paginationAdapter.deserialize({ ...state, data });
  }
}
