import React from "react";

// Components.
import { Skills } from "modules/skills";
import { BioForm } from "modules/forms/bio";
import { SassyCard } from "components/cards";
import { SkillsForm } from "modules/forms/skills";
import { SubjectsForm } from "modules/forms/subjects";
import FormFooter from "components/forms/form-footer";
import { BasicInfoForm } from "modules/forms/basic-info";
import SubmitButton from "components/forms/submit-button";
import {
  Title,
  Footer,
  Header,
  Container,
  ErrorsList,
  ConfirmButton,
} from "../components";
import { Col, Row } from "react-bootstrap";
import { ProfilePhotoTutorForm } from "modules/forms/profile-photo/tutor";

// Utils.
import { getEnvInt } from "utils/utils-env";
import { gridStyles } from "constants/config";
import { capitalize } from "utils/utils-functions";
import { navItems } from "../layout/onboarding-tutor-layout";

// Hooks.
import useToast from "hooks/use-toast";
import { useNavigate } from "react-router-dom";
import { useOnboardingValidator } from "../hooks";
import useRemoteProfile from "hooks/profile/use-remote-profile";
import MaxLimitHint from "components/forms/max-limit-hint";

export default function OnboardingTutorBasics() {
  //Hooks.
  const toast = useToast();
  const navigate = useNavigate();
  const { profile, updateProfile } = useRemoteProfile();
  const { getBasicErrors, isStepAlreadyPassed } = useOnboardingValidator();

  // Checks.
  const haveSkillsExceeded =
    profile.skills.length >= getEnvInt("TUTOR_MAX_SKILLS");

  // Listeners.
  function clickNextListener() {
    // Validate if all steps of onboarding are completed.
    const errors = getBasicErrors(profile);

    if (errors.length) {
      toast.render(<ErrorsList errors={errors} />);
      return;
    }

    // inform the backend that the user has completed the profile step
    isStepAlreadyPassed("profile") ||
      updateProfile({
        profile: {
          onboarding_status: "profile",
        },
        onSuccess: () => {},
        onFailure: ({ message }) => {
          // toast.error(message);
        },
      });

    // Navigate to next step.
    navigate(navItems[1].href);
  }

  return (
    <>
      <Header title={`👋 Hi ${capitalize(profile.name)}`} />
      <Container>
        <Row className={gridStyles.g}>
          <Col xs={6} lg={4} xl={2} className="d-flex justify-content-start">
            <ProfilePhotoTutorForm value={profile.profile_picture} />
          </Col>
          <Col xs={12} xl={10}>
            <BasicInfoForm
              footer={
                <FormFooter>
                  <SubmitButton>Save</SubmitButton>
                </FormFooter>
              }
            />
          </Col>
        </Row>
      </Container>
      <Container>
        <Title>
          Subjects&nbsp;
          <MaxLimitHint limit={getEnvInt("TUTOR_MAX_SUBJECTS")} />
        </Title>
        <SubjectsForm
          footer={
            <FormFooter>
              <SubmitButton>Save</SubmitButton>
            </FormFooter>
          }
        />
      </Container>
      <Container>
        {!haveSkillsExceeded && (
          <>
            <Title>
              Skills&nbsp;
              <MaxLimitHint limit={getEnvInt("TUTOR_MAX_SKILLS")} />
            </Title>
            <SkillsForm
              footer={
                <FormFooter>
                  <SubmitButton>Save</SubmitButton>
                </FormFooter>
              }
            />
          </>
        )}
        <Skills
          header={
            <>
              {!haveSkillsExceeded && <hr className="divider" />}
              <Title>Your Skills</Title>
            </>
          }
        />
        {haveSkillsExceeded && (
          <small>
            <i>
              <strong>Note:</strong> You have reached the maximum number of
              skills. Please remove some skills to add more.
            </i>
          </small>
        )}
      </Container>
      <Container>
        <Title>Bio</Title>
        <BioForm
          footer={
            <FormFooter>
              <SubmitButton>Save</SubmitButton>
            </FormFooter>
          }
        />
      </Container>
      <Footer>
        <ConfirmButton onClick={clickNextListener}>Next</ConfirmButton>
      </Footer>
    </>
  );
}
