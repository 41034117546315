import { Icon } from "../..";
import { IconRefresh } from "@tabler/icons";
import { Link, useNavigate } from "react-router-dom";

/**
 * @typedef {Object} NotificationActionRescheduleProps
 *
 * @property {import("react-router-dom").LinkProps['to']} to
 * The route to be redirect upon click.
 */

/**
 * The action that allows the user to interact
 * with a `reschedule` button.
 *
 * @param {NotificationActionRescheduleProps} object
 *
 * @returns {JSX.Element}
 * @version 0.0.4
 * @author kashan-ahmad.
 *
 * @changelog
 * - 0.0.5: Replaced `Link` with button and integrated `useNavigate` hook in order to call `e.stopPropagation` as the parent was given a click listener of it's own.
 * - 0.0.4: Removed `onReschedule` prop and added a typedef.
 * - 0.0.3: Added the `onReschedule` handler.
 * - 0.0.2: Replaced the `Link` with a `button`.
 * - 0.0.1: Initial version.
 */
function NotificationActionReschedule({ to }) {
  const navigate = useNavigate();

  return (
    <button
      type="button"
      onClick={(e) => {
        e.stopPropagation();
        navigate(to);
      }}
      className="text-primary d-inline-flex align-items-center gap-2"
    >
      <Icon size="sm">
        <IconRefresh />
      </Icon>
      Reschedule
    </button>
  );
}

export default NotificationActionReschedule;
