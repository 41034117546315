import { Button, Modal, ModalBody } from "reactstrap";
import {
  MyModalHeader,
  NotificationAvatar,
  NotificationCompactBody,
} from "components";
import ModalSessionRescheduleBody from "./modal-reschedule-body";

/**
 * @typedef {Object} ModalSessionRescheduleProps
 *
 * @property {Boolean} isCompact
 * Whether to show the compact variant or not.
 *
 * @property {import("constants/types/types-notifications").Notification['updated_at']} updated_at
 *
 * @property {import("constants/types/types-notifications").Notification['description']} description
 *
 * @property {import("constants/types/types-notifications").Notification['profile_picture']} profile_picture
 *
 * @property {import("constants/types/types-notifications").NotificationType} notificationType
 */

/**
 * @param {ModalSessionRescheduleProps & import("reactstrap").ModalProps}
 */
function ModalSessionReschedule({
  toggle,
  isCompact = false,
  updated_at,
  description,
  profile_picture,
  notificationType,
  ...props
}) {
  return (
    <Modal {...props} toggle={toggle}>
      <ModalBody className="p-3 gap-column-xl">
        <MyModalHeader
          title="Are you sure you want to reject the session?"
          toggleModal={toggle}
        />

        {isCompact ? (
          <div className="d-flex gap-3">
            <NotificationAvatar {...{ profile_picture, notificationType }} />
            <div className="gap-column-sm flex-grow-1">
              <NotificationCompactBody
                {...{ updated_at, description, notificationType }}
              />
              <hr className="divider" />
              <ModalSessionRescheduleBody />
            </div>
          </div>
        ) : (
          <ModalSessionRescheduleBody />
        )}
        <footer className="d-flex justify-content-end gap-2">
          <Button color="secondary" style={{ minWidth: "5rem" }} outline>
            Yes
          </Button>
          <Button color="secondary" style={{ minWidth: "5rem" }}>
            No
          </Button>
        </footer>
      </ModalBody>
    </Modal>
  );
}

export default ModalSessionReschedule;
