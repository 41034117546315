import React, {useState} from "react";
import {Col} from "reactstrap";
import "assets/css/term-and-conditions.css";
import {WEBSITE_URL} from "constants";
import {store} from "store";
import {storeOnboarding} from "store/tutordashboard/action";
import ToastFactory from "utils/toast-factory";
import {messages} from "constants/strings";

function TermAndCondition(props) {
  const toastFactory = ToastFactory();

  const [agree1, setAgree1] = useState(false);
  const [agree2, setAgree2] = useState(false);
  const [agree3, setAgree3] = useState(false);

  const allTermsAgreed = agree1 && agree2 && agree3;

  function nextSteps() {
    // props.nextStep()
    if (allTermsAgreed) {
      store.dispatch(storeOnboarding(() => window.location.replace("/")));
    } else {
      toastFactory.error(messages.ERR_STEP("Terms & Conditions"));
    }
  }

  return (
    <div className="recent-grid terms-and-conditions">
      <div className="card rounded-0">
        <div className="card-body">
          <span className="bInfoText">Tutor agreement</span>
          {/* <p className="card-text bioPragraphStylings mt-4">
              Having your legal agreements seprated like this-with seprated
              links and text-makes it clear to users that each section holds
              different information and should be investigated individually.
            </p> */}
          {/* <p className="card-text bioPragraphStylings mt-4">
              Having your legal agreements seprated like this-with seprated
              links and text-makes it clear to users that each section holds
              different information and should be investigated
              individually.Having your legal agreements seprated like this-with
              seprated links and text-makes it clear to users that each section
              holds different information and should be investigated{" "}
              <span id="dots"></span>
              <span id="more">individually.</span>
              <button
                onClick={myFunction}
                id="myBtn"
                className="bg-light text-success"
              >
                Read more
              </button>
            </p> */}
          <div className="form-check mt-3">
            <input
              className="form-check-input regular-checkbox"
              type="checkbox"
              value=""
              id="flexCheckChecked"
              checked={agree1}
              onClick={() => setAgree1(!agree1)}
            />
            <p className="card-text bioPragraphStylings ">
              You agree to the{" "}
              <a
                rel="noreferrer"
                target="_blank"
                className="terms-condition-underline"
                href={`${WEBSITE_URL}/tutor-contract`}
              >
                Tutor Contract
              </a>
              {` , `}
              <a
                rel="noreferrer"
                target="_blank"
                className="terms-condition-underline"
                href={`${WEBSITE_URL}/terms-conditions`}
              >
                {" "}
                Terms & Conditions
              </a>
              {` , `}
              <a
                rel="noreferrer"
                target="_blank"
                className="terms-condition-underline"
                href={`${WEBSITE_URL}/privacy-policy`}
              >
                {" "}
                Privacy Policy
              </a>
              {` & `}
              <a
                rel="noreferrer"
                target="_blank"
                className="terms-condition-underline"
                href={`${WEBSITE_URL}/disclaimer`}
              >
                {" "}
                Disclaimer{" "}
              </a>
            </p>
          </div>
          <div className="form-check mt-3">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckChecked"
              checked={agree2}
              onClick={() => setAgree2(!agree2)}
            />
            <p className="card-text bioPragraphStylings ">
              You agree to stay on the TheTutor.me platform while tutoring
              learners you’ve connected with here. You agree to be honest and
              full of integrity in your interactions with learners and
              TheTutor.me employees, and to be especially communicative with
              your learners.
            </p>
          </div>
          <div className="form-check mt-3">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckChecked"
              checked={agree3}
              onClick={() => setAgree3(!agree3)}
            />
            <p className="card-text bioPragraphStylings ">
              You agree that the platform (TheTutor.me) may obtain information
              about you from a third party consumer reporting agency in
              connection with your potential engagement as a Tutor/Teacher.
              Thus, you may be the subject of a “consumer report” which may
              indulge information about your character, general reputation,
              personal characteristics, and/or mode of living. These reports may
              contain information regarding your criminal history, social
              security verification, verification of your education or
              employment history, or other background checks.
            </p>
          </div>
        </div>
      </div>
      <Col xs="12">
        <button
          className="btn btn-secondary text-white mt-3 mb-3 continue-button"
          onClick={nextSteps}
          style={{marginLeft: 8}}
        >
          I Agree
        </button>
      </Col>
    </div>
  );
}
export default TermAndCondition;
