/* eslint-disable eqeqeq */
import React from "react";
import { useNavigate } from "react-router";
import { Button, Modal } from "react-bootstrap";
import { StaticLabel } from "components/static-label";
import TimezoneSelect, { allTimezones } from "react-timezone-select";
import { useRemoteProfile } from "hooks/profile";
import { getAutoSelectedTimezone } from "utils";
import { useDispatch, useSelector } from "react-redux";
import ToastFactory from "utils/toast-factory";
import { Link } from "react-router-dom";
import { ReduxStore } from "constants/";
import { Col, Input } from "reactstrap";

/**
 * Timezone Update Modal Component shown when the logged in user's machine timezone is different from the one saved in the database.
 * @author Abdullah-Sajjad026
 */
export default function TimezoneUpdateModal(): JSX.Element {
  // Hooks
  const navigate = useNavigate();
  const toast = ToastFactory();
  const dispatch: (action: any) => void = useDispatch();

  // Getting User Type and Profile Data
  const { profile, updateProfile } = useRemoteProfile();

  // getting the previous timezone
  const storedTimezone = profile.timezone;

  // Timezone State
  const [timezone, setTimezone] = React.useState({
    label: "",
    value: getAutoSelectedTimezone(allTimezones),
  });

  // Update Status State to show different buttons on footer
  /* added it in store because it was difficult to update this status from the timezone error modal that is shown
  when a tutor has availability error during timezone update */

  const updateStatus = useSelector(
    (store: ReduxStore) => store.common.wrongTimezoneUpdateStatus
  );

  // helper function to set update status in common store
  const setUpdateStatus = (
    status: ReduxStore["common"]["wrongTimezoneUpdateStatus"]
  ) => {
    dispatch({ type: "SET_WRONG_TIMEZONE_UPDATE_STATUS", payload: status });
  };

  React.useEffect(() => {
    // only on first render
    dispatch({
      type: "SET_WRONG_TIMEZONE_UPDATE_STATUS",
      payload: timezone.value !== storedTimezone ? "eligible" : "",
    });
  }, []);

  //  Function to handle Timezone Update Request.
  const handleTimezoneUpdate = () => {
    toast.loading();

    const profile = {
      timezone: timezone.value,
    };

    // this action will also handle the setting of updated profile in the store
    updateProfile({
      profile,
      onSuccess: ({ data, message }) => {
        // dismissing loading toast
        toast.dismiss();
        setUpdateStatus("updated");
      },
      onFailure: ({ message }) => {
        // dismissing loading toast
        toast.dismiss();
        setUpdateStatus("erred");
      },
      // onByPass: () => {
      //   // dismissing loading toast
      //   toast.dismiss();
      // },
    });
  };

  return (
    <Modal show onHide={() => navigate(-1)} centered>
      <Modal.Header closeButton>
        <Modal.Title>Update Your Timezone</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {updateStatus !== "updated" && updateStatus !== "erred" && (
          <>
            <p className="font-size-15">
              There was a change detected in your timezone.{" "}
            </p>
            <p className="font-size-15">
              Please update your timezone to make sure you get the best
              experience on the platform.
            </p>
          </>
        )}

        <p className="font-size-15">
          {updateStatus === "updated" ? (
            <span className="text-success">
              Your timezone has been updated successfully.
            </span>
          ) : updateStatus === "erred" ? (
            <>
              <span className="text-danger">
                <strong>Something went wrong!</strong> Please try again from
                your <Link to="/profile">profile settings</Link>.
              </span>
            </>
          ) : (
            <>
              You can also skip this step and update it later in your{" "}
              <Link to="/profile">profile settings</Link>.
            </>
          )}
        </p>
        <br />
        {updateStatus !== "updated" && updateStatus !== "erred" && (
          <>
            <div className="d-flex flex-column flex-sm-row align-items-sm-center gap-2 mb-3">
              <Col sm="4">
                <StaticLabel className="text-nowrap">
                  Profile Timezone:
                </StaticLabel>
              </Col>
              <Input disabled value={storedTimezone} />
            </div>
            <div className="d-flex flex-column flex-sm-row align-items-sm-center gap-2">
              <Col sm="4">
                <StaticLabel className="text-nowrap">
                  Device Timezone:
                </StaticLabel>
              </Col>
              <TimezoneSelect
                isDisabled={["updated", "erred"].includes(updateStatus)}
                className="flex-grow-1"
                placeholder="Select your Time Zone"
                // @ts-ignore
                value={timezone}
                onChange={(val) => {
                  if (val.value !== storedTimezone) {
                    setUpdateStatus("eligible");
                  }
                  setTimezone(val);
                }}
              />
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className="hstack justify-content-end gap-2">
          {updateStatus !== "updated" && (
            <Button variant="outline-danger" onClick={() => navigate(-1)}>
              Skip
            </Button>
          )}
          {updateStatus === "eligible" && (
            <Button onClick={() => handleTimezoneUpdate()} variant="secondary">
              Update
            </Button>
          )}
          {updateStatus === "updated" && (
            <Button onClick={() => navigate(-1)} variant="success">
              Done
            </Button>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  );
}
