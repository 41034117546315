import React from "react";
import { IconCreditCard } from "@tabler/icons";
import { LeadingBadgesList } from "components/lists";
import { MAX_LIMIT_DESCRIPTIONS } from "constants/";

import { Button, Modal, ModalBody } from "reactstrap";
import {
  Avatar,
  BookSession,
  Loader,
  MyModalHeader,
  TextField,
} from "components";
import { IViewModelPassedControllers } from "./learner-book-session-view-model";
import { Navigate } from "react-router-dom";

function LearnerBookSessionView(
  controllers: IViewModelPassedControllers
): JSX.Element {
  return (
    <Modal isOpen={controllers.isOpen} size="xl">
      <ModalBody>
        <MyModalHeader
          title="Enter Session Details"
          toggleModal={controllers.toggle}
        />
        <div className="p-3 p-md-4" id="chosen-tutor">
          <div className="student-info d-flex flex-column flex-sm-row align-items-sm-center mt-3">
            <Avatar
              className="icon-lg"
              src={controllers.selectedBundle?.profile_picture}
              alt="Tutor Image"
            />

            <div className="ms-sm-3 mt-2 mt-sm-0">
              <div className="student-details d-flex flex-column flex-sm-row align-items-sm-center">
                <h3
                  className="pe-2 m-0"
                  style={{ borderRight: "1px solid #d5dde5 " }}
                >
                  {controllers.selectedBundle?.tutor_name}
                </h3>
                <div className="d-flex align-items-center">
                  <IconCreditCard
                    className="ms-sm-2"
                    style={{
                      fontSize: "20px",
                      color: `${
                        controllers.selectedBundle?.remaining_credits <= 0
                          ? "#EA8686"
                          : "#5CDB95"
                      }`,
                    }}
                  />
                  <p className="m-0 ps-1" style={{ color: "#05386b " }}>
                    {controllers.selectedBundle?.remaining_credits}{" "}
                    {controllers.selectedBundle?.remaining_credits > 1
                      ? "Credits"
                      : "Credit"}{" "}
                    Available
                  </p>
                </div>
              </div>

              <div className="hstack gap-2 mt-1">
                <p className="text-muted mb-0">Subject:</p>
                <h3 className="font-size-14 fw-bold mb-0">
                  {controllers.bundleSubjectName}
                </h3>
              </div>
            </div>
          </div>
          <div className="mx-0 mt-2 mt-sm-4 d-flex flex-column flex-sm-row gap-4 ">
            <div>
              <p className="text-muted mb-0">Grades</p>
              {/* @ts-ignore */}
              <LeadingBadgesList
                breakAt={1}
                hasDropdown
                entities={controllers.saneGrades}
              />
            </div>
            <div>
              <p className="text-muted mb-0">Curricula</p>
              {/* @ts-ignore */}

              <LeadingBadgesList
                breakAt={1}
                hasDropdown
                entities={controllers.saneCurriculums}
              />
            </div>
          </div>

          {!controllers.availableSlots.isLoaded ? (
            <Loader isInline />
          ) : !controllers.availableSlots.isErred ? (
            <div className="session-time mt-4">
              <h3 className="font-size-14 fw-bold mb-0">Session Time</h3>

              <BookSession
                duration={controllers.selectedBundle?.duration}
                slotsClass="book-session-tutoring-learner"
                availableSlots={controllers.availableSlots}
                parentComponent={"tutoring"}
                selectedSlot={controllers.selectedSlot}
                setSelectedSlot={controllers.setSelectedSlot}
                selectedDate={controllers.selectedDate}
                setSelectedDate={controllers.setSelectedDate}
              />
            </div>
          ) : (
            <Navigate to="/400" replace={true} />
          )}

          <div className="d-flex gap-3 mt-2">
            <div className="flex-grow-1">
              <label
                htmlFor="agenda"
                className="tutor-profile-setup-text float-start mt-2"
              >
                Agenda
              </label>
              {/* @ts-ignore */}
              <TextField
                type="text"
                placeholder="Enter your agenda here"
                name="agenda"
                onBlur={(e: { target: { value: string } }) =>
                  controllers.setAgenda(e.target.value)
                }
                className="user-account-input"
              />
              {controllers.simpleValidator.current.message(
                "Agenda",
                controllers.agenda,
                "required"
              )}
            </div>
            <div className="flex-grow-1">
              <label className="tutor-profile-setup-text mt-2">
                Description
              </label>
              <textarea
                className="tutor-textarea form-control form-control-noicon"
                placeholder="Enter Description Here"
                name="description"
                maxLength={parseInt(MAX_LIMIT_DESCRIPTIONS as string)}
                onBlur={(e) => controllers.setDescription(e.target.value)}
              />
              {controllers.simpleValidator.current.message(
                "Description",
                controllers.description,
                "required"
              )}
            </div>
          </div>

          <div className="mt-3">
            <Button className=" w-auto" onClick={controllers.handleSendRequest}>
              Book Session
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default LearnerBookSessionView;
