import React from "react";
import Select from "react-select";
import {ComboboxOption} from "./combobox";
import type {StateManagerProps} from "react-select/dist/declarations/src/useStateManager";

export type ComboboxMultiClickHandler = (options: ComboboxOption[]) => void;

export type ComboboxMultiProps = Omit<
  StateManagerProps,
  "value" | "options" | "onChange"
> & {
  maxValues: number;
  value: ComboboxOption[];
  options: ComboboxOption[];
  onChange: ComboboxMultiClickHandler;
};

export default function ComboboxMulti({
  value,
  maxValues,
  ...props
}: ComboboxMultiProps) {
  return (
    <Select
      {...props}
      isMulti
      value={value}
      isClearable={false}
      closeMenuOnSelect={false}
      data-testclass="combobox"
      isOptionDisabled={() => value.length >= maxValues}
    />
  );
}
