import React from "react";
import { Link } from "react-router-dom";
import { IconBulb } from "@tabler/icons";

const WithdrawDisclaimerCard = ({ text, link }) => {
  return (
    <div className="d-flex">
      <IconBulb
        size="22"
        className="text-error me-2 "
        style={{ minWidth: "20px" }}
      />
      <p className="fs-7">
        {text}
        {link && (
          <Link to="/support" className="text-underline text-primary">
            support@thetutor.me
          </Link>
        )}
      </p>
    </div>
  );
};

export default WithdrawDisclaimerCard;
