import React from "react";
import "./tutor-on-board.scss";
import { isEmpty } from "lodash";

//Simple bar
import SimpleBar from "simplebar-react";
import PersonImage from "assets/images/person.png";

import { connect } from "react-redux";

import {
  IconUser,
  IconBook,
  IconComet,
  IconSchool,
  IconBriefcase,
  IconCertificate,
  IconCircleCheck,
  IconCash,
  IconCheck,
  IconFileDots,
} from "@tabler/icons";
import IconRounded from "components/icon/icon-rounded";
import { ETutorOnboarding } from "constants/enums/ETutorOnboarding";
import { useTutorProfile } from "hooks/profile";

const TutorOnBoardProgress = ({ user, onBoardingStep, ...props }) => {
  const { isValidBasicInfo } = useTutorProfile();

  const qualification = !isEmpty(props.storedQualification);
  const experience = !isEmpty(props.storedExperience);
  const skills = !isEmpty(props.storedSkills);
  const certificates = !isEmpty(props.storedCertificate);
  const subjects = !isEmpty(props.storedSubjects);

  const isProfileSettedUp = qualification && experience && skills && subjects;

  return (
    <React.Fragment>
      <SimpleBar className="h-100">
        <div id="sidebar-menu">
          <section className="onboardprogress">
            <div className="onboardprogress-setup-profile">
              <IconRounded
                size="md"
                className={`${
                  ["Choose Goal"].includes(onBoardingStep)
                    ? "text-muted border border-2"
                    : "bg-success text-white"
                }`}
              >
                <IconUser fontSize="20px" />
              </IconRounded>
              <div className="onboardprogress-content">
                <span className="onboardprogress-heading">Setup Profile</span>
              </div>
              {isProfileSettedUp &&
                !["Choose Goal"].includes(onBoardingStep) && (
                  <IconRounded
                    size="xs"
                    className=" ms-auto bg-success text-white"
                  >
                    <IconCheck fontSize="18px" />
                  </IconRounded>
                )}
            </div>

            <div className="onboardprogress-setup-profile">
              <span id="" className="rounded-circle">
                <span className="visible-none">
                  <img alt="" src={PersonImage} />
                </span>
              </span>
              <div className="onboardprogress-content">
                <span className="onboardprogress-content-details">
                  Tutors with 100% complete profiles tend to get more students.
                </span>
              </div>
            </div>
            {/*---------------------- On Boarding steps ---------------------------- */}
            {onBoardingStep === "Profile Setup" && (
              <>
                <a
                  href={`#${ETutorOnboarding.BASIC_INFO}`}
                  className="onboardprogress-steps"
                >
                  <span className={`onboardprogress-steps-icon text-success `}>
                    <IconUser size={16} />
                  </span>

                  <span className="onboardprogress-steps-title">
                    Basic Information
                  </span>
                  {isValidBasicInfo() && (
                    <span className="onboardprogress-done-icon ms-auto">
                      <IconCheck fontSize="15px" />
                    </span>
                  )}
                </a>
                <a
                  href={`#${ETutorOnboarding.QUALIFICATIONS}`}
                  className="onboardprogress-steps"
                >
                  <span
                    className={`onboardprogress-steps-icon ${
                      qualification && "text-success"
                    }`}
                  >
                    <IconSchool size={16} />
                  </span>

                  <span className="onboardprogress-steps-title">
                    Qualifications
                  </span>
                  {qualification && (
                    <span className="onboardprogress-done-icon ms-auto">
                      <IconCheck fontSize="15px" />
                    </span>
                  )}
                </a>
                <a
                  href={`#${ETutorOnboarding.EXPERIENCE}`}
                  className="onboardprogress-steps"
                >
                  <span
                    className={`onboardprogress-steps-icon ${
                      experience && "text-success"
                    }`}
                  >
                    <IconBriefcase size={16} />
                  </span>

                  <span className="onboardprogress-steps-title">
                    Experiences
                  </span>
                  {experience && (
                    <span className="onboardprogress-done-icon ms-auto">
                      <IconCheck fontSize="15px" />
                    </span>
                  )}
                </a>
                <a
                  href={`#${ETutorOnboarding.SKILLS}`}
                  className="onboardprogress-steps"
                >
                  <span
                    className={`onboardprogress-steps-icon ${
                      skills && "text-success"
                    }`}
                  >
                    <IconComet size={16} />
                  </span>
                  <span className="onboardprogress-steps-title">Skills</span>
                  {skills && (
                    <span className="onboardprogress-done-icon ms-auto">
                      <IconCheck fontSize="15px" />
                    </span>
                  )}
                </a>
                <a
                  href={`#${ETutorOnboarding.CERTS}`}
                  className="onboardprogress-steps"
                >
                  <span
                    className={`onboardprogress-steps-icon ${
                      certificates && "text-success"
                    }`}
                  >
                    <IconCertificate size={16} />
                  </span>
                  <span className="onboardprogress-steps-title">
                    Certificates & Videos
                  </span>
                  {certificates && (
                    <span className="onboardprogress-done-icon ms-auto">
                      <IconCheck fontSize="15px" />
                    </span>
                  )}
                </a>
                <a
                  href={`#${ETutorOnboarding.SUBJECTS}`}
                  className="onboardprogress-steps"
                >
                  <span
                    className={`onboardprogress-steps-icon ${
                      subjects && "text-success"
                    }`}
                  >
                    <IconBook size={16} />
                  </span>
                  <span className="onboardprogress-steps-title">Subjects</span>
                  {subjects && (
                    <span className="onboardprogress-done-icon ms-auto">
                      <IconCheck fontSize="15px" />
                    </span>
                  )}
                </a>
              </>
            )}

            {/*---------------------- On Boarding steps ---------------------------- */}

            <div className="onboardprogress-setup-profile">
              <IconRounded
                size="md"
                className={`${
                  // if onboarding is in previous steps currently, then this icon is muted.
                  ["Choose Goal", "Profile Setup"].includes(onBoardingStep)
                    ? "text-muted border border-2"
                    : "bg-success text-white"
                }`}
              >
                <IconCash size={20} />
              </IconRounded>

              <div className="onboardprogress-content">
                <span className="onboardprogress-heading">Setup Payment</span>
              </div>
              {onBoardingStep === "Accept Terms" && (
                <IconRounded
                  size="xs"
                  className=" ms-auto bg-success text-white"
                >
                  <IconCheck fontSize="18px" />
                </IconRounded>
              )}
            </div>

            <div className="onboardprogress-setup-profile">
              <span id="" className="rounded-circle">
                <span className="visible-none">
                  <img alt="" src={PersonImage} />
                </span>
              </span>
              <div className="onboardprogress-content">
                <span className="onboardprogress-content-details">
                  Payments are easy and secure.
                </span>
              </div>
            </div>

            <div className="onboardprogress-setup-profile">
              <IconRounded
                size="md"
                className={`${
                  ["Choose Goal", "Profile Setup", "Bank Setup"].includes(
                    onBoardingStep
                  )
                    ? "text-muted border border-2"
                    : "bg-success text-white"
                }`}
              >
                <IconFileDots />
              </IconRounded>
              <div className="onboardprogress-content">
                <span className="onboardprogress-heading">
                  Terms & Conditions
                </span>
              </div>
              {onBoardingStep === 4 && (
                <span className="onboardprogress-done-icon">
                  <IconCircleCheck />
                </span>
              )}
            </div>

            <div className="onboardprogress-setup-profile">
              <span id="" className="rounded-circle">
                <span className="visible-none">
                  <img alt="" src={PersonImage} />
                </span>
              </span>
            </div>

            <div className="onboardprogress-setup-profile">
              <IconRounded
                size="md"
                className={`${
                  [
                    "Choose Goal",
                    "Profile Setup",
                    "Bank Setup",
                    "Accept Terms",
                  ].includes(onBoardingStep)
                    ? "text-muted border border-2"
                    : "bg-success text-white"
                }`}
              >
                <IconBook />
              </IconRounded>
              <div className="onboardprogress-content">
                <span className="onboardprogress-heading">
                  Tell us how you would like to start earning!
                </span>
              </div>
            </div>

            <div className="onboardprogress-setup-profile">
              <span id="" className="rounded-circle">
                <span className="visible-none">
                  <img alt="" src={PersonImage} />
                </span>
              </span>
              <div className="onboardprogress-content">
                <span className="onboardprogress-content-details">
                  Set your availability to create content and start earning
                  today!
                </span>
              </div>
            </div>
          </section>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

const mapToStateProps = (state) => ({
  user: state.tutorDashboard.userInfo,
  storedUserdata: state.tutorDashboard.userInfo.isData,
  storedQualification: state.tutorDashboard.userInfo.user.qualifications,
  storedExperience: state.tutorDashboard.experience.storedExperience,
  selectedExperience: state.tutorDashboard.experience.selectedExperience,
  storedSkills: state.tutorDashboard.userInfo.user.skills,
  storedCertificate: state.tutorDashboard.certificate.storedCertificate,
  storedSubjects: state.tutorDashboard.subject.storedSubjects,
});

export default connect(mapToStateProps, {})(TutorOnBoardProgress);
