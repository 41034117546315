import { FileSchema } from "modules/schemas";
import { getDiscounted } from "utils";
import { string, object, array, boolean, number } from "yup";

function getSchema({
  maxVideoSize,
  // maxPreviewSize,
  maxThumbnailSize,
  maxTitleLength,
  maxDescriptionLength,
  allowedImageFormats,
  allowedVideoFormats,
}) {
  return object({
    title: string()
      .required("Please enter the title")
      .max(maxTitleLength, `Title can be ${maxTitleLength} characters long.`),
    description: string()
      .required("Please describe the contents of this material")
      .max(
        maxDescriptionLength,
        `Description can be ${maxDescriptionLength} characters long.`
      ),
    topic: string()
      .required("Please enter a topic")
      .max(maxTitleLength, `Topic can be ${maxTitleLength} characters long.`),
    grades: array().min(1, "Please select at least 1 grade").of(number()),
    subjects: array().min(1, "Please select at least 1 subject").of(number()),
    curriculums: array()
      .min(1, "Please select at least 1 curriculum")
      .of(number()),
    materialTypes: array()
      .min(1, "Please select a type for your material")
      .of(number()),
    tags: array().min(1, "Please type at least 1 tag").of(string()),
    seoTags: array().min(1, "Please type at least 1 SEO tag").of(string()),
    hasQuiz: boolean().default(false),
    hasKey: boolean().default(false),
    hasCopyright: boolean().default(false),
    // after applying the discount, the total price calculated by getDiscounted must be at least 15
    price: number()
      .required("Please enter the price")
      .positive("The price must be above 0")
      .min(5, "The minimum price should be 5")
      .default(5),
    discount: number()
      .max(99, "The discount cannot be above 99%")
      .min(0, "The discount cannot be below 0%")
      .default(0),
    // calculating total price after applying the discount
    totalPrice: number().test(
      "totalPrice",
      "The total price needs to be above 5",
      function () {
        const { discount, price } = this.parent;
        const discountedPrice = Number(getDiscounted(price, discount));
        return discountedPrice >= 5;
      }
    ),
    // @ts-ignore
    thumbnail: new FileSchema({
      fileName: "thumbnail",
      maxSize: maxThumbnailSize,
      allowedFormats: allowedImageFormats,
      allowString: true,
    }),
    // @ts-ignore
    // preview: new FileSchema({
    //   fileName: "preview image",
    //   maxSize: maxPreviewSize,
    //   allowedFormats: allowedImageFormats,
    //   allowString: true,
    // }),
    // @ts-ignore
    video: new FileSchema({
      fileName: "video",
      isRequired: false,
      maxSize: maxVideoSize,
      allowedFormats: allowedVideoFormats,
      allowString: true,
    }),
  });
}

export default getSchema;
