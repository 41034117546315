import React from "react";
import { Icon } from "components";
import { NotifierProps } from ".";
import { SassyCard } from "components/cards";
import { IconCircleX } from "@tabler/icons";
import "./notifier.styles.scss";

export default function Notifier({
  children,
  closeNotifier,
  hasCloseButton = true,
  className = "",
}: NotifierProps) {
  return (
    <div className="notification-radius-remove">
      <SassyCard className="relative">
        <SassyCard.Body className={`text-secondary ${className}`}>
          {children}
        </SassyCard.Body>
        {closeNotifier && hasCloseButton && (
          <Icon
            size="sm"
            tag="button"
            onClick={closeNotifier}
            className="text-white position-absolute top-0 end-0 mt-2 me-2"
          >
            <IconCircleX fill="red" />
          </Icon>
        )}
      </SassyCard>
    </div>
  );
}
