import React, { useEffect, useRef, useState } from "react";
import { Button } from "reactstrap";
import { Loader } from "components";
import { BookSession } from "components";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import { fetchTutorAvailableSlots } from "store";
import { DEFAULT_LIMIT_TITLES } from "constants";
import { MAX_LIMIT_DESCRIPTIONS } from "constants";
import ToastFactory from "utils/toast-factory";
import { messages } from "constants/strings";
import { useStaticDataV3 } from "hooks/static-data";
import useSessionActions from "hooks/sessions/useSessionActions";
import { RTutorTutoring } from "constants/tutor-views-routes";

const ScheduleSession = ({ bundle, learner }) => {
  const studentId = learner?.user_id;
  const bundleId = bundle?.bundle_id;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const toastFactory = ToastFactory();
  const { handleBookSessionByTutor } = useSessionActions();

  const [agenda, setAgenda] = useState("");
  const [description, setDescription] = useState("");

  const { subjects, getStaticData } = useStaticDataV3(["subjects"]);

  // book session states
  const [selectedDate, setSelectedDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [selectedSlot, setSelectedSlot] = useState(null);

  const { tutorId, availableSlots } = useSelector((state) => ({
    tutorId: state.auth.userDetails.id,
    availableSlots: state.tutorTutoring.availableSlots,
  }));

  useEffect(() => {
    dispatch(
      fetchTutorAvailableSlots({
        bundleId,
        range: 7,
        date: selectedDate,
        tutorId,
        onFailure: (error) => {
          toastFactory.error(error.message);
        },
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate, bundleId]);

  const handleSendRequest = () => {
    if (simpleValidator.current.allValid()) {
      if (!selectedSlot) {
        toastFactory.info(messages.SLOT_NOT_SELECTED);
        return 0;
      }
    } else {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return false;
    }

    const startTime = moment(
      selectedSlot.startTimeString.split(" ")[1],
      "HH:mm"
    ).format("HH:mm");

    const endTime = selectedSlot.endTime;

    const weekDay = moment(selectedDate, "YYYY-MM-DD").day();
    const date = moment(selectedSlot.id.split(" ")[0], "DD-MM-YYYY").format(
      "YYYY-MM-DD"
    );

    handleBookSessionByTutor({
      agenda,
      description,
      startTime,
      endTime,
      receiverId: studentId,
      bundleId,
      date,
      weekDay,
      subjectName: getStaticData(bundle.subject, subjects.data),
      receiverName: learner.user.name,
      receiverPicture: learner.user.profile_picture,
      onSuccess: () => {
        dispatch({ type: "RESET_TUTOR_UPCOMING_SESSIONS" });
        navigate(RTutorTutoring.sessionsBaseView);
      },
    });
  };

  // const credits = [
  //   {
  //     credit: 1,
  //     originalPrice: "55",
  //     discountedPrice: "95",
  //   },
  //   {
  //     credit: 4,
  //     originalPrice: "555",
  //     discountedPrice: "955",
  //   },
  //   {
  //     credit: 12,
  //     originalPrice: "1555",
  //     discountedPrice: "1955",
  //   },
  // ];

  return (
    <div>
      {/* {availableCredits === 0 ? (
        <div className=" bg-light-gray p-3 rounded">
          {credits.map((c, index) => (
            <OneCredit
              key={index}
              credits={c.credit}
              originalPrice={c.originalPrice}
              discountedPrice={c.discountedPrice}
            />
          ))}
        </div>
      ) : ( */}
      {!availableSlots.isLoaded ? (
        <Loader />
      ) : !availableSlots.isErred ? (
        <BookSession
          slotsClass="book-session-tutoring-learner"
          availableSlots={availableSlots}
          duration={bundle.duration}
          parentComponent={"tutoring"}
          selectedSlot={selectedSlot}
          setSelectedSlot={setSelectedSlot}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
        />
      ) : (
        <Navigate to="/400" replace={true} />
      )}
      {/* )} */}
      <div className="d-flex gap-3 mt-2">
        <div className="flex-grow-1">
          <label
            htmlFor=""
            className="tutor-profile-setup-text float-start mt-2"
          >
            Agenda
          </label>
          {/* <TextField
            type="text"
            placeholder="Enter Your Agenda Here"
            name="agenda"
            // value={agenda}
            onBlur={(e) => setAgenda(e.target.value)}
            className="user-account-input"
            maxLength={DEFAULT_LIMIT_TITLES}
          /> */}

          <textarea
            className="tutor-textarea form-control form-control-noicon"
            placeholder="Enter Your Agenda Here"
            name="agenda"
            maxLength={DEFAULT_LIMIT_TITLES} // value={description}
            onBlur={(e) => setAgenda(e.target.value)}
          />
          {simpleValidator.current.message("Agenda", agenda, "required")}
        </div>
        <div className="flex-grow-1">
          <label className="tutor-profile-setup-text mt-2">Description</label>
          <textarea
            className="tutor-textarea form-control form-control-noicon"
            placeholder="Enter Description Here"
            name="description"
            maxLength={MAX_LIMIT_DESCRIPTIONS}
            // value={description}
            onBlur={(e) => setDescription(e.target.value)}
          />
          {simpleValidator.current.message(
            "Description",
            description,
            "required"
          )}
        </div>
      </div>
      <div className="mt-3">
        <Button onClick={handleSendRequest}>Send Request</Button>
      </div>
    </div>
  );
};

// const OneCredit = ({credits, originalPrice, discountedPrice}) => (
//   <Row className="align-items-center px-2 my-3">
//     <Col
//       sm="3"
//       md="12"
//       xxl="3"
//       className="p-0 d-flex align-items-center mb-2 mb-sm-0 mb-md-2 mb-xxl-0"
//     >
//       <input type="radio" name="credits" id="credits" />
//       <IconCreditCard
//         className="ms-2 me-1"
//         style={{
//           fontSize: "20px",
//           color: "#5CDB95",
//         }}
//       />
//       <h3 className="font-size-16 mb-0">{credits} Credits</h3>{" "}
//     </Col>
//     <Col
//       sm="4"
//       md="12"
//       xxl="4"
//       className="p-0 mb-2 mb-sm-0 mb-md-2 mb-xxl-0 text-start text-sm-center text-md-start text-xxl-center"
//     >
//       <h3 className="mb-0 ">
//         ${discountedPrice} {`  `}
//         <span className="text-muted" style={{textDecoration: "line-through"}}>
//           ${originalPrice}
//         </span>
//       </h3>
//     </Col>
//     <Col sm="5" md="12" xxl="5" className="p-0">
//       <input
//         type="text"
//         className="form-control helper-search-input py-1 px-2"
//         placeholder="Offer Additional Discount"
//       />
//     </Col>
//   </Row>
// );

export default ScheduleSession;
