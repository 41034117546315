import React from "react";
import { useNavigate } from "react-router-dom";
import { TutoringTutorBundle } from "views/tutoring";
import { useRemoteProfile } from "hooks/profile";
import useToast from "hooks/use-toast";
import { navItems } from "../layout/onboarding-tutor-layout";
import { Footer, Header, Container, CancelButton } from "../components";
import TutoringTutorBundleHeadless from "views/tutoring/tutoring-tutor/tutoring-tutor-bundle/tutoring-tutor-bundle-headless";

export default function OnboardingTutorService() {
  const toast = useToast();
  const navigate = useNavigate();
  const { updateProfile } = useRemoteProfile();

  const onSubmitHandler = () => {
    // Update onboarding status and redirect to dashboard.
    toast.loading();

    updateProfile({
      profile: {
        is_onboarded: 1,
        onboarding_status: "completed",
      },
      onFailure: ({ message }) => toast.error(message),
      onSuccess: () => {
        toast.success("Please wait while we setup your account.");

        // Reloading sets a whole new user and takes them to their dashboard.
        setTimeout(() => {
          window.location.replace("/");
        }, 3000);
      },
    });
  };

  return (
    <>
      <Header title="Create A Service" />
      <Container className="flex-grow-1">
        <TutoringTutorBundle
          AltView={TutoringTutorBundleHeadless}
          onSubmit={onSubmitHandler}
        />
      </Container>
      <Footer>
        <CancelButton onClick={() => navigate(navItems[2].href)}>
          Back
        </CancelButton>
      </Footer>
    </>
  );
}
