import { Icon } from "components";
import { ListGroupItem } from "reactstrap";

// Static.
import styles from "./list-item-account.module.scss";
import bankAccountImage from "assets/images/earning/back-logo-1.png";
import creditCardImage from "assets/images/credit-card/credit-card.png";
import { IconTrash, IconChecks, IconEye, IconPencil } from "@tabler/icons";

/**
 * @typedef {Object} ListItemAccountProps
 *
 * @property {"card" | "bank"} type
 * @property {boolean | undefined} isDefault
 * @property {string} accountNumber
 * @property {string} caption
 * @property {Function} onViewListener
 * @property {Function} onEditListener
 * @property {Function} onRemoveListener
 */

/**
 * A generic account information component.
 * @param {ListItemAccountProps & import("reactstrap").ListGroupItemProps} object
 * @returns {JSX.Element}
 *
 * @author kashan-ahmad
 * @version 0.0.1
 */
function ListItemAccount({
  type,
  caption,
  accountNumber,
  onViewListener,
  onMakeDefaultListener,
  onEditListener,
  onRemoveListener,
  isDefault = false,
  className = "",
  ...props
}) {
  return (
    <ListGroupItem
      {...props}
      className={`p-2 py-3 bg-gray-100 vstack gap-1 ${className}`}
    >
      {isDefault && (
        <div className="text-secondary ms-1">
          {type === "card" ? "Default Card" : "Default Bank"}
        </div>
      )}
      <div className="hstack">
        <div className="hstack flex-grow-1 gap-2">
          <div
            className={
              {
                card: styles["card-image"],
                bank: styles["bank-image"],
              }[type]
            }
          >
            <img
              alt=""
              className="w-100 h-100 object-contain"
              src={type === "card" ? creditCardImage : bankAccountImage}
            />
          </div>
          <div className="flex-grow-1 vstack gap-0 justify-content-center">
            <div className="text-secondary">{accountNumber}</div>
            <div className="text-muted">{caption}</div>
          </div>
        </div>
        <div className="hstack">
          {onViewListener && (
            <button
              type="button"
              role="tooltip"
              className="p-1"
              aria-label="View"
              onClick={onViewListener}
              data-microtip-position="top"
            >
              <Icon size="xs" className="text-secondary">
                <IconEye />
              </Icon>
            </button>
          )}
          {!isDefault && onMakeDefaultListener && (
            <button
              type="button"
              role="tooltip"
              className="p-1"
              aria-label="Make default"
              data-microtip-position="top"
              onClick={onMakeDefaultListener}
            >
              <Icon size="xs" className="text-secondary">
                <IconChecks />
              </Icon>
            </button>
          )}
          {onEditListener && (
            <button
              type="button"
              role="tooltip"
              className="p-1"
              aria-label="Edit"
              onClick={onEditListener}
              data-microtip-position="top"
            >
              <Icon size="xs" className="text-secondary">
                <IconPencil />
              </Icon>
            </button>
          )}
          {onRemoveListener && (
            <button
              type="button"
              role="tooltip"
              className="p-1"
              aria-label="Remove"
              onClick={onRemoveListener}
              data-microtip-position="top"
            >
              <Icon size="xs" className="text-secondary">
                <IconTrash />
              </Icon>
            </button>
          )}
        </div>
      </div>
    </ListGroupItem>
  );
}

export default ListItemAccount;
