import * as React from "react";
import moment from "moment";
import DayPicker from "./day-picker";
import MonthPicker from "./month-picker";
import YearPicker from "./year-picker";

import styles from "./date-picker.module.scss";
import { messages } from "constants/strings";
/**
 *  DataPicker is a wrapper for wrapping DayPicker, MonthPicker and Year Picker elements and returns 3 Select elements in responsive form.
 */

type IDatePickerProps = React.ComponentPropsWithoutRef<"div"> & {
  children: JSX.Element[];
  max?: Date;
  min?: Date;
  selectedDate?: string;
  isValidSelection?: boolean;
  setValidation?: (result: boolean) => void;
};

function DatePicker({
  children,
  max,
  min,
  selectedDate,
  isValidSelection,
  setValidation,
  ...props
}: IDatePickerProps) {
  const [DaySelect, MonthSelect, YearSelect] = children;
  React.useEffect(() => {
    // console.log(
    //   selectedDate?.split("-").length === 3,
    //   moment(selectedDate).isSameOrBefore(max)
    // );
    if (selectedDate?.split("-").length === 3) {
      if (max && moment(selectedDate).isSameOrBefore(max)) {
        setValidation?.(true);
      } else {
        setValidation?.(false);
      }
    }
  }, [selectedDate]);

  return (
    <div {...props} data-testclass="datePickerParent">
      <div className={styles["date-picker"]}>
        <div className={styles["day-picker"]}>{DaySelect}</div>
        <div className={styles["month-picker"]}>{MonthSelect}</div>
        <div className={styles["year-picker"]}>{YearSelect}</div>
      </div>
      {isValidSelection === false && (
        <p className="srv-validation-message mt-1">{messages.NOT_VALID_DATE}</p>
      )}
    </div>
  );
}

export default Object.assign(DatePicker, {
  Day: DayPicker,
  Month: MonthPicker,
  Year: YearPicker,
});
