import { generateReducer } from "utils";
import actionTypes from "./store-learning-material-progress-action-types";
import { LearningMaterialProgressAdapter } from "adapters/learning-materials";

const INITIAL_STATE = {
  state: "loading",
  data: {
    numOfPurchased: 0,
    percentageOfPending: 0,
    percentageOfCompleted: 0,
  },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, { type, payload }) =>
  generateReducer({
    state,
    payload,
    actionTypes,
    currentActionType: type,
    adapter: new LearningMaterialProgressAdapter(),
  });
