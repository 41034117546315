import * as React from "react";

// Components.
import { Col, Row } from "reactstrap";
import { ErrorMessage, FormikProps } from "formik";
import { FileBox } from "components/boxes";
import { FormErrorMessage } from "components";

// Utils.
import { VIDEO_FILE_TYPES } from "constants/types";
import {
  MAX_MATERIAL_VIDEO_SIZE,
  // MAX_MATERIAL_PREVIEW_SIZE,
  MAX_MATERIAL_THUMBNAIL_SIZE,
} from "constants/env";
import { getMegabytesFromBytes } from "utils";
import { FormCreateMaterialState } from "../form-create-material-adapter";

/**
 * The media section of the Tutor's Create/Update learning material form.

 * @author kashan-ahmad
 * @version 0.0.1
 */
function MediaSection({
  values,
  errors,
  handleChange,
  setFieldTouched,
}: Pick<
  FormikProps<FormCreateMaterialState>,
  "values" | "handleChange" | "setFieldTouched" | "errors"
>): JSX.Element {
  return (
    <section>
      <Col xs="12" md="12">
        <header className="fw-semi-bold mb-2 text-blue">
          Product Previews
        </header>
        <Row className="g-2">
          <Col xs="12" md="4">
            {/* @ts-ignore */}
            <FileBox
              allowPreview={!errors.thumbnail}
              id="thumbnail"
              prompt="Select a thumbnail"
              selectedFile={values.thumbnail}
              maxSize={getMegabytesFromBytes(MAX_MATERIAL_THUMBNAIL_SIZE)}
              onInputFile={(e) => {
                setFieldTouched("thumbnail");
                handleChange(e);
              }}
            />
            <ErrorMessage
              name="thumbnail"
              render={(message) => <FormErrorMessage message={message} />}
            />
          </Col>
          {/* <Col xs="12" md="4">
            <FileBox
              allowPreview
              id="preview"
              prompt="Select a preview"
              selectedFile={values.preview}
              maxSize={getMegabytesFromBytes(MAX_MATERIAL_PREVIEW_SIZE)}
              onInputFile={(e) => {
                setFieldTouched("preview");
                handleChange(e);
              }}
            />
            <ErrorMessage
              name="preview"
              render={(message) => <FormErrorMessage message={message} />}
            />
          </Col> */}

          <Col xs="12" md="4">
            {/* @ts-ignore */}
            <FileBox
              id="video"
              allowPreview={!errors.video}
              previewType="video"
              prompt="Select a video"
              selectedFile={values.video}
              allowedFormats={VIDEO_FILE_TYPES}
              maxSize={getMegabytesFromBytes(MAX_MATERIAL_VIDEO_SIZE)}
              onInputFile={(e) => {
                setFieldTouched("video");
                handleChange(e);
              }}
            />
            <ErrorMessage
              name="video"
              render={(message) => <FormErrorMessage message={message} />}
            />
          </Col>
        </Row>
      </Col>
    </section>
  );
}

export default MediaSection;
