import { TabBar } from "components";
import React from "react";
import { useState } from "react";
import { Card } from "reactstrap";
import DashboardTutorMaterials from "./dashboard-tutor-materials";
import DashboardTutorUpcomingSessions from "./dashboard-tutor-upcoming-sessions";

const TutorDashboardProducts = ({ hasSessionBundles }) => {
  const [activeTab, setActiveTab] = useState(1);

  return (
    <Card className="m-0 bg-white rounded-4 border-1">
      <TabBar>
        <TabBar.Item
          tabNumber={1}
          activeTab={activeTab}
          onSwitchTab={setActiveTab}
          data-tourstep="showUpcomingSessionsCta"
        >
          Upcoming Session
        </TabBar.Item>
        <TabBar.Item
          tabNumber={2}
          activeTab={activeTab}
          onSwitchTab={setActiveTab}
          data-tourstep="showLearningMaterialsCta"
        >
          Draft Learning Material
        </TabBar.Item>
      </TabBar>

      {activeTab === 1 ? (
        <DashboardTutorUpcomingSessions hasSessionBundles={hasSessionBundles} />
      ) : (
        <DashboardTutorMaterials />
      )}
    </Card>
  );
};

export default TutorDashboardProducts;
