import {
  createDispatchHook,
  createSelectorHook,
  ReactReduxContextValue,
  TypedUseSelectorHook,
} from "react-redux";
import context from "./store-static-v3-context";
import type { StaticDataV3Dispatch } from "./store-static-v3";
import type { StaticDataV3Store } from "constants/types/types-static-data";
import { AnyAction } from "redux";
import { Context } from "react";

// @see https://redux.js.org/usage/usage-with-typescript
export const useStaticDataV3Dispatch: () => StaticDataV3Dispatch =
  createDispatchHook(
    context as unknown as Context<ReactReduxContextValue<unknown, AnyAction>>
  );
export const useStaticDataV3Selector: TypedUseSelectorHook<StaticDataV3Store> =
  createSelectorHook(
    context as unknown as Context<ReactReduxContextValue<unknown, AnyAction>>
  );
