/**
 * @typedef {Object} ListDividerVerticalProps
 *
 * @property {number} currentIndex
 * The list index being iterated over.
 *
 * @property {number} listSize
 * The length of the list, required to determine the last render.
 */

/**
 * Vertical list divider that renders between all the list items but the last one.
 * @param {ListDividerVerticalProps & JSX.IntrinsicElements['hr']} object
 * @returns {JSX.Element}
 * @author kashan-ahmad
 */
const ListDividerVertical = ({
  className = "",
  currentIndex,
  listSize,
  ...props
}) =>
  currentIndex === listSize - 1 ? null : (
    <hr className={`my-0 ${className}`} {...props} />
  );

export default ListDividerVertical;
