import {apiService} from "service";
import {getAuthHeaders, getFormDataFromObject} from "utils";
import {API_MAIN_URL} from "constants";
import {DEFAULT_LEARNING_MATERIALS_PER_PAGE} from "constants";

/**
 * Service to interact with all the learning-material-related endpoints.
 *
 * @author kashan-ahmad
 * @version 0.0.1
 */
export default class LearningMaterialService {
  /**** GET ****/

  /**
   * Fetch the learning materials created by a user.
   *
   * @param {Object} props
   * @param {string} props.startDate
   * @param {string} props.endDate
   * @param {boolean | undefined} props.isPublished Default=true
   * @param {import("components/pagination/pagination-pages").PaginationPagesProps['currentPage'] | undefined} props.currentPage
   * @param {import("components/pagination/pagination-pages").PaginationPagesProps['itemsPerPage'] | undefined} props.itemsPerPage
   * @param {Boolbacks['onSuccess']} props.onSuccess
   * @param {Boolbacks['onFailure']} props.onFailure
   *
   * @author kashan-ahmad
   * @version 0.0.1
   */
  fetchCreated({
    startDate,
    endDate,
    currentPage,
    itemsPerPage,
    isPublished,
    onSuccess,
    onFailure,
  }) {
    apiService.get({
      onSuccess,
      onFailure,
      headers: getAuthHeaders(),
      url: `${API_MAIN_URL}/contentbuilder/tutor/learning-material/fetch?${new URLSearchParams(
        {
          page: currentPage,
          limit: itemsPerPage,
          ...(isPublished && {status: 1}),
          ...(startDate &&
            endDate && {start_date: startDate, end_date: endDate}),
        }
      )}`,
    });
  }

  /**
   * Request the learning materials created by a user.
   *
   * @param {Object} props
   * @param {string} filter
   * @param {import("components/pagination/pagination-pages").PaginationPagesProps['currentPage'] | undefined} props.currentPage
   * @param {import("components/pagination/pagination-pages").PaginationPagesProps['itemsPerPage'] | undefined} props.itemsPerPage
   * @param {Boolbacks['onSuccess']} props.onSuccess
   * @param {Boolbacks['onFailure']} props.onFailure
   *
   * @author kashan-ahmad
   * @version 0.0.1
   */
  fetchPurchased({
    filter,
    currentPage = 1,
    itemsPerPage = DEFAULT_LEARNING_MATERIALS_PER_PAGE,
    onSuccess,
    onFailure,
  }) {
    apiService.get({
      onSuccess,
      onFailure,
      headers: getAuthHeaders(),
      url: `${API_MAIN_URL}/learner/learning-material/filtered-list?${new URLSearchParams(
        {
          filter,
          page: currentPage,
          limit: itemsPerPage,
        }
      )}`,
    });
  }

  /**
   * Request the popular/trending learning materials.
   *
   * @param {Object} props
   * @param {import("components/pagination/pagination-pages").PaginationPagesProps['itemsPerPage']} props.itemsPerPage
   * @param {Boolbacks['onSuccess']} props.onSuccess
   * @param {Boolbacks['onFailure']} props.onFailure
   *
   * @author kashan-ahmad
   * @version 0.0.1
   */
  fetchPopular({itemsPerPage, onSuccess, onFailure}) {
    apiService.get({
      onFailure,
      onSuccess,
      headers: getAuthHeaders(),
      url: `${API_MAIN_URL}/contentbuilder/learning-material/trending?${new URLSearchParams(
        {limit: itemsPerPage}
      )}`,
    });
  }

  /**
   * Fetch the progress of learning materials purchased by a user.
   *
   * @param {Object} props
   * @param {Boolbacks['onSuccess']} props.onSuccess
   * @param {Boolbacks['onFailure']} props.onFailure
   *
   * @author kashan-ahmad
   * @version 0.0.1
   */
  fetchProgress({onSuccess, onFailure}) {
    apiService.get({
      onSuccess,
      onFailure,
      headers: getAuthHeaders(),
      url: `${API_MAIN_URL}/learner/learning-material/get-progress`,
    });
  }

  /**
   * Fetch the files of a learning material by it's id.
   *
   * @param {Object} props
   * @param {number} props.id
   * @param {Boolbacks['onSuccess']} props.onSuccess
   * @param {Boolbacks['onFailure']} props.onFailure
   *
   * @author kashan-ahmad
   * @version 0.0.1
   */
  fetchFiles({id, onSuccess, onFailure}) {
    apiService.get({
      onSuccess,
      onFailure,
      headers: getAuthHeaders(),
      url: `${API_MAIN_URL}/contentbuilder/learning-material/fetch-files?${new URLSearchParams(
        {
          content_id: id,
        }
      )}`,
    });
  }

  /**
   * Fetch a single learning material by it's id.
   *
   * @param {Object} props
   * @param {string} props.slug
   * @param {Boolbacks['onSuccess']} props.onSuccess
   * @param {Boolbacks['onFailure']} props.onFailure
   *
   * @author kashan-ahmad
   * @version 0.0.1
   */
  fetchById({id, onSuccess, onFailure}) {
    apiService.get({
      onSuccess,
      onFailure,
      headers: getAuthHeaders(),
      url: `${API_MAIN_URL}/contentbuilder/tutor/learning-material/fetch/${id}`,
    });
  }

  /**** POST ****/

  /**
   * Purchase a learning material for a user.
   *
   * @param {Object} props
   * @param {number} props.id
   * @param {Boolbacks['onSuccess']} props.onSuccess
   * @param {Boolbacks['onFailure']} props.onFailure
   *
   * @author kashan-ahmad
   * @version 0.0.1
   */
  purchaseMaterial({id, onSuccess, onFailure}) {
    apiService.post({
      onSuccess,
      onFailure,
      headers: getAuthHeaders(),
      data: getFormDataFromObject({lm_id: id, order_id: 1}),
      url: `${API_MAIN_URL}/contentbuilder/learning-material/orders`,
    });
  }

  /**
   * Update the progress of a learning material of type file.
   *
   * @param {Object} props
   * @param {number} props.id
   * The id of the learning materal.
   *
   * @param {number} props.entityID
   * The id of the file that triggered the update.
   *
   * @param {Boolbacks['onSuccess']} props.onSuccess
   * @param {Boolbacks['onFailure']} props.onFailure
   *
   * @author kashan-ahmad
   * @version 0.0.1
   */
  updateProgress({id, entityID, onSuccess, onFailure}) {
    apiService.post({
      onSuccess,
      onFailure,
      headers: getAuthHeaders(),
      data: getFormDataFromObject({
        lm_id: id,
        page_id: 0,
        file_id: entityID,
      }),
      url: `${API_MAIN_URL}/contentbuilder/learning-material/completion-progress`,
    });
  }
}
