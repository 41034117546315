import * as yup from "yup";

import {
  borderlessCountriesOptions,
  borderlessSupportedCurrenciesOptions,
} from "constants";
import {capitalize} from "utils";
import {
  messages,
  prompts,
  // questions,
  tutorBankAddRequirements,
} from "constants/strings";
import ToastFactory from "utils/toast-factory";
import {FormErrorMessage, SassyCard} from "components";
import {ListGroup} from "react-bootstrap";

const toast = ToastFactory();

export const selectsOptions = {
  accountTypeOptions: [
    {value: "PERSONAL", label: "Personal"},
    {value: "BUSINESS", label: "Business"},
  ],
  bankAccountTypeOptions: [
    {value: "CHECKING", label: "Checking"},
    {value: "SAVINGS", label: "Savings"},
  ],
  currencyOptions: borderlessSupportedCurrenciesOptions,
  countryOptions: borderlessCountriesOptions,
};

export const validationSchema = yup.object({
  // add zonecode and popcode
  email: yup.string().required(tutorBankAddRequirements.EMAIL),
  bankName: yup.string().required(tutorBankAddRequirements.BANK_NAME),
  accountNumber: yup.string().required(tutorBankAddRequirements.ACCOUNT_NUMBER),
  country: yup.object().required(tutorBankAddRequirements.COUNTRY),
  currency: yup.object().required(tutorBankAddRequirements.CURRENCY),
  state: yup.string().required(tutorBankAddRequirements.BRANCH_STATE),
  city: yup.string().required(tutorBankAddRequirements.BRANCH_CITY),
  postalCode: yup.string().required(tutorBankAddRequirements.POSTAL_CODE),
  zoneCode: yup.mixed().required(tutorBankAddRequirements.ZONE_CODE),
  branchName: yup.string(),
  bankSwiftBic: yup.string(),
  routingNumber: yup.string(),
  firstName: yup.string().required(tutorBankAddRequirements.FIRST_NAME),
  lastName: yup.string().required(tutorBankAddRequirements.LAST_NAME),
  addressLine1: yup.string().required(tutorBankAddRequirements.ADDRESS),
  accountType: yup.object().required(tutorBankAddRequirements.ACCOUNT_TYPE),
  bankAccountType: yup
    .object()
    .required(tutorBankAddRequirements.BANK_ACCOUNT_TYPE),
  // agreement: yup.boolean().isTrue(questions.DONT_AGREE),
  phoneNumber: yup.number().required(prompts.PHONE_NUMBER),
  businessName: yup.string(),
  // .when("accountType", (val) => {
  //   if (val && val.label === "Business") {
  //     return yup.string().required(tutorBankAddRequirements.BUSINESS_NAME);
  //   }
  // }),
  businessTaxId: yup.string(),
  // .when("accountType", (val) => {
  //   if (val && val.label === "Business") {
  //     return yup.string().required(tutorBankAddRequirements.BUSINESS_TAX_ID);
  //   }
  // }),
  personalTaxId: yup.string(),
  // .when("accountType", (val) => {
  //   if (val && val.label === "Personal") {
  //     return yup.string().required(tutorBankAddRequirements.PERSONAL_TAX_ID);
  //   }
  // }),
  placeOfBirthRegion: yup.string(),
  // .when("country", (val) => {
  //   if (val && val.value === "IN") {
  //     return yup.string().required(tutorBankAddRequirements.SPECIFIC_COUNTRY);
  //   }
  // }),

  placeOfBirthCountry: yup.object(),
  // .when("country", (val) => {
  //   if (val && val.value === "IN") {
  //     return yup.object().required(tutorBankAddRequirements.SPECIFIC_COUNTRY);
  //   }
  // }),
});

export const getInitialValues = ({tutorEmail, tutorName}) => {
  return {
    branchName: "",
    bankSwiftBic: "",
    routingNumber: "",
    bankName: "",
    addressLine1: "",
    postalCode: "",
    city: "",
    state: "",
    phoneNumber: "",
    accountNumber: "",
    personalTaxId: "",
    bankAccountType: "",
    businessName: "",
    businessTaxId: "",
    country: "",
    currency: "",
    accountType: "",
    addressLine2: "",
    placeOfBirthCountry: "",
    placeOfBirthRegion: "",
    agreement: false,
    zoneCode: "",
    email: tutorEmail,
    firstName: tutorName.split(" ")[0],
    lastName: tutorName.split(" ")[1],
  };
};

export const bankFieldsMap = new Map([
  ["branchName", "Branch Name"],
  ["bankSwiftBic", "Bank Swift BIC"],
  ["routingNumber", "Routing Number"],
  ["bankName", "Bank Name"],
  ["addressLine1", "Address Line 1"],
  ["postalCode", "Postal Code"],
  ["zoneCode", "Zone Code"],
  ["city", "City"],
  ["state", "State"],
  ["phoneNumber", "Phone Number"],
  ["accountNumber", "Account Number"],
  ["personalTaxId", "Personal Tax ID"],
  ["bankAccountType", "Bank Account Type"],
  ["businessName", "Business Name"],
  ["businessTaxId", "Business Tax ID"],
  ["country", "Country"],
  ["currency", "Currency"],
  ["accountType", "Account Type"],
  ["addressLine2", "Address Line 2"],
  ["placeOfBirthCountry", "Place of Birth Country"],
  ["placeOfBirthRegion", "Place of Birth Region"],
  ["agreement", "Agreement"],
  ["email", "Email"],
  ["firstName", "First Name"],
  ["lastName", "Last Name"],
]);

export const onBankAddSuccess = ({response, afterSuccess}) => {
  // dismissing the loading toast
  toast.dismiss();

  if (
    response.data.status === "PENDING" ||
    response.data.status === "pending"
  ) {
    toast.info(messages.BANK_UNDER_APPROVAL);
    const isPending = true;
    afterSuccess?.(isPending);
  } else if (
    response.data.status === "APPROVED" ||
    response.data.status === "approved"
  ) {
    // showing success toast
    toast.success(messages.BANK_ADDED);
    const isPending = false;
    afterSuccess?.(isPending);
  }
};

export const onBankAddFailure = ({error, afterFailure, setErredField}) => {
  toast.dismiss();

  const errorMessage = error.message.toLowerCase();

  if (error.error_code === 400) {
    if (
      errorMessage.includes("handle") &&
      errorMessage.includes("contact") &&
      errorMessage.includes("already")
    ) {
      toast.error(messages.BANK_ERR_EXISTING);
    } else if (
      error.data.length > 0 &&
      (errorMessage.includes("incorrect") ||
        (errorMessage.includes("required") && errorMessage.includes("missing")))
    ) {
      // in this case, error has a data key that contains an array of of strings that are the names of the fields that are incorrect
      // one liner
      toast.render(
        <>
          <div>
            <SassyCard.Title>Invalid Bank Information</SassyCard.Title>
            <p className="mb-0">{error.message}</p>
          </div>
          <ListGroup className="mb-0 text-secondary">
            {error.data.map((erredField, index) => (
              <ListGroup.Item key={index}>
                <FormErrorMessage
                  message={
                    bankFieldsMap.get(erredField) || capitalize(erredField)
                  }
                />
                {setErredField(
                  erredField,
                  errorMessage.includes("incorrect")
                    ? "Incorrect Information"
                    : "Missing Information"
                )}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </>
      );
    } else {
      toast.error(error.message);
    }
    // else if (error.message.includes("does not match")) {
    //   toast.error(messages.BANK_ERR_UNMATCHED);
    // } else if (errorMessage.includes("birth")) {
    //   toast.error(error.message);
    // } else {
    //   toast.error(messages.AN_ERR);
    // }
  } else if (error.error_code === 401 && error.message.includes("expired")) {
    toast.error(messages.BANK_ERR_EXPIRED);
  } else toast.error(error.message);

  afterFailure?.(error);
};
