import React from "react";
import { Button } from "reactstrap";
import SassyAlert from "./sassy-alert";
import { IconCircleCheck } from "@tabler/icons";

/**
 * @typedef {Object} OverlayErrorProps
 *
 * @property {boolean} condition
 * The condition upon which the overlay's display depends.
 *
 * @property {string} error
 * The title of the error.
 *
 * @property {string} description
 * The description of the error.
 *
 * @property {1 | 2 | 3 | 4 | 5 | undefined} isPaddedBy
 * The bootstrap's padding class type around the overlay, this will result in a scale up in order to overcome the bizarre edges of the blurred items due to the surrounding padding.
 *
 * @property {keyof JSX.IntrinsicElements & React.ReactElement | undefined} as
 * Default = div
 *
 * @property {React.ReactElement| undefined} children
 */

/**
 * An overlay that blurs the background and shows an error if the defined condition is met.
 * @param {OverlayErrorProps & React.HTMLAttributes<HTMLElement>}
 * @returns {JSX.Element}
 *
 * @author kashan-ahmad
 * @version 0.0.1
 */
const OverlayError = ({
  as: Component = "div",
  error,
  condition,
  description,
  className = "",
  isPaddedBy,
  children,
  style,
  onCloseOverlay,
  ...props
}) =>
  !condition ? null : (
    <Component
      {...props}
      style={{
        transform:
          {
            1: "scale(1.01)",
            2: "scale(1.015)",
            3: "scale(1.02)",
            4: "scale(1.025)",
            5: "scale(1.03)",
          }[isPaddedBy] || "scale(1)",
        ...style,
      }}
      className={`position-absolute top-0 end-0 bottom-0 start-0  blur-bg-5 ${className}`}
    >
      <div className=" mt-3 d-flex flex-column justify-content-start align-items-center flex-md-row justify-content-md-center  ">
        <SassyAlert
          color="danger"
          className="m-0"
          title={error}
          description={description}
        />
        {onCloseOverlay && (
          <div className="mt-2 mt-md-0 ms-md-2">
            <Button
              type="button"
              color="success"
              className="hstack gap-2"
              onClick={onCloseOverlay}
            >
              <IconCircleCheck size={16} />
              Close
            </Button>
          </div>
        )}
      </div>
    </Component>
  );

export default OverlayError;
