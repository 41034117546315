import { getEnvVar } from "utils/utils-env";

/**
 * All the external routes at one spot.
 */
export const externalRoutes = {
  website: {
    home: getEnvVar("WEBSITE_URL"),
  },
  tutors: {
    search: `${getEnvVar("WEBSITE_URL")}/tutor/search`,
  },
  learningMaterials: {
    search: `${getEnvVar("WEBSITE_URL")}/learning-material/search`,
  },
  // verifyEmail: `${getEnvVar("WEBSITE_URL")}/auth/email-verification`,
  verifyEmail: `${getEnvVar("WEBSITE_URL")}/auth/email-verification-code`,
};
