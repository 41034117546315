import * as React from "react";

import SassySelect from "components/sassy-select";
import { SassySelectProps, SelectOption } from "constants/types/types";

const daysOptions = [...Array(31)].map((_, i) => ({
  label: i + 1,
  value: i + 1,
}));

/**
 * A React-Select based Day picker component that allows to select a day between 1-31 from a select menu.
 * @author "Abdullah-Sajjad026"
 */

const DayPicker = ({
  value,
  onChange,
  className,
  disabled,
  label,
  placeholder = "Day",
  isMulti = false,
  closeMenuOnSelect = true,
  ...props
}: SassySelectProps & { value: SelectOption }): JSX.Element => {
  return (
    <SassySelect
      {...{
        ...props,
        label,
        className,
        disabled,
        onChange,
        placeholder,
        closeMenuOnSelect,
        isMulti,
      }}
      options={daysOptions}
      selectedOptions={{ ...value, value: parseInt(value.value) }}
    />
  );
};

export default DayPicker;
