import React from "react";
import User from "assets/images/doodles/person-doodle.svg";
import "./dashboard-referrer.scss";
import { Link } from "react-router-dom";

const DashboardReferrer = () => {
  return (
    <div className="refer-a-friend">
      <img src={User} alt="user" className="refer-a-friend-img" />
      <h2 className="refer-a-friend-header my-2">Refer a Friend</h2>
      <p className="refer-a-friend-subheading ">
        Invite your friends and <br /> enjoy a free 7-day trial of <br />
        TheTutor.me.
      </p>
      <Link
        className="refer-a-friend-button btn btn-success"
        to="/refer-friends"
      >
        Refer Now
      </Link>

      {/* <Modal
        isOpen={show}
        onRequestClose={handleClose}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.71)",
          },
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "500px",
            padding: 0,
            borderRadius: "8px",
          },
        }}
      >
        <div
          className="refer-modal-header"
          style={{
            fontSize: "20px",
            fontWeight: "700",
            padding: "16px",
            color: "black",
            borderBottom: "1px solid #eff2f7",
            textAlign: "center",
          }}
        >
          Refer Your friend
        </div>
        <div
          style={{
            fontSize: "16px",
            fontWeight: "500",
            padding: "16px",
            color: "#05386b",
            borderBottom: "1px solid #eff2f7",
          }}
        >
          <b>
            Your referral ID:{" "}
            <span>
              {WEBSITE_URL}/auth/signup/{referalId}
            </span>
          </b>
          <br />
          <br />
        </div>

        <div
          className="refer-modal-footer"
          style={{
            borderBottom: "1px solid #eff2f7",
            padding: "16px",
          }}
        >
          <Button
            style={{
              background: "#ffffff",
              border: "2px solid #05386B",
              marginRight: "12px",
            }}
            type="button"
            onClick={handleClose}
            className="cancel-button"
          >
            <span style={{ color: "#05386B" }}>Cancel</span>
          </Button>
          <Button
            className="continue-button"
            onClick={() => {
              copy(`${WEBSITE_URL}/auth/signup/${referalId}`);
            }}
          >
            Share Link
          </Button>{" "}
        </div>
      </Modal> */}
    </div>
  );
};

export default DashboardReferrer;
