/* eslint-disable eqeqeq */
export const DEFAULT_STATE = {
  to: "",
  from: "",
  company: "",
  country: "",
  job_title: "",
  document: null,
  experience: "",
  company_url: "",
  currently_working: true,
};

/**
 * Make an experience entry edible for the form.
 * @param {TutorExperience} state
 * @returns {TutorExperience}
 */
export function getDeserialized({
  currently_working,
  country,
  document,
  ...rest
}) {
  return {
    ...rest,
    country: {label: country, value: country},
    currently_working: currently_working == 1,
    ...(typeof document === "string"
      ? {document: "", fileName: document}
      : {document}),
  };
}

/**
 * Make an experience entry edible for the API.
 * @param {TutorExperience} state
 * @returns {TutorExperience}
 */
export function getSerialized({to, currently_working, ...rest}) {
  return {
    ...rest,
    to: currently_working ? "" : to,
    currently_working: currently_working ? 1 : 0,
  };
}
