import React from "react";
import { Alert } from "reactstrap";
import { parseMessage } from "utils";

/**
 * @typedef { Object } SassyAlertProps
 *
 * @property { string } title
 * Bold, leading text of the alert.
 *
 * @property { React.ReactElement } description
 * Dimmed, following text of the alert, best for detailed messages.
 *
 * @property { React.ReactElement } rightWing
 * Something to place on the right edge of the alert. Note that this will make
 * the alert be divided into two responsive columns.
 *
 * @property { React.ReactElement } children
 */

/**
 * An alert with sass.
 *
 * @param { SassyAlertProps & PolymorphicComponent & import("reactstrap").AlertProps }
 * @returns { JSX.Element }
 */
function SassyAlert({
  as,
  tag,
  component,
  title,
  description,
  rightWing,
  className = "",
  children,
  ...props
}) {
  const renderAs = as || tag || component || "div";

  let classNames = `p-2 mb-0 ${className}`;

  if (rightWing) {
    classNames +=
      " d-flex gap-2 flex-column flex-sm-row justify-content-between align-items-sm-center";
  }

  return (
    <Alert
      {...props}
      data-testclass="sassyAlert"
      tag={renderAs}
      className={classNames}
    >
      <div>
        {title && <div className="fw-bold fs-6">{parseMessage(title)}</div>}
        {description && <div>{description}</div>}
        {children}
      </div>
      {rightWing && <div>{rightWing}</div>}
    </Alert>
  );
}

export default SassyAlert;
