export enum MeetingStatusTitles {
  "Scheduled" = "Scheduled",
  "JoinedByTutor" = "Joined By Tutor",
  "JoinedByYou" = "Joined By You",
  "JoinedByLearner" = "Joined By Learner",
  "SessionInProgress" = "Session in Progress",
  "MissedByYou" = "Missed By You",
  "MissedByTutor" = "Missed By Tutor",
  "MissedByLearner" = "Missed By Learner",
  "Unattended" = "Unattended",
  "Completed" = "Completed",
}
