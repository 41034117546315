import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPaymentMethods, removePaymentMethod } from "store";
import { makePaymentMethodDefault } from "store";
import { parseMessage } from "utils";
import { messages } from "constants/strings";
import ToastFactory from "utils/toast-factory";

/**
 * A hook that returns the payment methods of the current user.
 * A method is also returned by the hook that can be used to remove a specific payment method.
 * @returns {PaymentMethodState & {
 * onRemoveListener: (method: PaymentMethod) => void,
 * onMakeDefaultListener: (method: PaymentMethod) => void
 * }}
 */
function usePaymentMethods() {
  const dispatch = useDispatch();
  const toastFactory = ToastFactory();
  const controller = new AbortController();

  /**
   * @type {PaymentMethodState}
   */
  const paymentMethods = useSelector(
    (state) => state.transactions.paymentMethods
  );

  React.useEffect(() => {
    paymentMethods.isLoaded || dispatch(fetchPaymentMethods({}));
    return () => controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Listener for a payment method's removal.
   * @param {PaymentMethod} method
   */
  const onRemoveListener = ({ _id, is_default }) => {
    if (is_default) {
      toastFactory.error(parseMessage(messages.ACCOUNT_DEFAULT_ERR_REMOVE));
      return;
    }

    if (window.confirm("Are you sure about that?")) {
      toastFactory.loading();

      dispatch(
        removePaymentMethod({
          _id,
          onSuccess: () => toastFactory.dismiss(),
          onFailure: () => {
            toastFactory.error(
              parseMessage(messages.REMOVING_ERR("payment method"))
            );
          },
        })
      );
    }
  };

  /**
   * Listener for a payment method being made default.
   * @param {PaymentMethod} method
   */
  const onMakeDefaultListener = ({ _id, is_default }) => {
    if (is_default) {
      toastFactory.info(
        parseMessage(messages.ACCOUNT_DEFAULT_ALR("payment method"))
      );
      return;
    }

    toastFactory.loading();

    dispatch(
      makePaymentMethodDefault({
        _id,
        onSuccess: () => toastFactory.dismiss(),
        onFailure: () => {
          toastFactory.error(parseMessage(messages.AN_ERR));
        },
      })
    );
  };

  return {
    ...paymentMethods,
    onRemoveListener,
    onMakeDefaultListener,
  };
}

export default usePaymentMethods;
