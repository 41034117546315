import { Icon, Avatar } from "..";

/**
 * @typedef {Object} NotificationAvatarProps
 *
 * @property {import("constants/types/types-notifications").Notification['profile_picture']} profile_picture
 *
 * @property {import("constants/types/types-notifications").NotificationType} notificationType
 */

/**
 * The avatar of the notification.
 *
 * @param {NotificationAvatarProps & JSX.IntrinsicElements['div']} object
 * The forwarded props.
 *
 * @returns {JSX.Element}
 *
 * @version 0.0.2
 * @author kashan-ahmad
 *
 * @changelog
 * - 0.0.2: Added typedef for properties.
 * - 0.0.1: Initial version.
 */
const NotificationAvatar = ({
  notificationType: Type,
  profile_picture,
  ...props
}) => (
  <div {...props}>
    {Type.Icon ? (
      <Icon
        size="lg"
        className={`p-1 rounded-circle bg-gray-300 text-${
          Type?.color || "primary"
        }`}
      >
        <Type.Icon className="rounded-circle avatar-xs" />
      </Icon>
    ) : (
      <Avatar alt="" src={profile_picture} className="avatar-xs" />
    )}
  </div>
);

export default NotificationAvatar;
