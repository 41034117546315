import React from "react";
import {getMeetingLink} from "utils";
// import {Link} from "react-router-dom";

// Components.
import Expired from "./expired";
// import {Button} from "reactstrap";
// import {RLearnerTutoring} from "constants/learner-views-routes";

// Types.
import {SessionStatusCardVariantProps} from "../session-status-card-types";
import SessionDetailsButton from "../session-details-button";
import {useUser} from "hooks";
// import useSessionActions from "hooks/sessions/useSessionActions";
// import {useStaticDataV3} from "hooks/static-data";

export default function Accepted({
  isExpired,
  renderer,
  isDetailOpen,
  onViewDetails,

  ...session
}: SessionStatusCardVariantProps) {
  const {meeting, session_id, id} = session;

  const user = useUser();

  // const {start_time, end_time, date, name, profile_picture, id, subject_id} =
  //   session;

  // const {subjects, getStaticData} = useStaticDataV3(["subjects"]);

  // The corresponding hook.
  // const {handleSessionCancel} = useSessionActions();

  if (isExpired)
    return (
      <Expired
        {...session}
        {...{isExpired, renderer, isDetailOpen, onViewDetails}}
      />
    );

  return (
    <>
      {/* Accepted Session */}
      {
        <div className="hstack gap-2 flex-md-wrap flex-lg-nowrap justify-content-end">
          <a
            href={getMeetingLink(
              meeting.meeting_id,
              meeting.meeting_type.type,
              user.type === "learner" ? "prejoining" : "meet"
            )}
            className="btn btn-secondary"
          >
            Join
          </a>
          <SessionDetailsButton
            sessionId={session_id}
            requestId={id}
            {...{renderer, isDetailOpen, onViewDetails}}
          />
          {/* <Link
            type="button"
            className="btn btn-outline-info"
            // TODO: fix link when uncommenting
            to={RLearnerTutoring.getSessionRescheduleView(session_id)}
          >
            Reschedule
          </Link>

          <Button
            color="danger"
            outline
            onClick={() =>
              handleSessionCancel({
                onSuccess: onActionSuccess,
                startTime: start_time,
                endTime: end_time,
                date,
                userName: name,
                userPicture: profile_picture,
                requestId: id,
                subjectName: getStaticData(subject_id, subjects.data),
              })
            }
          >
            Cancel
          </Button> */}
        </div>
      }
    </>
  );
}
