import React from "react";
import {Loader} from "components";

// Views.
import TutoringTutorBundle from "./tutoring-tutor-bundle";
import TutoringTutorLanding from "./tutoring-tutor-landing";
import TutoringTutorSidebar from "./tutoring-tutor-sidebar";
import TutoringTutorSchedule from "./tutoring-tutor-schedule/";
import TutoringTutorServices from "./tutoring-tutor-services";
import TutoringTutorAvailability from "./tutoring-tutor-availability";
import TutoringTutorNewSession from "./tutoring-tutor-new-session";
import TutoringTutorPreviousActivityDetailed from "./tutoring-tutor-previous-activity-detailed";
import TutoringTutorSessionRequest from "./tutoring-tutor-session-request";

const View = React.lazy(() => import("./tutoring-tutor.js"));

const TutoringTutor = (props) => (
  <React.Suspense fallback={<Loader />}>
    <View {...props} />
  </React.Suspense>
);

export * from "./tutoring-tutor-new-session";
export {
  TutoringTutor,
  TutoringTutorBundle,
  TutoringTutorLanding,
  TutoringTutorSidebar,
  TutoringTutorServices,
  TutoringTutorSchedule,
  TutoringTutorAvailability,
  TutoringTutorNewSession,
  TutoringTutorPreviousActivityDetailed,
  TutoringTutorSessionRequest,
};
