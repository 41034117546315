import {OverlayError} from "components";
import {TUTOR_MAX_QUALIFICATIONS} from "constants";
import {messages} from "constants/strings";
import {TutorQualificationForm} from "modules/forms";
import React from "react";
import {useState} from "react";
import {useDispatch} from "react-redux";
import {useOutletContext} from "react-router-dom";
import {
  deleteQualifications,
  storeQualifications,
} from "store/tutordashboard/action";
import {convertBase64} from "utils";
import ToastFactory from "utils/toast-factory";
import {Error404} from "views/error";
import {DEFAULT_STATE} from "./utils";
import TutorQualificationsList from "./view-profile-tutor-qualifications-list";
import {wrapperStyles} from "constants/config";
import {ConfirmDeletionModal} from "modules/modals/confirmation";
import {DeletionModalFooter} from "modules/modals/components/modal-footer/variants";
import {SimpleModalBody} from "modules/modals/components/modal-body/variants";

/**
 * @author Abdullah-Sajjad026
 */
const TutorProfileQualificationsView = (props) => {
  const dispatch = useDispatch();
  const toastFactory = ToastFactory();

  const outletProps = useOutletContext();
  const qualifications = props.qualifications || outletProps.qualifications;

  const [{isEditing, formVariant, currentState}, setViewState] = useState({
    formVariant: "add",
    currentState: DEFAULT_STATE,
    // Editable by default if the qualifications don't exist.
    isEditing:
      !qualifications || qualifications.length === 0 || props.isEditing,
    isListEditable: props.isListEditable || true,
  });

  function setState(key, value) {
    setViewState((state) => ({...state, [key]: value}));
  }

  // Form actions
  const onAddQualification = async (qualification) => {
    if (
      qualifications.length >= TUTOR_MAX_QUALIFICATIONS &&
      formVariant === "add"
    ) {
      toastFactory.error(
        messages.ERR_LIMIT_REACHED(TUTOR_MAX_QUALIFICATIONS, "qualifications")
      );
      return;
    }
    setState("currentState", DEFAULT_STATE);

    if (Object.keys(qualification).includes("id")) {
      qualification["qualification_id"] = qualification.id;
      delete qualification.id;
    }
    if (qualification.document)
      qualification.document = await convertBase64(qualification.document);

    toastFactory.loading();

    dispatch(
      storeQualifications(
        [
          {
            ...qualification,
          },
        ],
        // pretending on success handler
        (data) => {
          toastFactory.dismiss();
          setViewState((state) => ({
            ...state,
            isEditing: props.isEditing ? true : formVariant === "add",
            formVariant: "add",
            currentState: DEFAULT_STATE,
          }));
          document
            .getElementById("qualifications-list")
            .scrollIntoView({behavior: "smooth"});
        },
        // pretending on failure handler
        (error) => {
          toastFactory.error(error.message);
        }
      )
    );
  };

  const onRemoveQualification = (qualificationId) => {
    toastFactory.render(
      <ConfirmDeletionModal
        body={
          <SimpleModalBody>
            Are you sure you want to delete this qualification?
          </SimpleModalBody>
        }
        footer={
          <DeletionModalFooter
            onCancel={() => toastFactory.dismiss()}
            onDelete={() => {
              toastFactory.loading();

              dispatch(
                deleteQualifications(
                  qualificationId,
                  () => {
                    toastFactory.dismiss();
                    setViewState((state) => ({
                      ...state,
                      isEditing: true,
                    }));
                  },
                  (error) => {
                    toastFactory.error(error.message);
                  }
                )
              );
            }}
          />
        }
      />
    );
  };

  // Experience actions.
  function onEditQualification(qualification) {
    setViewState((state) => ({
      ...state,
      formVariant: "edit",
      currentState: {
        ...qualification,
        ...(typeof qualification.document === "string"
          ? {document: "", fileName: qualification.document}
          : {document: qualification.document}),
      },
    }));

    // Scroll the form into sight.
    document
      .getElementById("qualification-form")
      .scrollIntoView({behavior: "smooth"});
  }

  const onCloseForm = () =>
    setViewState((state) => ({
      ...state,
      formVariant: "add",
      currentState: DEFAULT_STATE,
      // Editable by default if the qualifications don't exist.
      isEditing: false,
    }));

  const onResetForm = () => {
    setViewState((state) => ({
      ...state,
      formVariant: "add",
      currentState: DEFAULT_STATE,
    }));
  };

  return !qualifications ? (
    <Error404 />
  ) : (
    <>
      {isEditing && (
        <section id="qualification-form" className="position-relative">
          <TutorQualificationForm
            state={currentState}
            variant={formVariant}
            onSubmit={onAddQualification}
            onReset={onResetForm}
            onCloseForm={onCloseForm}
            allowFormClose={props.allowFormCancel && qualifications.length > 0}
          />
          <OverlayError
            isPaddedBy={3}
            condition={
              qualifications.length >= TUTOR_MAX_QUALIFICATIONS &&
              !Object.keys(currentState).includes("id")
            }
            {...(props.allowFormCancel ? {onCloseOverlay: onCloseForm} : {})}
            error={messages.ERR_LIMIT_REACHED(
              TUTOR_MAX_QUALIFICATIONS,
              "qualifications"
            )}
            description={messages.ERR_LIMIT_REACHED_DESC("qualifications")}
          />
        </section>
      )}
      <section
        id="qualifications-list"
        className={`d-flex flex-column ${wrapperStyles.yGap}`}
      >
        <TutorQualificationsList
          {...{
            isEditing,
            qualifications,
            onEditQualification,
            onRemoveQualification,
          }}
          setIsEditing={(value) => setState("isEditing", value)}
        />
      </section>
    </>
  );
};

export default TutorProfileQualificationsView;
