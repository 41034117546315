import { ErrorMessage } from "formik";
import { FormGroup } from "reactstrap";
import SassyFormLabel from "../sassy-form-label";
import FormErrorMessage from "components/error-message-form";

/**
 * The good ol' field, but better.
 *
 * @param {import("./sassy-form-field").SassyFormFieldProps & import("reactstrap").FormGroupProps & PolymorphicComponent} props
 *
 * @returns {JSX.Element}
 */
export default function SassyFormFieldV2({
  as,
  tag,
  component,
  name,
  label,
  children,
  ...props
}) {
  const Component = as || tag || component || FormGroup;

  return (
    <Component {...props}>
      {label && <SassyFormLabel htmlFor={name}>{label}</SassyFormLabel>}
      {children}
      <ErrorMessage
        name={name}
        render={(message) => <FormErrorMessage {...{ message }} />}
      />
    </Component>
  );
}
