import React from "react";
import { Loader } from "components";

// Features.
import TutorProfileExperiencesView from "./view-profile-tutor-experiences";
import TutorProfileCertificatesView from "./view-profile-tutor-certificates";
import TutorProfileQualificationsView from "./view-profile-tutor-qualifications";

// import TutorProfileBasicView from "./basic-info";
// import TutorProfileSkillView from "./Skill/index";
// import TutorProfileSubjectView from "./SubjectBlock/index";
// import TutorProfileManageView from "./ManageBlock/index";

const View = React.lazy(() => import("./view-profile-tutor"));

const TutorProfileView = (props) => (
  <React.Suspense fallback={<Loader />}>
    <View {...props} />
  </React.Suspense>
);

export default TutorProfileView;
export {
  // TutorProfileBasicView,
  // TutorProfileSkillView,
  // TutorProfileSubjectView,
  // TutorProfileManageView,
  TutorProfileExperiencesView,
  TutorProfileCertificatesView,
  TutorProfileQualificationsView,
};
