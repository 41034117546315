export default class LearningMaterialProgressAdapter {
  serialize(state) {
    return {
      total_purchased: state.numOfPurchased || 0,
      pending_percentage: state.percentageOfPending || 0,
      completed_percentage: state.percentageOfCompleted || 0,
    };
  }

  deserialize(state) {
    return {
      numOfPurchased: state.total_purchased || 0,
      percentageOfPending: state.pending_percentage || 0,
      percentageOfCompleted: state.completed_percentage || 0,
    };
  }
}
