import React from "react";
import { Switch } from "components";
import { getEnvInt } from "utils/utils-env";
import { boxStyles } from "constants/config";
import { Col, Form, Row } from "react-bootstrap";
import FormLabel from "components/forms/form-label";
import PhoneField from "components/forms/phone-field";
import { StaticField } from "components/static-field";
import FormErrorMessage from "components/error-message-form";
import DatePickerField from "components/forms/date-picker-field";
import TimezoneSelect from "react-timezone-select";

// Types.
import type { FormikProps } from "formik";
import type { BasicInfoFormState } from "./basic-info-form-types";
import { isObjectValid, scrollErrorIntoView } from "utils";

export default function BasicInfoFormView({
  values,
  errors,
  touched,
  handleBlur,
  handleChange,
  setFieldValue,
  setFieldTouched,
}: FormikProps<BasicInfoFormState>) {
  React.useEffect(() => {
    if (isObjectValid(errors)) {
      scrollErrorIntoView();
    }
  }, [errors]);

  const colProps = {
    xs: 12,
    md: 6,
  };

  return (
    <Row className={boxStyles.g}>
      {/* Name */}
      <Col {...colProps}>
        <Form.Group>
          <FormLabel htmlFor="name">Full Name</FormLabel>
          <Form.Control
            id="name"
            type="text"
            placeholder="Enter Full Name"
            value={values.name}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          {touched.name && errors.name && (
            <Form.Text className="text-muted">
              <FormErrorMessage message={errors.name} />
            </Form.Text>
          )}
        </Form.Group>
      </Col>
      {/* Email */}
      <Col {...colProps}>
        <Form.Group>
          <FormLabel htmlFor="">Email Address</FormLabel>
          <Form.Control
            disabled
            id="email"
            type="email"
            placeholder="Enter Email Address"
            value={values.email}
          />
        </Form.Group>
      </Col>
      {/* Phone */}
      <Col {...colProps}>
        <Form.Group>
          <FormLabel htmlFor="phone">Mobile Number</FormLabel>
          <PhoneField
            country="ae"
            value={`${values.phone.dialCode}${values.phone.number}`}
            onBlur={() => setFieldTouched("phone", true)}
            onChange={(_, { dialCode, strippedPhoneNumber }) =>
              setFieldValue("phone", {
                dialCode,
                number: strippedPhoneNumber,
              })
            }
          />
          {touched.phone && errors.phone?.number && (
            <Form.Text className="text-muted">
              <FormErrorMessage message={errors.phone.number} />
            </Form.Text>
          )}
        </Form.Group>
      </Col>
      {/* Dob */}
      <Col {...colProps}>
        <Form.Group>
          <FormLabel htmlFor="dob">Date of Birth</FormLabel>
          <DatePickerField
            id="dob"
            value={values.dob}
            onBlur={() => setFieldTouched("dob", true)}
            onChange={(date) => setFieldValue("dob", date)}
          />
          {touched.dob && errors.dob && (
            <Form.Text className="text-muted">
              <FormErrorMessage message={errors.dob} />
            </Form.Text>
          )}
        </Form.Group>
      </Col>
      <Col {...colProps}>
        <Form.Group>
          <FormLabel htmlFor="languages">Languages</FormLabel>
          <StaticField.Multi
            id="languages"
            staticKey="languages"
            value={values.languages}
            maxValues={getEnvInt("TUTOR_MAX_LANGUAGES")}
            onBlur={() => setFieldTouched("languages", true)}
            onChange={(value) => setFieldValue("languages", value)}
          />
          {touched.languages && errors.languages && (
            <Form.Text className="text-muted">
              <FormErrorMessage message={errors.languages as string} />
            </Form.Text>
          )}
        </Form.Group>
      </Col>
      <Col {...colProps}>
        <Form.Group>
          <FormLabel htmlFor="timezone">Timezone</FormLabel>
          <TimezoneSelect
            data-testid="timezoneSelect"
            placeholder="Select your Time Zone"
            // @ts-ignore
            value={values.timezone}
            // @ts-ignore
            defaultValue={values.timezone}
            onBlur={() => setFieldTouched("timezone", true)}
            onChange={(value) => setFieldValue("timezone", value)}
            className="timezone-select"
          />
          {touched.timezone && errors.timezone && (
            <Form.Text className="text-muted">
              <FormErrorMessage message={errors.timezone as string} />
            </Form.Text>
          )}
        </Form.Group>
      </Col>
      <Col {...colProps}>
        <Form.Group>
          <FormLabel htmlFor="title">Profile Title</FormLabel>
          <Form.Control
            id="title"
            type="text"
            placeholder="Enter Profile Title"
            value={values.title}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          {touched.title && errors.title && (
            <Form.Text className="text-muted">
              <FormErrorMessage message={errors.title} />
            </Form.Text>
          )}
        </Form.Group>
      </Col>
      <Col
        {...colProps}
        className="d-flex align-items-md-end justify-content-end"
      >
        <Form.Group className={`d-flex align-items-center ${boxStyles.xGap}`}>
          <FormLabel htmlFor="isSearchable" className="mb-0">
            Appear in Search?
          </FormLabel>
          <Switch
            id="isSearchable"
            data-testid="searchableSwitch"
            checked={values.isSearchable}
            onChange={() => setFieldValue("isSearchable", !values.isSearchable)}
          />
        </Form.Group>
      </Col>
    </Row>
  );
}
