import type {TutoringSessionStatus} from "constants/";
import type {RawLearnerUpcomingSession} from "constants/";

export type SessionStatusCardProps = React.PropsWithChildren<
  RawLearnerUpcomingSession & {
    status: TutoringSessionStatus["id"] | TutoringSessionStatus;
  }
>;

export type SessionStatusCardVariantProps = {
  /**
   * Whether to display the action button or not, depends on the expiration status of a session.
   */
  isExpired: boolean;
  // default success handler of action
  onActionSuccess?: () => void;

  renderer: SessionStatusCardRenderers;
  isDetailOpen?: boolean;
  onViewDetails?: () => void;
} & SessionStatusCardProps;
// &  (
//     | {
//         renderer: SessionStatusCardRenderers.Default;
//         isDetailOpen?: never;
//         onViewDetails?: never;
//       }
//     | {
//         renderer: SessionStatusCardRenderers.ContainedDetails;
//         isDetailOpen: boolean;
//         onViewDetails: () => void;
//       }
//   );

export type SessionStatusCardRendererProps = SessionStatusCardProps;

export enum SessionStatusCardRenderers {
  Default,
  TutorDefault,
  LearnerContainedDetails,
  TutorContainedDetails,
  LearnerPreviousDefault,
}
