import { useCallback } from "react";

// Utils.
import { isFirstVisit } from "utils";
import useTourLib from "./use-tour-lib";
import { IconHelp } from "@tabler/icons";
import useTourData from "./use-tour-data";
import useProfileMenu from "hooks/use-profile-menu";

/**
 * The tour hook. Handles the tour data and the tour library.
 *
 * @example
 * const { tourState, renderTour } = useTour();
 */
export default function useTour() {
  const tour = useTourLib();
  const { addItem } = useProfileMenu();
  const { getTourData, tourState, getCombinedTourData } = useTourData();

  /**
   * Handles the tour data.
   */
  const onSuccess = useCallback(({ data }) => {
    // Add the loaded steps to the tour.
    tour.addSteps(data.steps);

    // Add the tour button to the profile menu.
    addItem({
      id: 4,
      title: "Tour",
      icon: IconHelp,
      onClick: () => tour.start(),
    });

    // Don't show the tour if the user has already visited the page.
    if (!isFirstVisit()) return;

    // Start the tour.
    tour.start();
    // This is done to avoid the infinite loop.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Handles the tour.
   * @param {string} path The path to the tour data without the file extension.
   */
  const renderTour = useCallback((path: string) => {
    getTourData({
      path,
      onSuccess,
      onFailure: () => {
        // Do nothing.
      },
    });
    // This is done to avoid the infinite loop.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Handles the combined tour.
   * @param {string[]} paths The paths to the tour data without the file extension.
   */
  const renderCombinedTour = useCallback((paths: string[]) => {
    getCombinedTourData({
      paths,
      onSuccess,
      onFailure: () => {
        // Do nothing.
      },
    });
    // This is done to avoid the infinite loop.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    tourState,
    renderTour,
    renderCombinedTour,
  };
}
