import {time} from "utils/si-factory";
import {getCurrentTimezone} from "utils";
// import {useUserProfile} from "hooks/profile";
// import {useDispatch} from "react-redux";
// import ToastFactory from "utils/toast-factory";

// Types.
import type {SessionStatusCardProps} from "components/cards";
// import useRemoteProfile from "hooks/profile/use-remote-profile";
import useUser from "hooks/use-user";
import {useLearnerProfile, useTutorProfile} from "hooks/profile";

export default function useSessionStatus(session: SessionStatusCardProps) {
  // const toastFactory = ToastFactory();
  // const dispatch: (dispatch: any) => void = useDispatch();

  const {type} = useUser();

  // The current user's profile.
  // todo: implement useRemoteProfile

  // currently only these two places have latest timezone of user. all others are invalid except the new useRemoteProfile
  const {profile: learnerProfile} = useLearnerProfile();
  const {profile: tutorProfile} = useTutorProfile();

  const profile = type === "learner" ? learnerProfile : tutorProfile;

  /**
   * Date/time formatting library set to the user's or the default timezone.
   */

  const libPerZone = time.getLibPerZone(
    profile.timezone || getCurrentTimezone()
  );

  /**
   * The end time of the current session. Notice the format conversion.
   */
  const sessionEndTime =
    // this will create a datetime of the given date and time and set its zone to specified zone.
    time
      .getLib()
      .fromFormat(
        `${session.date} ${session.end_time}`,
        "yyyy-MM-dd HH:mm:ss",
        {
          zone:
            profile.timezone ||
            localStorage.getItem("TIMEZONE") ||
            getCurrentTimezone(),
        }
      );

  /**
   * Has the session has ended already?
   */
  const isExpired = sessionEndTime < libPerZone;

  //@ts-ignore
  const status: number = session.status?.id || session.status;

  return {status, isExpired};
}
