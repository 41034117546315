import { RemoteOnboardingStatus, RemoteProfile } from "constants/types";
import { useRemoteProfileValue } from "hooks/profile";

export default function useOnboardingValidator() {
  const profile = useRemoteProfileValue();

  function getBasicErrors(profile: RemoteProfile) {
    // Validate if all steps of onboarding are completed.
    const errors: string[] = [];

    /// Validate if all steps of onboarding are completed.
    // Validate basic information form.
    if (!profile.profile_picture)
      errors.push("Please upload a Profile Picture.");

    if (!profile.name) errors.push("Please enter your Name.");

    if (!profile.dob) errors.push("Please enter your Date of Birth.");

    if (!profile.languages.length)
      errors.push("Please select at least one Language.");

    if (!profile.timezone) errors.push("Please select your Timezone.");

    if (!profile.title) errors.push("Please enter your Profile Title.");

    // Validate subjects, skills, and bio forms.
    if (!profile.subjects.length)
      errors.push("Please select at least one Subject.");

    if (!profile.skills.length)
      errors.push("Please select at least one Skill.");

    if (!profile.bio) errors.push("Please enter your Bio.");

    return errors;
  }

  function isStepAlreadyPassed(step: RemoteOnboardingStatus) {
    return profile.onboarding_history?.some(
      (history) => history.status === step
    );
  }

  return {
    getBasicErrors,

    isStepAlreadyPassed,
  };
}
