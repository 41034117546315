import { IconPencil } from "@tabler/icons";
import { ButtonProps } from "react-bootstrap";
import IconButton from "./button-icon";

/**
 * Generic edit button.
 *
 * @param {JSX.IntrinsicElements['button'] & {tooltipPosition?: string} & ButtonProps} props
 *
 * @returns {JSX.Element}
 *
 * @author kashan-ahmad
 * @version 0.0.2
 *
 * @changelog
 * - 0.0.4: Made `variant` a prop
 * - 0.0.3: Added ButtonProps in the type definition.
 * - 0.0.2: Added the `tooltipPosition` property.
 * - 0.0.1: Initial version.
 *
 */
function EditButton({
  tooltipPosition = "top",
  variant = "outline-secondary",
  ...props
}) {
  return (
    <IconButton
      {...props}
      aria-label="Edit"
      variant={variant}
      tooltipPosition={tooltipPosition}
    >
      <IconPencil size={16} aria-hidden={true} />
    </IconButton>
  );
}

export default EditButton;
