import {useRef, useState} from "react";
import {Row, Col, Button, Label, Modal, ModalBody} from "reactstrap";
import {MyModalHeader} from "./";
import modalImage from "../assets/images/dash-head.png";
import SimpleReactValidator from "simple-react-validator";
import {useEffect} from "react";
import {StaticSelect} from "./";
import {VIDEO_TOOL_URL} from "constants";
import {instantConnect} from "store";
import {useDispatch} from "react-redux";
import ToastFactory from "utils/toast-factory";
import {messages, requirements} from "constants/strings";

function InstantConnectModal({isOpen, toggleModal}) {
  const [selectedCurriculum, setSelectedCurriculum] = useState([]);
  const [selectedGrade, setSelectedGrade] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState([]);
  const toastFactory = ToastFactory();
  // const [, forceUpdate] = useState();

  const dispatch = useDispatch();

  const simpleValidator = useRef(new SimpleReactValidator());
  const [randomValue, setRandomValue] = useState(Math.random());

  const handleClick = () => {
    if (simpleValidator.current.allValid()) {
      toastFactory.loading();
      dispatch(
        instantConnect({
          subject: selectedSubject,
          curriculum: selectedCurriculum,
          grade: selectedGrade,
          onSuccess: (res) => {
            if (res.status) {
              const meetingCode = res.data.meeting_code;

              toastFactory.success(res.message);
              toggleModal();
              window.location.replace(
                `${VIDEO_TOOL_URL}/prejoining/${meetingCode}`
              );
              // <Navigate to={`${VIDEO_TOOL_URL}/prejoining/${meetingCode}`} />;
            } else {
              toastFactory.info(messages.SEARCH_EMPTY);
            }
          },
          onFailure: (error) => {
            toastFactory.error(error.message);
          },
        })
      ) && setRandomValue(Math.random());
    } else {
      toastFactory.error(requirements.ALL);
    }
  };

  useEffect(() => {
    setSelectedCurriculum([]);
    setSelectedSubject([]);
    setSelectedGrade([]);
  }, [randomValue]);

  return (
    <Modal
      isOpen={isOpen}
      toggleModal={toggleModal}
      className="text-secondary"
      size="lg"
    >
      <ModalBody className="p-3 text-secondary">
        <MyModalHeader
          title="Let’s find you a tutor"
          toggleModal={toggleModal}
        />
        <p className="mt-1">
          Fill up following simple information & instant connect with the tutor.
        </p>
        <main className="main">
          <div className="my-3">
            <Row className="d-flex my-3">
              <Col xs="12" lg="4">
                <Label className="control-label font-size-13 text-secondary-dim mt-3  mt-lg-0">
                  Subject
                </Label>
                <StaticSelect
                  label="Subjects"
                  selectedOptions={selectedSubject}
                  setSelectedOptions={setSelectedSubject}
                  isClearable={true}
                  isMulti={false}
                  closeMenuOnSelect={true}
                />
                {simpleValidator.current.message(
                  "Subject",
                  selectedSubject,
                  "required"
                )}
              </Col>
              <Col xs="12" lg="4">
                <Label className="control-label font-size-13 text-secondary-dim mt-3 mt-lg-0">
                  Curriculum
                </Label>
                <StaticSelect
                  label="Curriculums"
                  selectedOptions={selectedCurriculum}
                  setSelectedOptions={setSelectedCurriculum}
                  isClearable={true}
                  isMulti={false}
                  closeMenuOnSelect={true}
                />
                {simpleValidator.current.message(
                  "Curriculum",
                  selectedCurriculum,
                  "required"
                )}
              </Col>
              <Col xs="12" lg="4">
                <Label className="control-label font-size-13 text-secondary-dim mt-3 mt-lg-0">
                  Grade
                </Label>
                <StaticSelect
                  label="Grades"
                  selectedOptions={selectedGrade}
                  setSelectedOptions={setSelectedGrade}
                  isClearable={true}
                  isMulti={false}
                  closeMenuOnSelect={true}
                />
                {simpleValidator.current.message(
                  "Grade",
                  selectedGrade,
                  "required"
                )}
              </Col>
            </Row>
          </div>
          <div className="my-3 text-end">
            {" "}
            <Button
              color="secondary"
              style={{minWidth: "5rem"}}
              onClick={handleClick}
            >
              Find The Tutor
            </Button>
          </div>
        </main>

        {/* <footer className="mt-3 rounded bg-secondary p-4 d-flex justify-content-between align-items-center">
          <div>
            <h1 className="text-white mb-2">
              Get the <span className="card-connect_span"> free E-books</span>
            </h1>
            <h1 className="h3 text-white">Enroll Now</h1>
          </div>
          <img src={modalImage} alt="" className="h-100" />
        </footer> */}
      </ModalBody>
    </Modal>
  );
}
export default InstantConnectModal;
