import { messages, requirements } from "constants/strings";
import { mixed } from "yup";

class FileSchema {
  /**
   * @param {Object} props
   *
   * @param {string} props.fileName Used in error messages.
   *
   * @param {number} props.maxSize
   *
   * @param {boolean} props.isRequired Default=true, whether the file is required or not.
   *
   * @param {string[]} props.allowedFormats Allowed file extensions.
   *
   * @param {boolean} props.allowString Default=false, whether the file can be a string or not. If TRUE, all checks will be ignored and the file will be considered a string.
   *
   * This is done is order to consume files as URLs.
   */
  constructor({
    maxSize,
    fileName,
    allowedFormats,
    isRequired = true,
    allowString = false,
  }) {
    return mixed()
      .test({
        name: "required",
        message: requirements.INIT(fileName),
        test: (file) => {
          // Can be a string when initialized from props, a file URL might be received.
          if (allowString && typeof file === "string") {
            return !!file;
          }

          if (isRequired) {
            return file && file.name;
          }

          return true;
        },
      })
      .test({
        name: "fileFormat",
        message: messages.FILE_FORMAT,
        test: (file) => {
          // No need to test file format of a string.
          if (allowString && typeof file === "string") return true;

          if (isRequired || file) {
            return (
              file &&
              allowedFormats.some((format) => file.name.endsWith(format))
            );
          }

          return true;
        },
      })
      .test({
        name: "fileSize",
        message: messages.FILE_SIZE,
        test: (file) => {
          // No need to test filesize of a string.
          if (allowString && typeof file === "string") return true;

          if (isRequired || file) {
            return file && file.size <= maxSize;
          }

          return true;
        },
      });
  }
}

export default FileSchema;
