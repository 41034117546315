import React from "react";
import { ModalProps } from "reactstrap";
import LearnerBookSessionView from "./learner-book-session-view";
import LearnerBookSessionViewModel from "./learner-book-session-view-model";

export interface IComponentProps {
  isOpen: ModalProps["isOpen"];
  toggle: ModalProps["toggle"];
  bundleId: number;
}

function LearnerBookSessionModal(componentProps: IComponentProps): JSX.Element {
  return (
    <LearnerBookSessionViewModel {...componentProps}>
      {(passedControllers) => <LearnerBookSessionView {...passedControllers} />}
    </LearnerBookSessionViewModel>
  );
}

export default LearnerBookSessionModal;
