import {useSelector} from "react-redux";
import {useLearnerProfile, useTutorProfile} from "./profile";

/**
 * Basic user type checker and defaulter.
 * @returns {User}
 * @version 0.0.5
 * @author [kashan-ahmad, Abdullah-Sajjad026]
 *
 * @changelog
 * - 0.0.5: Added all of the user's attributes.
 * - 0.0.4: Removed 403 redirection upon error.
 * - 0.0.3: Removed specific strings and changed the return type to an Object.
 * - 0.0.2: Added specific strings to catch disallowed types.
 * - 0.0.1: Initial version.
 */
function useUser() {
  const type = localStorage.getItem("userType");

  const {profile: learnerProfile} = useLearnerProfile();
  const {profile: tutorProfile} = useTutorProfile();

  const rawUserProfile = type === "tutor" ? tutorProfile : learnerProfile;

  return {type, ...rawUserProfile};
}

export default useUser;
