import React from "react";
import {Loader} from "components";

const View = React.lazy(() => import("./tutoring-learner-previous-sessions"));

const TutoringLearnerPreviousSessions = (props) => (
  <React.Suspense fallback={<Loader />}>
    <View {...props} />
  </React.Suspense>
);

export default TutoringLearnerPreviousSessions;
