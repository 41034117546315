import React, {useState} from "react";
import SimpleBar from "simplebar-react";
import {ReduxStore} from "constants/";
import {useApiEffect, useUser} from "hooks";
import {getMessagesUsers} from "store/store-messages/store-messages";
import {useDispatch, useSelector} from "react-redux";
import {Loader, SassyAlert} from "components";
import {messages} from "constants/strings";
import {Link} from "react-router-dom";
import {ListItemUser} from "components/list-items";
import OpenCloseButton from "components/open-close-button";

/**
 * Messages Users List
 */
export default function MessagesUsersList({
  openedUserId,
}: {
  openedUserId?: number;
}): JSX.Element {
  const isOnSmallScreen = window.innerWidth < 576;

  const {type: userType} = useUser();
  // const [activeMessagesTab, setActiveMessagesTab] = useState(1);
  // const onSwitchTab = (tabNumber: number) => setActiveMessagesTab(tabNumber);

  const [areUsersExpanded, setAreUsersExpanded] = useState(true);

  const dispatch: (action: any) => void = useDispatch();
  const {messagesUsers} = useSelector((store: ReduxStore) => ({
    messagesUsers: store.messages.connectedUsers,
  }));

  useApiEffect(() => {
    dispatch(getMessagesUsers({}));
  }, []);

  return (
    <section>
      {/* <h3>Messages</h3> */}

      <div className="hstack justify-content-between">
        <h3 className="mb-0 ">
          My {userType === "learner" ? "Tutors" : "Learners"}
        </h3>
        {isOnSmallScreen && (
          <OpenCloseButton
            orientation="vertical"
            isActive={areUsersExpanded}
            onClick={() => setAreUsersExpanded((prev) => !prev)}
          />
        )}
      </div>

      {/* <TabBar className="my-3">
        <TabBar.Item
          tabNumber={1}
          activeTab={activeMessagesTab}
          onSwitchTab={onSwitchTab}
        >
          All
        </TabBar.Item>
        <TabBar.Item
          tabNumber={2}
          activeTab={activeMessagesTab}
          onSwitchTab={onSwitchTab}
        >
          Unread
        </TabBar.Item>
        <TabBar.Item
          className="ms-auto"
          tabNumber={3}
          activeTab={activeMessagesTab}
          onSwitchTab={onSwitchTab}
        >
          Archived
        </TabBar.Item>
      </TabBar> */}

      {/* {areUsersExpanded ? ( */}
      <SimpleBar
        style={{
          maxHeight: areUsersExpanded ? "80vh" : "0",
          overflow: "auto",
        }}
      >
        <div className="mt-3 px-2">
          {/* {activeMessagesTab === 1 && ( 
             <> */}
          {messagesUsers.state === "loading" ? (
            <Loader />
          ) : messagesUsers.state === "erred" ? (
            <SassyAlert color="danger" title={messages.AN_ERR} />
          ) : messagesUsers.data.length === 0 ? (
            <SassyAlert
              color="info"
              title={`No connected ${
                userType === "learner" ? "tutor" : "learner"
              } found`}
            />
          ) : (
            <div className="d-flex flex-column gap-3">
              {messagesUsers.data.map((user) => (
                <Link
                  to={`/messages/user/${user.id}`}
                  key={user.id}
                  onClick={() => {
                    if (isOnSmallScreen) setAreUsersExpanded(false);
                  }}
                >
                  <ListItemUser
                    className={`rounded p-1 ${
                      user.id === openedUserId ? "bg-soft bg-info" : ""
                    }`}
                    avatarSrc={user.profile_picture}
                    username={user.name}
                    itemTag="div"
                    // leftWing={
                    //   <span
                    //     className={`rounded-circle ${
                    //       user.status === 1 ? "bg-success" : "bg-gray-300"
                    //     }`}
                    //     style={{width: "7px", height: "7px"}}
                    //   />
                    // }
                    // rightWing={
                    //   <div className="hstack gap-2">
                    //     <IconPinned size="18" className="cursor-pointer" />
                    //     <IconStar
                    //       size="18"
                    //       fill="#F3D11A"
                    //       color="#F3D11A"
                    //       className="cursor-pointer"
                    //     />
                    //     <Label color="info" tag="span" className="">
                    //       02
                    //     </Label>
                    //   </div>
                    // }
                  />
                </Link>
              ))}
            </div>
          )}
          {/* </> 
             )} */}
        </div>
      </SimpleBar>
      {/* ) : (
        <p>null</p>
      )} */}

      {/* {activeMessagesTab === 2 && <>Unread Messages</>}
          {activeMessagesTab === 3 && <>Archived Messages</>} */}
    </section>
  );
}
