import React from "react";
import { Button } from "reactstrap";
import BrandLogo from "./invoice-logo.png";
import SimpleBar from "simplebar-react";
import styles from "./transaction-invoice.module.scss";

/**
 * @author Abdullah-Sajjad026
 */
function TransactionInvoice() {
  const invoiceData = JSON.parse(sessionStorage.getItem("invoiceData"));

  const { refId, date, status, userName, totalPrice, receiptItems } =
    invoiceData;

  function calculatePercentage(transactionCharges, total, aed) {
    if (total === 0) {
      return (0).toFixed(2);
    }
    const percentage =
      (Number(transactionCharges) * Number(total)) / 100 + Number(aed);
    return percentage.toFixed(2);
  }

  function calculatePercentageTax(tax, total) {
    if (tax === 0) {
      return (0).toFixed(2);
    }
    const percentage = (Number(tax) * Number(total)) / 100;
    return percentage.toFixed(2);
  }

  // const totalAmount = receiptItems.reduce(
  //   (acc, curr) => (acc += curr.amount),
  //   0
  // );
  // const totalSessions = receiptItems.reduce(
  //   (acc, curr) => (acc += curr.sessions),
  //   0
  // );

  // useEffect(() => window.print(), []);

  return (
    <main
      className="my-4 mx-auto p-3 overflow-x-auto"
      style={{ maxWidth: "1200px" }}
    >
      <section>
        <div className="d-flex align-items-start gap-2 ">
          <div style={{ width: "38%" }}>
            <img src={BrandLogo} alt="" width="170" />
          </div>
          {/* <div className="d-flex flex-column w-100 d-sm-none justify-items-start gap-2 my-3">
            <div className="d-flex flex-column">
              <span>Pathfinder Innovation Portal LLC</span>
              <span>Dubai, UAE</span>
              <span>P.O.Box 24857</span>
            </div>
            <div className="flex-grow-1 d-flex align-items-center justify-content-between">
              <h5 className="fw-bold font-size-13 mb-0">Customer Name:</h5>
              <div className="text-end">{userName}</div>
            </div>
          </div> */}
          <div className="vstack">
            <div className="d-flex align-items-center justify-content-between">
              <h5 className="fw-bold font-size-13">Receipt #:</h5>
              <div className="text-end">{refId}</div>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <h5 className="fw-bold font-size-13">Receipt Date:</h5>
              <div className="text-end">{date}</div>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <h5 className="fw-bold font-size-13">Receipt Status:</h5>
              <div className="text-end">{status}</div>
            </div>
          </div>
        </div>

        <div className="d-flex align-items-start gap-2 my-3">
          <div style={{ width: "38%" }} className="d-flex flex-column">
            <span>Pathfinder Innovation Portal LLC</span>
            <span>Dubai, UAE</span>
            <span>P.O.Box 24857</span>
          </div>
          <div className="flex-grow-1 d-flex align-items-center justify-content-between">
            <h5 className="fw-bold font-size-13 mb-0">Customer Name:</h5>
            <div className="text-end">{userName}</div>
          </div>
        </div>
      </section>

      <div className="mt-1">
        <SimpleBar>
          <table
            width="100%"
            className="overflow-auto "
            style={{ minWidth: "600px" }}
          >
            <tbody className={styles.tablemargins}>
              {receiptItems.map((i) => (
                <>
                  <tr>
                    <td className="pt-4">
                      <strong>Item</strong>
                    </td>
                    <td className="hstack pt-4">
                      {i.subject || i.product.material_title} &nbsp;
                      {i.subject && <b>[Online Tutoring Session(s)]</b>}
                    </td>
                  </tr>
                  <tr>
                    <td className="pt-4">
                      <strong>
                        Number of{" "}
                        {i.product.material_title ? "Materials" : "Sessions"}
                      </strong>
                    </td>
                    <td className="pt-4">{Number(i.product.quantity)}</td>
                  </tr>
                  <tr>
                    <td className="pt-4">
                      <strong>Tutor</strong>
                    </td>
                    <td className="pt-4">{i.userName}</td>
                  </tr>
                  <tr>
                    <td className="pt-4">
                      <strong>Amount (USD)</strong>
                    </td>
                    <td className="pt-4">
                      {Number(i.product.price).toFixed(2)}
                    </td>
                  </tr>
                  <tr>
                    <td className="pt-4">
                      <strong>Discount (USD)</strong>
                    </td>
                    <td className="pt-4">
                      {Number(
                        i.product.order_detail?.voucher_discount || 0
                      ).toFixed(2)}
                    </td>
                  </tr>

                  <tr>
                    <td className="pt-4">
                      <strong>Total (USD)</strong>
                    </td>
                    <td className="pt-4">
                      {Number(i.product.total_fee).toFixed(2)}
                    </td>
                  </tr>
                  <tr style={{ borderTop: "1px solid black" }}>
                    <td className="pt-4">
                      <strong>Tax (USD)</strong>
                    </td>
                    <td className="pt-4">
                      {calculatePercentageTax(
                        i.product.order_detail.tax,
                        i.product.total_fee
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td className="pt-4">
                      <b>Transaction Charges</b>
                      &nbsp;&nbsp;
                      <span>
                        {i.product.order_detail.processing_fee} <b>%</b>
                      </span>
                      &nbsp;
                      <b>(USD)</b>
                    </td>
                    <td className="pt-4">
                      {calculatePercentage(
                        i.product.order_detail.processing_fee,
                        i.product.total_fee,
                        i.product.order_detail.aed_charge
                      )}{" "}
                    </td>
                  </tr>
                  <tr style={{ borderTop: "1px solid black" }}>
                    <td className="pt-4">
                      <strong>Grand Total (USD)</strong>
                    </td>
                    <td
                      className="pt-4"
                      style={{
                        borderTop: "1px solid black",
                      }}
                    >
                      {Number(i.product.total_fee) === 0
                        ? (0).toFixed(2)
                        : Number(i.product.order_detail.grandTotal).toFixed(2)}
                    </td>
                  </tr>
                  {/* <tr>
                    <td style={{ paddingTop: "20px" }}>
                      <b>Total:</b>
                    </td>
                    <td>
                      {Number(i.product.order_detail.subtotal) +
                        Number(i.product.order_detail.processing_fee)}
                    </td>
                  </tr> */}
                </>
              ))}
            </tbody>
          </table>
        </SimpleBar>
      </div>
      <div className="text-end mt-4">
        <Button
          className={styles["print-button"]}
          onClick={() => window.print()}
        >
          Print
        </Button>
      </div>
    </main>
  );
}

export default TransactionInvoice;
