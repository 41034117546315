import { Button } from "reactstrap";

/**
 * The notification action that allows the
 * user to confirm or cancel the notification,
 * with a different touch and feel.
 *
 * @param {Object} object The properties passed
 * to the component.
 * @param {boolean} object.isCompact Whether the
 * notification is compact or not.
 * @param {() => void} object.onConfirm The confirmation
 * handler.
 * @param {() => void} object.onCancel The cancellation
 * handler.
 * @param {string} object.className The CSS classes.
 * @param {*} object.props The rest of the properties.
 *
 * @returns {JSX.Element} The component.
 * @version 0.0.1
 * @author kashan-ahmad.
 *
 * @changelog
 * - 0.0.2: Added the `onConfirm` and `onCancel` handlers.
 * - 0.0.1: Initial version.
 */
const NotificationActionSetup = ({
  isCompact = false,
  onConfirm = () => {},
  onCancel = () => {},
  className = "",
  ...props
}) => (
  <div {...props} className={`d-flex gap-2 ${className}`}>
    <Button
      color="secondary"
      onClick={onCancel}
      className={isCompact ? "flex-grow-1" : ""}
      outline
    >
      Remind me Later
    </Button>
    <Button color="secondary" onClick={onConfirm} className="py-2">
      Setup Session
    </Button>
  </div>
);

export default NotificationActionSetup;
