import { useStaticDataV3 } from "hooks/static-data";
import { StaticDataV3Key } from "constants/types/types-static-data";
import React from "react";
import { StaticFieldOption } from "components/static-field";

type Props = {
  /**
   * The values returned from the API.
   */
  values: any[];

  /**
   * The key to use to get the id from the values.
   */
  keyOfId: string;

  /**
   * The static key.
   */
  key: StaticDataV3Key;
};

/**
 * Hook to get the values for a static combobox.
 */
export default function useStaticValues({
  key,
  values,
  keyOfId,
}: Props): StaticFieldOption[] {
  const { getStaticData, ...records } = useStaticDataV3([key]);

  // removing null or undefined values.
  values = values.filter((value) => value !== null && value !== undefined);

  // Create the values.
  return React.useMemo(
    () =>
      values.map((value) => ({
        // Value is the id.
        value: value[keyOfId],
        // Label is the string against the id.
        label: getStaticData(value[keyOfId], records[key].data) ?? "",
      })) satisfies StaticFieldOption[],
    [getStaticData, key, keyOfId, records, values]
  );
}
