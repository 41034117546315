import { generateReducer } from "utils";
import ACTION_TYPES from "./store-static-v3-types";
import { StaticDataV3Adapter } from "adapters/static-data";
import type {
    StaticDataV3Store,
    StaticDataV3Action,
} from "constants/types/types-static-data";

const INITIAL_STATE: StaticDataV3Store = {
    grades: {
        data: new Map(),
        state: "loading",
    },
    degrees: {
        data: new Map(),
        state: "loading",
    },
    goals: {
        data: new Map(),
        state: "loading",
    },
    subjects: {
        data: new Map(),
        state: "loading",
    },
    languages: {
        data: new Map(),
        state: "loading",
    },
    skills: {
        data: new Map(),
        state: "loading",
    },
    curriculums: {
        data: new Map(),
        state: "loading",
    },
    qualifications: {
        data: new Map(),
        state: "loading",
    },
    requestTypes: {
        data: new Map(),
        state: "loading",
    },
    learningStyles: {
        data: new Map(),
        state: "loading",
    },
    materialTypes: {
        data: new Map(),
        state: "loading",
    },
    interests: {
        data: new Map(),
        state: "loading",
    },

    // these are not static data, but are resources related to a tutor's services or learner's purchases.
    // looking for a better prefix though :smile emoji:
    offeredCurriculums: {
        data: new Map(),
        state: "loading",
    },
    offeredGrades: {
        data: new Map(),
        state: "loading",
    },
    offeredSubjects: {
        data: new Map(),
        state: "loading",
    },
};

/**
 * Reducer for the static API.
 *
 * @author kashan-ahmad
 * @version 0.0.1
 * @changelog
 */
export default function staticDataV3Reducer(
    state = INITIAL_STATE,
    { type, payload }: StaticDataV3Action
) {
    return generateReducer({
        state,
        payload,
        currentActionType: type,
        actionTypes: ACTION_TYPES,
        adapter: new StaticDataV3Adapter(),
    });
}
