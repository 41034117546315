/* eslint-disable eqeqeq */
import { SassyImg } from "../..";
import { Col, Row } from "reactstrap";

// Static.
import Image from "assets/images/lm-error.svg";
import ListItemMaterialActions from "./list-item-material-actions";

/**
 * @typedef {Object} ListItemMaterialProps
 *
 * @property {HeadingLevel} headingTag
 * The semantically accurate heading tag to display.
 *
 * @property {Function | undefined} editButtonListener
 *
 * @property {Function | undefined} downloadButtonListener
 *
 * @property {Function | undefined} deleteButtonListener
 *
 * @property {Function | undefined} cloneButtonListener
 *
 * @property {string} grade
 * The name of the grade associated with the corresponding material.
 *
 * @property {string} subject
 * The name of the subject associated with the corresponding material.
 *
 * @property {string} curriculum
 * The name of the curriculum associated with the corresponding material.
 *
 * @property {Object | undefined} thumbnailBreakpoints
 * Custom breakpoints in case you don't like the default ones.
 *
 * @property {Object | undefined} titleBreakpoints
 * Custom breakpoints in case you don't like the default ones.
 *
 * @property {Object | undefined} statBreakpoints
 * Custom breakpoints in case you don't like the default ones.
 *
 * @property {Object | undefined} actionButtonBreakpoints
 * Custom breakpoints in case you don't like the default ones.
 *
 * @property {boolean | undefined} allowThumbnail
 * Default = true; Whether to display the thumbnail or not.
 *
 * @property {boolean | undefined} allowTitle
 * Default = true; Whether to display the title or not.
 *
 * @property {boolean | undefined} allowStats
 * Default = true; Whether to display the stats or not.
 *
 * @property {boolean | undefined} allowActionButtons
 * Default = true; Whether to display the action buttons or not.
 */

/**
 * A list item that marks up a learning material instance.
 * @param {Omit<LearningMaterial, 'grades'|'subjects'|'curriculums'> & ListItemMaterialProps} object
 * @returns {JSX.Element}
 * @author kashan-ahmad
 * @version 0.0.1
 */
function ListItemMaterial({
  title,
  description,
  grade = "",
  subject = "",
  curriculum = "",
  lmVisibility,
  product_thumbnail,
  editButtonListener,
  deleteButtonListener,
  cloneButtonListener,
  downloadButtonListener,
  toggleSearchableListener,
  viewButtonListener,
  allowStats = true,
  allowTitle = true,
  allowThumbnail = true,
  allowActionButtons = true,
  headingTag: Heading = "div",
  statBreakpoints = { xs: 12, sm: 4, xl: 4 },
  titleBreakpoints = { xs: 12, sm: 4, xl: 4 },
  thumbnailBreakpoints = { xs: 12, sm: 2, xl: 2 },
  actionButtonBreakpoints = { xs: 12, sm: 2, xl: 2 },
  ...material
}) {
  return (
    <>
      <Row className="align-items-center g-3">
        {/* Thumbnail. */}
        {allowThumbnail && (
          <Col {...thumbnailBreakpoints} className="align-self-stretch">
            <div className="border-1 rounded thumbnail-wrapper">
              <SassyImg
                alt="No Preview"
                className="thumbnail"
                fallbackImgSrc={Image}
                src={product_thumbnail}
              />
            </div>
          </Col>
        )}
        {/* Title & Description, etc. */}
        {allowTitle && (
          <Col {...titleBreakpoints} className="vstack justify-content-center">
            <Heading className="fs-5 fw-semi-bold mb-0 text-truncate">
              {title}
            </Heading>
            {description ? (
              <p className="mb-0 text-blue text-truncate-n2">{description}</p>
            ) : (
              <div className="hstack gap-2 text-truncate mt-1">
                <div className="text-blue fs-7 list-dots">
                  <span>{subject.split(" ")[0]}</span>
                  <span>{grade.split("/")[0]}</span>
                  <span>{curriculum.split(" ")[0]}</span>
                </div>
              </div>
            )}
          </Col>
        )}
        {/* Stats. */}
        {allowStats && (
          <Col {...statBreakpoints}>
            <Row className="g-3">
              <Col xs={12} sm={6} xl={4}>
                <div className="d-flex gap-2 gap-sm-0 flex-sm-column align-items-center align-items-sm-start text-secondary">
                  {/* TODO: activate these stats */}
                  <div className="fw-bold fs-5">
                    {material.sellings.enrollments}
                  </div>
                  <p className="m-0">Enrollments this month</p>
                </div>
              </Col>
              <Col xs={12} sm={6} xl={3}>
                <div className="d-flex gap-2 gap-sm-0 flex-sm-column align-items-center align-items-sm-start text-secondary">
                  <div className="fw-bold fs-5">
                    {material.sellings.boughts}
                  </div>
                  <p className="m-0">Bought</p>
                </div>
              </Col>
              <Col xs={12} sm={6} xl={3}>
                <div className="d-flex gap-2 gap-sm-0 flex-sm-column align-items-center align-items-sm-start text-secondary">
                  <div className="fw-bold fs-5">{material.views}</div>
                  <p className="m-0">Views</p>
                </div>
              </Col>
              <Col xs={12} sm={6} xl={2}>
                <div className="d-flex gap-2 gap-sm-0 flex-sm-column align-items-center align-items-sm-start text-secondary">
                  <div className="fw-bold fs-5">
                    ${material.sellings.earned_amount}
                  </div>
                  <p className="m-0">Earned</p>
                </div>
              </Col>
            </Row>
          </Col>
        )}
        {/* Action buttons. */}
        {allowActionButtons && (
          <Col {...actionButtonBreakpoints} className="text-end">
            <ListItemMaterialActions
              {...{
                lmVisibility,
                editButtonListener,
                downloadButtonListener,
                deleteButtonListener,
                cloneButtonListener,
                viewButtonListener,
                toggleSearchableListener,
              }}
            />
          </Col>
        )}
      </Row>
    </>
  );
}

export default ListItemMaterial;
