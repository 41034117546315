/* eslint-disable eqeqeq */
import React from "react";
import NotificationAction from "../notification-action";
import NotificationAvatar from "../notification-avatar";
import NotificationCompactBody from "./notification-compact-body";

/**
 * @typedef {Object} NotificationCompactProps
 *
 * @property {Function} onClick
 *
 * @property {import("constants/types/types-notifications").NotificationType} notificationType
 */

/**
 * A compact variant of the notification for the dropdown.
 *
 * @param {NotificationCompactProps & import("constants/types/types-notifications").Notification} object
 *
 * @returns {JSX.Element}
 *
 * @version 0.0.3
 * @author kashan-ahmad
 *
 * @changelog
 * - 0.0.3: Introduced `NotificationAvatar` and
 * `NotificationCompactBody` components.
 * - 0.0.2: Added the `onClick`, `onConfirm`, `onCancel`,
 * and `onReschedule` properties.
 * - 0.0.1: Initial version.
 */
const NotificationCompact = ({
  seen,
  onClick,
  created_at,
  description,
  profile_picture,
  notificationType,
  ...props
}) => (
  <div
    onClick={(e) => onClick?.(e)}
    className={`hover:bg-gray-300 d-flex gap-2 w-100 m-0 p-2 p-sm-3 ${
      seen == 0 ? "bg-gray-300 cursor-pointer" : ""
    }`}
  >
    <NotificationAvatar {...{ notificationType, profile_picture }} />
    <div className="flex-grow-1 vstack gap-2">
      <NotificationCompactBody
        displayTime={created_at}
        {...{ seen, description, notificationType }}
      />
      {notificationType.action && (
        <NotificationAction
          {...props}
          isCompact
          action={notificationType.action}
        />
      )}
    </div>
  </div>
);

export default NotificationCompact;
