import React, { useState } from "react";
import moment from "moment";
import { Col, Row } from "reactstrap";
import { SassyAlert, TextField } from "..";
import Slot from "./Slot";
import SimpleBar from "simplebar-react";

/**
 * Book session component which accepts all required states and states functions as props
 *  and return an interactive component to book a session.
 *
 * @author Abdullah-Sajjad026
 */
function BookSession({
  duration,
  slotsClass = "",
  availableSlots,
  parentComponent,
  selectedDate,
  setSelectedDate,
  selectedSlot,
  setSelectedSlot,
}) {
  // date from which booking session slots start
  const [startDate, setStartDate] = useState(selectedDate);
  // const [endTime, setEndTime] = useState("");

  // programmatically creating an array of 7 dates using moment.js
  let days = [];
  let daysRequired = 6;

  for (let i = 0; i <= daysRequired; i++) {
    days.push(moment(startDate).add(i, "days"));
  }

  // handling a click on a slot.
  const bookSlot = (data) => {
    if (selectedSlot && selectedSlot.id === data.id) {
      setSelectedSlot(null);
      // setEndTime("");
    } else {
      setSelectedSlot(data);
      // setEndTime(data.endTime);
    }
  };

  const isUserAvailable =
    availableSlots.data.filter((i) => i.slots.length > 0).length > 0;

  return (
    <div>
      <Row className="p-0 w-100 m-0">
        <Col lg="12" className="p-0 m-0">
          <Row className="p-0 m-0 w-100 justify-content-between">
            <Col sm="5" md="12" xl="5" className="p-0 m-0 pe-md-3">
              <label className="tutor-profile-setup-text mt-2 mb-0">
                Start Date
              </label>
              <TextField
                type={"date"}
                name="startDate"
                min={new Date().toISOString().split("T")[0]}
                max={
                  new Date(new Date().setFullYear(new Date().getFullYear() + 1))
                    .toISOString()
                    .split("T")[0]
                }
                onChange={(e) => {
                  setStartDate(e.target.value);
                  setSelectedDate(e.target.value);
                }}
                value={selectedDate}
              />
            </Col>
            <Col sm="3" md="12" xl="3" className="p-0 m-0">
              <label className="tutor-profile-setup-text mt-2 mb-0">Time</label>
              <TextField
                type="text"
                name="startTime"
                value={selectedSlot ? `${selectedSlot.id.split(" ")[1]}` : ""}
                // value={
                //   selectedSlot
                //     ? `${selectedSlot.id.split(" ")[1]} - ${endTime}`
                //     : ""
                // }
                className="user-account-input"
                disabled
              />
            </Col>
            <Col sm="3" md="12" xl="3" className="p-0 m-0">
              <label className="tutor-profile-setup-text mt-2 mb-0">
                Duration
              </label>
              <TextField
                type="text"
                name="duration"
                value={`${duration ? duration + " minutes" : ""}`}
                className="user-account-input"
                disabled
              />
            </Col>
          </Row>
        </Col>
      </Row>
      {!isUserAvailable ? (
        <SassyAlert
          color="info"
          title="Not available for this week. Select another start date"
        />
      ) : (
        <SimpleBar className={`${slotsClass}`}>
          <Row className="p-0 w-100 m-0">
            {startDate &&
              days.map((d, i) => (
                // mapping days array through which are returning 7 cols, every col corresponding to one day.
                <Col
                  key={i}
                  className="px-0 py-2 m-0 justify-content-center align-items-center"
                >
                  <div className="bg-light-gray py-2">
                    <p className=" pb-0 mb-0 text-center text-secondary">
                      {moment(d).format("ddd")}
                    </p>
                    <p className="pb-0 mb-0 text-center text-secondary">
                      {moment(d).format("DD")}
                    </p>
                  </div>

                  <Row className="p-0 w-100 m-0">
                    {
                      // returning slots for a single day in its col.
                      availableSlots.data
                        // finding the slots corresponding to that day.
                        ?.find(
                          (item) => item.day == (d.day() === 0 ? 7 : d.day())
                        )
                        .slots.map((item, i) => {
                          const uidStart = `${d + i + item.start}`; //unique id
                          const timestampStart =
                            moment(d).format("DD/MM/YYYY") + " " + item.start;

                          return (
                            <Slot
                              key={uidStart}
                              day={moment(d).format("DD/MM/YYYY")}
                              startTime={item.start}
                              endTime={item.end}
                              isReserved={item.reserved}
                              setTime={bookSlot}
                              active={
                                selectedSlot
                                  ? selectedSlot.id === timestampStart
                                  : null
                              }
                            />
                          );
                        })
                    }
                  </Row>
                </Col>
              ))}
          </Row>
        </SimpleBar>
      )}
    </div>
  );
}

export default BookSession;
