import {WEBSITE_URL} from "constants";
import React from "react";
import {useSelector} from "react-redux";
import {Col, Row} from "reactstrap";
import {DashboardCardTutor} from "views/dashboard/dashboard-card";
// import {IViewModelPassedValues} from "./learner-subjects-tutors-view-model";

function LearnerSubjectsTutorsView({eligibleSubjectsTutors}) {
  const popularTutors = useSelector(
    (state) => state.learnerDashboard.popularTutors
  );

  return eligibleSubjectsTutors.length > 0 ? (
    <div className="vstack gap-5">
      {eligibleSubjectsTutors.map((g) => (
        <section className="">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h2 className="blogs-header ">{g.subject} Tutors</h2>
            <a
              href={`${WEBSITE_URL}/tutor/search?subject=${g.subject?.replaceAll(
                " ",
                "-"
              )}`}
              className="border btn btn-secondary w-12 body-para-medium"
            >
              View more
            </a>
          </div>

          {/* card rendering section */}
          <div>
            <Row className="popular-learning-material-cards g-3">
              {g.tutors.map((item, index) => {
                return (
                  <Col xl="4" sm="6" xs="12" key={index}>
                    <DashboardCardTutor
                      id={item.tutor.id}
                      name={item.tutor.name}
                      feePerSession={item.lowest_fee}
                      bought={item.boughts}
                      profilePicture={item.tutor.profile.profile_picture}
                      experience={item.experience}
                      rating={item.rating}
                      students={item.students}
                      keywords={item.keywords}
                      bio={item.tutor.bio}
                      offersFreeTrial={item.free_session}
                      freeBuyersList={item.free_session_bought_from}
                      bundles={item.bundles}
                      gradesIds={item.grades}
                      curriculumsIds={item.curriculums}
                      subjectsIds={item.subjects}
                    />
                  </Col>
                );
              })}
            </Row>
          </div>
          {/* card rendering section */}
        </section>
      ))}
    </div>
  ) : (
    popularTutors.isData && (
      <section className="">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h2 className="blogs-header ">Popular Tutors</h2>
          <a
            href={`${WEBSITE_URL}/tutor/search?sort=Trending`}
            className="border btn btn-secondary w-12 body-para-medium"
          >
            View more
          </a>
        </div>
        {/* card rendering section */}
        <div>
          <Row className="popular-learning-material-cards g-3">
            {popularTutors.popularTutors.map((item, index) => {
              return (
                <Col xl="4" md="6" sm="6" xs="12" key={index}>
                  <DashboardCardTutor
                    id={item.tutor.id}
                    name={item.tutor.name}
                    feePerSession={item.lowest_fee}
                    bought={item.boughts}
                    profilePicture={item.tutor.profile.profile_picture}
                    experience={item.experience}
                    rating={item.rating}
                    students={item.students}
                    keywords={item.keywords}
                    bio={item.tutor.bio}
                    offersFreeTrial={item.free_session}
                    freeBuyersList={item.free_session_bought_from}
                    bundles={item.bundles}
                    gradesIds={item.grades}
                    curriculumsIds={item.curriculums}
                    subjectsIds={item.subjects}
                  />
                </Col>
              );
            })}
          </Row>
        </div>
      </section>
    )
  );
}

export default LearnerSubjectsTutorsView;
