/*!!! DO NOT REMOVE ANY COMMENTS. !!!*/

import moment from "moment";
import { Avatar } from "components";
import { PAYMENT_HISTORY_STATUS_TYPES } from "constants/";
import { IconEye } from "@tabler/icons";
import InvoiceDownloadButton from "components/invoice-download-button";
import { transactionStatus } from "constants/strings";

// import { Badge } from "reactstrap";
// import { useStaticApi, useUser } from "hooks";
//import { capitalize, getKeyFromArrUsingId } from "utils";

const dateFormatter = (_, { date }) =>
  moment(date, "YYYY-MM-DD").format("DD, MMM YYYY");

const refFormatter = (_, { ref_id }) => <span>#{ref_id.substring(0, 5)}</span>;

const typeFormatter = (_, { product_type }) =>
  product_type === "materials" ? "Materials" : "1:1 Tutoring";

/**
 * @param { PaymentHistory & { title: string } & StaticDataState } object
 * @returns { JSX.Element }
 */
// const DetailsFormatter = ({
//   title,
//   grade_id,
//   subject_id,
//   curriculum_id,
//   grades,
//   subjects,
//   curriculums,
// }) => (
//   <div>
//     <p className="text-secondary mb-0">{title}</p>
//     <ul className="transactions-subject-list mb-0 p-0">
//       {subjects && subjects.length > 0 && (
//         <li>{getKeyFromArrUsingId(subjects, "subject", subject_id)}</li>
//       )}
//       {grades && grades.length > 0 && (
//         <li> {getKeyFromArrUsingId(grades, "grade", grade_id)}</li>
//       )}
//       {curriculums && curriculums.length > 0 && (
//         <li>{getKeyFromArrUsingId(curriculums, "title", curriculum_id)}</li>
//       )}
//     </ul>
//   </div>
// );

const userFormatter = ({ userType, row }) => (
  <div className="hstack gap-3">
    <Avatar
      alt={userType === "learner" ? "Tutor Image" : "Learner Image"}
      src={
        userType === "learner"
          ? row.product_owner.profile_picture
          : row.purchaser_image
      }
      className="avatar-xs "
    />
    <div>
      {(userType === "learner"
        ? row.product_owner.name
        : row.purchaser_name
      ).substring(0, 30)}
    </div>
  </div>
);

const amountFormatter = (_, { price }) => <span>${price}</span>;

// * If tutor side all earnings tab then use this function to calculate amount and display
const amountFormatterSum = (cell, row, productType) => {
  if (Array.isArray(cell)) {
    // Calculate the sum of the "amount_to_trasnfer" array
    const totalSum = cell.reduce((acc, obj) => {
      const amount = Number(obj.amount_to_trasnfer);
      return acc + amount;
    }, 0);
    console.log(totalSum, "totalSum");
    return <span>${totalSum.toFixed(2)}</span>;
  } else {
    // Handle other cases, if needed
    return cell;
  }
};

const statusFormatter = (_, { status }) => {
  const { classNames, label } = PAYMENT_HISTORY_STATUS_TYPES.find(
    (TYPE) => TYPE.key === status
  ) || {
    classNames: "bg-success text-white rounded",
    label: transactionStatus?.[status] || status,
  };

  return (
    <span
      className={`fs-8 fw-bold mb-0 p-2 py-1 text-capitalize ${classNames}`}
    >
      {label}
    </span>
  );
};

const actionFormatter = ({ row, onViewDetails }) => {
  return (
    <div className="hstack gap-2">
      <span
        role="tooltip"
        aria-label="View Details"
        data-microtip-position="top"
      >
        <IconEye
          size="17"
          className="cursor-pointer"
          onClick={() => onViewDetails(row)}
        />
      </span>
      <span
        role="tooltip"
        aria-label="View Invoice"
        data-microtip-position="top"
      >
        <InvoiceDownloadButton
          size="17"
          className="cursor-pointer"
          refId={row.ref_id}
          date={moment(row.date, "YYYY-MM-DD").format("DD, MMM YYYY")}
          status={transactionStatus?.[row.status] || row.status}
          price={row.price}
        />
      </span>
    </div>
  );
};

/**
 * @param { StaticDataState & { userType: User['type'],
 * productType: import("./module-payments-table").PaymentsTableModuleProps['productType'],
 * title: import("./module-payments-table").PaymentsTableModuleProps['title'] }} object
 * @returns {JSX.Element}
 *
 * @author Abdullah-Sajjad026, kashan-ahmad
 * @version 1.0.0
 */
export const getPaymentsTableColumns = ({
  title,
  userType,
  productType,
  onViewDetails,
  ...props
}) => [
  {
    dataField: "date",
    text: "Date",
    formatter: dateFormatter,
  },
  {
    text: "#Ref. ID",
    dataField: "ref_id",
    formatter: refFormatter,
  },
  {
    text: "Type",
    formatter: typeFormatter,
    dataField: "product_type",
    hidden: productType !== "total",
  },
  // {
  //   text: title,
  //   dataField: "bio",
  //   isDummyField: true,
  //   formatter: (cellContent, { bio }) => (
  //     <DetailsFormatter title={bio} {...props} />
  //   ),
  // },
  {
    isDummyField: true,
    formatter: (_, row) => userFormatter({ userType, row }),
    dataField: "product_owner",
    text: userType === "tutor" ? "Learner" : "Tutor",
    hidden: productType === "total transactions",
  },
  // {
  //   text: "Amount",
  //   dataField: "price",
  //   formatter: amountFormatter,
  // },
  {
    text: "Amount",
    dataField:
      productType === "all" && userType === "tutor" ? "payouts" : "total_fee",
    formatter:
      productType === "all" && userType === "tutor"
        ? amountFormatterSum
        : amountFormatter,
  },
  {
    text: "Status",
    isDummyField: true,
    dataField: "status",
    formatter: statusFormatter,
  },
  {
    text: "Actions",
    isDummyField: true,
    dataField: "action",
    hidden: userType !== "learner",
    formatter: (_, row) => actionFormatter({ row, onViewDetails }),
  },
];
