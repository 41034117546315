import {Button} from "reactstrap";
import {useUser} from "hooks";
import NotificationActionSetup from "./notification-action-setup";
import NotificationActionConfirm from "./notification-action-confirm";
import NotificationActionReschedule from "./notification-action-reschedule";
import {RLearnerTutoring} from "constants/learner-views-routes";
import {RTutorTutoring} from "constants/tutor-views-routes";

/**
 * Imports.
 *
 * @typedef {import("constants/types/types-notifications").Notification} Notification
 *
 * * @typedef {import("constants/types/types-notifications").NotificationType} NotificationType
 *
 * @typedef {import("constants/types/types-notifications").NotificationEventListeners} NotificationEventListeners
 */

/**
 * @typedef {Object} NotificationActionProps
 *
 * @property {NotificationType['action']} action
 * @property {boolean} isCompact
 */

/**
 * The action part of a notification.
 *
 * @param {NotificationActionProps & Notification & NotificationEventListeners} object The properties passed.
 * @returns {JSX.Element}
 *
 * @version 0.0.3
 * @author kashan-ahmad
 *
 * @changelog
 * - 0.0.3: Added the `onClick`, `onConfirm`, `onCancel`,
 * and `onReschedule` properties.
 * - 0.0.2: The `confirm_reschedule` action is
 * now responsive.
 * - 0.0.1: Initial version.
 */
function NotificationAction({
  link,
  action,
  req_id,
  onConfirm,
  session_id,
  isCompact = false,
  ...props
}) {
  const {type: userType} = useUser();

  const confirmationRoute =
    userType === "tutor"
      ? RTutorTutoring.getSessionDetailsView(session_id, req_id)
      : RLearnerTutoring.getUpcomingSessionDetailsView(session_id, req_id);

  return (
    {
      confirm: (
        <NotificationActionConfirm href={link} {...{isCompact, onConfirm}} />
      ),
      prompt: <Button color="success">Accept</Button>,
      confirm_reschedule: (
        <div
          className={
            isCompact
              ? "gap-column-xs"
              : "vstack gap-2 justify-content-end align-items-end"
          }
        >
          <NotificationActionReschedule to={confirmationRoute} />
          <NotificationActionConfirm
            isCompact={isCompact}
            to={confirmationRoute}
          />
        </div>
      ),
      reschedule: <NotificationActionReschedule to={confirmationRoute} />,
      setup: <NotificationActionSetup {...props} />,
      join: (
        <div className="text-end">
          <Button
            onClick={() => onConfirm?.()}
            color="success"
            className="w-full"
          >
            Join Now
          </Button>
        </div>
      ),
      pay: (
        <Button color="secondary" outline>
          Pay Now
        </Button>
      ),
      retry: (
        <Button color="secondary" outline>
          Try Again
        </Button>
      ),
      reply: (
        <Button color="secondary" outline>
          Reply
        </Button>
      ),
      reschedule_tutor:
        // In this condition, the buttons are shown only for the learner since a tutor has rescheduled the session.
        userType !== "learner" ? null : (
          <div
            className={
              isCompact
                ? "gap-column-xs"
                : "vstack gap-2 justify-content-end align-items-end"
            }
          >
            <NotificationActionReschedule to={confirmationRoute} />
            <NotificationActionConfirm
              isCompact={isCompact}
              to={confirmationRoute}
            />
          </div>
        ),
    }[action] || null
  );
}

export default NotificationAction;
