import {useState} from "react";
import {Button, Form, Modal} from "react-bootstrap";
import {updateSessionRequestStatus} from "store";
import {connect} from "react-redux";
import ToastFactory from "utils/toast-factory";
import {useNavigate} from "react-router-dom";
import {RLearnerTutoring} from "constants/learner-views-routes";
import BaseModal from "../base-modal";

const labels = [
  "Time conflicts",
  "Issues with tutor",
  "Session is no longer required",
  "Found another tutor",
  "Other",
];

function SessionRejectModal({requestId, updateSessionRequestStatus}) {
  const navigate = useNavigate();
  const toastFactory = ToastFactory();
  const [selectedInput, setSelectedInput] = useState("");
  const [selectedReason, setSelectedReason] = useState("");

  const handleSubmit = () => {
    updateSessionRequestStatus({
      requestId: requestId,
      status: 3,
      reason: selectedReason,
      onSuccess: () => {
        toastFactory.success();
        navigate(RLearnerTutoring.baseRoute, {replace: true});
      },
    });
  };

  return (
    <BaseModal
      show
      size="lg"
      onHide={() => navigate(-1)}
      className="text-secondary"
    >
      <Modal.Header closeButton>
        <Modal.Title>Are you sure you want to cancel this session?</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-3 gap-column-xl">
        {/* Commented for temporary purposes */}

        {/* <div
          style={{backgroundColor: "#ECF9F3"}}
          className="p-3 my-3 d-flex align-items-center rounded justify-content-between"
        >
          <span className="p-2 rounded bg-white d-flex align-items-center justify-content-center me-3">
            {" "}
            <HiOutlineBell className="font-size-17" />
          </span>
          <div>
            <h3>
              Your amount will be refunded within 72 hours from the date of
              cancellation{" "}
            </h3>
            <p className="mb-0">
              For any queries you can contact to Tutor support team, they will
              guide you on this topic
            </p>
          </div>
        </div> */}
        <div className="gap-column-md">
          <h2 className="h6">Select your reason</h2>
          <div>
            {labels.map((label, index, arr) => (
              <div key={index}>
                <div className="gap-row-sm py-3">
                  <Form.Check
                    name="radio"
                    type="radio"
                    label={label}
                    id={`radio-${index}`}
                    onChange={(e) => {
                      setSelectedReason(label);
                      setSelectedInput(index);
                    }}
                  />
                </div>
                {index !== arr.length - 1 && <hr className="divider" />}
              </div>
            ))}
            {selectedInput === 4 && (
              <textarea
                className="form-control form-control-noicon"
                onChange={(e) => setSelectedReason(e.target.value)}
              />
            )}
          </div>
        </div>
        <footer className="d-flex justify-content-start gap-2 mt-3">
          <Button
            variant="outline-secondary"
            onClick={handleSubmit}
            style={{minWidth: "5rem"}}
          >
            Yes
          </Button>
          <Button
            variant="secondary"
            style={{minWidth: "5rem"}}
            onClick={() => navigate(-1)}
          >
            No
          </Button>
        </footer>
      </Modal.Body>
    </BaseModal>
  );
}

export default connect((state) => ({}), {
  updateSessionRequestStatus,
})(SessionRejectModal);
