import moment from "moment";

function useOffTime() {
  /**
   * @param {TutorOfftime} offTime
   * @returns {boolean} Whether the offTime
   * is valid or not. The validation is done
   * based on actual time difference.
   * False if the end time appears before the
   * start time.
   */
  const isValid = (offTime) => {
    const startDateTime = `${offTime.startDate} ${offTime.startTime}`;
    const endDateTime = `${offTime.endDate || offTime.startDate} ${
      offTime.endTime
    }`;

    return moment(endDateTime, "YYYY-MM-DD hh:mm:ss").isAfter(
      moment(startDateTime, "YYYY-MM-DD hh:mm:ss")
    );
  };

  /**
   * @param {TutorOfftime} offTime
   * @returns {boolean} Whether the offTime
   * is empty or not. Both of the time fields
   * are checked and a single failure results
   * in an empty result.
   *
   * Note that the `endDate` is skipped as in
   * empty cases, startDate will be used as emptyDate.
   */
  const isEmpty = (offTime) =>
    !offTime.startDate || !offTime.startTime || !offTime.endTime;

  /**
   * @param {TutorOfftime} offTime
   * @param {string} currentFormat The current format of the
   * offTime instance. Used to parse it and apply formatting.
   *
   * @returns {TutorOfftime} The formatted offTime instance.
   */
  const format = (offTime, currentFormat) => {
    const startDateTime = `${offTime.startDate} ${offTime.startTime}`;
    const endDateTime = `${offTime.endDate || offTime.startDate} ${
      offTime.endTime
    }`;

    offTime.start_date_time = moment(startDateTime, currentFormat).format(
      "YYYY-MM-DD HH:mm:ss"
    );

    offTime.end_date_time = moment(endDateTime, currentFormat).format(
      "YYYY-MM-DD HH:mm:ss"
    );

    return offTime;
  };

  return {
    isValid,
    isEmpty,
    format,
  };
}

export default useOffTime;
