import { IconChevronLeft, IconChevronRight, IconStar } from "@tabler/icons";
import { Card, CardBody } from "reactstrap";
import {
  getAbbrPriceFrom,
  getTutorProductBuyUrl,
  getTutorProfileUrl,
  makePluralIf,
} from "utils";
import { Avatar, ReadMoreText } from "components";
import { IconShoppingCart } from "@tabler/icons";
import { LeadingBadgesList } from "components/lists";
import Slider from "react-slick";
import { useRef } from "react";
import { useRemoteProfile } from "hooks/profile";

// import {useStaticDataV3} from "hooks/static-data";

/**
 * @author Abdullah-Sajjad026
 */
const DashboardCardTutor = ({
  id,
  name,
  feePerSession,
  offersFreeTrial,
  freeBuyersList,
  bought,
  profilePicture,
  experience,
  rating,
  students,
  keywords,
  bio,
  bundles,
  curriculumsIds,
  gradesIds,
  subjectsIds,
  ...item
}) => {
  // const {grades, curriculums, subjects,getStaticData} = useStaticDataV3([
  //   "grades",
  //   "curriculums",
  //   "subjects",
  // ]);

  // if (
  //   grades.state === "loading" ||
  //   curriculums.state === "loading" ||
  //   subjects.state === "loading"
  // )
  //   return <Loader isInline />;

  // // Iterable curriculums.
  // const saneCurriculums = item.material.curriculums.map(
  //   (id) => getStaticData(id, curriculums.data)
  // );

  // // Iterable subjects.
  // const saneSubjects = item.material.subjects.map((id) => getStaticData(id, subjects.data) );

  // // Iterable grades.
  // const saneGrades = item.material.grades.map((id) => getStaticData(id, grades.data));

  const { profile } = useRemoteProfile();

  const sliderRef = useRef(null);

  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      onClick && (
        <button
          className={className + " text-secondary"}
          style={{ ...style, display: "block" }}
          onClick={onClick}
        >
          <IconChevronLeft size="14" />
        </button>
      )
    );
  };
  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      onClick && (
        <button
          className={className + " text-secondary"}
          style={{ ...style, display: "block" }}
          onClick={onClick}
        >
          <IconChevronRight size="14" />
        </button>
      )
    );
  };

  const sliderSettings = {
    className: "slider variable-width trending-tutor-products-slider",
    dots: false,
    infinite: false,
    centerMode: false,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <>
      <Card className="m-0 h-100 rounded border-1 bg-white">
        <CardBody className="vstack">
          <div className="d-flex align-items-center">
            <div className="trending-tutor-img-col">
              <Avatar
                src={profilePicture}
                alt="Tutor image"
                className="trending-tutor-img"
              />
            </div>
            <div className="tutor-info-col ms-3">
              <a href={getTutorProfileUrl(name, id)}>
                <h3 className="fw-bold fs-5 trending-tutor-title mb-1">
                  {name}
                </h3>
              </a>
              <div className="d-flex align-items-center flex-wrap ">
                {experience.split("y")[0] > 0 && (
                  <>
                    <p className="text-muted mb-0">Exp {experience}</p>
                    <span
                      style={{
                        width: "7px",
                        height: "7px",
                        backgroundColor: "#D9D9D9",
                      }}
                      className="rounded-circle mx-2"
                    />
                  </>
                )}

                {rating ? (
                  <>
                    <p className="text-muted mb-0">
                      <IconStar size={13} className="rating-star" /> {rating}
                    </p>
                    <span
                      style={{
                        width: "7px",
                        height: "7px",
                        backgroundColor: "#D9D9D9",
                      }}
                      className="rounded-circle mx-2"
                    />
                  </>
                ) : (
                  ""
                )}

                {students > 0 && (
                  <p className="text-muted mb-0">
                    {students} {makePluralIf("learner", students)}
                  </p>
                )}
              </div>

              <LeadingBadgesList
                breakAt={1}
                hasDropdown
                className="mt-2"
                entities={keywords}
              />
            </div>
          </div>

          <ReadMoreText
            text={bio}
            limit={70}
            textClass="medium-text trending-tutor-bio mt-4 mb-2"
            moreClickListener={() =>
              (window.location.href = getTutorProfileUrl(name, id))
            }
          />
          {/* <div className="mt-auto">
            <div className="curriculums mt-2">
              <p className="text-muted mb-1">Curricula</p>

              <LeadingBadgesList
                breakAt={2}
                hasDropdown
                className="mt-2"
                entities={saneCurriculums}
              />
            </div>
            <div className="grades mt-3">
              <p className="text-muted mb-1">Grades</p>

              <LeadingBadgesList
                breakAt={2}
                hasDropdown
                className="mt-2"
                entities={saneGrades}
              />
            </div>
            <div className="subjects mt-3">
              <p className="text-muted mb-1">Subjects</p>

              <LeadingBadgesList
                breakAt={2}
                hasDropdown
                className="mt-2"
                entities={saneSubjects}
              />
            </div>
          </div> */}
          <div className="mt-auto">
            {!!bought && (
              <div className=" mt-4 py-1 border text-center fw-semi-bold trending-tutor-boughts-box">
                <IconShoppingCart size={17} className="me-1" />
                {bought} bought so far
              </div>
            )}

            <div className="mt-3">
              {/* button  */}
              {offersFreeTrial && !freeBuyersList.includes(`${profile.id}`) ? (
                <div
                  style={{ height: "77px" }}
                  className="d-flex align-items-end"
                >
                  <a
                    href={getTutorProfileUrl(name, id)}
                    className="btn btn-success btn-website-success w-100 px-4 py-2"
                    style={{
                      boxShadow: "0 1px 4px rgb(0 0 0 / 40%)",
                      height: "55px",
                    }}
                  >
                    <div className="d-flex justify-content-between align-items-center h-100">
                      <div className="text-secondary">
                        <span className="text-decoration-line-through">
                          {getAbbrPriceFrom(feePerSession)}.00
                        </span>{" "}
                        Free Trial
                      </div>

                      {/* buy text */}
                      <span>Book Now</span>
                    </div>
                  </a>
                </div>
              ) : (
                <Slider {...sliderSettings} ref={sliderRef}>
                  {bundles
                    .filter((b) => b.fee_per_session !== 0)
                    .map((bundle, index) => (
                      <div key={index}>
                        <p
                          className={`text-center text-secondary font-size-12 mb-1 ${
                            !bundle.discount ? "invisible" : ""
                          }`}
                        >
                          was{" "}
                          <span className="text-decoration-line-through">
                            ${bundle.fee_per_session * bundle.no_of_sessions}
                            .00
                          </span>
                        </p>
                        <a
                          className="btn btn-secondary text-center w-100 px-4 py-2"
                          style={{ boxShadow: "0 1px 4px rgb(0 0 0 / 40%)" }}
                          href={getTutorProductBuyUrl({
                            tutorName: name,
                            tutorUserId: id,
                            gradeId: gradesIds,
                            subjectId: subjectsIds,
                            curriculumId: curriculumsIds,
                            bundleId: bundle.id,
                          })}
                        >
                          <div className="text-nowrap">
                            {getAbbrPriceFrom(bundle.total_fee)}.00
                          </div>
                          <div className="font-size-11 text-nowrap">
                            {bundle.no_of_sessions} Session
                          </div>
                        </a>
                      </div>
                    ))}
                </Slider>
              )}
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default DashboardCardTutor;

// /*
// <a
//               className={`btn ${
//                 offersFreeTrial
//                   ? "btn-success btn-website-success"
//                   : "btn-secondary "
//               } mt-4 d-flex justify-content-between align-items-center font-size-14`}
//               href={getTutorProfileUrl(name, id)}
//             >
//               {/* price box */}
//               <div className={`${offersFreeTrial ? "text-secondary" : ""}`}>
//                 <span
//                   className={`${
//                     offersFreeTrial ? "text-decoration-line-through" : ""
//                   }`}
//                 >
//                   {getAbbrPriceFrom(feePerSession)}.00
//                 </span>
//                 {offersFreeTrial ? (
//                   " Free Trial"
//                 ) : (
//                   <span className="font-size-12"> / Session</span>
//                 )}
//               </div>

//               {/* buy text */}
//               <span>Book Now</span>
//             </a>
