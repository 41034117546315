import React from "react";
import {Loader} from "components";

import LearnerProfileGeneralView from "./view-profile-learner-general";
import LearnerProfilePreferencesView from "./view-profile-learner-preferences";

const View = React.lazy(() => import("./view-profile-learner"));

const LearnerProfileView = (props) => (
  <React.Suspense fallback={<Loader />}>
    <View {...props} />
  </React.Suspense>
);

export default LearnerProfileView;

export * from "./view-profile-learner-preferences";
export {LearnerProfileGeneralView, LearnerProfilePreferencesView};
