import { useEffect } from "react";
import { resetAllScrollbars } from "utils";
import { useLocation } from "react-router-dom";

/**
 * Scrolls to the top on each navigation.
 * Place at the very top of your react-router-dom's `Router` component.
 */
export default function ScrollResetter() {
  const { pathname } = useLocation();

  useEffect(() => {
    resetAllScrollbars();
  }, [pathname]);

  return null;
}
