import Form from "./sassy-form";
import SassyFormField from "./sassy-form-field/sassy-form-field";
import SassyFormFieldText from "./sassy-form-field/sassy-form-field-text";
import SassyFormFieldFile from "./sassy-form-field/sassy-form-field-file";
import SassyFormFieldSelect from "./sassy-form-field/sassy-form-field-select";
import SassyFormFieldCheckbox from "./sassy-form-field/sassy-form-field-checkbox";
import SassyFormFieldV2 from "./sassy-form-field/sassy-form-field-v2";

const SassyForm = Object.assign(Form, {
  Field: SassyFormField,
  FieldV2: SassyFormFieldV2,
  File: SassyFormFieldFile,
  Input: SassyFormFieldText,
  Select: SassyFormFieldSelect,
  Checkbox: SassyFormFieldCheckbox,
});

export default SassyForm;
