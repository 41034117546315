import axios from "axios";
import strings, { messages } from "constants/strings";
import { API_MAIN_URL, DEFAULT_TUTOR_REVIEWS } from "constants";
import {
  CERTIFICATE_ERROR,
  DEGREE_ERROR,
  EXPERIENCE_ERROR,
  FETCH_CERTIFICATE,
  FETCH_CURRICULUM,
  FETCH_CURRICULUM_ERROR,
  FETCH_DEGREE,
  FETCH_EXPERIENCE,
  FETCH_QUALIFICATIONS,
  FETCH_SKILLS,
  FETCH_SUBJECTS,
  FETCH_SUBJECTS_ERROR,
  QUALIFICATIONS_ERROR,
  SKILLS_ERROR,
  STORE_CERTIFICATE,
  STORE_EXPERIENCE,
  DELETE_EXPERIENCE,
  STORE_QUALIFICATIONS,
  STORE_SKILLS,
  USERINFO_ERROR,
  STORE_LANGUAGES,
  FETCH_LANGUAGES,
  FETCH_LANGUAGES_ERROR,
  GET_TUTOR_DATA,
  PROFILE_DATA_ERROR,
  STORE_SUBJECTS,
  DELETE_QUALIFICATIONS,
  DELETE_CERTIFICATES,
  DELETE_SUBJECTS,
  UPDATE_QUALIFICATIONS,
  UPDATE_EXPERIENCE,
  DELETE_SKILLS,
  ONBOARDING_STATUS,
  UPDATE_CERTIFICATE,
  GET_CONTENT_BUILDER_DATA,
  CONTENT_BUILDER_ERROR,
  GET_LEARNING_TOPIC,
  GET_LEARNING_TOPIC_ERROR,
  GET_LEARNING_TYPE,
  GET_LEARNING_TYPE_ERROR,
  UPDATE_AVAILABILITY,
  // Settings.
  UPDATE_TUTOR_SETTINGS_SWITCHES,
  // Off-Time.
  SET_TUTOR_OFF_TIME,
  REMOVE_TUTOR_OFF_TIME,
} from "./actionTypes";

import { USER_UPDATE } from "../auth/authActionTypes";
import { topic } from "constants/dummyJson";
import { apiService } from "service";
import { getAuthHeaders, getFormDataFromObject, parseMessage } from "utils";
import ToastFactory from "utils/toast-factory";

const toast = ToastFactory();

//PROFILE DATA
export const getProfileData = () => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": localStorage.getItem("token"),
    },
  };
  try {
    const res = await axios.get(`${API_MAIN_URL}/tutor/get-profile`, config);
    localStorage.setItem("TIMEZONE", res.data?.data?.timezone);

    dispatch({
      type: GET_TUTOR_DATA,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: PROFILE_DATA_ERROR,
      payload: error.message,
    });
    console.error(error);
  }
};

//CLIENT BUILDER
export const getContentBuilderData = () => async (dispatch) => {
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    },
  };
  try {
    const res = await axios.get(
      `${API_MAIN_URL}/contentbuilder/learning-material/fetch?page=1`,
      config
    );
    dispatch({
      type: GET_CONTENT_BUILDER_DATA,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: CONTENT_BUILDER_ERROR,
      payload: error.message,
    });
    // console.error(error.message, "<=====from get profile action====");
    toast.error(error.message);
  }
};

//LEARNING MATERIAL TOPIC
export const getLearningTopic = () => async (dispatch) => {
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    },
  };
  try {
    // const res = await axios.get(`${API_MAIN_URL}/tutor/get-profile`, config)
    dispatch({
      type: GET_LEARNING_TOPIC,
      payload: topic,
    });
  } catch (error) {
    dispatch({
      type: GET_LEARNING_TOPIC_ERROR,
      payload: error.message,
    });
    // console.error(error.message, "<=====from get profile action====");
    toast.error(error.message);
  }
};

//LEARNING MATERIAL TOPIC
export const getLearningType = () => async (dispatch) => {
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    },
  };
  try {
    const res = await axios.get(
      `${API_MAIN_URL}/content/learning-material-types-list`,
      config
    );

    dispatch({
      type: GET_LEARNING_TYPE,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_LEARNING_TYPE_ERROR,
      payload: error.message,
    });
    // console.error(error.message, "<=====from get profile action====");
    toast.error(error.message);
  }
};

export const fetchLanguages = () => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.post(
      `${API_MAIN_URL}/content/languages-list`,
      config
    );
    dispatch({
      type: FETCH_LANGUAGES,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_LANGUAGES_ERROR,
      payload: error.message,
    });
  }
};

export const storeLanguages = (selectedLanguages) => async (dispatch) => {
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    },
  };

  const selectedLang = JSON.stringify(selectedLanguages);

  const body = {
    languages: selectedLang,
  };

  try {
    const res = await axios.post(
      `${API_MAIN_URL}/tutor/update-profile`,
      body,
      config
    );
    toast.success(res.data.message);
    dispatch({
      type: STORE_LANGUAGES,
    });
  } catch (error) {
    dispatch({
      type: FETCH_LANGUAGES_ERROR,
      payload: error.message,
    });
  }
};

export const fetchQualifications = () => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.get(
      `${API_MAIN_URL}/content/qualifications-list`,
      config
    );
    dispatch({
      type: FETCH_QUALIFICATIONS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: QUALIFICATIONS_ERROR,
      payload: error.message,
    });
  }
};

//--Fetch Degree--//

export const fetchDegree = () => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.get(`${API_MAIN_URL}/content/degrees-list`, config);
    dispatch({
      type: FETCH_DEGREE,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: DEGREE_ERROR,
      payload: error.message,
    });
  }
};

//---Fetch Curriculum--//
export const fetchCurriculum = () => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.post(
      `${API_MAIN_URL}/content/curriculums-list`,
      config
    );
    dispatch({
      type: FETCH_CURRICULUM,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_CURRICULUM_ERROR,
      payload: error.message,
    });
  }
};

///--------Store------------
export const storeQualifications =
  (formData, onSuccess, onFailure) => async (dispatch) => {
    // apiService.post({
    //   url:  `${API_MAIN_URL}/tutor/update-qualification`,
    //   data: {qualifications: JSON.stringify(formData)},
    //   headers: getAuthHeaders(),
    //   onSuccess: (res) => {
    //     if (res.status) {
    //
    //           toast.success(`Qualification saved successfully.`);
    //           dispatch({
    //             type: STORE_QUALIFICATIONS,
    //             payload: res,
    //           });
    //         } else {
    //
    //   onFailure: (error) => {
    //     dispatch({ type: QUALIFICATIONS_ERROR, payload: error.message });
    //       toast.dismiss();
    //       toast.error(error.message);
    //
    //       toast.error(error.message);
    //   }
    // })

    const token = localStorage.getItem("token");

    const config = {
      headers: {
        "Content-Type": "application/json",
        "X-Auth-Token": token,
      },
    };

    const body = {
      qualifications: JSON.stringify(formData),
    };

    try {
      const res = await axios.post(
        `${API_MAIN_URL}/tutor/update-qualification`,
        body,
        config
      );

      if (res.data.status) {
        dispatch({
          type: STORE_QUALIFICATIONS,
          payload: res.data.data,
        });
        onSuccess?.();
      } else {
        onFailure?.();
      }
    } catch (error) {
      dispatch({ type: QUALIFICATIONS_ERROR, payload: error.message });
      onFailure?.();
    }
  };
export const deleteQualifications =
  (id, onSuccess, onFailure) => async (dispatch) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        "X-Auth-Token": token,
      },
    };

    try {
      const res = await axios.delete(
        `${API_MAIN_URL}/tutor/delete-qualification/${id}`,
        config
      );
      if (res.data.status) {
        dispatch({
          type: DELETE_QUALIFICATIONS,
          payload: id,
        });
        onSuccess?.();
        //
        // toast.success(`Qualification deleted successfully.`);
      } else {
        onFailure?.();
        //
        // toast.error("We got an error. Please try again or refresh.");
      }
    } catch (error) {
      onFailure?.();
      dispatch({ type: QUALIFICATIONS_ERROR, payload: error.message });
      // toast.dismiss()
      // toast.error(strings.DEFAULT_ERROR_MESSAGE);
    }
  };

export const updatedQualifications = (id, formData) => async (dispatch) => {
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    },
  };

  formData.qualification_id = id;

  const finalbody = [formData];

  const body = {
    qualifications: JSON.stringify(finalbody),
  };

  try {
    const res = await axios.post(
      `${API_MAIN_URL}/tutor/update-qualification`,
      body,
      config
    );
    dispatch({
      type: UPDATE_QUALIFICATIONS,
      payload: res.data,
    });

    toast.success(`Qualification ${res.data.message}`);
  } catch (error) {
    dispatch({ type: QUALIFICATIONS_ERROR, payload: error.message });
    toast.error(error.message);
  }
};

export const fetchExperience = () => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.post(``, config);
    dispatch({
      type: FETCH_EXPERIENCE,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: EXPERIENCE_ERROR,
      payload: error.message,
    });
  }
};

/**
 * @param {RawTutorExperience[]} data
 * @param {Boolbacks['onSuccess'] | undefined} onSuccess
 * @param {Boolbacks['onFailure'] | undefined} onFailure
 */
export const storeExperience = (data, onSuccess, onFailure) => (dispatch) =>
  apiService.post({
    headers: getAuthHeaders(),
    url: `${API_MAIN_URL}/tutor/update-experience`,
    data: getFormDataFromObject({ experiences: JSON.stringify(data) }),
    onSuccess: ({ data }) => {
      onSuccess?.(data);
      dispatch({
        type: STORE_EXPERIENCE,
        payload: data,
      });
    },
    onFailure: ({ message }) => {
      onFailure?.(message);
      dispatch({ type: EXPERIENCE_ERROR, payload: message });
    },
  });

export const updatedExperience = (id, formData) => async (dispatch) => {
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    },
  };

  formData.experience_id = id;

  const finalbody = [formData];

  const body = {
    experiences: JSON.stringify(finalbody),
  };

  try {
    const res = await axios.post(
      `${API_MAIN_URL}/tutor/update-experience`,
      body,
      config
    );
    dispatch({
      type: UPDATE_EXPERIENCE,
      payload: res.data,
    });

    toast.success(`Experience ${res.data.message}`);
  } catch (error) {
    dispatch({ type: EXPERIENCE_ERROR, payload: error.message });
    toast.error(error.message);
  }
};

export const updatedCertificates = (id, formData) => async (dispatch) => {
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    },
  };

  formData.certificate_id = id;

  const finalbody = [formData];

  const body = {
    certificates: JSON.stringify(finalbody),
  };

  try {
    const res = await axios.post(
      `${API_MAIN_URL}/tutor/update-certificates`,
      body,
      config
    );
    dispatch({
      type: UPDATE_CERTIFICATE,
      payload: res.data,
    });

    toast.success(`Certificates ${res.data.message}`);
  } catch (error) {
    dispatch({ type: CERTIFICATE_ERROR, payload: error.message });
    toast.error(error.message);
  }
};

export const deleteExperience = (id, onSuccess) => async (dispatch) => {
  toast.loading();

  const token = localStorage.getItem("token");
  const config = {
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    },
  };

  try {
    const res = await axios.delete(
      `${API_MAIN_URL}/tutor/delete-experience/${id}`,
      config
    );
    if (res.data.status) {
      dispatch({
        type: DELETE_EXPERIENCE,
        payload: id,
      });
      toast.dismiss();
      onSuccess?.(res.data);
    } else {
      toast.error();
    }
  } catch (error) {
    dispatch({ type: EXPERIENCE_ERROR, payload: error.message });

    toast.error(error.message);
  }
};

export const fetchSkills = () => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.get(`${API_MAIN_URL}/content/skills-list`, config);
    dispatch({
      type: FETCH_SKILLS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: SKILLS_ERROR,
      payload: error.message,
    });
  }
};

export const storeSkills = (formData) => async (dispatch) => {
  toast.loading();

  apiService.post({
    url: `${API_MAIN_URL}/tutor/update-specialities`,
    headers: getAuthHeaders(),
    data: { skills: JSON.stringify(formData) },
    onSuccess: (res) => {
      dispatch({
        type: STORE_SKILLS,
        payload: res.data,
      });
      toast.dismiss();
    },
    onFailure: (error) => {
      dispatch({ type: SKILLS_ERROR, payload: error?.message });
      toast.error(error?.message);
    },
  });
};

export const deleteSkills = (id) => async (dispatch) => {
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    },
  };

  try {
    toast.loading();
    const res = await axios.delete(
      `${API_MAIN_URL}/tutor/delete-skill/${id}`,
      config
    );
    dispatch({
      type: DELETE_SKILLS,
      payload: id,
    });

    toast.dismiss();
  } catch (error) {
    dispatch({ type: SKILLS_ERROR, payload: error.message });
    toast.error(error.message);
  }
};
export const storeOnboarding = (onSuccess) => async (dispatch) => {
  const token = localStorage.getItem("token");

  const fileData = new FormData();

  fileData.append("is_onboarded", 1);
  fileData.append("onboarding_status", "completed");

  apiService.post({
    url: `${API_MAIN_URL}/tutor/update-profile`,
    headers: {
      "Content-Type": "multipart/form-data",
      "X-Auth-Token": token,
    },
    data: fileData,
    onSuccess: (res) => {
      dispatch({
        type: ONBOARDING_STATUS,
      });

      onSuccess?.();
    },
    onFailure: (error) => {
      toast.error(parseMessage(strings.DEFAULT_ERROR_MESSAGE));
    },
  });

  // const config = {
  //   headers: {
  //     "Content-Type": "multipart/form-data",
  //     "X-Auth-Token": token,
  //   },
  // };
  // try {
  //   const res = await axios.post(
  //     `${API_MAIN_URL}/tutor/update-profile`,
  //     fileData,
  //     config
  //   );
  //   dispatch({
  //     type: ONBOARDING_STATUS,
  //   });

  //   onSuccess?.();
  // } catch (error) {
  //   // dispatch({ type: USERINFO_ERROR, payload: error.message })
  // }
};
//--Update Users --///

export const storeUser =
  (formData, callback, onFailure) => async (dispatch) => {
    toast.loading();
    const {
      profile_picture,
      cover_image,
      name,
      bio,
      dob,
      title,
      mobile_number,
      country_code,
      languages,
      timezone,
      not_searchable,
    } = formData;

    const selectedLang = JSON.stringify(languages);

    const fileData = new FormData();

    fileData.append("cover_image", cover_image);
    fileData.append("name", name);
    fileData.append("bio", bio);
    fileData.append("dob", dob);
    fileData.append("title", title);
    fileData.append("mobile_number", mobile_number);
    fileData.append("country_code", country_code);
    fileData.append("languages", selectedLang);
    fileData.append("timezone", timezone);
    fileData.append("not_searchable", not_searchable);

    // Append file only if it's a file.
    if (profile_picture instanceof File)
      fileData.append("profile_picture", profile_picture);

    // todo: update to use this
    // apiService.post({
    //   url:  `${API_MAIN_URL}/tutor/update-profile`,
    //   headers: getAuthHeaders(),
    //   data: fileData,
    //   onSuccess: (res) => {
    //     console.log({res})
    //     if (res.data.timezone)
    //   localStorage.setItem("TIMEZONE", res.data.timezone);

    // dispatch({
    //   type: USER_UPDATE,
    //   payload: res.data,
    // });
    // toast.success(res.data.message)
    //   },
    //   onFailure: (error) => {
    //     console.log(error)
    //     // for (const key in res.data.message) {
    //     //   toast.error(`${res.data.message[key]}`);
    //     // }
    //     // return "error";

    //     dispatch({type: USERINFO_ERROR, payload: error.message});
    // toast.error(error.message);
    //   }
    // })

    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        "X-Auth-Token": token,
      },
    };

    try {
      const res = await axios.post(
        `${API_MAIN_URL}/tutor/update-profile`,
        fileData,
        config
      );

      if (res.data.status === false) {
        toast.dismiss();
        onFailure?.(res.data);
        return;
      }

      if (res.data?.data?.timezone)
        localStorage.setItem("TIMEZONE", res.data?.data?.timezone);

      // Update auth state.
      dispatch({
        type: USER_UPDATE,
        payload: res.data,
      });

      // Update profile state.
      dispatch({
        type: "UPDATE_TUTOR_USER",
        payload: res.data.data,
      });
      // todo: verify if its needed

      dispatch(getProfileData());

      res.data.status ? toast.success(`${res.data.message}`) : toast.error();

      callback?.(res.data);
    } catch (error) {
      // dispatch({ type: USERINFO_ERROR, payload: error.message });
      onFailure?.(error);
      console.error(error);
      toast.error();
    }
  };

//--Certificate section--//

export const fetchCertificates = () => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.post(``, config);
    dispatch({
      type: FETCH_CERTIFICATE,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: CERTIFICATE_ERROR,
      payload: error.message,
    });
  }
};

export const storeCertificates = (formData) => async (dispatch) => {
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    },
  };
  const body = {
    certificates: JSON.stringify(formData),
  };
  try {
    const res = await axios.post(
      `${API_MAIN_URL}/tutor/update-certificates`,
      body,
      config
    );

    dispatch({
      type: STORE_CERTIFICATE,
      payload: res.data,
    });
    toast.success(`Certificates ${res.data.message}`);
  } catch (error) {
    dispatch({ type: CERTIFICATE_ERROR, payload: error.message });

    toast.error(error.message);
  }
};

export const storeTutorCertificates =
  ({ certificates, onSuccess, onFailure }) =>
  async (dispatch) => {
    apiService.post({
      url: `${API_MAIN_URL}/tutor/update-certificates`,
      data: { certificates: JSON.stringify(certificates) },
      headers: getAuthHeaders(),
      onSuccess: ({ data }) => {
        dispatch({
          type: STORE_CERTIFICATE,
          payload: data,
        });

        onSuccess?.();
      },
      onFailure: (error) => {
        dispatch({ type: CERTIFICATE_ERROR, payload: error.message });

        onFailure?.(error);
      },
    });
  };

// export const removeTutorCertificate = ({certificateId,onSuccess,onFailure}) => async(dispatch) => {
//   apiService({

//   })
// }

export const deleteCertificates = (id, onSuccess) => async (dispatch) => {
  toast.loading();
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    },
  };

  try {
    const res = await axios.delete(
      `${API_MAIN_URL}/tutor/delete-certificate/${id}`,
      config
    );
    if (res.data.status) {
      dispatch({
        type: DELETE_CERTIFICATES,
        payload: id,
      });
      toast.dismiss();
      onSuccess?.();
    } else {
      toast.error();
    }
  } catch (error) {
    dispatch({ type: CERTIFICATE_ERROR, payload: error.message });

    toast.error(error.message);
  }
};

//--Fetch Subjects--//
export const fetchSubjects = () => async (dispatch) => {
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    },
  };
  try {
    const res = await axios.post(
      `${API_MAIN_URL}/content/subjects-list`,
      config
    );

    dispatch({
      type: FETCH_SUBJECTS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_SUBJECTS_ERROR,
      payload: error.message,
    });
  }
};

//--Store Subjects--//

export const storeSubjects =
  (selectedSubjects, onSuccess, onFailure) => async (dispatch) => {
    toast.loading();

    apiService.post({
      url: `${API_MAIN_URL}/tutor/create-subjects`,
      headers: getAuthHeaders(),
      data: { subjects: JSON.stringify(selectedSubjects) },
      onSuccess: (res) => {
        dispatch({
          type: STORE_SUBJECTS,
          payload: res.data,
        });
        toast.success();
        onSuccess?.();
      },
      onFailure: (error) => {
        dispatch({ type: FETCH_SUBJECTS_ERROR, payload: error.message });
        toast.error(error.message);
      },
    });

    // const token = localStorage.getItem("token");
    // const config = {
    //   headers: {
    //     "Content-Type": "application/json",
    //     "X-Auth-Token": token,
    //   },
    // };

    // const selectedSubjects = JSON.stringify(formData);

    // const body = {
    //   subjects: selectedSubjects,
    // };
    // try {
    //   const res = await axios.post(
    //     `${API_MAIN_URL}/tutor/create-subjects`,
    //     body,
    //     config
    //   );
    //   dispatch({
    //     type: STORE_SUBJECTS,
    //     payload: res.data.data,
    //   });
    //   toast.success();
    //   onSuccess?.()
    // } catch (error) {
    //   dispatch({type: FETCH_SUBJECTS_ERROR, payload: error.message});
    //   toast.error(error.message);
    // }
  };

//--Upload Files--//

export const uploadFiles = async (contentId, files, navi) => {
  // console.log({
  //   uploads: files,
  //   content_id: contentId,
  // });

  const token = localStorage.getItem("token");
  const config = {
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    },
  };

  const formData = new FormData();
  formData.append("uploads", JSON.stringify(files));
  formData.append("content_id", contentId);

  try {
    const res = await axios.post(
      `${API_MAIN_URL}/contentbuilder/learning-material/upload-files`,
      formData,
      config
    );

    if (res.data.status) {
      toast.success(res.data.message);
      navi();
    } else {
      toast.error();
    }
  } catch (error) {
    console.error("upload-file-status-error", error);

    // dispatch({type: UPLOAD_LEARNING_MATERIAL_FILE_ERROR, payload: error.message});
    toast.error(error.message);
  }
};

export const deleteSubjects = (id) => async (dispatch) => {
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token,
    },
  };

  try {
    const res = await axios.delete(
      `${API_MAIN_URL}/tutor/delete-subject/${id}`,
      config
    );
    dispatch({
      type: DELETE_SUBJECTS,
    });

    toast.success(`Subjects ${res.data.message}`);
  } catch (error) {
    dispatch({ type: FETCH_SUBJECTS_ERROR, payload: error.message });
    toast.error(error.message);
  }
};

/**
 * Post the tutor's availability to the API.
 * Update the store with the new availability.
 * 
 *@param {Object} object The forwarded props.

 * @param {any} object.data 
 * The object containing the availability as well
 * as the lesson booking settings.
 * 
 * @param {() => void} object.onSuccess 
 * Callback in case of success.
 * 
 * @param {() => void} object.onFailure
 * Callback in case of failure.
 *
 * @version 0.0.3
 * @author kashan-ahmad
 *
 * @changelog
 * - 0.0.3: Replaced `availability` prop with `data`.
 * - 0.0.2: Added auto-formData encoding.
 * - 0.0.1: Initial version.
 */
export const postTutorAvailability =
  ({ data, onSuccess, onFailure }) =>
  async (dispatch) =>
    apiService.post({
      url: `${API_MAIN_URL}/tutor/update-availability`,
      data: getFormDataFromObject(data),
      headers: getAuthHeaders(),
      onSuccess: ({ data }) => {
        dispatch({
          payload: data,
          type: UPDATE_AVAILABILITY,
        });

        // Inform the callback.
        onSuccess?.(data);
      },
      onFailure,
    });

// **** Settings. **** //

/**
 * Posts the tutor's switches to the API.
 * Updates the store with the new switches.
 *
 * @param {Object} object The forwarded props.
 *
 * @param {TutorSettingsSwitches} object.switches
 *  The switches to be posted.
 * @param {() => void} object.onSuccess
 *  The callback for a successful request.
 * @param {() => void} object.onFailure
 * The callback for a failed request.
 *
 * @version 0.0.1
 * @author kashan-ahmad
 *
 * @changelog
 * - 0.0.1: Initial version.
 */
export const updateTutorSettingsSwitches =
  ({ switches, onSuccess, onFailure }) =>
  (dispatch) =>
    apiService.post({
      headers: getAuthHeaders(),
      data: getFormDataFromObject(switches),
      url: `${API_MAIN_URL}/tutor/update-connect`,
      onFailure,
      onSuccess: ({ data }) => {
        dispatch({
          type: UPDATE_TUTOR_SETTINGS_SWITCHES,
          payload: {
            free_connect: data.free_connect,
            instant_connect: data.instant_connect,
          },
        });

        // Inform the callback.
        onSuccess?.(data);
      },
    });

/**
 * Posts a tutor's calendar settings.
 *
 * @param {Object} object The forwarded props.
 *
 * @param {TutorSettingsCalendar} object.calendarSettings
 * The calendar settings of the tutor.
 * @param {(data) => void} object.onSuccess
 * Callback in case of success.
 * @param {(error) => void} object.onFailure
 * Callback in case of failure.
 */
export const postTutorSettingsCalendar = ({
  calendarSettings,
  onSuccess,
  onFailure,
}) =>
  apiService.post({
    onSuccess,
    onFailure,
    headers: getAuthHeaders(),
    data: getFormDataFromObject(calendarSettings),
    url: `${API_MAIN_URL}/tutor/calendar-settings`,
  });

// **** Off-Time. **** //

/**
 * Updates or inserts a new offTime record to
 * the tutor's history. The updated history
 * is retrived from the API and set as it is.
 *
 * @param {Object} object The forwarded props.
 *
 * @param {TutorOfftime} object.offTime
 * The record to be added.
 * @param {(data) => void} object.onSuccess
 * Callback in case of success.
 * @param {(error) => void} object.onFailure
 * Callback in case of failure.
 */
export const upsertTutorOffTime =
  ({ offTime, onSuccess, onFailure }) =>
  (dispatch) =>
    apiService.post({
      onFailure,
      headers: getAuthHeaders(),
      data: getFormDataFromObject(offTime),
      url: `${API_MAIN_URL}/tutor/manage-time-off`,
      onSuccess: ({ data }) => {
        dispatch({
          type: SET_TUTOR_OFF_TIME,
          payload: data.map(
            ({ id, reason, start_date_time, end_date_time }) => ({
              id,
              reason,
              endDate: end_date_time.split(" ")[0],
              endTime: end_date_time.split(" ")[1],
              startDate: start_date_time.split(" ")[0],
              startTime: start_date_time.split(" ")[1],
            })
          ),
        });

        onSuccess?.(data);
      },
    });

/**
 * Removes an offTime record from the tutor's history.
 *
 * @param {Object} object The forwarded props.
 *
 * @param {TutorOfftime} object.offTime
 * The record to be removed.
 * @param {number} object.index
 * The index of the record in the history.
 * @param {(data) => void} object.onSuccess
 * Callback in case of success.
 * @param {(error) => void} object.onFailure
 * Callback in case of failure.
 */
export const removeTutorOffTime =
  ({ offTime, index, onSuccess, onFailure }) =>
  (dispatch) =>
    apiService.delete({
      onFailure,
      recordId: offTime.id,
      headers: getAuthHeaders(),
      url: `${API_MAIN_URL}/tutor/delete-time-off`,
      onSuccess: ({ data, status }) => {
        if (status) {
          dispatch({
            type: REMOVE_TUTOR_OFF_TIME,
            payload: index,
          });

          onSuccess?.(data);
          return;
        }

        onFailure({ message: strings.DEFAULT_ERROR_MESSAGE });
      },
    });
export const fetchTutorReviews =
  ({ limit = DEFAULT_TUTOR_REVIEWS, page = 1, onSuccess, onFailure }) =>
  async (dispatch) => {
    apiService.get({
      url: `${API_MAIN_URL}/tutor/tutor-reviews-list`,
      data: [
        { key: "page", value: page },
        { key: "limit", value: limit },
      ],
      headers: getAuthHeaders(),
      onSuccess: (res) => {
        dispatch({ type: "SET_TUTOR_REVIEWS", payload: res });
      },
      onFailure: ({ error }) => {
        dispatch({ type: "ERRED_TUTOR_REVIEWS" });
      },
    });
  };

//
export const getTutorStatistics =
  ({ onSuccess, onFailure }) =>
  async (dispatch) => {
    apiService.get({
      url: `${API_MAIN_URL}/tutor/get-statistics`,
      headers: getAuthHeaders(),
      onSuccess: ({ data }) => onSuccess?.(data),
      onFailure: ({ error }) => onFailure?.(),
    });
  };
