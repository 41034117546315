import React, { useState, useEffect, useCallback } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { integers } from "constants/config";

// import {
//   CarouselProvider,
//   Slider,
//   Slide,
//   ButtonBack,
//   ButtonNext,
// // } from "pure-react-carousel"
// import "pure-react-carousel/dist/react-carousel.es.css"

// import Course1 from "../../../assets/images/material/course-1.png"
// import Course2 from "../../../assets/images/material/course-2.png"
// import Course3 from "../../../assets/images/material/course-3.png"

const DashboardBlogCarousel = ({ blogsData }) => {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 2500,
    autoplaySpeed: integers.SLIDESHOW,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: (dots) => {
      return (
        <div>
          <ul className="dash-blog-carousel-dots">
            {dots.map((item, index) => {
              return <li key={index}>{item.props.children}</li>;
            })}
          </ul>
        </div>
      );
    },
  };

  return (
    <div className="dash-blog-carousel">
      <Slider {...settings}>
        {blogsData &&
          blogsData.map((blog, index) => {
            return (
              <a
                href={`${process.env.REACT_APP_WEBSITE_URL}/blog-detail/${blog.id}/${blog.attributes.Slug}`}
                target="blank"
              >
                <div className="dash-blog-carousel-container" key={index + 1}>
                  <div
                    className="dash-blog-carousel-img"
                    style={{
                      background: `linear-gradient(
                  to right bottom,rgb(0 0 0 / 34%), rgb(3 3 3 / 36%)),url(${blog.attributes.Image.Image.data.attributes.url})`,
                      backgroundRepeat: `no-repeat`,
                      backgroundSize: `cover`,
                    }}
                  ></div>
                  <h1 className="dash-blog-carousel-text">
                    {blog.attributes.Title}
                  </h1>
                </div>
              </a>
            );
          })}
      </Slider>
    </div>
  );
};

export default DashboardBlogCarousel;
