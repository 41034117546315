import React from "react";
import { messages } from "constants/strings";
import SessionActionModal from "modules/modals/session/session-action-modal";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  rescheduleSession,
  sendSessionRequestToLearner,
  updateSessionRequestStatus,
} from "store";
import { getModalRouteState } from "utils";
import ToastFactory from "utils/toast-factory";

export default function useSessionActions() {
  const toastFactory = ToastFactory();
  const location = useLocation();
  const navigate = useNavigate();

  const dispatch: (action: any) => void = useDispatch();

  const handleSessionConfirm = ({
    requestId,
    onSuccess,
    onFailure,
  }: {
    [key: string]: any;
  }) => {
    toastFactory.loading();
    dispatch(
      updateSessionRequestStatus({
        requestId,
        status: 2,
        onSuccess: ({ data }: { data: any }) => {
          toastFactory.dismiss();
          onSuccess?.({ data });
        },
        onFailure: (error: { message: string }) => {
          toastFactory.error(error.message);
          onFailure?.(error);
        },
      })
    );
  };

  const handleSessionReschedule = ({
    startTime,
    endTime,
    date,
    requestId,
    weekDay,
    rescheduledBy,
    onSuccess,
    onFailure,
    subjectName,
    receiverName,
    receiverImage,
  }: {
    [key: string]: any;
  }) => {
    toastFactory.loading();
    dispatch(
      rescheduleSession({
        requestId,
        startTime,
        endTime,
        date,
        day: weekDay,
        rescheduledBy,

        onSuccess: ({ data }: { data: any }) => {
          toastFactory.dismiss();

          onSuccess?.({ data });

          navigate("/modal/session/rescheduled", {
            state: getModalRouteState(location, {
              startTime: data.start_time,
              endTime: data.end_time,
              date: data.date,
              subjectName,
              userName: receiverName,
              userPicture: receiverImage,
            }),
          });
        },
        onFailure: (error: { message: string }) => {
          toastFactory.error(error.message);
          onFailure?.(error);
        },
      })
    );
  };

  const handleSessionCancel = ({
    requestId,
    userName,
    userPicture,
    subjectName,
    date,
    startTime,
    endTime,
    onSuccess,
    onFailure,
    onRebook,
  }: {
    onRebook: () => void;
    [key: string]: any;
  }) => {
    const onSessionCancel = (cancelComponentStateSetter: any) => {
      cancelComponentStateSetter("loading");
      dispatch(
        updateSessionRequestStatus({
          requestId,
          status: 5,
          onSuccess: ({ data }: { data: any }) => {
            cancelComponentStateSetter("cancelled");
            onSuccess?.({ data });
          },
          onFailure: (error: { message: string }) => {
            toastFactory.error(error.message);
            cancelComponentStateSetter("initial");
            onFailure?.(error);
          },
        })
      );
    };

    toastFactory.render(
      <SessionActionModal.Cancel
        {...{
          requestId,
          onSessionCancel,
          userName,
          date,
          startTime,
          endTime,
          subjectName,
          userPicture,
          onSuccess,
          onFailure,
          onRebook: () => {
            onRebook?.();
            toastFactory.dismiss();
          },
          onHide: () => {
            toastFactory.dismiss();
            window.location.reload();
          },
        }}
      />,
      {
        size: "md",
      }
    );
  };

  const handleBookSessionByLearner = ({
    startTime,
    endTime,
    date,
    agenda,
    description,
    weekDay,
    receiverId,
    bundleId,
    subjectName,
    receiverName,
    receiverPicture,
    onSuccess,
    onFailure,
  }: {
    [key: string]: any;
  }) => {
    toastFactory.loading();
    dispatch(
      sendSessionRequestToLearner({
        sessionRequestData: {
          sent_to: receiverId,
          agenda,
          description,
          start_time: startTime,
          end_time: endTime,
          bundle_id: bundleId,
          date,
          day: weekDay === 0 ? 7 : weekDay,
        },
        onSuccess: (res: any) => {
          if (res.message.indexOf("already") !== -1 && res.error_code === 409) {
            toastFactory.error(messages.SLOT_ALR_SELECTED);
            return;
          }
          toastFactory.dismiss();
          // we don't need to show this toast as we are showing it in the modal
          // toastFactory.success(messages.SESSION_REQUEST_SENT);
          onSuccess?.(res.data);
          navigate("/modal/session/scheduled", {
            state: getModalRouteState(location, {
              startTime,
              endTime,
              date,
              subjectName,
              userName: receiverName,
              userPicture: receiverPicture,
            }),
          });
        },
        onFailure: (error: { message: string }) => {
          toastFactory.error(error?.message);
          onFailure?.(error);
        },
      })
    );
  };

  const handleBookSessionByTutor = ({
    startTime,
    endTime,
    date,
    agenda,
    description,
    weekDay,
    receiverId,
    bundleId,
    subjectName,
    receiverName,
    receiverPicture,
    onSuccess,
    onFailure,
  }: {
    [key: string]: any;
  }) => {
    toastFactory.loading();
    dispatch(
      sendSessionRequestToLearner({
        sessionRequestData: {
          sent_to: receiverId,
          agenda,
          description,
          start_time: startTime,
          end_time: endTime,
          bundle_id: bundleId,
          date,
          day: weekDay === 0 ? 7 : weekDay,
        },
        onSuccess: (res: any) => {
          if (res.message.indexOf("already") !== -1 && res.error_code === 409) {
            toastFactory.error(messages.SLOT_ALR_SELECTED);
            return;
          }
          toastFactory.dismiss();
          // toastFactory.success(messages.SESSION_REQUEST_SENT);
          onSuccess?.(res.data);
          navigate("/modal/session/scheduled", {
            state: getModalRouteState(location, {
              startTime,
              endTime,
              date,
              subjectName,
              userName: receiverName,
              userPicture: receiverPicture,
            }),
          });
        },
        onFailure: (error: { message: string }) => {
          toastFactory.error(error?.message);
          onFailure?.(error);
        },
      })
    );
  };

  return {
    handleSessionConfirm,
    handleSessionReschedule,
    handleSessionCancel,
    handleBookSessionByTutor,
    handleBookSessionByLearner,
  };
}
