import { WrapperLayout } from "layout";
import { SassyAlert } from "components";
import { Link } from "react-router-dom";
import { useUser } from "hooks";

/**
 * A placeholder to show in place of a view if the user is not on board yet.
 *
 * @returns {JSX.Element}
 *
 * @author kashan-ahmad
 * @version 0.0.1
 */
function PlaceholderView() {
  const { type: userType } = useUser();

  return (
    <WrapperLayout>
      <article className="p-3 p-md-4">
        <SassyAlert
          title="Unable to access"
          description="You need to get yourself onboard before accessing the dashboard"
        />
        <div className="d-flex flex-column flex-sm-row gap-2 mt-3">
          <Link
            className="btn btn-secondary w-100 w-sm-auto"
            to={
              userType === "tutor" ? "/onboarding/tutor" : "/onboarding/learner"
            }
          >
            🙌 Onboard me
          </Link>
          <Link
            to="/support"
            className="btn btn-outline-danger w-100 w-sm-auto"
          >
            🤐 I'm aboard already
          </Link>
        </div>
      </article>
    </WrapperLayout>
  );
}

export default PlaceholderView;
