import {IconCalendar} from "@tabler/icons";
import {IconSchool} from "@tabler/icons";
import {IconStar} from "@tabler/icons";
import moment from "moment";
import {Icon} from "components";
import {IconVideo} from "@tabler/icons";

const TutoringTutorHistoryActivity = (props) => {
  return (
    <div className="single-session-activity d-flex flex-wrap gap-3 align-items-center my-4 mx-0">
      <div
        className="p-3 rounded-circle "
        style={{width: "max-content", backgroundColor: "#E6EBF0"}}
      >
        <Icon size="xs">
          <IconVideo />
        </Icon>
      </div>
      <div className="d-flex flex-column ms-1">
        <h3
          className="font-size-16 mb-1 text-start text-truncate-n1"
          style={{width: "300px"}}
        >
          {props.agenda}
        </h3>
        <div className="d-flex flex-column flex-sm-row align-items-sm-center gap-2 flex-wrap">
          <div className="hstack">
            <Icon size="xs">
              <IconSchool />
            </Icon>
            <p className="subject m-0 ms-1">{props.subjectName}</p>
          </div>

          <div className="hstack">
            <span className="green-dot ms-sm-2 me-2 d-none d-sm-inline" />
            <Icon size="xs">
              <IconStar className="rating-star" />
            </Icon>

            <span className="ms-1">
              {props.rating === 0 ? "Not rated yet" : props.rating}
            </span>
          </div>
        </div>
      </div>
      <div className="d-flex ms-sm-auto align-items-center text-muted">
        <IconCalendar />
        <span className="ms-1">
          {moment(props.date, "YYYY-MM-DD").format("DD MMM YYYY")}
        </span>
        <span className="ms-2">
          {" "}
          {moment(props.start_time, "HH:mm:ss").format("hh:mm A")} To{" "}
          {moment(props.end_time, "HH:mm:ss").format("hh:mm A")}
        </span>
      </div>
    </div>
  );
};

export default TutoringTutorHistoryActivity;
