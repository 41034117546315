import React from "react";
import { NotifierProps } from "..";

export type NotifierMessageProps = Pick<NotifierProps, "message">;

export default function NotifierMessage({ message }: NotifierMessageProps) {
  return message ? (
    <div className="fs-5 text-secondary text-center">{message}</div>
  ) : (
    <></>
  );
}
