import { ListGroupItem } from "reactstrap";

/**
 * A common wrapper to normalize all the lists.
 *
 * @param {JSX.IntrinsicElements['ul']} props
 *
 * @returns {JSX.Element}
 *
 * @author kashan-ahmad
 * @version 0.0.1
 */
function ListItemWrapper({ children, className = "", ...rest }) {
  return (
    <ListGroupItem
      {...rest}
      className={`p-3 p-md-4 border-gray-400 ${className}`}
    >
      {children}
    </ListGroupItem>
  );
}

export default ListItemWrapper;
