import React from "react";
import { SassyForm } from "components";
import { Col, Row } from "reactstrap";
import { getDiscounted } from "utils";
import { FormikProps } from "formik";
import { FormCreateMaterialState } from "../form-create-material-adapter";

/**
 * The price section of the Tutor's Create/Update learning material form.

 * @author Abdullah-Sajjad026
 * @version 0.0.1
 */

function PriceSection({
  values,
  handleChange,
  setFieldValue,
}: Pick<
  FormikProps<FormCreateMaterialState>,
  "values" | "handleChange" | "setFieldValue"
>): JSX.Element {
  return (
    <section className="mt-4">
      <Row className="g-2">
        <Col xs="12" md="4">
          <SassyForm.Input
            name="price"
            label="Price ($)"
            type="number"
            placeholder="Add a price"
            onChange={(e) => {
              setFieldValue(
                "totalPrice",
                values.discount
                  ? Number(
                      getDiscounted(Number(e.target.value), values.discount)
                    )
                  : Number(e.target.value)
              );
              handleChange(e);
            }}
          />
        </Col>

        <Col xs="12" md="4">
          <SassyForm.Input
            name="discount"
            label="Discount (%)"
            type="number"
            placeholder="Offer a discount"
            onChange={(e) => {
              setFieldValue(
                "totalPrice",
                e.target.value
                  ? getDiscounted(values.price, Number(e.target.value))
                  : values.price
              );
              handleChange(e);
            }}
          />
        </Col>

        <Col xs="12" md="4">
          <SassyForm.Input
            name="totalPrice"
            label="Total Price ($)"
            type="number"
            placeholder="Total Price"
            disabled
            readOnly
          />
        </Col>
      </Row>
    </section>
  );
}

export default PriceSection;
