import MyPaginationItem from "./pagination-item";

/**
 * Overflow handler for pagination items. Displays an ellipsis if the conditions are met.
 *
 * @param {Object} props
 *
 * @param {number[]} props.pages
 * The array of pages to truncate.
 *
 * @param {number} props.currentPage
 * The page currently selected.
 *
 * @param {number} props.maxOverflow
 * The maximum items that can be displayed around the selected/current page.
 *
 * @param {"prefix" | "suffix"} props.overflowType
 * The type of overflow to render ellipsis for. This decides whether the items
 * before the current page are truncated or the ones after it.
 *
 * @returns {JSX.Element}
 *
 * @author kashan-ahmad
 * @version 0.0.1
 */
function PaginationOverflow({ pages, currentPage, maxOverflow, overflowType }) {
  // The index where the prefix starts from, it ends at the current page ofc.
  // Note the `OR pages.length` as when the last page is selected, the currentPage, which starts from 1, exceeds the pages' indices, hence the length of the array is where the currentPage stands.
  const prefixStart = (pages[currentPage] || pages.length) - maxOverflow;

  // The suffix starts from the point where the allowed items post-currentPage end.
  const suffixStart = pages[currentPage] + maxOverflow;

  // Whether the calculcated index is a valid index. If Yes, we're overflowing.
  // Note the condition at the end, since we're displaying the first item in all cases, we need to skip it.
  const hasExceededPrefix = !!pages[prefixStart] && prefixStart !== 1;

  // Pretty similar.
  const hasExceededSuffix = !!pages[suffixStart];

  if (overflowType === "prefix" && hasExceededPrefix)
    return <MyPaginationItem isDisabled>...</MyPaginationItem>;

  if (overflowType === "suffix" && hasExceededSuffix)
    return <MyPaginationItem isDisabled>...</MyPaginationItem>;

  return null;
}

export default PaginationOverflow;
