import { ErrorMessage } from "formik";
import { FormGroup } from "reactstrap";
import FormErrorMessage from "components/error-message-form";
import SassyFormLabel from "../sassy-form-label";

/**
 * @typedef {Object} SassyFormFieldProps
 *
 * @property {string} name
 * The name of the field.
 *
 * @property {string} label
 * The label of the field
 *
 * @property {React.ReactElement} children
 * The actual form controller, can be whatever you want.
 */

/**
 * A formik-based form field wrapper but sassy, not for general usage.
 * Intended to be used by the sassy siblings. Wraps the field with a label and an error message.
 *
 * @param {SassyFormFieldProps} props
 *
 * @returns {JSX.Element}
 */
function SassyFormField({ label, name, children, className = "" }) {
  return (
    <FormGroup className={className}>
      {label && <SassyFormLabel htmlFor={name}>{label}</SassyFormLabel>}
      {children}
      <ErrorMessage
        name={name}
        render={(message) => <FormErrorMessage {...{ message }} />}
      />
    </FormGroup>
  );
}

export default SassyFormField;
