import {Loader} from "components";
import * as React from "react";

const View = React.lazy(() => import("./dashboard-tutor"));

const TutorDashboard = (props) => (
  <React.Suspense fallback={<Loader />}>
    <View {...props} />
  </React.Suspense>
);

export default TutorDashboard;
