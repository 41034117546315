import { number, object } from "yup";

export default function useSkillsFormSchema() {
  return object({
    skill: object().shape({
      value: number().required("Skill is required"),
    }),
    rating: number()
      .min(1, "Rating is required")
      .max(5, "Rating is required")
      .required("Rating is required"),
  });
}
