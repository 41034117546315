import { WrapperLayout } from "layout";
import { SassyAlert } from "components";
import { Link } from "react-router-dom";

/**
 * A placeholder to show in place of the onboarding if the user is already aboard,
 * @returns {JSX.Element}
 *
 * @author kashan-ahmad
 * @version 0.0.1
 */
function OnboardingPlaceholderView() {
  return (
    <WrapperLayout className="p-3 p-md-4">
      <SassyAlert
        title="Unable to access"
        description="You're already aboard the dashboard"
      />
      <div className="d-flex flex-column flex-sm-row gap-2 mt-3">
        <Link to="/" className="btn btn-secondary w-100 w-sm-auto">
          🙌 Let's get started!
        </Link>
        <Link to="/support" className="btn btn-outline-danger w-100 w-sm-auto">
          🤐 I'm not aboard yet.
        </Link>
      </div>
    </WrapperLayout>
  );
}

export default OnboardingPlaceholderView;
