import React from "react";
import { DateFilters, Loader, Pagination, SassyAlert } from "components";
import strings from "constants/strings";
import { PaymentsTableModule, TransactionDetailsModal } from "modules";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import { fetchTotalTransactions } from "store";
import { SassyCard } from "components/cards";

/**
 * @author Abdullah-Sajjad026
 */
const TransactionsTotalHistory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { page = 1 } = useParams();

  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [fetchAgain, setFetchAgain] = React.useState(0);
  const [transactionDetailsModal, setTransactionDetailsModal] = React.useState({
    isOpen: false,
    data: "",
  });

  const { state: historyState, data } = useSelector(
    (state) => state.transactions.totalTransactions
  );

  const { data: totalTransactions, total, per_page, current_page } = data;

  useEffect(() => {
    dispatch(
      fetchTotalTransactions({
        start_date: startDate,
        end_date: endDate,
        page,
      })
    );
  }, [fetchAgain, page]);

  return (
    <>
      <SassyCard>
        <CardHeader className="p-3 bg-white d-flex align-items-center justify-content-between flex-wrap">
          <CardTitle className="mb-0 ">All Transactions</CardTitle>
          <DateFilters
            onApplyFilters={(startDate, endDate) => {
              setStartDate(startDate);
              setEndDate(endDate);
              setFetchAgain(Math.random());
            }}
            onRemoveFilters={() => {
              setStartDate("");
              setEndDate("");
              setFetchAgain(Math.random());
            }}
          />
        </CardHeader>
        <CardBody className="p-0">
          {historyState === "erred" ? (
            <SassyAlert
              color="danger"
              className="mx-3 mb-3"
              title={strings.DEFAULT_ERROR_MESSAGE}
              description="Unable to load the history right now"
            />
          ) : historyState === "loading" ? (
            <Loader isInline className="p-5" />
          ) : totalTransactions && totalTransactions.length === 0 ? (
            // In case there is no history, only the first page is a valid page, the rest don't exist.
            current_page !== 1 ? (
              <Navigate to="/404" replace />
            ) : (
              <SassyAlert
                color="primary"
                className="mx-3 mb-3"
                description="No results found"
              />
            )
          ) : (
            <>
              <div>
                <PaymentsTableModule
                  title="Total Transactions"
                  paymentHistory={totalTransactions}
                  productType="total transactions"
                  onViewDetails={(row) => {
                    setTransactionDetailsModal({
                      isOpen: true,
                      data: row,
                    });
                  }}
                />
              </div>
              <Pagination
                type="pages"
                className="p-3"
                itemsPerPage={per_page}
                totalItems={parseInt(total)}
                currentPage={parseInt(page)}
                onChangePage={(newPage) => {
                  dispatch({ type: "LOADING_TOTAL_TRANSACTIONS" });
                  navigate(`/transactions/all/page/${newPage}`);
                }}
              />
            </>
          )}
        </CardBody>
      </SassyCard>
      <TransactionDetailsModal
        isOpen={transactionDetailsModal.isOpen}
        transaction={transactionDetailsModal.data}
        toggle={() =>
          setTransactionDetailsModal({
            isOpen: false,
            data: [],
          })
        }
      />
    </>
  );
};

export default TransactionsTotalHistory;
