import { Form } from "formik";
import React from "react";

/**
 * A formik-based form but sassy.
 *
 * @param {import('formik').FormikFormProps & JSX.IntrinsicElements['form']} props
 *
 * @returns {JSX.Element}
 */
function SassyForm({ children, ...rest }) {
  return <Form {...rest}>{children}</Form>;
}

export default SassyForm;
