import { apiService } from "service";
import { getAuthHeaders, getFormDataFromObject } from "utils";
import { API_MAIN_URL } from "constants";
import { DEFAULT_LEARNING_MATERIALS_PER_PAGE } from "constants";

/**
 * Fetches learning material by ID for a tutor.
 * @param {Object} TutorMaterialMiddlewareProps
 * @param {number} object.page Default = 1
 * @param {"published" | "draft"} object.status The status of the learning material to be fetched.
 * @param {number} object.limit Default = `DEFAULT_LEARNING_MATERIALS_PER_PAGE`
 * @param {Boolbacks['onSuccess']} onSuccess
 * @param {Boolbacks['onFailure']} onFailure
 * @author kashan-ahmad
 * @version 0.0.1
 */
export const fetchTutorLearningMaterial =
  ({
    status,
    page = 1,
    onSuccess,
    onFailure,
    start_date,
    end_date,
    limit = DEFAULT_LEARNING_MATERIALS_PER_PAGE,
  }) =>
  /**
   * @param {import("redux").Dispatch<ContentBuilderAction>} dispatch
   */
  (dispatch) =>
    apiService.get({
      headers: getAuthHeaders(),
      data: [
        { key: "page", value: page },
        { key: "limit", value: limit },
        ...(status ? [{ key: status, value: 1 }] : []),
        ...(start_date && end_date
          ? [
              { key: "end_date", value: end_date },
              { key: "start_date", value: start_date },
            ]
          : []),
      ],
      url: `${API_MAIN_URL}/contentbuilder/tutor/learning-material/fetch`,
      onSuccess: (data) => {
        dispatch({
          type: "SET_LEARNING_MATERIAL",
          payload: data,
        });

        onSuccess?.(data);
      },
      onFailure: (error) => {
        dispatch({
          type: "ERRED_LEARNING_MATERIAL",
        });

        onFailure?.(error);
      },
    });

//CREATE LEARNING MATERIAL
export const createLearningMaterial =
  (data, currentAction = "add", onSuccess, onFailure) =>
  (dispatch) =>
    apiService.post({
      onFailure,
      headers: getAuthHeaders(),
      data: getFormDataFromObject(data),
      url: `${API_MAIN_URL}/contentbuilder/learning-material/${currentAction}`,
      onSuccess: ({ data }) => {
        onSuccess?.(data);
      },
    });

/**
 * Delete a learning-material/content. It also makes learning material to loading state after deleting. You need to implement refetching logic.
 * @param id The id of the learning-material/content to delete.
 * @param onSuccess Callback to be called on success.
 * @param onFailure Callback to be called on failure.
 * @todo The consumer needs to implement refetching logic.
 */
export const deleteLearningMaterial =
  ({ id, onSuccess, onFailure }) =>
  (dispatch) => {
    apiService.delete({
      url: `${API_MAIN_URL}/contentbuilder/learning-material/delete`,
      recordId: id,
      headers: getAuthHeaders(),
      // we need to refetch the list of learning materials after deleting one
      onSuccess: (res) => {
        dispatch({
          type: "LOADING_LEARNING_MATERIAL",
        });

        onSuccess?.(res);
      },
      onFailure,
    });
  };
/**
 * @param data id, visibility 0 = notVisibile || 1 = visible to make searchable or not seachable.
 * @param onSuccess Callback to be called on success.
 * @param onFailure Callback to be called on failure.
 */
export const toggleSearchableListener =
  ({ data, onSuccess, onFailure }) =>
  (dispatch) => {
    apiService.post({
      url: `${API_MAIN_URL}/contentbuilder/update-lm-visibility`,
      data,
      headers: getAuthHeaders(),
      // we need to refetch the list of learning materials after deleting one
      onSuccess: (res) => {
        dispatch({
          type: "LOADING_LEARNING_MATERIAL",
        });

        onSuccess?.(res);
      },
      onFailure,
    });
  };

/// Files.
export const postTutorContentFiles =
  ({ data, onSuccess, onFailure } = undefined) =>
  (dispatch) => {
    apiService.post({
      headers: getAuthHeaders(),
      data: { ...data, files: JSON.stringify(data.files) },
      url: `${API_MAIN_URL}/contentbuilder/learning-material/upload-files`,
      onSuccess: ({ data }) => {
        // TODO: Create types for files used by tutor's content-builder.
        // TODO: Create and store the files in the redux-store.
        onSuccess?.(data);
      },
      onFailure,
    });
  };
