import {
  FETCHED_UPCOMING_SESSIONS,
  FETCHED_RECENT_TUTORS,
  // POST_SESSION_REVIEW,
  FETCHED_CALENDAR_SESSIONS,
  FETCHED_PREVIOUS_SESSIONS,
  // RESET_CALENDAR_SESSIONS,
  RESET_PREVIOUS_SESSIONS,
  FETCHED_ORDERED_BUNDLES,
  ERRED_PREVIOUS_SESSIONS,
  RESET_MISSED_SESSIONS,
  FETCHED_MISSED_SESSIONS,
  ERRED_MISSED_SESSIONS,
} from "./store-tutoring-learner-types";

import { apiService } from "service";
import {
  // DEFAULT_CONNECTED_USERS_PER_PAGE,
  DEFAULT_MEETING_LATE_SPAN,
  API_MAIN_URL,
} from "constants";
import { getAuthHeaders } from "utils";
import moment from "moment";
import { isEmpty } from "lodash";
import { DEFAULT_LEARNER_PREVIOUS_SESSIONS_PER_PAGE } from "constants";
import {
  DEFAULT_ORDERED_BUNDLES_PER_PAGE,
  DEFAULT_SESSIONS_PER_PAGE,
} from "constants";
// import ToastFactory from "utils/toast-factory";

// const toastFactory = new ToastFactory();

export const instantConnect =
  ({ subject, curriculum, grade, onSuccess, onFailure }) =>
  async (dispatch) => {
    const data = [
      { key: "subject", value: subject.value },
      { key: "curriculum", value: curriculum.value },
      { key: "grade", value: grade.value },
    ];

    apiService.get({
      url: `${API_MAIN_URL}/instant-connect-request`,
      headers: getAuthHeaders(),
      data: data,
      onSuccess,
      onFailure,
    });
  };

/**
 * @param {Object} [params]
 * @param {number} [params.limit]
 * @param {number} [params.page]
 * @param {function} [params.onSuccess]
 * @param {function} [params.onFailure]
 *
 */
export const fetchUpcomingSessions =
  ({ limit = DEFAULT_SESSIONS_PER_PAGE, page = 1, onSuccess, onFailure }) =>
  async (dispatch) => {
    let data = [];
    data.push({ key: "limit", value: limit });
    data.push({ key: "page", value: page });

    apiService.get({
      url: `${API_MAIN_URL}/learner/upcoming-sessions`,
      headers: getAuthHeaders(),
      data,
      onSuccess: (res) => {
        const { data, ...meta } = res;
        dispatch({
          type: FETCHED_UPCOMING_SESSIONS,
          payload: { sessions: res.data, meta },
        });
        onSuccess?.(res.data);
      },
      onFailure: ({ error }) => {
        dispatch({
          type: "ERRED_UPCOMING_SESSIONS",
        });
      },
    });
  };

/**
 *
 * @param {object} params
 * @param {number} object.requestId
 * @param {number} object.status
 * @param {string | undefined} object.reason
 * @param {() => void} object.onSuccess
 * @param {() => void | undefined} object.onFailure
 * @returns
 */
export const updateSessionRequestStatus =
  ({ requestId, status, onSuccess, onFailure, reason = undefined }) =>
  async (dispatch) => {
    const formData = new FormData();
    formData.append("request_id", requestId);
    formData.append("status", status);
    if (reason) {
      formData.append("reason", reason);
    }

    apiService.post({
      url: `${API_MAIN_URL}/learner/sessions/request-response`,
      headers: getAuthHeaders(),
      data: formData,
      onSuccess,
      onFailure,
    });
  };

export const fetchRecentTutorsList = () => async (dispatch) => {
  apiService.get({
    url: `${API_MAIN_URL}/learner/get-recent-tutors`,
    headers: getAuthHeaders(),
    onSuccess: (response) => {
      dispatch({
        type: FETCHED_RECENT_TUTORS,
        payload: response.data,
      });
    },
    onFailure: () => {
      dispatch({
        type: "ERRED_RECENT_TUTORS",
      });
    },
  });
};

export const fetchLearnerSessionDetails =
  ({ sessionId, requestId, onSuccess, onFailure }) =>
  async (dispatch) => {
    apiService.get({
      headers: getAuthHeaders(),
      data: [
        { key: "session_id", value: sessionId },
        { key: "request_id", value: requestId },
      ],
      url: `${API_MAIN_URL}/learner/sessions/detail`,
      onSuccess,
      onFailure,
    });
  };
/**
 *
 * @param {object} params
 * @param {number[] | undefined} [params.curriculums]
 * @param {number[] | undefined} [params.grades]
 * @param {number[] | undefined} [params.subjects]
 * @param {number[] | undefined} [params.statuses]
 * @param {string} params.start_date
 * @param {string} params.end_date
 * @param {(calendarSessions?: RawLearnerCalendarSessions[]) => void | undefined} params.onSuccess
 * @param {(error? : {message: string}) => void| undefined} params.onFailure
 * @returns
 */
export const fetchLearnerCalendarSessions =
  ({
    curriculums,
    grades,
    subjects,
    statuses,
    start_date,
    end_date,
    onSuccess = () => {},
    onFailure = () => {},
  }) =>
  async (dispatch) => {
    const data = [
      { key: "start_date", value: start_date },
      { key: "end_date", value: end_date },
    ];
    if (!isEmpty(curriculums)) {
      data.push({ key: "curriculum", value: curriculums });
    }
    if (!isEmpty(grades)) {
      data.push({ key: "grade", value: grades });
    }
    if (!isEmpty(subjects)) {
      data.push({ key: "subject", value: subjects });
    }
    if (!isEmpty(statuses)) {
      data.push({ key: "status", value: statuses });
    }

    apiService.get({
      url: `${API_MAIN_URL}/learner/get-calendar-sessions`,
      headers: getAuthHeaders(),
      data,
      onSuccess: (response) => {
        onSuccess?.(response.data);

        // adapting data for schedule component
        const sessionsData = response.data.map((m) => {
          const startTimeStamp = m.date + " " + m.start_time;
          const endTimeStamp = m.date + " " + m.end_time;

          const startTime = moment(
            startTimeStamp,
            "YYYY-MM-DD HH:mm:ss"
          ).format();

          const endTime = moment(endTimeStamp, "YYYY-MM-DD HH:mm:ss").format();
          /* above we manipulated session data according to our needs and now are grabbing only that data which is
          needed so that calendar can load fastly and work efficiently. This upper case notation is used in
          syncfusion example that's why this is also being used here. */
          return {
            SessionId: m.session_id,
            RequestId: m.id,
            StartTime: startTime,
            EndTime: endTime,
            SubjectId: m.session.subject_id,
            TutorName:
              m.sent_by.user_type === "tutor" ? m.sent_by.name : m.sent_to.name,
            RequestStatus: m.status.id,
            IsUpcoming: moment(startTimeStamp, "YYYY-MM-DD HH:mm:ss")
              .add(DEFAULT_MEETING_LATE_SPAN, "m")
              .isSameOrAfter(new Date()),
          };
        });

        dispatch({
          type: FETCHED_CALENDAR_SESSIONS,
          payload: sessionsData,
        });
      },
      onFailure: (error) => {
        onFailure?.(error);
        dispatch({ type: "ERRED_CALENDAR_SESSIONS" });
      },
    });
  };

export const fetchLearnerPreviousSessions =
  ({
    curriculums,
    agenda,
    subjects,
    statuses,
    onSuccess,
    onFailure,
    page = 1,
    limit = DEFAULT_LEARNER_PREVIOUS_SESSIONS_PER_PAGE,
  }) =>
  async (dispatch, getState) => {
    dispatch({
      type: RESET_PREVIOUS_SESSIONS,
    });

    apiService.get({
      url: `${API_MAIN_URL}/learner/previous-sessions?page=${page}&limit=${limit}`,
      headers: getAuthHeaders(),
      // data,
      onSuccess: (response) => {
        if (response.data) {
          dispatch({
            type: FETCHED_PREVIOUS_SESSIONS,
            payload: response,
          });
        } else {
          dispatch({
            type: ERRED_PREVIOUS_SESSIONS,
          });
        }
      },
      onFailure: (error) => {
        dispatch({
          type: ERRED_PREVIOUS_SESSIONS,
        });

        onFailure?.();
      },
    });
  };

export const fetchLearnerMissedSessions =
  ({
    onFailure,
    page = 1,
    limit = DEFAULT_LEARNER_PREVIOUS_SESSIONS_PER_PAGE,
  }) =>
  async (dispatch, getState) => {
    dispatch({
      type: RESET_MISSED_SESSIONS,
    });

    apiService.get({
      url: `${API_MAIN_URL}/learner/previous-sessions`,
      data: [
        { key: "session_type", value: "missed" },
        { key: "page", value: page },
        { key: "limit", value: limit },
      ],
      headers: getAuthHeaders(),
      onSuccess: (response) => {
        if (response.data) {
          dispatch({
            type: FETCHED_MISSED_SESSIONS,
            payload: response,
          });
        } else {
          dispatch({
            type: ERRED_MISSED_SESSIONS,
          });
        }
      },
      onFailure: (error) => {
        dispatch({
          type: ERRED_MISSED_SESSIONS,
        });

        onFailure?.();
      },
    });
  };

export const addSessionReview =
  ({ experience, skill, rate, review, session_id, onSuccess, onFailure }) =>
  async (dispatch) => {
    const formData = new FormData();
    formData.append("experience", experience);
    formData.append("skill", skill);
    formData.append("rate", rate);
    formData.append("review", review);
    formData.append("session_id", session_id);

    apiService.post({
      url: `${API_MAIN_URL}/learner/add-reviews`,
      data: formData,
      headers: getAuthHeaders(),
      onSuccess: (res) => {
        onSuccess?.(res);
      },
      onFailure,
    });
  };

export const fetchLearnerOrderedBundles =
  ({
    page = 1,
    limit = DEFAULT_ORDERED_BUNDLES_PER_PAGE,
    tutorUserId,
    bundleId,
  }) =>
  async (dispatch) =>
    apiService.get({
      headers: getAuthHeaders(),
      data: [
        { key: "page", value: page },
        { key: "limit", value: limit },
        { key: "tutor_user_id", value: tutorUserId },
        { key: "bundle_id", value: bundleId },
      ],

      url: `${API_MAIN_URL}/tutor/get-ordered-sessions-request`,

      onSuccess: (data) =>
        dispatch({
          type: FETCHED_ORDERED_BUNDLES,
          payload: data,
        }),
      onFailure: (error) =>
        dispatch({
          type: "ERRED_ORDERED_BUNDLES",
          payload: error,
        }),
    });

export const sendSessionRequestToTutor =
  ({ sessionRequestData, onSuccess, onFailure }) =>
  async (dispatch) => {
    const formData = new FormData();

    Object.entries(sessionRequestData).forEach(([key, value]) =>
      formData.append(key, value)
    );

    apiService.post({
      url: `${API_MAIN_URL}/learner/sessions/save-requests`,
      headers: getAuthHeaders(),
      data: formData,
      onSuccess: (res) => {
        onSuccess?.(res);
      },
      onFailure,
    });
  };

export const searchForTutors =
  ({
    limit = 3,
    subjects,
    curriculums,
    grades,
    sortStyle,
    onSuccess,
    onFailure,
  }) =>
  async (dispatch) => {
    const params = [{ key: "limit", value: limit }];

    if (subjects)
      params.push({
        key: "subject",
        value: subjects.map((s) => s?.replaceAll(" ", "-")).join("__"),
      });

    if (curriculums)
      params.push({
        key: "curriculum",
        value: curriculums.map((c) => c?.replaceAll(" ", "-")).join("__"),
      });

    if (grades)
      params.push({
        key: "grade",
        value: grades.map((g) => g?.replaceAll(" ", "-")).join("__"),
      });

    if (sortStyle) params.push({ key: "sort", value: sortStyle });

    apiService.get({
      url: `${API_MAIN_URL}/tutor/search`,
      headers: getAuthHeaders(),
      data: params,
      // the code below potentially makes this action non-general.
      onSuccess: ({ data }) => {
        dispatch({
          type: "UPDATE_LEARNER_SUBJECTS_TUTORS",
          payload: { subject: subjects[0], tutors: data },
        });
        onSuccess?.();
      },
      onFailure: (error) => {
        onFailure?.();
      },
    });
  };
