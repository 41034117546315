import React, {useState} from "react";
import {Button, Col, Container} from "reactstrap";
import ReferCard from "./ReferCard";
import RefersHistoryTable from "./refers-history-table";
import {useApiEffect, useUser} from "hooks";
import {useDispatch} from "react-redux";
import {fetchRefersHistory} from "store/actions";
import "./refer-main.scss";
import {Loader, SassyAlert} from "components";
import strings from "constants/strings";


/**
 * @author Abdullah-Sajjad026
 */
const ReferFriends = () => {
  // const {type: userType} = useUser();
  // const [showRefersHistory, setShowReferHistory] = useState(false);

  const dispatch = useDispatch();

  const [{state, data}, setRefersHistory] = useState({
    state: "loading",
    data: null,
  });

  useApiEffect(() => {
    dispatch(
      fetchRefersHistory({
        onSuccess: (data) => {
          setRefersHistory({state: "loaded", data});
        },
        onFailure: (error) => {
          setRefersHistory({state: "erred"});
        },
      })
    );
  }, []);

  return (
    <section className="content">
      {/* <Breadcrumb parentRouteName="Dashboard" activeRouteName="Referrals" /> */}
      <Container className="p-3 p-md-4" fluid>
        <ReferCard />

        {state === "erred" ? (
          <SassyAlert
            color="danger"
            className="my-3"
            title={strings.DEFAULT_ERROR_MESSAGE}
            description="Unable to load the history right now"
          />
        ) : state === "loading" ? // <Loader isInline />
        null : data.length === 0 ? // /> //   description="No results found" //   className="my-3" //   color="primary" // <SassyAlert
        null : (
          <RefersHistoryTable {...{state, data}} />
        )}

        {/* {!showRefersHistory ? (
          <Col
            xs="12"
            className="text-center align-items-center justify-content-center"
          >
            <h3 className="mt-5 secondary-color">
              Start referring to grow TheTutor.me community!
            </h3>
            {userType === "tutor" && (
              <p className="font-size-13 text-secondary-dim">
                Note: The network effect pay huge dividends for you <br /> as a
                Tutor on TheTutor.me!
              </p>
            )}
            <Button onClick={() => setShowReferHistory(true)}>
              View Referral History
            </Button>
          </Col>
        ) : (
          
        )} */}
      </Container>
    </section>
  );
};

export default ReferFriends;
