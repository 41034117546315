/* eslint-disable eqeqeq */
export default class UserAdapter {
  /**
   * @param {User} state
   */
  serialize(state) {
    // "id": 416,
    // "name": "Rashid Tutor",
    // "email": "rashid+12@pangiah.com",
    // "user_type": "tutor",
    // "social_access_tokens": null,
    // "social_data": null,
    // "status": 1,
    // "dob": "1995-12-12",
    // "mobile_number": "+91445421212",
    // "verification_status": 0,
    // "timezone": "UTC"
    return {
      id: state.id || "",
      name: state.name || "",
      email: state.email || "",
      user_type: state.userType || "tutor",
      dob: state.dob || "",
      mobile_number: state.phone || "",
      verification_status: state.isVerified ? 1 : 0,
      timezone: state.timezoneName || "",
    };
  }

  /**
   * @param {Object} state
   *
   * @returns {User}
   */
  deserialize(state) {
    return {
      id: state.id || "",
      name: state.name || "",
      email: state.email || "",
      userType: state.user_type || "tutor",
      dob: state.dob || "",
      phone: state.mobile_number || "",
      isVerified: state.verification_status == 1,
      timezoneName: state.timezone,
    };
  }
}
