import * as React from "react";

// Features.
import Router from "router";

// Views.
import { Error400, Error403, Error440, ErrorOffline } from "views";

// Components.
import { Loader, TransactionInvoice } from "components";

// Utils.
import { apiService } from "service";
import { getAuthHeaders } from "utils";
import { API_MAIN_URL } from "constants";

// Store.
import { USERINFO_ERROR } from "store";
import { useDispatch, useSelector } from "react-redux";
import { USER_LOADED } from "store/auth/authActionTypes";

// Static.
import "react-toastify/dist/ReactToastify.css";
import { useStaticDataV3 } from "hooks/static-data";
import {
  removeToken,
  getLogoutToken,
  removeLogoutToken,
} from "utils/utils-auth";
import { WEBSITE_URL } from "constants";
import useRemoteProfile from "hooks/profile/use-remote-profile";
import { useNavigate } from "react-router-dom";

function App() {
  const navigate = useNavigate();
  // Store.
  const dispatch = useDispatch();
  const [isAuthorized, setIsAuthorized] = React.useState(true);
  // const accessToken = useSelector(
  //   (state) => state.auth.userDetails?.access_token
  // );

  // Profile v2.
  const { getProfile } = useRemoteProfile();
  const [profileState, setProfileState] = React.useState("idle");

  /* Preloading static data resources to be used globally in the app.
   Many screens depend on these resources to be pre fetched so if in future you want to remove these,
   kindly keep backwards compatibility. */
  const { skills, grades, subjects, languages, curriculums, requestTypes } =
    useStaticDataV3([
      "skills",
      "grades",
      "subjects",
      "languages",
      "curriculums",
      "requestTypes",
    ]);

  const isStaticDataLoading = [
    skills.state,
    grades.state,
    subjects.state,
    languages.state,
    curriculums.state,
    requestTypes.state,
  ].includes("loading");

  // Effects.
  React.useEffect(() => {
    // Check if we're here cuz the user just logged out.
    if (getLogoutToken()) {
      removeToken();
      removeLogoutToken();

      //Redirect to the website.
      window.location.assign(`${WEBSITE_URL}/auth/login?isLoggedOut=true`);
      return;
    }

    /**
     * The token assigned to a user upon logging in.
     * @var {string | null}
     */
    const TOKEN =
      new URLSearchParams(window.location.search).get("token") ||
      localStorage.getItem("token");

    // If the user isn't logged in.
    if (!TOKEN) {
      dispatch({
        type: USERINFO_ERROR,
        payload: "😞 Error, authentication token not found!",
      });

      console.error("😞 Error, authentication token not found!");
      setIsAuthorized(false);
      return;
    }

    // Either a new token, retrieved from the url, is saved
    // or the one that persists in the local storage.
    localStorage.setItem("token", TOKEN);

    if (window.location.pathname !== "/440") {
      // Set profile V2 loading.
      setProfileState("loading");

      apiService.post({
        headers: getAuthHeaders(TOKEN),
        url: `${API_MAIN_URL}/validate-token`,

        onSuccess: ({ data }) => {
          localStorage.setItem("user", JSON.stringify(data.data));
          localStorage.setItem("TIMEZONE", data.data.timezone);

          const { user_type: userType } = data.data;

          // The access token isn't set when the app is initializing.
          // A 500 is received if a profile is requested without the
          // token being initialized.
          // userType === "tutor" && accessToken && dispatch(getTutorData());
          // userType === "learner" && accessToken && dispatch(getlearnerData());

          // Set profile
          dispatch({
            type: USER_LOADED,
            payload: {
              ...data.data,
              user_type: userType,
              access_token: TOKEN,
            },
          });

          // Get Profile V2.
          getProfile({
            userType,
            existingProfile: data.data,
            onFailure: () => setProfileState("erred"),
            onSuccess: ({ data }) => {
              setProfileState("loaded");
              if (!data.is_onboarded) return navigate("/");
            },
          });
        },
        onFailure: (error) => {
          dispatch({
            type: USERINFO_ERROR,
            payload: `😞 ${JSON.stringify(error)}`,
          });

          console.error("😞", error);
          setIsAuthorized(false);
        },
      });
    }

    // * to be tested it deprecation
    // Access Token is a dependancy since it's undefined when the app
    // gets initialized so the effect has to work when it gets set.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Effect: Remove the Syncfusion banner.
  React.useEffect(() => {
    // The banner loads in kinda later. So, we'll have to wait.
    const interval = setInterval(() => {
      // @see https://stackoverflow.com/a/37098628
      const getBanner = () =>
        document.evaluate(
          '//*[contains(text(),"Syncfusion")]',
          document,
          null,
          XPathResult.UNORDERED_NODE_ITERATOR_TYPE,
          null
        );

      // Banner is the first element.
      let syncfusionBanner = getBanner().iterateNext();

      // While the banner is there.
      while (syncfusionBanner) {
        // See if it has a parent.
        let parent = syncfusionBanner.parentElement;

        // Catch all of it's parents until the body and remove them all.
        while (parent && parent.tagName !== "BODY") {
          parent.remove();
          parent = parent.parentElement;
        }

        // Remove the banner.
        syncfusionBanner.remove();

        // Get the next banner.
        syncfusionBanner = getBanner().iterateNext();
      }
    }, 1000);

    // adding dependencies in Storage that need to be used for first visit only
    sessionStorage.setItem("canLearnerSemanticRedirect", "true");

    function beforePageUnload() {
      // check router.js and timezone-update-modal.tsx for the logic
      sessionStorage.removeItem("shownTimezoneUpdateModal");
    }

    window.addEventListener("beforeunload", beforePageUnload);

    return () => {
      clearInterval(interval);
      window.removeEventListener("beforeunload", beforePageUnload);
    };
  }, []);

  // When offline.
  if (!navigator.onLine) return <ErrorOffline />;

  // When the token is expired.
  if (window.location.pathname === "/440") {
    // Remove expired token.
    removeToken();

    // Redirect to the relevant page.
    return <Error440 />;
  }

  // A specific route that exists outside the router.
  if (window.location.pathname === "/transaction-invoice")
    return <TransactionInvoice />;

  // When the user isn't authorized.
  if (!isAuthorized) return <Error403 />;

  // When the static data OR profile is loading.
  if (
    isStaticDataLoading ||
    profileState === "idle" ||
    profileState === "loading"
  )
    return <Loader />;

  // When the profile has an error.
  // We DON'T check static data's state because it's not a critical error.
  if (profileState === "erred") return <Error400 />;

  return <Router />;
}

export default App;
