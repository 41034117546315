/* eslint-disable eqeqeq */
import { useCallback } from "react";

// Type.
import useStaticValues from "hooks/use-static-values/use-static-values";

import type {
  SubjectsFormState,
  RemoteSubjectsFormState,
} from "./subjects-form-types";
import type { StaticFieldOption } from "components/static-field";

export default function useSubjectsFormAdapter() {
  const serialize = useCallback(
    (state: SubjectsFormState): RemoteSubjectsFormState => {
      return {
        subjects: state.subjects
          ? state.subjects.map((lang) => ({
              id: lang.value,
              subject_id: lang.value,
            }))
          : [],
      };
    },
    []
  );

  const deserialize = useCallback(
    (remoteState: RemoteSubjectsFormState): SubjectsFormState => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const subjects = useStaticValues({
        key: "subjects",
        values: remoteState.subjects,
        keyOfId: "subject_id",
      }) as StaticFieldOption[];

      return {
        subjects,
      };
    },
    []
  );

  return {
    serialize,
    deserialize,
  };
}
