import * as React from "react";
import { ModalProps } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export default function withRouterModal<T extends ModalProps>(
  Component: React.ComponentType<T>,
  baseRoute: string
) {
  return function RouterModal(props: T) {
    const navigate = useNavigate();

    return <Component {...props} show onHide={() => navigate(baseRoute)} />;
  };
}
