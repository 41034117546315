import React from "react";
import { Dropdown } from "react-bootstrap";
import {
  IconCloudDownload,
  IconCopy,
  IconDots,
  IconPencil,
  IconTrash,
  IconEye,
  IconTargetOff,
  IconTarget,
} from "@tabler/icons";
import SassyDropdownItem from "components/sassy-dropdown-item";
import useToast from "hooks/use-toast";
import { ConfirmDeletionModal } from "modules/modals/confirmation";
import { SimpleModalBody } from "modules/modals/components/modal-body/variants";
import { DeletionModalFooter } from "modules/modals/components/modal-footer/variants";

export default function ListItemMaterialActions({
  lmVisibility,
  editButtonListener,
  cloneButtonListener,
  deleteButtonListener,
  downloadButtonListener,
  viewButtonListener,
  toggleSearchableListener,
}) {
  const toast = useToast();
  return (
    <Dropdown className="d-inline" style={{ zIndex: 1 }}>
      <Dropdown.Toggle size="sm" variant="outline-secondary">
        <IconDots size="16" />
      </Dropdown.Toggle>
      <Dropdown.Menu className="border-2">
        {/* <Dropdown.Header className="text-secondary fs-6">
          Actions
        </Dropdown.Header> */}
        {/* Edit button. */}
        {editButtonListener && (
          <SassyDropdownItem
            as="button"
            onClick={editButtonListener}
            icon={IconPencil}
            text="Edit Material"
          />
        )}

        {/* Delete button. */}
        {deleteButtonListener && (
          <SassyDropdownItem
            as="button"
            icon={IconTrash}
            text="Delete Material"
            onClick={() => {
              toast.render(
                <ConfirmDeletionModal
                  body={
                    <SimpleModalBody>
                      Are you sure you want to delete this material? You won't
                      be able to recover it later.
                    </SimpleModalBody>
                  }
                  footer={
                    <DeletionModalFooter
                      onCancel={() => toast.dismiss()}
                      onDelete={() => deleteButtonListener()}
                    />
                  }
                />
              );
            }}
          />
        )}

        {/* Download button */}
        {downloadButtonListener && (
          <SassyDropdownItem
            as="button"
            onClick={downloadButtonListener}
            icon={IconCloudDownload}
            text="Download Material"
          />
        )}

        {/* Clone Button */}
        {/* {cloneButtonListener && (
          <SassyDropdownItem
            as="button"
            onClick={cloneButtonListener}
            icon={IconCopy}
            text="Clone Material"
          />
        )} */}
        {/* View Button */}
        {viewButtonListener && (
          <SassyDropdownItem
            as="button"
            onClick={viewButtonListener}
            icon={IconEye}
            text="View Material"
          />
        )}

        {/* View Button */}
        {toggleSearchableListener && (
          <SassyDropdownItem
            as="button"
            onClick={toggleSearchableListener}
            icon={lmVisibility === 0 ? IconTarget : IconTargetOff}
            text={lmVisibility === 0 ? "Make Searchable" : "Not Searchable"}
          />
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}
