import React from "react";

// Views.
import {
  LearnerProfileView,
  LearnerOnboarding,
  LearnerDashboardReviews,
  TutoringLearnerLanding,
  TutoringLearnerNewSession,
  TutoringLearnerPreviousSessions,
  TutoringLearnerPreviousSessionDetailed,
  TransactionsView,
  TransactionsMethodsView,
  TransactionsDashboardView,
  PaymentsHistoryView,
  LearningMaterial,
  LearningMaterialDetails,
  LearningMaterialManage,
  LearningMaterialDashboard,
  TransactionsTotalHistory,
  TutoringLearnerCredits,
} from "views";

// Components.
import { Navigate, Route } from "react-router-dom";

// Layouts
import { VerticalLayout } from "layout";
import { Navigator } from "components";
import { RawPhoneNumberContextProvider } from "context/phone-number";
import getPhoneNumberRoutes from "./_get-phone-number-routes";
import {
  TutoringLearner,
  TutoringLearnerSchedule,
  TutoringLearnerSessions,
  TutoringLearnerUpcomingSessionDetailed,
} from "views/tutoring/tutoring-learner-v2/";
import FindTutorModal from "components/find-tutor-modal";
import { RLearnerTutoring } from "constants/learner-views-routes";
import { learnerRoutes } from "./routes";

export default function getLearnerRoutes(navigate) {
  return [
    // Routes for non boarded learners.
    <Route path="/onboarding/learner" element={<LearnerOnboarding />} />,
    <Route element={<VerticalLayout />}>
      <Route element={<Navigator />}>
        {/* Routes for boarded learners. */}
        <Route element={<RawPhoneNumberContextProvider />}>
          <Route path="/profile" element={<LearnerProfileView />}>
            {getPhoneNumberRoutes(navigate, "/profile").map((_) => _)}
          </Route>
        </Route>
        <Route path="/reviews" element={<LearnerDashboardReviews />} />
        <Route path="/1-1-tutoring" element={<TutoringLearnerLanding />} />
        <Route
          {...learnerRoutes.credits}
          element={<TutoringLearnerCredits />}
        />
        <Route
          {...learnerRoutes.creditsSearch}
          element={<TutoringLearnerCredits />}
        />

        <Route path="/dashboard" element={<TutoringLearner />}>
          <Route
            index
            element={<Navigate to={RLearnerTutoring.getSessionsView()} />}
          />
          <Route path="new-session">
            <Route
              path="tutor/:tutor_id"
              element={<TutoringLearnerNewSession />}
            />
          </Route>

          <Route path="upcoming-sessions" element={<TutoringLearnerSessions />}>
            <Route index element={<TutoringLearnerSchedule />} />
            <Route
              path="details/:session_id/req/:request_id"
              element={<TutoringLearnerUpcomingSessionDetailed />}
            />
            <Route
              path="reschedule/:session_id/req/:request_id"
              element={<TutoringLearnerUpcomingSessionDetailed isEdit={true} />}
            />
          </Route>
          <Route
            path="sessions-history"
            element={<TutoringLearnerPreviousSessions />}
          />
          <Route
            path="sessions-history/:session_id/req/:request_id"
            element={<TutoringLearnerPreviousSessionDetailed />}
          />

          <Route path="sessions">
            <Route element={<TutoringLearnerSessions />}>
              <Route index element={<TutoringLearnerSchedule />} />
              <Route path="request">
                <Route
                  path="upcoming/:session_id/req/:request_id"
                  element={<TutoringLearnerUpcomingSessionDetailed />}
                />
                <Route
                  path="reschedule/:session_id/req/:request_id"
                  element={
                    <TutoringLearnerUpcomingSessionDetailed isEdit={true} />
                  }
                />
                <Route
                  path="history/:session_id/req/:request_id"
                  element={<TutoringLearnerPreviousSessionDetailed />}
                />
              </Route>
            </Route>
            <Route path="new">
              <Route
                path="tutor/:tutor_id"
                element={<TutoringLearnerNewSession />}
              />
            </Route>
          </Route>
        </Route>

        {/* Learner's transaction-routes start here. */}
        <Route path="/transactions" element={<TransactionsView />}>
          <Route index element={<TransactionsDashboardView />} />
          <Route path="all">
            <Route index element={<TransactionsTotalHistory />} />
            <Route path="page/:page" element={<TransactionsTotalHistory />} />
          </Route>
          <Route path=":type" element={<PaymentsHistoryView />} />
          <Route path=":type/page/:page" element={<PaymentsHistoryView />} />
          <Route path="payment-methods" element={<TransactionsMethodsView />} />
        </Route>
        {/* Learner's transaction-routes end here. */}
        {/* Learner's learning material-routes start here. */}
        <Route path="/learning-materials" element={<LearningMaterial />}>
          <Route index element={<LearningMaterialDashboard />} />
          <Route path="manage" element={<LearningMaterialManage />} />
          <Route
            path="manage/page/:page/type/:type"
            element={<LearningMaterialManage />}
          />
          <Route path="details/:id/*" element={<LearningMaterialDetails />} />
        </Route>
      </Route>
      <Route
        path="/find-tutor"
        element={
          <FindTutorModal isOpen={true} toggleModal={() => navigate(-1)} />
        }
      />
    </Route>,
  ];
}
