import { IconDownload } from "@tabler/icons";
import { API_MAIN_URL } from "constants";
import { messages } from "constants/strings";
import { useUser } from "hooks";
import { useStaticDataV3 } from "hooks/static-data";
import { useEffect, useState } from "react";
import { apiService } from "service";
import { getAuthHeaders } from "utils";
import ToastFactory from "utils/toast-factory";

function InvoiceDownloadButton({ refId, date, status, price, ...props }) {
  const [transactionDetails, setTransactionDetails] = useState({
    state: "loading",
    data: {},
  });

  const user = useUser();
  const toastFactory = new ToastFactory();
  const { subjects, getStaticData } = useStaticDataV3(["subjects"]);

  const getTransactionDetails = (refId) => {
    const data = new FormData();
    data.append("ref_id", refId);

    toastFactory.loading();

    apiService.post({
      url: `${API_MAIN_URL}/payment/transaction-order-detail`,
      data,
      headers: getAuthHeaders(),
      onSuccess: ({ data }) => {
        toastFactory.dismiss();
        setTransactionDetails({ state: "loaded", data });
      },
      onFailure: (error) => {
        toastFactory.error(error.message);
        setTransactionDetails({ state: "erred", data: {} });
      },
    });
  };

  useEffect(() => {
    if (transactionDetails.state === "loaded" && subjects.state === "loaded") {
      // console.log(transactionDetails.data);
      const hasSessions =
        transactionDetails.data.session &&
        transactionDetails.data.session.length > 0;
      const hasMaterials =
        transactionDetails.data.materials &&
        transactionDetails.data.materials.length > 0;

      if (hasSessions || hasMaterials) {
        const receiptItems = [];

        if (hasSessions) {
          receiptItems.push(
            ...transactionDetails.data.session.map(({ product }) => ({
              product: product,
              subject: getStaticData(product.subject_id, subjects.data),
              userName: product.product_owner.name,
              date: product.date,
            }))
          );
        }

        if (hasMaterials) {
          receiptItems.push(
            ...transactionDetails.data.materials.map(({ product }) => ({
              product: product,
              subject: getStaticData(product.subject_id, subjects.data),
              userName: product.product_owner.name,
              date: product.date,
            }))
          );
        }
        const invoiceData = {
          refId: refId,
          date: date,
          status: status,
          userName: user.name,
          totalPrice: price,
          receiptItems: receiptItems,
        };
        sessionStorage.setItem("invoiceData", JSON.stringify(invoiceData));
        window.open(
          `${process.env.REACT_APP_DASHBOARD_URL}/transaction-invoice`
        );
      }

      // const invoiceData = {
      //   refId: refId,
      //   date: date,
      //   status: status,
      //   userName: user.name,
      //   totalPrice: price,
      //   receiptItems: transactionDetails.data.session.map(({ product }) => ({
      //     product: product,
      //     subject: getStaticData(product.subject_id, subjects.data),
      //     userName: product.product_owner.name,
      //     date: product.date,
      //     //   sessions: 6,
      //     // amount: product.price,
      //     // currency: "USD",
      //     // discount: product.order_detail.discount,
      //     // promo: product.order_detail.promo,
      //   })),
      // };
    }
  }, [transactionDetails, subjects.state]);

  if (transactionDetails.state === "erred" || subjects.state === "erred") {
    toastFactory.error(messages.ERROR_LOADING_INVOICE);
  }

  return (
    <IconDownload
      {...props}
      onClick={() => {
        getTransactionDetails(refId);
      }}
    />
  );
}

export default InvoiceDownloadButton;
