/* eslint-disable eqeqeq */
import { useCallback } from "react";
import { getAutoSelectedTimezone } from "utils";
import { allTimezones } from "react-timezone-select";

// Type.
import useStaticValues from "hooks/use-static-values/use-static-values";

import type {
  BasicInfoFormState,
  RemoteBasicInfoFormState,
} from "./basic-info-form-types";
import type { StaticFieldOption } from "components/static-field";

export default function useBasicInfoFormAdapter() {
  const serialize = useCallback(
    (state: BasicInfoFormState): RemoteBasicInfoFormState => {
      return {
        name: state.name || "",
        email: state.email || "",
        country_code: state.phone.dialCode || "",
        mobile_number: state.phone.number || "",
        dob: state.dob || "",
        languages: state.languages
          ? state.languages.map((lang) => ({
              id: lang.value,
              language_id: lang.value,
            }))
          : [],
        title: state.title || "",
        timezone: state.timezone.value || "",
        not_searchable: state.isSearchable ? 0 : 1,
      };
    },
    []
  );

  const deserialize = useCallback(
    (remoteState: RemoteBasicInfoFormState): BasicInfoFormState => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const languages = useStaticValues({
        key: "languages",
        values: remoteState.languages,
        keyOfId: "language_id",
      }) as StaticFieldOption[];

      return {
        languages,
        name: remoteState.name || "",
        email: remoteState.email || "",
        dob: remoteState.dob || "",
        title: remoteState.title || "",
        isSearchable: remoteState.not_searchable == 0,
        phone: {
          number: remoteState.mobile_number || "",
          dialCode: remoteState.country_code || "",
        },
        timezone: (() => {
          // If timezone is not there OR is UTC
          if (!remoteState.timezone || remoteState.timezone === "UTC") {
            return {
              label: "",
              value: getAutoSelectedTimezone(allTimezones),
            };
          }

          return {
            label: remoteState.timezone,
            value: remoteState.timezone,
          };
        })(),
      };
    },
    []
  );

  return {
    serialize,
    deserialize,
  };
}
