import { useCallback } from "react";

// Hooks.
import useToast from "hooks/use-toast";
import { useRemoteSubjects } from "hooks/tutor/subjects";
import useSubjectsFormSchema from "./use-subjects-form-schema";
import useSubjectsFormAdapter from "./use-subjects-form-adapter";

// Types.
import type { SubjectsFormState } from "./subjects-form-types";

type Props = {
  children: ({
    initialValues,
    validationSchema,
    onSubmit,
  }: {
    initialValues: SubjectsFormState;
    validationSchema: unknown;
    onSubmit: (state: SubjectsFormState) => void;
  }) => JSX.Element;
};

export default function SubjectsFormProvider({ children }: Props) {
  const toast = useToast();
  const { subjects, updateSubjects } = useRemoteSubjects();

  // Features.
  const validationSchema = useSubjectsFormSchema();
  const { serialize, deserialize } = useSubjectsFormAdapter();

  /**
   * Initial values of the form.
   */
  const initialValues = deserialize({ subjects });

  function onSubmit(state: SubjectsFormState) {
    toast.loading();

    updateSubjects({
      subjects: serialize(state).subjects,
      onFailure: ({ message }) => toast.error(message),
      onSuccess: () => toast.success(),
    });
  }

  return children({
    onSubmit,
    initialValues,
    validationSchema,
  });
}
