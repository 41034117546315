import { IconBook2, IconEye } from "@tabler/icons";
import { Loader, SassyAlert } from "components";
import SimpleBar from "simplebar-react";
import { API_MAIN_URL } from "constants";
import { useApiEffect } from "hooks";
import { useStaticDataV3 } from "hooks/static-data";
import moment from "moment";
import React from "react";
import { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Link } from "react-router-dom";
import { apiService } from "service";
import { getAdjustedTime, getAuthHeaders } from "utils";
import { tutorRoutes } from "router/routes";

const DashboardTutorMaterials = () => {
  const [{ state, data }, setMaterials] = useState({
    state: "loading",
    data: [],
  });

  const { subjects, grades, curriculums, getStaticData } = useStaticDataV3([
    "subjects",
    "grades",
    "curriculums",
  ]);

  useApiEffect(() => {
    // fetching tutor's latest 5 draft learning materials
    apiService.get({
      headers: getAuthHeaders(),
      data: [
        { key: "limit", value: 4 },
        { key: "drafted", value: 1 },
      ],
      url: `${API_MAIN_URL}/contentbuilder/tutor/learning-material/fetch`,
      onSuccess: ({ data }) => {
        setMaterials({ state: "loaded", data: data.list });
      },
      onFailure: ({ error }) => {
        setMaterials({ state: "erred", data: [] });
      },
    });
  }, []);

  const columns = [
    {
      isDummy: true,
      dataField: "agenda",
      text: "Material Title",
      formatter: (_, row) => (
        // <div>
        //   <p className="font-size-16 fw-normal">{row.agenda}</p>

        // </div>
        <div>
          <p className="text-secondary mb-0">
            {row.title.substring(0, 50)} {row.title.length > 50 && " ..."}
          </p>
          <ul className=" mb-0 p-0 d-flex text-muted list-dots">
            {subjects.state === "loaded" && (
              <li>
                {getStaticData(row.subjects[0].subject_id, subjects.data)}
              </li>
            )}
            {grades.state && (
              <li> {getStaticData(row.grades[0].grade_id, grades.data)}</li>
            )}
            {curriculums.state && (
              <li>
                {getStaticData(
                  row.curriculums[0].curriculum_id,
                  curriculums.data
                )}
              </li>
            )}
          </ul>
        </div>
      ),
    },
    {
      isDummy: true,
      dataField: "data",
      text: "Last Edit",
      formatter: (_, row) => (
        <div className="text-secondary">
          <p className="mb-0">
            {getAdjustedTime(row.updated_at, "HH:mm:ss").format("hh:mm A")}
          </p>
          <p className="mb-0">
            {moment(row.updated_at, "YYYY-MM-DD").format("DD, MMM YYYY")}
          </p>
        </div>
      ),
    },
    {
      isDummy: true,
      dataField: "id",
      text: "",
      formatter: (_, row) => (
        <Link
          as="button"
          role="tooltip"
          aria-label="Continue Editing"
          data-microtip-position="left"
          className={`p-1 border-1 rounded bg-gray-100 hover:bg-gray-300 focus:bg-gray-300 text-secondary `}
          // to={`${tutorRoutes.createMaterials.url}?id=${row.id}`}
          to={tutorRoutes.materials.url}
        >
          <IconEye size={16} />
        </Link>
      ),
    },
  ];

  if (
    grades.state === "loading" ||
    subjects.state === "loading" ||
    curriculums.state === "loading" ||
    state === "loading"
  ) {
    return <Loader />;
  }

  return (
    <div>
      {state === "erred" ||
      grades.state === "erred" ||
      subjects.state === "erred" ||
      curriculums.state === "erred" ? (
        <SassyAlert
          color="danger"
          title="We've encountered and error while loading your draft learning materials."
        />
      ) : !data.length === 0 ? (
        <div className="p-5 d-flex flex-column justify-content-center align-items-center">
          <div className="rounded-3 p-3 bg-soft-success">
            <IconBook2 />
          </div>
          <div className="text-secondary fw-semibold mt-3 text-center">
            <p className="fw-bold">You don't have any Draft Material, </p>
            <p className="fw-bold">
              By{" "}
              <Link className="text-info" to={tutorRoutes.createMaterials.url}>
                Creating Material
              </Link>{" "}
              you'll earn more{" "}
              <h3 className="d-inline-block fw-bolder">25%.</h3>
            </p>
          </div>
        </div>
      ) : data.length === 0 ? (
        <SassyAlert
          color="info"
          title="You don't have any draft learning material."
        />
      ) : (
        <div>
          <>
            <SimpleBar className="overflow-auto">
              <BootstrapTable
                columns={columns}
                data={data}
                striped={false}
                bordered={false}
                keyField="id"
                headerWrapperClasses="table-light"
                classes="table align-middle table-nowrap table-check mb-0 text-secondary"
              />
            </SimpleBar>
            <div className="p-3">
              <Link
                className="btn btn-secondary"
                to={tutorRoutes.materials.url}
              >
                View More
              </Link>
            </div>
          </>
        </div>
      )}
    </div>
  );
};

export default DashboardTutorMaterials;
