import React from "react";
import { Button } from "react-bootstrap";
import { boxStyles } from "constants/config";
import { IconArrowRight } from "@tabler/icons";

export default function OnboardingTutorConfirmButton({
  onClick,
  children,
}: {
  onClick: () => unknown;
  children: React.ReactNode;
}) {
  return (
    <Button
      onClick={onClick}
      variant="secondary"
      className={`d-flex align-items-center ${boxStyles.xGap}`}
    >
      {children}
      <IconArrowRight size={24} strokeWidth={1.5} color="currentColor" />
    </Button>
  );
}
