import React from "react";

// Views.
import {
  Home,
  Notifications,
  Error404,
  Error403,
  Error500,
  Error400,
  Error440,
  ErrorOffline,
  // Placeholders.
  PlaceholderView,
  OnboardingPlaceholderView,
  TutorStartPlaceholderView,
  Messages,
  MessagesPlaceholderView,
  MessagesInbox,
} from "views";

// Legacy chat component.
//import Chat from "components/chat";

// Components.
import { ErrorBoundary } from "components";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

// Layouts
import { useUser } from "hooks";
import { VerticalLayout } from "layout";
import RouterWrapper from "./router-wrapper";
import SupportListing from "views/support/SupportListing";
import ReferFriends from "views/refer-friends/refer-friends";
import SupportDashboard from "views/support/SupportDashboard";
import getTutorRoutes from "./_get-tutor-routes";
import getLearnerRoutes from "./_get-learner-routes";
import {
  SessionRejectModal,
  TimezoneUpdateErrorModal,
  SessionActionModal,
} from "modules";
import TimezoneUpdatePlaceholder from "views/view-placeholders/view-placeholder-timezone-update";
import { getAutoSelectedTimezone } from "utils";
import { allTimezones } from "react-timezone-select";
import {
  useLearnerProfile,
  useRemoteProfileValue,
  useTutorProfile,
} from "hooks/profile";
// import ViewsWrapper from "./views-wrapper";

function Router() {
  const navigate = useNavigate();
  const location = useLocation();

  const { type: userType } = useUser();

  const profile = useRemoteProfileValue();

  /**
   * Location provided by a modal.
   */
  const modalLocation = location.state && location.state.modalLocation;

  React.useEffect(() => {
    if (
      profile &&
      getAutoSelectedTimezone(allTimezones) !== profile.timezone &&
      profile.is_onboarded == 1 && // eslint-disable-line eqeqeq
      localStorage.getItem("TIMEZONE") === profile.timezone &&
      location.pathname !== "/placeholder/timezone/update" &&
      !location.pathname.startsWith("/onboarding")
    ) {
      // Redirect to timezone update page.
      navigate("/placeholder/timezone/update");
    }
  }, []);

  if (!navigator.onLine) return <ErrorOffline />;

  return (
    <ErrorBoundary>
      <RouterWrapper>
        {/* This enables us to render a whole suite of modals without leaving this suite of routes. */}
        <Routes location={modalLocation || location}>
          {/* <Route element={<ViewsWrapper />}> */}
          <Route exact path="/" element={<Home />} />
          <Route element={<VerticalLayout />}>
            {/* Common Routes */}
            {/* <Route path="/messages" element={<Chat />} /> */}
            <Route path="/messages" element={<Messages />}>
              <Route index element={<MessagesPlaceholderView />} />
              <Route path="user/:receiverId" element={<MessagesInbox />} />
            </Route>
            <Route path="/support" element={<SupportDashboard />} />
            <Route path="/refer-friends" element={<ReferFriends />} />
            <Route path="/notifications" element={<Notifications />} />
            <Route path="/support/tickets" element={<SupportListing />} />
          </Route>

          <Route element={<VerticalLayout />}>
            <Route path="tutor-start" element={<TutorStartPlaceholderView />} />
            {/* Error Routes */}
            <Route path="/400" element={<Error400 />} />
            <Route path="/403" element={<Error403 />} />
            <Route path="/404" element={<Error404 />} />
            <Route path="/440" element={<Error440 />} />
            <Route path="/500" element={<Error500 />} />
            <Route path="/offline" element={<ErrorOffline />} />
            <Route path="*" element={<Error404 />} />
            {/* Placeholder Routes */}
            <Route path="/placeholder">
              <Route index element={<PlaceholderView />} />
              <Route
                path="onboarding"
                element={<OnboardingPlaceholderView />}
              />
              <Route
                path="timezone/update"
                element={<TimezoneUpdatePlaceholder />}
              />
            </Route>
          </Route>

          {/* Tutor Routes */}
          {userType === "tutor" && getTutorRoutes(navigate).map((_) => _)}
          {/* Learner Routes */}
          {userType === "learner" && getLearnerRoutes(navigate).map((_) => _)}
          {/* </Route> */}
        </Routes>
        {modalLocation && (
          <Routes>
            <Route
              path="/modal/error/timezone/update"
              element={<TimezoneUpdateErrorModal />}
            />
            <Route path="/modal/session">
              <Route
                path="cancel"
                element={
                  <SessionActionModal
                    type="cancel"
                    onHide={() => navigate(-1)}
                    {...location.state}
                  />
                }
              />
              <Route
                path="reject"
                element={<SessionRejectModal {...location.state} />}
              />
              <Route
                path="scheduled"
                element={
                  <SessionActionModal
                    type="scheduled"
                    onHide={() => navigate(-1)}
                    {...location.state}
                  />
                }
              />
              <Route
                path="rescheduled"
                element={
                  <SessionActionModal
                    type="rescheduled"
                    onHide={() => navigate(-1)}
                    {...location.state}
                  />
                }
              />
            </Route>
          </Routes>
        )}
      </RouterWrapper>
    </ErrorBoundary>
  );
}
export default Router;
