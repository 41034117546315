import * as React from "react";

// Utils.
import {
  getCalendarListingIcon,
  removeTimezoneFromTime,
  setCalendarListingStyles,
} from "utils";
import { extend } from "@syncfusion/ej2-base";
import { useDispatch, useSelector } from "react-redux";
import {
  eventTemplate,
  footerTemplate,
  onCellClick,
} from "./tutoring-learner-schedule-functions";

// Components.
import {
  Day,
  Week,
  Month,
  Inject,
  WorkWeek,
  ScheduleComponent,
  EventSettingsModel,
} from "@syncfusion/ej2-react-schedule";
import { Button } from "reactstrap";
import SimpleBar from "simplebar-react";
import moment from "moment";
import { Loader, SassyAlert } from "components";

// Features.
import TutoringLearnerScheduleFilters from "./tutoring-learner-schedule-filters";

// Static.
import { ReduxStore, sessionStatusTypes } from "constants/";
import { fetchLearnerCalendarSessions } from "store";
import { useApiEffect } from "hooks";
import { onNavigate } from "views/tutoring/tutoring-tutor/tutoring-tutor-schedule/tutoring-tutor-schedule-functions";
import ToastFactory from "utils/toast-factory";

import { useStaticDataV3 } from "hooks/static-data";
import { messages } from "constants/strings";
import { useUserProfile } from "hooks/profile";
import { useNavigate } from "react-router";
import "assets/scss/views/tutoring-schedule.scss";

/**
 * @author Abdullah-Sajjad026
 */
function TutoringLearnerSchedule() {
  const { profile } = useUserProfile();
  const toastFactory = ToastFactory();
  const navigate = useNavigate();
  const dispatch: (dispatch: any) => void = useDispatch();
  // const {tourState, renderTour} = useTour();

  const [showFilters, setShowFilters] = React.useState<boolean>(false);
  const [fetchNonFiltered, setFetchNonFiltered] = React.useState(1);
  // const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);

  const calendarNavigation = React.useRef<{
    end_date: string;
    start_date: string;
    viewMode: "Day" | "Week" | "WorkWeek" | "Month";
  }>({
    viewMode: "Week",
    start_date: moment().startOf("week").format("YYYY-MM-DD"),
    end_date: moment().endOf("week").format("YYYY-MM-DD"),
  });
  const { start_date, end_date } = calendarNavigation.current;

  const { subjects, getStaticData } = useStaticDataV3(["subjects"]);
  const learnerCalendarSessions = useSelector(
    (state: ReduxStore) => state.learnerTutoring.calendarSessions
  );

  useApiEffect(() => {
    dispatch(
      fetchLearnerCalendarSessions({
        start_date,
        end_date,
        onSuccess: () => {},
        onFailure: (error: { message: string }) => {
          if (error.message.startsWith("Server error")) {
            toastFactory.dismiss();
            return navigate("/500");
          } else toastFactory.error(error.message);
        },
      })
    );
  }, [fetchNonFiltered, end_date]);

  // React.useEffect(() => {
  //   document.querySelector(".e-current-time")![0]?.scrollIntoView();
  // }, []);

  const calendarRef = React.useRef();

  const data = extend(
    [],
    learnerCalendarSessions.data.map((s) => ({
      ...s,
      Subject: getStaticData(s.SubjectId, subjects.data),
      // Apply UTC as the timezone per event.
      EndTimezone: "UTC",
      StartTimezone: "UTC",
      // Adjust the start-time according to the applied UTC. timezone.
      StartTime: removeTimezoneFromTime(s.StartTime, profile.timezone),
      EndTime: removeTimezoneFromTime(s.EndTime, profile.timezone),
    })),
    undefined,
    true
  );

  // Effect: Render tour.
  // React.useEffect(() => {
  //   renderTour("/tour/1-1-tutoring/sessions/learner");
  // }, [renderTour]);

  // if (tourState === "loading") return <Loader />;

  return (
    <article>
      <div className="px-4 py-3">
        <div className="d-flex align-items-start flex-wrap gap-2">
          <Button
            onClick={() => {
              setShowFilters((prevState) => !prevState);
            }}
            className="py-2 btn-dim"
            data-tourstep="showCalendarFiltersCta"
          >
            <i className="fas fa-filter text-secondary-dim"></i>
          </Button>
          {/* <Button
            onClick={() => {
              setIsModalOpen(true);
            }}
            className="py-2 "
            variant="outline"
            color="info"
          >
            Find Tutor
          </Button> */}
        </div>

        {showFilters && (
          <TutoringLearnerScheduleFilters
            {...{ end_date, start_date, setFetchNonFiltered }}
          />
        )}
      </div>
      {/* <SimpleBar> */}
      <div data-tourstep="calendar">
        <SimpleBar
          className="schedule-control-section"
          style={{ maxHeight: "75vh" }}
        >
          <div className="col-lg-12 control-section minw-md overflow-auto">
            <div className="control-wrapper">
              {learnerCalendarSessions.state === "erred" ? (
                <SassyAlert
                  className="my-3"
                  color="danger"
                  title={messages.ERROR_LOADING_SESSIONS_CALENDAR}
                  description="Try again or in worst case contact support."
                />
              ) : learnerCalendarSessions.state === "loading" ||
                subjects.state === "loading" ? (
                <Loader />
              ) : (
                learnerCalendarSessions.state === "loaded" && (
                  <>
                    <ScheduleComponent
                      width="100%"
                      timezone={profile.timezone}
                      ref={
                        calendarRef as unknown as React.LegacyRef<ScheduleComponent>
                      }
                      selectedDate={new Date(start_date)}
                      eventSettings={
                        {
                          dataSource: data,
                          template: eventTemplate,
                        } as unknown as EventSettingsModel
                      }
                      allowDragAndDrop={false}
                      allowResizing={false}
                      allowSwiping={false}
                      eventRendered={(arg) =>
                        setCalendarListingStyles(arg, calendarRef)
                      }
                      cellClick={onCellClick}
                      cellDoubleClick={(args) => (args.cancel = true)}
                      popupOpen={(args) => {
                        if (args.type === "Editor") args.cancel = true;
                      }}
                      quickInfoTemplates={{
                        footer: footerTemplate as unknown as string,
                      }}
                      navigating={(args) =>
                        onNavigate(
                          args,
                          calendarNavigation,
                          setFetchNonFiltered
                        )
                      }
                    >
                      <Inject services={[Day, Week, WorkWeek, Month]} />
                    </ScheduleComponent>
                  </>
                )
              )}
            </div>
          </div>
        </SimpleBar>
        {/* </SimpleBar> */}
        <div className="px-4 py-3 d-flex gap-4">
          {sessionStatusTypes.map((c, index) => (
            <div className="d-flex align-items-center" key={index}>
              <span
                className="session-type-icon-box"
                style={{ backgroundColor: `${c.color}` }}
              >
                {getCalendarListingIcon(c.id)}
              </span>
              <p className="ms-2 mb-0">{c.title}</p>
            </div>
          ))}
        </div>
      </div>
    </article>
  );
}

export default TutoringLearnerSchedule;
