import * as React from "react";
import { Button, Col, Row } from "reactstrap";
import { useDispatch } from "react-redux";
import { fetchLearnerCalendarSessions } from "store";
import ToastFactory from "utils/toast-factory";
import useStaticValues from "hooks/use-static-values/use-static-values";
import { StaticField, StaticFieldOption } from "components/static-field";
import FormLabel from "components/forms/form-label";

const TutoringLearnerScheduleFilters = ({
  setFetchNonFiltered,
  start_date,
  end_date,
}: {
  setFetchNonFiltered: React.Dispatch<React.SetStateAction<number>>;
  start_date: string;
  end_date: string;
}): JSX.Element => {
  const toastFactory = ToastFactory();

  // const [curriculums, setCurriculums] = React.useState<StaticFieldOption[]>(
  //   useStaticValues({
  //     key: "curriculums",
  //     values: [],
  //     keyOfId: "id",
  //   })
  // );

  // const [grades, setGrades] = React.useState<StaticFieldOption[]>(
  //   useStaticValues({
  //     key: "grades",
  //     values: [],
  //     keyOfId: "id",
  //   })
  // );

  const [subjects, setSubjects] = React.useState<StaticFieldOption[]>(
    useStaticValues({
      key: "offeredSubjects",
      values: [],
      keyOfId: "id",
    })
  );

  const [statuses, setStatuses] = React.useState<StaticFieldOption[]>(
    useStaticValues({
      key: "requestTypes",
      values: [],
      keyOfId: "id",
    })
  );

  const dispatch: (dispatch: any) => void = useDispatch();

  const getFilteredMeetings = () => {
    toastFactory.loading();

    // const selectedCurriculumsIds = curriculums.map(({value}) =>
    //   parseInt(`${value}`)
    // );
    // const selectedGradesIds = grades.map(({value}) => parseInt(`${value}`));
    const selectedSubjectsIds = subjects.map(({ value }) =>
      parseInt(`${value}`)
    );
    const selectedStatusesIds = statuses.map(({ value }) =>
      parseInt(`${value}`)
    );

    dispatch(
      fetchLearnerCalendarSessions({
        // curriculums: selectedCurriculumsIds,
        // grades: selectedGradesIds,
        subjects: selectedSubjectsIds,
        statuses: selectedStatusesIds,
        start_date,
        end_date,
        onSuccess: () => {
          toastFactory.dismiss();
        },
        onFailure: (error: { message: string }) => {
          toastFactory.error(error.message);
        },
      })
    );
  };

  const removeFilters = () => {
    // setCurriculums([]);
    // setGrades([]);
    setSubjects([]);
    setStatuses([]);
    setFetchNonFiltered((prevState) => prevState + 1);
  };

  return (
    <Row className="d-flex mt-3 g-3">
      {/* <Col xs="12" sm="6" xxl="3">
        <StaticField.Multi
          staticKey="curriculums"
          value={curriculums}
          onChange={setCurriculums}
          maxValues={5}
          closeMenuOnSelect={true}
          hasSuggestions={false}
          slotAbove={<FormLabel className="mb-0">Curricula</FormLabel>}
        />
      </Col> */}
      {/* <Col xs="12" sm="6" xxl="2">
        <StaticField.Multi
          staticKey="grades"
          value={grades}
          onChange={setGrades}
          maxValues={5}
          closeMenuOnSelect={true}
          hasSuggestions={false}
          slotAbove={<FormLabel className="mb-0">Grades</FormLabel>}
        />
      </Col> */}
      <Col xs="12" sm="6" xxl="3">
        <StaticField.Multi
          staticKey="offeredSubjects"
          value={subjects}
          onChange={setSubjects}
          maxValues={5}
          closeMenuOnSelect={true}
          hasSuggestions={false}
          slotAbove={<FormLabel className="mb-0">Subjects</FormLabel>}
        />
      </Col>
      <Col xs="12" sm="6" xxl="2">
        <StaticField.Multi
          staticKey="requestTypes"
          value={statuses}
          onChange={setStatuses}
          maxValues={5}
          closeMenuOnSelect={true}
          hasSuggestions={false}
          slotAbove={<FormLabel className="mb-0">Status</FormLabel>}
        />
      </Col>
      <Col
        className="d-flex justify-content-center mt-3 mt-xxl-0"
        xs="12"
        xxl="2"
      >
        <div className="d-flex align-items-end">
          <Button
            className="py-2 btn-dim bg-white me-3"
            onClick={removeFilters}
          >
            <i className="mdi mdi-refresh text-secondary"></i>
          </Button>
          <Button className="py-2 btn-secondary" onClick={getFilteredMeetings}>
            Apply
          </Button>
        </div>
      </Col>
    </Row>
  );
};

export default TutoringLearnerScheduleFilters;
