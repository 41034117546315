import * as React from "react";
import {Error404} from "views/error";
import {TutorExperienceForm} from "modules/forms";
import {useOutletContext} from "react-router-dom";

// Features.
import {DEFAULT_STATE, getDeserialized} from "./utils";
import TutorExperienceList from "./view-profile-tutor-experiences-list";
import {Button, Col, Row} from "reactstrap";
import {IconPencil} from "@tabler/icons";
import {convertBase64} from "utils";
import {useDispatch} from "react-redux";
import {deleteExperience, storeExperience} from "store/tutordashboard/action";
import {TUTOR_MAX_EXPERIENCES} from "constants";
import {OverlayError} from "components";
import ToastFactory from "utils/toast-factory";
import {messages} from "constants/strings";

/**
 * @author Abdullah-Sajjad026
 */
function TutorProfileExperiencesView(props) {
  const toastFactory = ToastFactory();
  const outletProps = useOutletContext();
  const experiences = props.experiences || outletProps.experiences;

  const dispatch = useDispatch();

  const [{isListEditable, isEditing, formVariant, currentState}, setViewState] =
    React.useState({
      formVariant: "add",
      currentState: DEFAULT_STATE,
      // Editable by default if the experiences don't exist.
      isEditing: !experiences || experiences.length === 0 || props.isEditing,
      isListEditable: props.isListEditable || true,
    });

  function setState(key, value) {
    setViewState((state) => ({...state, [key]: value}));
  }

  // Form actions.

  const onAddExperience = async (experience) => {
    if (experiences.length >= TUTOR_MAX_EXPERIENCES && formVariant === "add") {
      toastFactory.error(
        messages.ERR_LIMIT_REACHED(TUTOR_MAX_EXPERIENCES, "experiences")
      );
      return;
    }
    setState("currentState", DEFAULT_STATE);

    if (Object.keys(experience).includes("id")) {
      experience["experience_id"] = experience.id;
      delete experience.id;
    }
    if (experience.document)
      experience.document = await convertBase64(experience.document);
    toastFactory.loading();

    dispatch(
      storeExperience(
        [
          {
            ...experience,
            country: experience.country.value,
            to: experience.currently_working ? "" : experience.to,
            currently_working: experience.currently_working ? 1 : 0,
          },
        ],
        (data) => {
          toastFactory.dismiss();
          setViewState((state) => ({
            ...state,
            isEditing: props.isEditing ? true : formVariant === "add",
            formVariant: "add",
            currentState: DEFAULT_STATE,
          }));
          document
            .getElementById("experiences-list")
            .scrollIntoView({behavior: "smooth"});
        },
        (error) => {
          toastFactory.error(error.message);
        }
      )
    );
  };
  const onRemoveExperience = (experienceId) => {
    dispatch(
      deleteExperience(experienceId, () => {
        setViewState((state) => ({
          ...state,
          isEditing: true,
        }));
      })
    );
  };

  // Experience actions.
  function onEditExperience(experience) {
    setViewState((state) => ({
      ...state,
      formVariant: "edit",
      currentState: getDeserialized(experience),
    }));

    // Scroll the form into sight.
    document
      .getElementById("experience-form")
      .scrollIntoView({behavior: "smooth"});
  }

  const onCloseForm = () =>
    setViewState((state) => ({
      ...state,
      formVariant: "add",
      currentState: DEFAULT_STATE,
      // Editable by default if the experiences don't exist.
      isEditing: false,
    }));

  const onResetForm = () => {
    setViewState((state) => ({
      ...state,
      formVariant: "add",
      currentState: DEFAULT_STATE,
    }));
  };

  return !experiences ? (
    <Error404 />
  ) : (
    <>
      {isEditing && (
        <section id="experience-form" className="position-relative">
          <TutorExperienceForm
            state={currentState}
            variant={formVariant}
            onSubmit={onAddExperience}
            onReset={onResetForm}
            onCloseForm={onCloseForm}
            allowFormClose={props.allowFormCancel && experiences.length > 0}
          />
          <OverlayError
            isPaddedBy={3}
            condition={
              experiences.length >= TUTOR_MAX_EXPERIENCES &&
              !Object.keys(currentState).includes("id")
            }
            {...(props.allowFormCancel ? {onCloseOverlay: onCloseForm} : {})}
            error={`You can't add more than ${TUTOR_MAX_EXPERIENCES} experiences.`}
            description="You can edit or remove the added ones below."
          />
        </section>
      )}
      <section id="experiences-list">
        <Row>
          <Col {...(isListEditable && !isEditing && {md: "9"})}>
            <TutorExperienceList
              {...{
                isEditing,
                experiences,
                onEditExperience,
                onRemoveExperience,
              }}
            />
          </Col>
          {isListEditable && !isEditing && (
            <Col className="text-md-end ps-4 ps-md-0">
              <Button
                color="secondary"
                onClick={() =>
                  setViewState((state) => ({
                    ...state,
                    isEditing: true,
                  }))
                }
                className=""
              >
                <IconPencil size={16} className="mb-1 me-2" />
                Edit
              </Button>
            </Col>
          )}
        </Row>
      </section>
    </>
  );
}

export default TutorProfileExperiencesView;
