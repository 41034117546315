import React from "react";
import { SassyCard } from "components/cards";

type Props = {
  className?: string;
  children: React.ReactNode;
};

export default function OnboardingTutorContainer({
  children,
  className = "",
}: Props) {
  return (
    <SassyCard as="article" className={className}>
      <SassyCard.Body>{children}</SassyCard.Body>
    </SassyCard>
  );
}
