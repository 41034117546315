import { Link, useNavigate } from "react-router-dom";

/**
 * @typedef NotificationActionConfirmProps
 *
 * @property {boolean} isCompact
 * Whether to show the compact variant or not.
 *
 * @property {string} href
 * An external link to be redirected towards.
 *
 * @property {import("react-router-dom").LinkProps['to']} to
 * An internal link to be redirected towards.
 */

/**
 * The notification action that allows the user to
 * confirm or cancel the notification.
 *
 * @param {NotificationActionConfirmProps} object
 *
 * @returns {JSX.Element} The component.
 * @author kashan-ahmad.
 */
function NotificationActionConfirm({
  isCompact = false,
  href = "",
  to = "",
  onConfirm,
}) {
  const props = {};
  const navigate = useNavigate();

  // Link props.
  if (to) {
    props["onClick"] = (e) => {
      // Stop event bubbling cuz the parent has a listener of it's own.
      e.stopPropagation();

      // Perform the navigation.
      navigate(to);
    };
  }

  // Anchor props.
  if (href) {
    props["onClick"] = (e) => {
      // Stop event bubbling cuz the parent has a listener of it's own.
      e.stopPropagation();

      if (onConfirm) {
        onConfirm();
        return;
      }

      window.open(href, "_blank");
    };
  }

  return (
    <div className="d-flex gap-2 justify-content-end">
      <button
        {...props}
        className={`btn btn-outline-danger ${isCompact ? "flex-grow-1" : ""}`}
      >
        Decline
      </button>
      <button
        {...props}
        className={`btn btn-success ${isCompact ? "flex-grow-1" : ""}`}
      >
        Accept
      </button>
    </div>
  );
}

export default NotificationActionConfirm;
