import React from "react";
import {
  Notifier,
  InfoNotifier,
  FailureNotifier,
  LoadingNotifier,
  SuccessNotifier,
} from "components/notifier";
import { toast as toastify } from "react-toastify";
import { ToastMethod, ToastOptions } from "constants/types";
import { integers } from "constants/config";

type RenderToast = (node: React.ReactNode, options?: ToastOptions) => void;

/**
 * A toast that utilizes the on-boarded toast-making utility.
 */
export default function useToast() {
  function getToastContainer() {
    return document.querySelector(".Toastify__toast-container");
  }

  function hide(container: Element | null) {
    container?.setAttribute("style", "display: none !important");
  }

  function show(container: Element | null) {
    container?.removeAttribute("style");
  }

  function escapeKeyListener(event: KeyboardEvent) {
    if (event.key === "Escape") {
      dismiss();
    }
  }

  function clickListener(event: Event, container: Element | null) {
    // The check is necessary because the event can be triggered by the toast container's children.
    if (event.target === container) {
      dismiss();
    }
  }

  function setEventListeners(container: Element | null) {
    // Dismiss the toast when the user presses the escape key.
    window.addEventListener("keydown", escapeKeyListener);

    // Dismiss the toast when the user clicks on the toast container.
    container?.addEventListener("click", (event) =>
      clickListener(event, container)
    );
  }

  function removeEventListeners(container: Element | null) {
    // Remove the event listeners.
    window.removeEventListener("keydown", escapeKeyListener);
    container?.removeEventListener("click", (event) =>
      clickListener(event, container)
    );
  }

  /**
   * Dismisses all of the currently active toasts.
   */
  const dismiss = () => {
    const container = getToastContainer();

    // Hide the container as well as remove the event listeners.
    hide(container);
    removeEventListeners(container);

    // Dismiss all of the toasts.
    toastify.dismiss();
  };

  /**
   * Displays the toast container.
   */
  const display = () => {
    const container = getToastContainer();

    // Show the container as well as add the event listeners.
    show(container);
    setEventListeners(container);
  };

  /**
   * Display the toast for a configured time and then dismiss it.
   */
  function displayWithTimeout() {
    display();
    setTimeout(dismiss, integers.DONE);
  }

  /**
   * Send a predefined toast message that displays a `loading` text.
   *
   * @param {string | undefined} message
   * A custom loading message. By default, a simple "Loading" text is displayed.
   *
   * @returns {void}
   */
  const loading: ToastMethod = (message, options) => {
    dismiss();

    toastify(
      <LoadingNotifier message={message} children={options?.children} />,
      {
        closeOnClick: false,
        className: options?.size,
        ...(options?.toastId ? { toastId: options.toastId } : {}),
      }
    );

    // The loading notifier doesn't support keyboard events.
    // So, we manually show it since the display function adds the event listeners.
    show(getToastContainer());
  };

  /**
   * Send a predefined toast message that displays an `error` text.
   *
   * @param {string | undefined} message
   * A custom error message. By default, a simple "error" text is displayed.
   *
   * @returns {void}
   */
  const error: ToastMethod = (message, options) => {
    dismiss();

    toastify(
      <FailureNotifier
        closeNotifier={dismiss}
        {...{ message, hasCloseButton: options?.hasCloseButton }}
      >
        {options?.children}
      </FailureNotifier>,
      {
        closeOnClick: false,
        className: options?.size,
        ...(options?.toastId ? { toastId: options.toastId } : {}),
      }
    );

    display();
  };

  /**
   * Send a predefined toast message that displays an `success` text.
   *
   * @param {string | undefined} message
   * A custom success message. By default, a simple "success" text is displayed.
   *
   * @returns {void}
   */
  const success: ToastMethod = (message, options) => {
    dismiss();

    toastify(
      <SuccessNotifier
        closeNotifier={dismiss}
        {...{ message, hasCloseButton: false }}
      >
        {options?.children}
      </SuccessNotifier>,
      {
        closeOnClick: false,
        className: options?.size,
        ...(options?.toastId ? { toastId: options.toastId } : {}),
      }
    );

    displayWithTimeout();
  };

  /**
   * Send a predefined toast message that displays an `info` text.
   *
   * @param {string | undefined} message
   * A custom info message. By default, a simple "info" text is displayed.
   *
   * @returns {void}
   */
  const info: ToastMethod = (message, options) => {
    dismiss();

    toastify(
      <InfoNotifier
        closeNotifier={dismiss}
        {...{ message, hasCloseButton: options?.hasCloseButton }}
      >
        {options?.children}
      </InfoNotifier>,
      {
        closeOnClick: false,
        className: options?.size,
        ...(options?.toastId ? { toastId: options.toastId } : {}),
      }
    );

    display();
  };

  /**
   * Render any component with its own logic in the toast card
   * @param node - The component to render
   * @param options - The options to pass to the toast
   * @returns {void}
   */
  const render: RenderToast = (node, options): void => {
    dismiss();

    toastify(
      <Notifier closeNotifier={dismiss}>
        {node}
        {options?.children}
      </Notifier>,
      {
        closeOnClick: false,
        className: options?.size,
        ...(options?.toastId ? { toastId: options.toastId } : {}),
      }
    );

    display();
  };

  /**
   * Update the message of a toast
   * @param toastId - The id of the toast that needs to be updated
   * @param updateOptions - The options to update the toast with
   *
   * @todo - This is not working as expected. Need to fix it.
   */
  const updateMessage = (
    toastId: string,
    updateOptions: { message: string }
  ) => {
    toastify.update(toastId, {
      render: updateOptions.message,
    });
  };

  return {
    dismiss,
    loading,
    success,
    error,
    info,
    render,

    // updateMessage,
  };
}
