import React from "react";
import {Loader, SassyAlert} from "components";
import {TaskItem} from "modules";
import {ListGroup} from "reactstrap";
import {getTutorProfileUrl, parseMessage} from "utils";
import {useStaticDataV3} from "hooks/static-data";
import {RLearnerTutoring} from "constants/learner-views-routes";

const LearnerAllTasks = ({allTasks: data, state}) => {
  const {
    getStaticData,
    subjects: {state: subjectsState, data: subjectsData},
  } = useStaticDataV3(["subjects"]);

  return [state, subjectsState].includes("erred") ? (
    <SassyAlert title="Oops, couldn't load your tasks." color="danger" />
  ) : [state, subjectsState].includes("loading") ? (
    <Loader />
  ) : (
    <ListGroup className=" gap-4 items-bottom-borders">
      {data.length === 0 ? (
        <SassyAlert
          title={parseMessage("You don't have any tasks.")}
          color="success"
        />
      ) : (
        React.Children.toArray(
          data.map((task, index) => {
            return task.taskType === "session" ? (
              <TaskItem
                type="session"
                userName={task.tutor_name}
                userLink={getTutorProfileUrl(task.tutor_name, task.user_id)}
                taskProductName={getStaticData(task.subject_id, subjectsData)}
                remainingProgress={task.remaining_credits}
                usedProgress={task.no_of_sessions - task.remaining_credits}
                ctaLink={RLearnerTutoring.getTutorAvailableSessionsView(
                  task.user_id
                )}
              />
            ) : (
              <TaskItem
                type="learning-material"
                key={index}
                userName={task.tutor.name}
                userLink={getTutorProfileUrl(task.tutor.name, task.tutor.id)}
                taskProductName={task.title}
                remainingProgress={100 - task.completed_percentage}
                usedProgress={task.completed_percentage}
                ctaLink={`/learning-materials/details/${task.id}/${task.slug}`}
              />
            );
          })
        )
      )}
    </ListGroup>
  );
};

export default LearnerAllTasks;
