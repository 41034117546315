import React from "react";
import { SessionActionModalProps } from "./session-action-modal-types";
import SessionRescheduledModal from "./variants/rescheduled";
import SessionScheduledModal from "./variants/scheduled";
import BaseModal from "modules/modals/base-modal";
import { Modal } from "react-bootstrap";

function SessionActionModal({
  type,
  onHide,
  ...props
}: SessionActionModalProps) {
  const Component =
    {
      scheduled: SessionScheduledModal,
      rescheduled: SessionRescheduledModal,
    }[type!] || SessionScheduledModal;

  return (
    <BaseModal show size="lg" onHide={onHide}>
      <Modal.Header closeButton />

      <Modal.Body className="d-flex flex-column align-items-center gap-3">
        <Component {...props} onHide={onHide} />
      </Modal.Body>
    </BaseModal>
  );
}
export default SessionActionModal;
