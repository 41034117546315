import {generateReducer} from "utils";
import ACTION_TYPES from "./store-static-v2-types";

const INITIAL_STATE = {
  grades: {
    data: [],
    state: "loading",
  },
  degrees: {
    data: [],
    state: "loading",
  },
  goals: {
    date: [],
    state: "loading",
  },
  subjects: {
    data: [],
    state: "loading",
  },
  languages: {
    data: [],
    state: "loading",
  },
  skills: {
    data: [],
    state: "loading",
  },
  curriculums: {
    data: [],
    state: "loading",
  },
  qualifications: {
    data: [],
    state: "loading",
  },
  requestTypes: {
    data: [],
    state: "loading",
  },
  learningStyles: {
    data: [],
    state: "loading",
  },
  materialTypes: {
    data: [],
    state: "loading",
  },
  interests: {
    data: [],
    state: "loading",
  },
};

/**
 * Reducer for the static API.
 *
 * @param {StaticDataV2State} state
 * @param {StaticDataV2Action} action
 *
 * @returns {StaticDataV2State}
 *
 * @author kashan-ahmad
 * @version 0.0.1
 * @changelog
 */
// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, {type, payload}) =>
  generateReducer({
    state,
    payload,
    currentActionType: type,
    actionTypes: ACTION_TYPES,
  });
