import * as React from "react";
import SassySelect from "components/sassy-select";
import { DUMMIES_MONTHS_NUMERIC } from "constants/";
import { SassySelectProps, SelectOption } from "constants/types/types";

/**
 * A React-Select based Month picker component that allows to select month from a select menu.
 * @param {SassySelectProps & {value: {label: string, value: int}}} props
 * Props passed to Month Picker
 *
 * @returns {JSX.Element}
 * @author "Abdullah-Sajjad026"
 */

const MonthPicker = ({
  label,
  value,
  disabled,
  onChange,
  className,
  isMulti = false,
  placeholder = "Month",
  closeMenuOnSelect = true,
  ...props
}: SassySelectProps & { value: SelectOption }): JSX.Element => {
  return (
    <SassySelect
      {...{
        ...props,
        label,
        className,
        disabled,
        onChange,
        isMulti,
        placeholder,
        closeMenuOnSelect,
      }}
      selectedOptions={value}
      options={DUMMIES_MONTHS_NUMERIC}
    />
  );
};

export default MonthPicker;
