import React from "react";
import { ModalHeader } from "../components";

type Props = {
  body?: React.ReactNode;
  footer?: React.ReactNode;
  checkLogout?: boolean;
};

export default function ConfirmActionModal({
  body,
  footer,
  checkLogout,
}: Props) {
  return (
    <>
      <ModalHeader>{checkLogout ? "Are you sure" : "Note"}</ModalHeader>
      {body}
      {footer}
    </>
  );
}
