const LEARNING_MATERIAL_ACTION_TYPES = [
  "SET_LEARNING_MATERIALS",
  "ERRED_LEARNING_MATERIALS",
  "SET_POPULAR_LEARNING_MATERIALS",
  "ERRED_POPULAR_LEARNING_MATERIALS",
  "SET_PURCHASED_LEARNING_MATERIALS",
  "ERRED_PURCHASED_LEARNING_MATERIALS",
  "LOADING_PURCHASED_LEARNING_MATERIALS",
];

export default LEARNING_MATERIAL_ACTION_TYPES;
