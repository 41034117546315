/* eslint-disable eqeqeq */
import React from "react";
import { getAbbrPriceFrom } from "utils";

/**
 * @typedef {Object} ListItemSessionBundleProps
 *
 * @property {string} buttonText
 * @property {(e: React.MouseEventHandler<HTMLButtonElement>) => void} onClick
 */

/**
 * A list item that represents a session.
 *
 * @param {ListItemSessionBundleProps & RawTutoringSession} props
 *
 * @returns {JSX.Element}
 * @author kashan-ahmad
 * @version 0.0.1
 */
function ListItemSessionBundle({
  status,
  total_fee,
  no_of_sessions,
  fee_per_session,
}) {
  const readablePrice = getAbbrPriceFrom(total_fee);
  const readableDiscount = getAbbrPriceFrom(fee_per_session * no_of_sessions);

  return (
    <div
      className={`d-flex justify-content-between align-items-center ${
        status == 1 ? "" : "text-all-disabled text-all-strikethrough"
      }`}
    >
      <div className="d-flex align-items-center">
        <p className="font-size-13 text-secondary m-0">
          {no_of_sessions} {no_of_sessions > 1 ? "sessions" : "session"}
        </p>
      </div>
      <div className="hstack gap-2">
        <div className="hstack gap-1">
          {readableDiscount && readableDiscount !== readablePrice && (
            <div className="fs-6 text-disabled mb-0">
              <del className="text-decoration-line-through">
                {readableDiscount}
              </del>
            </div>
          )}
          <div className="fs-5 fw-bold text-secondary">{readablePrice}</div>
        </div>
      </div>
    </div>
  );
}

export default ListItemSessionBundle;
