/* eslint-disable eqeqeq */
import { useCallback } from "react";

import type {
  SkillsFormState,
  RemoteSkillsFormState,
} from "./skills-form-types";

export default function useSkillsFormAdapter() {
  const serialize = useCallback(
    (state: SkillsFormState): RemoteSkillsFormState => ({
      id: state.skill.value,
      skill: state.skill.value,
      rating: state.rating,
    }),
    []
  );

  return {
    serialize,
  };
}
