import React, {useEffect, useState} from "react";
import {Col, Row} from "reactstrap";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";
import {Select, Loader} from "components";
import {createSelectablesV3} from "utils";
import {useStaticDataV3} from "hooks/static-data";

const LearnerLanguagePreferences = ({state}) => {
  const [selectedLanguages, setSelectedLanguages] = state;
  const [allLanguagesOptions, setAllLanguagesOptions] = useState();

  const {languages, getStaticData} = useStaticDataV3(["languages"]);
  const {data: allLanguages, state: allLanguagesState} = languages;

  const {storedLanguages} = useSelector(
    (state) => state.learnerDashboard.language
  );

  useEffect(() => {
    if (allLanguagesState === "loaded") {
      /* when all languages list has been fetched, then here I am creating react-select options for all languages and 
      for the languages stored in learner's profile to pre-populate react select with stored languages.*/

      setAllLanguagesOptions(createSelectablesV3(allLanguages));

      const storedLanguagesOptions = storedLanguages.map((i) => {
        const languageInfo = getStaticData(i.language_id, allLanguages);
        return {label: languageInfo, value: i.language_id};
      });
      setSelectedLanguages(storedLanguagesOptions);
    }
  }, [allLanguagesState]);

  const handleButtonSelect = (langOption) => {
    const isLimitReached = selectedLanguages.length >= 5;

    if (selectedLanguages.find((i) => i.value == langOption.value)) {
      /*
        here we could call a callback function inside setter function of state with previous state
        but that may cause more memory due to execution of function.
       */
      const updatedLanguages = [
        ...selectedLanguages.filter((i) => i.value != langOption.value),
      ];
      setSelectedLanguages(updatedLanguages);
    } else {
      if (!isLimitReached) {
        const updatedLanguages = [langOption, ...selectedLanguages];
        setSelectedLanguages(updatedLanguages);
      }
    }
  };

  const generateLanguagesSuggestions = () =>
    !isEmpty(allLanguagesOptions) &&
    allLanguagesOptions
      .filter((i) => i.label !== "others")
      .slice(0, 5)
      .map((item) => {
        const isSelected = selectedLanguages.filter(
          (i) => i.value == item.value
        );

        return (
          <button
            key={item.id}
            className={`${
              !isEmpty(isSelected) && "border-success bg-white border"
            }  m-1 rounded b-1 preference-btn`}
            onClick={() => handleButtonSelect(item)}
          >
            {item.label}
          </button>
        );
      });

  if (allLanguagesState === "loading") {
    return <Loader />;
  }

  return allLanguagesState === "loaded" ? (
    <Row>
      <div>
        <h5 className="preference-main-title">Preferred languages</h5>
      </div>
      <p className="preference-subtitle">
        We will recommend tutors who can communicate in the below language
      </p>
      <Row>
        <Col md="9" sm="12" xs="12">
          <Row>
            <Col md="7" sm="12" xs="12">
              <span className="searchbar">
                <Select
                  isMulti
                  name="colors"
                  selectedOptions={selectedLanguages}
                  options={allLanguagesOptions}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  placeholder="Choose your language"
                  onSelect={setSelectedLanguages}
                  maxSelections={5}
                />
              </span>
            </Col>
            <Col
              md="5"
              sm="12"
              xs="12"
              className="d-flex align-items-center text-center"
            >
              <span className="preference-max mb-3">(Max 5 languages)</span>
            </Col>
            <Col>{generateLanguagesSuggestions()}</Col>
          </Row>
        </Col>
      </Row>
    </Row>
  ) : null;
};

export default LearnerLanguagePreferences;
