import { IconTrash } from "@tabler/icons";
import { Button } from "react-bootstrap";
import IconButton from "./button-icon";

/**
 * Generic delete button.
 *
 * @param {JSX.IntrinsicElements['button']} props
 *
 * @returns {JSX.Element}
 *
 * @author kashan-ahmad
 * @version 0.0.1
 */
function DeleteButton({ tooltipPosition = "top", ...props }) {
  return (
    <IconButton {...props} aria-label="Remove" variant="outline-danger">
      <IconTrash size={16} />
    </IconButton>
  );
}

export default DeleteButton;
