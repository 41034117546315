import React from "react";
import {Loader} from "components";

const View = React.lazy(() => import("./error-440.js"));

const Error440 = (props) => (
  <React.Suspense fallback={<Loader />}>
    <View {...props} />
  </React.Suspense>
);

export default Error440;
