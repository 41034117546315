import React from "react";
import { SassyCard } from "components/cards";

export default function OnboardingTutorTitle({
  children,
  className = "",
  ...props
}: React.ComponentProps<typeof SassyCard.Title>) {
  return (
    <SassyCard.Title
      {...props}
      className={`d-flex align-items-center ${className}`}
    >
      {children}
    </SassyCard.Title>
  );
}
