import React from "react";
import {IconCheck} from "@tabler/icons";
import {Icon} from "components";
import SessionConductInfo from "components/session-conduct-info";
import {SessionActionModalProps} from "../session-action-modal-types";

function SessionRescheduledModal({
  isOpen,
  toggle,
  userName,
  userPicture,
  startTime,
  endTime,
  subjectName,
  date,
}: Omit<SessionActionModalProps, "type">) {
  return (
    <>
      <div className="d-flex flex-column align-items-center">
        <Icon.Rounded
          size="lg"
          tag="span"
          className="bg-soft bg-success text-success"
        >
          <IconCheck />
        </Icon.Rounded>
        <h3 className="fs-4 mb-0">Success!</h3>
      </div>
      <p className="fs-5 mb-0 text-center">
        Your session has been successfully rescheduled
      </p>
      <SessionConductInfo
        userName={userName}
        userImage={userPicture}
        startTime={startTime}
        endTime={endTime}
        date={date}
        subjectName={subjectName}
      />
    </>
  );
}

export default SessionRescheduledModal;
