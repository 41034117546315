import React from "react";
import {Loader} from "components";

const View = React.lazy(() => import("./tutoring-learner-landing"));

const TutoringLearnerLanding = (props) => (
  <React.Suspense fallback={<Loader />}>
    <View {...props} />
  </React.Suspense>
);

export default TutoringLearnerLanding;
