import React from "react";
import Label from "react-bootstrap/FormLabel";
import type {FormLabelProps} from "react-bootstrap";

export default function FormLabel({
  className = "",
  children,
  hint,
  ...props
}: FormLabelProps & {hint?: React.ReactNode}) {
  return (
    <Label
      {...props}
      className={`text-blue d-flex align-items-center ${className}`}
    >
      {children} &nbsp;
      {hint}
    </Label>
  );
}
