import { SassyForm } from "components";
import { MAX_LIMIT_DESCRIPTIONS } from "constants";
import { MAX_LIMIT_TITLES } from "constants";
import { Formik } from "formik";
import { IconCircleCheck } from "@tabler/icons";
import { Button, CardTitle, Col, Row } from "reactstrap";
import ALLOWED_FORMATS from "./form-tutor-certificate-formats";
import tutorCertificateFormValidationSchema from "./form-tutor-certificate-schema";
import { boxStyles } from "constants/config";

const TutorCertificateForm = ({
  state,
  variant,
  onAddCertificate,
  onCloseForm,
  onReset,
  allowFormClose = true,
  ...props
}) => {
  function onSubmitListener(values, { resetForm }) {
    onAddCertificate(values);
    resetForm();
  }

  return (
    <Formik
      enableReinitialize
      initialValues={state}
      onSubmit={onSubmitListener}
      validationSchema={tutorCertificateFormValidationSchema}
    >
      {({ values, handleChange, setFieldTouched }) => (
        <SassyForm className="vstack gap-3" data-testid="certificatesForm">
          <header className="hstack justify-content-between">
            <CardTitle className="text-capitalize">
              {variant} Certificate
            </CardTitle>
          </header>
          <Row>
            <Col sm={6}>
              <SassyForm.Input
                name="certificate_name"
                label="Certificate Name"
                placeholder="Enter a certificate name"
                maxLength={MAX_LIMIT_TITLES}
              />
            </Col>
            <Col sm={6}>
              <SassyForm.Input
                name="issuing_organization"
                label="Issuing Organization"
                placeholder="Enter an organization name"
                maxLength={MAX_LIMIT_TITLES}
              />
            </Col>
            <Col sm={6}>
              <SassyForm.Input
                name="issue_date"
                type="date"
                label="Issue Date"
                placeholder="Select an issuing date"
              />
            </Col>
            <Col sm={6}>
              <SassyForm.File
                maxSize={2}
                name="document"
                accept={ALLOWED_FORMATS.join(",")}
                label={
                  <div className="d-flex gap-1">
                    Upload Certificate
                    <span className="text-lowercase">(jpg, png, pdf)</span>
                  </div>
                }
                {...{ values, handleChange, setFieldTouched }}
              />
              {state.fileName && (
                <p>
                  <span className="fw-bold">Existing Document: </span>{" "}
                  <span>{state.fileName?.substring(0, 20)} ... </span>
                </p>
              )}
            </Col>
            <Col xs={12}>
              <SassyForm.Input
                maxLength={MAX_LIMIT_DESCRIPTIONS}
                type="textarea"
                name="description"
                label="Describe your learnings and other details"
                placeholder="Enter the details"
                value={values.description}
              />
              {/* <div className="fs-6 text-end mt-1 mb-2">
                {values.description ? values.description.length : 0}/
                {MAX_BIO_CHARACTERS}
              </div> */}
            </Col>

            <Col
              xs="12"
              className={`d-flex align-items-center justify-content-end mt-3 ${boxStyles.xGap}`}
            >
              {allowFormClose && variant === "add" && (
                <Button
                  outline
                  type="button"
                  color="secondary"
                  onClick={onCloseForm}
                  data-testid="doneButton"
                >
                  Cancel
                </Button>
              )}
              {variant === "edit" && onReset && (
                <Button
                  outline
                  type="reset"
                  color="secondary"
                  onClick={onReset}
                  data-testid="cancelButton"
                >
                  Cancel Editing
                </Button>
              )}
              <Button type="submit" data-testid="submitButton">
                Save
              </Button>
            </Col>
          </Row>
        </SassyForm>
      )}
    </Formik>
  );
};

export default TutorCertificateForm;
