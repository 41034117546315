import React from "react";
import { ModalHeader } from "../components";

type Props = {
  body?: React.ReactNode;
  footer?: React.ReactNode;
};

export default function ConfirmDeletionModal({ body, footer }: Props) {
  return (
    <>
      <ModalHeader>Confirm Deletion</ModalHeader>
      {body}
      {footer}
    </>
  );
}
