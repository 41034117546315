import React from "react";
import { Label } from "components/";
import { MeetingStatusTitle } from "../constants";

type SessionStatusProps = (
  | {
      requestStatusId: 1;
      meetingStatusId: undefined;
    }
  | {
      requestStatusId: 2 | 3 | 4 | 5;
      meetingStatusId: number;
    }
) & {
  meetingStatusTitle: MeetingStatusTitle;

  isExpired?: boolean;
};

function SessionStatus({
  requestStatusId,
  meetingStatusId,
  meetingStatusTitle,
  isExpired,
}: SessionStatusProps) {
  // a hack to make sure that the meetingStatusTitle is a string due to some inconsistency in the API at the moment that is being worked on
  const _meetingStatusTitle =
    typeof meetingStatusTitle === "number"
      ? `${meetingStatusTitle}`
      : meetingStatusTitle?.toLowerCase();

  return requestStatusId === 5 ? (
    <Label tag="span" className="" color="danger">
      Cancelled
    </Label>
  ) : requestStatusId === 3 ? (
    <Label tag="span" className="" color="danger">
      Rejected
    </Label>
  ) : requestStatusId === 1 ? (
    <Label tag="span" className="" color="warning">
      Not Accepted
    </Label>
  ) : (
    <Label
      tag="span"
      className=""
      color={
        ["completed", "scheduled"].includes(_meetingStatusTitle)
          ? // meetingStatusId === 2 || (!isExpired && meetingStatusId === 4)
            "success"
          : [
              "missed by you",
              "missed by tutor",
              "missed by learner",
              "unattended",
            ].includes(_meetingStatusTitle)
          ? "danger"
          : [
              "joined by tutor",
              "joined by you",
              "joined by learner",
              "session in progress",
            ].includes(_meetingStatusTitle)
          ? "info"
          : "info"
      }
    >
      {/*{!!(isExpired && meetingStatusId === 4)*/}
      {/*    ? "Unattended"*/}
      {/*    : meetingStatusTitle}*/}

      {meetingStatusTitle}
    </Label>
  );
}

export default SessionStatus;
