import * as React from "react";
import { getFileNameFromUrl } from "utils";
import { IconCloudUpload, IconPencil } from "@tabler/icons";
import { withClickableProps } from "components/hoc";
import { IMAGE_FILE_TYPES, VIDEO_FILE_TYPES } from "constants/types";

/**
 * @typedef {Object} FileBoxProps
 *
 * @property {boolean} allowPreview Default=true;
 * Whether to show a preview of the selected file or not.
 * Should be disabled for files other than images and videos.
 *
 * @property {string} previewType Default='image';
 *
 * @property {string | undefined} prompt Default="Select a file"
 * String that prompts the user to select a file.
 *
 * @property {File | string | undefined} selectedFile
 * The currently selected file, usually the one retrived from an input[type=file] field.
 *
 * @property {(e: React.ChangeEvent<HTMLInputElement) => void} onInputFile
 *
 * @property {string} maxSize Default=5MB
 * Maximum file size, accompanied by the size units, such as 5MB, etc.
 *
 * @property {Array<string>} allowedFormats Default=["jpg", "jpeg", "png"]
 *
 * @property {React.ReactElement} children
 * Anything else you'd like inside the box.
 */

/**
 * A box containing a file input and a preview of the uploaded file, if it's an image.
 *
 * @param {FileBoxProps & JSX.IntrinsicElements['label']}
 *
 *@returns {JSX.Element}
 *
 * @author kashan-ahmad
 * @version 0.0.3
 *
 * @changelog
 * - 0.0.3: Added `prompt` property.
 * - 0.0.2: Fixed some bits of UI.
 */
function FileBox({
  id,
  onInputFile,
  selectedFile,
  maxSize = "5MB",
  className = "",
  allowPreview = false,
  allowedFormats = IMAGE_FILE_TYPES,
  previewType = "image",
  prompt = "Select a file",
  children,
  ...props
}) {
  let [previewFile, setPreviewFile] = React.useState("");

  const PreviewComponent = previewType === "video" ? "video" : "img";
  const previewComponentProps =
    previewType === "video"
      ? { controls: true }
      : {
          alt: "Preview of the selected file",
        };

  React.useEffect(() => {
    if (!selectedFile) return;

    // When it's a URL.
    if (typeof selectedFile === "string") {
      setPreviewFile(selectedFile);
      return;
    }

    let fileUrl = "";

    // When it's an actual file.
    if (selectedFile instanceof File) {
      // If it's an image or a video, preview of other formats can't be shown that easily.
      if (
        [...IMAGE_FILE_TYPES, ...VIDEO_FILE_TYPES].some((format) =>
          selectedFile.name.endsWith(format)
        )
      ) {
        fileUrl = URL.createObjectURL(selectedFile);
        setPreviewFile(fileUrl);
      }
    }

    /** @see https://stackoverflow.com/a/57781164 */
    return () => fileUrl && URL.revokeObjectURL(fileUrl);
  }, [selectedFile]);

  return (
    <label
      {...props}
      htmlFor={id}
      style={{ minHeight: "10rem" }}
      title={`Select a file, sized as large as ${maxSize}`}
      className={`p-3 pb-4 position-relative d-flex flex-column justify-content-center align-items-center border border-dashed rounded overflow-hidden cursor-pointer ${className}`}
    >
      <input
        hidden
        id={id}
        name={id}
        type="file"
        accept={allowedFormats.join(",")}
        onInput={(e) => {
          const file = e.target.files[0];

          if (!file) return;

          onInputFile({
            target: {
              name: id,
              value: file,
            },
          });
        }}
      />
      {allowPreview && previewFile ? (
        <>
          <PreviewComponent
            src={previewFile}
            className="position-absolute object-contain top-0 end-0 bottom-0 start-0 w-100 h-100"
            {...previewComponentProps}
          />

          <span className="position-absolute top-0 end-0 p-2 bg-gray-300 text-secondary border  ">
            <IconPencil size={18} />
          </span>
        </>
      ) : (
        <>
          {selectedFile ? (
            <p className="mb-0 text-center" data-testid="selectedFileName">
              <span>Selected file: </span>
              <span className="text-blue">
                {typeof selectedFile === "string"
                  ? getFileNameFromUrl(selectedFile)
                  : selectedFile.name}
              </span>
            </p>
          ) : (
            <p
              data-testid="selectFilePrompt"
              className="mb-0 vstack align-items-center"
            >
              <IconCloudUpload size={32} stroke={1} />
              <span>{prompt}</span>
              <span className="text-blue fs-8">Maximum size: {maxSize}</span>
            </p>
          )}
          {children}
          {allowedFormats && (
            <p className="mb-0 fw-normal text-center mt-3">
              Allowed formats:
              <br />
              <span className="fs-7 text-blue">
                {allowedFormats.join(", ")}
              </span>
            </p>
          )}
        </>
      )}
    </label>
  );
}

export default withClickableProps(FileBox);
