/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
// import {useDispatch, useSelector} from "react-redux";

// import {Avatar, BookSession, Loader, TextField} from "components";
import { useLocation, useParams } from "react-router-dom";
// import moment from "moment";
// import {IconCreditCard} from "@tabler/icons";
// import {getKeyFromArrUsingId} from "utils/utils-functions";
// import SimpleReactValidator from "simple-react-validator";
// import {sendSessionRequestToTutor, fetchTutorAvailableSlots} from "store";

import TutoringLearnerBundlesList from "./tutoring-learner-bundles-list";
// import {useStaticDataV2} from "hooks";
// import {MAX_LIMIT_DESCRIPTIONS} from "constants";
// import {LeadingBadgesList} from "components/lists";
// import ToastFactory from "utils/toast-factory";
// import {messages} from "constants/strings";
// import {ELearnerTutoring} from "constants/enums/ELearnerTutoring";
import { LearnerBookSessionModal } from "modules";
import BackBtn from "components/back-btn";
import { RLearnerTutoring } from "constants/learner-views-routes";

/**
 * @author Abdullah-Sajjad026
 */
const TutoringLearnerNewSession = () => {
  const location = useLocation();
  // const toastFactory = ToastFactory();
  // let {bundle_id} = useParams();
  // bundle_id = parseInt(bundle_id);

  const bundleId = location.state?.bundleId;

  const tutorId = parseInt(useParams().tutor_id);
  const [selectedBundleId, setSelectedBundleId] = useState(undefined);

  /*
    first we are getting bundle_id from params. it may be null or some value.
   */

  // const {curriculums, grades, subjects} = useStaticDataV2([
  //   "curriculums",
  //   "grades",
  //   "subjects",
  // ]);

  // const [
  //   {data: totalCurriculums, state: curriculumsState},
  //   {data: totalGrades, state: gradesState},
  //   {data: totalSubjects, state: subjectsState},
  // ] = [curriculums, grades, subjects];

  // const isStaticDataLoaded =
  //   curriculumsState === "loaded" &&
  //   gradesState === "loaded" &&
  //   subjectsState === "loaded";

  // const {
  //   // totalGrades,
  //   // totalSubjects,
  //   // totalCurriculums,
  //   availableSlots,
  //   orderedBundles,
  // } = useSelector((state) => ({
  //   // totalGrades: state.static.grades,
  //   // totalSubjects: state.static.subjects,
  //   // totalCurriculums: state.static.curriculums,
  //   currentUserDetails: state.auth.userDetails,
  //   availableSlots: state.tutorTutoring.availableSlots,
  //   orderedBundles: state.learnerTutoring.orderedBundles,
  // }));

  // useEffect(() => {
  //   // if (!orderedBundles.loading)
  //   //   document.querySelector("#top-container").scrollIntoView();

  //   // if orderedbundles have been fetched from the API and there is a bundle_id param, means user
  //   // wants to see details of a specific bundle, then we are finding that bundle from the bundles returned by API.
  //   // Currently, API returns default of 4 bundles.
  //   if (orderedBundles.state === "loaded" && bundle_id) {
  //     const foundBundle = orderedBundles.data.find(
  //       (b) => b.bundle_id === bundle_id
  //     );

  //     // if we find a bunlde having the bundle_id same as the bundle_id we got from params, then we are
  //     // proceeding forward else we are just returning and not doing anything here.

  //     if (!foundBundle) {
  //       return;
  //     }

  //     // if a bundle is found, then we are setting that specific bundle as selected bundle and fetching
  //     // that bundle's tutor's slots.
  //     setSelectedBundle(foundBundle);

  //     dispatch(
  //       fetchTutorAvailableSlots({
  //         bundleId: foundBundle?.bundle_id,
  //         range: 7,
  //         date: selectedDate,
  //         tutorId: foundBundle.user_id,
  //         onFailure: (error) => {
  //           toastFactory.error(error.message);
  //         },
  //       })
  //     );
  //   }

  //   // we are refetching tutor's slots whenever a new bundle is selected, or date changes from book session module
  //   // or ordered bundles have been loading.
  // }, [bundle_id, orderedBundles, selectedDate]);

  const toggleBookSessionModal = () => {
    setSelectedBundleId(null);
  };

  return (
    <section className="content">
      <Container fluid className="p-3 p-md-4" id="top-container">
        <BackBtn to={RLearnerTutoring.getSessionsView("upcoming")} />
        <TutoringLearnerBundlesList
          tutorId={tutorId}
          {...{ setSelectedBundleId }}
        />

        {!!selectedBundleId && (
          <LearnerBookSessionModal
            isOpen={!!selectedBundleId}
            toggle={toggleBookSessionModal}
            bundleId={selectedBundleId}
          />
        )}
      </Container>
    </section>
  );
};

export default TutoringLearnerNewSession;
