import React from "react";
import { Button, type ButtonProps } from "react-bootstrap";

export default function SubmitButton({ children, ...props }: ButtonProps) {
  return (
    <Button {...props} variant="secondary" type="submit">
      {children}
    </Button>
  );
}
