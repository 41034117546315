import React from "react";
import {Link} from "react-router-dom";
import {Card, CardBody, Col, Container, Row} from "reactstrap";
import RateSessionImg from "assets/images/rate-session-img.svg";
import RateMaterialImg from "assets/images/rate-material-img.svg";
import {RLearnerTutoring} from "constants/learner-views-routes";

/**
 * @author Abdullah-Sajjad026
 */
const LearnerDashboardReviews = () => {
  return (
    <section className="content h-100">
      <Container fluid className="p-3 p-md-4">
        <Row className="h-100 justify-content-center align-items-center">
          <Col xs="10" md="9" className="text-center p-0">
            <h3 className="fw-bold">
              By rating and reviewing your tutor, you can help them improve
              their craft and enhance the impact on learners' academic lives
            </h3>
            <p className="font-size-13 text-secondary-dim">
              How do you rate your Tutoring Session or Learning Material that
              you purchased?
            </p>
            <div className="d-flex flex-column flex-md-row justify-content-md-center mt-3">
              {/* <Card className="mx-4 mx-md-3">
                <CardBody className="d-flex flex-column align-items-center justify-content-between">
                  <img src={RateMaterialImg} alt="" className="w-100" />
                  <Link className="mt-4 mb-2 btn btn-secondary w-100" to="#">
                    Rate Material
                  </Link>
                </CardBody>
              </Card> */}
              <Card className="mx-4 mx-md-3">
                <CardBody className="d-flex flex-column align-items-center justify-content-between">
                  <img src={RateSessionImg} alt="" className="w-100" />
                  <Link
                    className="mt-4 mb-2 btn btn-secondary w-100"
                    to={RLearnerTutoring.getSessionsView("all")}
                  >
                    Rate Session
                  </Link>
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default LearnerDashboardReviews;
