import React from "react";
import { useEffect } from "react";
import { Row, Col, Modal, ModalBody } from "reactstrap";
import { MyModalHeader } from "..";
import RecentTutor from "./recent-tutor";
import { fetchRecentTutorsList } from "store";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import SimpleBar from "simplebar-react";
import { ReduxStore } from "constants/";
import { useRemoteProfile } from "hooks/profile";
import useStaticValues from "hooks/use-static-values/use-static-values";
import StaticField from "components/static-field/static-field";
import { StaticLabel } from "components/static-label";
import { ComboboxOption } from "components/combobox";
import { getEnvVar } from "utils/utils-env";
import "./find-tutor-modal.scss";

const _generateSearchUrl = (
  selectedSubject: ComboboxOption | undefined,
  selectedCurriculum: ComboboxOption | undefined,
  selectedGrade: ComboboxOption | undefined
) => {
  return `${getEnvVar("WEBSITE_URL")}/tutor/search?${
    !isEmpty(selectedSubject)
      ? `subject=${`${selectedSubject.label}`.replaceAll(" ", "-")}`
      : ""
  }${
    !isEmpty(selectedCurriculum)
      ? `&curriculum=${`${selectedCurriculum.label}`.replaceAll(" ", "-")}`
      : ""
  }${
    !isEmpty(selectedGrade)
      ? `&grade=${`${selectedGrade.label}`.replaceAll(" ", "-")}`
      : ""
  }`;
};

function FindTutorModal({
  isOpen,
  toggleModal,
}: {
  isOpen: boolean;
  toggleModal: () => void;
}) {
  const dispatch: (dispatch: any) => void = useDispatch();

  const { profile } = useRemoteProfile();
  const recentTutorsProps = useSelector(
    (state: ReduxStore) => state.learnerTutoring.recentTutors
  );

  const [subject, setSubject] = React.useState(
    useStaticValues({
      key: "subjects",
      values: [profile.subject[0]],
      keyOfId: "subject_id",
    })[0]
  );

  const [grade, setGrade] = React.useState(
    useStaticValues({
      key: "grades",
      values: [profile.grade[0]],
      keyOfId: "grade_id",
    })[0]
  );

  const [curriculum, setCurriculum] = React.useState(
    useStaticValues({
      key: "curriculums",
      values: [profile.curriculum[0]],
      keyOfId: "curriculum_id",
    })[0]
  );

  useEffect(() => {
    if (recentTutorsProps.state === "loading") {
      dispatch(fetchRecentTutorsList());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recentTutorsProps.state]);

  return (
    <Modal
      isOpen={isOpen}
      toggleModal={toggleModal}
      className="text-secondary"
      size="lg"
    >
      <ModalBody className="p-3 text-secondary">
        <div className="fw-bold">
          <MyModalHeader
            title="Let's get you learning"
            toggleModal={toggleModal}
          />
        </div>
        <main className="main">
          <div>
            {/* <h3 className=" text-secondary">Your Recent Tutors</h3>
            {recentTutorsProps.state === "erred" ? (
              <SassyAlert
                title="There was an error while loading your recent tutors. Try Again."
                color="danger"
              />
            ) : recentTutorsProps.state === "lodaing" ? (
              <Loader isInline />
            ) : recentTutorsProps.data.length === 0 ? (
              <SassyAlert
                title="You don't seem to have a session with any tutor recently."
                color="info"
              />
            ) : (
              <SimpleBar className="recent-tutors text-nowrap">
                {recentTutorsProps.data.map((tutor, index) => (
                  <RecentTutor key={index} tutor={tutor} />
                ))}
              </SimpleBar>
            )} */}

            {recentTutorsProps.state === "loaded" &&
              recentTutorsProps.data.length > 0 && (
                <div className="mt-4">
                  <h3 className="fw-bold text-secondary">Your Recent Tutors</h3>
                  <SimpleBar className="recent-tutors text-nowrap">
                    {recentTutorsProps.data.map((tutor, index) => (
                      <RecentTutor
                        key={index}
                        tutor={tutor}
                        toggle={toggleModal}
                      />
                    ))}
                  </SimpleBar>
                </div>
              )}
          </div>
        </main>

        <footer className="mt-4">
          <h3 className="mb-0 fw-bold">
            Explore TheTutor.Me to find new tutors
          </h3>
          <div className="my-3">
            <Row className="d-flex my-3">
              <Col xs="12" lg="4">
                <StaticField
                  staticKey="curriculums"
                  value={curriculum}
                  onChange={setCurriculum}
                  closeMenuOnSelect
                  hasSuggestions={false}
                  isClearable
                  slotAbove={
                    <StaticLabel className="mb-0">Curriculum</StaticLabel>
                  }
                />
              </Col>
              <Col xs="12" lg="4">
                <StaticField
                  staticKey="grades"
                  value={grade}
                  onChange={setGrade}
                  closeMenuOnSelect
                  hasSuggestions={false}
                  isClearable
                  slotAbove={<StaticLabel className="mb-0">Grade</StaticLabel>}
                />
              </Col>
              <Col xs="12" lg="4">
                <StaticField
                  staticKey="subjects"
                  value={subject}
                  onChange={setSubject}
                  closeMenuOnSelect
                  hasSuggestions={false}
                  isClearable
                  slotAbove={
                    <StaticLabel className="mb-0">Subject</StaticLabel>
                  }
                />
              </Col>
            </Row>
          </div>
          <div className="text-end">
            <a
              className="btn btn-secondary"
              href={_generateSearchUrl(subject, curriculum, grade)}
            >
              Find the Tutor
            </a>
          </div>
        </footer>
      </ModalBody>
    </Modal>
  );
}

export default FindTutorModal;
