import {Loader, SassyAlert} from "components";
import {RLearnerTutoring} from "constants/learner-views-routes";
import {useStaticDataV3} from "hooks/static-data";
import {TaskItem} from "modules";
import React from "react";
import {ListGroup} from "reactstrap";
import {getTutorProfileUrl, parseMessage} from "utils";

const LearnerSessionTasks = ({sessionTasks}) => {
  const {state, data} = sessionTasks;
  const {
    getStaticData,
    subjects: {state: subjectsState, data: subjectsData},
  } = useStaticDataV3(["subjects"]);

  return [state, subjectsState].includes("erred") ? (
    <SassyAlert
      title={parseMessage("There was a problem loading your sessions")}
      color="danger"
    />
  ) : [state, subjectsState].includes("loading") ? (
    <Loader />
  ) : (
    <ListGroup className=" gap-4 items-bottom-borders">
      {data.length === 0 ? (
        <SassyAlert
          title={parseMessage("No upcoming sessions")}
          color="success"
        />
      ) : (
        data.map((bundle, index) => (
          <TaskItem
            type="session"
            key={index}
            userName={bundle.tutor_name}
            userLink={getTutorProfileUrl(bundle.tutor_name, bundle.user_id)}
            taskProductName={getStaticData(bundle.subject_id, subjectsData)}
            remainingProgress={bundle.remaining_credits}
            usedProgress={bundle.no_of_sessions - bundle.remaining_credits}
            ctaLink={RLearnerTutoring.getTutorAvailableSessionsView(
              bundle.user_id
            )}
          />
        ))
      )}
    </ListGroup>
  );
};

export default LearnerSessionTasks;
