import { Button } from "reactstrap";

/**
 * Common footer for the error views.
 * @returns {JSX.Element}
 */
const ErrorFooter = () => (
  <>
    <a
      href="/"
      className="btn btn-outline-secondary border text-secondary border-secondary me-2"
    >
      Home
    </a>
    <Button
      color="secondary"
      onClick={() => {
        window.location.reload();
      }}
    >
      Reload
    </Button>
  </>
);

export default ErrorFooter;
