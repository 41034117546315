import { generateReducer } from "utils";
import LearningMaterialAdapter from "adapters/learning-materials";
import ACTION_TYPES from "./store-learning-material-action-types";

const INITIAL_STATE = {
  learningMaterials: {
    state: "loading",
    data: {
      records: [],
      currentPage: 1,
      numOfPages: 1,
    },
  },
  popularLearningMaterials: {
    state: "loading",
    data: {
      records: [],
      currentPage: 1,
      numOfPages: 1,
    },
  },
  allPurchasedLearningMaterials: {
    state: "loading",
    data: {
      records: [],
      currentPage: 1,
      numOfPages: 1,
    },
  },
  pendingPurchasedLearningMaterials: {
    state: "loading",
    data: {
      records: [],
      currentPage: 1,
      numOfPages: 1,
    },
  },
  completedPurchasedLearningMaterials: {
    state: "loading",
    data: {
      records: [],
      currentPage: 1,
      numOfPages: 1,
    },
  },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, { type, payload }) =>
  generateReducer({
    state,
    payload,
    currentActionType: type,
    actionTypes: ACTION_TYPES,
    adapter: new LearningMaterialAdapter(),
  });
