/* eslint-disable eqeqeq */
import * as React from "react";
// import { Button } from "react-bootstrap";
import { boxStyles } from "constants/config";
import { Avatar, FormErrorMessage } from "components";
import { Badge, Button } from "react-bootstrap";
import { IconPencil } from "@tabler/icons";

export type ProfilePictureFormProps = {
  /**
   * Serves as the `id`, `name`, and `for` attribute of all the relevant fields.
   */
  id?: string;

  /**
   * The initial source of the image.
   */
  initialValue: string;

  /**
   * The selected image file. Undefined is allowed since it will initially be that way.
   */
  value?: File;

  /**
   * The max size of the image in bytes.
   */
  maxSize: number;

  /**
   * An array of allowed formats.
   */
  allowedFormats?: string[];

  /**
   * Callback for the input's onChange listener.
   *
   * @param file The uploaded file. Empty only when the remove button is clicked.
   *
   * @returns {void}
   */
  onChange: (file?: File) => unknown;
};

export default function ProfilePictureForm({
  value,
  maxSize,
  onChange,
  initialValue,
  id = "profilePictureField",
  allowedFormats = ["image/png", "image/jpeg", "image/jpg"],
}: ProfilePictureFormProps) {
  const [error, setError] = React.useState("");

  /**
   * The preview of the uploaded file.
   * Since the file will initially be empty, it has to be used with a ternary operator.
   */
  const preview = value ? window.URL.createObjectURL(value) : "";

  /**
   * Listener for an avatar's selection by the user.
   */
  function onClickUploadListener(e: React.ChangeEvent<HTMLInputElement>) {
    const file = e.target.files?.[0];

    if (!file) return;

    if (!allowedFormats.includes(file.type)) {
      setError("Only images are allowed");
      return;
    }

    if (file.size > maxSize) {
      setError("Must be smaller than 1 Mb");
      return;
    }

    // Reset the error.
    setError("");

    // Invoke the callback.
    onChange(file);
  }

  return (
    <form
      data-tourstep="profilePhotoField"
      data-testid="profilePhotoParent"
      className={`w-100 position-relative text-center d-flex flex-column align-items-center ${boxStyles.yGap}`}
    >
      <Avatar
        //@ts-ignore
        size="lg"
        alt="Profile Photo"
        src={preview || initialValue}
      />
      <label
        tabIndex={1}
        htmlFor={id}
        title="Upload Profile Photo"
        data-testid="profilePhotoLabel"
        className="btn btn-secondary w-100"
      >
        Upload Photo
      </label>
      <input
        hidden
        id={id}
        name={id}
        type="file"
        data-testid="profilePhotoField"
        onChange={onClickUploadListener}
        placeholder="Upload Profile Picture"
        accept={allowedFormats.join(",")}
      />
      {/* If there's an error. */}
      {error && (
        <FormErrorMessage data-testid="profilePhotoError" message={error} />
      )}
    </form>
  );
}
