import * as React from "react";
import { NavigateFunction, Route } from "react-router-dom";
import { PhoneNumberModal } from "modules";
import { withRouterModal } from "components/hoc";
import { PhoneNumberForm, OtpForm } from "modules/forms";

export default function getPhoneNumberRoutes(
  navigate: NavigateFunction,
  baseRoute: string
) {
  const onCancel = () => navigate(-1);
  const onClose = () => navigate(baseRoute);

  return [
    <Route
      path="mobile"
      element={withRouterModal(PhoneNumberModal, baseRoute)({})}
    >
      <Route index element={<PhoneNumberForm {...{ onCancel, onClose }} />} />
      <Route path="verify" element={<OtpForm {...{ onCancel, onClose }} />} />
    </Route>,
  ];
}
