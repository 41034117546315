import {API_MAIN_URL} from "constants/";
import {apiService} from "service";
import {getAuthHeaders} from "utils";

export const getMessagesUsers =
  ({
    onSuccess,
    onFailure,
  }: {
    onSuccess?: (data: any) => void;
    onFailure?: (err: any) => void;
  }) =>
  async (dispatch) => {
    apiService.get({
      url: `${API_MAIN_URL}/chat/get-connected-users`,
      headers: getAuthHeaders(),
      onSuccess: (res) => {
        dispatch({
          type: "FETCHED_MESSAGES_USERS",
          payload: res.data,
        });
      },
      onFailure: (err) => {
        dispatch({
          type: "ERRED_MESSAGES_USERS",
        });
      },
    });
  };

export const getInboxMessages =
  ({
    receiverId,
    onSuccess,
    onFailure,
  }: {
    receiverId: number;
    onSuccess?: (data: any) => void;
    onFailure?: (err: any) => void;
  }) =>
  async (dispatch) => {
    apiService.get({
      url: `${API_MAIN_URL}/chat/get-messages?friend_id=${receiverId}`,
      headers: getAuthHeaders(),
      onSuccess: (res) => {
        console.log({res});

        onSuccess?.(res.data);
      },
      onFailure: (err) => {
        console.log({err});

        onFailure?.(err);
      },
    });
  };

export const sendNewMessage =
  ({
    file,
    message,
    senderId,
    receiverId,
    onSuccess,
    onFailure,
  }: {
    file?: File;
    receiverId: number;
    senderId: number;
    message: string;
    onSuccess?: (data: any) => void;
    onFailure?: (err: any) => void;
  }) =>
  async (dispatch) => {
    const data = new FormData();
    data.append("sent_to", `${receiverId}`);
    data.append("sent_by", `${senderId}`);
    data.append("message", message);

    apiService.post({
      url: `${API_MAIN_URL}/chat/send-messages`,
      headers: getAuthHeaders(),
      data,
      onSuccess: (res) => {
        onSuccess?.(res.data);
      },
      onFailure: (err) => {
        console.log({err});

        onFailure?.(err);
      },
    });
  };
