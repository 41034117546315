//auth action types

export const REGISTER_SUCCESS ='REGISTER_SUCCESS'
export const REGISTER_FAILED ='REGISTER_FAILED'
export const USER_LOADED ='USER_LOADED'
export const USER_UPDATE ='USER_UPDATE'
export const SET_TOKEN ='SET_TOKEN'

export const LOGIN_SUCCESS ='LOGIN_SUCCESS'
export const LOGIN_FAILED ='LOGIN_FAILED'
export const LOGOUT ='LOGOUT'