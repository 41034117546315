// User Profile - General
export const USERINFO_SUBMISSION = "INFORMATION_SUBMISSION";
export const USERINFO_ERROR = "USERINFO_ERROR";
export const STORE_USERINFO = "STORE_USERINFO";
export const ONBOARDING_STATUS = "ONBOARDING_STATUS";

// User Profile
export const GET_PROFILE_DATA = "GET_PROFILE_DATA";
export const GET_USER_DATA = "GET_USER_DATA";
export const PROFILE_DATA_ERROR = "PROFILE_DATA_ERROR";

///--Languages section---///
export const FETCH_LANGUAGES = "FETCH_LANGUAGES";
export const UPDATE_LANGUAGES = "UPDATE_LANGUAGES";
export const STORE_LANGUAGES = "STORE_LANGUAGES";
export const FETCH_LANGUAGES_ERROR = "FETCH_LANGUAGES_ERROR";

///--Material section---///
export const FETCH_MATERIAL = "FETCH_MATERIAL";
export const FETCH_MATERIAL_ERROR = "FETCH_MATERIAL_ERROR";

///--Post Purchasing Material---///
export const POST_PURCHASE_LEARNING = "POST_PURCHASE_LEARNING";
export const POST_PURCHASE_LEARNING_ERROR = "POST_PURCHASE_LEARNING_ERROR";

///--Goals section---///
export const FETCH_GOALS = "FETCH_GOALS";
export const FETCH_GOALS_ERROR = "FETCH_GOALS_ERROR";
export const UPDATE_GOALS = "UPDATE_GOALS";
export const STORE_GOALS = "STORE_GOALS";

///--Timeslots section---///

export const FETCH_TIMESLOTS = "FETCH_TIMESLOTS";
export const UPDATE_TIMESLOTS = "UPDATE_TIMESLOTS";
export const STORE_TIMESLOTS = "STORE_TIMESLOTS";
export const FETCH_TIMESLOTS_ERROR = "FETCH_TIMESLOTS_ERROR";

///--Learning section---///
export const FETCH_LEARNING_STYLES = "FETCH_LEARNING_STYLES";
export const UPDATE_LEARNING_STYLES = "UPDATE_LEARNING_STYLES";
export const STORE_LEARNING_STYLES = "STORE_LEARNING_STYLES";
export const FETCH_LEARNING_STYLES_ERROR = "FETCH_LEARNING_STYLES_ERROR";

// User Profile - Account

export const STORE_ACCOUNT_INFO = "STORE_ACCOUNT_INFO";
export const ACCOUNT_INFO_ERROR = "ACCOUNT_INFO_ERROR";

// User Profile - Payment
export const STORE_PAYMENT_INFO = "STORE_PAYMENT_INFO";
export const PAYMENT_INFO_ERROR = "PAYMENT_INFO_ERROR";

//Fetch CURRICULUM section
export const FETCH_CURRICULUM = "FETCH_CURRICULUM";
export const UPDATE_CURRICULUM = "UPDATE_CURRICULUM";
export const FETCH_CURRICULUM_ERROR = "FETCH_CURRICULUM_ERROR";

//Fetch Grade section

export const FETCH_GRADES = "FETCH_GRADES";
export const UPDATE_GRADES = "UPDATE_GRADES";
export const FETCH_GRADES_ERROR = "FETCH_GRADES_ERROR";

//Fetch Grade section

export const FETCH_SUBJECTS = "FETCH_SUBJECTS";
export const FETCH_SUBJECTS_ERROR = "FETCH_SUBJECTS_ERROR";

//Fetch INTERESTS section

export const FETCH_INTERESTS = "FETCH_INTERESTS";
export const UPDATE_INTERESTS = "UPDATE_INTERESTS";
export const FETCH_INTERESTS_ERROR = "FETCH_INTERESTS_ERROR";

// Choose Topic section

export const STORE_TOPICS_INFO = "STORE_TOPICS_INFO";
export const TOPICS_INFO_ERROR = "TOPICS_INFO_ERROR";

///--Refereals Section---///
// Refereals
export const FETCH_REFERALS = "FETCH_REFERALS";
export const FETCH_REFERALS_ERROR = "FETCH_REFERALS_ERROR";

// Refereals
export const FETCH_POPULAR_TUTORS = "FETCH_POPULAR_TUTORS";
export const FETCH_POPULAR_TUTORS_ERROR = "FETCH_POPULAR_TUTORS_ERROR";
