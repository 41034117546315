import React from "react";
import {apiService} from "service";

// Utils.
import {API_MAIN_URL} from "constants/env";
import ToastFactory from "utils/toast-factory";
import {useDispatch, useSelector} from "react-redux";
import {getAuthHeaders, getFormDataFromObject} from "utils";

// Types.
import type {RawLearnerProfile} from "constants/types/types-profile";

/**
 * Hook into the profile of a user.
 *
 * @returns
 */
export default function useLearnerProfile() {
  const toast = ToastFactory();
  const dispatch = useDispatch();

  const rawLearnerProfile: RawLearnerProfile = useSelector(
    (store: any) => store.learnerDashboard.userInfo.user
  );

  const setInterests = React.useCallback(
    ({
      gradeIds,
      subjectIds,
      curriculumIds,
    }: {
      gradeIds: Array<number | string>;
      subjectIds: Array<number | string>;
      curriculumIds: Array<number | string>;
    }) => {
      toast.loading();

      const data = getFormDataFromObject({
        grades: JSON.stringify(gradeIds),
        subjects: JSON.stringify(subjectIds),
        curriculums: JSON.stringify(curriculumIds),
      });

      apiService.post({
        data,
        headers: getAuthHeaders(),
        url: `${API_MAIN_URL}/learner/store-interests`,
        onFailure: ({message}) => toast.error(message),
        onSuccess: ({data}) => {
          toast.success();
          dispatch({
            type: "SET_LEARNER_PROFILE",
            payload: {
              grade: data.grades,
              subject: data.subjects,
              curriculum: data.curriculums,
            },
          });
        },
      });
    },
    [toast, dispatch]
  );

  return {profile: rawLearnerProfile, setInterests};
}
