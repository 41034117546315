import React from "react";
import { Avatar } from "components";
import { ListGroupItem } from "reactstrap";

/**
 * @typedef {Object} ListItemUserProps
 *
 * @property {string | undefined} avatarSrc
 *
 * @property {keyof JSX.IntrinsicElements} [itemTag] Default = 'a', HTML tag for
 * the actual list item inside the wrapper.
 *
 * @property {string} [itemClassName] CSS Classes for the actual list item.
 *
 * @property {React.HTMLAttributes<HTMLElement>} [itemProps] HTML attributes for
 * the actual list item.
 *
 * @property {string | undefined} [title]
 * A bold and large title next to the avatar.
 *
 * @property {string | undefined} username
 * The username or email or whatever, a regular sized heading.
 *
 * @property {string | undefined} [description]
 * A small-font description at the very bottom to the other two text fields.
 *
 * @property {React.ReactNode | undefined} [children]
 * The children of the component, if any.
 *
 * @property {React.ReactNode | undefined} [leftWing]
 * A component to be rendered on the left side of the list item.
 *
 * @property {React.ReactNode | undefined} [rightWing]
 * A component to be rendered on the right side of the list item.
 */

/**
 * A simple list item that displays a user's profile picture, name, title, and
 * some description.
 *
 * @param {ListItemUserProps & import("reactstrap").ListGroupItemProps} object
 *
 * @returns {JSX.Element}
 *
 * @author kashan-ahmad
 *
 * @version 0.0.2
 */
const ListItemUser = ({
  title,
  username,
  itemProps,
  avatarSrc,
  description,
  itemClassName = "",
  itemTag: Tag = "li",
  className = "",
  children,
  leftWing,
  rightWing,
  ...props
}) => (
  <ListGroupItem
    {...props}
    className={`border-top-0 border-end-0 border-start-0 ${className}`}
  >
    <Tag
      {...itemProps}
      className={`hstack gap-2 align-items-center rounded text-secondary w-100 ${itemClassName}`}
    >
      {leftWing}
      <Avatar
        src={avatarSrc}
        aria-hidden={true}
        className="p-0 border avatar-sm img-thumbnail object-cover"
      />
      <div className="flex-grow-1 text-start text-truncate">
        {children || (
          <>
            {title && <p className="mb-0 fw-bold fs-5">{title}</p>}
            {username && <p className={`mb-0 fw-bold`}>{username}</p>}
            {description && <p className="fs-8 mb-0">{description}</p>}
          </>
        )}
      </div>
      {rightWing && <div className="ms-auto flex-shrink-0">{rightWing}</div>}
    </Tag>
  </ListGroupItem>
);

export default ListItemUser;
