/**
 * Parse a message according to the norms of the app.
 *
 * @param {string} message
 *
 * @return {string} The parsed string.
 */
export function parseMessage(message) {
  let parsedMessage = message;

  // Strip fullstops(.)
  if (message.charAt(message.length - 1) === ".")
    parsedMessage = message.slice(0, message.length - 1);

  return parsedMessage;
}

/**
 * Makes a word plural or singular based on a specified criteria.
 *
 * @param {string} singularString The singular string to be dealt with.
 * @param {number} numOfItems The number of items to check the singularity with.
 *
 * @returns {string} The adjusted string.
 */
export function makePluralIf(singularString, numOfItems) {
  if (numOfItems > 1) return `${singularString}s`;

  return singularString;
}
