import {TutorBankAdd} from "modules";
import {Link, useNavigate} from "react-router-dom";
import {Container} from "reactstrap";

/**
 * @author Abdullah-Sajjad026
 */
const EarningsAddBank = () => {
  const navigate = useNavigate();

  return (
    // <section className="content">
    //   <Breadcrumb
    //     parentRouteName="Earnings"
    //     parentRoutePath="/earnings"
    //     activeRouteName="Bank Add"
    //   />
    <Container fluid>
      <TutorBankAdd
        afterSuccess={() => navigate("/earnings")}
        afterFailure={() => {}}
      />
      <div className="mt-2">
        <Link to="/earnings" className="btn btn-outline-danger">
          Cancel
        </Link>
      </div>
    </Container>
    // </section>
  );
};

export default EarningsAddBank;
