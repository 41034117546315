import React from "react";
import "./select.scss";
import Select from "react-select";

const TimeDropdown = ({
  selectedValue,
  onChange,
  values,
  disabled,
  subKey,
  name,
  index,
  color,
}) => {
  const customStyles = {
    dropdownIndicator: (provided) => ({
      ...provided,
      display: "none",
      borderRight: "none",
    }),
    control: (provided) => ({
      ...provided,
      width: "42px",
      backgroundColor: disabled ? "#ededed" : color,
    }),
  };

  return (
    <Select
      className="fs-8 text-center rounded-1"
      styles={customStyles}
      isSearchable={false}
      value={selectedValue}
      options={values}
      onChange={(e) => onChange(e, index, name, subKey)}
      isMulti={false}
      closeMenuOnSelect
      isClearable={false}
      isDisabled={disabled}
      components={{
        IndicatorSeparator: () => null,
      }}
    />

    // <select
    //   disabled={disabled}
    //   id={id}
    //   style={{
    //     textAlign: "center",
    //     padding: "10px 3px",
    //     width: "30px",
    //     color: "#495057",
    //     backgroundColor: disabled ? "#e9ecef" : "#bffcbf",
    //   }}
    //   className="custom-select fs-8 rounded-1"
    //   value={selectedValue}
    //   onChange={(e) => onChange(e, index, name, subKey)}
    // >
    //   {values?.map?.((item) => (
    //     <option key={item} value={item}>
    //       {item}
    //     </option>
    //   ))}
    // </select>
  );
};

export default TimeDropdown;
