import {
  SET_SESSIONS_COLLECTION,
  ERRED_OFFERED_SESSIONS,
  SET_OFFERED_SESSIONS,
  SET_TUTOR_CALENDAR_LISTINGS,
  ERRED_TUTOR_CALENDAR_LISTINGS,
  SET_CONNECTED_LEARNERS,
  ERRED_SET_CONNECTED_LEARNERS,
  SET_AVAILABLE_SLOTS,
  RESET_AVAILABLE_SLOTS,
  ERRED_AVAILABLE_SLOTS,
  SET_RECENT_ACTIVITIES,
  RESET_RECENT_ACTIVITIES,
  ERRED_RECENT_ACTIVITIES,
} from "./store-tutoring-tutor-types";

const INITIAL_STATE = {
  sessionCollection: [],
  offeredSessions: {
    data: [],
    active: 0,
    page_no: 1,
    number_of_pages: 1,
    isErred: false,
    isLoaded: false,
  },
  connectedLearners: {
    isLoaded: false,
    isErred: false,
    data: [],
    pagination: {
      page: 1,
      total_pages: 1,
      total_records: 1,
    },
  },
  availableSlots: {
    isLoaded: false,
    isErred: false,
    data: [],
  },
  recentActivities: {
    isLoaded: false,
    isErred: false.valueOf,
    data: [],
  },
  calendarListings: {
    data: [],
    isErred: false,
    isLoaded: false,
  },
  upcomingSessions: {
    state: "loading",
    data: [],
    meta: {},
  },
  previousSessions: {
    state: "loading",
    data: {},
  },
  missedSessions: {
    state: "loading",
    data: [],
    meta: {},
  },
};

function tutorTutoringReducer(state = INITIAL_STATE, action) {
  return (
    {
      [SET_SESSIONS_COLLECTION]: {
        ...state,
        sessionCollection: action.payload,
      },
      RESET_SESSIONS_COLLECTION: {
        ...state,
        sessionCollection: [],
      },
      [SET_OFFERED_SESSIONS]: {
        ...state,
        offeredSessions: {
          ...state.offeredSessions,
          ...action.payload,
          isLoaded: true,
          isErred: false,
        },
      },
      [ERRED_OFFERED_SESSIONS]: {
        ...state,
        offeredSessions: {
          ...state.offeredSessions,
          isLoaded: true,
          isErred: true,
        },
      },
      LOADING_OFFERED_SESSIONS: {
        ...state,
        offeredSessions: {
          ...state.offeredSessions,
          isLoaded: false,
          isErred: false,
        },
      },
      [SET_CONNECTED_LEARNERS]: (() => {
        const data = action.payload?.data || [];
        const pagination = action.payload?.pagination || {
          page: 1,
          total_pages: 1,
          total_records: 1,
        };

        return {
          ...state,
          connectedLearners: {
            data,
            pagination,
            isErred: false,
            isLoaded: true,
          },
        };
      })(),
      [ERRED_SET_CONNECTED_LEARNERS]: {
        ...state,
        connectedLearners: {
          ...state.connectedLearners,
          isErred: true,
          isLoaded: true,
        },
      },
      LOADING_CONNECTED_LEARNERS: {
        ...state,
        connectedLearners: {
          ...state.connectedLearners,
          isLoaded: false,
        },
      },
      [SET_AVAILABLE_SLOTS]: {
        ...state,
        availableSlots: {
          isLoaded: true,
          data: action.payload,
        },
      },
      [RESET_AVAILABLE_SLOTS]: {
        ...state,
        availableSlots: {
          isLoaded: false,
          isErred: false,
          data: [],
        },
      },
      [ERRED_AVAILABLE_SLOTS]: {
        ...state,
        availableSlots: {
          isLoaded: true,
          isErred: true,
          data: [],
        },
      },
      [SET_RECENT_ACTIVITIES]: {
        ...state,
        recentActivities: {
          isLoaded: true,
          isErred: false,
          data: action.payload,
        },
      },
      [RESET_RECENT_ACTIVITIES]: {
        ...state,
        recentActivities: {
          isLoaded: false,
          isErred: false,
          data: [],
        },
      },
      [ERRED_RECENT_ACTIVITIES]: {
        ...state,
        recentActivities: {
          isLoaded: true,
          isErred: true,
          data: [],
        },
      },
      [SET_TUTOR_CALENDAR_LISTINGS]: {
        ...state,
        calendarListings: {
          data: action.payload,
          isErred: false,
          isLoaded: true,
        },
      },
      [ERRED_TUTOR_CALENDAR_LISTINGS]: {
        ...state,
        calendarListings: {
          isErred: true,
          isLoaded: true,
        },
      },
      SET_TUTOR_UPCOMING_SESSIONS: {
        ...state,
        upcomingSessions: {
          state: "loaded",
          data: action.payload?.sessions,
          meta: action.payload?.meta,
        },
      },
      RESET_TUTOR_UPCOMING_SESSIONS: {
        ...state,
        upcomingSessions: {
          state: "loading",
          data: [],
          meta: {},
        },
      },
      ERRED_TUTOR_UPCOMING_SESSIONS: {
        ...state,
        upcomingSessions: {
          state: "erred",
          data: [],
        },
      },
      FETCHED_TUTOR_PREVIOUS_SESSIONS: {
        ...state,
        previousSessions: {
          state: "loaded",
          data: action.payload,
        },
      },
      RESET_TUTOR_PREVIOUS_SESSIONS: {
        ...state,
        previousSessions: {
          state: "loading",
          data: {},
        },
      },
      ERRED_TUTOR_PREVIOUS_SESSIONS: {
        ...state,
        previousSessions: {
          state: "erred",
          data: [],
        },
      },
      RESET_TUTOR_MISSED_SESSIONS: {
        ...state,
        missedSessions: {
          state: "loading",
          data: {},
        },
      },
      FETCHED_TUTOR_MISSED_SESSIONS: {
        ...state,
        missedSessions: {
          state: "loaded",
          data: action.payload,
        },
      },
      ERRED_TUTOR_MISSING_SESSIONS: {
        ...state,
        missedSessions: {
          state: "erred",
          data: [],
        },
      },
    }[action.type] || state
  );
}

export default tutorTutoringReducer;
