import React from "react";
import ModalFooter from "../modal-footer";
import { Button } from "react-bootstrap";

type Props = {
  onCancel: () => unknown;
  onDelete: () => unknown;

  cancelButtonText?: string;
  deleteButtonText?: string;
};

export default function DeletionModalFooter({
  onCancel,
  onDelete,
  cancelButtonText,
  deleteButtonText,
}: Props) {
  return (
    <ModalFooter>
      <Button onClick={onCancel} variant="outline-secondary">
        {cancelButtonText || "Cancel"}
      </Button>
      <Button variant="danger" onClick={onDelete}>
        {deleteButtonText || "Delete"}
      </Button>
    </ModalFooter>
  );
}
