import * as React from "react";
import { useUser } from "hooks";
import { Loader } from "components";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useRemoteProfileValue } from "hooks/profile";

function Home() {
  const navigate = useNavigate();
  const { type: userType } = useUser();
  const [params] = useSearchParams();

  // Store.

  const profile = useRemoteProfileValue();

  React.useEffect(() => {
    // Due to some analytical issues that I do not know of, we need to reload the page when a user logs in, which happens with a token being passed as a search parameter.
    if (params.get("token")) window.location.replace("/");

    if (!profile) return;

    profile?.is_onboarded
      ? navigate("/dashboard")
      : userType === "tutor"
      ? navigate("/onboarding/tutor")
      : navigate("/onboarding/learner");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Loader />;
}

export default Home;
