import {
  dobSchema,
  nameSchema,
  titleSchema,
  timezoneSchema,
  languagesSchema,
  mobileNumberSchema,
} from "modules/schemas";
import { object } from "yup";

export default function useBasicInfoFormSchema() {
  return object({
    dob: dobSchema,
    name: nameSchema,
    title: titleSchema,
    timezone: timezoneSchema,
    phone: mobileNumberSchema,
    languages: languagesSchema,
  });
}
