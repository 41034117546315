import {DateTime} from "luxon";

/**
 * A class containing members and methods related to the byte units.
 */
class Bytes {
  ONE_MB = 1048576;

  megaBytes(number: number) {
    return this.ONE_MB * number;
  }

  bytesToMegaBytes(bytes: number) {
    return bytes / this.ONE_MB;
  }

  megaBytesToBytes(megaBytes: number) {
    return megaBytes * this.ONE_MB;
  }
}

/**
 * A class containing members and methods related to the time units.
 */
class Time {
  now: Date;
  ONE_MINUTE = 1000 * 60;
  ONE_HOUR = 60 * this.ONE_MINUTE;
  ONE_DAY = 24 * this.ONE_HOUR;

  constructor() {
    this.now = new Date();
  }

  from(time: string) {
    return new Date(time);
  }

  minutes(number: number) {
    return this.ONE_MINUTE * number;
  }

  hours(number: number) {
    return this.ONE_HOUR * number;
  }

  days(number: number) {
    return this.ONE_DAY * number;
  }

  /**
   * Returns the currently active date-time library.
   */
  getLib() {
    return DateTime;
  }

  /**
   * Returns the currently active date-time library with the provided timezone applied.
   */
  getLibPerZone(timezone: string) {
    // return moment.tz(timezone);

    /* 
    we are getting the current time and zone and then setting the zone to the specified zone.
    It also gives back current time in that zone.
    e.g 
      Local Time: 16:37 Asia/Karachi
      after setting zone, 
      Zoned Time: 20:37 Asia/Tokyo

     */

    return DateTime.local().setZone(timezone);
  }
}

export const bytes = new Bytes();

export const time = new Time();
