import React from "react";
import { Loader } from "components";

const View = React.lazy(() => import("./onboarding-tutor"));

const OnboardingTutor = (props) => (
  <React.Suspense fallback={<Loader />}>
    <View {...props} />
  </React.Suspense>
);

export default OnboardingTutor;
