import { API_MAIN_URL } from "constants/env";
import { StaticDataV3Query } from "constants/types/types-static-data";

/**
 * A record of metadata required to request a static data type.
 */
const staticDataV3Queries: StaticDataV3Query = {
    grades: {
        method: "post",
        key: "grades",
        url: `${API_MAIN_URL}/content/grades-list`,
    },
    degrees: {
        key: "degrees",
        url: `${API_MAIN_URL}/content/degrees-list`,
    },
    goals: {
        method: "post",
        key: "goals",
        url: `${API_MAIN_URL}/content/goals-list`,
    },
    subjects: {
        method: "post",
        key: "subjects",
        url: `${API_MAIN_URL}/content/subjects-list`,
    },
    skills: {
        key: "skills",
        url: `${API_MAIN_URL}/content/skills-list`,
    },
    languages: {
        method: "post",
        key: "languages",
        url: `${API_MAIN_URL}/content/languages-list`,
    },
    curriculums: {
        method: "post",
        key: "curriculums",
        url: `${API_MAIN_URL}/content/curriculums-list`,
    },
    requestTypes: {
        key: "requestTypes",
        url: `${API_MAIN_URL}/tutor/request-types-list`,
    },
    materialTypes: {
        key: "materialTypes",
        url: `${API_MAIN_URL}/content/learning-material-types-list`,
    },
    learningStyles: {
        method: "post",
        key: "learningStyles",
        url: `${API_MAIN_URL}/content/learning-styles`,
    },
    qualifications: {
        key: "qualifications",
        url: `${API_MAIN_URL}/content/qualifications-list`,
    },
    interests: {
        method: "post",
        key: "interests",
        url: `${API_MAIN_URL}/content/interests-list`,
    },

    // currently, the offered resources are located at same endpoint but maybe separated in the future
    offeredSubjects: {
        key: "offeredSubjects",
        headers: true,
        url: `${API_MAIN_URL}/tutor/services-offerings`,
    },
    offeredGrades: {
        key: "offeredGrades",
        headers: true,
        url: `${API_MAIN_URL}/tutor/services-offerings`,
    },
    offeredCurriculums: {
        key: "offeredCurriculums",
        headers: true,
        url: `${API_MAIN_URL}/tutor/services-offerings`,
    },
};

export default staticDataV3Queries;
