import { getEnvInt } from "utils/utils-env";
import { object, string } from "yup";

export default function useSkillsFormSchema() {
  const maxLength = getEnvInt("MAX_BIO_CHARACTERS");

  return object({
    bio: string()
      .required("Bio is required")
      .min(100, "Bio must be at least 100 characters")
      .max(maxLength, `Bio must be less than ${maxLength} characters`),
  });
}
