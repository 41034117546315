/* eslint-disable eqeqeq */
import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Button, Form } from "reactstrap";
import { IconStar, IconX } from "@tabler/icons";
import { connect, useSelector } from "react-redux";
import { storeSkills, deleteSkills } from "store/tutordashboard/action";
import Select from "react-select";
import { Rating, Star } from "@smastrom/react-rating";
import SimpleReactValidator from "simple-react-validator";
import { createSelectablesV3 } from "utils";
import { TUTOR_MAX_SKILLS } from "constants";
import ToastFactory from "utils/toast-factory";
import { messages } from "constants/strings";
import { useStaticDataV3 } from "hooks/static-data";
import { FormErrorMessage, Loader } from "components";
import { ratingStarStyles } from "constants/config";

const AddSkills = (props) => {
  const toastFactory = ToastFactory();
  const [specialty, setSpecialty] = useState("");

  const [areErrorsShown, setAreErrorsShown] = useState(false);

  const [skillData, setSkillData] = useState({
    skill: "",
    rating: 0,
  });

  const {
    getStaticData,
    skills: { data: allSkillsData, state: allSkillsState },
  } = useStaticDataV3(["skills"]);

  const storedSkills = useSelector(
    (state) => state.tutorDashboard.userInfo.user.skills
  );

  const skillsOptions = createSelectablesV3(allSkillsData);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Max limit validation.
    // Minimum rating validation.
    if (
      storedSkills.length >= TUTOR_MAX_SKILLS ||
      skillData.rating < 1 ||
      !specialty
    ) {
      setAreErrorsShown(true);
      return;
    }

    setAreErrorsShown(false);

    const checkAlready = storedSkills.find(
      (item) => item.skill == skillData.skill
    );
    if (checkAlready) {
      toastFactory.error(messages.ERR_DUPLICATE);
    } else {
      props.storeSkills([{ ...skillData }]);
      setSkillData({
        skill: "",
        rating: 0,
      });
    }

    setSpecialty("");
  };

  const removeSkill = (id) => {
    props.deleteSkills(id);
  };

  const selectedDropdownstyle = {
    control: (base, state) => ({
      ...base,
      border: state.isFocused ? 0 : 0,
      boxShadow: state.isFocused ? 0 : 0,
      "&:hover": {
        border: state.isFocused ? 0 : 0,
      },
    }),
  };

  useEffect(() => {
    setSkillData({
      ...skillData,
      skill: specialty.value,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skillData.rating, specialty]);

  if (allSkillsState === "loading") return <Loader />;

  return (
    <section className="w-100">
      <Row>
        <Col xs="12" sm="9" xxl="6">
          <Form data-testid="skillsForm" onSubmit={handleSubmit}>
            <Row className="gy-2">
              <Col md={6}>
                <Select
                  name="specialty"
                  options={skillsOptions}
                  classNamePrefix="select"
                  value={specialty}
                  onChange={setSpecialty}
                  data-testid="skillSelect"
                  placeholder="Select your skills"
                  isDisabled={storedSkills.length >= TUTOR_MAX_SKILLS}
                />
                {areErrorsShown && !specialty && (
                  <FormErrorMessage
                    data-testid="skillError"
                    message="The Skills field is required"
                  />
                )}
              </Col>
              <Col md={6}>
                <div className="hstack align-items-center gap-4 justify-content-between">
                  <Rating
                    style={{ maxWidth: 250, maxHeight: 35 }}
                    value={skillData.rating}
                    itemStyles={ratingStarStyles}
                    data-testid="ratingSelect"
                    onChange={(rating) => {
                      if (storedSkills.length >= TUTOR_MAX_SKILLS) return;
                      else setSkillData({ ...skillData, rating });
                    }}
                  />
                  <Button
                    type="submit"
                    color="secondary"
                    data-testid="submitButton"
                    disabled={storedSkills.length >= TUTOR_MAX_SKILLS}
                  >
                    {props.setIsEdit ? "Add" : "Save"}
                  </Button>
                </div>
                {areErrorsShown && skillData.rating < 1 && (
                  <FormErrorMessage
                    data-testid="ratingError"
                    message="The Rating field is required"
                  />
                )}
              </Col>
            </Row>
          </Form>
          {storedSkills.length >= TUTOR_MAX_SKILLS && (
            <div
              data-testid="skillsLimitError"
              className="alert alert-danger mt-3"
            >
              <div className="fw-bold">
                Can't have more than {TUTOR_MAX_SKILLS} skills.
              </div>
              Maximum number of skills reached, remove existing ones to add
              more.
            </div>
          )}
        </Col>

        {storedSkills && storedSkills.length > 0 && (
          <Col xs={12}>
            <Row className="mt-3 d-flex justify-content-center flex-column">
              <h2 className="skill-title">My Skills</h2>
              <Col
                md="8"
                sm="12"
                xs="12"
                className="my-2 hstack flex-wrap gap-2"
              >
                {storedSkills.map(({ id, skill, rating }) => {
                  return (
                    <div
                      key={id}
                      data-testid={`skill${id}`}
                      className="hstack align-items-center alert alert-primary p-0 ps-2 gap-2 m-0"
                    >
                      {getStaticData(skill, allSkillsData)}
                      <div>
                        <IconStar className="me-1 rating-star" />
                        <span>{rating}/5</span>
                      </div>
                      <button
                        data-testclass="skillRemoveButton"
                        data-testid={`skill${id}RemoveButton`}
                        onClick={() => removeSkill(id)}
                        className="py-1 px-2 border bg-light text-secondary rounded hover:bg-gray-300 focus:bg-gray-300"
                      >
                        <IconX />
                      </button>
                    </div>
                  );
                })}
              </Col>

              {props.setIsEdit && (
                <Col className="mt-4 mb-4 hstack gap-2">
                  <Button
                    outline
                    type="button"
                    color="secondary"
                    onClick={() => props.setIsEdit(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="button"
                    color="secondary"
                    onClick={() => props.setIsEdit(false)}
                  >
                    Save
                  </Button>
                </Col>
              )}
            </Row>
          </Col>
        )}
      </Row>
    </section>
  );
};

const mapToStateProps = (state) => ({});
export default connect(mapToStateProps, {
  deleteSkills,
  storeSkills,
})(AddSkills);
