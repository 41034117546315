import {Loader} from "components";
import SessionConductInfo from "components/session-conduct-info";
import React, {useState} from "react";
import {Button} from "reactstrap";
import {SessionActionModalProps} from "../session-action-modal-types";

function SessionCancelModal({
  onHide,
  userName,
  userPicture,
  subjectName,
  startTime,
  endTime,
  date,
  onSessionCancel,
  onRebook,
}: Omit<SessionActionModalProps, "type">) {
  const [cancelState, setCancelState] = useState<
    "initial" | "loading" | "cancelled"
  >("initial");

  // Are you sure you would like to cancel this session?

  if (cancelState === "loading") return <Loader isInline />;

  return (
    <>
      <p className="fs-5 text-secondary text-center">
        {
          {
            initial: "Are you sure you would like to cancel this session?",
            cancelled: "Your session has been successfully cancelled",
          }[cancelState]
        }
      </p>
      <SessionConductInfo
        userName={userName}
        userImage={userPicture}
        startTime={startTime}
        endTime={endTime}
        date={date}
        subjectName={subjectName}
      />

      <div className="hstack gap-3 ">
        {
          {
            initial: (
              <>
                <Button
                  color="success"
                  className="flex-grow-1"
                  onClick={onHide}
                >
                  No
                </Button>
                <Button
                  color="secondary"
                  outline
                  className="flex-grow-1"
                  onClick={() => onSessionCancel(setCancelState)}
                >
                  Yes
                </Button>
              </>
            ),
            cancelled: (
              <Button
                color="success"
                className="flex-grow-1"
                onClick={onRebook}
              >
                Re-Book
              </Button>
            ),
          }[cancelState]
        }
      </div>
    </>
  );
}

export default SessionCancelModal;
