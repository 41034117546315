import { IconCloudDownload } from "@tabler/icons";
import {
  EditButton,
  IconButton,
  DeleteButton,
  FileTypeIndicator,
} from "components";
import { Row, Col, Input, ListGroupItem } from "reactstrap";

/**
 * @typedef {Object} ListItemFileProps
 *
 * @property {File | string | undefined} file
 *
 * @property {string} description
 *
 * @property {Function | undefined} [onRemove]
 *
 * @property {((file: Object) => void) | undefined} [onEdit]
 *
 * @property {React.ChangeEventHandler<HTMLInputElement> | undefined} [onChange]
 *
 * @property {Function | undefined} [onDownload]
 */

/**
 * A simple list item for a file entry.
 * The component's input fields are disabled unless a change handler is provided.
 *
 * @param {ListItemFileProps & import("reactstrap").ListGroupItemProps} props
 *
 * @returns {JSX.Element}
 *
 * @author kashan-ahmad
 * @version 0.0.1
 */
function ListItemFile({
  file,
  title = "",
  onEdit,
  onRemove,
  onChange,
  onDownload,
  description = "",
  ...props
}) {
  const titlePlaceholder = onChange
    ? "A sleek title is always appreciated."
    : "This file is missing a title.";

  const descriptionPlaceholder = onChange
    ? "Describe the file's contents, briefly."
    : "This file is missing a description.";

  return (
    <ListGroupItem {...props}>
      <Row className="g-2 align-items-center mx-0 w-100">
        <Col xs={1}>
          <FileTypeIndicator fileType={file.type || file.split(".").pop()} />
        </Col>
        <Col xs={4}>
          <Input
            type="text"
            name="title"
            value={title}
            className="w-100"
            disabled={!onChange}
            placeholder={titlePlaceholder}
            onChange={(e) => onChange?.(e)}
          />
        </Col>
        <Col xs={5}>
          <Input
            type="text"
            className="w-100"
            name="description"
            value={description}
            disabled={!onChange}
            placeholder={descriptionPlaceholder}
            onChange={(e) => onChange?.(e)}
          />
        </Col>
        <Col xs={2} className="hstack gap-2 justify-content-end">
          {onEdit && <EditButton onClick={onEdit} />}
          {onDownload && (
            <IconButton onClick={onDownload} aria-label="Download">
              <IconCloudDownload size={16} />
            </IconButton>
          )}
          {onRemove && <DeleteButton onClick={onRemove} />}
        </Col>
      </Row>
    </ListGroupItem>
  );
}

export default ListItemFile;
