import {
  StaticDataV3Action,
  StaticDataV3QueryType,
  StaticDataV3ActionType,
  StaticDataV3Key,
} from "constants/types/types-static-data";
import { Dispatch } from "redux";
import { apiService } from "service";
import { capitalize, getAuthHeaders } from "utils";
import { time } from "utils/si-factory";

function setExpiry() {
  localStorage.setItem("STATIC_EXPIRY", time.now.toString());
}

/**
 * Checks if the cached static data has expired or not.
 */
function isExpired() {
  /**
   * Time right now minus Last cache time = Period b/w cache and right now.
   */
  const timePassed =
    time.now.getMilliseconds() -
    time.from(localStorage.getItem("STATIC_EXPIRY")!).getMilliseconds();

  /**
   * Time passed divided by expiry constraint = Expiry time
   */
  const expiryTime = Math.floor(timePassed / time.days(7));

  // Less than zero when some time remains.
  // Greater than zero when the cache has exceeded expiry constraint.
  return expiryTime > 0;
}

/**
 * Static Data V3.
 *
 * A neutral action handler that fetches any of the static data based on the
 * provided parameters.
 */
const fetchStaticDataV3 =
  ({ url, key, method = "get", headers = false }: StaticDataV3QueryType) =>
  (dispatch: Dispatch<StaticDataV3Action>) => {
    /**
     * The action type is always in UPPER_SNAKE case.
     * @see StaticDataV3ActionType
     */
    const actionType = key.toUpperCase();
    const successActionType = `SET_${actionType}_V3` as StaticDataV3ActionType;
    const failureActionType =
      `ERRED_${actionType}_V3` as StaticDataV3ActionType;

    const cachedData = localStorage.getItem(`${actionType}_V3`);

    // If cached/de-hydrated data exists and it ain't expired.
    if (!isExpired() && cachedData) {
      dispatch({
        type: successActionType,
        payload: {
          key,
          data: JSON.parse(cachedData),
        },
      });
      return;
    }

    // Otherwise, fetch new data.
    apiService[method]({
      url,
      data: {},
      headers: !headers ? {} : getAuthHeaders(),
      onFailure: ({ message }: { message: string }) =>
        dispatch({
          type: failureActionType,
          payload: {
            key,
            error: message as string,
          },
        }),
      onSuccess: ({ data }: { data: any }) => {
        if (!key.includes("offered")) {
          // Cache the data.
          setExpiry();

          // Caching only the resources that are not user specific
          localStorage.setItem(`${actionType}_V3`, JSON.stringify(data));

          // Update the state.
          dispatch({
            payload: { key, data: data },
            type: successActionType,
          });
        } else {
          /*
                  The api endpoint to get offered resources is single and returns all the offered resources in one go.
                  So, it would be better if we can store all the offered resources in one go in the cache.
                  This would reduce the number of api calls and also the number of reducers.
                  Based on the pattern of static v3 resource, offered resources are gonna have 
                  store entities like offeredResource: {contents here}. 
                  also we have action types like SET_OFFEREDRESOURCE_V3
                */
          const offeredResourcesSuccessActions = Object.keys(data).reduce(
            (acc, curr) => {
              acc[`SET_OFFERED${curr.toUpperCase()}_V3`] = data[curr];
              return acc;
            },
            {}
          );

          // Update the state for all the offered resources in one go
          Object.entries(offeredResourcesSuccessActions).forEach(
            ([successActionType, data]) => {
              // making the key like: SET_OFFEREDRESOURCE_V3 -> offeredResource
              const lowerCasedResourceName = successActionType
                .split("_")[1]
                .toLowerCase()
                .replace("offered", "");

              const storeKey = `offered${capitalize(lowerCasedResourceName)}`;

              dispatch({
                payload: { key: storeKey as unknown as StaticDataV3Key, data },
                type: successActionType as unknown as StaticDataV3ActionType,
              });
            }
          );
        }
      },
    });
  };

export default fetchStaticDataV3;
