import { IconBulb, IconCopy } from "@tabler/icons";
import { Avatar, Icon, Loader, SassyAlert } from "components";
import { PAYMENT_HISTORY_STATUS_TYPES } from "constants";
import { API_MAIN_URL } from "constants";
import { messages, transactionStatus } from "constants/strings";
import { useApiEffect } from "hooks";
import { useStaticDataV3 } from "hooks/static-data";
import moment from "moment";
import { useState } from "react";
import { Link } from "react-router-dom";
import { ListGroup, Modal, ModalBody, ModalHeader } from "reactstrap";
import { apiService } from "service";
import { useCopyToClipboard } from "usehooks-ts";
import {
  getAbbrPriceFrom,
  getAuthHeaders,
  getDiscount,
  getTutorProfileUrl,
} from "utils";
import ToastFactory from "utils/toast-factory";

const TransactionDetailsModal = ({ isOpen, toggle, transaction }) => {
  const toastFactory = ToastFactory();
  const [, copy] = useCopyToClipboard();

  const [{ state, data }, setTransactionDetails] = useState({
    state: "loading",
    data: [],
  });

  const toggleModal = () => {
    setTransactionDetails({
      state: "loading",
      data: [],
    });

    toggle?.();
  };

  useApiEffect(() => {
    if (transaction && isOpen) {
      const data = new FormData();
      data.append("ref_id", transaction.ref_id);

      apiService.post({
        url: `${API_MAIN_URL}/payment/transaction-order-detail`,
        data,
        headers: getAuthHeaders(),
        onSuccess: ({ data }) => {
          setTransactionDetails({
            state: "loaded",
            data,
          });
        },
        onFailure: (error) => {
          toastFactory.error(error.message);
          setTransactionDetails({ state: "erred", data: [] });
        },
      });
    }
  }, [transaction, isOpen]);

  const { curriculums, grades, subjects, getStaticData } = useStaticDataV3([
    "curriculums",
    "grades",
    "subjects",
  ]);
  const isStaticDataLoaded =
    curriculums.state === "loaded" &&
    grades.state === "loaded" &&
    subjects.state === "loaded";
  const isStaticDataErred =
    curriculums.state === "erred" ||
    grades.state === "erred" ||
    subjects.state === "erred";

  const statusFormatter = (status) => {
    const { classNames, label } = PAYMENT_HISTORY_STATUS_TYPES.find(
      (TYPE) => TYPE.key === status
    ) || {
      classNames: "bg-success text-white rounded",
      label: transactionStatus?.[status] || status,
    };

    return (
      <span
        className={`fs-8 fw-bold mb-0 p-2 py-1 text-capitalize ${classNames}`}
      >
        {label}
      </span>
    );
  };

  function calculatePercentage(transactionCharges, total, aed) {
    if (total === 0) {
      return `$${(0).toFixed(1)}`;
    }
    const percentage =
      (Number(transactionCharges) * Number(total)) / 100 + Number(aed);
    return `$${percentage.toFixed(1)}`;
  }

  return (
    <Modal isOpen={isOpen} toggle={toggleModal} size="lg">
      {transaction && (
        <>
          <ModalHeader toggle={toggleModal}>
            <h3>
              Transaction Reference ID: #{transaction.ref_id?.substring(0, 5)}
              <Icon
                as="button"
                size="xs"
                role="tooltip"
                aria-label="Copy"
                data-microtip-position="bottom-left"
                data-microtip-size="small"
                className="d-inline-block ms-2 cursor-pointer"
                onClick={() => {
                  copy(transaction.ref_id);
                  toastFactory.success(messages.COPIED);
                }}
              >
                <IconCopy />{" "}
              </Icon>
            </h3>
            <div className="d-flex flex-column flex-sm-row gap-2 font-size-13 list-dots text-muted">
              <p>{data?.session?.length} Items Purchased</p>
              <p>
                {moment(transaction.date, "YYYY-MM-DD").format("DD, MMM YYYY")}
              </p>
              <p>{statusFormatter(transaction.status)}</p>
            </div>
          </ModalHeader>
          <ModalBody>
            {state === "erred" || isStaticDataErred ? (
              <SassyAlert
                color="danger"
                title="We've encountered an error while loading this transaction's details."
                description="Please try again."
              />
            ) : state === "loading" || !isStaticDataLoaded ? (
              <Loader />
            ) : state === "loaded" && isStaticDataLoaded ? (
              <>
                {data.session.length > 0 && (
                  <div className="tutoring-products">
                    <h3 className="fw-bolder">1:1 Tutoring</h3>
                    <ListGroup className="mt-3 gap-4 items-bottom-borders">
                      {data.session.map((p) => (
                        <ListProduct
                          p={p}
                          subject={getStaticData(
                            p.product.subject_id,
                            subjects.data
                          )}
                          grade={getStaticData(
                            p.product.grade_id !== ""
                              ? JSON.parse(p.product.grade_id)?.[0]
                              : p.product.grade_id,
                            grades.data
                          )}
                          curriculum={getStaticData(
                            p.product.curriculum_id !== ""
                              ? JSON.parse(p.product.curriculum_id)?.[0]
                              : p.product.curriculum_id,
                            curriculums.data
                          )}
                        />
                      ))}
                    </ListGroup>
                  </div>
                )}

                {console.log({ data })}

                {/*  Learning Material products */}
                {data.materials.length > 0 && (
                  <div className="material-products mt-4">
                    <h3 className="fw-bolder">Learning Material</h3>
                    <ListGroup className="mt-2 gap-4 items-bottom-borders">
                      {data.materials.map((p) => (
                        <ListProduct
                          p={p}
                          type="material"
                          subject={getStaticData(
                            p.product.subject_id !== ""
                              ? JSON.parse(p.product.subject_id)?.[0]
                              : p.product.subject_id,
                            subjects.data
                          )}
                          grade={getStaticData(
                            p.product.grade_id !== ""
                              ? JSON.parse(p.product.grade_id)?.[0]
                              : p.product.grade_id,
                            grades.data
                          )}
                          curriculum={getStaticData(
                            p.product.curriculum_id !== ""
                              ? JSON.parse(p.product.curriculum_id)?.[0]
                              : p.product.curriculum_id,
                            curriculums.data
                          )}
                        />
                      ))}
                    </ListGroup>
                  </div>
                )}

                <div className="p-2 bg-light-gray rounded-3 d-flex flex-column flex-lg-row justify-content-sm-between align-items-lg-center gap-2">
                  <div className="py-2 px-4 bg-white rounded-3 d-inline-block">
                    <p style={{ fontSize: "0.8rem" }} className="mb-0">
                      <IconBulb size="22" className="text-error me-2" />
                      In case of any problem contact our{" "}
                      <Link
                        to="/support"
                        className="text-underline"
                        style={{ color: "#48B3E0" }}
                      >
                        support team
                      </Link>
                    </p>
                  </div>
                  <div className="d-flex gap-lg-3 justify-content-between">
                    <div>
                      <p className="mb-0 text-secondary">Fee</p>
                      <div className="h5 fw-bold text-secondary mb-0 text-truncate">
                        {getAbbrPriceFrom(
                          data.session.reduce(
                            (acc, curr) => acc + Number(curr.product.price),
                            0
                          ) +
                            data.materials.reduce(
                              (acc, curr) => acc + Number(curr.product.price),
                              0
                            )
                        )}
                      </div>
                    </div>
                    <div>
                      <p className="mb-0 text-secondary">Discount</p>
                      <div className="h5 fw-bold text-secondary mb-0 text-truncate">
                        {getAbbrPriceFrom(
                          data.session.reduce(
                            (acc, curr) =>
                              acc +
                              Number(
                                curr.product.order_detail.voucher_discount
                              ),
                            0
                          ) +
                            data.materials.reduce(
                              (acc, curr) =>
                                acc +
                                Number(
                                  curr.product.order_detail.voucher_discount
                                ),
                              0
                            )
                        )}
                      </div>
                    </div>
                    <div>
                      <p className="mb-0 text-secondary">Charges</p>
                      <div className="h5 fw-bold text-secondary mb-0 text-truncate">
                        {calculatePercentage(
                          data.session.reduce(
                            (acc, curr) =>
                              acc +
                              Number(curr.product.order_detail.processing_fee),
                            0
                          ) +
                            data.materials.reduce(
                              (acc, curr) =>
                                acc +
                                Number(
                                  curr.product.order_detail.processing_fee
                                ),
                              0
                            ),
                          data.session.reduce(
                            (acc, curr) => acc + Number(curr.product.total_fee),
                            0
                          ) +
                            data.materials.reduce(
                              (acc, curr) =>
                                acc + Number(curr.product.total_fee),
                              0
                            ),
                          data.session.reduce(
                            (acc, curr) =>
                              acc +
                              Number(curr.product.order_detail.aed_charge),
                            0
                          ) +
                            data.materials.reduce(
                              (acc, curr) =>
                                acc +
                                Number(curr.product.order_detail.aed_charge),
                              0
                            )
                        )}
                      </div>
                    </div>
                    <div className="text-center">
                      <p className="mb-0 text-secondary">Tax</p>
                      <div className="h5 fw-bold text-secondary mb-0 text-truncate">
                        {getAbbrPriceFrom(
                          Number(
                            data.session.reduce(
                              (acc, curr) =>
                                acc + Number(curr.product.order_detail.tax),
                              0
                            ) +
                              data.materials.reduce(
                                (acc, curr) =>
                                  acc + Number(curr.product.order_detail.tax),
                                0
                              )
                          )
                        )}
                      </div>
                    </div>
                    <div className="text-end">
                      <p className="mb-0 text-secondary">Total</p>
                      <div className="h5 fw-bold text-secondary mb-0 text-truncate">
                        {getAbbrPriceFrom(
                          Number(
                            data.session.reduce(
                              (acc, curr) =>
                                acc +
                                Number(curr.product.order_detail.grandTotal),
                              0
                            )
                          ) +
                            Number(
                              data.materials.reduce(
                                (acc, curr) =>
                                  acc +
                                  Number(curr.product.order_detail.grandTotal),
                                0
                              )
                            )
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {/* <TransactionInvoice /> */}
              </>
            ) : (
              <SassyAlert
                color="danger"
                title="We've encountered an error while loading this transaction's details."
                description="Please try again."
              />
            )}
          </ModalBody>
        </>
      )}
    </Modal>
  );
};

export default TransactionDetailsModal;

const ListProduct = ({ p, type = "session", subject, grade, curriculum }) => {
  return (
    <div className="d-flex flex-column flex-lg-row gap-3 gap-lg-2 justify-content-between align-items-lg-center pb-3">
      <div>
        <div className="d-flex flex-column flex-sm-row align-items-sm-center gap-2">
          <h4 className="mb-0 text-black fw-bold font-size-16">
            {type === "session" ? subject : p.material_title}{" "}
          </h4>
          {type === "session" && (
            <p className="text-black mb-0">
              ({type === "material" && `${subject} |`} {grade} | {curriculum} )
            </p>
          )}
        </div>
        <div className="d-flex align-items-center gap-2 mt-2">
          {type !== "session" && (
            <>
              <p className="text-black mb-0">
                ({type === "material" && subject && `${subject}`}
                {subject && grade && " | "} {grade}
                {grade && curriculum && " | "}
                {curriculum} )
              </p>
              <span className="green-dot" />
            </>
          )}
          <a
            href={getTutorProfileUrl(
              p.product.product_owner.name,
              p.product.product_owner.owner_id
            )}
          >
            <div className="d-flex align-items-center gap-2">
              <Icon size="sm">
                <Avatar
                  src={p.product.product_owner.profile_picture}
                  size=""
                  className="w-100 h-100"
                />
              </Icon>
              <p className="text-secondary mb-0 font-size-15">
                {p.product.product_owner.name}
              </p>
            </div>
          </a>
        </div>
      </div>

      <div className="hstack gap-3 ">
        <div className="pricing-box text-lg-center">
          <h4 className="text-black font-size-14 fw-bold">
            {getAbbrPriceFrom(p.product.total_fee)}
          </h4>
          {p.product.session && (
            <p className="text-secondary">{p.product.session} Sessions</p>
          )}
        </div>
        {/* <Button outline>Request for Refund</Button> */}
      </div>
    </div>
  );
};
