import { IconPencil } from "@tabler/icons";
import { DeleteButton, EditButton } from "components";
import { SassyCard } from "components/cards";
import MaxLimitHint from "components/forms/max-limit-hint";
import { boxStyles, gridStyles } from "constants/config";
import moment from "moment";
import { Button } from "react-bootstrap";
import { CardTitle, Col, Row } from "reactstrap";
import { getEnvVar } from "utils/utils-env";

const formatTime = (time) => time && moment(time).format("MMM DD, YYYY");

const TutorCertificatesList = ({
  isEditing,
  setIsEditing,
  onEditCertificate,
  onRemoveCertificate,
  certificates,
}) => {
  if (!certificates || !certificates.length) return <></>;

  return (
    <>
      <header className="d-flex justify-content-between">
        <h1 className="card-title mb-0 d-flex align-items-center">
          Your Certificates&nbsp;
          <MaxLimitHint limit={getEnvVar("TUTOR_MAX_CERTIFICATIONS")} />
        </h1>
        {!isEditing && (
          <Button
            variant="secondary"
            className={`d-flex align-items-center ${boxStyles.xGap}`}
            onClick={() => setIsEditing(true)}
          >
            <IconPencil size={16} />
            Edit
          </Button>
        )}
      </header>
      <div className="position-relative">
        <Row className={gridStyles.gutters.sm.base}>
          {certificates
            .sort((a, b) => new Date(b.issue_date) - new Date(a.issue_date))
            .map((item, index) => (
              <Col key={index} xs={12} lg={6} xl={4}>
                <SassyCard color="gray">
                  <SassyCard.Body>
                    <Row className={gridStyles.gutters.sm.base}>
                      <Col
                        xs={12}
                        xl={9}
                        className={`d-flex flex-column ${boxStyles.yGap}`}
                      >
                        <div
                          className={`d-flex align-items-center ${boxStyles.xGap}`}
                        >
                          <span>Name:</span>
                          <CardTitle className="mb-0">
                            {item.certificate_name}
                          </CardTitle>
                        </div>
                        <div
                          className={`d-flex align-items-center ${boxStyles.xGap}`}
                        >
                          <span>Issuing Organization: </span>
                          <CardTitle className="mb-0">
                            {item.issuing_organization}
                          </CardTitle>
                        </div>
                        <div
                          className={`d-flex align-items-center ${boxStyles.xGap}`}
                        >
                          <span>Issue Date: </span>
                          <CardTitle className="mb-0">
                            {formatTime(item.issue_date)}
                          </CardTitle>
                        </div>
                      </Col>
                      {isEditing && (
                        <Col xs={12} xl={3}>
                          <footer
                            className={`d-flex justify-content-end ${boxStyles.xGap}`}
                          >
                            {onEditCertificate && (
                              <EditButton
                                onClick={() => onEditCertificate(item)}
                              />
                            )}
                            {onRemoveCertificate && (
                              <DeleteButton
                                onClick={() => onRemoveCertificate(item.id)}
                              />
                            )}
                          </footer>
                        </Col>
                      )}
                    </Row>
                  </SassyCard.Body>
                </SassyCard>
              </Col>
            ))}
        </Row>
      </div>
    </>
  );
};
export default TutorCertificatesList;
