import {
  IconX,
  IconClock,
  IconCheck,
  IconRefresh,
  IconExclamationMark,
  IconRotateClockwise2,
} from "@tabler/icons";
import {hexToRgba} from "./utils-functions";
import {getEnvVar} from "./utils-env";

/**
 * Returns the icon in correspondance to a number.
 * @param {number} requestStatus The number to find an icon against.
 * @returns {JSX.Element}
 *
 * @version 0.0.1
 * @author Abdullah-Sajjad026
 * @changelog
 * - 0.0.1: Initial version.
 */
export const getCalendarListingIcon = (requestStatus) =>
  ({
    1: <IconRefresh />,
    2: <IconCheck />,
    3: <IconExclamationMark />,
    4: <IconRotateClockwise2 />,
    5: <IconX />,
  }[requestStatus] || <IconClock />);

/**
 * Returns the color in correspondance to a number.
 * @param {number} requestStatus The number to find a color against.
 * @returns {string} The hex code of the color.
 *
 * @version 0.0.1
 * @author Abdullah-Sajjad026
 * @changelog
 * - 0.0.1: Initial version.
 */
export const getCalendarListingColor = (requestStatus) =>
  ({
    1: "#B8C7D6",
    2: "#3E6D9C",
    3: "#EA8686",
    4: "#5CDB95",
    5: "#555051",
  }[requestStatus] || "#FFFFFF");

/**
 * A common method to set the styles for a calendar listing
 * of either the tutor or the learner or both.
 *
 * @param {import("@syncfusion/ej2-react-schedule").EventRenderedArgs} listing
 * @param {import("react").MutableRefObject<ScheduleComponent>} ref
 */
export const setCalendarListingStyles = ({data, element}, ref) => {
  let categoryColor = getCalendarListingColor(data.RequestStatus);

  element.style.borderLeft = "2px solid";
  element.style.borderColor = categoryColor;

  if (!element || !categoryColor) {
    return;
  }

  if (ref.current.currentView === "Agenda") {
    element.firstChild.style.borderLeftColor = categoryColor;
    element.firstChild.style.backgroundColor = categoryColor;
  } else {
    element.style.backgroundColor = hexToRgba(categoryColor);
  }
};

export const getMeetingLink = (meetingId, meetingType, landingPoint = "meet") =>
  getEnvVar("JITSI_ENV") === "production"
    ? `${getEnvVar(
        "VIDEO_CALL_TOOL"
      )}/${landingPoint}/${meetingId}?type=${meetingType}&token=${localStorage.getItem(
        "token"
      )}`
    : // it means it is fallback
      `${getEnvVar("WEBSITE_URL")}/meet-with-us/${meetingId}`;
