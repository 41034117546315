import App from "app";
import React from "react";
import { Provider } from "react-redux";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { store, NotificationProvider } from "store";
import { registerLicense } from "@syncfusion/ej2-base";
import { StaticDataV2Provider } from "store/store-static-v2";
import ScrollResetter from "components/scroll-resetter";
import { StaticDataV3Provider } from "store/store-static-v3";
import { ToastContainer, cssTransition } from "react-toastify";

// importing packages related css
import "microtip/microtip.css";
import "@smastrom/react-rating/style.css";

// CSS for the react-phone-input-2 package.
import "react-phone-input-2/lib/style.css";

// Importing shepherd css.
import "shepherd.js/dist/css/shepherd.css";

// Importing index css.
// This is the main css file which overrides some of the css of the application.
import "./index.css";

// Registering syncfusion react scheduler license
registerLicense(process.env.REACT_APP_SYNCFUSION_LICENSE);

ReactDOM.createRoot(document.getElementById("root")).render(
  <>
    <BrowserRouter>
      <ScrollResetter />
      <StaticDataV3Provider>
        <StaticDataV2Provider>
          <Provider store={store}>
            <NotificationProvider>
              <App />
            </NotificationProvider>
          </Provider>
        </StaticDataV2Provider>
      </StaticDataV3Provider>
    </BrowserRouter>
    <ToastContainer
      transition={cssTransition({
        enter: "toastEnter",
        exit: "toastExit",
      })}
      draggable={false}
      autoClose={false}
      closeButton={false}
      hideProgressBar={true}
    />
  </>
);

reportWebVitals();
