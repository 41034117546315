import React from "react";
import { useUser } from "hooks";
import BootstrapTable from "react-bootstrap-table-next";
import SimpleBar from "simplebar-react";
import { getPaymentsTableColumns } from "./module-payments-table-utils";

/**
 * @typedef { Object } PaymentsTableModuleProps
 *
 * @property { string } title
 * @property { PaymentHistory[] } paymentHistory
 * @property { "sessions" | "materials" | "total" } productType
 */

/**
 * Module that generates a table for both the tutor and the learner's payment history.
 *
 * @param { PaymentsTableModuleProps & import("react-bootstrap-table-next").BootstrapTableProps } object
 * @returns {JSX.Element}
 *
 * @author Abdullah-Sajjad026, kashan-ahmad
 * @version 1.0.0
 */
function PaymentsTableModule({
  title,
  productType,
  paymentHistory,
  onViewDetails,
  rowStyles,
  ...props
}) {
  const { type: userType } = useUser();

  // const rowEvents = {
  //   onClick: (e, row, rowIndex) => {
  //     onRowClick?.(row);
  //   },
  // };

  const tableRowStyle = () => ({
    cursor: "default",
    height: "65px",
    ...rowStyles,
  });
  console.log(productType, "kjhkjhjk");
  return (
    <SimpleBar className="overflow-auto">
      <BootstrapTable
        {...props}
        striped={false}
        bordered={false}
        keyField="ref_id"
        data={paymentHistory}
        // rowEvents={rowEvents}
        rowStyle={tableRowStyle}
        columns={getPaymentsTableColumns({
          title,
          userType,
          productType,
          onViewDetails,
          ...props,
        })}
        headerWrapperClasses="table-light"
        classes="table align-middle table-nowrap table-check mb-0"
      />
    </SimpleBar>
  );
}

export default PaymentsTableModule;
