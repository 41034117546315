import React from "react";
import {
  IconChevronDown,
  IconChevronLeft,
  IconChevronRight,
  IconChevronUp,
} from "@tabler/icons";
import {RLearnerTutoring} from "constants/learner-views-routes";
import {Link, useLocation} from "react-router-dom";
import {Button} from "reactstrap";
import {SessionStatusCardRenderers} from "./session-status-card-types";
import {RTutorTutoring} from "constants/tutor-views-routes";

type SessionDetailsButtonProps = {
  renderer: SessionStatusCardRenderers;
  isDetailOpen?: boolean;
  onViewDetails?: () => void;
  sessionId: number | string;
  requestId: number | string;
};

const SessionDetailsButton = ({
  sessionId,
  requestId,
  renderer,
  isDetailOpen,
  onViewDetails,
}: SessionDetailsButtonProps) => {
  // TODO: to be simplified

  // data uniformation for default renderer
  const location = useLocation();

  const sessionDetailsRoute =
    renderer === SessionStatusCardRenderers.Default
      ? RLearnerTutoring.getUpcomingSessionDetailsView(sessionId, requestId)
      : renderer === SessionStatusCardRenderers.TutorDefault
      ? RTutorTutoring.getSessionDetailsView(sessionId, requestId)
      : renderer === SessionStatusCardRenderers.LearnerPreviousDefault
      ? RLearnerTutoring.getPastSessionDetailsView(sessionId, requestId)
      : "";

  const sessionsBaseRoute =
    renderer === SessionStatusCardRenderers.Default
      ? RLearnerTutoring.getSessionsView()
      : renderer === SessionStatusCardRenderers.LearnerPreviousDefault
      ? RLearnerTutoring.getSessionsView()
      : renderer === SessionStatusCardRenderers.TutorDefault
      ? RTutorTutoring.sessionsBaseView
      : "";

  const isOnDetailsPage = location.pathname === sessionDetailsRoute;
  // ends here

  return (
    <>
      {[
        SessionStatusCardRenderers.Default,
        SessionStatusCardRenderers.TutorDefault,
        SessionStatusCardRenderers.LearnerPreviousDefault,
      ].includes(renderer) ? (
        <Link
          to={isOnDetailsPage ? sessionsBaseRoute : sessionDetailsRoute}
          className={`btn ${isOnDetailsPage ? "btn-info" : "btn-outline-info"}`}
        >
          {isOnDetailsPage ? (
            <IconChevronLeft size="19" />
          ) : (
            <IconChevronRight size="19" />
          )}
        </Link>
      ) : [
          SessionStatusCardRenderers.LearnerContainedDetails,
          SessionStatusCardRenderers.TutorContainedDetails,
        ].includes(renderer) ? (
        <Button
          onClick={onViewDetails}
          color="info"
          {...(isDetailOpen ? {} : {outline: true})}
        >
          {isDetailOpen ? (
            <IconChevronUp size="19" />
          ) : (
            <IconChevronDown size="19" />
          )}
        </Button>
      ) : (
        ""
      )}
    </>
  );
};

export default SessionDetailsButton;
