import { DEFAULT_TUTOR_REVIEWS } from "constants/";
import { useApiEffect } from "hooks";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchTutorReviews } from "store/tutordashboard/action";
import { Loader, Pagination, SassyAlert } from "components";
import ReviewBlank from "./review-blank";
import ReviewInfo from "./review-info";
import useTour from "hooks/use-tour/use-tour";

/**
 * @author Abdullah-Sajjad026
 */
function TutorDashboardReviews() {
  // const { tourState, renderTour } = useTour();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const itemsPerPage = DEFAULT_TUTOR_REVIEWS;

  /// if there is any review data, then render ReviewHavingData page and if there's not any then render ReviewBlank page.

  useApiEffect(() => {
    dispatch(fetchTutorReviews({ page, limit: itemsPerPage }));
  }, [page]);

  const { state, data } = useSelector((state) => state.tutorDashboard.reviews);

  // Effect: Render tour.
  // useEffect(() => {
  //   renderTour("/tour/reviews/tutor");
  // }, [renderTour]);

  // if (tourState === "loading") return <Loader />;
  return (
    <section class="content">
      {/* <Breadcrumb parentRouteName="Dashboard" activeRouteName="Review" /> */}
      <div className="p-3">
        {state === "erred" ? (
          <SassyAlert />
        ) : state === "loading" ? (
          <Loader />
        ) : state === "loaded" && data.data.length > 0 ? (
          <>
            <div className="container-fluid p-0">
              <div className="items-bottom-borders d-flex flex-column gap-3 ">
                {data.data.map((r) => (
                  <ReviewInfo key={r.id} review={r} />
                ))}
              </div>
              <div className="mt-3">
                <Pagination
                  type="pages"
                  itemsPerPage={DEFAULT_TUTOR_REVIEWS}
                  totalItems={DEFAULT_TUTOR_REVIEWS * data.number_of_pages}
                  currentPage={page}
                  onChangePage={(newPage) => setPage(newPage)}
                />
              </div>
            </div>
          </>
        ) : (
          <ReviewBlank />
        )}
      </div>
    </section>
  );
}

export default TutorDashboardReviews;
