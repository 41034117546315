import React from "react";
import { Dispatch } from "redux";
import { useDispatch, useSelector } from "react-redux";

// Types.
import type {
  ProfileMenuItem,
  ProfileMenuState,
  ProfileMenuAction,
} from "store/profile-menu";

// Actions.
import { profileMenuAddItem, profileMenuRemoveItem } from "store/profile-menu";

/**
 * Hook to add or remove items from the profile menu.
 * @returns The profile menu state and addItem and removeItem functions.
 * @see {@link ProfileMenuItem}
 * @see {@link ProfileMenuState}
 * @see {@link ProfileMenuAction}
 * @example
 * const { menuItems, addItem, removeItem } = useProfileMenu();
 *
 * addItem({
 *  id: 4,
 *  title: "Settings",
 *  icon: IconSettings,
 * });
 *
 * removeItem(4);
 */
export default function useProfileMenu() {
  const dispatch: Dispatch<ProfileMenuAction> = useDispatch();
  const state: ProfileMenuState = useSelector(
    (state: any) => state.profileMenu
  );

  /**
   * Add an item to the profile menu.
   * @param item The item to add.
   * @example
   * addItem({
   *  id: 4,
   *  title: "Settings",
   *  icon: IconSettings,
   * });
   * @returns void
   * @see {@link ProfileMenuItem}
   * @see {@link ProfileMenuAction}
   */
  const addItem = React.useCallback(
    (item: ProfileMenuItem) => {
      dispatch(profileMenuAddItem(item));
    },
    [dispatch]
  );

  /**
   * Remove an item from the profile menu.
   * @param id The id of the item to remove.
   * @example
   * removeItem(4);
   * @returns void
   * @see {@link ProfileMenuItem}
   * @see {@link ProfileMenuAction}
   */
  const removeItem = React.useCallback(
    (id: ProfileMenuItem["id"]) => {
      dispatch(profileMenuRemoveItem(id));
    },
    [dispatch]
  );

  return {
    ...state,
    addItem,
    removeItem,
  };
}
