import React from "react";
import { boxStyles } from "constants/config";
import { Combobox } from "components/combobox";
import { Suggestions } from "components/suggestions";
import useStaticOptions from "hooks/use-static-options/use-static-options";

// types
import type { BaseStaticFieldProps } from ".";
import type { ComboboxMultiProps } from "components/combobox";

export type StaticMultiFieldProps = BaseStaticFieldProps &
  Omit<ComboboxMultiProps, "options">;

export default function StaticMultiField({
  value = [],
  onChange,
  slotBelow,
  slotAbove,
  staticKey,
  className = "",
  maxValues = 100,
  onBlur,
  excludedValues = [],
  hasSuggestions = true,
  ...props
}: StaticMultiFieldProps) {
  // Create the options.
  const options = useStaticOptions(staticKey).filter(
    (option) => !excludedValues.includes(option.value)
  );

  return (
    <div
      data-testclass="staticField"
      className={`d-flex flex-column ${boxStyles.xGap}`}
    >
      {slotAbove}
      <Combobox.Multi
        {...props}
        {...{ value, options, onChange, maxValues, onBlur }}
      />
      {/*  Only show the suggestions if the value is less than the max. */}
      {value.length < maxValues && hasSuggestions && (
        <Suggestions
          keyToDisplay="label"
          onClickSuggestion={(suggestion) =>
            // Adding the suggestion to the value.
            onChange([...value, suggestion])
          }
          suggestions={options
            // Filtering out the options that are already selected.
            // eslint-disable-next-line eqeqeq
            .filter((option) => !!!value.find((v) => v.value == option.value))
            // Only showing the first 5.
            .slice(0, 5)}
        />
      )}
      {slotBelow}
    </div>
  );
}
