import { Helmet } from "react-helmet";
import { META_DATA } from "../constants";

function ViewHelmet({ item, dynamicItem }) {
  const metaData = item ? META_DATA[item] : dynamicItem;

  return (
    <Helmet>
      <title>{metaData.title}</title>
      <meta
        property="og:title"
        content={metaData.title ? metaData.title : ""}
      />
      <meta
        name="description"
        content={metaData.description ? metaData.description : ""}
      />
      <meta
        name="keywords"
        content={metaData.keywords ? metaData.keywords : ""}
      />
      <meta
        property="og:image"
        content={
          dynamicItem
            ? metaData.cover
            : metaData.cover
            ? window.location.host + metaData.cover
            : ""
        }
      />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={window.location.href} />
      <meta
        property="og:image"
        content={
          dynamicItem
            ? metaData.cover
            : metaData.cover
            ? window.location.host + metaData.cover
            : ""
        }
      />
      <meta property="og:site_name" content="TheTutor.Me" />
      <meta
        property="og:description"
        content={metaData.description ? metaData.description : ""}
      />
      <meta name="twitter:card" content="summary" />
      <meta
        name="twitter:title"
        content={metaData.title ? metaData.title : ""}
      />
      <meta
        name="twitter:description"
        content={metaData.description ? metaData.description : ""}
      />
      <meta itemProp="name" content={metaData.title ? metaData.title : ""} />
      <meta
        itemProp="description"
        content={metaData.description ? metaData.description : ""}
      />
      <meta
        itemProp="image"
        content={
          dynamicItem
            ? metaData.cover
            : metaData.cover
            ? window.location.host + metaData.cover
            : ""
        }
      ></meta>
    </Helmet>
  );
}

export default ViewHelmet;
