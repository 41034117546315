import { string, object } from "yup";
import { FileSchema } from "modules/schemas";
import { TWO_MB } from "constants";

const validationSchema = object({
  degree: string().required("Please select a Degree"),
  institute: string().required("Please enter the Institute Name"),
  qualification: string().required("Please enter the Qualification details"),
  year_of_graduation: string().required("Please select a Year of Graduation"),
  // curriculum: string().required("Which curriculum was it?"),

  document: new FileSchema({
    fileName: "certificate",
    isRequired: false,
    maxSize: TWO_MB,
    allowedFormats: ["png", "jpg", "jpeg", "pdf"],
    allowString: true,
  }),
});

export default validationSchema;
