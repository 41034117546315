import useApiEffect from "hooks/use-api-effect";
import {
  fetchStaticDataV3,
  staticDataV3Queries,
  useStaticDataV3Selector,
  useStaticDataV3Dispatch,
} from "store/store-static-v3";
import type {
  StaticDataV3Key,
  StaticDataV3Record,
} from "constants/types/types-static-data";

/**
 * Request one or more of the static data entities.
 *
 * @author kashan-ahmad
 * @version 1.0.0
 */
export default function useStaticDataV3(keys?: StaticDataV3Key[]) {
  const dispatch = useStaticDataV3Dispatch();
  const store = useStaticDataV3Selector((store) => store);

  useApiEffect(() => {
    if (!keys) return;
    keys.forEach((key) => {
      // A query type contains the metadata required to perform a request.
      const queryType = staticDataV3Queries[key];

      // If this data is missing, then it's an invalid key.
      if (!queryType) throw new Error(`😡 Invalid static data key: ${key}!`);

      // For some reason, the dispatch method wouldn't be typed correctly.
      // Assertions and coercions, both don't seem to work, hence we gotta =>

      // No need to fetch if the data is already loaded.
      store[key].state === "loaded" ||
        dispatch(
          // @ts-ignore
          fetchStaticDataV3(queryType)
        );
    });
  }, []);

  function getStaticData(key: string | number, record: StaticDataV3Record) {
    return record.get(parseInt(typeof key === "string" ? key : `${key}`));
  }

  return { ...store, getStaticData };
}
