import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import TutoringTutorHistoryActivity from "./tutoring-tutor-history-activity";
import TutoringTutorPreviousActivityDetailed from "../tutoring-tutor-previous-activity-detailed";
import {fetchFinishedSessionsWithStudent} from "store";
import {Loader, Pagination, SassyAlert} from "components";
import {useStaticDataV2} from "hooks";
import {getKeyFromArrUsingId} from "utils";
import {DEFAULT_TUTOR_ACTIVITES_PER_PAGE} from "constants";

/*
  Flow of component:  
    activity: single previous finished session
    activityDetails: Detailed preview of a finished session.

    In this component, we are using state to manage showing list of cards of previous sessions and 
    details of a single previous activity details.
    Each activity is surrounded by a click listener which updates showActivityDetails state to true
    and component re renders on state update and we are conditionally rendering activity details.

    The state changer function is passed to history details by which history details can be de-mounted.

 */

const SessionHistory = () => {
  const [showActivityDetails, setShowActivityDetails] = useState(false);
  const [activitySessionId, setActivitySessionId] = useState("");
  const [activityRequestId, setActivityRequestId] = useState("");

  const [page, setPage] = useState(1);
  const itemsPerPage = DEFAULT_TUTOR_ACTIVITES_PER_PAGE;

  const dispatch = useDispatch();

  const {subjects} = useStaticDataV2(["subjects"]);

  let {student_id} = useParams();
  const learner_id = parseInt(student_id);

  useEffect(() => {
    dispatch(
      fetchFinishedSessionsWithStudent({
        learner_id,
        limit: itemsPerPage,
        page,
      })
    );
  }, [learner_id, page]);

  const {isLoaded, isErred, data} = useSelector(
    (state) => state.tutorTutoring.recentActivities
  );

  if (subjects.state === "loading") return <Loader isInline />;
  return (
    <>
      {showActivityDetails ? (
        <>
          <TutoringTutorPreviousActivityDetailed
            {...{
              setShowActivityDetails,
              activitySessionId,
              activityRequestId,
            }}
          />
        </>
      ) : (
        <>
          {isErred ? (
            <SassyAlert
              color="danger"
              title="Couldn't load your recent activities with this learner"
            />
          ) : !isLoaded ? (
            <Loader />
          ) : (
            <>
              <div className="d-flex justify-content-between mb-3">
                <h3>Activity Log</h3>

                {/*
                TODO:
                <div className="pe-3 ms-auto">
                  <SearchBar />
                </div>
                <div>
                  <button className="btn back-btn">
                    <FiFilter />
                  </button>
                </div> */}
              </div>
              <div className="sessions-history-container">
                {data.data.length > 0 ? (
                  <>
                    {data.data.map((s, index) => (
                      <button
                        key={index}
                        onClick={() => {
                          setShowActivityDetails(true);
                          setActivitySessionId(s.session_id);
                          setActivityRequestId(s.id);
                        }}
                        className="wrapper-link d-block w-100"
                        type="button"
                      >
                        <TutoringTutorHistoryActivity
                          {...s}
                          subjectName={getKeyFromArrUsingId(
                            subjects.data,
                            "subject",
                            s.subject_id
                          )}
                        />
                      </button>
                    ))}
                    <Pagination
                      type="pages"
                      className="mt-3"
                      totalItems={itemsPerPage * data.number_of_pages}
                      itemsPerPage={itemsPerPage}
                      currentPage={page}
                      onChangePage={(newPage) => setPage(newPage)}
                    />
                  </>
                ) : (
                  <SassyAlert color="info">
                    You do not have any recent activity with this learner{" "}
                  </SassyAlert>
                )}
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default SessionHistory;
