import {
  IconX,
  IconBook,
  IconCheck,
  IconTicket,
  IconUserPlus,
  IconMailOpened,
  IconMessagePlus,
  IconAlertCircle,
} from "@tabler/icons";

/**
 * @typedef {Object} Notification
 *
 * @property {number | undefined} id
 *
 * @property {number} sent_by
 * The notifier's id.
 *
 * @property {number} sent_to
 * The receiver's id.
 *
 * @property {string} description
 * Textual information about the notification.
 *
 * @property {number} type
 * Type of notification, see `NOTIFICATION_TYPES`.
 *
 * @property {number} req_id
 * The id of the request represented by the notfication.
 *
 * @property {number} session_id
 * The id of the session represented by the notification.
 *
 * @property {number} seen
 * Binary boolean that says `read` or not.
 *
 * @property {string} created_at
 * @property {string} updated_at
 * @property {string} state
 * @property {number} curriculum_id
 * @property {number} subject_id
 * @property {number} grade_id
 *
 * @property {string} name
 * The notifier's name.
 *
 * @property {string | undefined} link
 * A link that can be redirected to on some notification types.
 *
 * @property {string} profile_picture
 * The notifier's profile picture.
 */

/**
 * @typedef {Object} NotificationType
 *
 * @property {number | undefined} id
 * @property {string} label
 * @property {string | undefined} color
 * @property {() => JSX.Element | undefined} Icon
 * @property {"confirm" | "prompt" |"confirm_reschedule" |"confirm_reschedule" |"reschedule" |"confirm_reschedule" |"setup" |"confirm_reschedule" |"pay" |"retry" | "reply" | "reschedule_learner" | "reschedule_tutor" | undefined} action
 */

/**
 * @typedef {Object} NotificationState
 *
 * @property {Notification[]} data
 * @property {number} page_no
 * @property {number} number_of_pages
 * @property {boolean} isLoaded
 * @property {boolean} isErred
 */

/**
 * @typedef {"SET_NOTIFICATIONS"
 * | "ERRED_NOTIFICATIONS"
 * } NotificationActionType
 */

/**
 * @typedef {Object} NotificationAction
 *
 * @property {any} payload
 * @property {NotificationActionType} type
 */

/**
 * @typedef {Object} NotificationEventListeners
 *
 * @property {Function | undefined} onClick The onclick
 * handler for the actions that require it.
 * @property {Function | undefined} onCancel The cancellaton
 * handler for the actions that require it.
 * @property {Function | undefined} onConfirm The confirmation
 * handler for the actions that require it.
 * @property {Function | undefined} onReschedule The reschedule
 * handler for the actions that require it.
 */

/**
 * The different types of notifications (so far).
 *
 * @type {NotificationType[]}
 */
const NOTIFICATION_TYPES = [
  {
    id: 1,
    label: "Instant Connect",
    action: "confirm",
  },

  {
    id: 2,
    label: "Meeting Alert",
    action: "prompt",
  },

  {
    id: 3,
    color: "success",
    label: "Meeting Booked",
    action: "confirm_reschedule",
  },

  {
    id: 4,
    color: "success",
    label: "Session Request",
    action: "confirm_reschedule",
  },

  {
    id: 5,
    color: "danger",
    label: "Session Cancelled",
    action: "reschedule",
  },

  {
    id: 6,
    label: "Session Rescheduled",
  },

  {
    id: 7,
    color: "success",
    label: "Session Follow-up",
    action: "setup",
  },

  {
    id: 8,
    color: "success",
    label: "Session Booked",
  },

  {
    id: 9,
    label: "Free Session",
    action: "join",
  },

  {
    id: 10,
    Icon: IconAlertCircle,
    label: "Refund Request",
    action: "pay",
  },

  {
    id: 11,
    color: "success",
    label: "Material Booked",
  },

  {
    id: 12,
    color: "success",
    Icon: IconCheck,
    label: "Payment Received",
  },

  {
    id: 13,
    color: "danger",
    Icon: IconX,
    label: "Payment Failed",
    action: "retry",
  },

  {
    id: 14,
    color: "success",
    Icon: IconCheck,
    label: "Payment Withdrawn",
  },

  {
    id: 15,
    color: "success",
    Icon: IconMailOpened,
    label: "Message Received",
    action: "reply",
  },

  {
    id: 16,
    color: "success",
    Icon: IconMessagePlus,
    label: "Comment Received",
    action: "reply",
  },

  {
    id: 17,
    color: "success",
    Icon: IconUserPlus,
    label: "Refer a Friend",
  },

  {
    id: 18,
    color: "success",
    Icon: IconTicket,
    label: "Ticket Message",
  },

  {
    id: 19,
    color: "success",
    Icon: IconBook,
    label: "Material Approved",
  },

  {
    id: 20,
    color: "danger",
    Icon: IconBook,
    label: "Material Rejected",
  },
  {
    id: 21,
    color: "success",
    label: "Session Purchased",
  },
  {
    id: 22,
    label: "Session Reschedule",
    action: "reschedule_tutor",
  },
];

export default NOTIFICATION_TYPES;
