import React from "react";
import moment from "moment";
import DatePicker from "components/date-picker";
import { DUMMIES_MONTHS_NUMERIC } from "constants/dummies";

type Props = {
  id?: string;
  value: string;
  onBlur?: () => unknown;
  onChange: (value: string) => unknown;
};

export default function DatePickerField({
  id,
  value,
  onBlur,
  onChange,
}: Props) {
  const date = new Date();

  const [selectedDay, setSelectedDay] = React.useState({
    label: value ? value.split("-")[2] : "1",
    value: value ? value.split("-")[2] : "1",
  });

  const [selectedMonth, setSelectedMonth] = React.useState(
    DUMMIES_MONTHS_NUMERIC.filter(
      (m) => m.value === parseInt(value.split("-")[1])
    )[0] || {
      label: "January",
      value: 1,
    }
  );

  const [selectedYear, setSelectedYear] = React.useState({
    label: value ? value.split("-")[0] : date.getFullYear(),
    value: value ? value.split("-")[0] : date.getFullYear(),
  });

  // Abdullah knows whatever the fuck is happening here.
  // I didn't have time so I copied his shit and made it better.
  const handleDayChange = (val: any) => {
    if (!val || !val.value) return;

    setSelectedDay(val);
    onChange(
      `${selectedYear?.value}-${("0" + selectedMonth.value).slice(-2)}-${(
        "0" + val.value
      ).slice(-2)}`
    );
  };

  const handleMonthChange = (val: any) => {
    if (!val || !val.value) return;

    setSelectedMonth(val);
    onChange(
      `${selectedYear?.value}-${("0" + val.value).slice(-2)}-${(
        "0" + selectedDay?.value
      ).slice(-2)}`
    );
  };

  const handleYearChange = (val: any) => {
    if (!val || !val.value) return;

    setSelectedYear(val);
    onChange(
      `${val.value}-${("0" + selectedMonth.value).slice(-2)}-${(
        "0" + selectedDay?.value
      ).slice(-2)}`
    );
  };

  return (
    <DatePicker
      value={value}
      // @ts-ignore
      max={moment().subtract(1, "days")}
    >
      {[
        // @ts-ignore
        <DatePicker.Day
          id={id}
          className="flex-grow-1"
          value={selectedDay}
          onBlur={onBlur}
          onChange={handleDayChange}
        />,
        // @ts-ignore
        <DatePicker.Month
          className="flex-grow-1"
          value={selectedMonth}
          onBlur={onBlur}
          onChange={handleMonthChange}
        />,
        // @ts-ignore
        <DatePicker.Year
          className="flex-grow-1"
          value={selectedYear}
          onBlur={onBlur}
          onChange={handleYearChange}
        />,
      ]}
    </DatePicker>
  );
}
