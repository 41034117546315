import { Label } from "reactstrap";
import { IconX, IconCalendar } from "@tabler/icons";
import { TextField, Icon } from "components";
import { IconCircleCheck } from "@tabler/icons";
import { useRef } from "react";

const DateFilters = ({
  onApplyFilters,
  onRemoveFilters,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}) => {
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onApplyFilters(
          e.target.elements["startDate"].value,
          e.target.elements["endDate"].value
        );
      }}
      onReset={() => {
        onRemoveFilters();
      }}
    >
      <div className="filters d-flex flex-wrap align-items-center">
        <div className="d-flex align-items-center me-3">
          <div className="w-50">
            <Label className="form-label mb-0 " style={{ width: "40px" }}>
              From:{" "}
            </Label>
          </div>
          <div>
            <TextField
              value={startDate && startDate}
              onChange={(e) => setStartDate && setStartDate(e.target.value)}
              type="date"
              max={new Date().toISOString().split("T")[0]}
              name="startDate"
              required
            />
          </div>
        </div>
        <div className="d-flex align-items-center">
          <div className="w-50">
            <Label className="form-label mb-0" style={{ width: "40px" }}>
              To:{" "}
            </Label>
          </div>
          <div>
            <TextField
              value={endDate && endDate}
              onChange={(e) => setEndDate && setEndDate(e.target.value)}
              type="date"
              max={new Date().toISOString().split("T")[0]}
              name="endDate"
              required
            />
          </div>
        </div>
        <div className="ms-1 gap-row-sm">
          <button
            type="submit"
            role="tooltip"
            className="p-1"
            aria-label="Apply Filters"
            data-microtip-position="top"
          >
            <Icon size="xs" className="text-secondary">
              <IconCircleCheck />
            </Icon>
          </button>
          <button
            type="reset"
            className="p-1"
            role="tooltip"
            aria-label="Reset Filters"
            data-microtip-position="top"
          >
            <Icon size="xs" className="text-secondary">
              <IconX />
            </Icon>
          </button>
        </div>
      </div>
    </form>
  );
};

export default DateFilters;
