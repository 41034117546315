import { apiService } from "service";
import { getAuthHeaders } from "utils";
import { getEnvVar } from "utils/utils-env";
import { Boolbacks, RemoteProfile } from "constants/types";
import { useSelector } from "react-redux";
import useSetRemoteProfile from "hooks/profile/use-set-remote-profile";

type UpdateSubjects = {
  subjects: RemoteProfile["subjects"];
} & Boolbacks;

export default function useRemoteSubjects() {
  const setProfile = useSetRemoteProfile();

  const { subjects } = useSelector(
    (state: any) => state.profile
  ) as RemoteProfile;

  function setSubjects({ subjects }: { subjects: RemoteProfile["subjects"] }) {
    setProfile({ profile: { subjects } });
  }

  function updateSubjects({ subjects, onSuccess, onFailure }: UpdateSubjects) {
    apiService.post({
      headers: getAuthHeaders(),
      data: { subjects: JSON.stringify(subjects.map(({ id }) => id)) },
      url: `${getEnvVar("USER_SERVICE_URL")}/tutor/create-subjects`,
      onFailure,
      onSuccess: () => {
        setSubjects({ subjects });
        onSuccess?.({ data: subjects });
      },
    });
  }

  return { subjects, updateSubjects };
}
