import {Loader} from "components";
import React from "react";

const View = React.lazy(() => import("./error-404.js"));

const Error404 = (props) => (
  <React.Suspense fallback={<Loader />}>
    <View {...props} />
  </React.Suspense>
);

export default Error404;
