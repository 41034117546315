import React from "react";
import { Notifier, NotifierProps } from "..";
import strings from "constants/strings";
import { Icon } from "components";
import { IconInfoCircle } from "@tabler/icons";
import NotifierMessage from "../components/notifier-message";

export default function InfoNotifier({
  children,
  message = strings.DEFAULT_SUCCESS_MESSAGE,
  ...props
}: NotifierProps) {
  return (
    <Notifier
      {...props}
      className="p-5 align-items-center justify-content-center"
    >
      <Icon.Rounded size="xl" tag="span" className="bg-soft bg-info text-info">
        <IconInfoCircle />
      </Icon.Rounded>
      <NotifierMessage message={message} />
      {children}
    </Notifier>
  );
}
