import avatarSrc from "assets/images/dash-head.png";
import dashedCircleSrc from "assets/images/sidebar-eclipse.png";
import { LEARNING_MATERIALS_SEARCH } from "constants";

/**
 * @typedef {Object} ExploreCardProps
 * @property {number | undefined} props.discount
 */

/**
 * Card representing an "Explore what's new" prompt with an optional discount.
 *
 * @param {JSX.IntrinsicElements['article'] & ExploreCardProps} props
 *
 * @author kashan-ahmad
 *
 * @version 0.0.1
 */
function ExploreCard({ discount, className = "", ...props }) {
  return (
    <article
      {...props}
      className={`rounded bg-blue px-0 position-relative ${className}`}
    >
      <div className="px-3 pt-2 d-flex align-items-end justify-content-between">
        {/* {discount && (
          <span
            role="contentinfo"
            className="px-3 py-1 bg-success text-light rounded-3 fs-4 fw-bold flex-shrink-0"
          >
            {discount}% <span className="fs-7">Off</span>
          </span>
        )} */}
        <span></span>
        <div aria-hidden={true}>
          <div className="d-flex card-connect_box">
            <img src={avatarSrc} alt="" className="card-connect_circle" />
            <div className="d-grid">
              <div className="card-connect_line"></div>
              <div className="card-connect_line1"></div>
            </div>
          </div>
          <div className="d-flex card-connect_box1">
            <img src={avatarSrc} alt="" className="card-connect_circle" />
            <div className="d-grid">
              <div className="card-connect_line"></div>
              <div className="card-connect_line1"></div>
            </div>
          </div>
          <div className="d-flex card-connect_box2">
            <img src={avatarSrc} alt="" className="card-connect_circle" />
            <div className="d-grid">
              <div className="card-connect_line"></div>
              <div className="card-connect_line1"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="p-3 position-relative z-1">
        <a
          target="_blank"
          rel="noreferrer"
          href={LEARNING_MATERIALS_SEARCH}
          className="fs-2 fw-bold text-white d-block"
        >
          Explore What's new on <br />
          <span className="text-success">TheTutor.me 🎉</span>
        </a>
      </div>
      <img
        alt=""
        aria-hidden={true}
        src={dashedCircleSrc}
        className="position-absolute top-0 end-0"
      />
    </article>
  );
}

export default ExploreCard;
