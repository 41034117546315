import React from "react";
import {Loader} from "components";

const View = React.lazy(() => import("./earnings-withdrawals-table"));


/**
 * @author Abdullah-Sajjad026
 */
const TutorEarningsWithdrawals = (props) => (
  <React.Suspense fallback={<Loader />}>
    <View {...props} />
  </React.Suspense>
);

export default TutorEarningsWithdrawals;
