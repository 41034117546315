import Pusher from "pusher-js";
import { PUSHER_APP_CLUSTER, PUSHER_APP_KEY } from "constants/env";

type ChannelSubscription = {
  channelName: string;
  eventName: string;
  callback: Function;
};

interface PusherService {
  subscribe: (subscription: ChannelSubscription) => () => void;
  // unsubscribe: (channelName: string) => void;
}

// implementing singleton pattern for pusher instance to avoid multiple instances of pusher in the app
let pusherInstance: Pusher | null = null;

const getPusherInstance = (): Pusher => {
  // Check if pusherInstance is null, if it is, create a new instance of Pusher
  if (!pusherInstance) {
    pusherInstance = new Pusher(PUSHER_APP_KEY!, {
      cluster: PUSHER_APP_CLUSTER!,
      //   forceTLS: true,
    });
  }

  // Return the instance of Pusher
  return pusherInstance;
};

const pusherService: PusherService = {
  /**
   * Subscribe to a channel and bind an event listener to it and return a function to unsubscribe from the channel.
   * Implements the singleton pattern to avoid multiple instances of Pusher.
   */
  subscribe: ({ channelName, eventName, callback }: ChannelSubscription) => {
    // Get the instance of Pusher
    const pusher = getPusherInstance();
    // Subscribe to the channel
    const channel = pusher.subscribe(channelName);
    // Bind the event listener to the channel
    channel.bind(eventName, callback);

    /**
     *  Return a function to unsubscribe from the channel and unbind the event listener.
     *  Implements the singleton pattern to avoid multiple instances of Pusher.
     */
    return function unsubscribe() {
      // Unbind the event listener
      channel.unbind(eventName, callback);
      // Unsubscribe from the channel
      pusher.unsubscribe(channelName);

      //
      pusherInstance = null;
    };
  },

  // /**
  //  * Unsubscribe from a channel.
  //  * Implements the singleton pattern to avoid multiple instances of Pusher.
  //  */
  // unsubscribe: (channelName: string) => {
  //   // Get the instance of Pusher
  //   const pusher = getPusherInstance();
  //   // Unsubscribe from the channel
  //   pusher.unsubscribe(channelName);
  // },
};

export { pusherService };
