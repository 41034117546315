import * as React from "react";
import {Loader} from "components";
import LearnerUpcomingSessionDetailedViewModel, {
  IViewModelPassedControllers,
} from "./learner-upcoming-session-detailed-view-model";

const View = React.lazy(
  () => import("./learner-upcoming-session-detailed-view")
);

export interface IComponentProps {
  isEdit?: boolean;
  sessionId: number;
  requestId: number;
}

/**
 * @author Abdullah-Sajjad026
 */
const TutoringLearnerUpcomingSessionDetailed = (props: IComponentProps) => (
  <React.Suspense fallback={<Loader />}>
    <LearnerUpcomingSessionDetailedViewModel {...props}>
      {(controllers: IViewModelPassedControllers) => <View {...controllers} />}
    </LearnerUpcomingSessionDetailedViewModel>
  </React.Suspense>
);

export default TutoringLearnerUpcomingSessionDetailed;
