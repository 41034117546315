import { boxStyles } from "constants/config";
import React from "react";
import Footer from "react-bootstrap/ModalFooter";

export default function ModalFooter({
  children,
  className = "",
  ...props
}: Parameters<typeof Footer>[0]) {
  return (
    <Footer {...props} className={boxStyles.xGap}>
      {children}
    </Footer>
  );
}
