import { RemoteProfile } from "constants/types";

export const dummyRemoteProfile: RemoteProfile = {
  access_token: "",
  address_line_1: "",
  address_line_2: "",
  agrees_to_privacy_policy: 0,
  agrees_to_stay_on_platform: 0,
  agrees_to_data_sharing: 0,
  agrees_to_commission: 0,
  authorized: 0,
  authorization_status: 0,
  availability: [],
  bio: "",
  bio_template:
    "Hi, I am <Your Name>. I am a passionate tutor who enjoys teaching and impacting the lives of my students. I specialize in teaching <Subject 1>, <Subject 2>. I have extensive knowledge and experience in teaching across different grades and curricula. My Skills range from <Skill 1> to <Skill 2>. Lastly, I am someone who is patient, friendly and dedicated to help you achieve your academic goals.",
  boughts: 0,
  calendar_setting: {
    date_format: "",
    id: undefined,
    timezone: "",
    week_start_on: "",
  },
  duration: 15,
  free_session_duration: 15,
  certificates: [],
  channel_id: "",
  channel_name: "",
  city: "",
  country: "",
  country_code: "",
  cover_image: "",
  curriculum: [],
  dob: "",
  email: "",
  experience: "",
  experiences: [],
  free_connect: 0,
  free_session_bought_from: [],
  goal: [],
  grade: [],
  id: undefined,
  instant_connect: 0,
  interest: [],
  is_best_seller: 0,
  is_onboarded: 0,
  is_recommended: 0,
  is_student_favourite: 0,
  is_subject_guru: 0,
  languages: [],
  learningstyle: [],
  max_advance_book_time: 2,
  min_booking_time: 24,
  mobile_number: "",
  name: "",
  not_searchable: 0,
  onboarding_history: [
    {
      status: "signup",
      datetime: "2021-06-01T12:00:00.000Z",
    },
  ],
  onboarding_status: "signup",
  pincode: "",
  profile: "",
  profile_picture: "",
  qualifications: [],
  quick_register: 0,
  rating: 0,
  referal_id: "",
  remaining_credits: 0,
  rocket_chat_user_id: "",
  session_subjects: [],
  skills: [],
  slot_start_date: "",
  state: "",
  subject: [],
  subjects: [],
  sub_type: 0,
  time_off: [],
  timeslot: [],
  timezone: "",
  title: "",
  trending: 0,
  user_type: "tutor",
  username: "",
  users_rated: 0,
  verification_status: 0,
};
