import { ErrorMessage } from "formik";
import { FormGroup } from "reactstrap";
import SassySelect from "components/sassy-select";
import FormErrorMessage from "components/error-message-form";

/**
 * A formik-based select-field but sassy. Uses React-Select with tweaks that handle the formik-context by itself.
 *
 * @param {import("react-select/dist/declarations/src/stateManager").StateManagerProps} props
 * @param {string} props.label
 * @param {"text" | "textarea"} props.type
 * @param {import("formik").FormikHandlers['handleChange']} props.handleChange
 * @param {import("formik").FormikHelpers['setFieldTouched']} props.setFieldTouched
 *
 * @returns {JSX.Element}
 */
function SassyFormFieldSelect({
  name,
  label,
  value,
  className,
  handleChange,
  closeMenuOnSelect = true,
  setFieldTouched,
  returnKey = "value",
  ...rest
}) {
  return (
    <FormGroup data-testid={`${name}Parent`}>
      <SassySelect
        id={name}
        {...rest}
        data-testid={`${name}Select`}
        {...{ name, label, closeMenuOnSelect }}
        selectedOptions={value}
        onBlur={() => setFieldTouched?.(name)}
        onChange={(value) => {
          if (Array.isArray(value)) {
            handleChange({
              target: {
                name,
                value: value.map((value) => value[returnKey]),
              },
            });
            return;
          }

          handleChange({
            target: {
              name,
              value: value[returnKey],
            },
          });
        }}
      />
      <ErrorMessage
        name={name}
        render={(message) => (
          <FormErrorMessage data-testid={`${name}Error`} {...{ message }} />
        )}
      />
    </FormGroup>
  );
}

export default SassyFormFieldSelect;
