import React from "react";
import {Loader} from "components";

import EarningsDashboard from "./earnings-dashboard";
import EarningsAddBank from "./earnings-add-bank";
import EarningsWithdrawals from "./earnings-withdrawals";

const View = React.lazy(() => import("./earnings.js"));

const Earnings = (props) => (
  <React.Suspense fallback={<Loader />}>
    <View {...props} />
  </React.Suspense>
);

export {Earnings, EarningsDashboard, EarningsWithdrawals, EarningsAddBank};
