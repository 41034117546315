import * as React from "react";
import { Formik } from "formik";
import { IconButton } from "components";
import { IconChevronLeft } from "@tabler/icons";
import Provider from "./phone-number-form-provider";
import { Button, Form, Modal } from "react-bootstrap";
import { BaseFormProps } from "constants/types/types";
import PhoneNumberFormFields from "./phone-number-form-fields";

/**
 * Form to update the phone number of a user.
 *
 * @returns {JSX.Element}
 */
export default function PhoneNumberForm({
  onClose,
  onCancel,
}: BaseFormProps): JSX.Element {
  return (
    <Provider {...{ onClose }}>
      {({ schema, onSubmit, initialValue, countryCode, mobileNumber }) => (
        <Formik
          onSubmit={onSubmit}
          validationSchema={schema}
          initialValues={{
            countryCode,
            mobileNumber,
            phoneNumber: initialValue,
          }}
        >
          {({
            values,
            handleSubmit,
            handleChange,
            setFieldValue,
            setFieldTouched,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title>Edit mobile number</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <PhoneNumberFormFields
                  name="phoneNumber"
                  value={values.phoneNumber}
                  {...{ handleChange, setFieldValue, setFieldTouched }}
                />
              </Modal.Body>
              <Modal.Footer className="d-flex justify-content-between">
                <IconButton
                  type="button"
                  title="Back"
                  tooltipPosition="right"
                  onClick={() => onCancel()}
                >
                  <IconChevronLeft size={18} />
                </IconButton>
                <Button type="submit" variant="secondary">
                  Confirm
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      )}
    </Provider>
  );
}
