import React from "react";
import DashboardBlog from "../dashboard-blog";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "reactstrap";

import { connect } from "react-redux";
import { fetchPopularTutors } from "store/learnerdashboard/action";

import { Loader } from "components";
import { useApiEffect } from "hooks";
import LearnerDashboardTasks from "./dashboard-learner-tasks";
import { useStaticDataV3 } from "hooks/static-data";
import LearnerSubjectsTutors from "modules/learner-subjects-tutors";
import LearnerDashboardSidebar from "./dashboard-learner-sidebar";
import { withRouterModal } from "components/hoc";


/**
 * @author Abdullah-Sajjad026
 */
const LearnerDashboard = () => {
  const dispatch = useDispatch();

  const { popularTutors } = useSelector((state) => ({
    popularTutors: state.learnerDashboard.popularTutors,
  }));

  useApiEffect(() => {
    if (popularTutors.loading) {
      dispatch(fetchPopularTutors());
    }
    // getPopularLearningMaterials({
    //   onSuccess: (data) =>
    //     setPopularLearningMaterials({
    //       state: "loaded",
    //       data: new LearningMaterialAdapter().deserialize({ data }),
    //     }),
    //   onFailure: () => {
    //     setPopularLearningMaterials({ state: "erred", data: [] });
    //   },
    // });
  }, []);

  const { grades, subjects, curriculums } = useStaticDataV3([
    "grades",
    "subjects",
    "curriculums",
  ]);

  if (
    grades.state !== "loaded" ||
    subjects.state !== "loaded" ||
    curriculums.state !== "loaded"
  ) {
    return <Loader />;
  }

  return (
    <section className="content">
      {/* <Breadcrumb
        activeRouteName={`${userName}'s Dashboard`}
        activeRoutePath="/dashboard"
      /> */}

      <div className="p-3 p-md-4 d-flex flex-column gap-3 gap-md-4">
        <Row className="g-3 g-md-4">
          <Col xs={12} lg={7} className="d-flex flex-column gap-3 gap-md-4">
            {/* <LearnerDashboardWelcome /> */}

            {/* This component is placed temporary here. */}
            {/* <div className="mt-3">
              <DashboardConnectProfile />
            </div> */}

            <LearnerDashboardTasks />

            {/* ---- Developer Note
                  Dont remove the below commented code. */}
          </Col>

          <Col xs={12} lg={5}>
            <LearnerDashboardSidebar />
          </Col>
        </Row>

        {/* {popularLearningMaterials.state === "loaded" &&
          popularLearningMaterials.data.records.length > 0}
        <GridWrapper
          headingTag="h1"
          badgeText="Best Seller"
          headingText="Popular Learning Materials"
          className="mt-5"
        >
          <Row>
            {popularLearningMaterials.data.records.map(
              (
                {
                  id,
                  tags,
                  slug,
                  price,
                  title,
                  seller,
                  discount,
                  thumbnail,
                  description,
                  numOfPlacedOrders,
                },
                i,
                arr
              ) => (
                <Col key={id} xl={"4"} md={"6"} sm={"6"} xs={"12"}>
                  <BestSellingProductCard
                    {...{id, price, title, description}}
                    keywords={tags}
                    badgeText="Best Seller"
                    sellerName={seller?.name}
                    coverImageSrc={thumbnail}
                    discountPercentage={discount}
                    numOfRecentSales={numOfPlacedOrders}
                    headingTag={getSemanticHeading("h1")}
                    buyButtonListener={() =>
                      navigate(`/learning-materials/details/${id}/${slug}`, {
                        state: arr[i],
                      })
                    }
                    onClick={() =>
                      navigate(`/learning-materials/details/${id}/${slug}`, {
                        state: arr[i],
                      })
                    }
                  />
                </Col>
              )
            )}
          </Row>
        </GridWrapper> */}

        <LearnerSubjectsTutors />
        {/* Blogs section */}
        <section className="main-dash-blog mt-4">
          <DashboardBlog />
        </section>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => ({
  popularTutors: state.learnerDashboard.popularTutors,
});

export default withRouterModal(connect(mapStateToProps, {})(LearnerDashboard));
