export type TourAction = "cancel" | "complete";
export type RemoteTourAction = "skipped" | "proceeded";

export type AllowedStepPosition = "top" | "bottom" | "right" | "left";

/**
 * The position of the step.
 * Note that there can be two positions, separated by a plus. This is to allow the step to be positioned responsively.
 * The first position is for mobile devices, and the second position is for desktop devices.
 * In case of a single position, the step will be positioned the same way on both mobile and desktop devices.
 * @type {AllowedStepPosition | `${AllowedStepPosition} + ${AllowedStepPosition}`}
 */
export type TourStepPosition =
  | AllowedStepPosition
  | `${AllowedStepPosition} + ${AllowedStepPosition}`;

export type TourActionButton = {
  /**
   * The text to be displayed on the button.
   * @type {string}
   */
  text: string;

  /**
   * The action to be performed when the button is clicked.
   * @type {"forwards" | "backwards" | "stop" | "forwards+scrollToTop"}
   */
  action: "forwards" | "backwards" | "stop" | "forwards+scrollToTop";

  /**
   * The class name to be applied to the button.
   * @type {string}
   */
  className?: string;

  /**
   * The button will be disabled if this is set to true.
   * @type {boolean}
   */
  disabled?: boolean;

  /**
   * The aria-label to be applied to the button.
   * @type {string}
   */
  "aria-label"?: string;

  /**
   * The button will be styled as a secondary button if this is set to true.
   * @type {boolean}
   */
  isSecondary?: boolean;
};

export type TourStep = {
  /**
   * The id of the step.
   * @type {string}
   */
  id: string;

  /**
   * The title of the step.
   * Displayed as the heading of the step.
   * @type {string}
   */
  title?: string;

  /**
   * The text of the step.
   * Displayed as the body of the step.
   * @type {string}
   */
  text?: string;

  /**
   * The class name to be applied to the step.
   * @type {string}
   */
  className?: string;

  /**
   * The target element of the step.
   * @type {Object}
   */
  target?: {
    /**
     * The element to be targeted.
     * @type {string | HTMLElement}
     */
    element: string | HTMLElement;

    /**
     * The position of the step relative to the target element.
     * @type {TourStepPosition | undefined}
     */
    position?: TourStepPosition;
  };

  /**
   * The close button of the step.
   * @type {Object}
   */
  closeButton?: {
    /**
     * The close button will be hidden if this is set to true.
     * @type {boolean}
     */
    hidden?: boolean;

    /**
     * The class name to be applied to the close button.
     * @type {string}
     */
    "aria-label"?: string;
  };

  /**
   * The action buttons of the step.
   * @type {Array<TourActionButton>}
   */
  actionButtons: Array<TourActionButton>;
};

export type TourStepStatus = {
  key: string;
  isVisited: boolean;
  actionTaken: TourAction;
};

export type RemoteTourStepStatus = {
  key: string;
  viewed: 0 | 1;
  action: RemoteTourAction;
};

export function isRemoteStepStatus(data: any): data is RemoteTourStepStatus {
  return (
    data && typeof data.key === "string" && typeof data.action === "string"
  );
}

export type Tour = {
  /**
   * Starts the tour based on the added steps.
   * @returns {void}
   */
  start: Function;

  /**
   * Add a step to the tour.
   * @param step The step to add.
   * @returns {void}
   */
  addStep: (step: TourStep) => void;

  /**
   * Add multiple steps to the tour.
   * @param steps The steps to add.
   * @returns {void}
   */
  addSteps: (steps: Array<TourStep>) => void;

  /**
   * Move to the next step.
   * @returns {void}
   */
  forwards: Function;

  /**
   * Move to the previous step.
   * @returns {void}
   */
  backwards: Function;

  /**
   * Stop the tour.
   * @returns {void}
   */
  stop: Function;
};

export type TourData = {
  steps: Array<TourStep>;
};

export function isTourData(data: any): data is TourData {
  return data && data.steps && Array.isArray(data.steps);
}
