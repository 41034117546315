/* eslint-disable eqeqeq */
import { useRef, useState } from "react";
import {
  Container,
  Col,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
  Input,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

import {
  IconInfoCircle,
  IconBook,
  IconCreditCard,
  IconStar,
  IconX,
  IconCheck,
} from "@tabler/icons";

import {
  BookSession,
  Loader,
  RejectRequestModal,
  Avatar,
  SassyAlert,
  EditButton,
  IconButton,
} from "components";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import {
  fetchTutorAvailableSlots,
  fetchTutorSessionDetails,
  updateSessionInfo,
} from "store";
import {
  getAdjustedTime,
  getAbbrPriceFrom,
  getDiscounted,
  makePluralIf,
} from "utils";

import { LeadingBadgesList } from "components/lists";
import ToastFactory from "utils/toast-factory";
import { useStaticDataV3 } from "hooks/static-data";
import useSessionActions from "hooks/sessions/useSessionActions";
import SessionStatus from "components/session-status";
import SessionActionsButtons from "modules/session-actions-buttons";
import { RTutorTutoring } from "constants/tutor-views-routes";
import { DEFAULT_LIMIT_TITLES } from "constants/";
import SimpleReactValidator from "simple-react-validator";
import { useApiEffect } from "hooks";
import { MAX_LIMIT_DESCRIPTIONS } from "constants/";
import { DateTime } from "luxon";

/**
 *  @description Tutoring Tutor Session Request Component
 *  @category TutoringTutor
 *  @component
 *  @visibleName Tutoring Tutor Session Request Component
 * @author Abdullah-Sajjad026
 */

function TutoringTutorSessionRequest({ isEdit, sessionId, requestId }) {
  const toastFactory = ToastFactory();

  // if user has navigated to this page by url, then he has a session id and that is prioritized
  // else a requestId would have been sent and that is used

  let { session_id, request_id } = useParams();
  session_id = session_id ? parseInt(session_id) : sessionId;
  request_id = request_id ? parseInt(request_id) : requestId;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  //
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();

  // this component is for both upcoming and previous sessions,
  // so if it is a previous session, this needs to be true by the navigator Link that leads here.
  const isPreviousSession = location.state?.isPreviousSession;

  const [rejectModalState, setRejectModalState] = useState(false);
  const isRebook = location.state?.openReschedule;
  // session details state
  const [sessionInfo, setSessionInfo] = useState({
    state: "loading",
    data: {},
  });

  const [isRescheduling, setIsRescheduling] = useState(
    isEdit || isRebook || false
  );

  // states to manage the editing and updating of session info like agenda and description
  const [isEditingSessionInfo, setIsEditingSessionInfo] = useState(false);
  const [sessionNewInfo, setSessionNewInfo] = useState({
    agenda: "",
    description: "",
  });

  // BookSession states
  const [slotsStartDate, setSlotsStartDate] = useState(
    DateTime.now().toFormat("yyyy-MM-dd")
  );
  const [selectedSlot, setSelectedSlot] = useState(null);

  const { currentUserDetails, availableSlots } = useSelector((state) => ({
    currentUserDetails: state.auth.userDetails,
    availableSlots: state.tutorTutoring.availableSlots,
  }));

  const { curriculums, grades, subjects, getStaticData } = useStaticDataV3([
    "curriculums",
    "grades",
    "subjects",
  ]);

  const { handleSessionReschedule, handleSessionConfirm, handleSessionCancel } =
    useSessionActions();

  const [
    { data: totalCurriculums, state: curriculumsState },
    { data: totalGrades, state: gradesState },
    { data: totalSubjects, state: subjectsState },
  ] = [curriculums, grades, subjects];

  const isStaticDataLoaded =
    curriculumsState === "loaded" &&
    gradesState === "loaded" &&
    subjectsState === "loaded";

  // resetting and refetching session info on session id change
  useApiEffect(() => {
    setSessionInfo({ state: "loading", data: {} });
    setIsEditingSessionInfo(false);
    setIsRescheduling(isEdit || isRebook || false);

    dispatch(
      fetchTutorSessionDetails({
        sessionId: session_id,
        requestId: request_id,
        onSuccess: (response) => {
          setSessionInfo({ state: "loaded", data: response.data });
        },
        onFailure: (error) => {
          toastFactory.error(error.message);
          setSessionInfo({ state: "erred", data: {} });
        },
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session_id, request_id]);

  // fetching available slots for the selected date and bundle id on date change or session info getting loaded
  useApiEffect(() => {
    if (sessionInfo.state === "loaded") {
      dispatch(
        fetchTutorAvailableSlots({
          bundleId: sessionInfo.data?.bundle_id,
          range: 7,
          date: slotsStartDate,
          tutorId: currentUserDetails?.id,
          onFailure: (error) => {
            toastFactory.error(error.message);
          },
        })
      );
      setSessionNewInfo({
        agenda: sessionInfo.data?.agenda,
        description: sessionInfo.data?.description,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionInfo, slotsStartDate]);

  const toggleModal = () => {
    setRejectModalState(false);
  };

  // handler for
  const handleRequestConfirm = () => {
    handleSessionConfirm({
      requestId: sessionInfo.data.request_id,
      onSuccess: ({ data }) => {
        dispatch({ type: "RESET_TUTOR_UPCOMING_SESSIONS" });
        setSessionInfo({ state: "loaded", data });
      },
    });
  };
  const handleRequestCancel = () => {
    handleSessionCancel({
      requestId: sessionInfo.data.request_id,
      startTime: sessionInfo.data.start_time,
      endTime: sessionInfo.data.end_time,
      date: sessionInfo.data.date,
      userName: learnerName,
      userPicture: learnerImage,
      subjectName,
      onSuccess: ({ data }) => {
        dispatch({ type: "RESET_TUTOR_UPCOMING_SESSIONS" });
        setSessionInfo({ state: "loaded", data });
      },
      onRebook: () => {
        navigate(
          RTutorTutoring.getSpecificNewSessionView(
            sessionInfo.data.learner_id,
            sessionInfo.data.bundle_id
          )
        );
      },
    });
  };

  const handleRequestReschedule = () => {
    const newTime = DateTime.fromFormat(selectedSlot.id, "dd/MM/yyyy HH:mm");

    const startTime = newTime.toFormat("HH:mm");
    const endTime = selectedSlot.endTime;
    const date = newTime.toFormat("yyyy-MM-dd");
    const weekDay = newTime.weekday;

    handleSessionReschedule({
      date,
      startTime,
      endTime,
      weekDay,
      subjectName,
      receiverName: learnerName,
      receiverImage: learnerImage,
      rescheduledBy: currentUserDetails.id,
      requestId: sessionInfo.data.request_id,
      onSuccess: ({ data }) => {
        dispatch({ type: "RESET_TUTOR_UPCOMING_SESSIONS" });
        // toastFactory.success();
        setIsRescheduling(false);
        setSessionInfo({ state: "loaded", data });
      },
    });
  };

  const handleSessionInfoUpdate = () => {
    if (!simpleValidator.current.allValid()) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    toastFactory.loading();
    dispatch(
      updateSessionInfo({
        id: sessionInfo.data.request_id,
        data: sessionNewInfo,
        onSuccess: (res) => {
          toastFactory.success();
          dispatch({ type: "RESET_TUTOR_UPCOMING_SESSIONS" });

          setIsEditingSessionInfo(false);

          setSessionInfo({
            state: "loaded",
            data: {
              ...sessionInfo.data,
              agenda: sessionNewInfo.agenda,
              description: sessionNewInfo.description,
            },
          });
          dispatch({ type: "RESET_TUTOR_UPCOMING_SESSIONS" });
        },
        onFailure: (error) => {
          toastFactory.error();
        },
      })
    );
  };

  if (sessionInfo.state === "loading" || !isStaticDataLoaded) {
    return <Loader />;
  }

  // TODO: confirm it
  if (sessionInfo.data.request_status == false) {
    navigate(RTutorTutoring.sessionsBaseView, {
      replace: true,
    });
  }

  /// Data uniformation starts here.

  /**
   * Loop-able grades.
   */
  const saneGrades = sessionInfo.data.learner.grades?.map?.((grades) =>
    getStaticData(grades.grade_id, totalGrades)
  );

  /**
   * Loop-able curriculums.
   */
  const saneCurriculums = sessionInfo.data.learner.curriculums?.map?.((curri) =>
    getStaticData(curri.curriculum_id, totalCurriculums)
  );

  const learnerImage = sessionInfo?.data?.learner?.profile_picture;
  const learnerName = sessionInfo.data?.learner?.name;
  const subjectName = getStaticData(
    sessionInfo?.data?.subject_id,
    totalSubjects
  );
  const meetingStatusId = sessionInfo?.data?.meeting?.meeting_status?.id;
  const requestStatusId = sessionInfo?.data?.request_status;

  // can edit the agenda or description of the session or not
  const canEditSessionInfo =
    !isPreviousSession &&
    ![3, 5].includes(requestStatusId) &&
    sessionInfo.data?.sent_by == currentUserDetails.id;

  const readablePrice = getAbbrPriceFrom(
    parseInt(
      getDiscounted(sessionInfo.data.fee_per_session, sessionInfo.data.discount)
    )
  );

  const readableOriginalPrice = getAbbrPriceFrom(
    sessionInfo.data.fee_per_session
  );

  /// Data uniformation ends here.

  return (
    <Container fluid className="p-0">
      <div className="bg-inherit h-100 p-3 p-md-4">
        {sessionInfo.state === "erred" ? (
          <SassyAlert
            color="danger"
            title="We have got an error while fetching the session's details."
          />
        ) : sessionInfo.data.length === 0 ? (
          <SassyAlert
            color="danger"
            title="We could not find any details for this specific session."
          />
        ) : (
          <>
            <div className="d-flex align-items-sm-center flex-column flex-sm-row gap-2 gap-sm-3 justify-content-between">
              <div>
                {isEditingSessionInfo ? (
                  /* text box and action buttons for editing */
                  <div className="hstack gap-3">
                    <Input
                      maxLength={DEFAULT_LIMIT_TITLES}
                      value={sessionNewInfo.agenda}
                      onChange={(e) =>
                        setSessionNewInfo((prev) => ({
                          ...prev,
                          agenda: e.target.value,
                        }))
                      }
                    />
                    <IconButton
                      title="Confirm"
                      tooltipPosition="bottom"
                      onClick={handleSessionInfoUpdate}
                    >
                      <IconCheck />
                    </IconButton>
                    <IconButton
                      title="Cancel Editing"
                      tooltipPosition="bottom"
                      onClick={() => {
                        setSessionNewInfo({
                          agenda: sessionInfo.data?.agenda,
                          description: sessionInfo.data?.description,
                        });
                        setIsEditingSessionInfo(false);
                      }}
                    >
                      <IconX />
                    </IconButton>
                  </div>
                ) : (
                  <div className="hstack gap-1">
                    <h2 className="fs-3 mb-0">{sessionInfo.data.agenda}</h2>
                    {canEditSessionInfo && (
                      <EditButton
                        size="sm"
                        onClick={() => setIsEditingSessionInfo(true)}
                        tooltipPosition="bottom"
                        variant="none"
                        // className="p-1 border-1 rounded bg-gray-100 hover:bg-gray-300 focus:bg-gray-300 text-secondary "
                      />
                    )}
                  </div>
                )}
                {simpleValidator.current.message(
                  "Agenda",
                  sessionNewInfo.agenda,
                  "required"
                )}
              </div>
              <div className="prices d-flex">
                {/* Price */}
                <h3 className="discounted-price me-2 mb-0">{readablePrice}</h3>
                {/* Original Price */}
                {/* Ofc it isn't shown if there hasn't really been a discount */}
                {readableOriginalPrice &&
                  readableOriginalPrice !== readablePrice && (
                    <h3 className="original-price text-muted mb-0 text-decoration-line-through">
                      {readableOriginalPrice}
                    </h3>
                  )}
              </div>
            </div>

            <div className="mt-2 mt-sm-4 d-flex align-items-sm-center  flex-column flex-sm-row flex-wrap gap-2">
              <div className="hstack gap-2">
                <span className="green-dot d-sm-none" />

                <Avatar
                  src={learnerImage}
                  alt="Tutor Image"
                  className="tutor-img"
                  style={{ width: "20px", height: "20px" }}
                />
                <p className="mb-0 ms-1">{learnerName}</p>
              </div>
              <div className="hstack gap-2">
                {/* <span className="green-dot " /> */}
                <UncontrolledDropdown className="border-0">
                  <DropdownToggle color="" size="sm" className="border-0 p-0">
                    <IconInfoCircle
                      style={{ width: "20px", height: "20px" }}
                      className="text-secondary"
                    />
                  </DropdownToggle>
                  <DropdownMenu className="p-3">
                    <Row className="mx-0 gap-3">
                      <Col className="ps-0" xs="12">
                        <h3 className="font-size-14 fw-bold mb-2 ">
                          Curriculum
                        </h3>
                        {/* @ts-ignore */}
                        <LeadingBadgesList
                          breakAt={1}
                          hasDropdown
                          entities={saneCurriculums}
                        />
                      </Col>
                      <Col className="ps-0" xs="12">
                        <h3 className="font-size-14 fw-bold mb-2 ">Grade</h3>
                        {/* @ts-ignore */}
                        <LeadingBadgesList
                          breakAt={1}
                          hasDropdown
                          entities={saneGrades}
                        />
                      </Col>
                    </Row>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>

              <div className="hstack gap-2">
                <span className="green-dot" />

                <IconCreditCard
                  style={{
                    fontSize: "20px",
                    color: `${
                      sessionInfo.data.remaning_sessions === 0
                        ? "#EA8686"
                        : "#5CDB95"
                    }`,
                  }}
                />
                <p className="m-0 " style={{ color: "#05386b " }}>
                  {sessionInfo.data.remaning_sessions}{" "}
                  {makePluralIf("Credit", sessionInfo.data.remaning_sessions)}{" "}
                  Available
                </p>
              </div>
              <div className="hstack gap-2">
                <span className="green-dot" />

                <IconBook className="font-size-20" />
                <p className="subject m-0">{subjectName}</p>
              </div>
              {sessionInfo.data.rating > 0 && (
                <>
                  <div className="hstack gap-2">
                    <span className="green-dot" />

                    <IconStar className="rating-star font-size-20" />
                    <span className="ms-1">{sessionInfo.data.rating}</span>
                  </div>
                </>
              )}
            </div>

            <div className="mt-2 mt-sm-4">
              <div className="hstack gap-2">
                <h3 className="font-size-14 fw-bold mb-0">Description</h3>
                {canEditSessionInfo && !isEditingSessionInfo && (
                  <EditButton
                    size="sm"
                    onClick={() => setIsEditingSessionInfo(true)}
                    tooltipPosition="bottom"
                    variant="none"
                    // className="p-1 border-1 rounded bg-gray-100 hover:bg-gray-300 focus:bg-gray-300 text-secondary "
                  />
                )}
              </div>
              <div className="mt-1 mt-sm-2">
                {isEditingSessionInfo ? (
                  <Input
                    type="textarea"
                    maxLength={MAX_LIMIT_DESCRIPTIONS}
                    value={sessionNewInfo.description}
                    onChange={(e) =>
                      setSessionNewInfo((prev) => ({
                        ...prev,
                        description: e.target.value,
                      }))
                    }
                  />
                ) : (
                  <p className=" mb-0 font-size-14">
                    {sessionInfo.data.description}
                  </p>
                )}
              </div>
              {simpleValidator.current.message(
                "desctiption",
                sessionNewInfo.description,
                "required"
              )}
            </div>

            <div className="session-time mt-2 mt-sm-4">
              <h3 className="font-size-14 fw-bold mb-1 mb-sm-2 ">Session</h3>
              {/* {!isRescheduling && ( */}
              <>
                <div className="mx-0 d-flex flex-sm-row gap-2 gap-sm-4 flex-wrap">
                  <div>
                    <p className="text-muted mb-0">Date</p>
                    <h3 className="font-size-13 fw-bold m-0 ">
                      {DateTime.fromISO(sessionInfo.data.date).toFormat(
                        "dd MMM yyyy"
                      )}
                      {/* {moment(sessionInfo.data.date, "YYYY-MM-DD").format(
                        "DD MMM YYYY"
                      )} */}
                    </h3>
                  </div>
                  <div>
                    <p className="text-muted mb-0">Time</p>
                    <h3 className="font-size-13 fw-bold m-0 ">
                      {getAdjustedTime(
                        sessionInfo.data.start_time,
                        "HH:mm:ss"
                      ).format("hh:mm A")}{" "}
                    </h3>
                  </div>
                  <div>
                    <p className="text-muted mb-0">Duration</p>
                    <h3 className="font-size-13 fw-bold m-0 ">
                      {sessionInfo.data.duration} Min
                    </h3>
                  </div>
                  <div>
                    <p className="text-muted mb-1">Status</p>
                    <div className="d-flex align-items-center">
                      {/* TODO: ...... */}
                      {/* @ts-ignore   */}
                      <SessionStatus
                        {...{
                          requestStatusId: sessionInfo.data.request_status,
                          meetingStatusId:
                            sessionInfo.data.meeting?.meeting_status?.id,
                          meetingStatusTitle:
                            sessionInfo.data.meeting?.meeting_status?.title,
                          isExpired: isPreviousSession,
                        }}
                      />
                      {requestStatusId === 2 &&
                        meetingStatusId === 2 &&
                        !!sessionInfo.data.rating && (
                          <div className="ms-1">
                            <IconStar className="rating-star font-size-20" />
                            <span className="ms-1">
                              {sessionInfo.data.rating}
                            </span>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </>
              {/* )} */}
              {isRescheduling && (
                <div className="mt-2 mt-sm-4">
                  {!availableSlots.isLoaded ? (
                    <Loader isInline />
                  ) : !availableSlots.isErred ? (
                    <>
                      <h3 className="font-size-14 fw-bold m-0">New Timings</h3>
                      <BookSession
                        duration={sessionInfo.data?.duration}
                        slotsClass="book-session-tutoring-learner"
                        availableSlots={availableSlots}
                        parentComponent="tutoring"
                        selectedSlot={selectedSlot}
                        setSelectedSlot={setSelectedSlot}
                        selectedDate={slotsStartDate}
                        setSelectedDate={setSlotsStartDate}
                      />
                    </>
                  ) : (
                    <Navigate to="/400" replace={true} />
                  )}
                </div>
              )}
            </div>

            {/* ---------- Alhamdulillah separated to a component ------------- */}

            <div className="mt-3 mt-sm-4">
              <SessionActionsButtons
                session={sessionInfo.data}
                isEditing={isRescheduling}
                isPreviousSession={isPreviousSession}
                onEditTime={() => setIsRescheduling((prev) => !prev)}
                onReject={() => setRejectModalState(true)}
                onAccept={handleRequestConfirm}
                onCancel={handleRequestCancel}
                onReschedule={handleRequestReschedule}
              />
            </div>

            <RejectRequestModal
              isOpen={rejectModalState}
              toggleModal={toggleModal}
              requestId={sessionInfo.data.request_id}
            />
          </>
        )}
      </div>
    </Container>
    // </section>
  );
}

export default TutoringTutorSessionRequest;

// <section className="content">
/* <PageHeader
		title="Session Details"
		subTitle="Here are details of the session"
		routes={[
		  {
			name: "1:1 Tutoring",
			path: "/1-1-tutoring",
		  },
		  {
			active: true,
			name: "Session Details",
			path: "/1-1-tutoring/session-request",
		  },
		]}
	  /> */

//   <div className="student-info d-flex flex-column flex-sm-row  align-items-sm-center">
//   <Avatar className="avatar-sm" src={learnerImage} />
//   <div className="student-details ms-sm-3 mt-2 mt-sm-0">
//     <div className="d-flex flex-column flex-sm-row flex-wrap align-items-sm-center mb-2 gap-2">
//       <h3
//         className="pe-2 m-0"
//         style={{borderRight: "1px solid #d5dde5 "}}
//       >
//         {learnerName}
//       </h3>
//       <div className="hstack gap-2">
//         <IconCreditCard
//           style={{
//             fontSize: "20px",
//             color: `${
//               sessionInfo.data.remaning_sessions === 0
//                 ? "#EA8686"
//                 : "#5CDB95"
//             }`,
//           }}
//         />
//         <p className="m-0 ps-1" style={{color: "#05386b "}}>
//           {" "}
//           {sessionInfo.data.remaning_sessions} Credits Available
//         </p>
//       </div>
//     </div>
//     /* <div>
//       <p className="text-muted m-0">
//         Last Session{" "}
//         <span
//           className="text-secondary"
//           style={{color: "#05386b "}}
//         >
//           26th Jun 2022
//         </span>{" "}
//       </p>
//     </div> */
//   </div>
// </div>

//             <div className="d-flex flex-wrap gap-3 mt-4 justify-content-between">
//               {/* Don't remove it. Commented on temporary purposes. */}
//               {/* <Col className="ps-0">
//  <p className="text-muted mb-0">Topic</p>
//  <h3 className="font-size-14 fw-bold m-0 ">
//    {sessionInfo.data.agenda}
//  </h3>
//         // </Col> */}
//               <div className="">
//                 <p className="text-muted mb-0">Curricula</p>
//                 <LeadingBadgesList
//                   breakAt={1}
//                   hasDropdown
//                   entities={saneCurriculums}
//                 />
//                 {/* <h3 className="font-size-14 fw-bold mb-2 ">
//            {getKeyFromArrUsingId(
//              totalCurriculums,
//              "title",
//              sessionInfo.data.curriculum_id
//            )}
//          </h3> */}
//               </div>
//               <div>
//                 <p className="text-muted mb-0">Grades</p>
//                 <LeadingBadgesList
//                   breakAt={1}
//                   hasDropdown
//                   entities={saneGrades}
//                 />
//                 {/* <h3 className="font-size-14 fw-bold m-0 ">
//            {getKeyFromArrUsingId(
//              totalGrades,
//              "grade",
//              sessionInfo.data.grade_id
//            )}
//          </h3> */}
//               </div>
//               <div>
//                 <p className="text-muted mb-0">Subject</p>
//                 <div className="font-size-14 fw-bold mb-2">{subjectName}</div>
//               </div>
//             </div>

/* <div className="mt-4">
			  <h3 className="font-size-14 fw-bold mb-2 ">Agenda</h3>
			  <p className=" mb-0 font-size-14">{sessionInfo.data.agenda}</p>
			</div> */

/* <div className="mt-2 mt-sm-4">
			  <h3 className="font-size-14 fw-bold mb-2 ">Status</h3>
			  <div className="d-flex align-items-center">
				<SessionStatus
				  {...{
					requestStatusId,
					meetingStatusId,
					meetingStatusTitle,
				  }}
				/>
				{requestStatusId === 2 &&
				  meetingStatusId === 2 &&
				  !!sessionInfo.data.rating && (
					<div className="ms-1">
					  <IconStar className="rating-star font-size-20" />
					  <span className="ms-1">{sessionInfo.data.rating}</span>
					</div>
				  )}
			  </div>
			</div> */

/* <div className="session-time mt-2 mt-sm-4">
			  <h3 className="font-size-14 fw-bold mb-2 ">Session Time</h3>
			  {!isRescheduling && (
				<>
				  <div className="mx-0 d-flex ">
					<div className="me-3">
					  <p className="text-muted mb-0">Date</p>
					  <h3 className="font-size-14 fw-bold m-0 ">
						{moment(sessionInfo.data.date, "YYYY-MM-DD").format(
						  "DD MMM YYYY"
						)}
					  </h3>
					</div>
					<div className="ms-5">
					  <p className="text-muted mb-0">Time</p>
					  <h3 className="font-size-14 fw-bold m-0 ">
						{getAdjustedTime(
						  sessionInfo.data.start_time,
						  "HH:mm:ss"
						).format("hh:mm A")}{" "}
						to{" "}
						{getAdjustedTime(
						  sessionInfo.data.end_time,
						  "HH:mm:ss"
						).format("hh:mm A")}
					  </h3>
					</div>
				  </div>
				</>
			  )}

			  {isRescheduling && (
				<>
				  {!availableSlots.isLoaded ? (
					<Loader isInline />
				  ) : !availableSlots.isErred ? (
					<BookSession
					  slotsClass="book-session-tutoring-learner"
					  availableSlots={availableSlots}
					  parentComponent={"tutoring"}
					  selectedSlot={selectedSlot}
					  setSelectedSlot={setSelectedSlot}
					  selectedDate={selectedDate}
					  setSelectedDate={setSelectedDate}
					/>
				  ) : (
					<Navigate to="/400" replace={true} />
				  )}
				</>
			  )}
			</div> */
