import * as React from "react";

// Components.
import {
  CardTitle,
  ListGroup,
  CardHeader,
  ListGroupItem,
  Row,
  Col,
} from "reactstrap";

// Utils.
import moment from "moment";
import {calculateExperience} from "utils";

// Static.
import Circle from "assets/images/circle1.svg";
import {DeleteButton, EditButton} from "components";
import {IconBuildingCommunity} from "@tabler/icons";

/**
 * @typedef {Object} TutorExperienceListProps
 *
 * @property {boolean} isEditable
 * @property {Function} onClickEdit
 * @property {TutorExperience[]} experiences
 * @property {(experience: TutorExperience) => void} onEditExperience
 * @property {(experience: TutorExperience) => void} onRemoveExperience
 */

/**
 * A list displaying all the past experiences of a tutor.
 *
 * @param {TutorExperienceListProps} props
 * @returns {JSX.Element}
 *
 * @author Abdullah-Sajjad026
 * @version 0.0.1
 */
function TutorExperienceList({
  isEditing,
  experiences,
  onEditExperience,
  onRemoveExperience,
}) {
  return (
    <div className="position-relative">
      {experiences.length > 0 && (
        <>
          {isEditing && (
            <CardHeader>
              <CardTitle className="hstack justify-content-between">
                <div>Experiences</div>
              </CardTitle>
            </CardHeader>
          )}
          <ListGroup>
            {experiences
              .sort((a, b) => new Date(b.from) - new Date(a.from))
              .map((experienceItem, index) => {
                const {
                  to,
                  from,
                  company,
                  job_title,
                  experience,
                  currently_working,
                } = experienceItem;

                const resultExperience = calculateExperience(from, to);

                return (
                  <ListGroupItem
                    key={index}
                    className="p-0 py-3 border-top-0 border-end-0 border-start-0 text-secondary vstack gap-1 position-relative"
                  >
                    <Row className="w-100 mx-0">
                      <Col xs="12" sm="9">
                        <div className="d-flex flex-column flex-sm-row gap-4 align-items-start">
                          {/* <img
                            alt=""
                            width={16}
                            height={16}
                            src={School}
                            aria-hidden={true}
                          /> */}
                          <div>
                            <IconBuildingCommunity size="22" />
                          </div>
                          <div className="vstack gap-2">
                            <div className="d-flex flex-column flex-sm-row gap-2 fs-5.5 fw-bold">
                              {job_title && (
                                <div className="pe-2 border-end border-3 border-radius-sm">
                                  {job_title}
                                </div>
                              )}
                              {company && <div>{company}</div>}
                            </div>
                            <div className="d-flex flex-column flex-sm-row align-items-sm-center gap-2 fw-semi-bold text-blue">
                              {moment(from).format("MMM YYYY")} -{" "}
                              {currently_working === 1
                                ? "Present"
                                : to && moment(to).format("MMM YYYY")}
                              <div className="d-flex align-items-center gap-2">
                                <img
                                  alt=""
                                  width={8}
                                  height={8}
                                  src={Circle}
                                  aria-hidden={true}
                                />
                                <div>{resultExperience}</div>
                              </div>
                            </div>
                            <p className="mb-0 fs-5.5 text-blue mt-2">
                              {experience}
                            </p>
                          </div>
                        </div>
                      </Col>
                      <Col xs="12" sm="3" className="mt-3 mt-sm-0">
                        {isEditing && (
                          <div className="hstack gap-2 z-1">
                            {onEditExperience && (
                              <EditButton
                                onClick={() => onEditExperience(experienceItem)}
                              />
                            )}
                            {onRemoveExperience && (
                              <DeleteButton
                                onClick={() =>
                                  onRemoveExperience(experienceItem.id)
                                }
                              />
                            )}
                          </div>
                        )}
                      </Col>
                    </Row>
                  </ListGroupItem>
                );
              })}
          </ListGroup>
        </>
      )}
    </div>
  );
}

export default TutorExperienceList;
