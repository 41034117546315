export const SET_SESSIONS_COLLECTION = "SET_SESSIONS_COLLECTION";
// Offered Sessions.
export const ERRED_OFFERED_SESSIONS = "ERRED_OFFERED_SESSIONS";
export const SET_OFFERED_SESSIONS = "SET_OFFERED_SESSIONS";
// Connected Learners
export const SET_CONNECTED_LEARNERS = "SET_CONNECTED_LEARNERS";
export const ERRED_SET_CONNECTED_LEARNERS = "ERRED_SET_CONNECTED_LEARNERS";
// Available Slots
export const SET_AVAILABLE_SLOTS = "SET_AVAILABLE_SLOTS";
export const RESET_AVAILABLE_SLOTS = "RESET_AVAILABLE_SLOTS";
export const ERRED_AVAILABLE_SLOTS = "ERRED_AVAILABLE_SLOTS";
// Recent Activities
export const SET_RECENT_ACTIVITIES = "SET_RECENT_ACTIVITIES";
export const RESET_RECENT_ACTIVITIES = "RESET_RECENT_ACTIVITIES";
export const ERRED_RECENT_ACTIVITIES = "ERRED_RECENT_ACTIVITIES";
// Calendar Listings.
export const SET_TUTOR_CALENDAR_LISTINGS = "SET_TUTOR_CALENDAR_LISTINGS";
export const ERRED_TUTOR_CALENDAR_LISTINGS = "ERRED_TUTOR_CALENDAR_LISTINGS";
