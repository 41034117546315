import { MAX_LIMIT_DESCRIPTIONS, TWO_MB } from "constants";
import { messages } from "constants/strings";
import { string, date, boolean, mixed, object } from "yup";
import ALLOWED_FORMATS from "./form-tutor-experience-formats";

const today = new Date();

const validationSchema = object({
  company: string().required("Please enter the Company Name"),
  country: object().required("Please select a Country"),
  job_title: string().required("Please enter the Job Title"),
  experience: string()
    .required("Please enter the Experience details")
    .max(
      MAX_LIMIT_DESCRIPTIONS,
      `Maximum ${MAX_LIMIT_DESCRIPTIONS} characters`
    ),
  currently_working: boolean().optional(),
  from: date()
    .max(today, messages.NOT_VALID_DATE)
    .required("Please select a Start Date"),
  to: date()
    .nullable()
    .default(null)
    .when(["from", "currently_working"], (from, currently_working, schema) => {
      // Required when currently_working isn't selected.
      if (!currently_working) {
        return (
          schema
            .required("Please select an End Date")
            // The field might get touched while the "from" isn't yet touched.
            .min(
              from || today,
              "Please select a valid date (Date > Start Date)"
            )
            // The field might get touched while the "from" isn't yet touched.
            .max(today, messages.NOT_VALID_DATE)
        );
      }

      return schema.optional();
    }),
  document: mixed()
    .test({
      name: "fileSize",
      message: messages.FILE_SIZE,
      test: (value) => !value || value.size <= TWO_MB,
    })
    .test({
      name: "fileFormat",
      message: messages.FILE_FORMAT,
      test: (value) => !value || ALLOWED_FORMATS.includes(value.type),
    }),
});

export default validationSchema;
