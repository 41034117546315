import React from "react";
import { SassyCard } from "components/cards";
import { useNavigate } from "react-router-dom";
import { Button, Card, Col, Container, Row } from "react-bootstrap";

// Static.
import userIdleLargeDoodle from "assets/images/doodles/@x2/doodle.png";
import userPhoneLargeDoodle from "assets/images/doodles/@x2/user-phone.png";
import { RLearnerTutoring } from "constants/learner-views-routes";

export default function TransactionsEmptyView() {
  const navigate = useNavigate();

  return (
    <article className="p-3 p-md-4">
      <Container>
        <Row className="g-3 g-md-4 justify-content-center">
          <Col xs={12} sm={6} lg={4}>
            <SassyCard className="h-100 position-relative">
              <SassyCard.Body
                style={{ zIndex: 1 }}
                className="align-items-center align-items-md-start justify-content-between justify-content-lg-start"
              >
                <Card.Title className="m-0 text-center text-md-start">
                  Did you know that a tutor can help you improve your grades by
                  33%?
                </Card.Title>
                <div className="align-self-stretch d-flex gap-3 flex-column align-items-center align-items-md-start mt-lg-3">
                  <Card.Text className="m-0 text-center text-md-start">
                    Get learning and schedule your first session.
                  </Card.Text>
                  <Button
                    variant="secondary"
                    className="w-75 w-md-auto"
                    onClick={() =>
                      navigate(RLearnerTutoring.trendingTutorsView)
                    }
                    // onClick={() => navigate(RLearnerTutoring.getSessionsView())}
                  >
                    Find a tutor
                  </Button>
                </div>
              </SassyCard.Body>
              <img
                alt=""
                src={userPhoneLargeDoodle}
                className="d-none d-lg-block position-absolute w-75 end-0 bottom-0"
              />
            </SassyCard>
          </Col>
          <Col xs={12} sm={6} lg={4}>
            <SassyCard className="h-100 position-relative">
              <SassyCard.Body
                style={{ zIndex: 1 }}
                className="align-items-center align-items-md-start"
              >
                <Card.Title className="m-0">Payment Methods</Card.Title>
                <img alt="" src={userIdleLargeDoodle} className="w-100 h-75" />
                <Button
                  variant="secondary"
                  onClick={() => navigate("/transactions/payment-methods")}
                >
                  Add a payment method
                </Button>
              </SassyCard.Body>
            </SassyCard>
          </Col>
        </Row>
      </Container>
    </article>
  );
}
