import {RawLearnerRecentTutor, USER_AVATAR} from "constants/";
import {RLearnerTutoring} from "constants/learner-views-routes";
import React from "react";
import {Link} from "react-router-dom";
// import {getTutorProfileUrl, makePluralIf} from "utils";

function RecentTutor({
  tutor,
  toggle,
}: {
  tutor: RawLearnerRecentTutor;
  toggle: () => void;
}) {
  // const subject = "Maths";
  // const credits = "3";

  const {user_id, profile_picture, name} = tutor;

  return (
    <Link
      className="recent-tutor d-inline-block mx-2 my-1 p-3"
      // onClick={() => window.location.assign(getTutorProfileUrl(name, user_id))}
      to={RLearnerTutoring.getTutorAvailableSessionsView(user_id)}
      onClick={toggle}
    >
      <div className="d-flex flex-column align-items-center">
        <img
          src={
            profile_picture?.includes("http") ? profile_picture : USER_AVATAR
          }
          alt=""
          className="tutor-img rounded-circle mb-2"
          style={{width: "40px", height: "40px", objectFit: "cover"}}
        />
        <h5 className="text-secondary">{name || "Unknown"}</h5>
        {/* <p className="text-muted mb-0">
          {subject}{" "}
          <span className="text-secondary">
            ({credits + makePluralIf("credits", credits > 0)})
          </span>
        </p> */}
      </div>
    </Link>
  );
}

export default RecentTutor;
