import {useState} from "react";
import {Button, Input, Label, Modal, ModalBody} from "reactstrap";
import {MyModalHeader} from "./";
import {updateSessionRequestStatus} from "store";
import {connect} from "react-redux";
import ToastFactory from "utils/toast-factory";
import {useNavigate} from "react-router-dom";
import {RLearnerTutoring} from "constants/learner-views-routes";

function RejectRequestModal({
  isOpen,
  toggleModal,
  requestId,
  updateSessionRequestStatus,
}) {
  const toastFactory = ToastFactory();
  const navigate = useNavigate();
  const [selectedReason, setSelectedReason] = useState("");
  const [selectedInput, setSelectedInput] = useState("");

  const labels = [
    "Time conflicts",
    "Issues with tutor",
    "Session is no longer required",
    "Found another tutor",
    "Other",
  ];

  const handleSubmit = () => {
    updateSessionRequestStatus({
      requestId: requestId,
      status: 3,
      reason: selectedReason,
      onSuccess: () => {
        toastFactory.success();
        toggleModal();
        navigate(RLearnerTutoring.baseRoute, {replace: true});
      },
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      toggleModal={toggleModal}
      className="text-secondary"
      size="lg"
    >
      <ModalBody className="p-3 gap-column-xl">
        <MyModalHeader
          title="Are you sure you want to cancel this session?"
          toggleModal={toggleModal}
        />

        {/* Commented for temporary purposes */}

        {/* <div
          style={{backgroundColor: "#ECF9F3"}}
          className="p-3 my-3 d-flex align-items-center rounded justify-content-between"
        >
          <span className="p-2 rounded bg-white d-flex align-items-center justify-content-center me-3">
            {" "}
            <HiOutlineBell className="font-size-17" />
          </span>
          <div>
            <h3>
              Your amount will be refunded within 72 hours from the date of
              cancellation{" "}
            </h3>
            <p className="mb-0">
              For any queries you can contact to Tutor support team, they will
              guide you on this topic
            </p>
          </div>
        </div> */}
        <div className="gap-column-md">
          <h2 className="h6">Select your reason</h2>
          <div>
            {labels.map((label, index, arr) => (
              <div key={index}>
                <div className="gap-row-sm py-3">
                  <Input
                    name="radio"
                    type="radio"
                    id={`radio-${index}`}
                    onChange={(e) => {
                      setSelectedReason(label);
                      setSelectedInput(index);
                    }}
                  />
                  <Label check htmlFor={`radio-${index}`}>
                    {label}
                  </Label>
                </div>
                {index !== arr.length - 1 && <hr className="divider" />}
              </div>
            ))}
            {selectedInput === 4 && (
              <textarea
                className="form-control form-control-noicon"
                onChange={(e) => setSelectedReason(e.target.value)}
              />
            )}
          </div>
        </div>
        <footer className="d-flex justify-content-start gap-2 mt-3">
          <Button
            color="secondary"
            style={{minWidth: "5rem"}}
            outline
            onClick={handleSubmit}
          >
            Yes
          </Button>
          <Button
            color="secondary"
            style={{minWidth: "5rem"}}
            onClick={toggleModal}
          >
            No
          </Button>
        </footer>
      </ModalBody>
    </Modal>
  );
}

export default connect((state) => ({}), {
  updateSessionRequestStatus,
})(RejectRequestModal);
