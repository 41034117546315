import { Card } from "reactstrap";

/**
 * @param {import('reactstrap').CardProps} props
 *
 * @returns {JSX.Element}
 */
export default function CardWrapper({
  children,
  className = "",
  tag = "article",
  ...props
}) {
  return (
    <Card
      {...props}
      className={`mb-0 h-100 border-1 rounded position-relative overflow-hidden ${className}`}
    >
      {children}
    </Card>
  );
}
