import {IconHandClick} from "@tabler/icons";
import React from "react";
import styles from "./trigger-scroll-animation.module.scss";

const TriggerScrollAnimation = () => {
  return (
    <div className={`${styles["trigger-scroll"]} bg-soft bg-success `}>
      <IconHandClick size="20" />
    </div>
  );
};

export default TriggerScrollAnimation;
