import * as React from "react";
import { isObjectValid } from "utils";
import ToastFactory from "utils/toast-factory";
import { useNavigate } from "react-router-dom";
import PhoneNumberService from "service/phone-number";
import PhoneNumberAdapter from "adapters/phone-number";
import PhoneNumberSchema from "./phone-number-form-schema";

// Types.
import type { FormikHelpers } from "formik";
import type { PhoneNumber } from "constants/types/types-phone-number";
import type { PhoneNumberFormState } from "./phone-number-form-types";
import { RawPhoneNumberContext } from "context/phone-number";
import { BaseFormProps } from "constants/types/types";

type Props = {
  children: ({
    schema,
    onSubmit,
    initialValue,
  }: {
    initialValue: string;
    schema: PhoneNumberSchema;
    onSubmit: (
      values: PhoneNumberFormState,
      formikHelpers: FormikHelpers<PhoneNumberFormState>
    ) => void;
  } & PhoneNumber) => JSX.Element;
} & Pick<BaseFormProps, "onClose">;

/**
 * Data provider for the phone number form.
 *
 * @returns {JSX.Element}
 */
export default function PhoneNumberFormProvider({
  onClose,
  children,
}: Props): JSX.Element {
  const navigate = useNavigate();
  const { state } = React.useContext(RawPhoneNumberContext);

  const toastFactory = ToastFactory();

  const schema = new PhoneNumberSchema();
  const service = new PhoneNumberService();
  const adapter = new PhoneNumberAdapter();

  if (!adapter.isRawPhoneNumber(state)) {
    console.warn(
      "PhoneNumberForm requires a valid phone number of type RawPhoneNumber"
    );
    onClose();
  }

  const phoneNumber = adapter.deserialize(state);
  const initialValue = `${phoneNumber.countryCode || ""}${
    phoneNumber.mobileNumber || ""
  }`;

  function onSubmit(
    values: PhoneNumberFormState,
    _: FormikHelpers<PhoneNumberFormState>
  ) {
    toastFactory.loading();

    const rawPhoneNumber = adapter.serialize({
      countryCode: values.countryCode,
      mobileNumber: values.mobileNumber,
    });

    service.upsertPhoneNumber({
      rawPhoneNumber,
      onFailure: ({ message }) => toastFactory.error(message),
      onSuccess: ({ data, message }) => {
        // Number already verified.
        if (isObjectValid(data)) {
          toastFactory.success(message);
          return;
        }

        // An OTP has been sent.
        toastFactory.info(message);

        // Navigate to the OTP verification page.
        navigate("verify", {
          state: rawPhoneNumber,
        });
      },
    });
  }

  return children({
    schema,
    onSubmit,
    initialValue,
    ...phoneNumber,
  });
}
