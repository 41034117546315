/* eslint-disable eqeqeq */
import axios from "axios";
import strings from "constants/strings";
import { isObjectValid } from "../utils";

axios.interceptors.request.use((request) => {
  // CMS rejects any additional headers.
  if (request.url.match(/(https:\/\/cms.thetutor.me)/)) return request;
  if (request.url.match(/(cms)[./](thetutor.me)/)) return request;

  request.headers["X-User-Timezone"] = localStorage.getItem("TIMEZONE");
  return request;
});

axios.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error

    if (error.response.status === 440) {
      window.location.href = "/440";
    }

    return Promise.reject(error);
  }
);

async function service({ proposal, boolBacks }) {
  const { onSuccess, onFailure } = boolBacks;

  try {
    const response = await axios(proposal);

    if (response.status === 200) {
      const { data, status } = response;

      // Well, an extra layer of protection never hurts 😉
      if (data && status == 200) {
        onSuccess?.(data);
        return;
      }
    }

    // Why do we fall Bruce? Only to rise back up.
    console.error(JSON.stringify(response));
    onFailure?.(JSON.stringify(response));
  } catch (error) {
    // This is the recommended error handling approach by axios.
    if (error.response) {
      // Status code is not 200.
      console.error(strings.DEFAULT_ERROR_MESSAGE, error);
      onFailure?.({
        message: error.response.data?.message || strings.DEFAULT_ERROR_MESSAGE,
        error: error.response,
      });
      return;
    }

    if (error.request) {
      // No response was received.
      console.error("Server timed out!", error);
      onFailure?.({ message: "Server timed out!" });
      return;
    }

    if (error.message) {
      // Error setting up the request.
      console.error("Error setting up the service!", error);
      onFailure?.({ message: strings.DEFAULT_ERROR_MESSAGE });
      return;
    }

    console.error("There was an unidentified error", error);
    onFailure?.({ message: strings.DEFAULT_ERROR_MESSAGE });
  }
}

function getProposal({ url, headers, method }) {
  if (!url) throw new Error("ApiService requires a valid url to proceed!");

  const proposal = {};

  if (isObjectValid(headers)) proposal["headers"] = headers;

  proposal["url"] = url;
  proposal["method"] = method;

  return proposal;
}

/**
 * Perform a GET request.
 *
 * @param { Omit<ServiceInitiator, 'data'> & Boolbacks } object The parameters passed.
 * @param {{ key: string, value: string | Array }[]} object.data
 * The body of the request, must be an
 * array of the objects of the form {key, value} where;
 * - key: the string-based URL query parameter,
 * - value: the value for the URL query parameter
 */
function get({ url, data, headers, ...boolBacks }) {
  const params = {};

  data?.[0]?.key &&
    data?.[0]?.value &&
    data.forEach(({ key, value }) => {
      if (value)
        params[key] = Array.isArray(value) ? JSON.stringify(value) : value;
    });

  return service({
    boolBacks,
    proposal: getProposal({
      headers,
      method: "get",
      url: isObjectValid(params)
        ? `${url}?${new URLSearchParams(params)}`
        : url,
    }),
  });
}

/**
 * Perform a POST request.
 *
 * @param { ServiceInitiator & Boolbacks } object The parameters passed.
 */
function post({ url, data, headers, method = "post", ...boolBacks }) {
  return service({
    boolBacks,
    proposal: { ...getProposal({ url, method, headers }), data },
  });
}

/**
 * Perform a POST request but also send some query parameters.
 *
 * @param { ServiceInitiator & Boolbacks } object The parameters passed.
 * @param {{ key: value }[]} object.params The query parameters.
 */
function postAndGet({ url, params, ...props }) {
  // URLSearchParams accepts a Record<string, string>
  const queryParams = {};

  // We have to make the record directly from the array of objects passed as the params.
  params.forEach((param) =>
    Object.entries(param).forEach(
      ([key, value]) =>
        (queryParams[key] = Array.isArray(value)
          ? // An array of keys needs to be passed as it is, such as [1,2,3,4,5]
            JSON.stringify(value)
          : value)
    )
  );

  post({
    ...props,
    url: `${url}?${new URLSearchParams(queryParams)}`,
  });
}

/**
 * Perform a DELETE request.
 *
 * @param { Omit<ServiceInitiator, 'data'> & Boolbacks } object The parameters passed.
 *
 * @param {number} object.recordId The record to delete.
 */
function deleteRecord({ url, recordId, headers, ...boolBacks }) {
  const finalUrl = recordId ? `${url}/${recordId}` : url;

  return service({
    boolBacks,
    proposal: getProposal({
      headers,
      url: finalUrl,
      method: "delete",
    }),
  });
}

const apiService = {
  get,
  post,
  postAndGet,
  delete: deleteRecord,
};

export default apiService;
