import React from "react";
import { Loader } from "components";
import { lazy, Suspense } from "react";

// @ts-ignore
const View = lazy(() => import("./TermsHeadless"));

const TermsHeadless = (props) => (
  <Suspense fallback={<Loader />}>
    <View {...props} />
  </Suspense>
);

export default TermsHeadless;
