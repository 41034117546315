/* eslint-disable eqeqeq */
import * as React from "react";

// Components.
import { FormErrorMessage, SassyForm, SassySelect } from "components";
import { ErrorMessage, Formik } from "formik";
import { Row, Col, Button, CardTitle, FormGroup } from "reactstrap";

// Utils.
import { getCountryNames } from "utils";
import validationSchema from "./form-tutor-experience-schema";
import ALLOWED_FORMATS from "./form-tutor-experience-formats";
import { MAX_LIMIT_TITLES, MAX_LIMIT_DESCRIPTIONS } from "constants";
import { boxStyles } from "constants/config";

/**
 * Form that manages the function of editing and adding the tutor's experience.
 *
 * @param {Object} props
 * @param {boolean} props.allowFormClose
 * @param {Function} props.onSubmit
 * @param {Function} props.onReset
 * @param {Function} props.onCloseForm
 * @param {TutorExperience} props.state
 * @param {"add" | "edit"} props.variant
 *
 * @returns {JSX.Element}
 */
function TutorExperienceForm({
  state,
  variant = "add",
  onSubmit,
  onReset,
  onCloseForm,
  allowFormClose = true,
}) {
  const COUNTRIES = React.useMemo(
    () =>
      getCountryNames.map((element, index) => ({
        label: element,
        value: element,
      })),
    []
  );

  /**
   * 
   * @param {TutorExperience} values
} param0 
   */

  return (
    <Formik
      enableReinitialize
      initialValues={state}
      onSubmit={(values, { resetForm }) => {
        onSubmit(values);
        resetForm();
      }}
      validationSchema={validationSchema}
    >
      {({ values, handleChange, setFieldValue, setFieldTouched }) => (
        <SassyForm className="vstack gap-3" data-testid="experiencesForm">
          <header className="hstack justify-content-between">
            <CardTitle className="text-capitalize">
              {variant} Experience
            </CardTitle>
          </header>
          <Row>
            <Col sm={6}>
              <SassyForm.Input
                name="company"
                label="Company name"
                placeholder="Enter the company name"
                maxLength={MAX_LIMIT_TITLES}
              />
            </Col>
            <Col sm={6}>
              <FormGroup data-testid="countryParent">
                <SassySelect
                  id="country"
                  name="country"
                  label="Country"
                  isMulti={false}
                  data-testid="countrySelect"
                  closeMenuOnSelect={true}
                  options={COUNTRIES}
                  selectedOptions={values.country}
                  onBlur={() => setFieldTouched("country")}
                  onChange={(value) => setFieldValue("country", value)}
                />
                <ErrorMessage
                  name="country"
                  render={(message) => (
                    <FormErrorMessage
                      data-testid="countryError"
                      {...{ message }}
                    />
                  )}
                />
              </FormGroup>
              {/* <SassyForm.Select
                name="country"
                label="Country"
                isMulti={false}
                value={{label: values.country, value: values.country}}
                options={COUNTRIES}
                placeholder="Select a country"
                closeMenuOnSelect={true}
                {...{handleChange, setFieldTouched}}
              /> */}
            </Col>
            <Col xs={12}>
              <Row>
                <Col sm={6}>
                  <SassyForm.Input
                    name="job_title"
                    label="Job title"
                    placeholder="Enter the job title"
                    maxLength={MAX_LIMIT_TITLES}
                  />
                </Col>
              </Row>
            </Col>
            <Col sm={6}>
              <SassyForm.Input
                name="from"
                type="date"
                label="From"
                placeholder="Select a start date"
              />
            </Col>
            <Col sm={6}>
              <SassyForm.Input
                name="to"
                label="To"
                type="date"
                placeholder="Select an end date"
                disabled={values.currently_working}
              />
              <SassyForm.Checkbox
                name="currently_working"
                label="I currently work here"
                placeholder="Select an end date"
              />
            </Col>
            <Col xs={12}>
              <SassyForm.Input
                type="textarea"
                name="experience"
                label="Describe your experience"
                placeholder="Describe the experience"
                value={values.experience}
                maxLength={MAX_LIMIT_DESCRIPTIONS}
              />
              {/* <div className="fs-6 text-end mt-1">
                {values.experience ? values.experience.length : 0}/
                {MAX_BIO_CHARACTERS}
              </div> */}
            </Col>
            <Col sm={6}>
              <SassyForm.Input
                name="company_url"
                placeholder="Enter the URL"
                label="Company/Organization's website"
                maxLength={MAX_LIMIT_TITLES}
              />
            </Col>
            <Col sm={6}>
              <SassyForm.File
                maxSize={2}
                name="document"
                accept={ALLOWED_FORMATS.join(",")}
                label={
                  <div className="d-flex gap-1">
                    Upload Certificate
                    <span className="text-lowercase">(jpg, png, pdf)</span>
                  </div>
                }
                {...{ values, handleChange, setFieldTouched }}
              />
              {state.fileName && (
                <p>
                  <span className="fw-bold">Existing Document: </span>{" "}
                  <span>{state.fileName?.substring(0, 20)} ... </span>
                </p>
              )}
            </Col>
            <Col
              xs="12"
              className={`d-flex align-items-center justify-content-end ${boxStyles.xGap}`}
            >
              {allowFormClose && variant === "add" && (
                <Button
                  outline
                  type="button"
                  color="secondary"
                  onClick={onCloseForm}
                  data-testid="doneButton"
                >
                  Cancel
                </Button>
              )}
              {variant === "edit" && onReset && (
                <Button
                  outline
                  type="reset"
                  color="secondary"
                  onClick={onReset}
                  data-testid="cancelButton"
                >
                  Cancel Editing
                </Button>
              )}
              <Button type="submit" data-testid="submitButton">
                Save
              </Button>
            </Col>
          </Row>
        </SassyForm>
      )}
    </Formik>
  );
}

export default TutorExperienceForm;
