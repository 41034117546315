import React from "react";
import { Loader } from "components";

const View = React.lazy(() => import("./view-transactions-dashboard"));

const TransactionsDashboardView = (props) => (
  <React.Suspense fallback={<Loader />}>
    <View {...props} />
  </React.Suspense>
);

export default TransactionsDashboardView;
