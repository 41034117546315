import React from "react";
import { Form } from "formik";
import { boxStyles } from "constants/config";

export default function BaseForm({
  children,
  className = "",
  ...props
}: Parameters<typeof Form>[0]) {
  return (
    <Form {...props} className={`d-flex flex-column ${boxStyles.yGap}`}>
      {children}
    </Form>
  );
}
