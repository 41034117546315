import {useStaticDataV3} from "hooks/static-data";
import {Navigate} from "react-router-dom";
import React from "react";
import {Loader} from "components";
import {useDispatch, useSelector} from "react-redux";
import {searchForTutors} from "store";
import {useApiEffect} from "hooks";

function LearnerSubjectsTutorsViewModel({children}) {
  const dispatch = useDispatch();

  const {subjects, getStaticData} = useStaticDataV3(["subjects"]);

  const storedSubjects = useSelector(
    (state) => state.learnerDashboard.userInfo.user.subject
  );
  const learnerSubjectsTutors = useSelector(
    (state) => state.learnerTutoring.learnerSubjectsTutors
  );

  const [viewState, setViewState] = React.useState({
    subjectsCount: storedSubjects.length,
    fetchedCount: 0,
  });

  useApiEffect(() => {
    if (
      subjects.state === "loaded" &&
      learnerSubjectsTutors.state !== "loaded"
    ) {
      const learnerSubjects = storedSubjects.map(({subject_id}) =>
        getStaticData(subject_id, subjects.data)
      );
      learnerSubjects.forEach((s) =>
        dispatch(
          searchForTutors({
            subjects: [s],
            onSuccess: () => {
              setViewState((prev) => ({
                ...prev,
                fetchedCount: prev.fetchedCount + 1,
              }));
            },
            onFailure: () => {
              setViewState((prev) => ({
                ...prev,
                fetchedCount: prev.fetchedCount + 1,
              }));
            },
          })
        )
      );
    }
  }, []);

  if (
    viewState.subjectsCount === viewState.fetchedCount &&
    learnerSubjectsTutors.state === "loading"
  ) {
    dispatch({type: "LEARNER_SUBJECTS_TUTORS_LOADED"});
  }

  if (subjects.state === "erred") return <Navigate to="/404" />;
  else if (
    subjects.state === "loading" ||
    learnerSubjectsTutors.state === "loading"
  )
    return <Loader isInline />;
  else {
    const eligibleSubjectsTutors = learnerSubjectsTutors.data.filter(
      (d) => d.tutors.length > 0 && d.tutors.length === 3
    );
    return children(eligibleSubjectsTutors);
  }
}

export default LearnerSubjectsTutorsViewModel;
