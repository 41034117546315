import { StaticDataV3ActionType } from "constants/types/types-static-data";

const ACTION_TYPES: StaticDataV3ActionType[] = [
  "SET_GOALS_V3",
  "ERRED_GOALS_V3",
  "SET_GRADES_V3",
  "ERRED_GRADES_V3",
  "SET_DEGREES_V3",
  "ERRED_DEGREES_V3",
  "SET_SUBJECTS_V3",
  "ERRED_SUBJECTS_V3",
  "SET_SKILLS_V3",
  "ERRED_SKILLS_V3",
  "SET_INTERESTS_V3",
  "ERRED_INTERESTS_V3",
  "SET_LANGUAGES_V3",
  "ERRED_LANGUAGES_V3",
  "SET_CURRICULUMS_V3",
  "ERRED_CURRICULUMS_V3",
  "SET_REQUESTTYPES_V3",
  "ERRED_REQUESTTYPES_V3",
  "SET_QUALIFICATIONS_V3",
  "ERRED_QUALIFICATIONS_V3",
  "SET_MATERIALTYPES_V3",
  "ERRED_MATERIALTYPES_V3",
  "SET_LEARNINGSTYLES_V3",
  "ERRED_LEARNINGSTYLES_V3",

  // Actions for offered grades, subjects and curriculums that are specific to the logged in user.
  "SET_OFFEREDGRADES_V3",
  "ERRED_OFFEREDGRADES_V3",
  "SET_OFFEREDSUBJECTS_V3",
  "ERRED_OFFEREDSUBJECTS_V3",
  "SET_OFFEREDCURRICULUMS_V3",
  "ERRED_OFFEREDCURRICULUMS_V3",

  "RESET_OFFEREDSUBJECTS_V3",
];

export default ACTION_TYPES;
