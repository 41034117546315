import React from "react";
import { Card, CardProps } from "react-bootstrap";

type Props = CardProps & {
  color?: "white" | "gray";
};

export default function SassyCard({
  color = "white",
  children,
  className = "",
  ...props
}: Props) {
  const actualColor =
    {
      white: "white",
      gray: "gray-300",
    }[color] || "white";

  return (
    <Card
      {...props}
      className={`m-0 rounded-4 border-1 bg-${actualColor} ${className}`}
    >
      {children}
    </Card>
  );
}
