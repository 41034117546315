import {WrapperLayout} from "layout";
import React from "react";
import {Col, Row} from "reactstrap";
import MessagesUsersList from "./messages-users-list";
import {Outlet, useParams} from "react-router-dom";


/**
 * @author Abdullah-Sajjad026
 */
export default function MessagesView(): JSX.Element {
  const receiverId = parseInt(useParams().receiverId!);

  return (
    <WrapperLayout className="h-100">
      <Row className="h-100 g-0">
        <Col sm="5" lg="4">
          <div className="bg-white h-100 p-3">
            <MessagesUsersList openedUserId={receiverId} />
          </div>
        </Col>
        <Col sm="7" lg="8">
          <Outlet />
        </Col>
      </Row>
    </WrapperLayout>
  );
}
