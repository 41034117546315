import React from "react";
import { Loader } from "components";

const View = React.lazy(() => import("./content-builder-manage"));

const ContentBuilderManage = (props: {}) => (
  <React.Suspense fallback={<Loader />}>
    <View {...props} />
  </React.Suspense>
);

export default ContentBuilderManage;
