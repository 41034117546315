import * as fileTypes from "constants/types";
import styles from "./file-type-indicator.module.css";

/**
 * @typedef {Object} FileTypeIndicatorProps
 *
 * @property {string} fileType
 */

/**
 * A simple indicator for a file type, send the type and it'll return you the
 * element.
 *
 * @param {JSX.IntrinsicElements['span'] & FileTypeIndicatorProps} props
 *
 * @returns {JSX.Element}
 *
 * @author kashan-ahmad
 *
 * @version 0.0.1
 */
function FileTypeIndicator({ fileType, ...props }) {
  function getLabel() {
    if (
      fileTypes.IMAGE_FILE_TYPES.find(
        (type) => type.toLocaleLowerCase() === fileType.toLocaleLowerCase()
      )
    )
      return "IMG";

    if (
      fileTypes.DOC_FILE_TYPES.find(
        (type) => type.toLocaleLowerCase() === fileType.toLocaleLowerCase()
      )
    )
      return "DOC";

    if (
      fileTypes.PPT_FILE_TYPES.find(
        (type) => type.toLocaleLowerCase() === fileType.toLocaleLowerCase()
      )
    )
      return "PPT";

    if (
      fileTypes.SHEET_FILE_TYPES.find(
        (type) => type.toLocaleLowerCase() === fileType.toLocaleLowerCase()
      )
    )
      return "XLS";

    if (
      fileTypes.PLAIN_FILE_TYPES.find(
        (type) => type.toLocaleLowerCase() === fileType.toLocaleLowerCase()
      )
    )
      return "TXT";

    if (fileType.toLocaleLowerCase() === "pdf") return "PDF";

    return "FILE";
  }

  return (
    <span {...props} className={styles.label}>
      {getLabel()}
    </span>
  );
}

export default FileTypeIndicator;
