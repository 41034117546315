import { DEFAULT_LEARNING_MATERIALS_PER_PAGE } from "constants";
import LearningMaterialService from "service/learning-materials";

/**
 * Middleware for all the learning materials.
 *
 * @author kashan-ahmad
 * @version 0.0.1
 */
export default class LearningMaterialMiddleware {
  service = new LearningMaterialService();

  FILTER_ALL = "All";
  FILTER_COMPLETED = "Completed";
  FILTER_INCOMPLETE = "In-progress";

  constructor(store) {
    this.store = store;
  }

  /**
   * @param {Object} props
   *
   * @param {boolean} props.isLoading Default = false.
   * Pass true to set the state to loading.
   *
   * @param {import("components/pagination/pagination-pages").PaginationPagesProps['itemsPerPage']} props.itemsPerPage Default = 4
   *
   * @param {import("components/pagination/pagination-pages").PaginationPagesProps['currentPage']} props.currentPage Default = 4
   *
   * @param {string} filter Use this class' filter constants
   *
   * @author kashan-ahmad
   * @version 0.0.3
   *
   * @changelog
   * - 0.0.3: Added filter-based keys.
   * - 0.0.2: Added `isLoading` prop and relevant functionality.
   * - 0.0.1: Initial version.
   */
  handlePurchased({
    isLoading = false,
    currentPage = 1,
    itemsPerPage = DEFAULT_LEARNING_MATERIALS_PER_PAGE,
    filter = this.FILTER_ALL,
  } = {}) {
    const key = {
      [this.FILTER_ALL]: "allPurchasedLearningMaterials",
      [this.FILTER_INCOMPLETE]: "pendingPurchasedLearningMaterials",
      [this.FILTER_COMPLETED]: "completedPurchasedLearningMaterials",
    }[filter];

    if (isLoading) {
      return (dispatch) =>
        dispatch({
          type: "LOADING_PURCHASED_LEARNING_MATERIALS",
          payload: {
            key,
            loading: true,
          },
        });
    }

    return (dispatch) =>
      this.service.fetchPurchased({
        filter,
        currentPage,
        itemsPerPage,
        onFailure: ({ message }) =>
          dispatch({
            type: "ERRED_PURCHASED_LEARNING_MATERIALS",
            payload: {
              key,
              error: message,
            },
          }),
        onSuccess: (data) =>
          dispatch({
            type: "SET_PURCHASED_LEARNING_MATERIALS",
            payload: {
              key,
              data,
            },
          }),
      });
  }

  /**
   * @param {Object} props
   *
   * @param {import("components/pagination/pagination-pages").PaginationPagesProps['itemsPerPage']} props.itemsPerPage Default = 4
   *
   * @author kashan-ahmad
   * @version 0.0.1
   */
  handlePopular({ itemsPerPage = 4 } = {}) {
    return (dispatch) =>
      this.service.fetchPopular({
        itemsPerPage,
        onFailure: ({ message }) =>
          dispatch({
            type: "ERRED_POPULAR_LEARNING_MATERIALS",
            payload: {
              error: message,
              key: "popularLearningMaterials",
            },
          }),
        onSuccess: (data) =>
          dispatch({
            type: "SET_POPULAR_LEARNING_MATERIALS",
            payload: {
              data,
              key: "popularLearningMaterials",
            },
          }),
      });
  }

  /**
   * @param {Object} props
   * @param {boolean} props.isLoading Default = false,
   *
   * @author kashan-ahmad
   * @version 0.0.2
   *
   * @changelog
   * - 0.0.2: Added `isLoading` prop and relevant functionality.
   * - 0.0.1: Initial version.
   */
  handleProgress({ isLoading = false } = {}) {
    if (isLoading) {
      return (dispatch) =>
        dispatch({
          type: "LOAING_PURCHASED_LEARNING_MATERIAL_PROGRESS",
          payload: {
            loading: true,
          },
        });
    }

    return (dispatch) =>
      this.service.fetchProgress({
        onFailure: ({ message }) =>
          dispatch({
            type: "ERRED_PURCHASED_LEARNING_MATERIAL_PROGRESS",
            payload: {
              error: message,
            },
          }),
        onSuccess: (data) =>
          dispatch({
            type: "SET_PURCHASED_LEARNING_MATERIAL_PROGRESS",
            payload: data,
          }),
      });
  }
}
