// On Boarding
export const SET_ONBOARDING_STEP = "SET_ONBOARDING_STEP";

//User info --//
export const STORE_TUTOR_INFO = "STORE_TUTOR_INFO";
export const USERINFO_ERROR = "USERINFO_ERROR";

// User Profile
export const GET_TUTOR_DATA = "GET_TUTOR_DATA";
export const SET_TUTOR_LOADING_TRUE = "SET_TUTOR_LOADING_TRUE";
export const PROFILE_DATA_ERROR = "PROFILE_DATA_ERROR";
export const ONBOARDING_STATUS = "ONBOARDING_STATUS";

//---Qualifications info --////

export const STORE_QUALIFICATIONS = "STORE_QUALIFICATIONS";
export const FETCH_QUALIFICATIONS = "FETCH_QUALIFICATIONS";
export const UPDATE_QUALIFICATIONS = "UPDATE_QUALIFICATIONS";
export const DELETE_QUALIFICATIONS = "DELETE_QUALIFICATIONS";
export const QUALIFICATIONS_ERROR = "QUALIFICATIONS_ERROR";

export const FETCH_LANGUAGES = "FETCH_LANGUAGES";
export const UPDATE_LANGUAGES = "UPDATE_LANGUAGES";
export const STORE_LANGUAGES = "STORE_LANGUAGES";
export const FETCH_LANGUAGES_ERROR = "FETCH_LANGUAGES_ERROR";

//--Degree---//
export const FETCH_DEGREE = "FETCH_DEGREE";
export const UPDATE_DEGREE = "UPDATE_DEGREE";
export const DEGREE_ERROR = "DEGREE_ERROR";

//--Curriculum---//
export const FETCH_CURRICULUM = "FETCH_CURRICULUM";
export const UPDATE_CURRICULUM = "UPDATE_CURRICULUM";
export const FETCH_CURRICULUM_ERROR = "FETCH_CURRICULUM_ERROR";

//--Subjects info--//

export const FETCH_SUBJECTS = "FETCH_SUBJECTS";
export const UPDATE_SUBJECTS = "UPDATE_SUBJECTS";
export const DELETE_SUBJECTS = "DELETE_SUBJECTS";
export const STORE_SUBJECTS = "STORE_SUBJECTS";
export const FETCH_SUBJECTS_ERROR = "FETCH_SUBJECTS_ERROR";
//---Experience info --////

export const STORE_EXPERIENCE = "STORE_EXPERIENCE";
export const FETCH_EXPERIENCE = "FETCH_EXPERIENCE";
export const UPDATE_EXPERIENCE = "UPDATE_EXPERIENCE";
export const DELETE_EXPERIENCE = "DELETE_EXPERIENCE";
export const EXPERIENCE_ERROR = "EXPERIENCE_ERROR";

//---Skill info --////

export const STORE_SKILLS = "STORE_SKILLS";
export const DELETE_SKILLS = "DELETE_SKILLS";
export const FETCH_SKILLS = "FETCH_SKILLS";
export const UPDATE_SKILLS = "UPDATE_SKILLS";
export const SKILLS_ERROR = "SKILLS_ERROR";

//---Certificate info --////

export const STORE_CERTIFICATE = "STORE_CERTIFICATE";
export const UPDATE_CERTIFICATE = "UPDATE_CERTIFICATE";
export const DELETE_CERTIFICATES = "DELETE_CERTIFICATES";
export const FETCH_CERTIFICATE = "FETCH_CERTIFICATE";
export const CERTIFICATE_ERROR = "CERTIFICATE_ERROR";

//---Content Builder info --////

export const GET_CONTENT_BUILDER_DATA = "GET_CONTENT_BUILDER_DATA";
export const CONTENT_BUILDER_ERROR = "CONTENT_BUILDER_ERROR";

//---Content Builder Form Topics --////

export const GET_LEARNING_TOPIC = "GET_LEARNING_TOPIC";
export const UPDATE_TOPIC = "UPDATE_TOPIC";
export const GET_LEARNING_TOPIC_ERROR = "GET_LEARNING_TOPIC_ERROR";

//---Content Builder Form type--////

export const GET_LEARNING_TYPE = "GET_LEARNING_TYPE";
export const UPDATE_TYPE = "UPDATE_TYPE";
export const GET_LEARNING_TYPE_ERROR = "GET_LEARNING_TYPE_ERROR";

// //---Create Learning Material --////

// export const CREATE_LEARNING_MATERIAL = "CREATE_LEARNING_MATERIAL";
// export const CREATE_LEARNING_MATERIAL_ERROR = "CREATE_LEARNING_MATERIAL_ERROR";

//---Upload Learning Material --////

export const UPLOAD_LEARNING_MATERIAL_FILE = "UPLOAD_LEARNING_MATERIAL_FILE";
export const UPLOAD_LEARNING_MATERIAL_FILE_ERROR =
  "UPLOAD_LEARNING_MATERIAL_FILE_ERROR";

// Availability.
export const UPDATE_AVAILABILITY = "UPDATE_AVAILABILITY";

// Settings.
export const UPDATE_TUTOR_SETTINGS_BOOKING = "UPDATE_TUTOR_SETTINGS_BOOKING";
export const UPDATE_TUTOR_SETTINGS_CALENDAR = "UPDATE_TUTOR_SETTINGS_CALENDAR";
export const UPDATE_TUTOR_SETTINGS_SWITCHES = "UPDATE_TUTOR_SETTINGS_SWITCHES";

// Off-Time
export const SET_TUTOR_OFF_TIME = "SET_TUTOR_OFF_TIME";
export const REMOVE_TUTOR_OFF_TIME = "REMOVE_TUTOR_OFF_TIME";
