import React, {useState} from "react";
import moment from "moment";
import SimpleBar from "simplebar-react";
import {Loader, SassyAlert} from "components";
import {useApiEffect} from "hooks";
import {useDispatch} from "react-redux";
import {fetchLearnerCalendarSessions} from "store";
import {SassyCard, SessionStatusCard} from "components/cards";
import {Accordion, CardTitle} from "reactstrap";
import {IconCalendar} from "@tabler/icons";
import "assets/scss/views/dashboard/dashboard-sidebar.scss";

const LearnerDashboardSidebar = () => {
  const [open, setOpen] = React.useState("");
  const toggle = (id) => {
    if (open === id) {
      setOpen("");
    } else {
      setOpen(id);
    }
  };

  const [todaysSessions, setTodaysSessions] = useState({
    state: "loading",
    data: [],
  });

  const dispatch = useDispatch();

  useApiEffect(() => {
    dispatch(
      fetchLearnerCalendarSessions({
        statuses: [2],
        start_date: moment().format("YYYY-MM-DD"),
        end_date: moment().format("YYYY-MM-DD"),
        onSuccess: (data) => {
          setTodaysSessions({state: "loaded", data});
        },
        onFailure: () => {
          setTodaysSessions({state: "erred", data: []});
        },
      })
    );
  }, []);

  return (
    <SassyCard>
      <SassyCard.Body>
        <header className="d-flex gap-2 align-items-center">
          <IconCalendar size={18} className="text-secondary" />
          <CardTitle className="m-0">
            {moment().format("DD MMM YYYY")}
          </CardTitle>
        </header>
        {todaysSessions.state === "erred" ? (
          <SassyAlert
            title="There was a problem loading your sessions."
            color="danger"
          />
        ) : todaysSessions.state === "loading" ? (
          <Loader isInline />
        ) : todaysSessions.data.length === 0 ? (
          <SassyAlert title="No upcoming sessions" color="success" />
        ) : (
          <SimpleBar className="overflow-y-auto" style={{maxHeight: "470px"}}>
            <div className="d-flex flex-column gap-3">
              {todaysSessions.data.map((session) => (
                <Accordion {...{open, toggle}}>
                  <SessionStatusCard.Renderer.LearnerDefault
                    {...session}
                    key={session.id}
                  />
                </Accordion>
              ))}
            </div>
          </SimpleBar>
        )}
      </SassyCard.Body>

      {/* <div className="learner-dash-sidebar-user2 d-flex">
        <div className="learner-dash-sidebar-profiles">
          <img src={User} alt="" className="learner-dash-sidebar-image2" />
        </div>
        <div>
          <h2 className="learner-dash-sidebar-subject2">Still deciding?</h2>
          <h2 className="learner-dash-sidebar-name2">
            Instantly connect with a{" "}
            <span
              style={{
                color: "#5CDB95",
                textDecoration: "underline",
              }}
            >
              <button onClick={() => setIsModalOpen(true)}>Tutor</button>
            </span>{" "}
            Now!
          </h2>
        </div>
      </div> */}

      {/* ------ Developer Note

            Don't remove the code commented below

          */}

      {/* <section className="learner-dash-sidebar-explore">
            <img
              src={SidebarEclipse}
              alt="learner-dash-sidebar-eclipse"
              className="learner-dash-sidebar-explore-background"
            />
            <DashboardConnectProfile />
          </section> */}

      {/* <InstantConnectModal isOpen={isModalOpen} toggleModal={toggleModal} /> */}
    </SassyCard>
  );
};

export default LearnerDashboardSidebar;
