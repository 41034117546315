import React from "react";
import { Dropdown } from "react-bootstrap";
import { TablerIcon } from "@tabler/icons";

type IconedDropdownProps = {
  className?: string;
  as?: React.ElementType;
  icon?: TablerIcon;
  text?: string;
  onClick?: Function;
  link?: any;
};

export default function SassyDropdownItem({
  className = "",
  as = "button",
  icon: Icon,
  text,
  onClick,
}: IconedDropdownProps) {
  return (
    <Dropdown.Item
      className={`text-secondary px-3 d-flex align-items-center gap-3 ${className}`}
      as={as}
      onClick={() => onClick?.()}
    >
      {Icon && <Icon size="21" />}
      {text && <p className="text-nowrap mb-0 fs-5">{text}</p>}
    </Dropdown.Item>
  );
}
