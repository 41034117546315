import { Provider } from "react-redux";
import notificationStore from "./store-notification";
import NotificationContext from "./store-notification-context";

const NotificationProvider = ({ children }) => (
  <Provider store={notificationStore} context={NotificationContext}>
    {children}
  </Provider>
);

export default NotificationProvider;
