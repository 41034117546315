import * as React from "react";
import { Link, useLocation } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, Col, Row } from "reactstrap";

/**
 * A generic breadcrumb component that supports both dynamic and static routes. Active routes are automatically assigned the window's current path unless specified
 *
 * @param {BreadCrumbProps} object
 * @returns {JSX.Element}
 * @author kashan-ahmad
 * @version 0.0.2
 * @changelog
 * - 0.0.1: Added support for dynamic routes using the new `routes` property.
 * - 0.0.1: Initial version.
 */

interface IBreadCrumbProps {
  activeRouteName?: string;
  activeRoutePath?: string;
  parentRouteName?: string;
  parentRoutePath?: string;
  routes?:
    | { name: string; path: string | undefined; active: boolean | undefined }[];
}

function BreadCrumb({
  parentRouteName = "",
  parentRoutePath = "",
  activeRouteName = "",
  activeRoutePath = "",
  routes = [],
}: IBreadCrumbProps): JSX.Element {
  const location = useLocation();

  return (
    <Row className="bg-light border-top border-bottom">
      <Col xs="12">
        <div className="py-1 px-3 d-sm-flex align-items-center">
          <Breadcrumb listClassName="m-0">
            {routes &&
              routes.map(({ name, path, active = false }, index, arr) => (
                <BreadcrumbItem active={active} key={index}>
                  <Link className="font-size-13" to={path || location.pathname}>
                    {name}
                  </Link>
                </BreadcrumbItem>
              ))}
            {parentRouteName && (
              <BreadcrumbItem>
                <Link className="font-size-13" to={parentRoutePath || "#"}>
                  {parentRouteName}
                </Link>
              </BreadcrumbItem>
            )}
            {activeRouteName && (
              <BreadcrumbItem active>
                <Link className="font-size-13" to={activeRoutePath || "#"}>
                  {activeRouteName}
                </Link>
              </BreadcrumbItem>
            )}
          </Breadcrumb>
        </div>
      </Col>
    </Row>
  );
}

export default BreadCrumb;
