import React from "react";

/**
 * Effect that uses the AbortController that cancels requests when components are terminated.
 * @param {React.EffectCallback} callback
 * @param {React.DependencyList} deps
 */
function useApiEffect(callback, deps) {
  const controller = new AbortController();

  React.useEffect(() => {
    const cleanup = callback();

    return () => {
      controller.abort();

      if (typeof cleanup === "function") cleanup();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}

export default useApiEffect;
