import {useLocation} from "react-router";
import React from "react";
import {Link} from "react-router-dom";
import {Button} from "reactstrap";
import {
  IconChevronDown,
  IconChevronLeft,
  IconChevronRight,
  IconChevronUp,
} from "@tabler/icons";

type PropsType =
  | {
      orientation: "horizontal";
      to: string;
      backRoute: string;
      routeState?: any;

      onClick?: never;
      isActive?: never;
    }
  | {
      orientation: "vertical";
      onClick: () => void;
      isActive: boolean;

      backRoute?: never;
      to?: never;
      routeState?: never;
    };

export default function OpenCloseButton({
  orientation,
  routeState,
  to,
  backRoute,
  isActive,
  onClick,
}: PropsType) {
  const location = useLocation();
  let active: boolean = false;

  if (orientation === "horizontal" && to === location.pathname) {
    active = true;
  } else if (orientation === "vertical") {
    active = isActive;
  }

  return to ? (
    <Link
      to={!active ? to : backRoute}
      className={`btn ${active ? "btn-info" : "btn-outline-info"}`}
      state={!active ? routeState : {}}
      replace
    >
      {active ? <IconChevronLeft size="19" /> : <IconChevronRight size="19" />}
    </Link>
  ) : (
    <Button onClick={onClick} color="info" {...(active ? {} : {outline: true})}>
      {active ? <IconChevronUp size="19" /> : <IconChevronDown size="19" />}
    </Button>
  );
}
