/* eslint-disable eqeqeq */
import React from "react";
import { getDiscounted } from "utils";
import { BUNDLE_DUMMY } from "constants";
import { FormErrorMessage, IconButton, Switch } from "components";
import { Col, Input, Label, ListGroupItem, Row } from "reactstrap";
import { TUTOR_MIN_SESSION_FEE } from "constants";
import { TUTOR_MAX_SESSION_FEE } from "constants";
import { MAX_BUNDLES_PER_SESSION } from "constants";
import { IconPlus, IconTrash } from "@tabler/icons";
import { TUTOR_MAX_SESSIONS } from "constants";

/**
 * The bundles partition of the tutor's create bundle page.
 * Allows the user to create bundles of sessions.
 *
 * @param {Object} object The properties passed
 * to the component.
 * @param {Array} object.bundles The array of bundles
 * that contains objects in the form of bundles.
 * @param {bundles => void} object.onUpdateBundles The
 * method that updates the bundles.
 * @returns {JSX.Element}
 *
 * @version 0.0.1
 * @author kashan-ahmad
 *
 * @changelog
 * - 0.0.1: Initial version.
 */
function TutoringTutorBundles({
  //feePerSession,
  bundles,
  onUpdateBundles,
  showErrorMessages,
}) {
  // Effects
  // React.useEffect(() => {
  //   // Calculate the fee on first iteration.
  //   // or when the feePerSession is changed.
  //   onUpdateBundles(
  //     bundles.map((bundle) => ({
  //       ...bundle,
  //       fee: getDiscounted(
  //         +feePerSession * bundle.sessions.value,
  //         bundle.discount.value
  //       ),
  //     }))
  //   );
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [feePerSession]);

  // Handlers.
  /**
   * Updates a key in a specific bundle.
   *
   * @param {Object} key The key to be updated
   * @param {number} i The index of the bundle to be updated.
   */
  function updateBundle(key, value, i) {
    const newBundles = bundles.slice();
    newBundles[i] = { ...newBundles[i], [key]: value };

    // Recalculate fee.
    newBundles[i] = {
      ...newBundles[i],
      total_fee: Math.ceil(
        getDiscounted(
          +newBundles[i].fee_per_session * newBundles[i].no_of_sessions,
          newBundles[i].discount
        )
      ).toFixed(0),
    };

    onUpdateBundles(newBundles);
  }

  /**
   * Adds a bundle to the bundles
   */
  const addBundle = () =>
    onUpdateBundles([
      ...bundles,
      {
        ...BUNDLE_DUMMY,
        // 1 more session than the last bundle's no_of_sessions.
        no_of_sessions:
          1 + parseInt(bundles[bundles.length - 1].no_of_sessions),
      },
    ]);

  /**
   * Removes a bundle from the bundles
   *
   * @param {number} i The position of the bundle
   * to be removed in the bundles array.
   */
  const removeBundle = (i) => {
    const newBundles = bundles.slice();
    newBundles.splice(i, 1);
    onUpdateBundles(newBundles);
  };

  /**
   * Common listeners required by about all the input fields.
   *
   * @param {number} min Minimum value.
   * @param {number} max Maximum value.
   * @param {number} index Index in the bundles array.
   * @param {string} key Key in the bundles array.
   *
   * @returns {{
   * onChange: (e: React.FormEvent<HTMLInputElement>) => void
   * onKeyDown: (e: React.FormEvent<HTMLInputElement>) => void
   * }}
   */
  function listenersFactory(min, max, index, key) {
    return {
      onBlur: (e) => {
        const value = e.target.value;

        if (!value) updateBundle(key, min, index);
      },
      onChange: (e) => {
        const value = e.target.value;

        if (value && (value < 0 || value > max)) return;

        updateBundle(key, value, index);
      },
    };
  }

  return (
    <>
      {bundles.map((bundle, index) => (
        <ListGroupItem
          key={index}
          className="p-0 pb-4 mb-4 border-top-0 border-end-0 border-start-0"
        >
          <Row className="g-3">
            <Col xs={4} xxl={2} className="d-flex flex-column gap-1">
              <Label
                htmlFor={`noOfSessions${index}`}
                className="fs-7 text-blue m-0"
              >
                No. of Sessions
              </Label>
              <Input
                type="number"
                id={`noOfSessions${index}`}
                value={bundle.no_of_sessions}
                onChange={(e) =>
                  updateBundle("no_of_sessions", e.target.value, index)
                }
              />
              {showErrorMessages &&
                (bundle.no_of_sessions > TUTOR_MAX_SESSIONS ||
                  bundle.no_of_sessions < 1) && (
                  <FormErrorMessage
                    message={`Min 1 | Max ${TUTOR_MAX_SESSIONS}`}
                  />
                )}
            </Col>
            <Col xs={4} xxl={2} className="d-flex flex-column gap-1">
              <Label
                className="fs-7 text-blue m-0"
                htmlFor={`noOfSessions${index}`}
              >
                Fee per session
              </Label>
              <Input
                type="number"
                id={`noOfSessions${index}`}
                value={bundle.fee_per_session}
                {...listenersFactory(
                  0,
                  TUTOR_MAX_SESSION_FEE,
                  index,
                  "fee_per_session"
                )}
              />
            </Col>
            <Col xs={4} xxl={2} className="d-flex flex-column gap-1">
              <Label
                className="fs-7 text-blue m-0"
                htmlFor={`discount${index}`}
              >
                Discount %
              </Label>
              <Input
                type="number"
                id={`discount${index}`}
                value={bundle.discount}
                {...listenersFactory(0, 99, index, "discount")}
              />
            </Col>
            <Col xs={8} xxl={3} className="d-flex flex-column gap-1">
              <Label
                htmlFor={`totalFee${index}`}
                className="fs-7 text-blue m-0"
              >
                Total Fee $
                {/* <span className="text-blue font-size-10">
                  (Min ${TUTOR_MIN_SESSION_FEE}, Max ${TUTOR_MAX_SESSION_FEE})
                </span> */}
              </Label>
              <Input
                disabled
                type="number"
                placeholder="0"
                id={`totalFee${index}`}
                value={bundle.total_fee}
                className="form-control px-2 font-size-13 text-secondary"
              />
              {showErrorMessages &&
                (bundle.total_fee < TUTOR_MIN_SESSION_FEE ||
                  bundle.total_fee > TUTOR_MAX_SESSION_FEE) && (
                  <FormErrorMessage
                    message={`Min $${TUTOR_MIN_SESSION_FEE} | Max $${TUTOR_MAX_SESSION_FEE}`}
                  />
                )}
            </Col>
            <Col xs={4} xxl={3} className="d-flex gap-3">
              <div className="d-flex flex-column gap-1">
                <Label
                  htmlFor={`switch${index}`}
                  className="fs-7 text-blue m-0"
                >
                  Active
                </Label>
                <Switch
                  type="number"
                  id={`switch${index}`}
                  checked={bundle.status == 1}
                  onChange={(checked) =>
                    updateBundle("status", checked ? 1 : 0, index)
                  }
                />
              </div>
              {/* The delete button is shown only on bundles that have an ID, which specifies that they're freshly added and not being edited. */}
              {index && !bundle.id ? (
                <div className="d-flex flex-column gap-1">
                  <Label
                    htmlFor={`remove${index}`}
                    className="fs-7 text-blue m-0"
                  >
                    Remove
                  </Label>
                  <IconButton
                    type="button"
                    id={`remove${index}`}
                    onClick={() => removeBundle(index)}
                  >
                    <IconTrash className="text-danger" />
                  </IconButton>
                </div>
              ) : null}
            </Col>
          </Row>
        </ListGroupItem>
      ))}
      {/* Can't add more than a fixed number of bundles. */}
      {bundles.length < MAX_BUNDLES_PER_SESSION && (
        <IconButton type="button" title="Add a session" onClick={addBundle}>
          <IconPlus className="text-success" />
        </IconButton>
      )}
    </>
  );
}

export default TutoringTutorBundles;
