import React from "react";
import { Card } from "reactstrap";

const QuickTipCard = ({
  img,
  subtitle = "3min Quick tips",
  title = "Did you know You Can Create A Course In Less Than 10 Minutes?",
  url = "",
}) => {
  return (
    <Card className="m-0 bg-white rounded border-1">
      <a href={url} target="_blank" rel="noreferrer">
        <div
          className="d-flex flex-column justify-content-between p-2"
          style={{ minHeight: "50vh" }}
        >
          <p className="text-secondary p-1 mt-4 mb-1">
            <i className="bx bx-time"></i>
          </p>
          <h2 className="text-secondary p-1 ps-2 ">{subtitle}</h2>
          <h2 className="text-secondary p-1 ps-2">
            {title}
            <i className="bx bx-right-arrow-alt"></i>
          </h2>
          <img
            src={img}
            className="d-block align-self-end"
            style={{
              objectFit: "cover",
            }}
            width="200px"
            height="140px"
            alt=""
          />
        </div>
      </a>
    </Card>
  );
};
export default QuickTipCard;
