import { RawUserProfile } from "constants/types/types-profile";
import { useSelector } from "react-redux";

type ReturnType = {
  profile: RawUserProfile;
};

/**
 * Hook into the profile of a user.
 *
 * @returns
 */
export default function useUserProfile(): ReturnType {
  const rawUserProfile: RawUserProfile = useSelector(
    (store: any) => store.auth.userDetails
  );

  return { profile: rawUserProfile };
}
