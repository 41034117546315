import { Loader } from "components";
import * as React from "react";

const View = React.lazy(() => import("./dashboard-tutor-v1"));

const TutorDashboardV1 = (props) => (
  <React.Suspense fallback={<Loader />}>
    <View {...props} />
  </React.Suspense>
);

export default TutorDashboardV1;
