import * as React from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";

//i18n
import { withTranslation } from "react-i18next";

import { Avatar, Icon } from "components";
import { get } from "lodash";
import { IconChevronDown, IconHome, IconLogout, IconUser } from "@tabler/icons";
import { connect } from "react-redux";
import useProfileMenu from "hooks/use-profile-menu";
import { useAuth } from "hooks/auth";

const ProfileMenu = (props) => {
  const { menuItems } = useProfileMenu();

  const { logout } = useAuth();

  const userType = get(props.userDetails, "user_type", "");
  const getProfilePic = (userType) => {
    switch (userType) {
      case "learner":
        return get(props.learnerDetails, "profile_picture", "");
      case "tutor":
        return get(props.tutorDetails, "profile_picture", "");
      default:
        break;
    }
  };

  //const isOnboarded = getonBoarding(userType);
  const profilePicture = getProfilePic(userType);

  return (
    <React.Fragment>
      <Dropdown className="border-0">
        <Dropdown.Toggle as="button" className="btn header-item">
          <span className="p-1 bg-gray-300 rounded-pill d-flex align-items-center gap-1">
            <Avatar size="xs" src={profilePicture} />
            <Icon size={18}>
              <IconChevronDown />
            </Icon>
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu className="border-1">
          <Dropdown.Item
            as="a"
            target="_blank"
            rel="noreferrer"
            href={process.env.REACT_APP_WEBSITE_URL}
            className="d-flex align-items-center gap-2"
          >
            <IconHome size={18} />
            Home
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item
            as={Link}
            to="/profile"
            className="d-flex align-items-center gap-2"
          >
            <IconUser size={18} />
            Profile
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item
            as="button"
            onClick={() => logout()}
            className="d-flex align-items-center gap-2"
          >
            <IconLogout size={18} />
            Logout
          </Dropdown.Item>

          {menuItems.length > 0 &&
            menuItems.map((item, index) => (
              <React.Fragment key={item.id}>
                <Dropdown.Divider />
                {/* Show divider only if the item is not the first item */}
                {index !== 0 && <Dropdown.Divider />}
                <Dropdown.Item
                  as="button"
                  onClick={() => item.onClick?.()}
                  className="d-flex align-items-center gap-2"
                >
                  <item.icon size={18} />
                  {item.title}
                </Dropdown.Item>
              </React.Fragment>
            ))}
        </Dropdown.Menu>
      </Dropdown>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const learnerDetails = state.learnerDashboard.userInfo.user;
  const tutorDetails = state.tutorDashboard.userInfo.user;
  const { userDetails } = state.auth;
  const { success } = state.Profile;
  return { success, userDetails, learnerDetails, tutorDetails };
};

export default connect(mapStateToProps, {})(withTranslation()(ProfileMenu));
